import styled from 'styled-components'
import tw from "tailwind-styled-components"
import {Link} from 'react-router-dom';
import {Menu} from '@styled-icons/boxicons-regular/Menu'
import {Home} from '@styled-icons/boxicons-solid/Home'
import {SearchOutline} from '@styled-icons/evaicons-outline/SearchOutline'
import {DataTrending} from '@styled-icons/fluentui-system-regular/DataTrending'
import {Person} from '@styled-icons/bootstrap/Person'
import {Group} from '@styled-icons/boxicons-regular/Group'
import  {Bell} from '@styled-icons/bootstrap/Bell';
import {Boxes} from '@styled-icons/bootstrap/Boxes'
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'
import {Notifications} from '@styled-icons/ionicons-outline/Notifications'
import {Box} from '@styled-icons/bootstrap/Box'

export const Container = tw.div`
    w-full h-12 bottom-0 fixed z-[49]
    max-lg:flex
    lg:hidden
`

export const Layout = tw.div`
    flex w-full h-full items-center px-2
    bg-white border-t
`

export const NavButton = tw(Link)`
    flex flex-col justify-center w-full h-full
    text-gray-700 py-1.5
    
`
export const NavButtonDiv = tw.div`
    flex flex-col justify-center w-full h-full
    text-gray-700
`
export const NavButtonTitle = tw.span`
    flex flex-center h-full 
    ${(props) => props.navstate ? "text-amber-700 bg-amber-200 " : ""}
    rounded-lg
    text-center text-sm 
`
export const NavIconContainer = tw.div`
    flex flex-center h-5
`
export const HomeIcon = tw(Home)`
    h-full
`
export const SearchIcon = tw(SearchOutline)`
    h-full
`
export const NoticeIcon = tw(Notifications)`
    h-full
`
export const MyPageIcon = tw(PersonFill)`
    h-full
`