import React from "react"
import { ExcelIcon } from "../../pages/myPage/perform/MyPagePerformStyle"
import { ArrowRightCircleFill } from "@styled-icons/bootstrap/ArrowRightCircleFill"

export default function ItemMarketerNameChain({ nameChain, minify = false }) {
    const names = String(nameChain).split(" > ")

    if (names.length <= 1) {
        return <div>{nameChain}</div>
    } else {
        const chains = names.slice(0, names.length - 1)
        const name = names[names.length - 1]
        return (
            <div className="flex flex-wrap [&>span]:text-nowrap">
                {chains.map((chain, index) => (
                    <span key={index} className="mr-1">
                        {minify ? (
                            <ArrowRightCircleFill className="text-amber-600 text-sm h-4 mr-0.5 mb-1" />
                        ) : (
                            <span className="text-slate-500 text-sm">{`${chain} >`}</span>
                        )}
                    </span>
                ))}
                <span>{name}</span>
            </div>
        )
    }
}
