import tw from "tailwind-styled-components"
import { BodyInnerBox, BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"
import { Share } from "@styled-icons/boxicons-solid/Share"
import styled from "styled-components"
import { ChevronUp } from "@styled-icons/fa-solid/ChevronUp"

export const ItemMarketerMatchingPageContainer = tw(ContainerPaddingTop)`
    h-auto max-md:pt-6 md:pt-12 md:mt-6
    min-h-screen
    bg-gradient-to-t from-slate-150 to-slate-50
`

export const ItemMarketerMatchingContainer = tw.div`    
    w-full md:max-w-[1200px]
`

export const ItemMarketerMatchingSearchBox = tw.div`
    max-sm:sticky max-sm:bottom-0
    flex flex-center w-full max-sm:h-20 sm:h-36 max-sm:px-4
`
export const ItemMarketerMatchingSearchButton = tw.button`
    max-sm:w-44 max-sm:h-10 sm:w-48 sm:h-14
    marketer-matching-button-color rounded-full
    text-xl font-semibold
    duration-300
`

export const ItemMarketerMatchingResultBox = tw.div`
    flex flex-col w-full mt-2 mb-8
`
