import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { getRegionTypes } from "../../../../apis/api/commons"
import { reviveUser, updateUserDetail } from "../../../../apis/api/users"

import { getUserBrandRequestData } from "../../../../services/users"
import { MarketerStatusTypeColors } from "../../../../types/brandItems/BrandItemTypes"
import { EditStateTypeColors, EditStateTypes } from "../../../../types/EditorTypes"
import Brand from "../../../../types/users/Brand"
import UserBrand from "../../../../types/users/UserBrand"
import { UserGenderNames } from "../../../../types/users/UserTypes"
import { userDetailModalStore } from "../../../../zustand/modalstore"
import {
    MarketerStatSelect,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
} from "../../marketer/MyPageMarketerStyle"
import { StaffUserReviveButton } from "./StaffUserStyle"

const MarketerCellWidth = {
    Index: "min-w-[6rem]",
    Name: "min-w-[8rem]",
    Email: "min-w-[14rem] w-full",
    Tel: "min-w-[8.5rem]",
    Age: "min-w-[5rem]",
    ProfileImage: "min-w-[8rem]",
    Gender: "min-w-[4rem]",
    Location: "min-w-[13rem]",
    Created: "min-w-[8rem]",
    Deleted: "min-w-[6rem]",
    Point: "min-w-[7rem]",
}

const RowMinWidth = "min-w-[94.5rem] w-full"

const MarketerTableRow = ({ userMarketer, locationTypes }) => {
    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    const handeRowClick = () => {
        setUserId(userMarketer.marketer.user.id)
        setUserShow(true)
    }

    const handleReviveUser = () => {
        if (!confirm("탈퇴 복구 하시겠습니까?")) {
            return
        }

        reviveUser(userMarketer.user.id)
            .then((response) => {
                alert("탈퇴 복구 처리되었습니다.\n새로 고침해주세요.")
            })
            .catch((error) => {
                alert("복구 실패했습니다.")
            })
    }

    return (
        <MarketerTableDataRow className={`${RowMinWidth} divide-x-2`}>
            <MarketerTableData className={`${MarketerCellWidth.Index}`}>{userMarketer.user.id}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Name}`}>{userMarketer.user.name}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>{userMarketer.user.email}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>{userMarketer.user.tel}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>{userMarketer.user.age}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.ProfileImage}`}>프로필 이미지</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Gender}`}>
                {UserGenderNames[userMarketer.user.gender]}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Location}`}>
                {locationTypes[userMarketer.detail.location1]},{locationTypes[userMarketer.detail.location2]},
                {locationTypes[userMarketer.detail.location3]}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Created}`}>가입 날짜</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Deleted}`}>
                {userMarketer.user.isDeleted ? (
                    <StaffUserReviveButton
                        onClick={() => {
                            handleReviveUser()
                        }}
                    >
                        탈퇴 복구
                    </StaffUserReviveButton>
                ) : (
                    <span>활동 중</span>
                )}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Point}`}>
                {userMarketer.user.recommanderPoint}
            </MarketerTableData>
            {/* <MarketerTableData className={`${MarketerCellWidth.Index}`}>
                {itemMarketer.index}
            </MarketerTableData>
            <HoverUserCard userType={itemMarketer.marketer.user.id} onClick={() => {handeRowClick(itemMarketer)}}    >
                <MarketerTableData className={MarketerCellWidth.Name}>
                    {itemMarketer.marketer.user.name}
                </MarketerTableData>
            </HoverUserCard>
            <MarketerTableData className={`${MarketerCellWidth.GroupName}`}>
                {itemMarketer.group.name}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>
                {itemMarketer.marketer.user.email}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>
                {itemMarketer.marketer.user.tel}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>
                {itemMarketer.marketer.detail.age}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Location}`}>
                {locationTypes[itemMarketer.marketer.detail.location1]},
                {locationTypes[itemMarketer.marketer.detail.location2]},
                {locationTypes[itemMarketer.marketer.detail.location3]}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Performs}`}>
                {itemMarketer.performs}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Status}`}>
                <MarketerStatSelect 
                    value={brandStatusType} 
                    className={`bg-[${MarketerStatusTypeColors[brandStatusType]}] text-white`}
                    onChange={(e)=>{handleBrandStatus(e.target.value)}}
                    editStateType={brandStatusEditStateType}
                >
                    <option value={MarketerStatusTypes.WAIT}>{MarketerStatusTypeNames[MarketerStatusTypes.WAIT]}</option>
                    <option value={MarketerStatusTypes.CONFIRM}>{MarketerStatusTypeNames[MarketerStatusTypes.CONFIRM]}</option>
                    <option value={MarketerStatusTypes.BLOCK}>{MarketerStatusTypeNames[MarketerStatusTypes.BLOCK]}</option>
                </MarketerStatSelect>
            </MarketerTableData> */}
        </MarketerTableDataRow>
    )
}

const Columns = () => {
    return (
        <MarketerTableHeadRow className={`sticky top-0 z-10 ${RowMinWidth}`}>
            <MarketerTableHead className={`${MarketerCellWidth.Index}`}>NO</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Name}`}>이름</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Email}`}>이메일</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Tel}`}>전화번호</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Age}`}>나이</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.ProfileImage}`}>프로필 이미지</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Gender}`}>성별</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Location}`}>활동 지역</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Created}`}>가입 날짜</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Deleted}`}>탈퇴 여부</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Point}`}>보유 포인트</MarketerTableHead>
        </MarketerTableHeadRow>
    )
}

export default function StaffUserMarketerTable({ userMarketers }) {
    const [locationTypes, setLocationTypes] = useState({})

    useEffect(() => {
        getTypes()
    }, [])

    const getTypes = () => {
        getRegionTypes().then((response) => {
            let regionTypes = { ...response }
            regionTypes[0] = "-"
            setLocationTypes(regionTypes)
        })
    }

    return (
        <MarketerTableCon>
            <Columns />

            {userMarketers.map((userMarketer, idx) => (
                <MarketerTableRow userMarketer={userMarketer} locationTypes={locationTypes} key={idx} />
            ))}
            {/* <MarketerTableBody>
            </MarketerTableBody> */}
        </MarketerTableCon>
    )
}
