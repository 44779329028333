import UserMarketer from "./UserMarketer"

export default class extends UserMarketer {
    constructor() {
        super()
        this.invited = false
    }

    convertByResponse(response) {
        super.convertByResponse(response)
        this.invited = response["invited"]
    }

    convertToRequest() {}
}
