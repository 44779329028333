import PerformValue from "./PerformValue";
import { PerformColumnFormTypes } from "./PerformValueTypes";

export default class extends PerformValue{
    constructor() {
        super()
        this.columnID = -1
        this.performID = -1
        this.checked = -1
    }

    convertByResponse(response) {
    }

    convertToRequest() {
    }
}