import tw from "tailwind-styled-components"
import styled from "styled-components"
import { ThreeDotsVertical } from "@styled-icons/bootstrap/ThreeDotsVertical"
import { TelephoneFill } from "@styled-icons/bootstrap/TelephoneFill"
import { Email } from "@styled-icons/entypo/Email"
import { Plus } from "@styled-icons/bootstrap/Plus"
import { MyPageInputModalLayout } from "../MyPageMainStyle"
import { MyPageContentHeaderColor, MyPageContentRowHeight } from "../MyPageMainStyleParameter"
import { EditStateTypes } from "../../../types/EditorTypes"

export const MarketerGroupCon = tw.div`
  w-full h-full bg-transparent overflow-hidden flex flex-col drop-shadow
`

export const MarketerTableSearchCon = tw.div`
  w-fit flex items-center rounded-xl overflow-hidden border border-amber-300
`

export const GroupHeaderBox = tw.div`
w-full h-12 flex items-center justify-between mb-4
`
export const GroupHeaderTitle = tw.span`
text-2xl
`
export const GroupHeaderControlBox = tw.div`
h-full w-auto flex items-center
`
export const GroupAddButton = tw.div`
flex justify-center items-center px-4 h-10
rounded-md bg-nightblue-700 hover:bg-nightblue-600 text-white 
duration-200
`

export const MarketerGroupTableCon = tw.div`
  w-full h-full relative overflow-scroll scroll-transparent scroll-overlay bg-transparent
`

export const GroupGridBox = tw.div`
    w-full inline-grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 pb-6
`

export const GroupCardCon = tw.div`
    flex flex-col bg-white h-auto min-w-[24rem] w-full p-4 rounded-lg drop-shadow-lg
`

export const GroupPerformStatusBox = tw.div`
    h-full w-1/3 flex items-center text-gray-700
`
export const GroupPerformStatusLabel = tw.span`
    font-bold mr-1
`
export const GroupPerformStatusValue = tw.span`
`

export const OptionIcon = tw(ThreeDotsVertical)`
    h-full
`

export const GroupMasterMarketerInfoBox = tw.div`
    flex items-center mt-1
    text-gray-800 
`
export const GroupMasterMarketerInfoIconContainer = tw.div`
    flex flex-center h-5 p-1 text-gray-500
`
export const TelIcon = tw(TelephoneFill)`
    h-full
`

export const EmailIcon = tw(Email)`
    h-full
`

export const PlusIcon = tw(Plus)`
    h-8
`

export const GroupMarketerStatus = tw.span`
    text-sm text-gray-600
`

export const GroupDropdownMenuContainer = tw.div`
relative flex flex-center py-1 h-full z-30
hover:text-gray-800 
duration-200 cursor-pointer
`
export const GroupDropdownMenuBox = tw.div`
absolute flex flex-col w-28 left-6 top-6 bg-white drop-shadow-md
${(props) => (props.menuIsOpen ? "visible z-30" : "invisible")}
`
export const GroupDropdownMenu = tw.button`
w-full py-2 text-base
text-gray-800
bg-white hover:bg-gray-100
`

export const GroupMarketersAvatarContainer = tw.div`
w-10 h-10 rounded-full overflow-hidden mr-1
`
export const GroupMarketersAvatarCount = tw.span`
ml-2 w-8 h-8 rounded-full bg-slate-200 flex justify-center items-center
`

export const GroupInviteContainer = tw(MyPageInputModalLayout)`
    max-md:w-screen md:w-[45rem]
    h-[60vh]
`
export const GroupInviteSearchBox = tw.div`
    flex justify-between items-center px-1 mb-2
    bg-slate-100 border border-slate-200 rounded-lg
`

export const GroupInviteColumnBox = tw.div`
    sticky top-0
    flex items-center w-full py-1
    rounded-lg
    ${() => MyPageContentHeaderColor}
    [&>span]:text-white [&>span]:font-medium [&>span]:text-center
    [&>span]:max-sm:text-sm
`

export const GroupInviteMarketerList = tw.div`
    flex flex-col w-full
`
export const GroupInviteMarketerRow = tw.div`
flex items-center 
${() => MyPageContentRowHeight}
[&>div]:flex [&>div]:flex-center [&>div]:px-1
[&>div>span]:text-center [&>div>span]:w-full [&>div>span]:truncate [&>div>span]:max-sm:text-xs
border-b hover:bg-slate-100
`

export const GroupInviteButton = tw.button`
    flex flex-center w-full h-8
    rounded-lg text-white
    ${(props) => (props.invited ? "bg-gray-400 cursor-default" : "bg-nightblue-400 hover:bg-nightblue-500")}
`

export const GroupInviteControlContainer = tw.div`
    flex flex-col w-20 justify-start
`
export const GroupInviteControlButton = tw.button`
    flex flex-center w-full py-0.5 mb-1
    rounded bg-slate-200 hover:bg-slate-300
`

export const GroupMarketerLayout = tw.div`
    flex max-lg:flex-col lg:flex-row w-full h-full max-lg:px-2
`
export const GroupMarketerContentHead = tw.div`
    flex items-center flex-shrink-0 w-full
`
export const GroupMarketerListLayout = tw.div`
    flex flex-col w-full lg:h-full
`
export const GroupMarketerListContainer = tw.div`
    relative
    w-full lg:h-full
`
export const GroupMarketerDetailLayout = tw.div`
    flex flex-col flex-shrink-0 max-lg:w-full max-xl:min-w-[20rem] max-xl:w-80 xl:min-w-[20rem] xl:w-[20rem] h-full lg:mr-2
`
export const GroupMarketerDetailBox = tw.div`
    flex flex-col justify-between w-full h-full max-lg:h-fit max-lg:p-0 max-xl:p-3 xl:p-4
    drop-shadow
    rounded-lg bg-gradient-to-tr from-nightblue-200 to-nightblue-100 from-10%
`

export const GroupDetailTitleBox = tw.div`
    flex items-center w-full h-8 mb-2
    ${(props) => props.editState === EditStateTypes.WAIT && "justify-between"}
`
export const GroupDetailTitle = tw.span`
    max-lg:text-lg lg:text-xl font-bold text-nightblue-200 py-1
`
export const GroupDetailTitleInput = tw.input`
    px-2 py-1 w-full
    rounded-lg bg-nightblue-200 text-nightblue-800
`
export const GroupDetailTitleIconContainer = tw.div`
    flex flex-center h-8 p-1.5 ml-2
    rounded-lg 
    text-nightblue-200 lg:hover:bg-nightblue-500 duration-100
`

export const GroupDetailMasterMarketerBox = tw.div`
    flex flex-col p-4 pt-2 max-lg:mb-0 lg:mb-6
    rounded-lg bg-nightblue-700
`
export const GroupDetailMasterMarketerTitle = tw.span`
    text-nightblue-100 font-semibold text-lg py-2
`

export const GroupDetailMasterMarketerDetailBox = tw.div`
    flex w-full h-full
`
export const GroupDetailMasterMarketerAvatarBox = tw.div`
    flex flex-center aspect-square h-20
    rounded-full overflow-hidden
`
export const GroupDetailInfoBox = tw.div`
    flex flex-col w-full px-2
`
export const GroupDetailInfoLabel = tw.span`
    text-nightblue-600 mr-2
`
export const GroupDetailInfoValue = tw.span`
    text-nightblue-700 font-semibold
`

export const GroupDetailMarketerControlBox = tw.div`
    grid grid-cols-2 gap-4 mt-4
`
export const GroupDetailMarketerControlButton = tw.button`
    w-full py-2
    rounded-lg text-nightblue-600
    bg-blue-400/40 hover:bg-blue-400/60
`

export const GroupDetailExitGroupButton = tw.button`
    text-nightblue-700 px-2
`

export const GroupManagerContainer = tw(MyPageInputModalLayout)`
    max-md:w-screen md:w-[45rem]
    h-[60vh]
`
