import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { getBusinessTypes, getJobTypes } from "../../../apis/api/commons"
import { deleteMarketerCareers, updateMarketerCareers, updateUserDetail } from "../../../apis/api/users"
import { MyPageUserInput, MyPageUserInputCombo } from "../../../components/user/Inputs"
import { getUserMarketerCareerRequestData, getUserMarketerSignupRequestData } from "../../../services/users"
import UserMarketer from "../../../types/users/UserMarketer"
import UserMarketerCareer from "../../../types/users/UserMarketerCareer"
import { MarketerParticipantNames, MarketerParticipantTypes } from "../../../types/users/UserTypes"
import Utils from "../../../utils/Utils"
import {
    MyInfoDataContainer,
    MyInfoDataEditButton,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyPageDataGrid,
    MyPageDataControlBox,
    MyPageDataSubmitButton,
    MyPageDataAddButton,
    MyPageCareerGrid,
    MyPageCareerInfoBox,
    MyPageCareerTitle,
    MyPageCareerDelete,
    MyInfoDataTitleContainer,
} from "./MyInfoMainStyle"
import { EditStateTypes } from "../../../types/EditorTypes"

const Career = ({ career, jobTypes, businessTypes, deleteCareer, index, handleAddCareer }) => {
    const [jobType, setJobType] = useState(1)
    const [year, setYear] = useState(0)
    const [businessType, setBusinessType] = useState(1)
    // 기타 지역 정보 입력 하는 state 백엔드에서 jobType -1에 기타 만들어 주면 그거 선택시에 vsible 할 예정
    const [jobDetail, setJobDetail] = useState("")

    useEffect(() => {
        if (career !== undefined) {
            if (career.jobType !== undefined && career.jobType !== 0) {
                setJobType(career.jobType)
            } else if (career.jobType === null) {
                setJobType(null)
            } else {
                setJobType(Object.keys(jobTypes).length > 0 ? Object.keys(jobTypes)[0] : 1)
            }

            if (career.jobDetail !== null && career.jobDetail !== undefined) {
                setJobDetail(career.jobDetail)
            } else {
                setJobDetail(0)
            }

            if (career.careerYear !== null && career.careerYear !== undefined) {
                setYear(career.careerYear)
            } else {
                setYear(0)
            }

            if (career.businessType !== null && career.businessType !== undefined && career.businessType !== 0) {
                setBusinessType(career.businessType)
            } else {
                setBusinessType(Object.keys(businessTypes).length > 0 ? Object.keys(businessTypes)[0] : 1)
            }
        }
    }, [career])

    useEffect(() => {
        career.jobType = jobType
    }, [jobType])
    useEffect(() => {
        career.jobDetail = jobDetail
    }, [jobDetail])
    useEffect(() => {
        career.careerYear = year
    }, [year])
    useEffect(() => {
        career.businessType = businessType
    }, [businessType])

    const handleJobType = (value) => {
        setJobType(Number(value))
    }

    const handleYear = (value) => {
        setYear(value)
    }

    const handleBusinessType = (value) => {
        setBusinessType(value)
    }

    const handleDeleteCareer = () => {
        deleteCareer(career.id, index)
    }

    return (
        <div className="flex flex-col w-full h-full">
            <MyPageCareerInfoBox className="my-0">
                <MyPageCareerTitle>
                    {index === 0 && <span className="text-red-500 mr-1">*</span>}경력 {index + 1}
                </MyPageCareerTitle>
                {index === 0 ? (
                    <MyPageDataAddButton
                        className="bg-transparent"
                        onClick={() => {
                            handleAddCareer()
                        }}
                    >
                        경력 추가
                    </MyPageDataAddButton>
                ) : (
                    <MyPageCareerDelete
                        onClick={() => {
                            handleDeleteCareer()
                        }}
                    >
                        삭제
                    </MyPageCareerDelete>
                )}
                {/* <MyPageCareerDelete onClick={() => { handleDeleteCareer() }}>
                    삭제
                </MyPageCareerDelete> */}
            </MyPageCareerInfoBox>
            <MyPageCareerGrid>
                <MyPageUserInputCombo
                    label={`직종`}
                    optionKeys={[...Object.keys(jobTypes), -1]}
                    names={[...Object.values(jobTypes), "기타"]}
                    value={jobType}
                    onChange={(e) => {
                        handleJobType(e.target.value)
                    }}
                />

                <MyPageUserInput label={`경력 (년)`} type={"number"} data={year} setData={handleYear} />

                {/* <MyPageUserInputCombo
                    label={`회사 업종`} 
                    optionKeys={Object.keys(businessTypes)} 
                    names={Object.values(businessTypes)}
                    value={businessType}
                    onChange={(e)=>{handleBusinessType(e.target.value)}}
                /> */}

                <div className={`${jobType === -1 ? "visible" : "hidden"} duration-150`}>
                    <MyPageUserInput
                        label={`상세 직종`}
                        type={"text"}
                        data={jobDetail}
                        setData={setJobDetail}
                        maxLength={100}
                    />
                </div>
            </MyPageCareerGrid>
        </div>
    )
}

export default function MyInfoMarketerCareer({
    marketer,
    isApply = false,
    jobTypes,
    setJobTypes,
    businessTypes,
    setBusinessTypes,
    careers,
    setCareers,
    submitCareeres,
}) {
    useEffect(() => {
        getTypes()
    }, [])

    useEffect(() => {
        if (marketer !== null) {
            updateValue(marketer)
        }
    }, [marketer])

    const getTypes = () => {
        getJobTypes().then((response) => {
            setJobTypes(response)
        })
        getBusinessTypes().then((response) => {
            setBusinessTypes(response)
        })
    }

    const updateValue = (_marketer) => {
        // 경력은 1개가 필수로 있어야한다.
        if (_marketer.careers.length === 0) {
            setCareers([new UserMarketerCareer()])
        } else {
            setCareers(_marketer.careers)
        }
    }

    const handleAddCareer = () => {
        setCareers([...careers, new UserMarketerCareer()])
    }

    const deleteCareer = (index) => {
        let newCareers = [...careers.filter((c, i) => i !== index)]
        setCareers(newCareers)
    }

    const handleDeleteCareer = (careerID, index) => {
        if (confirm("경력을 삭제하시겠습니까?")) {
            if (careerID >= 0) {
                deleteMarketerCareers(careerID).then((response) => {
                    deleteCareer(index)
                })
            } else {
                deleteCareer(index)
            }
        }
    }

    // const submit = () => {
    //     const requestData = {
    //         careers: careers.map(career => getUserMarketerCareerRequestData(career))
    //     }
    //     updateMarketerCareers(requestData).then(response => {
    //         let careers = []
    //         response.data.map(careerResponse => {
    //             const _career = new UserMarketerCareer()
    //             _career.convertByResponse(careerResponse)
    //             careers.push(_career)
    //         })
    //         setCareers(careers)
    //         alert("수정되었습니다.")
    //     }).catch(error => {
    //         alert("실패했습니다.")
    //     })
    // }

    return (
        <MyInfoDataContainer className="mb-0">
            <MyInfoDataBox className="py-2">
                {careers.map((career, idx) => (
                    <Career
                        career={career}
                        jobTypes={jobTypes}
                        businessTypes={businessTypes}
                        deleteCareer={handleDeleteCareer}
                        index={idx}
                        key={idx}
                        handleAddCareer={handleAddCareer}
                    />
                ))}
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
