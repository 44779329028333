export const NavSize = {
    BIG: 1,
    SMALL: 0,
}

export const ItemCardType = {
    ITEM: 1,
    MAIN: 0,
}

export const PmsNavFolded = {
    FOLDED: true,
    UNFOLED: false,
}

export const PmsNavState = {
    STAFFNOTICE: -4,
    STAFFITEM: -3,
    STAFFUSER: -2,
    NONE: -1,
    HOME: 0,
    ITEM: 1,
    PERFORM: 2,
    PROFIL: 3,
    AD: 4,
    ADVERTISEMENT: 5,
    POINT: 6,
}

export const PmsOfficeNavState = {
    NONE: -1,
    PERFORM: 0,
    CAL: 1,
    MARKETER: 2,
    GROUP: 3,
    NOTICE: 4,
    PERFORM_COLUMN: 5,
    AD: 6,
    ADVERTISEMENT: 7,
    POINT: 8,
}

export const GroupMarketerState = {
    GROUPMASTER: 0,
    GROUPMEMBER: 1,
}

export const PerformCalcFoldType = {
    OPEN: 0,
    CLOSE: 1,
}

export const TextFormats = {
    TEXT: 0,
    NUMBER: 1,
    NUMBER_ONLY: 2,
    PRICE: 3,
    TEL: 4,
}

export const REGION_KEYS = [1, 2, 13, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
