import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"

// 카테고리 [월수]
// 차트
{
    /*
    {
        category: ['1월','2월','3월','4월','5월']
        name:"실적"
        data:[30, 40, 45, 50, 49]
    }
*/
}

export const BarChart = ({ barchartdata }) => {
    const [chartData, setChartData] = useState(null)

    // update state
    useEffect(() => {
        setChartData({
            options: {
                chart: {
                    id: "basic-bar",
                },
                xaxis: {
                    categories: barchartdata.category,
                },
            },
            series: [
                {
                    name: barchartdata.name,
                    data: barchartdata.data,
                },
            ],
        })
    }, [])

    if (chartData) {
        return (
            <div className="bg-white w-full h-full relative">
                <div className="w-full h-full absolute">
                    <ReactApexChart options={chartData.options} series={chartData.series} height="100%" type="bar" />
                </div>
            </div>
        )
    }
    return <div className="bg-white w-full h-full flex items-center justify-center">로딩중</div>
}

export const PieChart = ({ piechartdata }) => {
    const [chartData, setChartData] = useState(null)
    {
        /*
        {
            series: [44, 55, 41],               도넛 그래프 안에 들어갈 개수 퍼센트는 자동 생성
            labels: ['SCRM', '세일즈랑', '마커누'],  우측에 생성될 라벨 데이터
        }
    */
    }

    // update state
    useEffect(() => {
        setChartData({
            series: piechartdata.series,
            options: {
                chart: {
                    type: "donut",
                },
                labels: piechartdata.labels,
                responsive: [
                    {
                        breakpoint: "100%",
                        options: {
                            chart: {
                                height: "100%",
                            },
                        },
                    },
                ],
            },
        })
    }, [])

    if (chartData) {
        return (
            <div className="bg-white w-full h-full relative">
                <div className="w-11/12 h-fit absolute-center ">
                    <ReactApexChart options={chartData.options} series={chartData.series} type="donut" />
                </div>
            </div>
        )
    }

    return <div className="bg-white w-full h-full flex items-center justify-center">로딩중</div>
}
