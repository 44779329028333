import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { sendAuthSMS, sendAuthSubmitSMS, updateUser } from "../../../apis/api/users"
import { getUserData } from "../../../apis/services/users"
import {
    MyPageUserInput,
    MyPageUserInputCombo,
    UserSMSAuthRequestButton,
    UserSMSAuthSubmitButton,
} from "../../../components/user/Inputs"
import { PasswordValidate } from "../../../components/user/PasswordValidate"
import { useFocus } from "../../../hooks/useFocus"
import { getUserSignupRequestData } from "../../../services/users"
import { EditStateTypes } from "../../../types/EditorTypes"
import User from "../../../types/users/User"
import { UserTypes } from "../../../types/users/UserTypes"
import Utils from "../../../utils/Utils"
import {
    MyInfoDataContainer,
    MyInfoDataEditButton,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyPageDataGrid,
    MyPageDataControlBox,
    MyPageDataSubmitButton,
} from "./MyInfoMainStyle"
import { TextFormats } from "../../../types/DataEnum"

export default function MyInfoUser({ user }) {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [nickname, setNickname] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [tel, setTel] = useState("")
    const [birthday, setBirthday] = useState("")
    const [passwordValidate, setPasswordValidate] = useState(false)

    const [isAlertItem, setAlertItem] = useState(true)

    const [password1State, setPassword1State] = useState(EditStateTypes.WAIT)
    const [password2State, setPassword2State] = useState(EditStateTypes.WAIT)
    const [passwordFocusRef, isPasswordFocus] = useFocus()

    const [telSubmitCode, setTelSubmitCode] = useState("")

    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [telSubmitState, setTelSubmitState] = useState(EditStateTypes.DISABLED)
    const [telSubmitTime, setTelSubmitTime] = useState(0)
    const [telSubmitTimeText, setTelSubmitTimeText] = useState("")

    useEffect(() => {
        updateValue(user)
    }, [user])

    useEffect(() => {
        if (telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.WAIT) {
            setTelSubmitState(EditStateTypes.DISABLED)
            setTelSubmitCode("")
        }
        if (telAuthState === EditStateTypes.PENDING) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
    }, [telAuthState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelSubmitState(EditStateTypes.DISABLED)
        }

        let newTel = tel
        newTel = tel.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
        if (newTel.length > 12) {
            newTel = newTel.slice(0, 12)
        }
        setTel(newTel)
    }, [tel])

    useEffect(() => {
        if (telSubmitState !== EditStateTypes.DISABLED) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
        if (telSubmitCode.length > 20) {
            setTelSubmitCode(telSubmitCode.slice(0, 20))
        }
    }, [telSubmitCode])

    useEffect(() => {
        const time = setInterval(() => {
            const second = telSubmitTime - 1
            setTelSubmitTimeText(`${Math.floor(second / 60)} : ${second % 60}`)
            setTelSubmitTime(second)
        }, 1000)

        if (telSubmitTime === 0 || telAuthState !== EditStateTypes.PENDING) {
            clearInterval(time)
        }

        return () => clearInterval(time)
    }, [telSubmitTime])

    const updateValue = (_user) => {
        setEmail(_user.email)
        setName(_user.name)
        setNickname(_user.nickname)
        setTel(_user.tel)
        setBirthday(_user.birthday)
        setPassword1("")
        setPassword2("")
        setAlertItem(_user.isAlertItem)
    }

    const telAuth = async () => {
        if (
            !Utils.isStringNullOrEmpty(tel) &&
            (telAuthState === EditStateTypes.WAIT || telAuthState === EditStateTypes.PENDING)
        ) {
            const requestData = { phone_number: tel }

            // 전화번호로 SMS 전송
            await sendAuthSMS(requestData)

            setTelAuthState(EditStateTypes.PENDING)
            setTelSubmitState(EditStateTypes.WAIT)
            setTelSubmitTime(60 * 5)
        }
        if (telAuthState === EditStateTypes.DONE) {
            setTelAuthState(EditStateTypes.WAIT)
        }
    }

    const telSubmit = async () => {
        if (!Utils.isStringNullOrEmpty(telSubmitCode)) {
            const requestData = {
                phone_number: tel,
                auth_number: telSubmitCode,
            }

            // 전화번호로 SMS 전송
            const response = await sendAuthSubmitSMS(requestData)
            const smsResult = response.result

            if (smsResult) {
                setTelAuthState(EditStateTypes.DONE)
                setTelSubmitState(EditStateTypes.DONE)
            } else {
                setTelAuthState(EditStateTypes.PENDING)
                setTelSubmitState(EditStateTypes.FAILED)
            }
        }
    }

    const submit = () => {
        if (
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            Utils.isStringNullOrEmpty(tel) ||
            (!Utils.isStringNullOrEmpty(password1) && (!passwordValidate || password1 !== password2))
        ) {
            if (password1 !== password2) {
                alert("비밀번호가 일치하지 않습니다.")
                return
            } else if (Utils.isStringNullOrEmpty(tel)) {
                alert("전화번호를 입력 해주시기 바랍니다.")
                return
            } else {
                alert("입력된 내용을 확인 해주시기 바랍니다.")
                return
            }
        }

        const requestData = getUserSignupRequestData(
            email,
            name,
            nickname,
            password1,
            tel,
            UserTypes.BRAND,
            isAlertItem,
        )["user"]

        updateUser(requestData)
            .then((response) => {
                if (response.data.id >= 0) {
                    const _user = new User()
                    _user.convertByResponse(response.data)
                    updateValue(_user)
                    alert("수정되었습니다.")
                } else {
                    alert("실패했습니다.")
                }
            })
            .catch(() => {
                alert("실패했습니다.")
            })
    }

    return (
        <MyInfoDataContainer>
            <MyInfoDataTitleBox>
                <MyInfoDataTitle>
                    {user.userType === UserTypes.BRAND ? (
                        <React.Fragment>{"담당자 정보"}</React.Fragment>
                    ) : (
                        <React.Fragment>{"나의 정보"}</React.Fragment>
                    )}
                </MyInfoDataTitle>
                {/* <MyInfoDataEditButton>
                    정보 수정
                </MyInfoDataEditButton> */}
            </MyInfoDataTitleBox>

            <MyInfoDataBox>
                <MyPageDataGrid>
                    <MyPageUserInput label={"이메일"} type={"text"} data={email} readOnly />
                    <MyPageUserInput label={"이름"} type={"text"} data={name} readOnly />
                    <MyPageUserInput
                        label={"전화번호"}
                        type={"text"}
                        data={tel}
                        isTel={true}
                        setData={setTel}
                        maxLength={20}
                    >
                        <UserSMSAuthRequestButton telAuthState={telAuthState} telAuth={telAuth} />
                    </MyPageUserInput>
                    <MyPageUserInput
                        label={"전화번호 인증"}
                        type={"text"}
                        data={telSubmitCode}
                        setData={setTelSubmitCode}
                        disabled={telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE}
                    >
                        <UserSMSAuthSubmitButton
                            telAuthState={telAuthState}
                            telSubmitState={telSubmitState}
                            telSubmitTimeText={telSubmitTimeText}
                            telSubmit={telSubmit}
                        />
                    </MyPageUserInput>
                    {/* <MyPageUserInput
                        label={"생년월일"}
                        type={"date"}
                        data={birthday}
                        readOnly
                    /> */}

                    <MyPageUserInput
                        label={"비밀번호 변경"}
                        type={"password"}
                        data={password1}
                        setData={setPassword1}
                        ref={passwordFocusRef}
                        placeholder={"비밀번호 변경 시에만 입력해주세요."}
                        maxLength={40}
                    >
                        {isPasswordFocus && (
                            <PasswordValidate top={"-7.5rem"} passWord={password1} setValidate={setPasswordValidate} />
                        )}
                    </MyPageUserInput>
                    <MyPageUserInput
                        label={"비밀번호 확인"}
                        type={"password"}
                        data={password2}
                        setData={setPassword2}
                        placeholder={"비밀번호 변경 시에만 입력해주세요."}
                        maxLength={40}
                    />
                    {user.userType === UserTypes.MARKETER && (
                        <MyPageUserInput
                            label={"적립 포인트"}
                            type={"text"}
                            data={Utils.textFormat(user.recommanderPoint, TextFormats.NUMBER)}
                            readOnly
                        />
                    )}
                    <MyPageUserInputCombo
                        label={"상품 관련 알림 수신"}
                        optionKeys={["true", "false"]}
                        names={["수신", "미수신"]}
                        value={isAlertItem ? "true" : "false"}
                        onChange={(e) => {
                            setAlertItem(e.target.value == "true")
                        }}
                        showDefault={false}
                    />
                </MyPageDataGrid>
                <MyPageDataControlBox>
                    <MyPageDataSubmitButton
                        onClick={() => {
                            submit()
                        }}
                    >
                        저장
                    </MyPageDataSubmitButton>
                </MyPageDataControlBox>
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
