import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import {Send} from "@styled-icons/bootstrap/Send"
import { PerformValueInputLayout } from "./MyPagePerformStyle";
import { CheckBox, CheckBoxOutlineBlank, RadioButtonChecked, RadioButtonUnchecked } from "styled-icons/material";

export const PerformDateInputLayout = tw(PerformValueInputLayout)`
    max-md:h-auto md:h-auto lg:h-auto
    max-md:w-auto md:w-auto
    max-md:max-h-full md:max-h-[75vh] lg:min-h-fit lg:max-h-[75vh]
`

export const PerformDateInputBox = tw.div`
    flex justify-center w-full flex-shrink-0 px-2 max-sm:py-2 sm:py-4 my-2
    rounded-lg border border-slate-200 bg-slate-100
`

export const PerformDateMemoInputBox = tw.div`
    flex w-full h-36 p-2 my-2
    rounded-lg border border-slate-200 bg-white
`

export const DateControlBox = tw.div`
    flex justify-end w-full
`

export const PerformDateTimeSubTitle = tw.span`
    text-gray-800 font-semibold pl-1
`

export const PerformDateTimeMemoInput = tw.textarea`
    w-full h-36 p-2 my-2
    rounded-lg border border-slate-200 
    bg-white disabled:bg-slate-100
    duration-300
    outline-none focus:outline-none focus:border-nightblue-500
    resize-none scroll-transparent
`