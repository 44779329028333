import React, { useEffect, useState } from 'react';
import  tw from 'tailwind-styled-components'

const ContractContainer = tw.div`
    relative
    flex justify-center w-full h-full
`
const ContractIFrame = tw.iframe`
    absolute
    max-sm:w-[200%] max-sm:h-[195%] max-sm:scale-50 max-sm:translate-y-[-25%]
    max-lg:w-[130%] max-lg:h-[125%] max-lg:scale-75 max-lg:translate-y-[-12.5%]
    lg:w-[100%] lg:h-[100%]
    overflow-x-auto overflow-y-auto scroll-transparent scroll-overlay scroll-touch
    ${(props) => props.isOverflowAuto && "border-t border-transparent"}
`

export function Contract({ htmlContract }) {
    const [isOverflowAuto, setOverflowAuto] = useState(false) // 아이폰에서 이게 없으면 스크롤이 안되서 0.1초 후에 border-t로 변화를 줘서 스크롤이 가능하게한다.

    useEffect(()=>{
        window.onpageshow = (e) => {
            if (e.persisted || (window.performance && window.performance.navigation.type === 2)) {
                setOverflowAuto(false)
            }
        }
    }, [])

    useEffect(()=>{
        setOverflowAuto(false)

        setTimeout(()=>{
            setOverflowAuto(true)
        }, 100)
    }, [htmlContract])

    return (
        <ContractContainer>
            <ContractIFrame
                title="contract"
                srcDoc={htmlContract}
                frameBorder="0"
                isOverflowAuto={isOverflowAuto}
            />
        </ContractContainer>
    );
}