import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { getRegionTypes } from "../../../../apis/api/commons"
import { reviveUser, updateUserDetail } from "../../../../apis/api/users"
import { getUserBrandRequestData } from "../../../../services/users"
import { MarketerStatusTypeColors } from "../../../../types/brandItems/BrandItemTypes"
import { EditStateTypeColors, EditStateTypes } from "../../../../types/EditorTypes"
import Brand from "../../../../types/users/Brand"
import UserBrand from "../../../../types/users/UserBrand"
import { userDetailModalStore, marketerContractViewer } from "../../../../zustand/modalstore"
import {
    MarketerCellButton,
    BuisnessImageButton,
    MarketerStatSelect,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
} from "../../marketer/MyPageMarketerStyle"
import { getBuisnessImage } from "../../../../apis/api/users/index"
import { getImageFile } from "../../../../apis/api/files"
import { StaffUserReviveButton } from "./StaffUserStyle"

const MarketerCellWidth = {
    Index: "min-w-[6rem]",
    Name: "min-w-[4rem]",
    Email: "min-w-[16rem] w-full",
    Tel: "min-w-[6rem]",
    Keyword: "min-w-[16rem] w-full",
    Date: "min-w-[14rem]",
    PointMain: "min-w-[10rem]",
    PointSub: "min-w-[10rem]",
    Description: "min-w-[12rem]",
    Created: "min-w-[8rem]",
}

const RowMinWidth = "min-w-[102rem] w-full"

const MarketerTableRow = ({ recommander, openModal }) => {
    // const recommander = new Brand()
    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    const handleUserClick = () => {
        setUserId(recommander.user.id)
        setUserShow(true)
    }

    const handleClick = () => {
        openModal(recommander)
    }

    return (
        <MarketerTableDataRow
            className={`${RowMinWidth} cursor-pointer`}
            onClick={() => {
                handleClick()
            }}
        >
            <MarketerTableData className={`${MarketerCellWidth.Index}`}>{recommander.id}</MarketerTableData>
            <MarketerTableData
                className={`${MarketerCellWidth.Name} hover:bg-slate-100 rounded-lg cursor-pointer`}
                onClick={(e) => {
                    e.stopPropagation()
                    handleUserClick()
                }}
            >
                {recommander.user.name}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>{recommander.user.email}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>{recommander.user.tel}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Keyword}`}>{recommander.keyword}</MarketerTableData>
            <MarketerTableData
                className={`${MarketerCellWidth.Date}`}
            >{`${recommander.dateStart} ~ ${recommander.dateEnd}`}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.PointMain}`}>{recommander.pointMain}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.PointSub}`}>{recommander.pointSub}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Description}`}>
                {recommander.description}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Created}`}>{recommander.created}</MarketerTableData>
        </MarketerTableDataRow>
    )
}

const Columns = () => {
    return (
        <MarketerTableHeadRow className={`sticky top-0 z-10 ${RowMinWidth}`}>
            <MarketerTableHead className={`${MarketerCellWidth.Index}`}>NO</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Name}`}>이름</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Email}`}>이메일</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Tel}`}>전화번호</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Keyword}`}>검색 키워드</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Date}`}>적용기간</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.PointMain}`}>적립포인트 (받는사람)</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.PointSub}`}>적립포인트 (하는사람)</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Description}`}>설명</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Created}`}>생성 날짜</MarketerTableHead>
        </MarketerTableHeadRow>
    )
}

export default function StaffUserPointTable({ recommanders, openModal }) {
    return (
        <MarketerTableCon>
            <Columns />

            {recommanders.map((recommander, idx) => (
                <MarketerTableRow recommander={recommander} openModal={openModal} key={idx} />
            ))}
            {/* <MarketerTableBody>
            </MarketerTableBody> */}
        </MarketerTableCon>
    )
}
