import React, { useState, useEffect } from "react"
import QRCode from "qrcode.react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "styled-icons/boxicons-regular"
import { Share } from "styled-icons/fluentui-system-filled"
import tw from "tailwind-styled-components"
import Utils from "../../../../utils/Utils"

const OpenAgreementContainer = tw.div`
    flex justify-center max-sm:items-center py-10 sm:px-14 px-12    
    max-sm:w-screen max-sm:max-h-screen sm:w-[24rem]
    bg-nightblue-300 rounded-lg
    max-sm:overflow-hidden
`
const QRCodeContainer = tw.div`
    p-6
    flex items-center justify-center w-full aspect-square
    rounded-md bg-white 
    overflow-hidden drop-shadow-lg
`
const AgreementLayout = tw.div`
    flex flex-col items-center
    w-full 
`
const AgreeLinkGridContainer = tw.div`
    mt-6
    w-full  
    grid grid-flow-col grid-cols-2 gap-6
`
const AgreementLinkContainer = tw.div`
    flex-col flex items-center py-2  
    bg-white rounded-md 
    drop-shadow-lg
    cursor-pointer
`
const LogoContainer = tw.div`
    flex justify-center items-center mt-4
    w-full flex-1 
    font-semibold text-white 
    drop-shadow-lg
`

export default function MyPageOpenAgreement({ link }) {
    const [urlData, setUrlData] = useState()
    const [urlMarketerData, setUrlMarketerData] = useState()

    useEffect(() => {
        const baseUrl = Utils.getBaseUrl()
        // setUrlData(`${baseUrl}agreement/submit/${link}`)
        setUrlData(`${baseUrl}/${link}`)
        // setUrlMarketerData(`${baseUrl}agreement/submitmarketer/${link}`)
    }, [link])

    return (
        <OpenAgreementContainer>
            <AgreementLayout
                className={`${Utils.isStringNullOrEmpty(link) ? "opacity-0" : "opacity-100"} duration-500`}
            >
                <QRCodeContainer>
                    <QRCode style={{ width: "100%", height: "auto", aspectRatio: "1/1" }} value={urlData} />
                </QRCodeContainer>
                <AgreeLinkGridContainer>
                    <AgreementLinkContainer>
                        <CopyToClipboard
                            className="Toram"
                            text={urlData}
                            onCopy={() => alert("링크가 클립보드에 복사되었습니다.")}
                        >
                            <div>
                                <div className="p-2">
                                    <Share />
                                </div>
                                <span className="text-sm">링크 복사</span>
                            </div>
                        </CopyToClipboard>
                    </AgreementLinkContainer>
                    <AgreementLinkContainer onClick={() => window.open(urlData)}>
                        <div>
                            <div className="p-2">
                                <Link />
                            </div>
                            <span className="text-sm">링크 이동</span>
                        </div>
                    </AgreementLinkContainer>
                </AgreeLinkGridContainer>
                <LogoContainer>S.MARANG</LogoContainer>
            </AgreementLayout>
        </OpenAgreementContainer>
    )
}
