import tw from "tailwind-styled-components"

export const MyInfoDeleteContainer = tw.div`
    flex flex-col max-md:w-screen md:w-[48rem] max-md:h-full md:max-h-[90vh] p-4
    md:rounded-lg bg-white
    overflow-y-auto scroll-transparent scroll-overlay
`

export const MyInfoDeleteHead = tw.div`
    flex items-center
`
export const MyInfoDeleteTitle = tw.span`
    flex flex-center flex-shrink-0 h-full
    text-lg font-semibold mb-4
`

export const MyInfoDeleteContents = tw.div`
    flex flex-col w-full px-0
    overflow-y-auto scroll-transparent
`

export const MyInfoDeleteSectionBox = tw.div`
    flex flex-col p-4 mb-4
    rounded-lg border
    ${(props) => (props.active ? "bg-blue-100 border-blue-300" : "bg-slate-100 border-slate-300")}
    duration-300
`
export const MyInfoDeleteSectionTitle = tw.span`
    font-semibold
    ${(props) => (props.active ? "text-blue-700" : "text-slate-700")}
    duration-300
`
export const MyInfoDeleteSectionDescription = tw.p`
    mt-2 mb-4
    text-slate-600 max-sm:text-sm sm:text-base
`
export const MyInfoDeleteSectionSubTitle = tw.span`
    flex items-center mt-4 mb-1
    text-black max-sm:text-sm sm:text-base
`
export const MyInfoDeleteSectionControlBox = tw.div`
    flex items-center w-full mt-4
`
export const MyInfoDeleteSectionConfirmButton = tw.button`
    flex flex-center px-4 py-1 ml-auto
    rounded-md bg-blue-500
    text-white font-medium text-sm
`

export const MyInfoDeleteOptionItemBox = tw.div`
    flex items-center mb-1
    text-slate-800
    cursor-pointer
`
export const MyInfoDeleteOptionItemName = tw.span`
    font-medium
`
export const MyInfoDeleteOptionEtc = tw.textarea`
    w-[calc(100%-1rem)] h-16 p-2 ml-auto
    rounded-lg border bg-white
    outline-none resize-none
    focus:border-blue-500
    duration-300
`

export const MyInfoDeleteControlBox = tw.div`
    flex justify-end items-center w-full
`
export const MyInfoDeleteButton = tw.button`
    px-3 py-1
    rounded-md border
    bg-red-100 hover:bg-red-300 border-red-400
    disabled:bg-slate-100 disabled:hover:bg-slate-100 disabled:border-slate-400
    text-red-700 disabled:text-slate-600
`
export const MyInfoDeleteNotMessage = tw.span`
    text-red-600
`
