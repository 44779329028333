import React, { useCallback, useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { ItemLayout, ItemList, ItemInnerTop, ItemSortButton } from "../MyPageAdItemStyle"
import { Pagination } from "../../../../components/Pagination"
import { Link, useNavigate } from "react-router-dom"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { ItemSearchTypes } from "../../../../types/brandItems/BrandItemTypes"
import { ItemOrders, ItemOrderValues, MYPAGE_PAGE_LENGTH } from "../../../../constants/ConstItem"
import { getAdItemListAuth } from "../../../../apis/api/boardItems"
import BrandItemList from "../../../../types/brandItems/BrandItemList"
import { ModalContainer } from "../../../../components/ModalContainer"
import { useUserStore } from "../../../../zustand/store"
import { PerformTableSearch, PerformTableSearchButton, PerformTableSearchIcon } from "../../perform/MyPagePerformStyle"
import { MarketerToggleButton } from "../../marketer/MyPageMarketerStyle"
import { PaginationStyles } from "../../../../types/StyleTypes"
import SortSwitch from "../../../../components/SortSwitch"
import { SortTypes } from "../../../../types/EditorTypes"
import AccessRules from "../../../../rules/AccessRules"
import { UserTypes } from "../../../../types/users/UserTypes"
import { BrandValidate } from "../../../../components/user/BrandValidate"
import { ImageBase64 } from "../../../../components/ImageContainer"

import Utils from "../../../../utils/Utils"
import FileUtils from "../../../../utils/FileUtils"
import { ItemListStatusFilterButton, ItemSearchBox } from "../brand/MyPageItemBrandStyle"
import { RESPONSIVE_WIDTH } from "../../../../constants/ConstUI"
import Advertisement from "../../../../types/commons/Advertisement"
import { AdvertisementCategories, AdvertisementCategoryNames } from "../../../../types/commons/CommonTypes"
import { getAdvertisementsAuth } from "../../../../apis/api/commons"
import AdvertisementList from "../../../../types/commons/AdvertisementList"
import MyPageAdvertisementWrite from "./MyPageAdvertisementWrite"

export default function MyPageAdvertisement() {
    const { user, userType, userDetail, actions } = useUserStore()
    const navigate = useNavigate()

    const [itemOrder, setItemOrder] = useState(
        user.userType === UserTypes.MARKETER ? ItemOrders.ITEM_REGISTED : ItemOrders.CREATED,
    )
    const [itemSortType, setItemSortType] = useState(SortTypes.DESC)
    const [advertiseCategory, setAdvertiseCategory] = useState(AdvertisementCategories.NONE)

    const [pageSize, setPageSize] = useState(10)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [items, setItems] = useState([])

    const [searchValue, setSearchValue] = useState("") // 입력 중인 검색 값
    const [searchKeyword, setSearchKeyword] = useState("") // 검색한 혹은 검색할 값 값

    const [selectedItem, setSelectedItem] = useState(new Advertisement())
    const [showWriteModel, setShowWriteModal] = useState(false)

    useEffect(() => {
        if (!showWriteModel) {
            updateAdvertisementList(pageIndex, ItemOrderValues[itemOrder], itemSortType, advertiseCategory)
        }
    }, [showWriteModel])

    const updateAdvertisementList = async (page, ordering, sortType, category, searchValue = null) => {
        let search = ""
        if (searchValue !== null) {
            // 검색을 한 경우
            setSearchKeyword(searchValue)
            search = searchValue
        } else {
            // 리스트 업데이트 시 입력중인 키워드를 검색에 사용할 키워드로 돌려놓는다.
            setSearchValue(searchKeyword)
            search = searchKeyword
        }
        // setItems([])

        ordering = Utils.getOrdering(ordering, sortType)
        const response = await getAdvertisementsAuth(page, pageSize, ordering, search, category)
        const brandItemList = new AdvertisementList()
        brandItemList.convertByResponse(response)

        setItemCount(brandItemList.count)
        setItems(brandItemList.items)
    }

    const itemUpdate = (item) => {
        setSelectedItem(item)
        setShowWriteModal(true)
    }

    const itemCreate = () => {
        const newItem = new Advertisement()

        setSelectedItem(newItem)
        setShowWriteModal(true)
    }

    const handleSearch = () => {
        updateAdvertisementList(1, ItemOrderValues[itemOrder], itemSortType, advertiseCategory, searchValue)
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handlePageIndex = (page) => {
        setPageIndex(page)
        updateAdvertisementList(page, ItemOrderValues[itemOrder], itemSortType, advertiseCategory)
        window.scrollTo({ top: 0 })
    }

    const handleItemOrder = (order) => {
        setPageIndex(1)
        setItemOrder(order)
        updateAdvertisementList(1, ItemOrderValues[order], itemSortType, advertiseCategory)
    }

    const handleAdvertiseCategory = (filter) => {
        setPageIndex(1)
        setAdvertiseCategory(filter)
        updateAdvertisementList(1, ItemOrderValues[itemOrder], itemSortType, filter)
    }

    const handleSort = (sortType) => {
        setItemSortType(sortType)
        updateAdvertisementList(1, ItemOrderValues[itemOrder], sortType, advertiseCategory)
    }

    return (
        <ItemLayout>
            <ItemInnerTop>
                <div className="text-2xl max-lg:px-1">광고관리</div>
                <div className="flex max-lg:flex-col lg:flex-row justify-between items-center w-full max-lg:px-1 py-2 mt-4">
                    <div className="flex max-lg:w-full max-lg:flex-col lg:flex-row lg:space-x-2">
                        <div className="flex items-center space-x-2">
                            <ItemSearchBox>
                                <PerformTableSearch
                                    type="text"
                                    placeholder="광고 제목"
                                    onKeyPress={handleSearchKeyPress}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                    }}
                                />
                                <PerformTableSearchButton
                                    onClick={() => {
                                        handleSearch()
                                    }}
                                >
                                    <PerformTableSearchIcon />
                                </PerformTableSearchButton>
                            </ItemSearchBox>

                            <ItemSortButton>
                                <SortSwitch sortType={itemSortType} setSortType={handleSort} />
                            </ItemSortButton>
                        </div>
                        <div className="flex max-lg:mt-2 max-lg:w-full">
                            <div className="max-lg:flex lg:hidden max-lg:h-8 lg:h-9 ml-auto">
                                {AccessRules.brandItemManagement() && (
                                    // <MarketerToggleButton marketerstate={0} onClick={()=>{itemCreate()}}>상품 등록</MarketerToggleButton>
                                    <ItemAdd itemCreate={itemCreate} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="max-lg:hidden lg:flex max-lg:justify-between max-lg:w-full h-full max-lg:mt-2">
                        {/* <AdvertisementPagination
                            pageIndex={pageIndex}
                            handlePageIndex={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                        /> */}
                        {AccessRules.brandItemManagement() && (
                            // <MarketerToggleButton marketerstate={0} onClick={()=>{itemCreate()}}>상품 등록</MarketerToggleButton>
                            <ItemAdd itemCreate={itemCreate} />
                        )}
                    </div>
                </div>
                {user.userType !== UserTypes.MARKETER && (
                    <div className="flex items-end w-full h-11 p-0.5 space-x-2 max-sm:overflow-x-auto scroll-transparent">
                        {/* 아이템 상태 필터 */}
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleAdvertiseCategory(null)
                            }}
                            isSelected={advertiseCategory === null}
                        >
                            전체
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleAdvertiseCategory(AdvertisementCategories.MAIN_BANNER)
                            }}
                            isSelected={advertiseCategory === AdvertisementCategories.MAIN_BANNER}
                        >
                            메인 배너
                        </ItemListStatusFilterButton>
                    </div>
                )}
                {/* <Link to={'create'}>
                </Link> */}
            </ItemInnerTop>
            <ItemList>
                {items.map((advertisement, i) => (
                    <ItemListCard
                        key={i}
                        user={user}
                        advertisement={advertisement}
                        itemUpdate={itemUpdate}
                        // showItemContract={handleShowItemContract}
                        // showItemDetailMobile={handleShowItemDetailMobile}
                        // deleteItemMarketer={handleDeleteItemMarketer}
                        // setShowAgreementModal={setShowAgreementModal}
                        // openNoticeModal={handleOpenNoticeModal}
                        // openAgreementList={handleOpenAgreementList}
                    />
                ))}
            </ItemList>

            {/* 모바일용 페이지네이션 */}
            <div className="flex justify-center w-full my-8">
                <BrandItemPagination
                    pageIndex={pageIndex}
                    handlePageIndex={handlePageIndex}
                    pageSize={pageSize}
                    itemCount={itemCount}
                />
            </div>
            {/* <ItemInnerBottom>
                <Pagination/>
            </ItemInnerBottom> */}
            <ModalContainer
                show={showWriteModel}
                setShow={setShowWriteModal}
                isBlur={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.SM}
            >
                <MyPageAdvertisementWrite item={selectedItem} setShowModal={setShowWriteModal} />
            </ModalContainer>
        </ItemLayout>
    )
}

const BrandItemPagination = ({ pageIndex, handlePageIndex, pageSize, itemCount }) => {
    return (
        <Pagination
            page={pageIndex}
            setPage={handlePageIndex}
            pageSize={pageSize}
            itemCount={itemCount}
            maxPageButtons={MYPAGE_PAGE_LENGTH}
            styleType={PaginationStyles.TextButtons}
        />
    )
}

// eslint-disable-next-line react/display-name
const ItemAdd = React.forwardRef(({ isActiveBrand, hasSignBrand, itemAddValidateIsOpen, itemCreate }, ref) => {
    return (
        <div className="relative flex h-full ml-auto" ref={ref}>
            <MarketerToggleButton
                className="max-lg:w-28 lg:w-32 max-lg:text-sm"
                marketerstate={0}
                onClick={() => {
                    itemCreate()
                }}
            >
                상품 등록
            </MarketerToggleButton>
            <BrandValidate
                isActiveBrand={isActiveBrand}
                hasSignBrand={hasSignBrand}
                isShow={itemAddValidateIsOpen}
                top={"2.5rem"}
            />
        </div>
    )
})

const ItemListCard = ({ advertisement, itemUpdate }) => {
    const [imageUrl, setImageUrl] = useState("")

    useEffect(() => {
        getImageUrl()
    }, [advertisement])

    const getImageUrl = () => {
        let imageUrls = []
        if (!Utils.isStringNullOrEmpty(advertisement.image1URL)) {
            imageUrls.push(advertisement.image1URL)
        }
        if (!Utils.isStringNullOrEmpty(advertisement.image2URL)) {
            imageUrls.push(advertisement.image2URL)
        }
        if (!Utils.isStringNullOrEmpty(advertisement.image3URL)) {
            imageUrls.push(advertisement.image3URL)
        }
        if (!Utils.isStringNullOrEmpty(advertisement.image4URL)) {
            imageUrls.push(advertisement.image4URL)
        }

        if (imageUrls.length === 0) {
            return
        }

        const randomIndex = Math.floor(Math.random() * imageUrls.length)

        setImageUrl(FileUtils.getMediaFileURL(imageUrls[randomIndex]))
    }

    const handleUpdate = () => {
        itemUpdate(advertisement)
    }

    return (
        <div
            className="flex w-full h-24 p-2 bg-white hover:bg-slate-50"
            onClick={() => {
                handleUpdate()
            }}
        >
            {/* 정보 */}
            <div className="flex flex-col w-full h-full">
                <span className="h-6 font-semibold line-clamp-1">{advertisement.title}</span>
                <span className="h-5 font-light text-sm text-slate-600 line-clamp-1">{advertisement.content}</span>
                <div className="flex items-center w-full mt-auto text-sm [&>.label]:text-slate-600 [&>.label]:mr-1">
                    <span className="px-1.5 py-0.5 mr-2 rounded bg-nightblue-500 text-white">
                        {AdvertisementCategoryNames[advertisement.category]}
                    </span>
                    <span className="label">광고 기간</span>
                    <span className="mr-2">{`${advertisement.dateStart} ~ ${advertisement.dateEnd}`}</span>
                    <span className="label">등록일</span>
                    <span className="mr-2">{advertisement.created}</span>
                </div>
            </div>
            {/* 이미지 */}
            <div className="flex flex-shrink-0 h-full aspect-[3/2] bg-slate-300 rounded-md">
                <ImageBase64 imageURL={imageUrl} />
            </div>
        </div>
    )
}
ItemListCard.propTypes = {
    advertisement: Advertisement.isRequired,
}
