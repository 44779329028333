import BackendResponse, { getBackendResponse } from "../../../constants/BackendResponse"
import BrandItemContract from "../../../types/brandItems/BrandItemContract"
import BrandItemContractList from "../../../types/brandItems/BrandItemContractList"
import { BrandItemMarketerOrderOptionTypes } from "../../../types/brandItems/BrandItemOption"
import PerformCalculateGroupList from "../../../types/brandItems/calculates/PerformCalculateGroupList"
import BrandItemMarketerGroupList from "../../../types/brandItems/marketers/BrandItemMarketerGroupList"
import BrandItemMarketerInviteList from "../../../types/brandItems/marketers/BrandItemMarketerInviteList"
import BrandItemMarketerList from "../../../types/brandItems/marketers/BrandItemMarketerList"
import BrandItemNotice from "../../../types/brandItems/notices/BrandItemNotice"
import BrandItemNoticeList from "../../../types/brandItems/notices/BrandItemNoticeList"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import PerformColumnStatusComment from "../../../types/brandItems/performColumns/PerformColumnStatusComment"
import PerformColumnStatusCommentList from "../../../types/brandItems/performColumns/PerformColumnStatusCommentList"
import Perform from "../../../types/brandItems/performValues/Perform"
import PerformList from "../../../types/brandItems/performValues/PerformList"
import UserMarketerInviteList from "../../../types/users/UserMarketerInviteList"
import { EditStateTypes, SortTypes } from "../../../types/EditorTypes"
import Utils from "../../../utils/Utils"
import { authInstance, defaultInstance, defaultOrAuthInstance } from "../../utils"
import BrandItemPersonalAgreementPaper from "../../../types/brandItems/BrandItemPersonalAgreementPaper"
import BrandItemMarketerPersonalAgreementList from "../../../types/brandItems/BrandItemMarketerPersonalAgreementList"
import BrandItemMarketerPersonalAgreement from "../../../types/brandItems/BrandItemMarketerPersonalAgreement"
import { AdCategoryTypes } from "../../../types/brandItems/BrandItemTypes"

const getBrandItemListParams = (
    page,
    pageSize,
    ordering,
    itemSalesFilter = null,
    marketerFilter = null,
    search = "",
    searchType = null,
) => {
    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }

    if (itemSalesFilter !== null && itemSalesFilter.length > 0) {
        params["item_sales_type"] = itemSalesFilter.join(",")
    }
    if (marketerFilter !== null && marketerFilter.length > 0) {
        params["marketer_type"] = marketerFilter.join(",")
    }
    if (search !== "" && searchType !== null) {
        params["search"] = search
        params[searchType] = true
    }

    return params
}
const getAdItemListParams = (
    page,
    pageSize,
    ordering,
    categoryFilter = null,
    sigunguFilter = null,
    search = "",
    searchType = null,
) => {
    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }

    if (categoryFilter !== null && categoryFilter != AdCategoryTypes.CATEGORY0) {
        params["category"] = categoryFilter
    }
    if (sigunguFilter !== null) {
        params["sigungu"] = sigunguFilter
    }
    if (search !== "" && searchType !== null) {
        params["search"] = search
        params[searchType] = true
    }

    return params
}

export const getBrandItemList = async (
    page,
    pageSize,
    ordering,
    itemSalesFilter = null,
    marketerFilter = null,
    search = "",
    searchType = null,
) => {
    const params = getBrandItemListParams(page, pageSize, ordering, itemSalesFilter, marketerFilter, search, searchType)
    const response = await defaultInstance.get(`/api/brand_item/item_list/`, {
        params: params,
    })

    return response.data
}

export const getAdItemList = async (
    page,
    pageSize,
    ordering,
    categoryFilter = null,
    sigunguFilter = null,
    search = "",
    searchType = null,
) => {
    const params = getAdItemListParams(page, pageSize, ordering, categoryFilter, sigunguFilter, search, searchType)
    const response = await defaultInstance.get(`/api/brand_item/item_list_ad/`, {
        params: params,
    })

    return response.data
}

export const getAdItemListHome = async () => {
    const response = await defaultInstance.get(`/api/brand_item/item_list_home_ad/`, {})

    return response.data
}

export const getBrandItemListAuth = async (
    page,
    pageSize,
    ordering,
    search = "",
    searchType = null,
    marketerStatusFilter,
    editStatusFilter = null,
) => {
    let params = {
        ...getBrandItemListParams(page, pageSize, ordering, null, null, search, searchType),
        marketer_status_filter: marketerStatusFilter,
    }
    if (editStatusFilter != EditStateTypes.NONE) {
        params["edit_state_type"] = editStatusFilter
    }

    const response = await authInstance.get(`/api/brand_item/item_list_auth/`, {
        params: params,
    })

    return response.data
}

export const getBrandItemListAuthSimple = async (page, pageSize) => {
    let params = {
        ...getBrandItemListParams(page, pageSize),
    }
    const response = await authInstance.get(`/api/brand_item/item_list_auth/`, {
        params: params,
    })
    return response.data
}

export const getAdItemListAuth = async (
    page,
    pageSize,
    ordering,
    search = "",
    searchType = null,
    marketerStatusFilter,
    editStatusFilter = null,
    adTypeFilter = null,
) => {
    let params = {
        ...getBrandItemListParams(page, pageSize, ordering, null, null, search, searchType),
        marketer_status_filter: marketerStatusFilter,
    }
    if (editStatusFilter != EditStateTypes.NONE) {
        params["edit_state_type"] = editStatusFilter
    }
    if (adTypeFilter != null) {
        params["ad_type"] = adTypeFilter
    }

    const response = await authInstance.get(`/api/brand_item/item_list_auth_ad/`, {
        params: params,
    })

    return response.data
}

export const getAdItemListAuthSimple = async (page, pageSize) => {
    let params = {
        ...getBrandItemListParams(page, pageSize),
    }
    const response = await authInstance.get(`/api/brand_item/item_list_auth_ad/`, {
        params: params,
    })
    return response.data
}

export const getBrandItemListAuthCount = async () => {
    const response = await authInstance.get(`/api/brand_item/item_list_auth_count/`)
    return response.data
}

export const getAdItemListAuthCount = async () => {
    const response = await authInstance.get(`/api/brand_item/item_list_auth_count_ad/`)
    return response.data
}

export const getBrandItemView = async (pageID) => {
    const response = await defaultOrAuthInstance.get(`/api/brand_item/item_view/${pageID}`)

    return response.data
}

export const getBrandItemTempView = async (pageID) => {
    const response = await defaultOrAuthInstance.get(`/api/brand_item/item_temp_view/${pageID}`)

    return response.data
}

export const createBrandItem = async (request) => {
    let bResult = false
    await authInstance
        .post(`/api/brand_item/item_create/`, request)
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const updateBrandItem = async (itemID, request) => {
    let bResult = false
    await authInstance
        .put(`/api/brand_item/item_update/${itemID}`, request)
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const deleteBrandItem = async (itemID, request) => {
    let bResult = false
    await authInstance
        .delete(`/api/brand_item/item_update/${itemID}`, request)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const acceptBrandItemUpdate = async (itemID) => {
    let bResult = false
    await authInstance
        .put(`/api/brand_item/item_temp_view/${itemID}`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const deleteBrandItemTemp = async (itemID) => {
    let bResult = false
    await authInstance
        .delete(`/api/brand_item/item_temp_view/${itemID}`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const updateBrandItemState = async (itemID, itemStateType) => {
    let bResult = false
    await authInstance
        .put(`/api/brand_item/item_state_view/${itemID}`, {
            edit_state_type: itemStateType,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

// isActiveMarketer = 마케터(유저)가 정지 상태인지 여부
export const getItemUserAccess = async (itemID, isActiveMarketer) => {
    let bResult = false
    await authInstance
        .post(`/api/brand_item/item_access/`, {
            item_id: itemID,
            is_active_marketer: isActiveMarketer,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getItemPerformColumns = async (itemID) => {
    let columns = []

    if (!(itemID >= 0)) {
        return columns
    }

    await authInstance
        .get(`api/brand_item/perform_column_list/`, { params: { item_id: itemID } })
        .then(({ data }) => {
            data.map((response) => {
                const column = new PerformColumn()
                column.convertByResponse(response)
                columns.push(column)
            })
        })
        .catch((error) => {})

    return columns
}

export const createPerformColumn = async (itemID) => {
    let bResult = false

    await authInstance
        .post(`/api/brand_item/perform_column_create/`, {
            item_id: itemID,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getCountPerformColumnValue = async (columnID) => {
    let result = 0

    await authInstance
        .post(`/api/brand_item/perform_column_value_count/`, {
            column_id: columnID,
        })
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}

export const updatePerformColumn = async (column) => {
    let bResult = false

    await authInstance
        .put(`/api/brand_item/perform_column_view/${column.id}`, column.convertToRequest())
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const deletePerformColumn = async (columnID) => {
    let bResult = false

    await authInstance
        .delete(`/api/brand_item/perform_column_view/${columnID}`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const updatePerformColumnForm = async (columnForm) => {
    let bResult = false

    await authInstance
        .post(`/api/brand_item/perform_column_form_write/`, columnForm.convertToRequest())
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const deletePerformColumnForm = async (columnForm) => {
    let bResult = false

    await authInstance
        .delete(`/api/brand_item/perform_column_form_write/`, {
            params: {
                id: columnForm.id,
                column_id: columnForm.columnID,
            },
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getItemPerformColumnOrders = async (itemID) => {
    let orders = {}

    if (!(itemID >= 0)) {
        return orders
    }

    await authInstance
        .get(`api/brand_item/perform_column_order/`, { params: { item_id: itemID } })
        .then(({ data }) => {
            const json = data["order_json"]
            orders = JSON.parse(json)
        })
        .catch((error) => {})

    return orders
}

export const updateItemPerformColumnOrders = async (itemID, columnOrders) => {
    let bResult = false

    await authInstance
        .put(`/api/brand_item/perform_column_order/`, {
            item_id: itemID,
            order_json: JSON.stringify(columnOrders),
        })
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const createItemPerform = async (itemID, marketerID) => {
    let perform = new Perform()

    if (!(itemID >= 0)) {
        return perform
    }

    await authInstance
        .post(`api/brand_item/perform_create/`, {
            item_id: itemID,
            marketer_id: marketerID,
        })
        .then(({ data }) => {
            perform.convertByResponse(data)
        })
        .catch((error) => {})

    return perform
}

export const updateItemPerform = async (performID, itemID, marketerID) => {
    let bResult = false

    await authInstance
        .put(`/api/brand_item/perform_view/${performID}`, { item_id: itemID, marketer_id: marketerID })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}
export const deleteItemPerform = async (performID) => {
    let bResult = false

    await authInstance
        .delete(`/api/brand_item/perform_view/${performID}`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getItemPerforms = async (itemID, page, pageSize, filterDic) => {
    let performList = new PerformList()

    if (!(itemID >= 0)) {
        return performList
    }

    await authInstance
        .post(`api/brand_item/perform_list/?page=${page}&page_size=${pageSize}`, {
            params: {
                page: page,
                page_size: pageSize,
            },
            item_id: itemID,
            filter: filterDic,
        })
        .then(({ data }) => {
            performList.convertByResponse(data)
        })
        .catch((error) => {})

    return performList
}

export const getItemPerformValue = async (columnID, performID) => {
    let response = {}

    let params = {
        column_id: columnID,
        perform_id: performID,
    }

    await authInstance
        .get(`api/brand_item/perform_form/`, {
            params: params,
        })
        .then(({ data }) => {
            response = data
        })

    return response
}
export const updateItemPerformValue = async (columnID, performID, value) => {
    let bResult = false

    let request = {
        column_id: columnID,
        perform_id: performID,
        ...value,
    }

    await authInstance
        .post(`api/brand_item/perform_form/`, request)
        .then(({ data }) => {
            if (data.column_id >= 0) {
                bResult = true
            } else if (Array.isArray(data)) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error, columnID, performID, value)
        })

    return bResult
}
export const batchUpdateItemPerformValue = async (updateDic, performIDs) => {
    let bResult = false

    await authInstance
        .put(`api/brand_item/perform_list/`, {
            update: updateDic,
            perform_ids: performIDs,
        })
        .then(({ data }) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const deleteItemPerformValue = async (columnID, performID, value) => {
    let bResult = false

    let request = {
        column_id: columnID,
        perform_id: performID,
        ...value,
    }

    await authInstance
        .delete(`api/brand_item/perform_form/`, { params: request })
        .then(({ data }) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const getPerformStatusComments = async (columnID, performID, statusIDs, pageIndex) => {
    let commentList = new PerformColumnStatusCommentList()

    if (!(columnID >= 0) || !(performID >= 0)) {
        return commentList
    }

    let params = {
        column_id: columnID,
        perform_id: performID,
        page: pageIndex,
    }

    if (statusIDs !== null && statusIDs.length > 0) {
        params["status_ids"] = statusIDs.join(",")
    }

    const { data } = await authInstance.get(`api/brand_item/perform_status_comment/`, {
        params: params,
    })
    commentList.convertByResponse(data)

    return commentList
}

export const updatePerformStatusComments = async (columnID, performID, statusID, comment) => {
    let bResult = false

    if (!(statusID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/perform_status_comment/`, {
            column_id: columnID,
            perform_id: performID,
            status_id: statusID,
            comment: comment,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const getPerformStatusConstraintList = async (itemID, columnID, statusID) => {
    let constraintIDs = []

    let params = {
        column_id: columnID,
        item_id: itemID,
        status_id: statusID,
    }

    const { data } = await authInstance.get(`api/brand_item/perform_status_constraint_list/`, {
        params: params,
    })
    constraintIDs = data

    return constraintIDs
}
export const getPerformStatusConstraint = async (itemID, columnID, statusMainID, statusSubID) => {
    let result = true

    let params = {
        column_id: columnID,
        item_id: itemID,
        status_main_id: statusMainID,
        status_sub_id: statusSubID,
    }

    const { data } = await authInstance.get(`api/brand_item/perform_status_constraint/`, {
        params: params,
    })
    result = data

    return result
}
export const updatePerformStatusConstraint = async (itemID, columnID, statusMainID, statusSubID, isUpdate) => {
    let result = true

    let params = {
        column_id: columnID,
        item_id: itemID,
        status_main_id: statusMainID,
        status_sub_id: statusSubID,
        is_update: isUpdate,
    }

    const { data } = await authInstance.post(`api/brand_item/perform_status_constraint/`, params)
    result = data

    return result
}

export const updatePerformColumnFileEditState = async (columnID, performID, editState, files = []) => {
    // editState에 따라 처리가 다르다. DONE : 확정, DISABLED : 임시 상태인 파일 삭제
    let bResult = false

    if (!(columnID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/perform_column_file_edit_state/`, {
            column_id: columnID,
            perform_id: performID,
            edit_state: editState,
            files: files,
        })
        .then(({ data }) => {
            bResult = true
        })

    return bResult
}

export const getCalculateGroups = async (itemID, pageSize, ordering) => {
    let calculateGroups = new PerformCalculateGroupList()

    if (!(itemID >= 0)) {
        return calculateGroups
    }

    const { data } = await authInstance.get(`api/brand_item/calc_group_create/`, {
        params: { item_id: itemID, page_size: pageSize, ordering: ordering },
    })

    calculateGroups.convertByResponse(data)

    return calculateGroups
}

export const addCalcGroup = async (itemID, name) => {
    let bResult = false

    if (!(itemID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/calc_group_create/`, {
            item_id: itemID,
            name: name,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const updateCalcGroup = async (groupID, name) => {
    let bResult = false

    if (!(groupID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/calc_group_view/${groupID}`, {
            name: name,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const deleteCalcGroup = async (groupID) => {
    let bResult = BackendResponse.NOT_FOUND

    if (!(groupID >= 0)) {
        return bResult
    }

    await authInstance
        .delete(`api/brand_item/calc_group_view/${groupID}`)
        .then(() => {
            bResult = BackendResponse.SUCCESS
        })
        .catch((error) => {
            const responseMessage = error.response.data
            bResult = getBackendResponse(responseMessage)
        })

    return bResult
}

export const addCalc = async (groupID, calcName, calcDescription) => {
    let bResult = false

    if (!(groupID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/calc_create/`, {
            group_id: groupID,
            name: calcName,
            description: calcDescription,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const updateCalc = async (calcID, name, description) => {
    let bResult = false

    if (!(calcID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/calc_view/${calcID}`, {
            name: name,
            description: description,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const deleteCalc = async (calcID) => {
    let bResult = BackendResponse.NOT_FOUND

    if (!(calcID >= 0)) {
        return bResult
    }
    let responseMessage = ""

    await authInstance
        .delete(`api/brand_item/calc_view/${calcID}`)
        .then(({ data }) => {
            responseMessage = data
        })
        .catch((error) => {
            responseMessage = error.response.data
        })
    bResult = getBackendResponse(responseMessage)

    return bResult
}

export const addPerformCalc = async (performIDs, calcID) => {
    let bResult = BackendResponse.INVALID

    if (!(calcID >= 0) || !(performIDs.length >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/calc_relation/`, {
            perform_ids: performIDs,
            calc_id: calcID,
        })
        .then(({ data }) => {
            bResult = BackendResponse.SUCCESS
        })
        .catch((error) => {
            console.log(error)
            const responseMessage = error.response.data
            bResult = getBackendResponse(responseMessage)
        })

    return bResult
}

export const getPerformCalc = async (performID = -1, calcID = -1) => {
    let result = null

    if (!(calcID >= 0) && !(performID >= 0)) {
        return result
    }

    let request = {}
    if (performID >= 0) {
        request = { perform_id: performID }
        result = new PerformCalculateGroupList()
    }
    if (calcID >= 0) {
        request = { calc_id: calcID }
        result = new PerformList()
    }

    await authInstance
        .post(`api/brand_item/calc_relation/`, request)
        .then(({ data }) => {
            result.convertByResponse(data)
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}

export const registItemMarketer = async (itemID, itemMarketerParentID, marketerName) => {
    let bResult = BackendResponse.NOT_FOUND
    console.log(itemID, itemMarketerParentID, marketerName)

    if (!(itemID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_create/`, {
            item_id: itemID,
            parent_id: itemMarketerParentID,
            marketer_name: marketerName,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = BackendResponse.SUCCESS
            }
        })
        .catch((error) => {
            const responseMessage = error.response.data.non_field_errors[0]
            bResult = getBackendResponse(responseMessage)
        })

    return bResult
}

export const deleteItemMarketer = async (itemID, marketerID) => {
    let bResult = false

    if (!(itemID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_delete/`, {
            item_id: itemID,
            marketer_id: marketerID,
        })
        .then(({ data }) => {
            bResult = true
            if (process.env.NODE_ENV !== "production") {
                alert("item marketer 삭제")
            }
        })
        .catch((error) => {})

    return bResult
}

export const getItemMarketers = async (
    itemID,
    pageIndex,
    pageSize,
    search = "",
    searchType,
    searchStatus = -1,
    searchSuspendStatus = -1,
    order = "-id",
) => {
    let result = new BrandItemMarketerList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        page: pageIndex,
        page_size: pageSize,
        ordering: order,
    }
    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }
    if (searchStatus >= 0) {
        params["marketer_status_type"] = searchStatus
    }
    if (searchSuspendStatus >= 0) {
        params["marketer_suspend_status_type"] = searchSuspendStatus
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_create/`, { params: params })
    result.convertByResponse(data)

    return result
}
export const getItemMarketerByUser = async (itemID, userID) => {
    let result = new BrandItemMarketerList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        user_id: userID,
        parent_marketer_user_id: userID,
        page_size: 1,
        ordering: "id",
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_create/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const updateItemMarketerStatus = async (itemMarketerID, statusID) => {
    let bResult = false

    if (!(itemMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_view/${itemMarketerID}`, {
            marketer_status_type: statusID,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })

    return bResult
}
export const updateItemMarketerName = async (itemMarketerID, marketerName) => {
    let bResult = false

    if (!(itemMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_view/${itemMarketerID}`, {
            marketer_name: marketerName,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}
export const updateItemMarketerSuspendStatus = async (itemMarketerID, statusID) => {
    let bResult = false

    if (!(itemMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_view/${itemMarketerID}`, {
            marketer_suspend_status_type: statusID,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}
export const updateItemMarketerInviteStatePermission = async (itemMarketerID, isInvitePermission) => {
    let bResult = false

    if (!(itemMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_view/${itemMarketerID}`, {
            is_invite_permission: isInvitePermission,
        })
        .then(({ data }) => {
            if (data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const getItemMarketerGroups = async (itemID, pageIndex, pageSize, search = "") => {
    let result = new BrandItemMarketerGroupList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        page: pageIndex,
        page_size: pageSize,
    }
    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_create/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const addItemMarketerGroup = async (itemID) => {
    let bResult = false

    if (!(itemID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_group_create/`, {
            item_id: itemID,
        })
        .then(({ data }) => {
            if (data.item_id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const updateItemMarketerGroup = async (groupID, name) => {
    let bResult = false

    if (!(groupID >= 0) || Utils.isStringNullOrEmpty(name)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_group_view/${groupID}`, {
            name: name,
        })
        .then(({ data }) => {
            if (data.item_id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {})

    return bResult
}

export const getItemMarketerInvites = async (itemID, groupID, pageIndex, pageSize, search = "", parentUserID) => {
    let result = new UserMarketerInviteList()

    if (!(itemID >= 0) || !(groupID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        group_id: groupID,
        parent_user_id: parentUserID,
        page: pageIndex,
        page_size: pageSize,
    }
    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_invite_create/`, { params: params })

    result.convertByResponse(data)

    return result
}

export const getItemMarketerInviteHistories = async (
    itemID,
    groupID,
    pageIndex,
    pageSize,
    search = "",
    parentUserID,
) => {
    let result = new UserMarketerInviteList()

    if (!(itemID >= 0) || !(groupID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        group_id: groupID,
        parent_user_id: parentUserID,
        page: pageIndex,
        page_size: pageSize,
    }
    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_invite_sender/`, { params: params })

    result.convertByResponse(data)

    return result
}

export const addItemMarketerInvite = async (itemID, sendMarketerID, receiveMarketerID, memo = "") => {
    let bResult = false

    if (!(itemID >= 0) || !(sendMarketerID >= 0) || !(receiveMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_group_invite_create/`, {
            item_id: itemID,
            marketer_sender_id: sendMarketerID,
            marketer_receiver_id: receiveMarketerID,
            memo: memo,
        })
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const updateItemMarketerInvite = async (inviteID, memo = "", isChecked = false) => {
    let bResult = false

    if (!(inviteID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_group_invite_view/${inviteID}`, {
            memo: memo,
            is_checked: isChecked,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

// export const deleteItemMarketerInvite = async (inviteID) => {
//     let bResult = false

//     if (!(inviteID >= 0)) {
//         return bResult
//     }

//     await authInstance
//         .delete(`api/brand_item/marketer_group_invite_view/${inviteID}`)
//         .then((response) => {
//             bResult = true
//         })
//         .catch((error) => {
//             console.log(error)
//         })

//     return bResult
// }

export const addItemMarketerManualInvite = async (itemID, sendMarketerID, manualName, manualTel, memo = "") => {
    let bResult = false

    if (!(itemID >= 0) || !(sendMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_group_invite_manual_create/`, {
            item_id: itemID,
            marketer_sender_id: sendMarketerID,
            manual_name: manualName,
            manual_tel: manualTel,
            memo: memo,
        })
        .then((response) => {
            if (response.data.id >= 0) {
                bResult = true
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const updateItemMarketerInviteState = async (inviteID, inviteStatusType) => {
    let bResult = false

    if (!(inviteID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_group_invite_update/`, {
            invite_id: inviteID,
            invite_status_type: inviteStatusType,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const deleteItemMarketerInvite = async (inviteID) => {
    let bResult = false

    if (!(inviteID >= 0)) {
        return bResult
    }

    await authInstance
        .delete(`api/brand_item/marketer_group_invite_update/`, {
            params: {
                invite_id: inviteID,
            },
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const getItemMarketerInviteds = async (
    itemID,
    userID,
    inviteStatusType,
    pageIndex,
    pageSize,
    search,
    ordering = SortTypes.ASC,
) => {
    let result = new BrandItemMarketerInviteList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        user_id: userID,
        invite_status_type: inviteStatusType,
        page: pageIndex,
        page_size: pageSize,
        search: search,
        ordering: ordering === SortTypes.ASC ? "id" : "-id",
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_invited_list/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const getItemMarketerManualInviteds = async (itemID, parentUserID) => {
    let result = new BrandItemMarketerInviteList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        parent_user_id: parentUserID,
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_invite_sender_manual/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const getItemMarketerGroupMarketers = async (itemID, groupID, pageIndex, pageSize) => {
    let result = new BrandItemMarketerList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        group_id: groupID,
        page: pageIndex,
        page_size: pageSize,
    }

    const { data } = await authInstance.get(`api/brand_item/marketer_group_relation/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const setItemMarketerGroupAppoint = async (itemID, appointMarketerID) => {
    let bResult = false

    if (!(itemID >= 0) || !(appointMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .put(`api/brand_item/marketer_group_relation/`, {
            item_id: itemID,
            appoint_marketer_id: appointMarketerID,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const updateItemMarketerRoot = async (itemMarketerID) => {
    let bResult = false

    if (!(itemMarketerID >= 0)) {
        return bResult
    }

    await authInstance
        .post(`api/brand_item/marketer_root/`, {
            item_marketer_id: itemMarketerID,
        })
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const getItemNotices = async (itemID, pageIndex, pageSize, search) => {
    let result = new BrandItemNoticeList()

    if (!(itemID >= 0)) {
        return result
    }

    let params = {
        item_id: itemID,
        page: pageIndex,
        page_size: pageSize,
        search: search,
    }

    const { data } = await authInstance.get(`api/brand_item/notice_list/`, { params: params })
    result.convertByResponse(data)

    return result
}

export const getItemNotice = async (noticeID) => {
    let result = new BrandItemNotice()

    if (!(noticeID >= 0)) {
        return result
    }

    const { data } = await authInstance.get(`api/brand_item/notice_view/${noticeID}`)
    result.convertByResponse(data)

    return result
}

export const addItemNotice = async (itemID, board) => {
    let result = new BrandItemNotice()

    if (!(itemID >= 0)) {
        return result
    }

    const { data } = await authInstance.post(`api/brand_item/notice_create/`, {
        item_id: itemID,
        board: board.convertToRequest(),
    })
    result.convertByResponse(data)

    return result
}

export const updateItemNotice = async (noticeID, board) => {
    let result = new BrandItemNotice()

    if (!(noticeID >= 0)) {
        return result
    }

    const { data } = await authInstance.put(`api/brand_item/notice_view/${noticeID}`, {
        board: board.convertToRequest(),
        notice_id: noticeID,
    })
    result.convertByResponse(data)

    return result
}

export const deleteItemNotice = async (noticeID) => {
    let bResult = false

    if (!(noticeID >= 0)) {
        return bResult
    }

    await authInstance
        .delete(`api/brand_item/notice_view/${noticeID}`)
        .then(() => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const getItemContractThumbs = async (itemID, pageIndex, pageSize) => {
    let result = new BrandItemContractList()

    if (!(itemID >= 0)) {
        return result
    }

    const { data } = await authInstance.get(`api/brand_item/item_contract_list/`, {
        params: {
            item_id: itemID,
            page: pageIndex,
            page_size: pageSize,
        },
    })
    result.convertByResponse(data)

    return result
}

// rev값을 안주면 자동으로 최신 rev로 가져온다. (현재 rev는 하나만 사용으로 롤백 rev는 기본값 0)
// export const getItemContractImages = async (itemID, rev=0) => {
//     let result = []

//     const {data} = await authInstance.get(`api/brand_item/item_contract_view/`, {params:{
//         item_id:itemID,
//         rev: rev,
//     }})
//     result = data
//     return result
// }
export const getItemContractHtml = async (itemID) => {
    let result = []

    const { data } = await authInstance.get(`api/brand_item/item_contract_view/`, {
        params: {
            item_id: itemID,
        },
    })
    result = data
    return result
}

// 아이템과 마케터간의 계약서를 base64배열로 반환
export const getItemContractMarketerImages = async (itemID, marketerID) => {
    let result = []
    const { data } = await authInstance.get(`api/brand_item/item_contract_marketer/`, {
        params: {
            item_id: itemID,
            marketer_id: marketerID,
        },
    })
    result = data
    return result
}

export const updateItemContractMarketer = async (itemID, signID) => {
    let bResult = true
    await authInstance
        .post(`api/brand_item/item_contract_marketer/`, {
            item_id: itemID,
            sign_id: signID,
        })
        .then((response) => {
            //
        })
        .catch((error) => {
            console.log(error)
            bResult = false
        })
    return bResult
}

export const importItemPerformColumn = async (itemID, importItemID) => {
    let bResult = true
    await authInstance
        .post(`api/brand_item/perform_column_import/`, {
            item_id: itemID,
            import_item_id: importItemID,
        })
        .then((response) => {
            //
        })
        .catch((error) => {
            console.log(error)
            throw error
        })

    return bResult
}

export const getItemPersonalAgreementPaper = async (itemID) => {
    let result = new BrandItemPersonalAgreementPaper()

    const { data } = await authInstance.get(`api/brand_item/personal_agreement_paper/${itemID}`)
    result.convertByResponse(data)

    return result
}

export const updatePersonalAgreementPaper = async (itemID, content) => {
    let bResult = true
    await authInstance
        .post(`api/brand_item/personal_agreement_paper/`, {
            item_id: itemID,
            content: content,
        })
        .then((response) => {
            //
        })
        .catch((error) => {
            console.log(error)
            bResult = false
        })

    return bResult
}

export const getBrandItemMarketerPersonalAgreements = async (page, pageSize, itemMarketerID = -1) => {
    let params = {
        page: page,
        page_size: pageSize,
    }

    if (itemMarketerID >= 0) {
        params["item_marketer_id"] = itemMarketerID
    }

    let result = new BrandItemMarketerPersonalAgreementList()

    const { data } = await authInstance.get(`api/brand_item/marketer_personal_agreement/`, { params })
    result.convertByResponse(data)

    return result
}

export const getBrandItemMarketerPersonalAgreement = async (id) => {
    let result = new BrandItemMarketerPersonalAgreement()

    const { data } = await authInstance.get(`api/brand_item/marketer_personal_agreement/${id}/`)
    result.convertByResponse(data)

    return result
}

export const createBrandItemMarketerPersonalAgreement = async (data) => {
    let bResult = true

    await authInstance
        .post(`api/brand_item/marketer_personal_agreement/`, data)
        .then((response) => {
            //
        })
        .catch((error) => {
            console.log(error)
            bResult = false
        })

    return bResult
}

export const updateBrandItemMarketerPersonalAgreement = async (id, data) => {
    let bResult = true

    await authInstance
        .put(`api/brand_item/marketer_personal_agreement/${id}/`, data)
        .then((response) => {
            //
        })
        .catch((error) => {
            console.log(error)
            bResult = false
        })

    return bResult
}

export const getBrandItemMarketerPersoanlAgreementLink = async (itemMarketerID, name = null, tel = null) => {
    let link = ""

    await authInstance
        .post("/api/brand_item/marketer_personal_agreement_generate/", {
            item_marketer_id: itemMarketerID,
            name: name,
            tel: tel,
        })
        .then((response) => {
            link = response.data
        })

    return link
}

export const getItemMarketerID = async (userID = -1, itemID = -1) => {
    let itemMarketerID = -1

    const response = await authInstance.post("/api/brand_item/item_marketer_id/", {
        user_id: userID,
        item_id: itemID,
    })
    itemMarketerID = response.data

    return itemMarketerID
}
