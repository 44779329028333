import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const makeItRain = keyframes`
  from { opacity: 0; }
  50% { opacity: 1; }
  to { transform: translateY(400px); }
`;

const ConfettiPiece = styled.div`
  position: absolute;
  width: 5px;
  height: 20px;
  background: #ffd300;
  top: 0;
  opacity: 0;
  animation: ${makeItRain} 1000ms infinite ease-out;
  
  &:nth-child(odd) { background: #7431e8; }
  &:nth-child(even) { z-index: 1; }
  &:nth-child(4n) { width: 5px; height: 12px; animation-duration: 2000ms; }
  &:nth-child(3n) { width: 3px; height: 10px; animation-duration: 2500ms; animation-delay: 1000ms; }
  &:nth-child(4n-7) { background: red; }
  
  ${[...Array(13)].map((_, i) => css`
    &:nth-child(${i + 1}) {
      left: ${Math.random() * 100}%;
      transform: rotate(${Math.random() * 360}deg);
      animation-delay: ${Math.random() * 1000}ms;
      animation-duration: ${1000 + Math.random() * 1000}ms;
    }
  `)}
`;

const ConfettiContainer = styled.div`
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
`;

const Confetti = () => (
  <ConfettiContainer>
    {[...Array(13)].map((_, i) => (
      <ConfettiPiece key={i} />
    ))}
  </ConfettiContainer>
);

export default Confetti;
