import React, { Fragment } from "react"
import tw from "tailwind-styled-components"
import { logout } from "../../services/users"
import { NavSize } from "../../types/DataEnum"
import { useUserStore } from "../../zustand/store"
import UserAvatar from "../user/UserAvatar"
import { useNavigate } from "react-router-dom"

const ProfilDropdownLink = tw.a`
    no-underline text-base py-3 flex align-middle justify-center
    duration-200 cursor-pointer hover:bg-gray-50
`

const ProfilDropdownCon = tw.div`
relative inline-block group z-10
${(props) => (props.navsize === NavSize.BIG ? "h-10" : "h-7")};
duration-200
`
export const ProfileImageContainer = tw.div`
bg-gray-100 rounded-full hover:shadow-lg hover:shadow-gray-500/50  overflow-hidden
${(props) => (props.navsize === NavSize.BIG ? "h-10" : "h-7")}
aspect-square
duration-200
`

export function UserProfileDropDown(props) {
    const navigate = useNavigate()
    const { user, actions } = useUserStore()

    const handleLogout = () => {
        if (!window.location.pathname.includes("/items/preview")) {
            actions.setLogout()
            logout()
        }
    }

    const handleNavigateLogout = () => {
        navigate("/")
    }

    const handleNavigateMyPage = () => {
        navigate("/mypage/item")
    }
    const handleNavigateMyinfo = () => {
        navigate("/mypage/myinfo")
    }

    return (
        <ProfilDropdownCon navsize={NavSize.SMALL}>
            <ProfileImageContainer navsize={NavSize.SMALL}>
                <UserAvatar user={user} />
            </ProfileImageContainer>
            <div className="hidden group-hover:block">
                <div className="absolute z-20 font-normal bg-white drop-shadow-xl w-36 right-1 rounded-sm border">
                    <ProfilDropdownLink
                        onClick={() => {
                            handleNavigateMyPage()
                        }}
                    >
                        마이 페이지
                    </ProfilDropdownLink>
                    <ProfilDropdownLink
                        onClick={() => {
                            handleNavigateMyinfo()
                        }}
                    >
                        프로필 관리
                    </ProfilDropdownLink>
                    <ProfilDropdownLink
                        onClick={() => {
                            handleLogout()
                            handleNavigateLogout()
                        }}
                    >
                        로그아웃
                    </ProfilDropdownLink>
                </div>
            </div>
        </ProfilDropdownCon>
    )
}
