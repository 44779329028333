import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import { ListUl } from '@styled-icons/bootstrap/ListUl'
import { BoxMaxMin } from '../../../layouts/Box'
import { ContentContainer } from '../MyPageMainStyle'

export const NoticeDetailContainer = tw(ContentContainer)`
items-center max-lg:px-0 py-0
bg-slate-100
`

export const NoticeDetailLayout = tw(BoxMaxMin)`
flex flex-col items-center w-full min-h-[90vh] px-10
bg-white rounded-2xl drop-shadow
`

export const NoticeDetailHead = tw.div`
    w-full border-b border-gray-300 px-4
    flex flex-col
`

export const ReturnIcon = tw(ListUl)`
    h-9
`

export const NoticeDetailTitle = tw.span`
    pt-12 pb-6
    text-3xl h-28
`
export const NoticeDetailInfoRow = tw.div`
    flex justify-between items-center
`
export const NoticeDetailInfos = tw.div`
    flex place-items-center
`
export const NoticeDetailInfoBox = tw.div`
    flex items-center mr-2
`
export const NoticeDetailInfoLabel = tw.span`
    flex items-center mr-1
    font-medium
    text-gray-800
`
export const NoticeDetailInfoValue = tw.span`
    flex items-center w-24
    text-gray-600
`

export const ReturnButton = tw.button`
    w-12 h-12 hover:bg-gray-100 rounded-full duration-300
`

export const NoticeDetailTitlebottom = tw.div`
    w-full h-14 flex justify-between items-center text-lg
`


export const NoticeDetailBottom = tw.div`
    w-full h-24 flex justify-between items-center
`

export const NoticeEditButton = tw.button`
    bg-blue-400 text-blue-50 px-5 py-1 rounded-md hover:bg-blue-300
`

export const NoticeDeleteButton = tw.button`
    bg-red-400 text-red-50 px-5 py-1 rounded-md hover:bg-red-300
`

export const NoticeDetailBody = tw.div`
    w-full h-full p-4
    scroll-transparent scroll-overlay
`