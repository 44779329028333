export const TableHeaderList = [
    {
        'thtitle':'타이틀1',
        'thwidth':1,
        'colid':0,
        'type':1,
    },
    {
        'thtitle':'타이틀2',
        'thwidth':1,
        'colid':1,
        'type':1,
    },
    {
        'thtitle':'타이틀3',
        'thwidth':1,
        'colid':2,
        'type':1,
    },
    {
        'thtitle':'타이틀4',
        'thwidth':1,
        'colid':3,
        'type':1,
    },
    {
        'thtitle':'타이틀5',
        'thwidth':1,
        'colid':4,
        'type':1,
    },
    {
        'thtitle':'타이틀6',
        'thwidth':1,
        'colid':5,
        'type':1,
    },
    {
        'thtitle':'타이틀7',
        'thwidth':1,
        'colid':6,
        'type':1,
    },
    {
        'thtitle':'타이틀8',
        'thwidth':1,
        'colid':7,
        'type':1,
    },
    {
        'thtitle':'타이틀9',
        'thwidth':1,
        'colid':8,
        'type':1,
    },
    {
        'thtitle':'타이틀10',
        'thwidth':1,
        'colid':9,
        'type':1,
    },
    {
        'thtitle':'타이틀11',
        'thwidth':1,
        'colid':10,
        'type':1,
    },
]



export const TestDataList = [
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],[
    {
        'data':'내용1',
        'colid':0
    },
    {
        'data':'내용2',
        'colid':1
    },
    {
        'data':'내용3',
        'colid':2
    },
    {
        'data':'내용4',
        'colid':3
    },
    {
        'data':'내용5',
        'colid':4
    },
    {
        'data':'내용6',
        'colid':5
    },
    {
        'data':'내용7',
        'colid':6
    },
    {
        'data':'내용8',
        'colid':7
    },
    {
        'data':'내용9',
        'colid':8
    },
    {
        'data':'내용10',
        'colid':9
    },
    {
        'data':'내용11',
        'colid':10
    },
],
]