import tw from 'tailwind-styled-components'
import {Checkbox} from "@styled-icons/boxicons-regular/Checkbox"
import {CheckboxChecked} from "@styled-icons/boxicons-regular/CheckboxChecked"
import {Close} from "@styled-icons/evil/Close"
import { PerformColumnTypes } from '../../../types/brandItems/performColumns/PerformColumnTypes'
import { MyPageInputModalLayout } from '../MyPageMainStyle'
import styled from 'styled-components'

export const FilterContainer = tw(MyPageInputModalLayout)`
    max-sm:w-screen max-md:w-136 max-md:h-[60vh] md:w-156 md:h-[75vh] max-h-[100vh]
`
export const FilterListLayout = tw.div`
    max-sm:hidden sm:flex h-full
`
export const FilterShortcutResponsiveBox = styled.div`
    display: none;

    @media (min-width: 640px) {
        &:nth-child(1) {
            display: flex;
        }
    }

    @media (min-width: 768px) {
        &:nth-child(1) {
            display: flex;
        }
        &:nth-child(2) {
            display: flex;
        }
    }

    @media (min-width: 1280px) {
        display: flex;
    }
`

export const FilterRowList = tw.div`
    flex flex-col w-full h-full
    overflow-y-auto scroll-transparent scroll-overlay
`

export const FilterBox = tw.div`
    flex w-full min-h-[3rem] flex-shrink-0 mb-3 p-2
    rounded-md
    max-md:text-sm md:text-base
    ${(props) => props.isActive ? "bg-blue-100" : "bg-slate-100"}
`

export const FilterCheckBox = tw.div`
    flex flex-center min-w-[1.5rem] max-w-[1.5rem] h-full text-gray-600
    cursor-pointer
`
export const FilterCheckIcon = tw(Checkbox)`
    w-full h-full
`
export const FilterCheckedIcon = tw(CheckboxChecked)`
    w-full h-full
`

export const FilterColumnName = tw.div`
    flex flex-center max-md:min-w-[6rem] md:min-w-[10rem] h-full
    select-none cursor-pointer
`

export const FilterValueContainer = tw.div`
    flex flex-center w-full h-full px-2
`

export const FilterTagContainer = tw.div`
flex w-auto max-w-full border border-green-500
`
export const FilterTagList = tw.div`
flex items-center w-full h-full
scroll-transparent scroll-overlay
`
export const FilterTagBox = tw.div`
flex flex-shrink-0 items-center mr-1 px-1
text-xs rounded-full
${(props) => props.columnType === PerformColumnTypes.STATIC && "bg-orange-500"}
${(props) => props.columnType === PerformColumnTypes.DYNAMIC && "bg-nightblue-500"}
${(props) => props.columnType === PerformColumnTypes.CALCULATE && "bg-purple-500"}
`
export const FilterTagColumnName = tw.span`
px-2 py-1 text-white
`
export const FilterTagValue = tw.span`
px-2 py-0.5 bg-white rounded-full
`
export const FilterTagCancleButton = tw.button`
flex flex-center w-5 h-5 ml-1 p-0.5
text-gray-200 hover:bg-black/20 rounded-full
`

export const FilterTagCancleIcon = tw(Close)`
    h-full
`