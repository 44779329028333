import React,{useEffect,useState,useRef} from "react"
import { DateTimeInputLayout, DateTimeUnitBox, DateTimeUnitInput, DateTimeUnitInputBox, DateTimeUnitLabel, DateTimeUnitSection } from "./DateTimeInputStyle"
import Utils from "../../utils/Utils"
import dayjs from "dayjs"

const CLASS_DATE_TIME_UNIT_INPUT = "date-time-unit-input"

const Unit = ({boxStyle, label, placeholder, maxLength, value, onChange}) => {
    const [isFocused, setFocused] = useState(false)

    return (
        <DateTimeUnitBox className={boxStyle}>
            <DateTimeUnitLabel focused={isFocused}>
                {label}
            </DateTimeUnitLabel>
            <DateTimeUnitInputBox focused={isFocused}>
                <DateTimeUnitInput
                    type="text"
                    className={CLASS_DATE_TIME_UNIT_INPUT}
                    placeholder={placeholder}
                    onFocus={(e)=>{setFocused(true); e.target.select()}}
                    onBlur={()=>{setFocused(false)}}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            </DateTimeUnitInputBox>
        </DateTimeUnitBox>
    )
}

export const DateTimeInput = ({
    timeStamp,
    setTimeStamp,
    editYear=true,
    editMonth=true,
    editDay=true,
    editHour=true,
    editMinute=true,
    editSecond=true
}) => {
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [day, setDay] = useState()
    const [hour, setHour] = useState()
    const [minute, setMinute] = useState()
    const [second, setSecond] = useState()

    useEffect(() => {
        if (timeStamp !== null) {
            const date = dayjs(timeStamp)
            setYear(date.year())
            setMonth(date.month()+1)
            setDay(date.date())
            setHour(date.hour())
            setMinute(date.minute())
            setSecond(date.second())
        }
    }, [timeStamp])

    const handleTimeStamp = (_year, _month, _day, _hour, _minute, _second) => {
        let inputYear = _year
        if (Utils.isNullOrUndefined(inputYear)) {
            inputYear = 0
        }
        let inputMonth = _month
        if (Utils.isNullOrUndefined(inputMonth) || _month === 0) {
            inputMonth = 1
        }
        let inputDay = _day
        if (Utils.isNullOrUndefined(inputDay) || _day === 0) {
            inputDay = 1
        }
        let inputHour = _hour
        if (Utils.isNullOrUndefined(inputHour)) {
            inputHour = 0
        }
        let inputMinute = _minute
        if (Utils.isNullOrUndefined(inputMinute)) {
            inputMinute = 0
        }
        let inputSecond = _second
        if (Utils.isNullOrUndefined(inputSecond)) {
            inputSecond = 0
        }

        // 월은 실제 월이 아닌 index를 넣는다.
        inputMonth -= 1

        const date = new Date(inputYear, inputMonth, inputDay, inputHour, inputMinute, inputSecond)
        const _timeStamp = date.getTime()
        
        if (!isNaN(_timeStamp)) {
            setTimeStamp(_timeStamp)
        }
    }

    const handleYear = (e) => {
        const value = Number(e.target.value)
        handleTimeStamp(value, month, day, hour, minute, second)
        setYear(value)

        if (e.target.value.length >= 4) {
            focusNext(e.target)
        }
    }

    const handleMonth = (e) => {
        const value = Number(e.target.value)
        if (value > 12) {
            return
        }
        handleTimeStamp(year, value, day, hour, minute, second)
        setMonth(value)

        if (e.target.value.length >= 2) {
            focusNext(e.target)
        }
    }

    const handleDay = (e) => {
        const value = Number(e.target.value)
        if (value > 31) {
            return
        }
        handleTimeStamp(year, month, value, hour, minute, second)        
        setDay(value)

        if (e.target.value.length >= 2) {
            focusNext(e.target)
        }
    }

    const handleHour = (e) => {
        const value = Number(e.target.value)
        if (value > 24) {
            return
        }
        handleTimeStamp(year, month, day, value, minute, second)
        setHour(value)

        if (e.target.value.length >= 2) {
            focusNext(e.target)
        }
    }

    const handleMinute = (e) => {
        const value = Number(e.target.value)
        if (value > 60) {
            return
        }
        handleTimeStamp(year, month, day, hour, value, second)
        setMinute(value)

        if (e.target.value.length >= 2) {
            focusNext(e.target)
        }
    }

    const handleSecond = (e) => {
        const value = Number(e.target.value)
        if (value > 60) {
            return
        }
        handleTimeStamp(year, month, day, hour, minute, value)
        setSecond(value)

        if (e.target.value.length >= 2) {
            focusNext(e.target)
        }
    }

    return (
        <DateTimeInputLayout>
            {editYear && (
                <Unit
                    boxStyle={"w-16"}
                    label={"연도"}
                    placeholder={"YYYY"}
                    value={year}
                    onChange={handleYear}
                    maxLength={4}
                />
            )}
            {editMonth && (
                <Unit
                    boxStyle={"w-12"}
                    label={"월"}
                    placeholder={"MM"}
                    value={month}
                    onChange={handleMonth}
                    maxLength={2}
                />
            )}
            {editDay && (
                <Unit
                    boxStyle={"w-12"}
                    label={"일"}
                    placeholder={"DD"}
                    value={day}
                    onChange={handleDay}
                    maxLength={2}
                />
            )}
            {editHour && (
                <Unit
                    boxStyle={"w-12"}
                    label={"시(24)"}
                    placeholder={"HH"}
                    value={hour}
                    onChange={handleHour}
                    maxLength={2}
                />
            )}
            {editMinute && (
                <Unit
                    boxStyle={"w-12"}
                    label={"분"}
                    placeholder={"mm"}
                    value={minute}
                    onChange={handleMinute}
                    maxLength={2}
                />
            )}
            {editSecond && (
                <Unit
                    boxStyle={"w-12"}
                    label={"초"}
                    placeholder={"ss"}
                    value={second}
                    onChange={handleSecond}
                    maxLength={2}
                />
            )}
        </DateTimeInputLayout>
    )
}

const focusNext = (target) => {
    const currentPlaceholder = target.placeholder
    const currentValue = target.value

    const dateTimeInpuyLayout = target.parentElement.parentElement.parentElement
    const brother = dateTimeInpuyLayout.children

    let isMe = false
    for (let i=0; i<brother.length; i++) {
        const unit = brother[i]
        const unitInput = unit.querySelector(`.${CLASS_DATE_TIME_UNIT_INPUT}`)

        if (isMe) {
            unitInput.focus()
            return
        }

        if (unitInput.placeholder === currentPlaceholder && unitInput.value === currentValue) {
            isMe = true
        }
    }
}