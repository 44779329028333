import tw from "tailwind-styled-components"
import { Box } from "@styled-icons/bootstrap/Box"
import { ArrowDropDown } from "@styled-icons/material-rounded/ArrowDropDown"

export const BoxIcon = tw(Box)`
    h-full
`

export const BoxIconContainer = tw.div`
    flex flex-center
    w-full h-full max-lg:p-[0.5rem] lg:p-[0.6rem] 
    rounded-lg border border-gray-500 
    text-gray-600 
`

export const PerformNavContainer = tw.div`
    flex justify-between items-center flex-shrink-0
    w-full max-lg:h-9 lg:h-12 max-lg:bg-slate-200 lg:bg-slate-200 px-4 
    border-b border-slate-300
`

export const PerformSelectContainer = tw.div`
    max-lg:w-full lg:w-156 h-10 group py-1
    rounded-lg lg:bg-slate-100
`

export const PerformNowSelectedContainer = tw.div`
    w-full h-full flex items-center px-1
    max-lg:text-sm
`

export const PerformItemsListSelectContainer = tw.div`
    absolute top-10 hidden lg:group-hover:block 
    z-30 w-156 h-128
    bg-white shadow-lg rounded-lg
    overflow-y-scroll scroll-transparent scroll-overlay
`

export const PerformNavButton = tw.button`
    max-xl:w-32 xl:w-40 px-2 h-full flex items-center justify-center
    bg-slate-200 bg-gradient-to-t hover:from-white hover:to-slate-200 focus:outline-none
    ${(props) =>
        props.navstate === true
            ? "border-b-2 text-nightblue-900 border-b-nightblue-600 from-slate-100 to-slate-200"
            : "text-slate-700"} 
`

export const BrandItemThumbBox = tw.div`
    h-full aspect-square flex-shrink-0
    bg-transparent  rounded-lg mr-2 overflow-hidden
`

export const PerformItemCardContainer = tw.div`
    w-full h-14 px-1 flex items-center hover:bg-slate-100 p-1
`

export const PerformNavLinkContainer = tw.div`
    max-lg:hidden lg:flex items-center
    h-full  
`

export const PerformItemsMoreIcon = tw(ArrowDropDown)`
    h-full mr-2 scale-125 text-slate-500
`
