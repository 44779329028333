import Paginator from "../Paginator"
import Utils from "../../utils/Utils"
import User from "../users/User"
import UserBrand from "../users/UserBrand"
import UserMarketer from "../users/UserMarketer"
import { PointHistoryTypes } from "./PointTypes"

export default class {
    constructor() {
        this.id = -1
        this.userID = -1
        this.user = new User()
        this.brandID = -1
        this.brand = new UserBrand()
        this.marketerID = -1
        this.marketer = new UserMarketer()
        this.point = 0
        this.historyType = PointHistoryTypes.ETC
        this.description = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.userID = response["user_id"]
        this.brandID = response["brand_id"]
        this.marketerID = response["marketer_id"]
        this.point = response["point"]
        this.historyType = response["history_type"]
        this.description = response["description"]

        const userResponse = response["user"]
        const brandResponse = response["brand"]
        const marketerResponse = response["marketer"]

        if (!Utils.isNullOrUndefined(userResponse)) {
            this.user = new User()
            this.user.convertByResponse(userResponse)
        }
        if (!Utils.isNullOrUndefined(brandResponse)) {
            this.brand = new UserBrand()
            this.brand.convertByResponse(brandResponse)
        }
        if (!Utils.isNullOrUndefined(marketerResponse)) {
            this.marketer = new UserMarketer()
            this.marketer.convertByResponse(marketerResponse)
        }

        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {
        let request = {
            id: this.id,
            user_id: this.userID,
            brand_id: this.brandID,
            marketer_id: this.marketerID,
            point: this.point,
            history_type: this.historyType,
            description: this.description,
        }

        return request
    }

    pointStatus() {
        if (this.historyType === PointHistoryTypes.ETC) {
            return 0
        } else if (this.historyType === PointHistoryTypes.MANUAL_DEPOSIT) {
            return 1
        } else if (this.historyType === PointHistoryTypes.MANUAL_WITHDRAW) {
            return -1
        } else if (this.historyType === PointHistoryTypes.SIGNUP_REFERRAL) {
            return 1
        } else if (this.historyType === PointHistoryTypes.SIGNUP_REFERRED) {
            return 1
        } else if (this.historyType === PointHistoryTypes.COUPON_BUY) {
            return -1
        } else if (this.historyType === PointHistoryTypes.COUPON_REFUND) {
            return 1
        } else {
            return 0
        }
    }

    pointStatusColor(defaultColor = "text-black") {
        const pointStatus = this.pointStatus()

        if (pointStatus === 1) {
            return "text-red-600"
        } else if (pointStatus === -1) {
            return "text-blue-600"
        } else {
            return defaultColor
        }
    }
}
