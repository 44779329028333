import React, { useState, useEffect } from "react"
import tw from "tailwind-styled-components"
import { ArrowIosBack } from "@styled-icons/evaicons-solid/ArrowIosBack"
import { ArrowIosForward } from "@styled-icons/evaicons-solid/ArrowIosForward"
import { TEXT_TOP_LIST } from "../../constants/ConstBanner"
import Aibtn from "../../static/Ai_image02.png"
import styled from "styled-components"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"

export const SlidInnerBoxStyled = styled.div`
    max-width: 1200px;
    width: 1200px;
`
export const SlideContainerTransition = styled(ContainerPaddingTop)`
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
`

export const SlideInnerBox = tw(BoxMaxMin)`
    flex items-center w-full h-full
`

export const SlideContentBox = tw.div`
    flex flex-center max-md:flex-col md:flex-row w-full h-auto md:max-h-[28rem] py-8
`

export const SlideInfoBox = tw.div`
    flex flex-col justify-center w-full h-full max-md:px-4 md:px-2
`
export const SlideInfoMessageBox = tw.div`
    flex flex-col w-full mb-4
    [&>textarea]:bg-transparent [&>textarea]:resize-none
`

export const SlideInfoSearchBox = tw.div`
    flex flex-col w-full mb-4
`
export const SlideInfoSearchBar = tw.div`
    flex items-center w-full h-12 px-2
    bg-white rounded-full overflow-hidden
`
export const SlideInfoSearchInputContainer = tw.div`
    flex items-center w-full h-full
`
export const SlideInfoSearchButtonContainer = tw.div`
    flex flex-center w-12 h-full p-2
    aspect-square flex-shrink-0
    cursor-pointer
`
export const SlideInfoSearchButtonIcon = tw(Search)`
    h-full
`

export const SlideInfoSearchKeywordList = tw.div`
    flex flex-wrap
`
export const SlideInfoSearchKeywordButton = tw.button`
    max-sm:flex sm:flex flex-center px-3 py-2 mr-2 mb-2
    text-white max-md:text-xs md:text-sm
    bg-white/10 hover:bg-white/20 rounded-full
`

export const SlideImageBox = tw.div`
    w-full h-auto max-md:px-4 md:pl-4 md:pr-2 max-md:mt-4
`

export const SlideContainer = tw(SlideContainerTransition)`
    w-full flex justify-center  
`

export const SlideImgContainer = tw.div`
    h-full aspect-[4/3] flex flex-col rounded-xl drop-shadow-2xl duration-300 mx-auto
`
export const SlideInnerContainer = tw.div`
    w-full h-2/6 duration-300
    `

export const SlideMoveButton = tw.button`
    opacity-30 bg-white rounded-full hover:opacity-50 duration-300 hover:top-4
`

export const SlideBtnContainer = tw.div`
    flex justify-center items-center h-11 w-11 mx-2

`

export const ArrowBack = tw(ArrowIosBack)`
    h-10 w-10 opacity-50 hover:h-11 hover:w-11 duration-300 
`
export const ArrowForward = tw(ArrowIosForward)`
h-10 w-10  opacity-50 hover:h-12 hover:w-12 duration-300
`
