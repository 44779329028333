import ImageFile from "../../../types/files/ImageFile"
import CommonFile from "../../../types/files/CommonFile"
import { authInstance, defaultInstance, downloadInstance, fileInstance } from "../../utils"
import Utils from "../../../utils/Utils"
import JSZip from "jszip"
import FileSaver from "file-saver"

export const getCommonFile = async (file_id) => {
    const file = new CommonFile()
    if (file_id < 0 || file_id === undefined) {
        return file
    }
    const response = await defaultInstance.post("/api/commons/base_file/", { file_id: file_id })
    if (response.data.id !== undefined) {
        file.convertByResponse(response.data)
        return file
    }

    return file
}

export const getImageFile = async (imageID) => {
    const imageFile = new ImageFile()
    if (imageID < 0 || imageID === undefined) {
        return imageFile
    }
    const response = await defaultInstance.post("/api/commons/image/", { file_id: imageID })

    if (response.data.base64 !== undefined || response.data.url !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const uploadImage = async (image, isBase64 = false) => {
    const imageFile = new ImageFile()
    if (image === undefined) {
        return imageFile
    }

    console.log(image)
    const response = await fileInstance.post("/api/commons/image_write/", { images: image, is_base64: isBase64 })
    console.log(response)

    if (response.data.base64 !== undefined || response.data.url !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const uploadImageBase64 = async (fileName, imageBase64) => {
    const imageFile = new ImageFile()
    if (Utils.isStringNullOrEmpty(imageBase64)) {
        return imageFile
    }
    const response = await fileInstance.post("/api/commons/image_write/", {
        base64_image: imageBase64,
        filename: fileName,
    })

    if (response.data.base64 !== undefined || response.data.url !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const uploadSign = async (signImage) => {
    const signFile = new ImageFile()
    if (signImage === undefined) {
        return signFile
    }
    const response = await fileInstance.post("/api/commons/sign_write/", { base64_image: signImage })

    if (response.data.base64 !== undefined) {
        signFile.convertByResponse(response.data)
        return signFile
    }

    return signFile
}

export const uploadFile = async (file) => {
    const commonFile = new CommonFile()
    if (file === undefined) {
        return commonFile
    }
    const response = await fileInstance.post("/api/commons/file_write/", { files: file })

    if (response.data.id >= 0) {
        commonFile.convertByResponse(response.data)
        return commonFile
    }

    return commonFile
}

export const downloadFile = async (fileURL, fileName) => {
    if (Utils.isStringNullOrEmpty(fileURL)) {
        return
    }
    downloadInstance.get(fileURL).then((response) => {
        const blob = new Blob([response.data])
        FileSaver.saveAs(blob, fileName)
    })
}

export const downloadFileZip = async (files, zipFileName) => {
    let bResult = false
    if (files.length === 0) {
        return bResult
    }
    let zip = new JSZip()

    await Promise.all(
        files.map(async (file) => {
            await downloadInstance
                .get(file.fileURL)
                .then((response) => {
                    // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
                    zip.file(file.name, new Blob([response.data]), { binary: true })
                })
                .catch((error) => {})
        }),
    )

    await zip
        .generateAsync({ type: "blob" })
        .then((content) => {
            FileSaver.saveAs(content, `${zipFileName}.zip`)
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const deleteFile = async (fileID) => {
    let bResult = false
    if (!(fileID >= 0)) {
        return bResult
    }
    await fileInstance
        .delete("/api/commons/file_write/", {
            params: {
                file_id: fileID,
            },
        })
        .then((response) => {
            bResult = true
        })
        .catch(() => {})

    return bResult
}
