// import ReactDOM from 'react-dom';
import React, { Routes, Route } from "react-router-dom"
import SignUp from "./SignUp"
import { SignUpLayout } from "./SignUpStyle"
import SignUpBrand from "./SignUpBrand"
import SignUpUserMarketer from "./SignUpUserMarketer"
import SignUpUserStaff from "./SignUpUserStaff"

import tw from "tailwind-styled-components"

const SignUpRouterContainer = tw.div`
    h-auto w-full flex justify-center
`

const SignUpRouter = () => {
    return (
        <SignUpRouterContainer>
            <SignUpLayout>
                <Routes>
                    <Route path="">
                        <Route path="" element={<SignUp />} />
                        <Route path="brand" element={<SignUpBrand />} />
                        <Route path="marketer" element={<SignUpUserMarketer />} />
                        <Route path="marketer_oauth" element={<SignUpUserMarketer isOauth={true} />} />
                        <Route path="staff" element={<SignUpUserStaff />} />
                    </Route>
                </Routes>
            </SignUpLayout>
        </SignUpRouterContainer>
    )
}

export default SignUpRouter
