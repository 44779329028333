export const NoticeData = [
    { id:1,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:2,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:3,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:4,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:5,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:6,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:7,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:8,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:9,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
    { id:10,title:'제목 테스트 입니다',date:'2023-02-23',writer:'관리자' },
]