import React, { useEffect, useRef, useState } from "react"
import tw from "tailwind-styled-components"
import Utils from "../../utils/Utils"
import SortSwitch from "../SortSwitch"
import {
    FilterInputLabel,
    FilterInputResetButton,
    FilterInputResetIcon,
    FilterInputSearch,
    FilterInputTextBox,
    FilterInputSearchButton,
    FilterInputSearchIcon,
    FilterInputSelect,
    FilterInputSelectBox,
    FilterInputSubSelect,
    FilterInputSort,
} from "./FilterInputsStyle"
import { DateTimeInput } from "../inputs/DateTimeInput"
import { DateRangePicker } from "./PerformInputs"
import { DateTimeRangeInput } from "../inputs/DateTimeRangeInput"
import useDropdownDetectClose from "../../hooks/useDropdownDetectClose"

export const FilterInputCombo = ({ label, value, setValue, sortType, setSortType, width = null, children }) => {
    return (
        <FilterInputSelectBox width={width}>
            {!Utils.isStringNullOrEmpty(label) && <FilterInputLabel>{label}</FilterInputLabel>}
            <FilterInputSelect
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
            >
                {children}
                {/* <option value={MarketerStatusTypes.NONE}>{MarketerStatusTypeNames[MarketerStatusTypes.NONE]}</option>
                <option value={MarketerStatusTypes.WAIT}>{MarketerStatusTypeNames[MarketerStatusTypes.WAIT]}</option>
                <option value={MarketerStatusTypes.CONFIRM}>{MarketerStatusTypeNames[MarketerStatusTypes.CONFIRM]}</option>
                <option value={MarketerStatusTypes.BLOCK}>{MarketerStatusTypeNames[MarketerStatusTypes.BLOCK]}</option> */}
            </FilterInputSelect>
            {!Utils.isNullOrUndefined(setSortType) && (
                <FilterInputSort>
                    <SortSwitch sortType={sortType} setSortType={setSortType} />
                </FilterInputSort>
            )}
        </FilterInputSelectBox>
    )
}

export const FilterInputText = ({
    label,
    value,
    setValue,
    placeholder = "",
    width = null,
    textOption = null,
    setTextOption = null,
    textOptionWidth = "20",
    textOptionTypes = {},
    textOptionNames = {},
    submit = null,
    onBlur = null,
}) => {
    const handleEnter = (e) => {
        if (e.key == "Enter") {
            submit()
        }
    }

    const handleTextOption = () => {}

    return (
        <FilterInputTextBox width={width}>
            {textOption !== null && Object.keys(textOptionTypes).length > 0 && (
                <FilterInputSubSelect
                    width={textOptionWidth}
                    onChange={(e) => {
                        setTextOption(e.target.value)
                    }}
                >
                    {Object.keys(textOptionTypes).map((textOptionKey) => {
                        const textOptionValue = textOptionTypes[textOptionKey]
                        return (
                            <option key={textOptionKey} value={textOptionValue}>
                                {textOptionNames[textOptionValue]}
                            </option>
                        )
                    })}
                </FilterInputSubSelect>
            )}
            {!Utils.isStringNullOrEmpty(label) && <FilterInputLabel>{label}</FilterInputLabel>}
            <FilterInputSearch
                type="text"
                placeholder={placeholder}
                onKeyPress={handleEnter}
                onChange={(e) => {
                    setValue(e.target.value)
                }}
                onBlur={onBlur}
                value={value}
            />
            {!Utils.isNullOrUndefined(submit) && (
                <FilterInputSearchButton
                    onClick={() => {
                        submit()
                    }}
                >
                    <FilterInputSearchIcon />
                </FilterInputSearchButton>
            )}
        </FilterInputTextBox>
    )
}

export const FilterInputDate = ({ label, width = null, dateStart, setDateStart, dateEnd, setDateEnd, children }) => {
    return (
        <FilterInputSelectBox width={width} className="relative h-9">
            {!Utils.isStringNullOrEmpty(label) && <FilterInputLabel>{label}</FilterInputLabel>}
            <input
                type="date"
                value={dateStart}
                onChange={(e) => {
                    setDateStart(e.target.value)
                }}
            />
            <span className="mx-2">~</span>
            <input
                type="date"
                value={dateEnd}
                onChange={(e) => {
                    setDateEnd(e.target.value)
                }}
            />
        </FilterInputSelectBox>
    )
}

export const FilterInputReset = ({ reset }) => {
    return (
        <FilterInputResetButton
            onClick={() => {
                reset()
            }}
            title="초기화"
        >
            <FilterInputResetIcon />
        </FilterInputResetButton>
    )
}
