export const ItemOrders = {
    FAV: 0,
    CREATED: 1,
    ITEM_REGISTED: 2
}
export const ItemOrderValues = [
    "favs", 
    "id",
    "item_marketer__registed_date",
]
export const ItemOrderNames = [
    "인기순", 
    "등록순",
    "승인순"
]
export const ItemFilterValuesByMarketer = {
    ALL: 0, 
    WAIT: 1, 
    CONFIRM: 2,
    SUSPEND_WAIT: 3,
    SUSPEND_CONFIRM: 4,
}
export const ItemFilterNamesByMarketer = {
    0: "전체", 
    1: "승인 대기", 
    2: "광고 중", 
    3: "정지 대기",
    4: "정지 승인",
}

export const ITEM_PAGE_SIZES = [12,16,20,24,28]

export const MYPAGE_ITEM_SIZE = 6
export const MYPAGE_PAGE_LENGTH = 5