import Paginator from "../Paginator";
import BrandItem from "./BrandItem";

export default class extends Paginator{
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]
        
        this.items = []
        response["results"].map(result => {
            let item = new BrandItem()
            item.convertByResponse(result)
            this.items.push(item)
        })
    }
}