import FileUtils from "../../utils/FileUtils";
import CommonFile from "../files/CommonFile";
import User from "../users/User"
import { BoardImportnaceStatusTypes, BoardStatusTypes } from './BoardTypes';

export default class{
    constructor() {
        this.id = -1
        this.user = new User()
        this.title = ""
        this.views = 0
        this.boardStatus = BoardStatusTypes.BLOCK
        this.content = ""
        this.files = []
        this.board_importance_status = BoardImportnaceStatusTypes.DEFAULT
        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        const userResponse = response["user"]
        this.user = new User()
        if (userResponse !== null && userResponse !== undefined) {
            this.user.convertByResponse(userResponse)
        }
        this.title = response["title"]
        this.views = response["views"]
        this.boardStatus = response["board_status"]
        const contentResponse = response["content"]
        if (contentResponse !== null && contentResponse !== undefined) {
            this.content = contentResponse
        }
        const filesResponse = response["board_file"]
        if (filesResponse !== null && filesResponse !== undefined) {
            filesResponse.map(fileResponse => {
                const file = new CommonFile()
                file.convertByResponse(fileResponse["file"])
                this.files.push(file)
            })
        }
        
        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {
        const uploadContent = FileUtils.getRequestContent(this.content)
        let request = {
            user_id: this.user.id,
            title: this.title,
            board_status: this.boardStatus,
            content: uploadContent,
            board_importance_status: this.board_importance_status,
            board_file_ids: this.files.map(f => f.id)
        }

        return request
    }
}