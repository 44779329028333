import Paginator from "../Paginator"
import Brand from "../users/Brand"
import BrandItemContract from "./BrandItemContract"
import BrandItemNotice from "./notices/BrandItemNotice"
import { MarketerStatusTypes, MarketerSuspendStatusTypes } from "./BrandItemTypes"
import { AdTypes, EditStateTypes, ItemTypes } from "../EditorTypes"
import Utils from "../../utils/Utils"
import FileUtils from "../../utils/FileUtils"
import Sigungu from "../commons/Sigungu"

export default class {
    constructor() {
        this.id = -1
        this.favs = 0
        this.brand = new Brand()
        this.detail = {
            title: "",
            marketerType: 0,
            itemSalesType: 0,
            dateStart: "",
            dateEnd: "",
            personMax: 0,
            thumbnailImageID: -1,
            thumbnailImageURL: "",
            rewardMessage: "",
            missionMessage: "",
            description: "",

            sigungu: new Sigungu(),
            thumbnailLink: "",
            adCategory: 0,
            adDescription: "",
            adCompanyName: "",
            adCompanyTel: "",
            adCompanyEmail: "",

            created: "",
            updated: "",
        }
        this.contract = new BrandItemContract()

        this.aggregation = {
            marketers: 0,
            marketerRequests: 0,
            performs: 0,
            performConfirms: 0,
            calculateds: 0,
        }

        this.notices = []

        // 마케터가 조회시에만 사용
        this.marketerStatusType = MarketerStatusTypes.WAIT
        this.marketerSuspendStatusType = MarketerSuspendStatusTypes.NONE
        this.registedDate = ""
        this.isAccess = false

        // 브랜드 및 스태프 조회시에만 사용
        this.editStateType = EditStateTypes.WAIT
        this.isEditRequested = false
        this.isTempItem = false

        // 아이템 타입
        this.itemType = ItemTypes.ITEM
        this.adType = AdTypes.FREE
    }

    convertByResponse(response) {
        const brandResponse = response["brand"]
        let detailResponse = response["detail"]
        this.id = response["id"]
        this.favs = response["favs"]

        if (brandResponse !== null) {
            this.brand = new Brand()
            this.brand.convertByResponse(brandResponse)
        }

        if (detailResponse !== null) {
            // 임시 수정본 데이터가 있는 경우 수정본 데이터로 교체한다.
            const detailTempResponse = response["detail_temp"]
            if (
                !Utils.isNullOrUndefined(detailTempResponse) &&
                !Utils.isStringNullOrEmpty(detailTempResponse["title"])
            ) {
                detailResponse = detailTempResponse
                this.isTempItem = true
            }

            const sigungu = new Sigungu()
            sigungu.convertByResponse(detailResponse["sigungu"])

            this.detail = {
                title: detailResponse["title"],
                marketerType: detailResponse["marketer_type"],
                itemSalesType: detailResponse["item_sales_type"],
                dateStart: detailResponse["date_start"],
                dateEnd: detailResponse["date_end"],
                personMax: detailResponse["person_max"],
                thumbnailImageID: detailResponse["thumbnail_image_id"],
                thumbnailImageURL: detailResponse["thumbnail_image_url"],
                rewardMessage: detailResponse["reward_message"],
                missionMessage: detailResponse["mission_message"],

                sigungu: sigungu,
                thumbnailLink: detailResponse["thumbnail_link"],
                adCategory: detailResponse["ad_category"],
                adDescription: detailResponse["ad_description"],
                adCompanyName: detailResponse["ad_company_name"],
                adCompanyTel: detailResponse["ad_company_tel"],
                adCompanyEmail: detailResponse["ad_company_email"],

                created: detailResponse["created_shorten"],
                updated: detailResponse["updated_shorten"],
            }

            try {
                if (!Utils.isNullOrUndefined(response["description"]["content"])) {
                    this.detail.description = response["description"]["content"]
                }
            } catch {
                // pass
            }
        }

        if (Object.keys(response).includes("contract") && response["contract"] !== null) {
            this.contract = new BrandItemContract()
            this.contract.convertByResponse(response["contract"])
        }

        const noticesResponse = response["notice"]
        this.notices = []
        if (!Utils.isNullOrUndefined(noticesResponse)) {
            noticesResponse.map((noticeResponse) => {
                const notice = new BrandItemNotice()
                notice.convertByResponse(noticeResponse)
                this.notices.push(notice)
            })
        }

        const marketersResponse = response["marketers"]
        if (!Utils.isNullOrUndefined(marketersResponse)) {
            this.aggregation.marketers = marketersResponse
        }
        const marketerRequestsResponse = response["marketer_requests"]
        if (!Utils.isNullOrUndefined(marketerRequestsResponse)) {
            this.aggregation.marketerRequests = marketerRequestsResponse
        }
        const performsResponse = response["performs"]
        if (!Utils.isNullOrUndefined(performsResponse)) {
            this.aggregation.performs = performsResponse
        }
        const performConfirmsResponse = response["perform_confirms"]
        if (!Utils.isNullOrUndefined(performConfirmsResponse)) {
            this.aggregation.performConfirms = performConfirmsResponse
        }
        const calculatedsResponse = response["calcs"]
        if (!Utils.isNullOrUndefined(calculatedsResponse)) {
            this.aggregation.calculateds = calculatedsResponse
        }
        const marketerStatusTypeResponse = response["marketer_status"]
        if (!Utils.isNullOrUndefined(marketerStatusTypeResponse)) {
            this.marketerStatusType = marketerStatusTypeResponse
        }
        const marketerSuspendStatusTypeResponse = response["marketer_suspend_status"]
        if (!Utils.isNullOrUndefined(marketerSuspendStatusTypeResponse)) {
            this.marketerSuspendStatusType = marketerSuspendStatusTypeResponse
        }
        const registedDateResponse = response["registed_date"]
        if (!Utils.isNullOrUndefined(registedDateResponse)) {
            this.registedDate = registedDateResponse

            this.isAccess = response["is_access"]
        }

        const editStateTypeResponse = response["edit_state_type"]
        if (!Utils.isNullOrUndefined(editStateTypeResponse)) {
            this.editStateType = editStateTypeResponse
        }
        const editRequestedResponse = response["edit_requests"]
        if (!Utils.isNullOrUndefined(editRequestedResponse)) {
            this.isEditRequested = editRequestedResponse > 0
        }

        const itemTypeResponse = response["item_type"]
        if (!Utils.isNullOrUndefined(itemTypeResponse)) {
            this.itemType = itemTypeResponse
        }
        const adTypeResponse = response["ad_type"]
        if (!Utils.isNullOrUndefined(adTypeResponse)) {
            this.adType = adTypeResponse
        }
    }

    convertToRequest() {
        let request = {
            id: -1,
            detail: {},
            contract: {
                contract: "",
                mission: "",
                reward: "",
            },
            description: {},
        }

        request.detail.title = this.detail.title
        request.detail.marketer_type = this.detail.marketerType
        request.detail.item_sales_type = this.detail.itemSalesType
        request.detail.date_start = this.detail.dateStart
        request.detail.date_end = this.detail.dateEnd
        request.detail.person_max = this.detail.personMax
        request.detail.thumbnail_image_id = this.detail.thumbnailImageID
        request.detail.reward_message = this.detail.rewardMessage
        request.detail.mission_message = this.detail.missionMessage

        if (this.detail.sigungu.id >= 0) {
            request.detail.sigungu_id = this.detail.sigungu.id
        }
        // request.detail.sigungu = this.detail.sigungu
        request.detail.thumbnail_link = this.detail.thumbnailLink
        request.detail.ad_category = this.detail.adCategory
        request.detail.ad_description = this.detail.adDescription
        request.detail.ad_company_name = this.detail.adCompanyName
        request.detail.ad_company_tel = this.detail.adCompanyTel
        request.detail.ad_company_email = this.detail.adCompanyEmail

        request.description.content = FileUtils.getRequestContent(this.detail.description)
        request.contract = this.contract.convertToRequest()
        request.item_type = this.itemType
        // request.ad_type = this.adType

        return request
    }

    // convertToRequest로 생상한 데이터를 item으로 다시 변환 시 사용
    convertByRequest(request) {
        this.id = request.id
        this.brand = new Brand()

        this.detail.title = request.detail.title
        this.detail.marketerType = request.detail.marketer_type
        this.detail.itemSalesType = request.detail.item_sales_type
        this.detail.dateStart = request.detail.date_start
        this.detail.dateEnd = request.detail.date_end
        this.detail.personMax = request.detail.person_max
        this.detail.thumbnailImageID = request.detail.thumbnail_image_id
        this.detail.rewardMessage = request.detail.reward_message
        this.detail.missionMessage = request.detail.mission_message

        this.detail.sigungu = request.detail.sigungu
        this.detail.thumbnailLink = request.detail.thumbnail_link
        this.detail.adCategory = request.detail.ad_category
        this.detail.adDescription = request.detail.ad_description
        this.detail.adCompanyName = request.detail.ad_company_name
        this.detail.adCompanyTel = request.detail.ad_company_tel
        this.detail.adCompanyEmail = request.detail.ad_company_email

        this.detail.description = request.description.content
        this.contract.mission = request.contract.mission
        this.contract.reward = request.contract.reward
        this.contract.contract = request.contract.contract

        // 리액트에서만 사용하는 속성
        try {
            this.isEditRequested = request.is_edit_requested
        } catch {
            //
        }
    }
}
