import {
    getAccessToken,
    getAuthTokenByLogin,
    getAuthTokenBySignup,
    getAuthTokenByToken,
    getLogout,
} from "../../apis/api/users"
import { setAccessToken, setUserToken } from "../../apis/services/users"
import { authInstance } from "../../apis/utils"
import ConstCookie from "../../constants/ConstCookie"
import { getCookie, removeCookie } from "../../utils/Cookie"
import Utils from "../../utils/Utils"
import { UserTypes } from "../../types/users/UserTypes"
import ConstLocalStorage from "../../constants/ConstLocalStorage"

// [개발,빌드] 일반 로그인 시 사용
export const getUserByLogin = async (email, password) => {
    let user = undefined
    await getAuthTokenByLogin(email, password)
        .then((response) => {
            user = setUserToken(response)
        })
        .catch((error) => {
            alert("이메일 또는 비밀번호를 확인 해주시기 바랍니다.")
        })

    return user
}

// [개발,빌드] 접속 시 유저정보를 가져오기 위해 사용 (임시 아님)
export const getUserByRefreshToken = async () => {
    let user = undefined

    // 쿠키에서 Refresh Token 로드
    const refreshToken = getCookie(ConstCookie.USER_REFRESH_TOKEN)

    if (Utils.isNullOrUndefined(refreshToken)) {
        return user
    }

    // 서버로 부터 Refresh Token으로 Access Token을 받아옴 , 빌드버전에서는 http쿠키로 보냄
    const response = await getAccessToken(refreshToken)

    if (response !== undefined) {
        // Access Token 쿠키에 저장
        setAccessToken(response)

        await getAuthTokenByToken()
            .then((response) => {
                user = setUserToken(response)
            })
            .catch((error) => {
                // alert("로그인 실패")
            })
    }

    return user
}

// [개발,빌드] 유저정보를 업데이트하기 위해 사용
export const getUserByAccessToken = async () => {
    let user = undefined

    await getAuthTokenByToken()
        .then((response) => {
            user = setUserToken(response)
        })
        .catch((error) => {
            // alert("로그인 실패")
        })

    return user
}

// [개발] 접속 시 기존 유저정보 임시로 사용하기 위해 사용
export const getUserTypeByRefreshToken = () => {
    const userTypeCode = localStorage.getItem(ConstLocalStorage.USER_TYPE)

    let userType = UserTypes.NONE
    if (userTypeCode === process.env.REACT_APP_USER_TYPE_MARKETER) {
        userType = UserTypes.MARKETER
    } else if (userTypeCode === process.env.REACT_APP_USER_TYPE_BRAND) {
        userType = UserTypes.BRAND
    } else if (userTypeCode === process.env.REACT_APP_USER_TYPE_STAFF) {
        userType = UserTypes.STAFF
    }

    return userType
    // const refreshToken = getCookie(ConstCookie.USER_REFRESH_TOKEN)

    // if (Utils.isStringNullOrEmpty(refreshToken)) {
    //     return UserTypes.NONE
    // }

    // const payload = Utils.decodeJWT(refreshToken)
    // if (!Object.keys(payload).includes("user_type")) {
    //     return UserTypes.NONE
    // }
    // return payload.user_type
}

// [개발,빌드] 로그아웃 시 사용
// [개발,빌드] 토큰 만료 시 사용
export const logout = () => {
    removeCookie(ConstCookie.USER_REFRESH_TOKEN)
    removeCookie(ConstCookie.USER_ACCESS_TOKEN)
    getLogout()

    localStorage.removeItem(ConstLocalStorage.USER_TYPE)
}

// 회원가입 시 서버로 보낼 데이터
export const getUserSignupRequestData = (email, name, nickname, password1, tel, userType, isAlertItem = true) => {
    return {
        user: {
            email: email,
            first_name: name,
            nickname: nickname,
            password: password1,
            tel: tel,
            user_type: userType,
            is_alert_item: Utils.isNullOrUndefined(isAlertItem) ? false : isAlertItem,
        },
        detail: {},
    }
}

export const getUserBrandRequestData = (
    brandName,
    companyManagerName,
    address1,
    address2,
    tel,
    businessImageID,
    businessNo,
    // date,
    // price,
    // profit,
    // creditGrade,
    // employees,
    // industry,
) => {
    return {
        business_no: businessNo,
        detail: {
            name: brandName,
            company_manager_name: companyManagerName,
            address1: address1,
            address2: address2,
            tel: tel,
            business_image_id: businessImageID,
            // "date": date,
            // "price": price,
            // "profit": profit,
            // "credit_grade": creditGrade,
            // "employees": employees,
            // "industry": industry,
        },
    }
}

export const getUserMarketerCareerRequestData = (career) => {
    let request = {
        job_type: career.jobType,
        career_year: career.careerYear,
        business_type: career.businessType,
        job_detail: career.jobDetail,
    }
    if (career.id >= 0) {
        request["id"] = career.id
    }

    return request
}

export const getUserMarketerSignupRequestData = (
    location1 = null,
    location2 = null,
    location3 = null,
    participantType,
    profit,
    careers,
) => {
    let careerData = []

    careers.map((career) => {
        careerData.push(getUserMarketerCareerRequestData(career))
    })

    let request = {
        detail: {
            // "participant_type": participantType,
            profit: profit,
        },
        careers: careerData,
    }

    if (location1 !== null) {
        request["detail"]["location1"] = location1
    }
    if (location2 !== null) {
        request["detail"]["location2"] = location2
    }
    if (location3 !== null) {
        request["detail"]["location3"] = location3
    }

    return request
}

export const getUserBySignup = async (requestData) => {
    let user = undefined
    await getAuthTokenBySignup(requestData)
        .then((response) => {
            user = setUserToken(response)
        })
        .catch((error) => {
            console.log(error)
        })

    return user
}
