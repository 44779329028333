import React from "react";
import { useState, useEffect } from "react";
import tw from "tailwind-styled-components"
import FileUtils from "../../utils/FileUtils";

const SkeletonBox = tw.div`
    skeleton mb-2
`

const ContentSkeleton = () => {
    return (
        <div className="flex flex-col">
            <SkeletonBox className="w-full aspect-[3/2]">
                loading...
            </SkeletonBox>
            <div className="flex">
                <SkeletonBox className="w-72 mr-2 h-8">
                    loading...
                </SkeletonBox>
                <SkeletonBox className="w-24 h-8">
                    loading...
                </SkeletonBox>
            </div>
            <SkeletonBox className="w-56 h-8">
                loading...
            </SkeletonBox>
        </div>
    )
}

export default function ReactQuillContent({
    content,
    isLoading=false
}) {
    const [sanitizedContent, setSanitizedContent] = useState("")

    useEffect(()=>{
        const sanitized = FileUtils.getSantinizedContent(content)
        setSanitizedContent(sanitized)
    }, [content])

    return(
        <div className="quill-content h-auto max-md:text-sm md:text-base px-0">
            {isLoading ? (
                <ContentSkeleton />
            ) : (
                <div dangerouslySetInnerHTML={{__html: sanitizedContent}} />
            )}
        </div>
    )
}