import React, { useState, useEffect } from "react"
import { useUserStore } from "../../zustand/store"
import { marketerApplyModal } from "../../zustand/modalstore"
import { useNavigate } from "react-router-dom"
import Utils from "../../utils/Utils"
import {
    ApplyWarnningMessage,
    MarketerDetailContainer,
    MarketerDetailInputBox,
    MarkterDetailButton,
    MarkterDetailButtonBox,
} from "../../components/user/MarketerDetailStyle"
import { MyInfoMarketerMore } from "../myPage/myinfo/MyInfoMarketerMore"
import {
    deleteUserProfileAvatar,
    getUserDetail as getUserDetailApi,
    updateUserProfileAvatar,
} from "../../apis/api/users"
import { SignUpBackButton, SignUpButton, SignUpButtonBox, SignUpButtonContainer } from "../user/SignUpStyle"

export default function ItemApplyMarketerDetail({ submitFlag, setSubmitFlag, nextStep }) {
    const [userDetail, setUserDetail] = useState(null)
    const [applyMarketerData, setApplyMarketerData] = useState(false)
    // const [submitFlag, setSubmitFlag] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const { user, actions } = useUserStore()

    const getUserDetail = () => {
        getUserDetailApi(user.id).then((response) => {
            setUserDetail(response)
        })
    }
    useEffect(() => {
        getUserDetail()
    }, [user])

    useEffect(() => {
        if (isSubmitted === true) {
            if (
                applyMarketerData.detail.location1 > 0 &&
                !Utils.isStringNullOrEmpty(applyMarketerData.user.birthday) &&
                applyMarketerData.user.gender != 2
            ) {
                nextStep()
            } else {
                alert("추가정보를 모두 입력 하고 저장 해주시기 바랍니다.")
            }
            setIsSubmitted(false)
        }
    }, [isSubmitted])

    // const marketerApply = () => {
    //     setSubmitFlag(true)
    // }

    return (
        <MarketerDetailContainer className="max-sm:w-full sm:w-full bg-white max-md:h-auto md:h-auto max-sm:p-0">
            <ApplyWarnningMessage className="px-8">
                ※ 필수 정보를 입력 후 저장 버튼을 눌러 주시기 바랍니다.
            </ApplyWarnningMessage>
            <MarketerDetailInputBox>
                <MyInfoMarketerMore
                    marketer={userDetail}
                    isApply={true}
                    submitFlag={submitFlag}
                    setSubmitFlag={setSubmitFlag}
                    isSubmitted={isSubmitted}
                    setIsSubmitted={setIsSubmitted}
                    setApplyMarketerData={setApplyMarketerData}
                />
            </MarketerDetailInputBox>
            {/* <MarketerDetailInputBox>
                <MyInfoMarketerCareer marketer={userDetail} isApply={true}  />
            </MarketerDetailInputBox> */}
            {/* <MarkterDetailButtonBox>
                <MarkterDetailButton
                    onClick={() => {
                        marketerApply()
                    }}
                >
                    저장 후 다음
                </MarkterDetailButton>
            </MarkterDetailButtonBox> */}
        </MarketerDetailContainer>
    )
}
