import React from 'react'
import tw from 'tailwind-styled-components'

import {SortAsc} from "@styled-icons/octicons/SortAsc"
import {SortDesc} from "@styled-icons/octicons/SortDesc"
import { SortTypes } from '../types/EditorTypes'

const AscIcon = tw(SortAsc)`
    h-full
`
const DescIcon = tw(SortDesc)`
    h-full
`

const SortSwitch = ({sortType, setSortType}) => {
    const handleSwitch = () => {
        if (sortType === SortTypes.ASC) {
            setSortType(SortTypes.DESC)
        } else if (sortType === SortTypes.DESC) {
            setSortType(SortTypes.ASC)
        }
    }

    return(
        <div className="flex flex-center h-full" onClick={()=>{handleSwitch()}}>
            {sortType === SortTypes.ASC && (
                <AscIcon />
            )}
            {sortType === SortTypes.DESC && (
                <DescIcon />
            )}
        </div>
    )
}

export default SortSwitch