import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"

import { MyPageUserInputCombo } from "../../../components/user/Inputs"
// import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd"
import { TableHeaderList, TestDataList } from "../../../constants/ConstTableData"
import { PerformConInner, PerformTableHeader, PerformTableHeaderBox } from "./MyPagePerformStyle"
import {
    PerformColumnControlBox,
    PerformColumnLayout,
    PerformColumnSubControlBox,
    PerformColumnSubDeleteButton,
    PerformColumnSubItemBox,
    PerformColumnSubName,
    PerformColumnSubSaveButton,
} from "./StaffPerformStyle"
import { MyPageUserInput } from "../../../components/user/Inputs"
import {
    PerformColumnFormTypes,
    PerformColumnPreviewTypes,
    PerformColumnTypeNames,
    PerformColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { MyPageCareerDelete, MyPageDataAddButton, MyPageDataSubmitButton } from "../myinfo/MyInfoMainStyle"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import Utils from "../../../utils/Utils"
import PerformColumnCombo from "../../../types/brandItems/performColumns/PerformColumnCombo"
import { QueryTypes } from "../../../types/EditorTypes"
import PerformColumnStatus from "../../../types/brandItems/performColumns/PerformColumnStatus"
import PerformColumnText from "../../../types/brandItems/performColumns/PerformColumnText"

const Combo = ({ combo, columnSubEdit }) => {
    const [name, setName] = useState("")

    useEffect(() => {
        setName(combo.name)
    }, [combo])

    const handleSaveCombo = (combo) => {
        if (confirm(`${combo.name} 저장하시겠습니까?`)) {
            let _combo = new PerformColumnCombo()

            _combo.id = combo.id
            _combo.columnID = combo.columnID
            _combo.name = name
            columnSubEdit(_combo, QueryTypes.CREATE)
        }
    }
    const handleDeleteCombo = (combo) => {
        if (confirm(`${combo.name} 삭제하시겠습니까?`)) {
            columnSubEdit(combo, QueryTypes.DELETE)
        }
    }

    return (
        <PerformColumnSubItemBox>
            <div className="flex flex-col w-full">
                <MyPageUserInput label={"이름"} type={"text"} data={name} setData={setName} />
            </div>
            <PerformColumnSubControlBox>
                <PerformColumnSubDeleteButton
                    onClick={() => {
                        handleDeleteCombo(combo)
                    }}
                >
                    X
                </PerformColumnSubDeleteButton>
                <PerformColumnSubSaveButton
                    onClick={() => {
                        handleSaveCombo(combo)
                    }}
                >
                    S
                </PerformColumnSubSaveButton>
            </PerformColumnSubControlBox>
        </PerformColumnSubItemBox>
    )
}
const ColumnCombo = ({ column, columnSubEdit }) => {
    const handleAddCombo = () => {
        const comboName = prompt("추가할 콤보 이름을 입력해주세요.")
        if (!Utils.isStringNullOrEmpty(comboName)) {
            let newCombo = new PerformColumnCombo()
            newCombo.columnID = column.id
            newCombo.name = comboName
            columnSubEdit(newCombo, QueryTypes.CREATE)
        }
    }

    return (
        <PerformColumnControlBox>
            <MyPageDataSubmitButton
                onClick={() => {
                    handleAddCombo()
                }}
            >
                추가
            </MyPageDataSubmitButton>
            {column.combos.map((combo, idx) => (
                <Combo combo={combo} columnSubEdit={columnSubEdit} key={idx} />
            ))}
        </PerformColumnControlBox>
    )
}

const Status = ({ status, columnSubEdit }) => {
    const [name, setName] = useState("")
    const [color, setColor] = useState("")
    const [bgColor, setBgColor] = useState("")
    const [brandReadOnly, setBrandReadOnly] = useState(false)
    const [marketerReadOnly, setMarketerReadOnly] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [isRequireComment, setIsRequireComment] = useState(false)

    useEffect(() => {
        setName(status.name)
        setColor(status.color)
        setBgColor(status.bgColor)
        setBrandReadOnly(status.brandReadOnly)
        setMarketerReadOnly(status.marketerReadOnly)
        setIsConfirm(status.isConfirm)
        setIsRequireComment(status.isRequireComment)
    }, [status])

    const handleColor = (value, setValue) => {
        if (!value.includes("#")) {
            value = "#" + value
        }

        if (value.length <= 7) {
            setValue(value)
        }
    }

    const handleSaveStatus = (status) => {
        if (confirm(`${status.name} 저장하시겠습니까?`)) {
            let _status = new PerformColumnStatus()

            _status.id = status.id
            _status.columnID = status.columnID
            _status.name = name
            _status.color = color
            _status.bgColor = bgColor
            _status.brandReadOnly = brandReadOnly
            _status.marketerReadOnly = marketerReadOnly
            _status.isConfirm = isConfirm
            _status.isRequireComment = isRequireComment
            columnSubEdit(_status, QueryTypes.CREATE)
        }
    }
    const handleDeleteStatus = (status) => {
        if (confirm(`${status.name} 삭제하시겠습니까?`)) {
            columnSubEdit(status, QueryTypes.DELETE)
        }
    }

    return (
        <PerformColumnSubItemBox>
            <div className="flex flex-col w-full">
                <MyPageUserInput label={"이름"} type={"text"} data={name} setData={setName} />
                <MyPageUserInput
                    label={"글씨 색상"}
                    type={"text"}
                    data={color}
                    setData={(value) => {
                        handleColor(value, setColor)
                    }}
                />
                <MyPageUserInput
                    label={"배경 색상"}
                    type={"text"}
                    data={bgColor}
                    setData={(value) => {
                        handleColor(value, setBgColor)
                    }}
                />
                <MyPageUserInput
                    label={"브랜드 Read"}
                    type={"checkbox"}
                    checked={brandReadOnly}
                    setData={(e) => {
                        setBrandReadOnly(!brandReadOnly)
                    }}
                />
                <MyPageUserInput
                    label={"마케터 Read"}
                    type={"checkbox"}
                    checked={marketerReadOnly}
                    setData={(e) => {
                        setMarketerReadOnly(!marketerReadOnly)
                    }}
                />
                <MyPageUserInput
                    label={"실적 승인"}
                    type={"checkbox"}
                    checked={isConfirm}
                    setData={(e) => {
                        setIsConfirm(!isConfirm)
                    }}
                />
                <MyPageUserInput
                    label={"커멘트 후 수정"}
                    type={"checkbox"}
                    checked={isRequireComment}
                    setData={(e) => {
                        setIsRequireComment(!isRequireComment)
                    }}
                />
                <div className={`flex w-full p-1 text-[${color}] bg-[${bgColor}]`}>현황</div>
            </div>
            <PerformColumnSubControlBox>
                <PerformColumnSubDeleteButton
                    onClick={() => {
                        handleDeleteStatus(status)
                    }}
                >
                    X
                </PerformColumnSubDeleteButton>
                <PerformColumnSubSaveButton
                    onClick={() => {
                        handleSaveStatus(status)
                    }}
                >
                    S
                </PerformColumnSubSaveButton>
            </PerformColumnSubControlBox>
        </PerformColumnSubItemBox>
    )
}
const ColumnStatus = ({ column, columnSubEdit, showUpdateColumn }) => {
    const handleAddStatus = () => {
        const comboName = prompt("추가할 현황 이름을 입력해주세요.")
        if (!Utils.isStringNullOrEmpty(comboName)) {
            let newStatus = new PerformColumnStatus()
            newStatus.columnID = column.id
            newStatus.name = comboName
            columnSubEdit(newStatus, QueryTypes.CREATE)
        }
    }

    return (
        <PerformColumnControlBox>
            <MyPageDataSubmitButton
                onClick={() => {
                    showUpdateColumn(column)
                }}
            >
                {/* <MyPageDataSubmitButton onClick={()=>{handleAddStatus()}}> */}
                설정
            </MyPageDataSubmitButton>
            {/* {column.statuses.map((status, idx) => (
                <Status status={status} columnSubEdit={columnSubEdit} key={idx} />
            ))} */}
        </PerformColumnControlBox>
    )
}

const Text = ({ text, columnSubEdit }) => {
    const [name, setName] = useState("")
    const [isButtonType, setButtonType] = useState(false)

    useEffect(() => {
        setName(text.name)
        setButtonType(text.previewType == PerformColumnPreviewTypes.BUTTON)
    }, [text])

    const handleSaveText = (text) => {
        if (confirm(`${text.name} 저장하시겠습니까?`)) {
            let _text = new PerformColumnText()

            _text.id = text.id
            _text.columnID = text.columnID
            _text.name = name
            _text.previewType = isButtonType ? PerformColumnPreviewTypes.BUTTON : PerformColumnPreviewTypes.TRUNCATE
            columnSubEdit(_text, QueryTypes.CREATE)
        }
    }
    const handleDeleteText = (text) => {
        if (confirm(`${text.name} 삭제하시겠습니까?`)) {
            columnSubEdit(text, QueryTypes.DELETE)
        }
    }

    return (
        <PerformColumnSubItemBox>
            <div className="flex flex-col w-full">
                {/* <MyPageUserInput
                    label={"이름"}
                    type={"text"}
                    data={name}
                    setData={setName}
                /> */}
                <MyPageUserInput
                    label={"버튼 타입으로 표시"}
                    type={"checkbox"}
                    checked={isButtonType}
                    setData={(e) => {
                        setButtonType(!isButtonType)
                    }}
                />
            </div>
            <PerformColumnSubControlBox>
                {/* <PerformColumnSubDeleteButton onClick={()=>{handleDeleteText(text)}}>
                    X
                </PerformColumnSubDeleteButton> */}
                <PerformColumnSubSaveButton
                    onClick={() => {
                        handleSaveText(text)
                    }}
                >
                    S
                </PerformColumnSubSaveButton>
            </PerformColumnSubControlBox>
        </PerformColumnSubItemBox>
    )
}
const ColumnText = ({ column, columnSubEdit }) => {
    useEffect(() => {
        // 텍스트 서브 컬럼이 없을 경우 자동으로 생성한다.
        if (column.id >= 0 && column.formType == PerformColumnFormTypes.TEXT && column.texts.length === 0) {
            let newText = new PerformColumnText()
            newText.columnID = column.id
            newText.name = `${column.name}-text`
            columnSubEdit(newText, QueryTypes.CREATE)
        }
    }, [column])
    // const handleAddText = () => {
    //     const textName = prompt("추가할 텍스트 컬럼 이름을 입력해주세요.")
    //     if (!Utils.isStringNullOrEmpty(textName)) {
    //         let newText = new PerformColumnText()
    //         newText.columnID = column.id
    //         newText.name = textName
    //         columnSubEdit(newText, QueryTypes.CREATE)
    //     }
    // }

    return (
        <PerformColumnControlBox>
            {/* <MyPageDataSubmitButton onClick={()=>{handleAddText()}}>
                추가
            </MyPageDataSubmitButton> */}
            {column.texts.map((text, idx) => (
                <Text text={text} columnSubEdit={columnSubEdit} key={idx} />
            ))}
        </PerformColumnControlBox>
    )
}

const ColumnOpenSetting = ({ column, showUpdateColumn }) => {
    return (
        <PerformColumnControlBox>
            <MyPageDataSubmitButton
                onClick={() => {
                    showUpdateColumn(column)
                }}
                className="bg-emerald-500"
            >
                설정
            </MyPageDataSubmitButton>
        </PerformColumnControlBox>
    )
}

const ColumnBase = ({ column, columnSave, columnOrder, formType, setFormType, columnDelete }) => {
    const [name, setName] = useState("")
    const [width, setWidth] = useState("")
    const [brandReadOnly, setBrandReadOnly] = useState(false)
    const [marketerReadOnly, setMarketerReadOnly] = useState(false)
    const [isBaseAdd, setIsBaseAdd] = useState(false)
    const [isBaseEdit, setIsBaseEdit] = useState(false)
    const [isExcelShow, setIsExcelShow] = useState(false)

    const [isFilterShortcut, setIsFilterShortcut] = useState(false)

    const [isEditableConfirmed, setIsEditableConfirmed] = useState(false)

    //write
    useEffect(() => {
        setName(column.name)
        setFormType(column.formType)
        setWidth(column.width)
        setBrandReadOnly(column.brandReadOnly)
        setMarketerReadOnly(column.marketerReadOnly)
        setIsBaseAdd(column.isBaseAdd)
        setIsBaseEdit(column.isBaseEdit)
        setIsFilterShortcut(column.isFilterShortcut)
        setIsExcelShow(column.isExcelShow)
        setIsEditableConfirmed(column.isEditableConfirmed)
    }, [column])

    //read
    const handleSave = () => {
        let _column = new PerformColumn()

        _column.id = column.id
        _column.itemID = column.itemID
        _column.type = column.type
        _column.formType = formType
        _column.name = name
        _column.width = width
        _column.brandReadOnly = brandReadOnly
        _column.marketerReadOnly = marketerReadOnly
        _column.isBaseAdd = isBaseAdd
        _column.isBaseEdit = isBaseEdit
        _column.isFilterShortcut = isFilterShortcut
        _column.isExcelShow = isExcelShow
        _column.isEditableConfirmed = isEditableConfirmed

        columnSave(_column)
    }

    const handleOrder = (direction) => {
        columnOrder(column, direction)
    }

    const handleDelete = () => {
        if (confirm(`${column.name} 컬럼을 삭제하시겠습니까?`)) {
            columnDelete(column.id)
        }
    }

    const handleFormType = (value) => {
        setFormType(value)
        // column.formType = value
    }

    const isStaticColumn = column.id >= 0

    return (
        <PerformColumnControlBox>
            <MyPageUserInput label={"이름"} type={"text"} data={name} setData={setName} readOnly={!isStaticColumn} />
            {isStaticColumn ? (
                <MyPageUserInputCombo
                    label={`타입`}
                    optionKeys={Object.values(PerformColumnFormTypes)}
                    names={Object.values(PerformColumnFormTypes).map((typeKey) => PerformColumnTypeNames[typeKey])}
                    value={formType}
                    onChange={(e) => {
                        handleFormType(e.target.value)
                    }}
                />
            ) : (
                <MyPageUserInput label={"타입"} type={"text"} data={PerformColumnTypeNames[formType]} readOnly />
            )}
            <MyPageUserInput label={"너비"} type={"text"} data={width} setData={setWidth} readOnly={!isStaticColumn} />
            <MyPageUserInput
                label={"브랜드 Read"}
                type={"checkbox"}
                checked={brandReadOnly}
                setData={(e) => {
                    setBrandReadOnly(!brandReadOnly)
                }}
                readOnly={!isStaticColumn}
            />
            <MyPageUserInput
                label={"마케터 Read"}
                type={"checkbox"}
                checked={marketerReadOnly}
                setData={(e) => {
                    setMarketerReadOnly(!marketerReadOnly)
                }}
                readOnly={!isStaticColumn}
            />
            <MyPageUserInput
                label={"추가 모달에서 설정 여부"}
                type={"checkbox"}
                checked={isBaseAdd}
                setData={(e) => {
                    setIsBaseAdd(!isBaseAdd)
                }}
                // readOnly={!isStaticColumn}
                readOnly={!(isStaticColumn && column.formType !== PerformColumnFormTypes.STATUS)}
            />
            <MyPageUserInput
                label={"수정 모달에서 설정 여부"}
                type={"checkbox"}
                checked={isBaseEdit}
                setData={(e) => {
                    setIsBaseEdit(!isBaseEdit)
                }}
                // readOnly={!isStaticColumn}
                readOnly={!(isStaticColumn && column.formType !== PerformColumnFormTypes.STATUS)}
            />
            <MyPageUserInput
                label={"필터 숏컷 여부"}
                type={"checkbox"}
                checked={isFilterShortcut}
                setData={(e) => {
                    setIsFilterShortcut(!isFilterShortcut)
                }}
                readOnly={
                    !(
                        isStaticColumn &&
                        column.formType !== PerformColumnFormTypes.FILE &&
                        column.formType !== PerformColumnFormTypes.DATE &&
                        column.formType !== PerformColumnFormTypes.OPTION
                    )
                }
            />
            <MyPageUserInput
                label={"엑셀 표시 유무"}
                type={"checkbox"}
                checked={isExcelShow}
                setData={(e) => {
                    setIsExcelShow(!isExcelShow)
                }}
                readOnly={
                    !(
                        isStaticColumn &&
                        column.formType !== PerformColumnFormTypes.FILE &&
                        column.formType !== PerformColumnFormTypes.DATE &&
                        column.formType !== PerformColumnFormTypes.OPTION
                    )
                }
            />
            <MyPageUserInput
                label={"승인 후 수정 여부"}
                type={"checkbox"}
                checked={isEditableConfirmed}
                setData={(e) => {
                    setIsEditableConfirmed(!isEditableConfirmed)
                }}
                readOnly={!isStaticColumn}
            />
            <div className="flex justify-center w-full py-2">
                <MyPageDataAddButton
                    onClick={() => {
                        handleOrder(-1)
                    }}
                >
                    {"<"}
                </MyPageDataAddButton>
                <MyPageDataAddButton
                    onClick={() => {
                        handleOrder(1)
                    }}
                >
                    {">"}
                </MyPageDataAddButton>
            </div>
            <MyPageDataSubmitButton
                onClick={() => {
                    handleSave()
                }}
            >
                저장
            </MyPageDataSubmitButton>
            <MyPageCareerDelete
                onClick={() => {
                    handleDelete()
                }}
            >
                삭제
            </MyPageCareerDelete>
        </PerformColumnControlBox>
    )
}

const PerformColumnContainer = ({
    column,
    columnSave,
    columnOrder,
    columnSubEdit,
    columnDelete,
    showUpdateColumn,
    idx,
}) => {
    const width = column.widthCalculated
    const [formType, setFormType] = useState(PerformColumnFormTypes.NONE)

    return (
        <PerformColumnLayout
            className={`min-w-[${width}px] w-[${width}px] max-w-[${width}px] sticky top-0 left-0  ${
                idx === 0 ? "z-10" : ""
            }`}
        >
            <ColumnBase
                column={column}
                columnSave={columnSave}
                columnOrder={columnOrder}
                columnDelete={columnDelete}
                formType={formType}
                setFormType={setFormType}
            />
            {/* {formType == PerformColumnFormTypes.COMBO && (
                <ColumnCombo column={column} columnSubEdit={columnSubEdit} />
            )} */}
            {formType == PerformColumnFormTypes.COMBO && (
                <ColumnOpenSetting column={column} showUpdateColumn={showUpdateColumn} />
            )}
            {/* {formType == PerformColumnFormTypes.STATUS && (
                <ColumnStatus column={column} columnSubEdit={columnSubEdit} showUpdateColumn={showUpdateColumn} />
            )} */}
            {formType == PerformColumnFormTypes.STATUS && (
                <ColumnOpenSetting column={column} showUpdateColumn={showUpdateColumn} />
            )}
            {formType == PerformColumnFormTypes.TEXT && column.id >= 0 && (
                <ColumnText column={column} columnSubEdit={columnSubEdit} />
            )}
            {formType == PerformColumnFormTypes.OPTION && (
                <ColumnOpenSetting column={column} showUpdateColumn={showUpdateColumn} />
            )}
            {formType == PerformColumnFormTypes.DATE && (
                <ColumnOpenSetting column={column} showUpdateColumn={showUpdateColumn} />
            )}
        </PerformColumnLayout>
    )
}

const PerformColumnHeader = ({ columns, columnWidthSum }) => {
    return (
        <PerformTableHeaderBox className={`min-w-[${columnWidthSum}px] max-w-[${columnWidthSum}px] `}>
            <div className={`flex w-full bg-slate-500`}>
                {columns.map((column, idx) => {
                    let width = column.widthCalculated
                    let widthrounded = column.widthCalculated - 4

                    return (
                        <div
                            key={idx}
                            className={`min-w-[${width}px] max-w-[${width}px] sticky top-0 left-0  ${
                                idx === 0 || columns.length - 1 === idx ? " z-20 bg-white" : "z-10 bg-nightblue-600"
                            }`}
                        >
                            <PerformTableHeader
                                id={column.id}
                                className={`
                                    ${
                                        idx === 0
                                            ? `w-[${widthrounded}px] bg-nightblue-600 absolute right-0 rounded-l-lg`
                                            : ""
                                    }
                                    ${
                                        columns.length - 1 === idx
                                            ? `w-[${widthrounded}px] bg-nightblue-600 absolute left-0 rounded-r-lg`
                                            : ""
                                    }
                                `}
                            >
                                <span>{column.name}</span>
                                {column.brandReadOnly && (
                                    <div className="absolute font-bold text-red-300 rounded-full border border-red-500 aspect-square h-6 flex flex-center left-0 top-5">
                                        B
                                    </div>
                                )}
                                {column.marketerReadOnly && (
                                    <div className="absolute font-bold text-red-300 rounded-full border border-red-500 aspect-square h-6 flex flex-center left-7 top-5">
                                        M
                                    </div>
                                )}
                            </PerformTableHeader>
                        </div>
                    )
                })}
            </div>
        </PerformTableHeaderBox>
    )
}

export default function StaffPerformTable({
    columns,
    columnSave,
    columnOrder,
    columnSubEdit,
    columnDelete,
    showUpdateColumn,
}) {
    const [columnWidthSum, setColumnWidthSum] = useState(0)

    // Column 정렬
    useEffect(() => {
        let widthSum = 0
        columns.map((column) => {
            widthSum += Number(column.widthCalculated)
        })
        setColumnWidthSum(widthSum)
    }, [columns])

    return (
        <PerformConInner>
            <PerformColumnHeader columns={columns} columnWidthSum={columnWidthSum} />
            <div className="flex w-full">
                {columns.map((column, idx) => (
                    <PerformColumnContainer
                        column={column}
                        columnSave={columnSave}
                        columnOrder={columnOrder}
                        columnSubEdit={columnSubEdit}
                        columnDelete={columnDelete}
                        showUpdateColumn={showUpdateColumn}
                        idx={idx}
                        key={idx}
                    />
                ))}
            </div>
        </PerformConInner>
    )
}
