import Utils from "../../../utils/Utils"
import User from "../../users/User"
import UserMarketer from "../../users/UserMarketer"
import { MarketerStatusTypes, MarketerSuspendStatusTypes } from "../BrandItemTypes"
import BrandItemMarketerGroup from "./BrandItemMarketerGroup"

export default class {
    constructor() {
        this.id = -1
        this.index = 0
        this.itemID = -1
        this.parentID = -1
        this.marketer = new UserMarketer()
        this.marketerName = ""
        this.marketerNameChain = ""
        this.marketerStatusType = MarketerStatusTypes.WAIT
        this.marketerSuspendStatusType = MarketerSuspendStatusTypes.WAIT
        this.isInvitePermission = false
        this.group = new BrandItemMarketerGroup()
        this.performs = 0
        this.performConfirms = 0
        this.calculates = 0
        this.invites = 0
        this.invited = false

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.index = response["index"]
        this.marketerName = response["marketer_name"]
        this.marketerNameChain = response["marketer_name_chain"]
        this.marketerStatusType = response["marketer_status_type"]
        this.marketerSuspendStatusType = response["marketer_suspend_status_type"]
        this.isInvitePermission = response["is_invite_permission"]

        const marketerResponse = response["marketer"]
        this.marketer = new UserMarketer()
        if (marketerResponse !== undefined) {
            this.marketer.convertByResponse(marketerResponse)
        }

        this.itemID = response["item_id"]
        if (this.itemID === null) {
            this.itemID = -1
        }
        this.parentID = response["parent_id"]
        if (this.parentID === null) {
            this.parentID = -1
        }
        this.performs = response["performs"]
        if (this.performs === null) {
            this.performs = 0
        }
        this.performConfirms = response["perform_confirms"]
        if (this.performConfirms === null) {
            this.performConfirms = 0
        }
        this.calculates = response["calculates"]
        if (this.calculates === null) {
            this.calculates = 0
        }
        this.invites = response["invites"]
        if (this.invites === null) {
            this.invites = 0
        }

        const groupResponse = response["group"]
        this.group = new BrandItemMarketerGroup()
        if (groupResponse !== null && groupResponse !== undefined) {
            this.group.convertByResponse(groupResponse)
        }

        if (!Utils.isNullOrUndefined(response.invited)) {
            this.invited = response["invited"]
        }

        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {}
}
