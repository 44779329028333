import React from 'react'
import {Handshake} from '@styled-icons/fa-solid/Handshake'
import {PersonFill} from '@styled-icons/bootstrap/PersonFill'
import {Basket3Fill} from '@styled-icons/bootstrap/Basket3Fill'
import {MiscellaneousServices} from '@styled-icons/material-outlined/MiscellaneousServices'
import {Bulb} from '@styled-icons/ionicons-solid/Bulb'
import { BrandItemFilterCategory } from '../types/brandItems/BrandItemOption'
import { ItemSalesTypes, MarketerTypes } from '../types/brandItems/BrandItemTypes'

export const CARD_CATEGORY_DATA_LIST = [

    {
        filterCategory: BrandItemFilterCategory.ItemSales,
        filterID: ItemSalesTypes.SOLUTON,
        smtitle:'상품 유형',
        title:'솔루션',
        icon:<Bulb className="w-4/6"/>,
        colorbox:'#ccaaff',
        colorboxHover:'#c6a1fd',
        colorIcon:'#a875f5',
        colorcircle:'bg-rose-300',
        description: "복잡한 문제나 \n도전 과제를 해결하는 \n도구, 시스템, \n또는 전략을 제공하는 것"
    },
    {
        filterCategory: BrandItemFilterCategory.ItemSales,
        filterID: ItemSalesTypes.SERVICE,
        smtitle:'상품 유형',
        title:'서비스',
        icon:<MiscellaneousServices className="w-4/6"/>,
        colorbox:'#98dbc6',
        colorboxHover:'#8ed4be',
        colorIcon:'#5b9784',
        colorcircle:'bg-orange-300',
        description: "물리적 제품이 아닌, \n지식이나 노하우를 바탕으로 \n고객의 요구를 충족시키는 \n활동이나 프로세스입니다"
    },
    {
        filterCategory: BrandItemFilterCategory.ItemSales,
        filterID: ItemSalesTypes.PRODUCT,
        smtitle:'상품 유형',
        title:'제품',
        icon:<Basket3Fill className="w-4/6"/>,
        colorbox:'#85ccff',
        colorboxHover:'#79c6fd',
        colorIcon:'#529fd6',
        colorcircle:'bg-sky-300',
        description: "물리적인 또는 디지털 \n형태의 구체적인 \n제품을 포함합니다"
    },
    {
        filterCategory: BrandItemFilterCategory.Marketer,
        filterID: MarketerTypes.PRO,
        smtitle:'마케터 유형',
        title:'프로 마케터',
        icon:<Handshake className="w-4/6"/>,
        // colorbox:'#efcb98',
        // colorboxHover:'#ecc288',
        // colorIcon:'#c18c47',
        colorbox:'#97b8ff',
        colorboxHover:'#8caef7',
        colorIcon:'#6188dc',
        colorcircle:'bg-indigo-300',
        description: "기업이나 조직의 공식적인 \n영업팀의 일원으로서 \n전문적인 마케팅 활동을 \n수행하는 사람."
    },
    {
        filterCategory: BrandItemFilterCategory.Marketer,
        filterID: MarketerTypes.PRE,
        smtitle:'마케터 유형',
        title:'프리 마케터',
        icon:<PersonFill className="w-4/6"/>,
        // colorbox:'#c5d863',
        // colorboxHover:'#b6cb4b',
        // colorIcon:'#95aa30',
        colorbox:'#FDBA74',
        colorboxHover:'#fcb05e',
        colorIcon:'#e1933f',
        colorcircle:'bg-violet-300',
        description: "자유롭게 영업 \n활동을 수행하는 \n독립적인 영업사원 \n또는 자유 직업자."
    },
]