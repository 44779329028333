import { debounce } from "lodash"
import react, {useState, useEffect, useCallback} from "react"

export const useWindowHeight = () => {
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(()=>{
        window.addEventListener("resize", handleResize)
        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const handleResize = debounce(()=>{
        setHeight(window.innerHeight)
    }, 300)

    return height
}