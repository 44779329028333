import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useFocus } from "../../../../hooks/useFocus"
import { useMouseHover } from "../../../../hooks/useMouseHover"
import { getUserMarketers } from "../../../../apis/api/users"
import { getItemMarketers } from "../../../../apis/api/boardItems"
import {
    PerformUpdateColumnLayout,
    PerformUpdateColumnTitle,
    PerformUpdateValueBox,
} from "../../perform/MyPagePerformStyle"
import { FilterInputSearchButton, FilterInputSearchIcon } from "../../../../components/brandItems/FilterInputsStyle"
import ItemMarketerNameChain from "../../../../components/brandItems/ItemMarketerNameChain"

const PAGE_SIZE = 20

export default function StaffUserPointMarketerSearch({ recommander, marketerID, setMarketerID, initSearch }) {
    const [search, setSearch] = useState(initSearch)
    const [marketers, setMarketers] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()

    const [focusRef, isFocus] = useFocus()
    const [hoverRef, isHover] = useMouseHover()

    useEffect(() => {
        getMarketers()
    }, [recommander, pageIndex])

    useEffect(() => {
        getUserMarketers(1, PAGE_SIZE, "", "-id").then((pagination) => {
            setMarketers(pagination.items)
            setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
        })
    }, [recommander])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && marketers.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            setPageIndex(pageIndex + 1)
        }
    }, [scrollInView])

    const getMarketers = async () => {
        const pagination = await getUserMarketers(pageIndex, PAGE_SIZE, search, "-id")

        if (pagination.count > 0) {
            if (pageIndex === 1) {
                setMarketers(pagination.items)
                setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
            } else {
                setMarketers([...marketers, ...pagination.items])
            }
        } else {
            setMarketers([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        if (pageIndex === 1) {
            getMarketers()
        } else {
            setPageIndex(1)
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSelect = (marketer) => {
        setMarketerID(marketer.user.id)
        setSearch(marketer.user.name)
    }

    return (
        <PerformUpdateColumnLayout readOnly={false} className="sm:px-0 pt-0">
            {/* <PerformUpdateColumnTitle>마케터</PerformUpdateColumnTitle> */}
            <PerformUpdateValueBox ref={hoverRef} className="relative">
                <div className="flex justify-between items-center w-full p-2 rounded-lg border bg-white">
                    <input
                        ref={focusRef}
                        type="text"
                        className="w-full px-2 focus:outline-none disabled:bg-gray-100"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={handleEnter}
                    />
                    <FilterInputSearchButton
                        className="max-lg:h-7 lg:h-7 px-2"
                        onClick={() => {
                            handleSearch()
                        }}
                    >
                        <FilterInputSearchIcon />
                    </FilterInputSearchButton>
                </div>
                {(isFocus || isHover) && (
                    <div className="absolute z-20 top-9 flex flex-col w-full max-h-[14rem] p-2 bg-white rounded-b-lg border border-t-0 overflow-y-auto scroll-transparent scroll-overlay">
                        {marketers.map((marketer, index) => (
                            <div
                                key={index}
                                ref={index !== 0 ? scrollRef : null}
                                className={`flex items-center w-full h-9 p-2 rounded-lg ${
                                    marketer.user.id === marketerID ? "bg-blue-100" : "hover:bg-slate-100"
                                } cursor-pointer space-x-2`}
                                onClick={() => {
                                    handleSelect(marketer)
                                }}
                            >
                                <span>{marketer.user.name}</span>
                                <span className="text-slate-500">{marketer.user.email}</span>
                                <span className="text-slate-500">{marketer.user.tel}</span>
                            </div>
                        ))}
                    </div>
                )}
            </PerformUpdateValueBox>
        </PerformUpdateColumnLayout>
    )
}
