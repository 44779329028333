
export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.name = ""

        this.isMultiSelect = false // 라디오타입인지 체크박스타입인지 여부
        this.hasEtc = false // 기타(직접입력)란 존재 여부
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["column_id"]
        this.name = response["name"]

        this.isMultiSelect = response["is_multi_select"]
        this.hasEtc = response["has_etc"]
    }

    convertToRequest() {
        let request = {
            id: -1,
            column_id: 0,
            name: "",
        }

        request.id = this.id
        request.column_id = this.columnID
        request.name = this.name

        request.is_multi_select = this.isMultiSelect
        request.has_etc = this.hasEtc
        return request
    }
}