import React, { useState,useEffect,useRef } from "react";
import { useParams,Link,useNavigate } from 'react-router-dom';
import { createBrandItem, getBrandItemView, registItemMarketer, updateBrandItem } from "../../apis/api/boardItems";
import { ImageBase64, ImageContainer } from "../../components/ImageContainer";
import {ITEM_DATA} from "../../constants/ConstItem"
import BrandItem from "../../types/brandItems/BrandItem";
import { ItemSalesTypeNames, MarketerTypeNames } from "../../types/brandItems/BrandItemTypes";
import {ItemApplyBrandContainer, ItemApplyBrandContents, ItemApplyBrandDescription, ItemApplyBrandDescriptionContainer, ItemApplyBrandIconContainer, ItemApplyBrandTitle, ItemApplyBrandTitleContainer, ItemApplyDetail, ItemDetailApplyContents, ItemDetailApplyItem, ItemDetailApplyItemContainer, ItemDetailApplyItemContents, ItemDetailApplyItemDescription, ItemDetailApplyButton, ItemDetailContainer,ItemDetailContentContainer,ItemDetailContentDiv,ItemDetailContentTitle,ItemDetailImageContainer,ItemDetailIntroduce,ItemDetailNav,ItemDetailNavInner,ItemDetailPageContainer, ItemDetailShareContainer, ItemDetailThumbnail, ItemDetailThumbnailContainer, ItemDetailThumbnailTitle, ShareButton, ShareIcon, ItemDetailApplyMarketerStatus, ItemDetailTitleContainer, ItemDetailThumbDataContainer, ItemDetailNavContainer, ItemDetailPreviewControlContainer, ItemDetailPreviewControlLayout, ItemDetailPreviewControlBox, ItemDetailPreviewCloseButton, ItemDetailPreviewSubmitButton} from './ItemDetailStyle'
import { UserTypes } from '../../types/users/UserTypes';
import { useUserStore } from '../../zustand/store'
import { useLoginModalStore,marketerApplyModal} from '../../zustand/modalstore'
import UserAvatar from "../../components/user/UserAvatar";
import tw from 'tailwind-styled-components'
import AccessRules from "../../rules/AccessRules";
import {MarketerStatusTypes} from  '../../types/brandItems/BrandItemTypes'
import FileUtils from "../../utils/FileUtils";
import { registItemByMarketer } from '../../apis/services/brandItems';
import Utils from "../../utils/Utils";
import ReactQuillContent from "../../components/boards/ReactQuillContent";
import ConstSession from "../../constants/ConstSession";
import { EditStateTypes, QueryTypes } from "../../types/EditorTypes";
import ConstLocalStorage from "../../constants/ConstLocalStorage";
import BrandItemUtils from "../../utils/brandItem/BrandItemUtils";
import CKEditorContent from "../../components/boards/CKEditorContent";

const getPreviewItemByLocalStorage = () => {
    const itemDataJson = sessionStorage.getItem(ConstSession.ITEM_PREVIEW_DATA)
    // 미리보기에서 승인 요청을 했는지 확인하기 위한 값 초기화
    localStorage.setItem(ConstLocalStorage.ITEM_PREVIEW_SUBMITTED, "0")
    const itemData = JSON.parse(itemDataJson)
    const item = new BrandItem()
    item.convertByRequest(itemData)

    return item
}

export default function ItemDetailPreivew() {
    const { user } = useUserStore(state => state)
    const navigate = useNavigate();

    const previewBodyRef = useRef(null)

    const [queryType, setWriteType] = useState(QueryTypes.NONE)
    const [item, setItem] = useState(new BrandItem())

    const [descriptionContent, setDescriptionContent] = useState("")
    const [missionContent, setMissionContent] = useState("")
    const [rewardContent, setRewardContent] = useState("")
    const [contractContent, setContractContent] = useState("")

    const [activeSection, setActiveSection] = useState(1)
    const DescriptionRef = useRef(null)
    const MissionRef = useRef(null)
    const RewardRef = useRef(null)
    const ContractRef = useRef(null)

    const [isConfirmed, setConfirmed] = useState(false) // 스크롤을 맨 아래까지 내려서 내용을 다 확인했는지 여부
    const [maxScroll, setMaxScroll] = useState(-1) // 스크롤을 어디까지 내렸는지에 저장
    const [contentHeight, setContentHeight] = useState(0) // 스크롤 길이 저장

    const close = () => {
        sessionStorage.removeItem(ConstSession.ITEM_PREVIEW_DATA)
        window.close()
    }

    useEffect(() => {
        if (window !== null) {
            window.addEventListener("scroll", handleScroll)
        }

        getPreviewItem()

        return () => {
            if (window !== null) {
                window.removeEventListener("scroll", handleScroll)
            }
            close()   
        }
    }, [])

    useEffect(()=>{
        setConfirmed(false)
    }, [contentHeight])

    useEffect(()=>{
        // 초기값인 경우 제외
        if (maxScroll >= 0 && maxScroll >= contentHeight - 150) {
            setConfirmed(true)
        }
    }, [maxScroll])

    const getPreviewItem = () => {
        const item = getPreviewItemByLocalStorage()
        // if (item.id < 0) {
        //     alert("내용을 찾을 수 없습니다.\n다시 시도해주세요.")
        //     navigate("/")
        // }

        try {
            setItem(item)
            initContractContent(item)

            setWriteType(item.id >= 0 ? QueryTypes.UPDATE : QueryTypes.CREATE)

            setTimeout(()=>{
                handleScrollConfirm()
            }, 300)
        } catch {
            //
        }
    }

    const initContractContent = (_item) => {
        setDescriptionContent(FileUtils.replaceMedieFileURL(_item.detail.description))
        setMissionContent(FileUtils.replaceMedieFileURL(_item.contract.mission))
        setRewardContent(FileUtils.replaceMedieFileURL(_item.contract.reward))
        setContractContent(FileUtils.replaceMedieFileURL(_item.contract.contract))
    }


    const handleScroll = () => {
        const outlet = window
        let offset = 106
        const descripTop = DescriptionRef.current.offsetTop - offset
        const missionTop = MissionRef.current.offsetTop - offset 
        const reawrdTop = RewardRef.current.offsetTop - offset 
        const contractTop = ContractRef.current.offsetTop - offset 
        // const scrollY = outlet.scrollTop
        const scrollY = window.screenY

        if (scrollY > descripTop && scrollY < missionTop) {
            setActiveSection(1)
        } else if (scrollY > missionTop && scrollY < reawrdTop) {
            setActiveSection(2)
        } else if (scrollY > reawrdTop && scrollY < contractTop) {
            setActiveSection(3)
        } else if (scrollY > contractTop){
            setActiveSection(4)
        } else{
            setActiveSection(1)
        }

        handleScrollConfirm()
    }

    // 스크롤을 다 내려서 내용을 확인했는지 여부를 위한 함수
    const handleScrollConfirm = () => {
        const { scrollTop, scrollHeight, clientHeight } = window
        setContentHeight(scrollHeight)

        const scroll = scrollTop + clientHeight
        if (maxScroll < scroll) {
            setMaxScroll(scroll)
        }
    }
    
    const handleNavLinkClick = (section) => {
        let ref
        let offset = 104
        switch (section) {
            case 1:
                ref = DescriptionRef
                break
            case 2:
                ref = MissionRef
                break
            case 3:
                ref = RewardRef
                break
            case 4:
                ref = ContractRef
                break
            default:
                break
        }
    
        if (ref) {
            const outlet = window
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
            // outlet.scrollTo({
            //     top: ref.current.offsetTop - offset,
            //     behavior: "smooth",
            // })
        }
    }

    const submit = async () => {
        let bResult = false

        const localStorageItem = getPreviewItemByLocalStorage()
        if (item.id !== localStorageItem.id) {
            alert("요청할 수 없는 상품입니다.\n미리보기를 다시 실행해주세요.")
            navigate("/")
            return
        }

        if (!BrandItemUtils.validateItemUpdate(item)) {
            alert("유효하지 않은 내용입니다.")
            return
        }        

        if (!confirm(`승인 요청을하면 내용을 수정하거나 요청을 취소할 수 없습니다.\n${queryType === QueryTypes.UPDATE ? "수정" : "등록"} 승인 요청 하시겠습니까?`)) {
            return
        }

        if (Utils.isStringNullOrEmpty(item.detail.title)) {
            alert("제목은 빈 값으로 설정할 수 없습니다.")
            return
        }

        if (queryType === QueryTypes.UPDATE || item.editStateType === EditStateTypes.PENDING) {
            bResult = await updateBrandItem(item.id, item.convertToRequest())
        } else if (queryType === QueryTypes.CREATE) {
            bResult = await createBrandItem(item.convertToRequest())
        }

        if (bResult) {
            alert("승인 요청되었습니다.")
            localStorage.setItem(ConstLocalStorage.ITEM_PREVIEW_SUBMITTED, "1")
            close()
        } else {
            alert("요청 실패했습니다.")
        }
    }
    
    return(
        <div className="w-full h-full overflow-y-auto scroll-transparent scoll-overlay" ref={previewBodyRef}>
            {!item.isEditRequested && (
                <ItemDetailPreviewControlContainer>
                    <ItemDetailPreviewControlLayout>
                        <ItemDetailPreviewControlBox>
                            <ItemDetailPreviewCloseButton onClick={()=>{close()}}>
                                닫기
                            </ItemDetailPreviewCloseButton>
                            <ItemDetailPreviewSubmitButton onClick={()=>{submit()}} disabled={!isConfirmed}>
                                {queryType === QueryTypes.UPDATE ? "수정" : "등록"}
                            </ItemDetailPreviewSubmitButton>
                        </ItemDetailPreviewControlBox>
                    </ItemDetailPreviewControlLayout>
                </ItemDetailPreviewControlContainer>
            )}

            <ItemDetailPageContainer className="max-md:pt-0 md:pt-0 pb-8">
                <ItemDetailContainer>
                    <ItemDetailIntroduce>
                        {/* <ItemDetailTitleContainer>
                            <ItemDetailThumbnailTitle>
                                {item.detail.title}
                            </ItemDetailThumbnailTitle>
                            <ItemDetailShareContainer>
                                <ShareButton>
                                    <ShareIcon/>
                                </ShareButton>
                            </ItemDetailShareContainer>
                        </ItemDetailTitleContainer> */}
                        <ItemDetailThumbDataContainer>
                            {/* 썸네일 이미지 */}
                            <ItemDetailThumbnail>
                                <ImageBase64 imageID={item.detail.thumbnailImageID} />
                            </ItemDetailThumbnail>
                            {/* 정보 박스 */}
                            <ItemDetailApplyItem>
                                <ItemDetailThumbnailTitle className="mb-6">
                                    {item.detail.title}
                                </ItemDetailThumbnailTitle>
                                <ItemDetailApplyContents>
                                    <ItemDetailApplyItemDescription>
                                        상품 유형
                                    </ItemDetailApplyItemDescription>
                                    <ItemDetailApplyItemContents >
                                        {ItemSalesTypeNames[item.detail.itemSalesType]}
                                    </ItemDetailApplyItemContents>
                                </ItemDetailApplyContents>
                                    
                                <ItemDetailApplyContents>
                                    <ItemDetailApplyItemDescription>
                                        마케터 타입
                                    </ItemDetailApplyItemDescription>
                                    <ItemDetailApplyItemContents >
                                        {MarketerTypeNames[item.detail.marketerType]}
                                    </ItemDetailApplyItemContents>
                                </ItemDetailApplyContents>
                                <ItemDetailApplyContents>
                                    <ItemDetailApplyItemDescription>
                                        영업 업무
                                    </ItemDetailApplyItemDescription>
                                    <ItemDetailApplyItemContents >
                                        {item.detail.missionMessage}
                                    </ItemDetailApplyItemContents>
                                </ItemDetailApplyContents>
                                <ItemDetailApplyContents>
                                    <ItemDetailApplyItemDescription>
                                        실적 보상
                                    </ItemDetailApplyItemDescription>
                                    <ItemDetailApplyItemContents >
                                        {item.detail.rewardMessage}
                                    </ItemDetailApplyItemContents>
                                </ItemDetailApplyContents>
                                {/* <ItemDetailApplyContents>
                                    <ItemDetailApplyItemDescription>
                                        기간
                                    </ItemDetailApplyItemDescription>
                                    <ItemDetailApplyItemContents >
                                        {item.detail.dateStart} ~ {item.detail.dateEnd}
                                    </ItemDetailApplyItemContents>
                                </ItemDetailApplyContents> */}
                                <ItemDetailApplyContents className="mt-auto mb-0">
                                    <ItemDetailApplyButton>
                                        지원하기
                                    </ItemDetailApplyButton>
                                </ItemDetailApplyContents>
                            </ItemDetailApplyItem>
                        </ItemDetailThumbDataContainer>
                    </ItemDetailIntroduce>
                    <ItemApplyDetail>
                        <ItemDetailNavContainer className="">
                            <ItemDetailNav>
                                <ItemDetailNavInner isnav={activeSection===1} onClick={() => handleNavLinkClick(1)}>
                                    상품 상세
                                </ItemDetailNavInner>
                                <ItemDetailNavInner isnav={activeSection===2} onClick={() => handleNavLinkClick(2)}>
                                    영업 업무
                                </ItemDetailNavInner>
                                <ItemDetailNavInner isnav={activeSection===3} onClick={() => handleNavLinkClick(3)}>
                                    실적 보상
                                </ItemDetailNavInner>
                                <ItemDetailNavInner isnav={activeSection===4} onClick={() => handleNavLinkClick(4)}>
                                    특약 사항
                                </ItemDetailNavInner>
                            </ItemDetailNav>
                        </ItemDetailNavContainer>
                        <ItemDetailContentContainer ref={DescriptionRef}>
                            <ItemDetailContentTitle>
                                상품 상세
                            </ItemDetailContentTitle>
                            {/* <ReactQuillContent content={descriptionContent} /> */}
                            <CKEditorContent content={descriptionContent} />
                            {/* <ItemDetailImageContainer>
                                <ImageBase64 imageID={item.detail.descriptionImageID} useImageHeight={true} />
                            </ItemDetailImageContainer> */}
                        </ItemDetailContentContainer>
                        <ItemDetailContentContainer ref={MissionRef}>
                            <ItemDetailContentTitle>
                                영업 업무
                            </ItemDetailContentTitle>
                            {/* <ReactQuillContent content={missionContent} /> */}
                            <CKEditorContent content={missionContent} />
                        </ItemDetailContentContainer>
                        <ItemDetailContentContainer ref={RewardRef}>
                            <ItemDetailContentTitle>
                                실적 보상
                            </ItemDetailContentTitle>
                            {/* <ReactQuillContent content={rewardContent} /> */}
                            <CKEditorContent content={rewardContent} />
                        </ItemDetailContentContainer>
                        <ItemDetailContentContainer ref={ContractRef}>
                            <ItemDetailContentTitle>
                                특약 사항
                            </ItemDetailContentTitle>
                            {/* <ReactQuillContent content={contractContent} /> */}
                            <CKEditorContent content={contractContent} />
                        </ItemDetailContentContainer>
                    </ItemApplyDetail>                
                </ItemDetailContainer>
            </ItemDetailPageContainer>
        </div>
    );
}