import React, { useState, useEffect } from "react"
import { ItemWriteTitle, OpenAgreementLayout } from "./MyPageItemBrandStyle"
import {
    getBrandItemMarketerPersoanlAgreementLink,
    getBrandItemMarketerPersonalAgreements,
    getItemPersonalAgreementPaper,
    updateBrandItemMarketerPersonalAgreement,
} from "../../../../apis/api/boardItems"
import Utils from "../../../../utils/Utils"
import ModalContainerPortal from "../../../../components/ModalContainerPortal"
import MyPageOpenAgreement from "./MyPageOpenAgreement"
import { Pagination } from "../../../../components/Pagination"
import { PaginationStyles } from "../../../../types/StyleTypes"
import CKEditorContent from "../../../../components/boards/CKEditorContent"

const PAGE_SIZE = 7

export default function MyPageOpenAgreementList({ itemID, itemMarketerID }) {
    const [agreements, setAgreements] = useState([])
    const [showAgreements, setShowAgreements] = useState(false)

    const [agreementPaper, setAgreementPaper] = useState("")
    const [showAgreementPaper, setShowAgreementPaper] = useState(false)

    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [name, setName] = useState("")
    const [tel, setTel] = useState("")

    const [showAgreementLink, setShowAgreementLink] = useState(false)
    const [agreementLink, setAgreementLink] = useState("")

    useEffect(() => {
        getAgreementPaper()
    }, [itemID])

    useEffect(() => {
        if (showAgreements) {
            getAgreements(pageIndex)
        }
    }, [itemMarketerID, showAgreements])

    const getAgreementPaper = async () => {
        setAgreementPaper("")
        if (itemID < 0) {
            return
        }

        const paper = await getItemPersonalAgreementPaper(itemID)
        if (paper.id < 0) {
            return
        }
        setAgreementPaper(paper.content)
    }

    const getAgreements = async (page) => {
        const response = await getBrandItemMarketerPersonalAgreements(page, pageSize, itemMarketerID)

        setAgreements(response.items)
        setItemCount(response.count)
    }

    const generateAgreementLink = async () => {
        if (Utils.isStringNullOrEmpty(agreementPaper)) {
            alert("등록된 동의서가 없습니다.")
            return
        }

        setAgreementLink("")
        setShowAgreementLink(true)

        const response = await getBrandItemMarketerPersoanlAgreementLink(itemMarketerID, name, tel)
        setAgreementLink(response)

        if (Utils.isStringNullOrEmpty(response)) {
            setShowAgreementLink(false)
            alert("링크 생성 실패했습니다.")
        }
    }

    const deleteAgreement = async (agreement) => {
        if (!confirm(`${agreement.name} ${agreement.tel}을\n삭제하시겠습니까?`)) {
            return
        }

        let data = agreement.convertToRequest()
        data["is_agree"] = false

        const result = await updateBrandItemMarketerPersonalAgreement(agreement.id, data)

        if (result) {
            alert("처리되었습니다.")
            getAgreements(pageIndex)
        } else {
            alert("실패했습니다.")
        }
    }

    const handlePageIndex = (page) => {
        setPageIndex(page)
        getAgreements(page)
    }

    return (
        <OpenAgreementLayout>
            <ItemWriteTitle className="px-2">개인정보동의</ItemWriteTitle>
            <div className="flex justify-between items-center w-full h-10 p-2 bg-transparent rounded text-sm">
                <div className="flex items-center space-x-2">
                    <button
                        className="px-3 py-1 rounded-full bg-nightblue-500 hover:bg-nightblue-600 text-white"
                        onClick={() => {
                            setShowAgreements(!showAgreements)
                        }}
                    >
                        내역 {showAgreements ? "숨기기" : "보기"}
                    </button>
                    <button
                        className="px-3 py-1 rounded-full bg-slate-500 hover:bg-slate-600 text-white"
                        onClick={() => {
                            if (Utils.isStringNullOrEmpty(agreementPaper)) {
                                alert("등록된 동의서가 없습니다.")
                                return
                            }
                            setShowAgreementPaper(!showAgreementPaper)
                        }}
                    >
                        동의서 보기
                    </button>
                </div>
                {showAgreements && <span className="text-slate-500">{`${itemCount} 개`}</span>}
            </div>
            <div className="flex flex-col w-full h-full py-4 space-y-2">
                {showAgreements &&
                    agreements.map((agreement, index) => (
                        <div key={index} className="flex items-center w-full h-10 border-b">
                            <div className="grid grid-cols-2 gap-x-2 w-full h-full text-slate-700 [&>div]:flex [&>div]:items-center [&>div]:px-2 [&>div]:space-x-2">
                                <div>
                                    <i className="fa-solid fa-user"></i>
                                    <span>{agreement.name}</span>
                                </div>
                                <div>
                                    <i className="fa-solid fa-mobile-screen"></i>
                                    <span>{agreement.tel}</span>
                                </div>
                                {/* <div>
                                <span>{agreement.isAgree ? "동의" : "미동의"}</span>
                            </div> */}
                            </div>
                            <button
                                className="flex-shrink-0 px-4 py-2 text-red-500"
                                onClick={() => {
                                    deleteAgreement(agreement)
                                }}
                            >
                                삭제
                            </button>
                        </div>
                    ))}
            </div>
            <div className="flex flex-center w-full h-10 mt-auto mb-4">
                <Pagination
                    page={pageIndex}
                    setPage={handlePageIndex}
                    pageSize={pageSize}
                    itemCount={itemCount}
                    maxPageButtons={5}
                    styleType={PaginationStyles.NoneBGCircleButtons}
                />
            </div>
            <div className="flex flex-col w-full p-3 space-y-2 bg-slate-100">
                <span className="text-slate-500 text-sm">미리 이름과 전화번호 데이터를 넣으실 수 있습니다.</span>
                <div className="grid grid-cols-2 gap-x-2 [&>input]:px-3 [&>input]:py-2 [&>input]:bg-white">
                    <input
                        placeholder="이름"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                    />
                    <input
                        placeholder="전화번호"
                        type="tel"
                        value={tel}
                        onChange={(e) => {
                            setTel(e.target.value)
                        }}
                    />
                </div>
                <button
                    className="w-full py-2 bg-blue-100 text-blue-700"
                    onClick={() => {
                        generateAgreementLink()
                    }}
                >
                    개인정보동의 링크 생성
                </button>
            </div>

            <ModalContainerPortal show={showAgreementLink} setShow={setShowAgreementLink} closeByBackground={false}>
                <MyPageOpenAgreement link={agreementLink} />
            </ModalContainerPortal>

            <ModalContainerPortal show={showAgreementPaper} setShow={setShowAgreementPaper}>
                <div className="max-sm:w-[95vw] sm:min-w-[32rem] max-h-[80vh] bg-white max-sm:p-4 sm:p-8 rounded-md overflow-y-auto">
                    <CKEditorContent content={agreementPaper} />
                </div>
            </ModalContainerPortal>
        </OpenAgreementLayout>
    )
}
