import { useEffect, useState, useRef } from "react";
import Utils from "../utils/Utils";

const classContain = (element, classNames) => {
    if (classNames.length === 0) {
        return false
    }

    for (let i=0; i<classNames.length; i++) {
        try {
            if (element.className.includes(classNames[i])) {
                return true
            }
        } catch {
            return false
        }
    }

    return false
}

export const containsParentElement = (element, classNames) => {
    if (classContain(element, classNames)) {
        return true
    }

    const parent = element.parentElement

    if (Utils.isNullOrUndefined(parent)) {
        return false
    }

    try {
        if (classContain(parent, classNames)) {
            return true
        }
    } catch {
        //
    }

    return containsParentElement(parent, classNames)
}

const classContains = (classNames, target) => {
    if (classNames.length === 0) {
        return false
    }

    if (containsParentElement(target, classNames)) {
        return true
    }

    return false
}

const useDropdownDetectCloseWithoutClassName = (initialState, classNames=[], closeEvents=[]) => {
    // containsRef는 className으로만 비교하기 때문에 주의!
    const [isOpen, setIsOpen] = useState(initialState);
    const ref = useRef(null);

    const removeHandler = (open=null) => {
        if (open === null) {
            setIsOpen(!isOpen)
        } else {
            setIsOpen(open)
        }
    };

    useEffect(() => {
        const onClick = (e) => {
            if (ref.current !== null && (!classContains(classNames, e.target))) {
                setIsOpen(!isOpen);

                closeEvents.map(closeEvent => {
                    closeEvent()
                })
            }
        };

        if (isOpen) {
            window.addEventListener("click", onClick);
        }

        return () => {
            window.removeEventListener("click", onClick);
        };
    }, [isOpen]);

    return [isOpen, ref, removeHandler];
};

export default useDropdownDetectCloseWithoutClassName;