import tw from "tailwind-styled-components"
import { BodyInnerBox, BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"
import { Check } from "@styled-icons/boxicons-regular/Check"
import { Filter } from "@styled-icons/octicons/Filter"

export const CheckIcon = tw(Check)`
    ${(props) => (props.isselected ? "opacity-100" : "opacity-0")} text-white mb-[0.06rem]
    h-full
`

export const ItemPageTitle = tw.span`
    max-sm:hidden sm:flex justify-between items-center mb-4
`

export const ItemPageBox = tw(ContainerPaddingTop)`
    bg-nightblue-10 flex flex-col items-center h-auto max-md:mt-4
`

export const ItemPageBoxInner = tw(BoxMaxMin)`
    flex flex-col w-full h-full max-md:my-0 md:my-4 
`

export const ItemCardListGrid = tw.div`
    grid max-lg:grid-cols-1 lg:grid-cols-2 max-lg:gap-2 lg:gap-4 w-full
`

export const ItemPageBoxInnerLeft = tw.div`
    w-full flex flex-col justify-start max-md:px-2 max-md:mt-0 md:mt-2
`

export const ItemSearchCon = tw.div`
    w-full h-auto flex flex-col max-md:p-2 md:p-3 bg-slate-100 items-center rounded-lg
`

export const ItemAdvertiseBox = tw.div`
    max-md:hidden md:flex flex-col
    w-full
    bg-transparent
`
export const ItemAdvertiseImageContainer = tw.div`
    w-auto h-auto
    ${(props) => (props.isShow ? "opacity-100" : "opacity-0")}
`
export const ItemAdvertiseButton = tw.button`
    absolute top-3 right-3
    flex flex-center
    w-5 h-5 bg-gray-100 border border-gray-400
    text-xs
`

export const ItemPageBoxInnerRight = tw.div`
    flex flex-col max-md:w-full max-md:px-2 w-full
    mt-4
`

export const ItemSortOptionCon = tw.div`
    flex items-center justify-between
    w-full px-1 py-0 bg-transparent mb-4 rounded-md
    drop-shadow-sm
`
export const ItemSortSwitchCon = tw.div`
    flex h-8 p-1.25 ml-1
    text-gray-500 hover:text-black
    cursor-pointer
`
export const ItemSortSelection = tw.select`
    m-2 px-2 py-1
    rounded-md bg-white
    text-gray-800 border border-slate-200
    focus:outline-none
`

export const ItemPaginationCon = tw.div`
    flex justify-center items-center h-24 w-full p-4 max-sm:mt-0 sm:mt-12
`
export const ItemPaginationInner = tw.div`
    w-fit
`
export const SearchCol = tw.div`
    flex max-md:flex-row md:flex-col w-full
`
export const SearchInputBox = tw.div`
    flex w-full h-8 px-2
    bg-white rounded-lg outline-none focus:outline-none focus:border-sky-400
    duration-300 border border-slate-200
`
export const SearchInput = tw.input`
    w-full outline-none focus:outline-none focus:border-sky-400 bg-transparent
`
export const SearchControlRow = tw.div`
    flex w-full h-8 space-x-2
    text-sm
`

const ItemsButton = tw.button`
    w-20 ml-2
    rounded-lg
    ${(props) => (props.isLoading ? "cursor-progress" : "cursor-pointer")} 
`
export const SearchButton = tw(ItemsButton)`
    flex-shrink-0 w-14 text-white
    bg-nightblue-600 hover:bg-nightblue-500
`
export const ResetButton = tw(SearchButton)`
    bg-slate-600 hover:bg-slate-500
`
export const FilterToggleButton = tw(ItemsButton)`
    max-md:flex md:hidden flex-shrink-0 flex-center w-12 py-1
    text-gray-700
    ${(props) => (props.isActive ? "bg-gray-200" : "bg-gray-50")}
    border
`
export const FilterIcon = tw(Filter)`
    h-full
`

export const SearchTypeSelectionBox = tw.div`
    flex flex-center w-full
    px-2 focus:outline-none
    rounded-md bg-white border border-slate-200
    text-gray-800
`
export const SearchTypeSelection = tw.select`
    w-full
`

export const FilterOptionBox = tw.div`
    ${(props) => (props.isShow ? "max-md:flex md:hidden" : "max-md:hidden md:hidden")}
    flex-col justify-center h-fit w-full
    rounded
`

export const FilterTop = tw.div`
    flex justify-center items-center h-10 w-full 
    border-b
    cursor-pointer
`

export const FilterInner = tw.div`
    flex flex-col
    duration-300
`

export const FilterCategoryTitle = tw.div`
    max-md:hidden md:block
    font-medium mt-4 md:px-1
`

export const PaginationButton = tw.button`
    w-12 h-12 m-1 
    bg-slate-100 hover:bg-gray-100 border rounded-full drop-shadow 
`

export const CategoryCheckItems = tw.div`
    flex max-md:flex-row md:flex-col mt-2 max-md:w-full max-md:h-8
    space-x-2
`

export const ItemCatgoryCheckContainer = tw.div`
    ${(props) => (props.isselected ? "bg-nightblue-100" : "bg-transparent")} 
    ${(props) => (props.isLoading ? "cursor-progress" : "cursor-pointer")} 
    max-md:mb-0.5 md:mb-1 px-2 py-1.5
    flex items-center rounded-md flex-shrink-0
    max-md:mr-1
    select-none
`

export const ItemCategoryCheckIconContainer = tw.div`
    ${(props) => (props.isselected ? "bg-nightblue-400 border-nightblue-400" : "bg-transparent border-slate-350")}  
    py-0.25 h-full aspect-square mr-1.5
    flex flex-center border rounded-[0.25rem] 
`

export const ItemCategoryText = tw.span`
    ${(props) => (props.isselected ? "text-black" : "text-gray-700")} 
    text-sm
`

export const ItemMarketerMatchingButton = tw.button`
    max-sm:w-full sm:w-32 h-8
    rounded-full
    marketer-matching-button-color
    duration-200
`
