import react, {useState, useEffect, useCallback} from "react"

export const useGettingHeight = () => {
    const [height, setHeight] = useState(false)

    const ref = useCallback((node) => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height)
        }
    })

    useEffect(()=>{
    }, [])

    return [height, ref]
}