export default class {
    constructor() {
        this.id = -1
        this.itemID = -1
        this.content = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.itemID = response["item_id"]
        this.content = response["content"]
    }

    convertToRequest() {
        let request = {
            item_id: this.itemID,
            content: this.content,
        }
        return request
    }
}
