import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getUserBrands } from "../../../../apis/api/users"
import { FilterInputCombo, FilterInputReset, FilterInputText } from "../../../../components/brandItems/FilterInputs"
import { Pagination } from "../../../../components/Pagination"
import { PmsNavState } from "../../../../types/DataEnum"
import { EditStateTypes, SortTypes } from "../../../../types/EditorTypes"
import { PaginationStyles } from "../../../../types/StyleTypes"
import { UserBrandOrderOptionNames, UserBrandOrderOptionTypes } from "../../../../types/users/UserOption"
import { MarketerSubControlBox, MarketerToggleButton } from "../../marketer/MyPageMarketerStyle"
import { ContentContainer, MyPageLayout } from "../../MyPageMainStyle"
import { PerformListControlBox, PerformListControlSection } from "../../perform/MyPagePerformStyle"
import StaffUserBrandTable from "./StaffUserBrandTable"
import { StaffListControlBox, StaffListControlSection } from "./StaffUserStyle"

const MAX_PAGE_LENGTH = 10

export default function StaffUserBrand({ setPmsNavState }) {
    const { itemID } = useParams()

    const [brands, setBrands] = useState([])

    const [search, setSearch] = useState("")
    const [brandStatus, setBrandStatus] = useState(EditStateTypes.NONE)

    const [order, setOrder] = useState(UserBrandOrderOptionTypes.REGIST)
    const [sortType, setSortType] = useState(SortTypes.DESC)

    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    useEffect(() => {
        setPmsNavState(PmsNavState.STAFFUSER)
        getBrands(pageIndex, pageSize, search, brandStatus, order, sortType)

        return () => setPmsNavState(PmsNavState.NONE)
    }, [])

    const getBrands = async (
        _pageIndex, // 페이지
        _pageSize, // 페이지내 아이템 개수
        _search, // 검색 키워드
        _brandStatus, // 마케터 상태
        _order, // 정렬 옵션
        _sort, // 정렬 방향
    ) => {
        if (_sort === SortTypes.DESC) {
            _order = "-" + _order
        }
        // 정렬 옵션이 id가 아닌 경우 2차로 id를 정렬한다
        if (!_order.includes(UserBrandOrderOptionTypes.REGIST)) {
            _order += `,-${UserBrandOrderOptionTypes.REGIST}`
        }
        if (_brandStatus === EditStateTypes.NONE) {
            _brandStatus = null
        }

        setPageIndex(_pageIndex)
        const response = await getUserBrands(_pageIndex, _pageSize, _search, _brandStatus, _order)
        if (response !== undefined) {
            setBrands(response.items)
            setItemCount(response.count)
        }
    }

    const handleSearchMarketer = () => {
        getBrands(1, pageSize, search, brandStatus, order, sortType)
    }

    const handleSearchMarketerEnter = () => {
        handleSearchMarketer()
    }

    const handlePageIndex = (pageIndex) => {
        getBrands(pageIndex, pageSize, search, brandStatus, order, sortType)
    }
    const handleStatus = (value) => {
        setBrandStatus(value)
        getBrands(1, pageSize, search, value, order, sortType)
    }
    const handleOrderType = (value) => {
        setOrder(value)
        let _sortType = sortType
        if (value === UserBrandOrderOptionTypes.REGIST) {
            _sortType = SortTypes.DESC
        } else if (value === UserBrandOrderOptionTypes.NAME) {
            _sortType = SortTypes.ASC
        } else if (value === UserBrandOrderOptionTypes.BRAND_NAME) {
            _sortType = SortTypes.ASC
        }
        setSortType(_sortType)

        getBrands(1, pageSize, search, brandStatus, value, _sortType)
    }
    const handleSortType = (value) => {
        setSortType(value)
        getBrands(1, pageSize, search, brandStatus, order, value)
    }

    const handleFilterReset = () => {
        setSearch("")
        setBrandStatus(EditStateTypes.NONE)
        setOrder(UserBrandOrderOptionTypes.REGIST)
        setSortType(SortTypes.DESC)

        getBrands(1, pageSize, "", EditStateTypes.NONE, UserBrandOrderOptionTypes.REGIST, SortTypes.DESC)
    }

    return (
        <MyPageLayout>
            <ContentContainer full_height>
                <StaffListControlBox>
                    <PerformListControlSection>
                        <FilterInputText
                            value={search}
                            setValue={setSearch}
                            submit={() => {
                                handleSearchMarketerEnter()
                            }}
                            width={"52"}
                            placeholder={"브랜드"}
                        />

                        <FilterInputCombo label={"상태"} value={brandStatus} setValue={handleStatus}>
                            <option value={EditStateTypes.NONE}>-</option>
                            <option value={EditStateTypes.WAIT}>대기</option>
                            <option value={EditStateTypes.DONE}>승인</option>
                            <option value={EditStateTypes.FAILED}>차단</option>
                        </FilterInputCombo>

                        <FilterInputCombo
                            label={"정렬"}
                            value={order}
                            setValue={handleOrderType}
                            sortType={sortType}
                            setSortType={handleSortType}
                            width={"48"}
                        >
                            <option value={UserBrandOrderOptionTypes.REGIST}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.REGIST]}
                            </option>
                            <option value={UserBrandOrderOptionTypes.NAME}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.NAME]}
                            </option>
                            <option value={UserBrandOrderOptionTypes.BRAND_NAME}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.BRAND_NAME]}
                            </option>
                        </FilterInputCombo>

                        <FilterInputReset reset={handleFilterReset} />
                    </PerformListControlSection>

                    <StaffListControlSection last>
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={MAX_PAGE_LENGTH}
                            styleType={PaginationStyles.NoneBGCircleButtons}
                        />
                        <div className="w-2"></div>
                        <Link to={`/mypage/staff/user/point`}>
                            <MarketerToggleButton marketerstate={1}>포인트 관리</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/staff/user/brand`}>
                            <MarketerToggleButton marketerstate={0}>브랜드 담당 목록</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/staff/user/marketer`}>
                            <MarketerToggleButton marketerstate={1}>마케터 목록</MarketerToggleButton>
                        </Link>
                    </StaffListControlSection>
                </StaffListControlBox>
                <MarketerSubControlBox></MarketerSubControlBox>

                <div className="w-full h-full relative">
                    <StaffUserBrandTable userBrands={brands} />
                </div>
            </ContentContainer>
        </MyPageLayout>
    )
}
