export default class {
    constructor() {
        this.id = -1
        this.uuid = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.uuid = response["uuid"]
    }
}
