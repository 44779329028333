import React from 'react'
import tw from 'tailwind-styled-components'


const ViewerContainer = tw.div`
    w-screen h-screen absolute-center z-[110]
`

const ViewerLayout = tw.div`
    w-screen h-screen relative z-50
`

const ImageViewerContainer = tw.div`
    absolute-center  z-50
`

const ImageViewerBackground =  tw.div`
    h-full w-full absolute-center bg-gray-900 opacity-40  z-40
`

export function MyPageMarketerDetailContractViewer({children,show,setShow}) {
    return(
        <React.Fragment>
            {show === true && 
                <ViewerContainer>
                    <ViewerLayout>
                        <ImageViewerContainer>
                            {children}
                        </ImageViewerContainer>
                        <ImageViewerBackground onClick={() => {setShow(false)}} />
                    </ViewerLayout>
                </ViewerContainer>
            }
        </React.Fragment>
    )
}