import React, { useEffect, useState } from "react"
import { GroupManagerContainer } from "./MyPageGroupStyle"
import { PerformValueInputTitle } from "../perform/MyPagePerformStyle"
import { UserIcon } from "../marketer/MyPageMarketerStyle"
import MyPageGroupManagerInvite from "./MyPageGroupManagerInvite"
import MyPageGroupManagerTree from "./MyPageGroupManagerTree"
import { updateItemMarketerRoot } from "../../../apis/api/boardItems"

const MENU_TYPES = {
    INVITE: 0,
    TREE: 1,
}

export default function MyPageGroupManager({ itemMarketer, getCurrentMarketers }) {
    const [menuType, setMenuType] = useState(MENU_TYPES.INVITE)

    const [marketerRootLoading, setMarketerRootLoading] = useState(false)

    const handleMarketerRoot = async () => {
        if (!confirm("이 마케터를 최상위 마케터로 이동하시겠습니까?")) {
            return
        }

        if (marketerRootLoading) {
            return
        }
        setMarketerRootLoading(true)

        const response = updateItemMarketerRoot(itemMarketer.id)

        if (response) {
            setTimeout(() => {
                alert("이동되었습니다.")
                getCurrentMarketers()
                setMarketerRootLoading(false)
            }, 500)
            return
        } else {
            alert("실패했습니다.")
            setMarketerRootLoading(false)
            return
        }
    }

    return (
        <GroupManagerContainer>
            <PerformValueInputTitle>
                <span>그룹 관리</span>
            </PerformValueInputTitle>
            <div className="flex max-sm:flex-col sm:flex-row items-center w-full mb-4">
                <div className="flex items-center">
                    <div className="w-6 h-6 mb-1 text-slate-500">
                        <UserIcon />
                    </div>{" "}
                    {itemMarketer.marketerNameChain}
                </div>
                <div className="flex items-center space-x-2 ml-auto">
                    {itemMarketer.parentID >= 0 && (
                        <button
                            className={`h-7 px-3 rounded-full ${
                                marketerRootLoading ? "bg-slate-500" : "bg-nightblue-400 hover:bg-nightblue-500"
                            } text-white`}
                            onClick={() => {
                                handleMarketerRoot()
                            }}
                        >
                            최상위 이동
                        </button>
                    )}
                    <div className="grid grid-cols-2 max-sm:w-full sm:w-44 h-9 p-0.75 rounded-md bg-slate-150">
                        <button
                            onClick={() => {
                                setMenuType(MENU_TYPES.INVITE)
                            }}
                            className={`flex flex-center w-full h-full rounded-md ${
                                menuType === MENU_TYPES.INVITE
                                    ? `bg-nightblue-500 text-white`
                                    : "bg-transparent text-slate-600"
                            }`}
                        >
                            초대 수신
                        </button>
                        <button
                            onClick={() => {
                                setMenuType(MENU_TYPES.TREE)
                            }}
                            className={`flex flex-center w-full h-full rounded-md ${
                                menuType === MENU_TYPES.TREE
                                    ? `bg-nightblue-500 text-white`
                                    : "bg-transparent text-slate-600"
                            }`}
                        >
                            초대 발신
                        </button>
                    </div>
                </div>
            </div>

            {menuType === MENU_TYPES.INVITE && (
                <MyPageGroupManagerInvite itemMarketer={itemMarketer} getCurrentMarketers={getCurrentMarketers} />
            )}
            {menuType === MENU_TYPES.TREE && (
                <MyPageGroupManagerTree
                    itemID={itemMarketer.itemID}
                    parentItemMarketer={itemMarketer}
                    getCurrentMarketers={getCurrentMarketers}
                />
            )}
        </GroupManagerContainer>
    )
}
