import { TextFormats } from "../types/DataEnum"
import { SortTypes } from "../types/EditorTypes"

import CryptoJS from "crypto-js"

export default class {
    static isStringNullOrEmpty(s) {
        return s === null || s === undefined || s === ""
    }
    static isNullOrUndefined(s) {
        return s === null || s === undefined
    }

    static decodeJWT(s) {
        var base64Url = s.split(".")[1]
        if (base64Url === undefined) {
            return ""
        }
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join(""),
        )

        return JSON.parse(jsonPayload)
    }

    static getFileSizeText(size) {
        // size 단위는 바이트

        const units = ["B", "KB", "MB", "GB", "TB"]
        let fileSize = size
        let unitIndex = 0

        while (fileSize >= 1024 && unitIndex < units.length - 1) {
            fileSize /= 1024
            unitIndex++
        }

        return fileSize.toFixed(2) + " " + units[unitIndex]
    }

    static removeImageSources(element) {
        const imageElements = element.getElementsByTagName("img")

        for (let i = 0; i < imageElements.length; i++) {
            const imageElement = imageElements[i]
            imageElement.src = ""
            imageElement.className = ""
        }

        return element
    }

    static equalsElementsWithoutImageSources(element1, element2) {
        if (this.isStringNullOrEmpty(element1)) {
            element1 = ""
        }
        if (this.isStringNullOrEmpty(element2)) {
            element2 = ""
        }

        let element1Container = element1
        if (String(element1).slice(0, 5) !== "<div>") {
            element1Container = document.createElement("div")
            element1Container.innerHTML = element1
        }
        let element2Container = element2
        if (String(element2).slice(0, 5) !== "<div>") {
            element2Container = document.createElement("div")
            element2Container.innerHTML = element2
        }

        // 이미지 속성은 사이즈가 크기 때문에 태그만 존재하는지 확인한다. 이미지 비교는 다른 로직을 이용하여 비교
        let element1ContainerWithoutImage = this.removeImageSources(element1Container)
        let element2ContainerWithoutImage = this.removeImageSources(element2Container)

        let element1InnerWithoutImage = element1ContainerWithoutImage.innerHTML
        let element2InnerWithoutImage = element2ContainerWithoutImage.innerHTML

        return element1InnerWithoutImage == element2InnerWithoutImage
    }

    static getOrdering(ordering, sortType) {
        if (sortType === SortTypes.DESC) {
            let splits = ordering.split(",")
            splits = splits.map((s) => "-" + s)

            return splits.join()
        } else {
            return ordering
        }
    }

    static isBatchimEnding(word) {
        if (typeof word !== "string") return null

        var lastLetter = word[word.length - 1]
        var uni = lastLetter.charCodeAt(0)

        if (uni < 44032 || uni > 55203) return null

        return (uni - 44032) % 28 != 0
    }

    static isIOS() {
        let result = false

        var checkUserAgent = navigator.userAgent.toLowerCase()

        // Android
        if (checkUserAgent.indexOf("android") != -1) {
            // alert('Android');
            // IOS
        } else if (
            checkUserAgent.indexOf("iphone") != -1 ||
            checkUserAgent.indexOf("ipad") != -1 ||
            checkUserAgent.indexOf("ipod") != -1
        ) {
            result = true
            // other
        } else {
            // alert('other');
        }

        return result
    }

    static timeout(delay) {
        return new Promise((r) => setTimeout(r, delay))
    }

    static getBaseUrl() {
        const currentURL =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        return currentURL
    }

    static telFormat(v) {
        let value = v.replace(/[^0-9]/g, "")

        let result = []
        let restNumber = ""

        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
            // 서울 02 지역번호
            result.push(value.substr(0, 2))
            restNumber = value.substring(2)
        } else if (value.startsWith("1")) {
            // 지역 번호가 없는 경우
            // 1xxx-yyyy
            restNumber = value
        } else {
            // 나머지 3자리 지역번호
            // 0xx-yyyy-zzzz
            result.push(value.substr(0, 3))
            restNumber = value.substring(3)
        }

        if (restNumber.length === 7) {
            // 7자리만 남았을 때는 xxx-yyyy
            result.push(restNumber.substring(0, 3))
            result.push(restNumber.substring(3))
        } else {
            result.push(restNumber.substring(0, 4))
            result.push(restNumber.substring(4))
        }

        return result.filter((val) => val).join("-")
    }
    static telFormatter(e) {
        let value = e.target.value
        if (!value) {
            // eslint-disable-next-line no-self-assign
            e.target.value = e.target.value
        }

        e.target.value = this.telFormat(value)
    }
    static textFormat(text, format) {
        let result = text

        if (format === TextFormats.NUMBER) {
            const number = Number(text)
            if (!isNaN(number)) {
                result = number.toLocaleString()
            }
            // // 숫자를 문자열로 변환
            // let numStr = text.toString()

            // // 정수 부분과 소수 부분 분리
            // const parts = numStr.split(".")
            // const integerPart = parts[0]
            // const decimalPart = parts[1] || ""

            // // 정수 부분에 콤마 추가
            // const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            // // 소수 부분과 합치기
            // result =
            //     decimalPart || text[text.length - 1] === "." ? `${integerWithCommas}.${decimalPart}` : integerWithCommas
            // console.log(decimalPart)
        } else if (format === TextFormats.PRICE) {
            const number = this.textFormat(text, TextFormats.NUMBER)
            result = number + "원"
        }

        if (this.isNullOrUndefined(result)) {
            result = ""
        }

        return result
    }
    static textFormatInput(text, format) {
        let result = text

        if (format === TextFormats.NUMBER) {
            result = text.replaceAll(",", "")
        } else if (format === TextFormats.NUMBER_ONLY) {
            result = text.replace(/[^0-9]/g, "")
        } else if (format === TextFormats.PRICE) {
            // 미구현
            const number = this.textFormatInput(text, TextFormats.NUMBER)
            result = number.replaceAll("원", "")
        } else if (format === TextFormats.TEL) {
            result = this.telFormat(text)
        }

        return result
    }
    static parseEncrypt(message) {
        const KEY = process.env.REACT_APP_CRYPTO_KEY

        const urlNonSafeMessage = message.replaceAll("_", "/").replaceAll("-", "+")

        // var cipher = CryptoJS.AES.encrypt(
        //     CryptoJS.pad.ZeroPadding.pad(CryptoJS.enc.Utf8.parse(message), CryptoJS.blockSize),
        //     CryptoJS.enc.Utf8.parse(KEY),
        //     { mode: CryptoJS.mode.ECB },
        // )
        // console.log(cipher, cipher.toString())

        // return cipher.toString(CryptoJS.enc.Base64)
        const decrypted = CryptoJS.AES.decrypt(urlNonSafeMessage, CryptoJS.enc.Utf8.parse(KEY), {
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.ECB,
        })

        return decrypted.toString(CryptoJS.enc.Utf8)
    }
    static isAvailableErrorData(error) {
        try {
            const errorData = error.response.data
            if (typeof errorData === "string" && errorData.includes("DOCTYPE")) {
                return false
            }

            return true
        } catch {
            return false
        }
    }
}
