import create from "zustand" // create로 zustand를 불러옵니다.

// 로그인 모달 show , setshow
export const useLoginModalStore = create((set) => ({
    loginShow: false,
    setLoginShow: (showState) => set({ loginShow: showState }),
}))
export const useBrandLoginModalStore = create((set) => ({
    loginShow: false,
    setLoginShow: (showState) => set({ loginShow: showState }),
}))

export const userDetailModalStore = create((set) => ({
    userShow: false,
    setUserShow: (showState) => set({ userShow: showState }),
    useId: -100,
    setUserId: (userIdState) => set({ useId: userIdState }),
}))

export const userDetailSmallCard = create((set) => ({
    userCardShow: false,
    setuserCardShow: (showState) => set({ userShow: showState }),
}))

export const marketerContractViewer = create((set) => ({
    marketerContractCardShow: false,
    setMarketerContractCardShow: (showState) => set({ marketerContractCardShow: showState }),
    contractImageSources: [],
    setContractImageSources: (contractImageSourceState) => set({ contractImageSources: contractImageSourceState }),
}))

export const marketerApplyModal = create((set) => ({
    itemID: null,
    setItemID: (itemstate) => set({ itemID: itemstate }),
    marketerDetailShow: false,
    setMarketerApplyModal: (showState) => set({ marketerDetailShow: showState }),
}))

{
    /* <MyPageMarketerDetailContractViewer show={showContractImageViewer} setShow={setShowContractImageViewer}>
    <ContractImageViewer imageSources={contractImageSources}/>
</MyPageMarketerDetailContractViewer> */
}
