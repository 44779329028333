import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import AccessRules from "../../../rules/AccessRules"
import {
    CrownContainer,
    CrownIcon,
    MarketerContentGroupName,
    MarketerNameContainer,
    PerformValueBox,
} from "./MyPagePerformStyle"
import { PerformTextBox } from "./MyPagePerformStyle"
import ItemMarketerNameChain from "../../../components/brandItems/ItemMarketerNameChain"
import ItemMarketerNameChainParent from "../../../components/brandItems/ItemMarketerNameChainParent"
import { userDetailModalStore } from "../../../zustand/modalstore"
import { HoverUserCardContainer } from "../marketerGroup/MyPageGroupInviteList"

const MarketerContent = ({ group, marketerName }) => {
    return (
        <React.Fragment>
            {(group !== undefined && group.id) > 0 && <MarketerContentGroupName>{group.name}</MarketerContentGroupName>}
            <MarketerNameContainer>
                {group.masterMarketer.user.id === marketerName.id && (
                    <CrownContainer>
                        <CrownIcon />
                    </CrownContainer>
                )}
                <span className="px-1 truncate max-lg:text-xs lg:text-sm font-medium">{marketerName.name}</span>
            </MarketerNameContainer>
        </React.Fragment>
    )
}

export const PerformValueMarketerCell = ({ user, column, perform, showUpdateComponent }) => {
    const handleShowUpdateComponent = () => {
        showUpdateComponent(perform)
    }

    // const setUserShow = userDetailModalStore((state) => state.setUserShow)
    // const setUserId = userDetailModalStore((state) => state.setUserId)

    // const handeRowClick = () => {
    //     setUserId(perform.marketer.user.id)
    //     setUserShow(true)
    // }

    return (
        <PerformValueBox className="h-auto">
            <PerformTextBox className="flex-col">
                {/* <MarketerContent group={perform.itemMarketer.group} marketerName={perform.marketer.user} /> */}
                {/* <HoverUserCardContainer
                    userID={perform.marketer.user.id}
                    onClick={() => {
                        handeRowClick()
                    }}
                >
                </HoverUserCardContainer> */}
                <ItemMarketerNameChainParent nameChain={perform.itemMarketer.marketerNameChain} />
            </PerformTextBox>
        </PerformValueBox>
    )
}
