import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { updateUserDetail } from "../../../apis/api/users"
import { MyPageUserInput } from "../../../components/user/Inputs"
import { getUserBrandRequestData } from "../../../services/users"
import Brand from "../../../types/users/Brand"
import Utils from "../../../utils/Utils"
import {
    MyInfoDataContainer,
    BuisnessImage,
    MyPageBusnessImageUploadContainer,
    MyPageBusinessImageUploadButton,
    MyInfoDataEditButton,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyPageDataGrid,
    MyPageDataControlBox,
    MyPageDataSubmitButton,
    MyPageBusinessImageTitle,
    BuisnessImageHover,
    MyPageBusinessImageTitleContainer,
    MyInfoDataTitleContainer,
} from "./MyInfoMainStyle"
import { AddressSearch } from "../../../components/user/SignUpAddress"
import { useMouseHover } from "../../../hooks/useMouseHover"
import { uploadImageBase64, uploadImage } from "../../../apis/api/files/index"
import { getBuisnessImage } from "../../../apis/api/users/index"
import { TelValidate } from "../../../components/user/TelValidate"
import { useFocus } from "../../../hooks/useFocus"
import { EditStateTypes } from "../../../types/EditorTypes"

export default function MyInfoUser({ brand }) {
    const [brandName, setBrandName] = useState("")
    const [brandManagerName, setBrandManagerName] = useState("")
    const [address, setAddress] = useState("")
    const [detailAddress, setDetailAddress] = useState("")
    const [brandTelNumber, setBrandTelNumber] = useState("")
    const [businessRegistration, setBusinessRegistration] = useState(null) //사업자 등록증
    const [businessImageId, setBuisnessImageId] = useState("")
    const [businessNo, setBusinessNo] = useState("") // 사업자 등록 번호
    const [imageName, setImageName] = useState("")
    const [telEditState, setTelEditState] = useState(EditStateTypes.WAIT)
    const [tel, setTel] = useState(false)

    const [refMouseHover, isMouseHover] = useMouseHover()
    const [telFocusRef, isTelFocus] = useFocus()

    const fileInputRef = useRef(null)

    // const [date, setDate] = useState("")
    // const [price, setPrice] = useState()
    // const [profit, setProfit] = useState()
    // const [creditGrade, setCreditGrade] = useState()
    // const [employees, setEmployees] = useState()
    // const [industry, setIndustry] = useState("")

    useEffect(() => {
        getBuisnessImage().then((response) => {
            if (response.id >= 0) {
                setBusinessRegistration(response.base64)
                setImageName(response.name)
            }
        })
    }, [])

    useEffect(() => {
        if (tel === true) {
            setTelEditState(EditStateTypes.DONE)
        } else {
            setTelEditState(EditStateTypes.FAILED)
        }
    }, [tel])

    useEffect(() => {
        if (brand !== null) {
            updateValue(brand)
        }
    }, [brand])

    const updateValue = (_brand) => {
        setBusinessNo(_brand.businessNo)
        setBrandName(_brand.detail.name)
        setBrandManagerName(_brand.detail.companyManagerName)
        setAddress(_brand.detail.address1)
        setDetailAddress(_brand.detail.address2)
        setBrandTelNumber(_brand.detail.tel)
        setBuisnessImageId(_brand.detail.businessImageID)
        // setDate(_brand.detail.date)
        // setPrice(_brand.detail.price)
        // setProfit(_brand.detail.profit)
        // setCreditGrade(_brand.detail.creditGrade)
        // setEmployees(_brand.detail.employees)
        // setIndustry(_brand.detail.industry)
    }

    async function ImageUpload(file) {
        const imageFile = await uploadImage(file, true)
        setBuisnessImageId(imageFile.id)
    }

    const submit = () => {
        if (
            Utils.isStringNullOrEmpty(brandName) ||
            Utils.isStringNullOrEmpty(brandManagerName) ||
            Utils.isStringNullOrEmpty(address) ||
            Utils.isStringNullOrEmpty(detailAddress) ||
            Utils.isStringNullOrEmpty(brandTelNumber) ||
            Utils.isStringNullOrEmpty(businessImageId)

            // Utils.isStringNullOrEmpty(date) ||
            // Utils.isStringNullOrEmpty(price) ||
            // Utils.isStringNullOrEmpty(profit) ||
            // Utils.isStringNullOrEmpty(creditGrade) ||
            // Utils.isStringNullOrEmpty(employees) ||
            // Utils.isStringNullOrEmpty(industry) ||
        ) {
            alert("값을 입력해주세요")
            return
        }

        let requestData = getUserBrandRequestData(
            brandName,
            brandManagerName,
            address,
            detailAddress,
            brandTelNumber,
            businessImageId,
            // date,
            // price,
            // profit,
            // creditGrade,
            // employees,
            // industry,
        )

        updateUserDetail(requestData)
            .then((response) => {
                const _brand = new Brand()
                _brand.convertByResponse(response.data)
                updateValue(_brand)
                alert("수정되었습니다.")
            })
            .catch((error) => {
                alert("실패했습니다.")
            })
    }

    const handleButtonClick = () => {
        fileInputRef.current.click()
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64Image = reader.result
                setBusinessRegistration(base64Image)
            }
            reader.readAsDataURL(file)
            setImageName(file.name)
            ImageUpload(file)
        } else {
            setImageName("")
        }
    }

    return (
        <MyInfoDataContainer>
            <MyInfoDataTitleContainer>
                <MyInfoDataTitle>브랜드 정보</MyInfoDataTitle>
                {/* <MyInfoDataEditButton>
                    정보 수정
                </MyInfoDataEditButton> */}
            </MyInfoDataTitleContainer>

            <MyInfoDataBox>
                <MyPageDataGrid>
                    <MyPageUserInput
                        label={"회사명"}
                        type={"string"}
                        data={brandName}
                        setData={setBrandName}
                        maxLength={100}
                    />
                    <MyPageUserInput
                        label={"대표자명"}
                        type={"string"}
                        data={brandManagerName}
                        setData={setBrandManagerName}
                        maxLength={100}
                    />
                    <MyPageUserInput
                        editStateType={telEditState}
                        isTel={true}
                        label={"회사 전화"}
                        type={"string"}
                        data={brandTelNumber}
                        setData={setBrandTelNumber}
                        ref={telFocusRef}
                        maxLength={20}
                    >
                        <TelValidate tel={brandTelNumber} setValidate={setTel} isShow={isTelFocus} top={"2.5rem"} />
                    </MyPageUserInput>
                    {/* <MyPageUserInput label={"회사 주소"} type={"string"} data={address} setData={setAddress} /> 
                    <MyPageUserInput label={"설립 일"} type={"date"} data={date} setData={setDate} />
                    <MyPageUserInput label={"매출 액"} type={"number"} data={price} setData={setPrice} />
                    <MyPageUserInput label={"영업 이익"} type={"number"} data={profit} setData={setProfit} />
                    <MyPageUserInput label={"신용등급 점수"} type={"number"} data={creditGrade} setData={setCreditGrade} />
                    <MyPageUserInput label={"사원 수"} type={"number"} data={employees} setData={setEmployees} />
                    <MyPageUserInput label={"상세업종"} type={"string"} data={industry} setData={setIndustry} /> */}
                    <MyPageUserInput
                        label={"사업자 등록 번호"}
                        type={"string"}
                        data={businessNo}
                        setData={setBusinessNo}
                        maxLength={45}
                    />
                </MyPageDataGrid>
                <AddressSearch
                    address={address}
                    setAddress={setAddress}
                    detailAddress={detailAddress}
                    setDetailAddress={setDetailAddress}
                />
                <label>사업자 등록증</label>
                <MyPageBusnessImageUploadContainer ref={refMouseHover}>
                    <MyPageBusinessImageUploadButton onClick={handleButtonClick}>
                        업로드
                    </MyPageBusinessImageUploadButton>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                    />
                    {businessRegistration && (
                        <MyPageBusinessImageTitleContainer>
                            <MyPageBusinessImageTitle>{imageName}</MyPageBusinessImageTitle>
                            <BuisnessImageHover ishovershow={isMouseHover}>
                                <BuisnessImage src={businessRegistration} alt={null} />
                            </BuisnessImageHover>
                        </MyPageBusinessImageTitleContainer>
                    )}
                </MyPageBusnessImageUploadContainer>

                <MyPageDataControlBox>
                    <MyPageDataSubmitButton
                        onClick={() => {
                            submit()
                        }}
                    >
                        저장
                    </MyPageDataSubmitButton>
                </MyPageDataControlBox>
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
