import React, { useEffect, useRef, useState } from "react"

// import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../types/DataEnum"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import {
    CouponApplicationStatusTextColor,
    CouponHistoryLayout,
    CouponHistoryList,
    CouponHistorySearchBox,
    MyPagePointDashPointBox,
    PointHistoryPointValueBox,
    PointHistoryUserBox,
    PointModalTitle,
    PointSetWriteLayout,
} from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"
import { useInView } from "react-intersection-observer"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { getImageFile } from "../../../apis/api/files"
import { ImageContainer } from "../../../components/ImageContainer"
import {
    CouponApplicationStatusTypeNames,
    CouponApplicationStatusTypes,
    PointHistoryTypeNames,
} from "../../../types/points/PointTypes"
import { UserTypes } from "../../../types/users/UserTypes"
import User from "../../../types/users/User"
import ModalContainerPortal from "../../../components/ModalContainerPortal"
import MyPagePointHistory from "./MyPagePointHistory"
import CouponApplication from "../../../types/points/CouponApplication"
import MyPageCouponTradeModal from "./MyPageCouponTradeModal"

const PAGE_SIZE = 20

const MyPageCouponApplication = ({ user, coupon }) => {
    const [isLoading, setLoading] = useState(true)

    const [search, setSearch] = useState("")
    const [searchedValue, setSearchedValue] = useState("")
    const [couponApplications, setPointHistories] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()
    const listRef = useRef(null)

    const [selectedUser, setSelectedUser] = useState(new User())
    const [showUserHistoryModal, setShowUserHistoryModal] = useState(false)

    const [showCouponModal, setShowCouponModal] = useState(false)
    const [selectedApplication, setSelectedApplication] = useState(new CouponApplication())

    useEffect(() => {
        getCouponApplications(1, PAGE_SIZE, "")
    }, [])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && couponApplications.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            getCouponApplications(pageIndex + 1, PAGE_SIZE, searchedValue)
        }
    }, [scrollInView])

    const getCouponApplications = async (_pageIndex, _pageSize, _search) => {
        // if (Utils.isStringNullOrEmpty(search)) {
        //     setPointHistories([])
        //     setPageIndex(1)
        //     setMaxPage(1)
        //     return
        // }

        const pagination = await PointApi.getCouponApplications(_pageIndex, _pageSize, _search, null, coupon.id)

        if (pagination.count > 0) {
            if (_pageIndex > 1) {
                setPointHistories([...couponApplications, ...pagination.items])
            } else {
                setPointHistories(pagination.items)
            }
            setMaxPage(Math.ceil(pagination.count / _pageSize))
            setPageIndex(_pageIndex)
            setSearchedValue(_search)
        } else {
            setPointHistories([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        getCouponApplications(1, PAGE_SIZE, search)
        if (listRef.current !== null) {
            listRef.current.scrollTo({ top: 0 })
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleUserHistoryModal = (_user) => {
        setSelectedUser(_user)
        setShowUserHistoryModal(true)
    }

    const handleCouponTrade = (_application) => {
        setSelectedApplication(_application)
        setShowCouponModal(true)
    }

    return (
        <CouponHistoryLayout>
            {/* <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} /> */}

            <div className="flex flex-col w-full h-full max-sm:p-4 sm:p-6">
                <PointModalTitle className="px-2">쿠폰 구매 내역</PointModalTitle>
                {user.userType === UserTypes.STAFF && (
                    <CouponHistorySearchBox>
                        <input
                            type="text"
                            placeholder="회원 검색"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            onKeyPress={handleEnter}
                        />

                        <Search
                            onClick={() => {
                                handleSearch()
                            }}
                        />
                    </CouponHistorySearchBox>
                )}

                <CouponHistoryList ref={listRef}>
                    {couponApplications.map((application, index) => (
                        <Item
                            key={index}
                            application={application}
                            handleUserHistoryModal={handleUserHistoryModal}
                            handleCouponTrade={handleCouponTrade}
                        />
                    ))}
                    <div className="w-full h-2" ref={scrollRef}></div>
                </CouponHistoryList>
            </div>
            <ModalContainerPortal
                show={showUserHistoryModal}
                setShow={setShowUserHistoryModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPagePointHistory
                    showModal={showUserHistoryModal}
                    setShowModal={setShowUserHistoryModal}
                    userType={user.userType}
                    defaultUser={selectedUser}
                />
            </ModalContainerPortal>
            <ModalContainerPortal
                show={showCouponModal}
                setShow={setShowCouponModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageCouponTradeModal
                    application={selectedApplication}
                    showModal={showCouponModal}
                    setShowModal={setShowCouponModal}
                />
            </ModalContainerPortal>
        </CouponHistoryLayout>
    )
}

export default MyPageCouponApplication

const Item = ({ application, handleUserHistoryModal, handleCouponTrade }) => {
    const [applicationStatus, setApplicatonStatus] = useState(application.applicationStatus)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        setApplicatonStatus(application.applicationStatus)
    }, [application])

    const handleStatus = async (_status) => {
        if (applicationStatus == _status) {
            return
        }

        if (!confirm(`${CouponApplicationStatusTypeNames[applicationStatus]}상태로 바꾸시겠습니까?`)) {
            return
        }

        if (isLoading) {
            alert("요청 중입니다.")
            return
        }
        setLoading(true)

        let requestData = application.convertToRequest()
        requestData["application_status"] = _status

        const response = await PointApi.updateCouponApplication(application.uuid, requestData)
        setLoading(false)

        if (!Utils.isStringNullOrEmpty(response.uuid)) {
            alert("저장되었습니다.")
            setApplicatonStatus(response.applicationStatus)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <div className="flex flex-col w-full p-2">
            <div className="flex flex-col w-full">
                <span className="font-semibold text-black">{application.coupon.title}</span>
                <div className="flex justify-between items-start w-full">
                    <span className="flex items-center font-light text-slate-700 text-sm mt-auto">
                        {Utils.textFormat(application.paymentPrice, TextFormats.NUMBER)}P -
                        <CouponApplicationStatusTextColor className="ml-1" $status={applicationStatus}>
                            {CouponApplicationStatusTypeNames[applicationStatus]}
                        </CouponApplicationStatusTextColor>
                    </span>
                    <div
                        className="
                            flex flex-shrink-0 items-center space-x-1
                            [&>button]:px-1.5 [&>button]:rounded
                            [&>button]:text-sm [&>button]:duration-200
                        "
                    >
                        {applicationStatus == CouponApplicationStatusTypes.DONE && (
                            <button
                                className={"bg-slate-200 hover:bg-slate-300 text-slate-700 border-slate-400"}
                                onClick={() => {
                                    handleCouponTrade(application)
                                }}
                            >
                                쿠폰 조회
                            </button>
                        )}
                        {/* 신청 중이 아닌 확정이 된 상태면 보이지 않는다. */}
                        {applicationStatus == CouponApplicationStatusTypes.APPLY && (
                            <button
                                className={`${
                                    applicationStatus == CouponApplicationStatusTypes.APPLY
                                        ? "bg-slate-600 text-slate-100 border-slate-700"
                                        : "bg-slate-100 hover:bg-slate-200 text-slate-600 border-slate-400"
                                }`}
                                onClick={() => {
                                    handleStatus(CouponApplicationStatusTypes.APPLY)
                                }}
                            >
                                신청 중
                            </button>
                        )}
                        {/* 환불이 된 상태면 다시 구매 확정할 수 없다. */}
                        {applicationStatus != CouponApplicationStatusTypes.REFUNDED && (
                            <button
                                className={`${
                                    applicationStatus == CouponApplicationStatusTypes.DONE
                                        ? "bg-green-600 text-green-100 border-green-700"
                                        : "bg-green-100 hover:bg-green-200 text-green-600 border-green-400"
                                }`}
                                onClick={() => {
                                    handleStatus(CouponApplicationStatusTypes.DONE)
                                }}
                            >
                                구매 확정
                            </button>
                        )}
                        <button
                            className={`${
                                applicationStatus == CouponApplicationStatusTypes.REFUNDED
                                    ? "bg-red-600 text-red-100 border-red-700"
                                    : "bg-red-100 hover:bg-red-200 text-red-600 border-red-400"
                            }`}
                            onClick={() => {
                                handleStatus(CouponApplicationStatusTypes.REFUNDED)
                            }}
                        >
                            환불 확정
                        </button>
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="font-light text-slate-500 text-xs">구입일 {application.created}</span>
                    {!Utils.isStringNullOrEmpty(application.doneDateTime) && (
                        <span className="font-light text-green-500 text-xs">확정일 {application.doneDateTime}</span>
                    )}
                    {!Utils.isStringNullOrEmpty(application.refundedDateTime) && (
                        <span className="font-light text-red-500 text-xs">환불일 {application.refundedDateTime}</span>
                    )}
                </div>
            </div>
            <div
                className="flex items-center w-full h-6 px-1 py-1 mt-1 bg-slate-200 hover:bg-slate-300 rounded text-xs divide-x divide-slate-400 [&>span]:px-1 cursor-pointer duration-200"
                onClick={() => {
                    handleUserHistoryModal(application.marketer.user)
                }}
            >
                <span>{application.marketer.user.email}</span>
                <span className="max-sm:hidden">{application.marketer.user.name}</span>
                <span className="max-sm:hidden">{application.marketer.user.nickname}</span>
                <span className="max-sm:hidden">{application.marketer.user.tel}</span>
                <span>보유 {Utils.textFormat(application.marketer.user.points, TextFormats.NUMBER)}P</span>
            </div>
        </div>
    )
}
