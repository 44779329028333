import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { MarketerTableDataList } from "../../../constants/ConstMarketer"
import {
    MarketerCon,
    MarketerControlBar,
    MarketerFilterResetButton,
    MarketerFilterResetIcon,
    MarketerSortOptionButton,
    MarketerSortVisCon,
    MarketerStatSelect,
    MarketerSubControlBox,
    MarketerTableBody,
    MarketerTableButton,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
    MarketerTableSearch,
    MarketerTableSearchCon,
    MarketerTableSortInput,
    MarketerTableSortSelect,
    MarketerTableStatusSelectBox,
    MarketerToggleButton,
    MarketerTopContainer,
} from "./MyPageMarketerStyle"
import { ContentContainer } from "../MyPageMainStyle"
import MyPageMarketerTable from "./MyPageMarketerTable"
import { getItemMarketers } from "../../../apis/api/boardItems"
import {
    PerformListControlBox,
    PerformListControlSection,
    PerformListControlSectionLast,
    PerformTableFilterButton,
    PerformTableFilterIcon,
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSearchButton,
    PerformTableSearchIcon,
} from "../perform/MyPagePerformStyle"
import { Pagination } from "../../../components/Pagination"
import { PaginationStyles } from "../../../types/StyleTypes"
import {
    MarketerStatusTypeNames,
    MarketerStatusTypes,
    MarketerSuspendStatusTypeNames,
    MarketerSuspendStatusTypes,
} from "../../../types/brandItems/BrandItemTypes"
import AccessRules from "../../../rules/AccessRules"
import { useUserStore } from "../../../zustand/store"
import { FilterInputCombo, FilterInputReset, FilterInputText } from "../../../components/brandItems/FilterInputs"
import {
    BrandItemMarketerSearchOptionNames,
    BrandItemMarketerSearchOptionTypes,
    BrandItemMarketerOrderOptionNames,
    BrandItemMarketerOrderOptionTypes,
} from "../../../types/brandItems/BrandItemOption"
import { SortTypes } from "../../../types/EditorTypes"
import { MobileModal } from "../../../components/MobileModal"
import MyPageMarketerDetailModal from "../../../components/mypage/marketer/MyPageMarketerDetailModal"
import SpinLoading from "../../../components/loading/SpinLoading"
import { RESPONSIVE_WIDTH } from "../../../constants/ConstUI"
import { ModalContainer } from "../../../components/ModalContainer"
import MyPageGroup from "../marketerGroup/MyPageGroup"
import MyPageGroupManager from "../marketerGroup/MyPageGroupManager"
import BrandItemMarketer from "../../../types/brandItems/marketers/BrandItemMarketer"

const MAX_PAGE_LENGTH = 5

export default function MyPageMarketer({ setOfficeNavState, selectedItem }) {
    const { user, actions } = useUserStore()
    const navigate = useNavigate()

    const [sortvisible, setSortvisible] = useState(0)

    const [marketers, setMarketers] = useState([])

    const [isLoading, setLoading] = useState(true)

    const [search, setSearch] = useState("")
    const [searchStatus, setSearchStatus] = useState(MarketerStatusTypes.NONE)
    const [searchSuspendStatus, setSearchSuspendStatus] = useState(MarketerSuspendStatusTypes.NONE)
    const [searchOptionType, setSearchOptionType] = useState(BrandItemMarketerSearchOptionTypes.MARKETER)
    const [order, setOrder] = useState(BrandItemMarketerOrderOptionTypes.REGIST)
    const [sortType, setSortType] = useState(SortTypes.DESC)

    const [pageSize, setPageSize] = useState(10)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [groupManagerShow, setGroupManagerShow] = useState(false)
    const [selectedItemMarketer, setSelectedItemMarketer] = useState(new BrandItemMarketer())

    useEffect(() => {
        setOfficeNavState(PmsOfficeNavState.MARKETER)

        return () => setOfficeNavState(PmsOfficeNavState.NONE)
    }, [])

    useEffect(() => {
        if (user.id >= 0 && !AccessRules.brandItemManagement(user)) {
            alert("잘못된 접근입니다.")
            navigate(`/mypage`)
        }
    }, [user])

    useEffect(() => {
        setLoading(true)
        getMarketers(pageIndex, pageSize, search, searchOptionType, searchStatus, searchSuspendStatus, order, sortType)
    }, [selectedItem])

    // useEffect(()=>{
    //     getMarketers()
    // }, [pageIndex])

    // useEffect(()=>{
    //     if (pageIndex > 1) {
    //         setPageIndex(1)
    //     } else {
    //         getMarketers()
    //     }
    // }, [searchStatus])

    const getMarketers = async (
        _pageIndex, // 페이지
        _pageSize, // 페이지내 아이템 개수
        _search, // 검색 키워드
        _searchType, // 검색 종류 (마케터, 그룹 명)
        _searchStatus, // 마케터 상태
        _searchSuspendStatus, // 마케터 정지 상태
        _order, // 정렬 옵션
        _sort, // 정렬 방향
    ) => {
        // if (isLoading) {
        //     return
        // }
        if (_sort === SortTypes.DESC) {
            _order = "-" + _order
        }
        // 정렬 옵션이 id가 아닌 경우 2차로 id를 정렬한다
        if (!_order.includes(BrandItemMarketerOrderOptionTypes.REGIST)) {
            _order += ",-id"
        }
        const response = await getItemMarketers(
            selectedItem.id,
            _pageIndex,
            _pageSize,
            _search,
            _searchType,
            _searchStatus,
            _searchSuspendStatus,
            _order,
            _sort,
        )
        if (response !== undefined) {
            setPageIndex(_pageIndex)
            setMarketers(response.items)
            setItemCount(response.count)
        }

        setLoading(false)
    }

    const handleSearchMarketer = () => {
        if (isLoading) {
            return
        }
        getMarketers(1, pageSize, search, searchOptionType, searchStatus, searchSuspendStatus, order, sortType)
    }

    const handleSearchMarketerEnter = () => {
        if (isLoading) {
            return
        }
        handleSearchMarketer()
    }

    const handlePageIndex = (pageIndex) => {
        if (isLoading) {
            return
        }
        getMarketers(pageIndex, pageSize, search, searchOptionType, searchStatus, searchSuspendStatus, order, sortType)
    }
    const handleStatus = (value) => {
        if (isLoading) {
            return
        }
        setSearchStatus(value)
        getMarketers(1, pageSize, search, searchOptionType, value, searchSuspendStatus, order, sortType)
    }
    const handleSuspendStatus = (value) => {
        setSearchSuspendStatus(value)
        getMarketers(1, pageSize, search, searchOptionType, searchStatus, value, order, sortType)
    }
    const handleOrderType = (value) => {
        if (isLoading) {
            return
        }
        setOrder(value)
        let _sortType = sortType
        if (value === BrandItemMarketerOrderOptionTypes.REGIST) {
            _sortType = SortTypes.DESC
        } else if (value === BrandItemMarketerOrderOptionTypes.MARKETER) {
            _sortType = SortTypes.ASC
        } else if (value === BrandItemMarketerOrderOptionTypes.GROUP) {
            _sortType = SortTypes.ASC
        }
        setSortType(_sortType)

        getMarketers(1, pageSize, search, searchOptionType, searchStatus, searchSuspendStatus, value, _sortType)
    }
    const handleSortType = (value) => {
        if (isLoading) {
            return
        }
        setSortType(value)
        getMarketers(1, pageSize, search, searchOptionType, searchStatus, searchSuspendStatus, order, value)
    }

    const handleFilterReset = () => {
        if (isLoading) {
            return
        }
        setSearch("")
        setSearchStatus(MarketerStatusTypes.NONE)
    }

    const handleOpenGroupManager = (itemMarketer) => {
        setGroupManagerShow(true)
        setSelectedItemMarketer(itemMarketer)
    }

    const handleUpdateWaitMarketers = () => {
        actions.updateMarketerWaitCount(selectedItem.id)
    }

    return (
        <ContentContainer full_height>
            <PerformListControlBox className="max-lg:h-[5rem]">
                <PerformListControlSection className="max-lg:mb-2">
                    <FilterInputText
                        value={search}
                        setValue={setSearch}
                        submit={() => {
                            handleSearchMarketerEnter()
                        }}
                        width={"52"}
                        placeholder={BrandItemMarketerSearchOptionNames[searchOptionType]}
                        textOption={searchOptionType}
                        setTextOption={setSearchOptionType}
                        textOptionTypes={BrandItemMarketerSearchOptionTypes}
                        textOptionNames={BrandItemMarketerSearchOptionNames}
                        textOptionWidth={"16"}
                    />
                    <div className="max-lg:hidden lg:block">
                        <FilterInputCombo label={"승인 상태"} value={searchStatus} setValue={handleStatus} width={"36"}>
                            <option value={MarketerStatusTypes.NONE}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.NONE]}
                            </option>
                            <option value={MarketerStatusTypes.WAIT}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.WAIT]}
                            </option>
                            <option value={MarketerStatusTypes.CONFIRM}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.CONFIRM]}
                            </option>
                            <option value={MarketerStatusTypes.BLOCK}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.BLOCK]}
                            </option>
                        </FilterInputCombo>
                    </div>
                    <div className="max-lg:hidden lg:block">
                        <FilterInputCombo
                            label={"정지 상태"}
                            value={searchSuspendStatus}
                            setValue={handleSuspendStatus}
                            width={"40"}
                        >
                            <option value={MarketerSuspendStatusTypes.NONE}>
                                {MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.NONE]}
                            </option>
                            <option value={MarketerSuspendStatusTypes.WAIT}>
                                {MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.WAIT]}
                            </option>
                            <option value={MarketerSuspendStatusTypes.CONFIRM}>
                                {MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.CONFIRM]}
                            </option>
                        </FilterInputCombo>
                    </div>
                    <div className="max-lg:hidden lg:block">
                        <FilterInputCombo
                            label={"정렬"}
                            value={order}
                            setValue={handleOrderType}
                            sortType={sortType}
                            setSortType={handleSortType}
                            width={"48"}
                        >
                            <option value={BrandItemMarketerOrderOptionTypes.REGIST}>
                                {BrandItemMarketerOrderOptionNames[BrandItemMarketerOrderOptionTypes.REGIST]}
                            </option>
                            <option value={BrandItemMarketerOrderOptionTypes.MARKETER}>
                                {BrandItemMarketerOrderOptionNames[BrandItemMarketerOrderOptionTypes.MARKETER]}
                            </option>
                            <option value={BrandItemMarketerOrderOptionTypes.GROUP}>
                                {BrandItemMarketerOrderOptionNames[BrandItemMarketerOrderOptionTypes.GROUP]}
                            </option>
                            <option value={BrandItemMarketerOrderOptionTypes.STATUS}>
                                {BrandItemMarketerOrderOptionNames[BrandItemMarketerOrderOptionTypes.STATUS]}
                            </option>
                            <option value={BrandItemMarketerOrderOptionTypes.SUSPEND}>
                                {BrandItemMarketerOrderOptionNames[BrandItemMarketerOrderOptionTypes.SUSPEND]}
                            </option>
                        </FilterInputCombo>
                    </div>
                    <FilterInputReset reset={handleFilterReset} />
                </PerformListControlSection>

                <PerformListControlSectionLast last>
                    <div className="max-lg:hidden lg:block h-9 ml-2">
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={MAX_PAGE_LENGTH}
                            styleType={PaginationStyles.NoneBGCircleButtons}
                        />
                    </div>
                    <div className="max-lg:hidden lg:w-2"></div>
                    {/* <div className="flex flex-1 justify-end">
                        <Link to={`/mypage/perform/marketer/${selectedItem.id}`}>
                            <MarketerToggleButton marketerstate={0}>마케터 관리</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/perform/group/${selectedItem.id}`}>
                            <MarketerToggleButton marketerstate={1}>그룹 관리</MarketerToggleButton>
                        </Link>
                    </div> */}
                </PerformListControlSectionLast>
            </PerformListControlBox>
            <MarketerSubControlBox></MarketerSubControlBox>

            <div className="w-full lg:h-full relative">
                <MyPageMarketerTable
                    isManage={true}
                    item={selectedItem}
                    marketers={marketers}
                    openGroupManager={handleOpenGroupManager}
                    updateWaitMarketers={handleUpdateWaitMarketers}
                />
            </div>

            <div className="max-lg:block lg:hidden h-9 mt-4">
                <Pagination
                    page={pageIndex}
                    setPage={handlePageIndex}
                    pageSize={pageSize}
                    itemCount={itemCount}
                    maxPageButtons={MAX_PAGE_LENGTH}
                    styleType={PaginationStyles.NoneBGCircleButtons}
                />
            </div>

            <ModalContainer
                show={groupManagerShow}
                setShow={setGroupManagerShow}
                isBlur={true}
                fadeDown={true}
                closeByBackground
            >
                <MyPageGroupManager itemMarketer={selectedItemMarketer} getCurrentMarketers={handleSearchMarketer} />
            </ModalContainer>

            <SpinLoading isLoading={isLoading} maxWindowWidth={RESPONSIVE_WIDTH.LG} />
        </ContentContainer>
    )
}
