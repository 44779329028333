import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import {FileEarmark} from "@styled-icons/bootstrap/FileEarmark"
import {FileEarmarkArrowDownFill} from "@styled-icons/bootstrap/FileEarmarkArrowDownFill"
import {FileEarmarkImageFill} from "@styled-icons/bootstrap/FileEarmarkImageFill"
import {UploadFile} from "@styled-icons/material-rounded/UploadFile"
import {CloudUpload} from "@styled-icons/bootstrap/CloudUpload"
import {Expand} from "@styled-icons/evaicons-solid/Expand"
import {Download} from "@styled-icons/boxicons-regular/Download"
import {Delete} from "@styled-icons/material/Delete"
import {CaretRightFill} from '@styled-icons/bootstrap/CaretRightFill'
import {Close} from '@styled-icons/ionicons-solid/Close'

export const FilePreviewCell = tw.div`
flex items-center h-full px-4 py-2
rounded-lg
hover:bg-gray-100
[&>span]:text-sm text-gray-600 [&>span]:select-none
duration-100
`

export const FileGridContainer = tw.div`
    flex flex-col w-full h-full
    overflow-y-auto scroll-transparent 
`
export const FileGrid = tw.div`
grid grid-cols-2 gap-3 p-1
w-full
`

export const FileLayout = tw.div`
flex flex-col flex-center w-full max-sm:h-36 sm:h-64 px-3 py-2 relative
bg-gray-100 rounded-lg select-none
${(props) => props.isTemp ? "ring-2 ring-yellow-400" : ""}
`
export const FileTypeTitle = tw.div`
flex items-center
text-gray-700 h-6 
`
// export const FileUploadBox = tw.div`
// absolute right-0 -top-6 h-6
// flex items-center
// `
export const FileZeroMessageSpan = tw.div`
    flex flex-center w-full h-full p-4
    rounded-md border bg-gray-100
    max-lg:text-base lg:text-lg max-lg:font-medium lg:font-semibold
`
export const FileInfoBox = tw.div`
flex justify-between items-center w-full h-6
`
export const FileInfoControlBox = tw.div`
flex items-center h-5
`
export const FileContentBox = tw.div`
w-full h-full flex flex-center py-1
`
export const FileUploadIconContainer = tw.div`
h-12 p-3 mb-4 text-gray-600 group
rounded-lg duration-200 cursor-pointer
${(props) => props.hasFile && "hover:bg-white/20"}
`
export const FileIconUpload = tw(UploadFile)`
h-full
`
export const FileIconDelete = tw(Delete)`
h-full
`
export const FileIconBlank = tw(FileEarmark)`
h-full
`
export const FileIconDownload = tw(FileEarmarkArrowDownFill)`
h-full group-hover:animate-bounce duration-300
`
export const FileIconImage = tw(FileEarmarkImageFill)`
h-full
`
export const FileDeleteIconContainer = tw.div`
flex flex-center mr-1 h-full p-0.25 cursor-pointer
text-gray-500 hover:text-red-700
`
export const FileImageContainer = tw.div`
    w-full h-full relative
    rounded-lg overflow-hidden
`
export const FileImageBox = tw.div`
    w-full h-full
    absolute z-0
`
export const FileImageDetailBox = tw.div`
    flex flex-center w-full h-full
    absolute z-10
    max-lg:bg-black/20 lg:bg-white/20 lg:backdrop-blur-sm
    lg:opacity-0 hover:opacity-100 duration-300
`
export const FileImageDetailIcons = tw.div`
    flex h-10 text-white
`
export const FileImageDetailIconContainer = tw.div`
    flex flex-center mx-1 p-2 hover:bg-white/20
    duration-200 rounded-lg group cursor-pointer
    text-gray-600
`
export const FileNameSpan = tw.span`
    text-gray-700 text-sm truncate h-6
`

export const FileDetailBox = tw.div`
flex justify-between items-center w-full
[&>span]:text-gray-500 [&>span]:text-xs
`

export const FileUploadLayout = tw.div`
flex flex-center w-full max-lg:h-16 lg:h-40 mt-2 flex-shrink-0
`
export const FileUploadBoxDesktop = tw.div`
max-lg:hidden lg:flex justify-center items-center w-full h-full px-3 py-2 relative
rounded-lg
bg-blue-100
`
export const FileUploadBoxMobile = tw.div`
max-lg:flex lg:hidden justify-center items-center w-full h-full px-3 py-2 relative
rounded-lg
bg-blue-100
`
export const FileExpandIcon = tw(Expand)`
    h-full group-hover:scale-125 duration-300
`
export const FileUploadIcon = tw(CloudUpload)`
    h-6 text-blue-600
    ${(props) => props.isDragging && "animate-bounce"}
`
export const FileIconImageDownload = tw(Download)`
    h-full group-hover:animate-bounce
`
export const FileUploadDragSpan = tw.span`
    text-blue-600 text-sm mt-2
    ${(props) => props.isDragging && "animate-pulse"}
`
export const FileInputLabel = tw.label`
    flex flex-center ml-2 w-20 py-2
    text-white text-sm
    bg-blue-400 hover:bg-blue-300 rounded-md
    duration-100 cursor-pointer
`
export const RightCaretIcon = tw(CaretRightFill)`
        w-full text-white group-hover:text-gray-400
`

export const LeftCaretIcon = tw(CaretRightFill)`
    w-fulltext-white origin-center rotate-180 group-hover:text-gray-400

`
export const CloseIcon = tw(Close)`
    text-white h-10
`