import React,{useState, useEffect, useRef, useCallback} from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import { deleteFile, uploadFile } from "../../apis/api/files";
import { useDragAndDrop } from "../../hooks/useFileDragAndDrop";
import { FileIconDelete, FileUploadDragSpan, FileUploadIcon } from "../../pages/myPage/perform/PerformValueFileStyle";
import { BoardDetailTitle, NoticeDeleteButton, BoardDetailBody,ReturnButton, BoardDetailBottom, BoardDetailContainer, BoardDetailTitlebottom, BoardDetailHead, NoticeEditButton, NoticeInner, ReturnIcon, BoardDetailLayout, BoardDetailInfoRow, BoardDetailInfos, BoardDetailInfoBox, BoardDetailInfoValue, BoardDetailInfoLabel, BoardControlButton } from "./BoardDetailStyle";
import { FileInputLabel, FileInputText, LinkIcon, UploadBoxDesktop, UploadBoxMobile, UploadedBox, UploadedFileIconBox, UploadedFileName, UploadedListBox, UploadLayout } from "./BoardFileUploaderStyle";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileUtils from "../../utils/FileUtils";

const FileInput = ({id, onChange}) => {
    return (
        <React.Fragment>
            <FileInputLabel for={`input-perform-file-${id}`}>
                {/* <FileIconUpload /> */}
                파일을 드래그하거나 선택해주세요.
            </FileInputLabel>
            <input id={`input-perform-file-${id}`} className="w-0 hidden" type="file" onChange={onChange} />
        </React.Fragment>
    )
}
const FileInputMobile = ({id, onChange}) => {
    return (
        <React.Fragment>
            <FileInputLabel for={`input-perform-file-${id}`}>
                {/* <FileIconUpload /> */}
                파일 업로드
            </FileInputLabel>
            <input id={`input-perform-file-${id}`} className="w-0 hidden" type="file" onChange={onChange} />
        </React.Fragment>
    )
}

const UploadedFile = ({file, fileListDelete}) => {
    const handleCopy = () => {
        alert("복사되었습니다.")
    }
    const handleDelete = () => {
        deleteFile(file.id).then(response => {
            if (response) {
                fileListDelete(file.id)
                alert("삭제되었습니다.")
            } else {
                alert("삭제실패했습니다.")
            }
        })
    }
    return (
        <UploadedBox is_temp={file.isTemp}>
            <UploadedFileName title={file.name}>{file.name}</UploadedFileName>
            <CopyToClipboard text={file.fileURL} onCopy={()=>{handleCopy()}}>
                <UploadedFileIconBox>
                    <LinkIcon />
                </UploadedFileIconBox>
            </CopyToClipboard>
            <UploadedFileIconBox onClick={()=>{handleDelete()}}>
                <FileIconDelete />
            </UploadedFileIconBox>
        </UploadedBox>
    )
}

export const BoardFileUploader = ({id, boardFiles, setBoardFiles}) => {
    const [files, setFiles] = useState([])

    const dragRef = useRef()
    const fileID = useRef(0)

    // 파일 업로드 이벤트
    const onChangeFiles = useCallback((e) => {
        let selectFiles = [];
        let tempFiles = files;

        if (e.type === "drop") {
            selectFiles = e.dataTransfer.files;
        } else {
            selectFiles = e.target.files;
        }

        for (const file of selectFiles) {
            tempFiles = [
                ...tempFiles,
                {
                    id: fileID.current++,
                    object: file
                }
            ];
        }

        setFiles(tempFiles);
    }, [files])
    const isDragging = useDragAndDrop({onChange: onChangeFiles, dragRef: dragRef})
    
    useEffect(()=>{
        // 파일 업로드 감지
        if (files.length > 0) {
            uploadFiles()
            setFiles([])
            fileID.current = 0
        }
    }, [files])
    
    const uploadFiles = async () => {
        let uploads = []

        for (let i=0; i<files.length; i++) {
            const file = files[i].object
            if (FileUtils.isFileOverVolume(FileUtils.getFileSize(file), 10)) {
                alert("크기가 10MB가 초과되는 파일이 있습니다.")
                return
            }
        }

        for (let i=0; i<files.length; i++) {
            const file = files[i].object
            
            let commonFile = null
            commonFile = await uploadFile(file)

            if (!(commonFile.id >= 0)) {
                continue
            }
            uploads.push(commonFile)
        }
        
        setBoardFiles([...boardFiles, ...uploads])
    }

    const handleFileListDelete = (fileID) => {
        let newFiles = []
        boardFiles.map(f => {
            if (f.id !== fileID) {
                newFiles.push(f)
            }
        })

        setBoardFiles(newFiles)
    }

    return (
        <UploadLayout>
            <UploadedListBox>
                {boardFiles.map((boardFile, idx) => (
                    <UploadedFile file={boardFile} key={idx} fileListDelete={handleFileListDelete} />
                ))}
            </UploadedListBox>
            {/* 파일 업로드 */}
            <UploadBoxDesktop ref={dragRef}>
                <div className="flex flex-col flex-center w-full h-full absolute">
                    <div className="flex flex-col">
                        <FileUploadIcon isDragging={isDragging} />
                        {/* <FileUploadDragSpan>
                            파일을 드래그하거나 선택해주세요.
                        </FileUploadDragSpan> */}
                    </div>
                    <FileInput id={`${id}`} onChange={onChangeFiles} />
                    <FileInputText>각 파일 당 최대 파일 크기 : 10MB</FileInputText>
                </div>
            </UploadBoxDesktop>
            <UploadBoxMobile>
                <div className="flex flex-center w-full h-full absolute">
                    <FileInputMobile id={`${id}`} onChange={onChangeFiles} />
                    <FileInputText className="text-xs ml-2">각 파일 당 최대 파일 크기 : 10MB</FileInputText>
                </div>
            </UploadBoxMobile>
        </UploadLayout>
    )
}