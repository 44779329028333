import React, { useCallback, useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import {
    ItemLayout,
    ItemList,
    ItemInnerTop,
    ItemInnerBottom,
    SettingBtn,
    SettingIcon,
    ItemCardLayout,
    ItemThumbnailBox,
    ItemControlBox,
    ItemDetailBox,
    ItemDetailDataBox,
    FileContractIcon,
    VersionIcon,
    ItemProcessBox,
    ItemMarketerBox,
    CreatedTimeIcon,
    UpdatedTimeIcon,
    ItemProcessObjectBox,
    ItemProcessTitle,
    ItemProcessValue,
    LinkIcon,
    LinkBtn,
    PerformIcon,
    ItemNoticeListBox,
    ItemNoticeRow,
    ItemSortButton,
    RegistedIcon,
    SetContractBtn,
    CancelIcon,
    ItemDetailTitle,
    DocumentIcon,
    ItemNoticeBlankBox,
    ItemNoticeBlankMessage,
    DetailIcon,
} from "../MyPageItemStyle"
import { Pagination } from "../../../../components/Pagination"
import { Link, useNavigate } from "react-router-dom"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { ImageBase64 } from "../../../../components/ImageContainer"
import {
    ItemSalesTypeNames,
    ItemSearchTypes,
    MarketerStatusTypes,
    MarketerSuspendStatusTypes,
    MarketerTypeNames,
} from "../../../../types/brandItems/BrandItemTypes"
import {
    ItemFilterNamesByMarketer,
    ItemFilterValuesByMarketer,
    ItemOrderNames,
    ItemOrders,
    ItemOrderValues,
    MYPAGE_ITEM_SIZE,
    MYPAGE_PAGE_LENGTH,
} from "../../../../constants/ConstItem"
import {
    deleteItemMarketer,
    getBrandItemListAuth,
    getBrandItemListAuthCount,
    getItemContractHtml,
    getItemContractMarketerImages,
    getItemMarketerID,
    getItemNotice,
} from "../../../../apis/api/boardItems"
import BrandItemList from "../../../../types/brandItems/BrandItemList"
import { ModalContainer } from "../../../../components/ModalContainer"
import MyPageItemWrite from "./MyPageItemWrite"
import MyPageItemContract from "./MyPageItemContract"
import { useUserStore } from "../../../../zustand/store"
import {
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSearchButton,
    PerformTableSearchIcon,
} from "../../perform/MyPagePerformStyle"
import {
    MarketerTableSortSelect,
    MarketerTableStatusSelectBox,
    MarketerToggleButton,
} from "../../marketer/MyPageMarketerStyle"
import BoardDetail from "../../../../components/boards/BoardDetail"
import Board from "../../../../types/boards/Board"
import { useMouseHover } from "../../../../hooks/useMouseHover"
import { PaginationStyles } from "../../../../types/StyleTypes"
import SortSwitch from "../../../../components/SortSwitch"
import { SortTypes, EditStateTypes } from "../../../../types/EditorTypes"
import AccessRules from "../../../../rules/AccessRules"
import BrandItemContract from "../../../../types/brandItems/BrandItemContract"
import ContractImageViewer from "../../../../components/brandItems/ContractImageViewer"
import { UserTypes } from "../../../../types/users/UserTypes"
import { getUserDetail, getUserHasSign, getUserThirdAgreementCode } from "../../../../apis/api/users"
import { BrandValidate } from "../../../../components/user/BrandValidate"

import useDropdownDetectClose from "../../../../hooks/useDropdownDetectClose"
import Utils from "../../../../utils/Utils"
import FileUtils from "../../../../utils/FileUtils"
import { ItemListStatusFilterButton, ItemListStatusFilterCount, ItemSearchBox } from "./MyPageItemBrandStyle"
import { mobileModalItemDetail } from "../../../../zustand/mobilemodalstore"
import { MobileModal } from "../../../../components/MobileModal"
import { MyPageDetailMobile } from "./MyPageDetailMobile"
import { RESPONSIVE_WIDTH } from "../../../../constants/ConstUI"
import { useRef } from "react"
import MyPageOpenAgreement from "./MyPageOpenAgreement"
import MyPageOpenAgreementList from "./MyPageOpenAgreementList"
import ModalContainerPortal from "../../../../components/ModalContainerPortal"

const PAGE_BUTTON_LENGTH = 10

const ItemDetailLabel = ({ label, value }) => {
    return (
        <div className="flex items-center max-lg:text-xs lg:text-sm">
            <span className="mr-2 max-lg:font-medium flex-shrink-0">{label}</span>
            <span className="text-gray-700 truncate" title={value}>
                {value}
            </span>
        </div>
    )
}

const ItemDetailTimeLabel = ({ label, value, width = "auto" }) => {
    return (
        <div className="flex items-center text-gray-700 mr-2">
            <span className="mr-1">{label}</span>
            <span className={`w-[${width}] truncate`}>{value}</span>
        </div>
    )
}

const ItemNoticeList = ({ notices, openNoticeModal }) => {
    const handleOpenNoticeModal = (notice) => {
        openNoticeModal(notice)
    }

    return (
        <ItemNoticeListBox>
            {notices.map((notice, idx) => (
                <ItemNoticeRow key={idx} onClick={() => handleOpenNoticeModal(notice)}>
                    <span className="w-full truncate" title={notice.board.title}>
                        {notice.board.title}
                    </span>
                    <span className="min-w-[8rem] text-right text-gray-600 max-sm:text-xs sm:text-sm">
                        {notice.board.created}
                    </span>
                </ItemNoticeRow>
            ))}
            {(Utils.isNullOrUndefined(notices) || notices.length === 0) && (
                <ItemNoticeBlankBox>
                    <ItemNoticeBlankMessage>등록된 공지사항이 없습니다.</ItemNoticeBlankMessage>
                </ItemNoticeBlankBox>
            )}
        </ItemNoticeListBox>
    )
}

// eslint-disable-next-line react/display-name
const ItemCardLayoutContainer = React.forwardRef(({ children, onClick }, ref) => {
    return (
        <>
            <ItemCardLayout className="lg:flex max-lg:hidden" ref={ref}>
                {children}
            </ItemCardLayout>
            <ItemCardLayout className="lg:hidden max-lg:flex" onClick={onClick}>
                {children}
            </ItemCardLayout>
        </>
    )
})

const ItemListCard = ({
    user,
    item,
    itemUpdate,
    showItemDetailMobile,
    showItemContract,
    deleteItemMarketer,
    setShowAgreementModal,
    openNoticeModal,
    openAgreementList,
}) => {
    const [thumbnailURL, setThumbnailURL] = useState("")

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setThumbnailURL("")
        } else {
            setThumbnailURL(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleItemUpdate = () => {
        itemUpdate(item)
    }
    const handleItemContract = () => {
        showItemContract(item)
    }
    async function handleOpenAgreement() {
        await getItemMarketerID(user.id, item.id).then((response) => {
            openAgreementList(item.id, response)
            setShowAgreementModal(true)
        })
    }
    const handleIconCancle = () => {
        // 취소 아이콘
        if (confirm(`상품 ${item.detail.title}의 지원 신청을 취소합니다.\n계속하시겠습니까?`)) {
            deleteItemMarketer(item)
        }
    }
    const handleItemPage = () => {
        window.open(`/items/${item.id}`, "_blank", "noopener, noreferrer")
    }
    const handlePerformPage = () => {
        window.open(`/items/${item.id}`, "_blank", "noopener, noreferrer")
    }

    const [hoverRef, isHover] = useMouseHover()

    const handleMobileModalOpen = () => {
        showItemDetailMobile(item)
    }

    return (
        <ItemCardLayoutContainer ref={hoverRef} onClick={handleMobileModalOpen}>
            <ItemDetailBox>
                <Link to={`/mypage/perform/${item.id}`} className="max-lg:hidden lg:block" state={{ itemID: item.id }}>
                    <ItemThumbnailBox>
                        {/* <ItemThumnail/>썸네일 이미지 들어갈 예정 */}
                        <ImageBase64 imageURL={thumbnailURL} />
                    </ItemThumbnailBox>
                </Link>
                <ItemThumbnailBox className="max-lg:flex lg:hidden">
                    {/* <ItemThumnail/>썸네일 이미지 들어갈 예정 */}
                    <ImageBase64 imageURL={thumbnailURL} />
                </ItemThumbnailBox>
                <ItemDetailDataBox>
                    {/* 아이템 데이터 */}
                    {/* 타이틀 */}
                    <Link
                        to={`/mypage/perform/${item.id}`}
                        className="max-sm:hidden sm:block"
                        state={{ itemID: item.id }}
                    >
                        <ItemDetailTitle className="hover:underline underline-offset-4">
                            {item.detail.title}
                        </ItemDetailTitle>
                    </Link>
                    <ItemDetailTitle className="max-lg:block lg:hidden">{item.detail.title}</ItemDetailTitle>

                    <div className="flex max-sm:flex-col-reverse sm:flex-col justify-end">
                        <div className="max-sm:hidden sm:grid max-sm:grid-cols-1 sm:grid-cols-2 max-sm:gap-0 sm:gap-1 w-full text-sm">
                            <div className="max-sm:hidden sm:block">
                                <ItemDetailLabel
                                    label={"상품 유형"}
                                    value={ItemSalesTypeNames[item.detail.itemSalesType]}
                                />
                            </div>
                            <ItemDetailLabel label={"실적 보상"} value={item.detail.rewardMessage} />
                            <div className="max-sm:hidden sm:block">
                                <ItemDetailLabel
                                    label={"마케터 타입"}
                                    value={MarketerTypeNames[item.detail.marketerType]}
                                />
                            </div>
                            <ItemDetailLabel label={"영업 업무"} value={item.detail.missionMessage} />
                        </div>
                        <div className="max-sm:block sm:hidden px-2 py-1 rounded-lg bg-slate-100">
                            <MyPageItemProcess item={item} openNoticeModal={openNoticeModal} simple />
                        </div>

                        <div className="flex items-center text-xs max-sm:mb-0.5 sm:mt-0.5">
                            <MyPageItemDate user={user} item={item} />
                            <MyPageItemMessage user={user} item={item} />

                            {/* 높이용 */}
                            <ItemDetailTimeLabel value={""} width={"1"} />
                        </div>
                    </div>
                </ItemDetailDataBox>
                <ItemControlBox>
                    {/* 아이템 세팅 */}
                    {AccessRules.brandItemUpdate(item, user) && (
                        <SettingBtn
                            isShow={item.isAccess}
                            onClick={() => {
                                handleItemUpdate()
                            }}
                        >
                            <SettingIcon />
                        </SettingBtn>
                    )}
                    {/* 아이템 취소 버튼 */}
                    <SetContractBtn
                        isShow={item.isAccess}
                        onClick={() => {
                            handleIconCancle()
                        }}
                    >
                        <CancelIcon />
                    </SetContractBtn>

                    {item.isAccess && AccessRules.marketer(user) && (
                        <SetContractBtn
                            className="hover:border-green-400"
                            onClick={() => {
                                handleOpenAgreement(item.id)
                            }}
                        >
                            <DocumentIcon className="hover:text-green-400" />
                        </SetContractBtn>
                    )}

                    <SetContractBtn
                        className="hover:border-green-400"
                        onClick={() => {
                            handleItemContract()
                        }}
                    >
                        <FileContractIcon />
                    </SetContractBtn>

                    <LinkBtn
                        isShow={item.isAccess}
                        onClick={() => {
                            handleItemPage()
                        }}
                    >
                        <DetailIcon />
                    </LinkBtn>
                    <Link to={`/mypage/perform/${item.id}`} state={{ itemID: item.id }}></Link>
                    {/* <Link to="edit">
                    </Link> */}
                </ItemControlBox>
            </ItemDetailBox>
            <ItemProcessBox isHover={isHover} isShow={item.isAccess}>
                <MyPageItemProcess item={item} openNoticeModal={openNoticeModal} />
            </ItemProcessBox>
            <ItemMarketerBox>
                <MyPageItemNoticeList openNoticeModal={openNoticeModal} item={item} isHover={isHover} user={user} />
            </ItemMarketerBox>
            {/* 마케터 혹은 공지사항은 width 100% 사용 */}
        </ItemCardLayoutContainer>
    )
}

export const MyPageItemNoticeList = ({ item, openNoticeModal }) => {
    return (
        <div className="w-full h-full relative">
            <ItemNoticeList notices={item.notices} openNoticeModal={openNoticeModal} />
        </div>
    )
}

export const MyPageItemProcess = ({ item, user, simple = false }) => {
    return (
        <>
            {/* 브랜드는 전부 볼 수 있다 */}
            {AccessRules.brandItemUpdate(item, user) && (
                <React.Fragment>
                    {!simple && (
                        <ItemProcessObjectBox>
                            <ItemProcessTitle>마케터</ItemProcessTitle>
                            <ItemProcessValue>{item.aggregation.marketers}</ItemProcessValue>
                        </ItemProcessObjectBox>
                    )}
                    <ItemProcessObjectBox>
                        <ItemProcessTitle>마케터 승인 대기</ItemProcessTitle>
                        <ItemProcessValue
                            className={`${item.aggregation.marketerRequests > 0 ? "text-red-600 animate-pulse" : ""}`}
                        >
                            {item.aggregation.marketerRequests}
                        </ItemProcessValue>
                    </ItemProcessObjectBox>
                </React.Fragment>
            )}
            {!simple && (
                <ItemProcessObjectBox>
                    <ItemProcessTitle>실적 등록</ItemProcessTitle>
                    <ItemProcessValue>{item.aggregation.performs}</ItemProcessValue>
                </ItemProcessObjectBox>
            )}
            <ItemProcessObjectBox>
                <ItemProcessTitle>정산 대기 / 완료</ItemProcessTitle>
                <ItemProcessValue>
                    {item.aggregation.performConfirms - item.aggregation.calculateds} / {item.aggregation.calculateds}
                </ItemProcessValue>
            </ItemProcessObjectBox>
        </>
    )
}

export const MyPageItemMessage = ({ user, item }) => {
    return (
        <>
            {/* 아이템 관리자인 경우 */}
            {AccessRules.brandItemManagement(user) && (
                <React.Fragment>
                    {/* <ItemDetailTimeLabel label={(<VersionIcon />)} value={item.contract.rev} /> */}
                    {item.editStateType === EditStateTypes.PENDING && (
                        <span className="text-orange-600">상품 등록 승인 대기중 입니다.</span>
                    )}
                    {item.editStateType !== EditStateTypes.PENDING && item.isEditRequested && (
                        <span className="text-yellow-600">상품 수정 승인 대기중 입니다.</span>
                    )}
                    {item.editStateType === EditStateTypes.DISABLED && (
                        <span className="text-gray-600">일시 정지 상태입니다.</span>
                    )}
                    {item.editStateType === EditStateTypes.FAILED && (
                        <span className="text-red-600">차단된 상품입니다.</span>
                    )}
                </React.Fragment>
            )}
            {/* 승인된 마케터의 경우 */}
            {/* 여기서만 perfromCreate 호출 시 에러 발생 확인 필요 230519 */}
            {/* {(item.isAccess && AccessRules.performCreate(user)) && ( */}
            {item.isAccess && user.userType === UserTypes.MARKETER && (
                <React.Fragment>
                    {item.editStateType === EditStateTypes.DISABLED && (
                        <span className="text-gray-600">일시 정지 상태입니다.</span>
                    )}
                    {item.editStateType === EditStateTypes.FAILED && (
                        <span className="text-red-600">영업 불가능한 상품입니다.</span>
                    )}
                    {item.marketerSuspendStatusType === MarketerSuspendStatusTypes.CONFIRM && (
                        <span className="text-red-500">영업 정지 상태입니다.</span>
                    )}
                </React.Fragment>
            )}
            {!item.isAccess && (
                <span className="text-red-500">
                    {item.marketerStatusType === MarketerStatusTypes.WAIT && "승인 대기 중 입니다."}
                </span>
            )}
            {AccessRules.staff(user) && (
                <span className="max-sm:hidden sm:block px-2 py-0.25 bg-black text-white justify-self-end">
                    {item.id}
                </span>
            )}
        </>
    )
}
export const MyPageItemDate = ({ user, item }) => {
    return (
        <>
            {/* 상품 관리자인 경우 */}
            {AccessRules.brandItemManagement(user) && (
                <React.Fragment>
                    {item.isAccess && (
                        <div className="max-sm:hidden sm:block">
                            <ItemDetailTimeLabel label={<CreatedTimeIcon />} value={item.detail.created} width={"64"} />
                        </div>
                    )}
                    {!Utils.isStringNullOrEmpty(item.contract.confirmed) && (
                        <div className="max-sm:hidden sm:block">
                            <ItemDetailTimeLabel label={<UpdatedTimeIcon />} value={item.contract.confirmed} />
                        </div>
                    )}
                </React.Fragment>
            )}
            {item.isAccess && user.userType === UserTypes.MARKETER && (
                <React.Fragment>
                    <div className="max-sm:hidden sm:block">
                        <ItemDetailTimeLabel label={<RegistedIcon />} value={item.registedDate} />
                    </div>
                </React.Fragment>
            )}
        </>
    )
}

export default function MyPageItemBrand() {
    const { user, userType, userDetail, actions } = useUserStore()
    const navigate = useNavigate()

    const [itemOrder, setItemOrder] = useState(
        user.userType === UserTypes.MARKETER ? ItemOrders.ITEM_REGISTED : ItemOrders.CREATED,
    )
    const [itemSortType, setItemSortType] = useState(SortTypes.DESC)
    const [itemMarketerStatusFilter, setItemMarketerStatusFilter] = useState(ItemFilterNamesByMarketer.ALL)
    const [itemEditStatusFilter, setItemEditStatusFilter] = useState(EditStateTypes.NONE)

    const [pageSize, setPageSize] = useState(MYPAGE_ITEM_SIZE)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [items, setItems] = useState([])

    const [searchValue, setSearchValue] = useState("") // 입력 중인 검색 값
    const [searchKeyword, setSearchKeyword] = useState("") // 검색한 혹은 검색할 값 값

    const [selectedItem, setSelectedItem] = useState(new BrandItem())
    const [showWriteModel, setShowWriteModal] = useState(false)
    const [showDetailMobileModal, setShowDetailMobileModal] = useState(false) // 모바일용 상품 상세 모달
    const [showContractModal, setShowContractModal] = useState(false)
    const [showAgreementModal, setShowAgreementModal] = useState(false)
    const [showContractImageModal, setShowContractImageModal] = useState(false)
    const [contractImageSources, setContractImageSources] = useState([])
    const [contractHTMLSources, setContractHTMLSources] = useState([])

    const [showNoticeModel, setShowNoticeModal] = useState(false)
    const [noticeBoard, setNoticeBoard] = useState(new Board())
    const [agreementItemID, setAgreementItemID] = useState(-1)
    const [agreementItemMarketerID, setAgreementItemMarketerID] = useState(-1)

    // 브랜드 일 시 상품 등록 가능 여부 관련 state
    const itemAddValidateOpenRef1 = useRef(null)
    const itemAddValidateOpenRef2 = useRef(null)
    const [itemAddValidateIsOpen, itemAddValidateRef, itemAddValidateHandler] = useDropdownDetectClose(false, [
        itemAddValidateOpenRef1,
        itemAddValidateOpenRef2,
    ])
    const [isActiveBrand, setActiveBrand] = useState(false)
    const [hasSignBrand, setHasSignBrand] = useState(false)

    // 스태프 일 시 승인 요청의 개수를 표시한다
    const [editeds, setEditeds] = useState(0)
    const [pendings, setPendings] = useState(0)

    useEffect(() => {
        if (user.id >= 0) {
            if (user.userType === UserTypes.BRAND) {
                actions.updateUserDetail(user)
            }
        }
    }, [user])

    useEffect(() => {
        if (user.id >= 0 && user.userType === UserTypes.BRAND && userDetail !== null) {
            setActiveBrand(userDetail.brandStatusType === EditStateTypes.DONE)
            getUserHasSign().then((response) => {
                setHasSignBrand(response)
            })
        }
    }, [userDetail])

    useEffect(() => {
        if (!showWriteModel) {
            updateBrandItemList(
                pageIndex,
                ItemOrderValues[itemOrder],
                itemSortType,
                itemMarketerStatusFilter,
                itemEditStatusFilter,
            )
        }
    }, [showWriteModel])

    const updateBrandItemList = async (
        page,
        ordering,
        sortType,
        marketerStatusFilter,
        editStatusFilter,
        searchValue = null,
    ) => {
        let search = ""
        if (searchValue !== null) {
            // 검색을 한 경우
            setSearchKeyword(searchValue)
            search = searchValue
        } else {
            // 리스트 업데이트 시 입력중인 키워드를 검색에 사용할 키워드로 돌려놓는다.
            setSearchValue(searchKeyword)
            search = searchKeyword
        }
        // setItems([])

        ordering = Utils.getOrdering(ordering, sortType)
        getBrandItemListAuth(
            page,
            pageSize,
            ordering,
            search,
            ItemSearchTypes.TITLE_ONLY,
            marketerStatusFilter,
            editStatusFilter,
        )
            .then((response) => {
                const brandItemList = new BrandItemList()
                brandItemList.convertByResponse(response)

                setItemCount(brandItemList.count)
                setItems(brandItemList.items)
            })
            .catch((error) => {
                setItems([])
            })

        // 스태프인 경우 승인 요청의 아이템 개수도 받는다.
        if (user.userType === UserTypes.STAFF) {
            const countData = await getBrandItemListAuthCount()

            setEditeds(countData["editeds"])
            setPendings(countData["pendings"])
        }
    }

    const itemUpdate = (item) => {
        setSelectedItem(item)
        setShowWriteModal(true)
    }

    const itemCreate = () => {
        if (!isActiveBrand || !hasSignBrand) {
            itemAddValidateHandler(true)
        } else {
            setSelectedItem(new BrandItem())
            setShowWriteModal(true)
        }
    }

    const handleSearch = () => {
        updateBrandItemList(
            1,
            ItemOrderValues[itemOrder],
            itemSortType,
            itemMarketerStatusFilter,
            itemEditStatusFilter,
            searchValue,
        )
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleOpenNoticeModal = (notice) => {
        setNoticeBoard(notice.board)
        setShowNoticeModal(true)

        getItemNotice(notice.id).then((response) => {
            if (response.id >= 0) {
                setNoticeBoard(response.board)
            }
        })
    }

    const handlePageIndex = (page) => {
        setPageIndex(page)
        updateBrandItemList(
            page,
            ItemOrderValues[itemOrder],
            itemSortType,
            itemMarketerStatusFilter,
            itemEditStatusFilter,
        )
        window.scrollTo({ top: 0 })
    }

    const handleItemOrder = (order) => {
        setPageIndex(1)
        setItemOrder(order)
        updateBrandItemList(1, ItemOrderValues[order], itemSortType, itemMarketerStatusFilter, itemEditStatusFilter)
    }

    const handleItemMarketerStatusFilter = (filter) => {
        setPageIndex(1)
        setItemMarketerStatusFilter(filter)
        updateBrandItemList(1, ItemOrderValues[itemOrder], itemSortType, filter, itemEditStatusFilter)
    }

    const handleItemEditStatusFilter = (filter) => {
        setPageIndex(1)
        setItemEditStatusFilter(filter)
        updateBrandItemList(1, ItemOrderValues[itemOrder], itemSortType, itemMarketerStatusFilter, filter)
    }

    const handleSort = (sortType) => {
        setItemSortType(sortType)
        updateBrandItemList(1, ItemOrderValues[itemOrder], sortType, itemMarketerStatusFilter, itemEditStatusFilter)
    }

    const handleShowItemContract = (item) => {
        if (userType === UserTypes.BRAND || userType === UserTypes.STAFF) {
            // 관리자의 경우 계약서 양식을 조회한다.
            // setSelectedItem(item)
            // setShowContractModal(true)
            getItemContractHtml(item.id)
                .then((response) => {
                    if (response.length > 0) {
                        setContractHTMLSources([response])
                        setShowContractImageModal(true)
                    } else {
                        alert("계약서를 찾을 수 없습니다.")
                    }
                })
                .catch((error) => {
                    alert("계약서를 찾을 수 없습니다.")
                })
        } else if (userType === UserTypes.MARKETER) {
            // 마케터의 경우 자기가 계약한 계약서를 조회한다.
            getItemContractMarketerImages(item.id, user.id)
                .then((response) => {
                    if (response.length > 0) {
                        setContractImageSources(response)
                        setShowContractImageModal(true)
                    } else {
                        alert("계약서를 찾을 수 없습니다.")
                    }
                })
                .catch((error) => {
                    alert("계약서를 찾을 수 없습니다.")
                })
        }
    }

    const handleShowContractImageModal = (isShow, contractImageSources = []) => {
        if (contractImageSources.length > 0) {
            setContractImageSources(contractImageSources)
            setShowContractImageModal(true)
        } else {
            alert("계약서를 찾을 수 없습니다.")
            setContractImageSources([])
            setShowContractImageModal(false)
        }
    }

    const handleDeleteItemMarketer = (item) => {
        if (item.id >= 0 && user.id >= 0) {
            const error = () => {
                alert("취소 실패했습니다.")
            }

            deleteItemMarketer(item.id, user.id)
                .then((response) => {
                    if (response) {
                        updateBrandItemList(
                            pageIndex,
                            ItemOrderValues[itemOrder],
                            itemSortType,
                            itemMarketerStatusFilter,
                            itemEditStatusFilter,
                        )
                        alert(`상품 ${item.detail.title}의 지원 신청이 취소되었습니다.`)
                    } else {
                        error()
                    }
                })
                .catch((error) => {
                    error()
                })
        }
    }

    const handleShowItemDetailMobile = (item) => {
        setShowDetailMobileModal(true)
        setSelectedItem(item)
    }

    const handleOpenAgreementList = (itemID, itemMarketerID) => {
        setAgreementItemID(itemID)
        setAgreementItemMarketerID(itemMarketerID)
    }

    return (
        <ItemLayout>
            <ItemInnerTop>
                <div className="text-2xl max-lg:px-1">영업아이템 현황</div>
                <div className="flex max-lg:flex-col lg:flex-row justify-between items-center w-full max-lg:px-1 py-2 mt-4">
                    <div className="flex max-lg:w-full max-lg:flex-col lg:flex-row lg:space-x-2">
                        <div className="flex items-center space-x-2">
                            <ItemSearchBox>
                                <PerformTableSearch
                                    type="text"
                                    placeholder="영업아이템"
                                    onKeyPress={handleSearchKeyPress}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                    }}
                                />
                                <PerformTableSearchButton
                                    onClick={() => {
                                        handleSearch()
                                    }}
                                >
                                    <PerformTableSearchIcon />
                                </PerformTableSearchButton>
                            </ItemSearchBox>

                            <ItemSortButton>
                                <SortSwitch sortType={itemSortType} setSortType={handleSort} />
                            </ItemSortButton>
                        </div>
                        <div className="flex max-lg:mt-2 max-lg:w-full">
                            {user.userType === UserTypes.MARKETER && (
                                <>
                                    {/* 마케터 상태 필터 */}
                                    <MarketerTableStatusSelectBox>
                                        <MarketerTableSortSelect
                                            value={itemMarketerStatusFilter}
                                            onChange={(e) => {
                                                handleItemMarketerStatusFilter(e.target.value)
                                            }}
                                        >
                                            <option value={ItemFilterValuesByMarketer.ALL}>
                                                {ItemFilterNamesByMarketer[ItemFilterValuesByMarketer.ALL]}
                                            </option>
                                            <option value={ItemFilterValuesByMarketer.WAIT}>
                                                {ItemFilterNamesByMarketer[ItemFilterValuesByMarketer.WAIT]}
                                            </option>
                                            <option value={ItemFilterValuesByMarketer.CONFIRM}>
                                                {ItemFilterNamesByMarketer[ItemFilterValuesByMarketer.CONFIRM]}
                                            </option>
                                            <option value={ItemFilterValuesByMarketer.SUSPEND_WAIT}>
                                                {ItemFilterNamesByMarketer[ItemFilterValuesByMarketer.SUSPEND_WAIT]}
                                            </option>
                                            <option value={ItemFilterValuesByMarketer.SUSPEND_CONFIRM}>
                                                {ItemFilterNamesByMarketer[ItemFilterValuesByMarketer.SUSPEND_CONFIRM]}
                                            </option>
                                        </MarketerTableSortSelect>
                                    </MarketerTableStatusSelectBox>
                                    {/* 아이템 정렬 순서 */}
                                    <MarketerTableStatusSelectBox>
                                        <MarketerTableSortSelect
                                            value={itemOrder}
                                            onChange={(e) => {
                                                handleItemOrder(e.target.value)
                                            }}
                                        >
                                            <option value={ItemOrders.CREATED}>
                                                {user.userType === UserTypes.MARKETER
                                                    ? "상품 등록 순"
                                                    : ItemOrderNames[ItemOrders.CREATED]}
                                            </option>
                                            {/* <option value={ItemOrders.FAV}>
                                                {user.userType === UserTypes.MARKETER ? 
                                                    "상품 인기 순" : 
                                                    ItemOrderNames[ItemOrders.FAV]
                                                }
                                            </option> */}
                                            {user.userType === UserTypes.MARKETER && (
                                                <option value={ItemOrders.ITEM_REGISTED}>지원 승인 순</option>
                                            )}
                                        </MarketerTableSortSelect>
                                    </MarketerTableStatusSelectBox>
                                </>
                            )}
                            <div
                                ref={itemAddValidateOpenRef1}
                                className="max-lg:flex lg:hidden max-lg:h-8 lg:h-9 ml-auto"
                            >
                                {AccessRules.brandItemManagement() && (
                                    // <MarketerToggleButton marketerstate={0} onClick={()=>{itemCreate()}}>상품 등록</MarketerToggleButton>
                                    <ItemAdd
                                        ref={itemAddValidateRef}
                                        isActiveBrand={isActiveBrand}
                                        hasSignBrand={hasSignBrand}
                                        itemAddValidateIsOpen={itemAddValidateIsOpen}
                                        itemCreate={itemCreate}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        ref={itemAddValidateOpenRef2}
                        className="max-lg:hidden lg:flex max-lg:justify-between max-lg:w-full h-full max-lg:mt-2"
                    >
                        {/* <BrandItemPagination
                            pageIndex={pageIndex}
                            handlePageIndex={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                        /> */}
                        {AccessRules.brandItemManagement() && (
                            // <MarketerToggleButton marketerstate={0} onClick={()=>{itemCreate()}}>상품 등록</MarketerToggleButton>
                            <ItemAdd
                                ref={itemAddValidateRef}
                                isActiveBrand={isActiveBrand}
                                hasSignBrand={hasSignBrand}
                                itemAddValidateIsOpen={itemAddValidateIsOpen}
                                itemCreate={itemCreate}
                            />
                        )}
                    </div>
                </div>
                {user.userType !== UserTypes.MARKETER && (
                    <div className="flex items-end w-full h-11 p-0.5 space-x-2 max-sm:overflow-x-auto scroll-transparent">
                        {/* 아이템 상태 필터 */}
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.NONE)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.NONE}
                        >
                            전체
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.WAIT)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.WAIT}
                        >
                            광고 중
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.DISABLED)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.DISABLED}
                        >
                            광고 일시정지
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.EDITED)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.EDITED}
                        >
                            수정 승인 요청
                            {editeds > 0 && <ItemListStatusFilterCount>{editeds}</ItemListStatusFilterCount>}
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.PENDING)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.PENDING}
                        >
                            등록 승인 요청
                            {pendings > 0 && <ItemListStatusFilterCount>{pendings}</ItemListStatusFilterCount>}
                        </ItemListStatusFilterButton>
                        <ItemListStatusFilterButton
                            onClick={() => {
                                handleItemEditStatusFilter(EditStateTypes.FAILED)
                            }}
                            isSelected={itemEditStatusFilter === EditStateTypes.FAILED}
                        >
                            차단
                        </ItemListStatusFilterButton>
                    </div>
                )}
                {/* <Link to={'create'}>
                </Link> */}
            </ItemInnerTop>
            <ItemList>
                {items.map((item, i) => (
                    <ItemListCard
                        key={i}
                        user={user}
                        item={item}
                        itemUpdate={itemUpdate}
                        showItemContract={handleShowItemContract}
                        showItemDetailMobile={handleShowItemDetailMobile}
                        deleteItemMarketer={handleDeleteItemMarketer}
                        setShowAgreementModal={setShowAgreementModal}
                        openNoticeModal={handleOpenNoticeModal}
                        openAgreementList={handleOpenAgreementList}
                    />
                ))}
            </ItemList>

            {/* 모바일용 페이지네이션 */}
            <div className="flex justify-center w-full my-8">
                <BrandItemPagination
                    pageIndex={pageIndex}
                    handlePageIndex={handlePageIndex}
                    pageSize={pageSize}
                    itemCount={itemCount}
                />
            </div>
            {/* <ItemInnerBottom>
                <Pagination/>
            </ItemInnerBottom> */}
            <ModalContainer
                show={showWriteModel}
                setShow={setShowWriteModal}
                isBlur={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.SM}
            >
                <MyPageItemWrite item={selectedItem} setShowModal={setShowWriteModal} />
            </ModalContainer>

            <ModalContainer
                show={showContractModal}
                setShow={setShowContractModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.LG}
            >
                <MyPageItemContract
                    item={selectedItem}
                    showContractImageModal={handleShowContractImageModal}
                    close={() => {
                        setShowContractModal(false)
                    }}
                />
            </ModalContainer>
            <ModalContainer
                show={showContractImageModal && (contractImageSources.length > 0 || contractHTMLSources.length > 0)}
                setShow={setShowContractImageModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.LG}
            >
                <ContractImageViewer imageSources={contractImageSources} htmlSources={contractHTMLSources} />
            </ModalContainer>

            <ModalContainer
                show={showNoticeModel}
                setShow={setShowNoticeModal}
                isBlur={true}
                fadeUp={true}
                closeByBackground={true}
            >
                <div className="flex max-lg:w-screen max-lg:h-[75vh] lg:w-[64rem] rounded-sm overflow-hidden">
                    <BoardDetail board={noticeBoard} readOnly isModal />
                </div>
            </ModalContainer>

            <ModalContainerPortal show={showAgreementModal} setShow={setShowAgreementModal} closeByBackground={false}>
                <MyPageOpenAgreementList itemID={agreementItemID} itemMarketerID={agreementItemMarketerID} />
            </ModalContainerPortal>

            <MobileModal
                isOpen={showDetailMobileModal}
                setIsOpen={setShowDetailMobileModal}
                canBigger={false}
                defaultHeight={100}
                needScroll={true}
            >
                <MyPageDetailMobile
                    user={user}
                    navigate={navigate}
                    item={selectedItem}
                    itemUpdate={itemUpdate}
                    showItemContract={handleShowItemContract}
                    deleteItemMarketer={handleDeleteItemMarketer}
                    openNoticeModal={handleOpenNoticeModal}
                    setShowAgreementModal={setShowAgreementModal}
                    openAgreementList={handleOpenAgreementList}
                />
            </MobileModal>
        </ItemLayout>
    )
}

const BrandItemPagination = ({ pageIndex, handlePageIndex, pageSize, itemCount }) => {
    return (
        <Pagination
            page={pageIndex}
            setPage={handlePageIndex}
            pageSize={pageSize}
            itemCount={itemCount}
            maxPageButtons={MYPAGE_PAGE_LENGTH}
            styleType={PaginationStyles.TextButtons}
        />
    )
}

// eslint-disable-next-line react/display-name
const ItemAdd = React.forwardRef(({ isActiveBrand, hasSignBrand, itemAddValidateIsOpen, itemCreate }, ref) => {
    return (
        <div className="relative flex h-full ml-auto" ref={ref}>
            <MarketerToggleButton
                className="max-lg:w-28 lg:w-32 max-lg:text-sm"
                marketerstate={0}
                onClick={() => {
                    itemCreate()
                }}
            >
                상품 등록
            </MarketerToggleButton>
            <BrandValidate
                isActiveBrand={isActiveBrand}
                hasSignBrand={hasSignBrand}
                isShow={itemAddValidateIsOpen}
                top={"2.5rem"}
            />
        </div>
    )
})
