import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { MarketerTableDataList } from "../../../constants/ConstMarketer"
import { Link, useParams } from "react-router-dom"
import { ModalContainer } from "../../../components/ModalContainer"
import { GroupMarketerState } from "../../../types/DataEnum"
import { ContentContainer } from "../MyPageMainStyle"
import { Pagination } from "../../../components/Pagination"
import {
    PerformListControlBox,
    PerformListControlSection,
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSearchButton,
    PerformTableSearchIcon,
} from "../perform/MyPagePerformStyle"
import { PaginationStyles } from "../../../types/StyleTypes"
import { MarketerSubControlBox, MarketerToggleButton } from "../marketer/MyPageMarketerStyle"
import {
    GroupAddButton,
    GroupHeaderBox,
    GroupHeaderControlBox,
    GroupHeaderTitle,
    MarketerGroupCon,
    MarketerGroupTableCon,
    PlusIcon,
} from "./MyPageGroupStyle"
import MyPageGroupGrid from "./MyPageGroupGrid"
import {
    addItemMarketerGroup,
    deleteItemMarketerInvite,
    getItemMarketerGroups,
    getItemMarketerInviteds,
} from "../../../apis/api/boardItems"
import MyPageGroupMarketerList from "./MyPageGroupMarketerList"
import BrandItemMarketerGroup from "../../../types/brandItems/marketers/BrandItemMarketerGroup"
import { UserTypes } from "../../../types/users/UserTypes"
import { useUserStore } from "../../../zustand/store"
import MyPageGroupList from "./MyPageGroupList"
import { WriteTypes } from "../../../types/EditorTypes"
import AccessRules from "../../../rules/AccessRules"
import MyPageGroupDeclinedList from "./MyPageGroupDeclinedList"
import MyPageGroupBrand from "./MyPageGroupBrand"
import MyPageGroupMarketer from "./MyPageGroupMarketer"

const MAX_PAGE_LENGTH = 5

export default function MyPageGroup({ setOfficeNavState }) {
    const { user } = useUserStore()

    const { itemID } = useParams()
    const [sortvisible, setSortvisible] = useState(0)

    const [groupShow, setGroupShow] = useState(false)
    const [groupMarketerListShow, setGroupMarketerListShow] = useState(false)
    const [groupMarketerListType, setGroupMarketerListType] = useState(WriteTypes.READ) // Read일 경우 마케터 리스트, Write일 경우 마케터 초대 리스트

    const [marketerHasGroup, setMarketerHasGroup] = useState(true) // 마케터가 그룹에 속해있는지 여부 (혹시 찰나에 그룹 추가나 다른 이벤트를 방지하기 위해 true를 기본값으로 준다.)
    const [selectedGroup, setSelectedGroup] = useState(new BrandItemMarketerGroup())
    const [search, setSearch] = useState("")

    const [reloadGroup, setReloadGroup] = useState(false)

    useEffect(() => {
        if (user.userType === UserTypes.MARKETER) {
            setOfficeNavState(PmsOfficeNavState.GROUP)
        } else {
            setOfficeNavState(PmsOfficeNavState.MARKETER)
        }

        return () => setOfficeNavState(PmsOfficeNavState.NONE)
    }, [])

    useEffect(() => {
        setReloadGroup(true)
    }, [itemID])

    const handleSearchMarketer = () => {
        setReloadGroup(true)
    }

    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }

    const handleOpenMarketerList = (group) => {
        setSelectedGroup(group)
        setGroupMarketerListType(WriteTypes.READ)
        setGroupMarketerListShow(true)
    }

    return (
        <ContentContainer full_height>
            {AccessRules.brandItemManagement(user) && <MyPageGroupBrand />}
            {AccessRules.performCreate(user) && <MyPageGroupMarketer />}
            <ModalContainer
                show={groupMarketerListShow}
                setShow={setGroupMarketerListShow}
                isBlur={true}
                fadeDown={true}
                closeByBackground
            >
                <MyPageGroupMarketerList
                    itemID={itemID}
                    marketerGroup={selectedGroup}
                    groupMarketerListType={groupMarketerListType}
                />
            </ModalContainer>
        </ContentContainer>
    )
}
