import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import tw from "tailwind-styled-components"
import {
    addItemMarketerInvite,
    addItemMarketerManualInvite,
    deleteItemMarketerInvite,
    getItemMarketerGroupMarketers,
    getItemMarketerInviteHistories,
    getItemMarketerInvites,
    getItemMarketerManualInviteds,
    getItemMarketers,
    setItemMarketerGroupAppoint,
    updateItemMarketerInvite,
} from "../../../apis/api/boardItems"
import AccessRules from "../../../rules/AccessRules"
import { MarketerStatusTypes } from "../../../types/brandItems/BrandItemTypes"
import { EditStateTypes, WriteTypes } from "../../../types/EditorTypes"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import {
    PerformTableSearch,
    PerformTableSearchButton,
    PerformTableSearchIcon,
    PerformValueInputTitle,
} from "../perform/MyPagePerformStyle"
import { HoverUserCard } from "../../../components/HoverComponants"
import {
    GroupInviteButton,
    GroupInviteColumnBox,
    GroupInviteContainer,
    GroupInviteMarketerList,
    GroupInviteMarketerRow,
    GroupInviteSearchBox,
} from "./MyPageGroupStyle"
import { userDetailModalStore } from "../../../zustand/modalstore"
import { ModalContainer } from "../../../components/ModalContainer"
import { MyPageUserInput } from "../../../components/user/Inputs"

const PAGE_SIZE = 20

const CellWidth = {
    Name: "max-sm:min-w-[4rem] sm:min-w-[8rem]",
    Email: "max-sm:min-w-[6rem] sm:min-w-[8rem] w-full",
    Tel: "max-sm:min-w-[6rem] sm:min-w-[8rem]",
    Invite: "max-sm:min-w-[4rem] sm:min-w-[5rem]",
}

const CellMinWidth = "max-sm:min-w-[19rem] sm:min-w-[33rem]"

// eslint-disable-next-line react/display-name
const MarketerRow = React.forwardRef(({ marketer, parentItemMarketer, itemID, getCurrentMarketers }, ref) => {
    const [invited, setInvited] = useState(false)

    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    const [inviteMemoEditStatusType, setInviteMemoEditStatusType] = useState(EditStateTypes.WAIT)
    const [inviteMemo, setInviteMemo] = useState("")

    useEffect(() => {
        setInvited(marketer.invited)
    }, [marketer])

    const handleOnClick = () => {
        setUserShow(true)
        setUserId(marketer.user.id)
    }

    const handleInviteEdit = () => {
        setInviteMemoEditStatusType(EditStateTypes.EDITED)
        setInviteMemo("")
    }

    const handleInvite = () => {
        addItemMarketerInvite(itemID, parentItemMarketer.marketer.user.id, marketer.user.id, inviteMemo)
            .then((response) => {
                if (response) {
                    setInvited(true)
                    setInviteMemoEditStatusType(EditStateTypes.WAIT)
                    if (getCurrentMarketers) {
                        getCurrentMarketers()
                    }
                } else {
                    alert("초대 실패했습니다.")
                }
            })
            .catch((error) => {
                alert("초대 실패했습니다.")
            })
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleInvite()
        }
    }

    const handleInviteDelete = () => {
        // deleteItemMarketerInvite(invite.id)
        //     .then((response) => {
        //         if (response) {
        //             setInvited(true)
        //             setInviteMemoEditStatusType(EditStateTypes.WAIT)
        //             if (getCurrentMarketers) {
        //                 getCurrentMarketers()
        //             }
        //         } else {
        //             alert("삭제 실패했습니다.")
        //         }
        //     })
        //     .catch((error) => {
        //         alert("삭제 실패했습니다.")
        //     })
    }

    return (
        <GroupInviteMarketerRow ref={ref} className={CellMinWidth}>
            {inviteMemoEditStatusType !== EditStateTypes.EDITED ? (
                <>
                    <div className={`${CellWidth.Name}`}>
                        <span>
                            <HoverUserCard
                                userID={marketer.user.id}
                                onClick={() => {
                                    handleOnClick()
                                }}
                            >
                                <span className="z-0">{marketer.user.name}</span>
                            </HoverUserCard>
                        </span>
                    </div>
                    <div className={CellWidth.Email}>
                        <span>{marketer.user.email}</span>
                    </div>
                    <div className={CellWidth.Tel}>
                        <span>{marketer.user.tel}</span>
                    </div>
                    <div className={`${CellWidth.Invite}`}>
                        <GroupInviteButton
                            invited={invited}
                            onClick={() => {
                                handleInviteEdit()
                            }}
                        >
                            {invited ? "초대 완료" : "초대"}
                        </GroupInviteButton>
                    </div>
                </>
            ) : (
                <div className="flex w-full space-x-2">
                    <input
                        type="text"
                        className="w-full px-2 py-1 rounded-lg border border-slate-300 bg-slate-50"
                        placeholder="브랜드에게 전달할 사항을 남겨주세요. (선택)"
                        value={inviteMemo}
                        onChange={(e) => {
                            setInviteMemo(e.target.value)
                        }}
                        onKeyPress={handleSearchKeyPress}
                    />
                    {/* <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-red-200 text-red-700"
                        onClick={() => {
                            handleInviteDelete()
                        }}
                    >
                        삭제
                    </button> */}
                    <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-nightblue-500 text-white"
                        onClick={() => {
                            handleInvite()
                        }}
                    >
                        확인
                    </button>
                    <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-slate-500 text-white"
                        onClick={() => {
                            setInviteMemoEditStatusType(EditStateTypes.WAIT)
                        }}
                    >
                        취소
                    </button>
                </div>
            )}
        </GroupInviteMarketerRow>
    )
})

// eslint-disable-next-line react/display-name
const ManualMarketerRow = React.forwardRef(({ invite, parentItemMarketer, itemID, getCurrentMarketers }, ref) => {
    const [invited, setInvited] = useState(true)

    const [inviteMemoEditStatusType, setInviteMemoEditStatusType] = useState(EditStateTypes.WAIT)
    const [inviteMemo, setInviteMemo] = useState("")

    const handleInviteEdit = () => {
        setInviteMemoEditStatusType(EditStateTypes.EDITED)
        // setInviteMemo(invite.memo) // 그냥 MarketerRow에서는 memo를 불러올 수 없으므로 여기서도 불러오지않는다.
        setInviteMemo("")
    }

    const handleInvite = () => {
        updateItemMarketerInvite(invite.id, inviteMemo)
            .then((response) => {
                if (response) {
                    setInvited(true)
                    setInviteMemoEditStatusType(EditStateTypes.WAIT)
                    if (getCurrentMarketers) {
                        getCurrentMarketers()
                    }
                    invite.memo = inviteMemo
                } else {
                    alert("수정 실패했습니다.")
                }
            })
            .catch((error) => {
                alert("수정 실패했습니다.")
            })
    }

    const handleInviteDelete = () => {
        deleteItemMarketerInvite(invite.id)
            .then((response) => {
                if (response) {
                    alert("삭제되었습니다.")
                    setInvited(true)
                    setInviteMemoEditStatusType(EditStateTypes.WAIT)
                    if (getCurrentMarketers) {
                        getCurrentMarketers()
                    }
                } else {
                    alert("삭제 실패했습니다.")
                }
            })
            .catch((error) => {
                alert("삭제 실패했습니다.")
            })
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleInvite()
        }
    }

    return (
        <GroupInviteMarketerRow ref={ref} className={CellMinWidth}>
            {inviteMemoEditStatusType !== EditStateTypes.EDITED ? (
                <>
                    <div className={`${CellWidth.Name}`}>
                        <span>
                            <span className="z-0">{invite.manualName}</span>
                        </span>
                    </div>
                    <div className={CellWidth.Email}>
                        <span>-</span>
                    </div>
                    <div className={CellWidth.Tel}>
                        <span>{invite.manualTel}</span>
                    </div>
                    <div className={`${CellWidth.Invite}`}>
                        <GroupInviteButton
                            invited={invited}
                            onClick={() => {
                                handleInviteEdit()
                            }}
                        >
                            {invited ? "초대 완료" : "초대"}
                        </GroupInviteButton>
                    </div>
                </>
            ) : (
                <div className="flex w-full space-x-2">
                    <input
                        type="text"
                        className="w-full px-2 py-1 rounded-lg border border-slate-300 bg-slate-50"
                        placeholder="브랜드에게 전달할 사항을 남겨주세요. (선택)"
                        value={inviteMemo}
                        onChange={(e) => {
                            setInviteMemo(e.target.value)
                        }}
                        onKeyPress={handleSearchKeyPress}
                    />
                    <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-red-200 text-red-700"
                        onClick={() => {
                            handleInviteDelete()
                        }}
                    >
                        삭제
                    </button>
                    <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-nightblue-500 text-white"
                        onClick={() => {
                            handleInvite()
                        }}
                    >
                        확인
                    </button>
                    <button
                        className="flex-shrink-0 h-8 px-4 rounded-lg bg-slate-500 text-white"
                        onClick={() => {
                            setInviteMemoEditStatusType(EditStateTypes.WAIT)
                        }}
                    >
                        취소
                    </button>
                </div>
            )}
        </GroupInviteMarketerRow>
    )
})

const Columns = () => {
    return (
        <GroupInviteColumnBox className={`${CellMinWidth} z-10`}>
            <span className={CellWidth.Name}>이름</span>
            <span className={CellWidth.Email}>이메일</span>
            <span className={CellWidth.Tel}>전화번호</span>
            <span className={CellWidth.Invite}>추가</span>
        </GroupInviteColumnBox>
    )
}

export default function MyPageGroupMarketerInvite({ itemID, parentItemMarketer, getCurrentMarketers }) {
    const [marketers, setMarketers] = useState([])
    const [search, setSearch] = useState("")

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()

    const [manualMarketers, setManualMarketers] = useState([])
    const [manualInviteShow, setManualInviteShow] = useState(false)
    const [manualInviteName, setManualInviteName] = useState("")
    const [manualInviteTel, setManualInviteTel] = useState("")
    const [manualInviteMemo, setManualInviteMemo] = useState("")

    useEffect(() => {
        getMarketers()
    }, [parentItemMarketer, pageIndex])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && marketers.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            setPageIndex(pageIndex + 1)
        }
    }, [scrollInView])

    const getMarketers = async () => {
        let response = undefined

        // 검색을 안할 경우 데이터를 불러오지 않는다.
        if (Utils.isStringNullOrEmpty(search)) {
            setMarketers([])
            setManualMarketers([])
            setPageIndex(1)
        } else if (search.length === 1) {
            alert("2글자 이상 입력해주세요.")
            setMarketers([])
            setPageIndex(1)
        } else {
            response = await getItemMarketerInvites(
                itemID,
                parentItemMarketer.id,
                pageIndex,
                PAGE_SIZE,
                search,
                parentItemMarketer.marketer.user.id,
            )
        }

        if (response !== undefined) {
            if (response.count > 0) {
                if (pageIndex === 1) {
                    setMaxPage(Math.ceil(response.count / PAGE_SIZE))
                    setMarketers(response.items)
                } else {
                    setMarketers([...marketers, ...response.items])
                }
            } else {
                setMarketers([])
                setPageIndex(1)
                setMaxPage(1)
            }

            setManualMarketers([])
        }
    }

    const getMarketerInviteds = async () => {
        const response = await getItemMarketerInviteHistories(
            itemID,
            parentItemMarketer.id,
            pageIndex,
            PAGE_SIZE,
            search,
            parentItemMarketer.marketer.user.id,
        )

        const manualResponse = await getItemMarketerManualInviteds(itemID, parentItemMarketer.marketer.user.id)
        setManualMarketers(manualResponse.items)

        setMaxPage(Math.ceil(response.count / PAGE_SIZE))
        setMarketers(response.items)
        setPageIndex(1)
    }

    const handleSearchMarketer = () => {
        if (pageIndex > 1) {
            setPageIndex(1)
        } else {
            getMarketers()
        }
    }

    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }

    const handleManualInvite = async () => {
        const result = await addItemMarketerManualInvite(
            itemID,
            parentItemMarketer.marketer.user.id,
            manualInviteName,
            manualInviteTel,
            manualInviteMemo,
        )

        if (result) {
            alert("초대되었습니다.")
            setManualInviteShow(false)
        } else {
            alert("초대 실패했습니다.")
        }
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex justify-between items-center w-full space-x-2">
                <div className="flex space-x-2">
                    <GroupInviteSearchBox>
                        <PerformTableSearch
                            type="text"
                            placeholder="마케터"
                            onKeyPress={handleSearchMarketerEnter}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <PerformTableSearchButton
                            onClick={() => {
                                handleSearchMarketer()
                            }}
                        >
                            <PerformTableSearchIcon />
                        </PerformTableSearchButton>
                    </GroupInviteSearchBox>
                    <button
                        className="h-7 px-2 mb-2 rounded-md bg-slate-200 text-slate-700 text-sm"
                        onClick={() => {
                            getMarketerInviteds()
                        }}
                    >
                        초대 내역
                    </button>
                </div>
                <button
                    className="h-7 px-2 mb-2 ml-auto rounded-md bg-nightblue-200 text-nightblue-800 text-sm"
                    onClick={() => {
                        setManualInviteShow(true)
                        setManualInviteName("")
                        setManualInviteTel("")
                    }}
                >
                    비회원 초대
                </button>
            </div>
            <div className="w-full h-full relative">
                <div className="absolute w-full h-full scroll-transparent scroll-overlay">
                    <Columns />
                    <GroupInviteMarketerList>
                        {marketers.map((marketer, idx) => (
                            <MarketerRow
                                marketer={marketer}
                                parentItemMarketer={parentItemMarketer}
                                itemID={itemID}
                                getCurrentMarketers={getCurrentMarketers}
                                key={idx}
                                ref={idx !== 0 ? scrollRef : null}
                            />
                        ))}
                        {manualMarketers.map((invite, idx) => (
                            <ManualMarketerRow
                                invite={invite}
                                parentItemMarketer={parentItemMarketer}
                                itemID={itemID}
                                getCurrentMarketers={getCurrentMarketers}
                                key={idx}
                                ref={idx !== 0 ? scrollRef : null}
                            />
                        ))}
                    </GroupInviteMarketerList>
                </div>
            </div>

            {manualInviteShow && (
                <div className="absolute absolute-center z-50 flex flex-col max-sm:w-72 sm:w-128 p-4 bg-slate-100 drop-shadow-md rounded-lg">
                    <span className="text-lg font-medium">비회원 초대</span>
                    <span className="text-slate-700 text-sm mb-3">
                        초대된 마케터가 세일즈랑에 가입 시 초대를 받을 수 있습니다.
                    </span>
                    <MyPageUserInput
                        label={"이름"}
                        type={"text"}
                        data={manualInviteName}
                        setData={setManualInviteName}
                        placeholder={""}
                    />
                    <MyPageUserInput
                        label={"전화번호"}
                        type={"text"}
                        data={manualInviteTel}
                        setData={(value) => {
                            setManualInviteTel(value.replace("-", ""))
                        }}
                        placeholder={"- 없이 입력해주세요."}
                    />
                    <MyPageUserInput
                        label={"메모"}
                        type={"text"}
                        data={manualInviteMemo}
                        setData={setManualInviteMemo}
                        placeholder={"브랜드에게 전달할 사항을 남겨주세요. (선택)"}
                    />
                    <div className="grid grid-cols-2 gap-2 items-center w-full h-8 mt-1 [&>button]:h-full">
                        <button
                            className="text-slate-500 hover:text-slate-800"
                            onClick={() => {
                                setManualInviteShow(false)
                            }}
                        >
                            취소
                        </button>
                        <button
                            className="rounded-lg bg-nightblue-500 text-white"
                            onClick={() => {
                                handleManualInvite(false)
                            }}
                        >
                            초대
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
