import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"

import { PerformNavState } from "../../../types/DataEnum"
// import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd"
import { TableHeaderList, TestDataList } from "../../../constants/ConstTableData"
import {
    PerformConInner,
    PerformValueLayout,
    PerformTableHeader,
    PerformTableHeaderBox,
    PerformTableRowLayout,
    PerformValueBox,
    PerformFileIcon,
    PerformPreviewButton,
    PerformStatus,
    PerformTextDetailIcon,
    PerformTextDetailButton,
    PerformTableHeaderFiltered,
    PerformTableColumnCellLayout,
    PerformTableValueCellLayout,
} from "./MyPagePerformStyle"
import {
    PerformColumnPreviewTypes,
    PerformColumnFormTypes,
    PerformStaticColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import PerformValueCombo from "../../../types/brandItems/performValues/PerformValueCombo"
import PerformValueFile from "../../../types/brandItems/performValues/PerformValueFile"
import PerformValueText from "../../../types/brandItems/performValues/PerformValueText"
import PerformValueStatus from "../../../types/brandItems/performValues/PerformValueStatus"
import PerformValueCheckBox from "../../../types/brandItems/performValues/PerformValueCheckBox"
import { PerformValueComboCell } from "./PerformValueComboCell"
import { PerformValueFileCell } from "./PerformValueFileCell"
import { PerformValueTextCell } from "./PerformValueTextCell"
import { PerformValueStatusCell } from "./PerformValueStatusCell"
import { PerformValueCheckBoxCell } from "./PerformValueCheckBoxCell"
import { getPerformEmptyValue } from "../../../utils/brandItem/BrandItemPerformUtils"
import { PerformValueFixedDateCell } from "./PerformValueFixedDateCell"
import { PerformValueOptionCell } from "./PerformValueOptionCell"
import { PerformValueMarketerCell } from "./PerformValueMarketerCell"
import { PerformValueUpdateCell } from "./PerformValueUpdateCell"
import { PerformValueDateCell } from "./PerformValueDateCell"

const PerformValue = ({
    user,
    value,
    perform,
    setEditComponent,
    showUpdateComponent,
    showViewerComponent,
    performCheck,
    openCalcListModal,
    idx,
}) => {
    const [isFrozen, setFrozen] = useState(false)
    const column = value.column
    const width = value.column.widthCalculated

    return (
        <PerformTableValueCellLayout idx={idx} column={column} columnLength={0} frozenColumn={2} key={idx}>
            {/* <PerformValueLayout className={`w-[${width}px] sticky top-0 ${idx <= 2 ? `z-10 left-[${value.column.columnLeft}]` : "left-0" }`}> */}
            {column !== null && (
                <React.Fragment>
                    {column.formType === PerformColumnFormTypes.COMBO && (
                        <PerformValueComboCell
                            column={column}
                            perform={perform}
                            value={value}
                            setEditComponent={setEditComponent}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.FILE && (
                        <PerformValueFileCell
                            column={column}
                            perform={perform}
                            value={value}
                            setEditComponent={setEditComponent}
                            showViewerComponent={showViewerComponent}
                        />
                    )}
                    {column.id === PerformStaticColumnTypes.MARKETER && (
                        <PerformValueMarketerCell column={column} perform={perform} user={user} />
                    )}
                    {column.id === PerformStaticColumnTypes.UPDATE && (
                        <PerformValueUpdateCell
                            column={column}
                            perform={perform}
                            showUpdateComponent={showUpdateComponent}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.TEXT &&
                        column.id !== PerformStaticColumnTypes.MARKETER &&
                        column.id !== PerformStaticColumnTypes.UPDATE && (
                            <PerformValueTextCell
                                column={column}
                                perform={perform}
                                value={value}
                                setEditComponent={setEditComponent}
                            />
                        )}
                    {column.formType === PerformColumnFormTypes.STATUS && (
                        <PerformValueStatusCell
                            column={column}
                            perform={perform}
                            value={value}
                            setEditComponent={setEditComponent}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.CHECK_BOX && (
                        <PerformValueCheckBoxCell
                            column={column}
                            value={value}
                            perform={perform}
                            setEditComponent={setEditComponent}
                            performCheck={performCheck}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.DATE && column.id < 0 && (
                        <PerformValueFixedDateCell
                            column={column}
                            value={value}
                            openCalcListModal={openCalcListModal}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.DATE && column.id >= 0 && (
                        <PerformValueDateCell
                            column={column}
                            perform={perform}
                            value={value}
                            setEditComponent={setEditComponent}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.OPTION && (
                        <PerformValueOptionCell
                            column={column}
                            perform={perform}
                            value={value}
                            setEditComponent={setEditComponent}
                        />
                    )}
                </React.Fragment>
            )}
            {/* </PerformValueLayout> */}
        </PerformTableValueCellLayout>
    )
}

const PerformRow = ({
    user,
    perform,
    columns,
    columnWidthSum,
    setEditComponent,
    showUpdateComponent,
    showViewerComponent,
    performCheck,
    openCalcListModal,
}) => {
    const [values, setValues] = useState([])

    useEffect(() => {
        let newValues = []

        columns.map((column) => {
            if (column === undefined) {
                return
            }

            const performValue = perform.values.find((pv) => pv.columnID === column.id)

            if (performValue !== undefined) {
                performValue.column = column

                // 파일 타입인 경우 FileID에 업로드된 파일 개수를 넣는다. (FileID를 사용하지 않기 때문)
                if (column.formType === PerformColumnFormTypes.FILE) {
                    performValue.fileID = perform.values.reduce(
                        (cnt, element) => cnt + (column.id === element.columnID),
                        0,
                    )
                }

                newValues.push(performValue)
            } else {
                // 실적에 컬럼에 해당하는 값이 없으면 빈 값을 만들어준다.
                let emptyValue = getPerformEmptyValue(column)

                if (emptyValue !== null) {
                    emptyValue.column = column
                    emptyValue.columnID = column.id
                    emptyValue.performID = perform.id
                    newValues.push(emptyValue)
                }
            }
        })

        setValues(newValues)
    }, [columns, perform])

    return (
        <PerformTableRowLayout className={`min-w-[${columnWidthSum}px] max-w-[${columnWidthSum}px] `}>
            {values.map((value, columnIndex) => (
                <PerformValue
                    user={user}
                    perform={perform}
                    value={value}
                    setEditComponent={setEditComponent}
                    showUpdateComponent={showUpdateComponent}
                    showViewerComponent={showViewerComponent}
                    performCheck={performCheck}
                    openCalcListModal={openCalcListModal}
                    idx={columnIndex}
                    key={columnIndex}
                />
            ))}
        </PerformTableRowLayout>
    )
}

const PerformColumn = ({ columns, columnWidthSum, filterDic }) => {
    return (
        <PerformTableHeaderBox className={`w-[${columnWidthSum}px] `}>
            <div className={`flex w-full bg-slate-500`}>
                {columns.map((column, idx) => {
                    let width = column.widthCalculated
                    let widthrounded = column.widthCalculated - 4

                    return (
                        <PerformTableColumnCellLayout
                            idx={idx}
                            column={column}
                            columnLength={columns.length}
                            frozenColumn={2}
                            // className={`w-[${width}px] flex-shrink-0 top-0 ${idx <= 2 || (columns.length-1)===idx ?  `z-20 bg-white sticky left-[${column.columnLeft}px]` : "left-0 z-10 bg-nightblue-600" }`}
                            key={idx}
                        >
                            <PerformTableHeader
                                id={column.id}
                                className={`
                                    ${
                                        idx === 0
                                            ? `w-[${widthrounded}px] bg-nightblue-600 absolute right-0 rounded-l-lg`
                                            : ""
                                    }
                                    ${
                                        columns.length - 1 === idx
                                            ? `w-[${widthrounded}px] bg-nightblue-600 absolute left-0 rounded-r-lg`
                                            : ""
                                    }
                                `}
                            >
                                {filterDic.contains(column.id) && <PerformTableHeaderFiltered />}
                                <span>{column.name}</span>
                                {/* {column.brandReadOnly && (
                                    <div className="absolute font-bold text-red-300 rounded-full border border-red-500 aspect-square h-6 flex flex-center left-0 top-5">
                                        B
                                    </div>
                                )}
                                {column.marketerReadOnly && (
                                    <div className="absolute font-bold text-red-300 rounded-full border border-red-500 aspect-square h-6 flex flex-center left-7 top-5">
                                        M
                                    </div>
                                )} */}
                            </PerformTableHeader>
                        </PerformTableColumnCellLayout>
                    )
                })}
            </div>
        </PerformTableHeaderBox>
    )
}

export default function MyPagePerformTable({
    user,
    columns,
    rows,
    filterDic,
    setEditComponent,
    showUpdateComponent,
    showViewerComponent,
    performCheck,
    openCalcListModal,
}) {
    const [columnWidthSum, setColumnWidthSum] = useState(0)

    // Column 정렬
    useEffect(() => {
        let widthSum = 0
        columns.map((column) => {
            widthSum += Number(column.widthCalculated)
        })
        setColumnWidthSum(widthSum)
    }, [columns])

    return (
        <PerformConInner>
            <PerformColumn columns={columns} columnWidthSum={columnWidthSum} filterDic={filterDic} />

            {rows.map((perform, idx) => (
                <PerformRow
                    key={idx}
                    user={user}
                    columns={columns}
                    perform={perform}
                    columnWidthSum={columnWidthSum}
                    setEditComponent={setEditComponent}
                    showUpdateComponent={showUpdateComponent}
                    showViewerComponent={showViewerComponent}
                    performCheck={performCheck}
                    openCalcListModal={openCalcListModal}
                />
            ))}
        </PerformConInner>
    )
}
