import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { ListControlItem } from "../../pages/myPage/MyPageMainStyle"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { ArrowCounterclockwise } from "@styled-icons/fluentui-system-filled/ArrowCounterclockwise"

const DEFAULT_WIDTH = "32"

export const FilterInputLabel = tw.span`
flex-shrink-0 text-gray-600 px-2 text-base
max-lg:text-sm
`

const InputBox = tw(ListControlItem)`
    max-lg:w-full ${(props) => (props.width !== null ? `lg:w-${props.width}` : `lg:w-${DEFAULT_WIDTH}`)}
    h-full
    mr-2
`

export const FilterInputSelectBox = tw(InputBox)`
`
export const FilterInputSelect = tw.select`
    w-full h-full focus:outline-none
`
export const FilterInputSort = tw.div`
    flex flex-center flex-shrink-0 w-auto h-full min-h-[1.75rem] aspect-square p-1 text-gray-500 hover:text-gray-700
    cursor-pointer
`

export const FilterInputTextBox = tw(InputBox)`
    justify-between
`

export const FilterInputSearch = tw.input`
w-full px-2 max-lg:text-sm lg:text-md
bg-transparent focus:outline-none
`
export const FilterInputSearchButton = tw.button`
flex flex-center max-lg:h-6 lg:h-7 px-2 py-1
text-nightblue-600 hover:text-nightblue-400 font-bold
rounded-lg
`
export const FilterInputSearchIcon = tw(Search)`
h-full
`
export const FilterInputSubSelect = tw.select`
flex shrink-0 mx-1 max-lg:text-sm
${(props) => `w-${props.width}`}
`

export const FilterInputResetButton = tw(InputBox)`
flex flex-center max-lg:w-10 lg:w-10 p-2 mr-0 select-none
text-gray-700 hover:text-gray-900 
cursor-pointer
`
export const FilterInputResetIcon = tw(ArrowCounterclockwise)`
    h-full
`
