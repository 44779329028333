import React, { useState, useEffect } from "react"
import WebEditor from "../../../../components/WebEditor"
import tw from "tailwind-styled-components"
import {
    updateBrandItemForm,
    getBrandItemForm,
    updateSystemAgreementFrom,
    getSystemAgreementFrom,
} from "../../../../apis/api/commons/index"
import { AgreementForm } from "../../../../types/Agreement"

const StaffItemsContractWriterLayout = tw.div`
    w-156 h-156 flex flex-col
`

const StaffItemsContractWriterTitle = tw.div`
    w-full h-14 flex items-center px-4
`

const StaffItemsContractWriterFooter = tw(StaffItemsContractWriterTitle)`
    justify-between
`

const StaffItemsContractWriterButton = tw.button`
    px-3 py-1.5 bg-nightblue-600 text-white rounded-md 
`

const StaffThirdPartyAgreementWriter = ({ setShow }) => {
    const [constarctValue, setConstarctValue] = useState(null)

    // 저장 눌렀을때 constarctValue 값 넘겨주기

    const handleonClickSaveValue = async () => {
        let bResult = false

        bResult = await updateSystemAgreementFrom(constarctValue, AgreementForm.THIRDPARTY)
        alert("저장되었습니다.")
    }

    const setData = async () => {
        const data = await getSystemAgreementFrom()
        const filteredList = data.filter((item) => item.key === AgreementForm.THIRDPARTY)
        if (filteredList.length > 0) {
            setConstarctValue(filteredList[0].content)
        }
    }

    useEffect(() => {
        setData()
    }, [])

    return (
        <StaffItemsContractWriterLayout>
            <StaffItemsContractWriterTitle className="text-lg font-medium">
                3자 정보 동의서 작성
            </StaffItemsContractWriterTitle>
            <div className="w-full flex-grow overflow-auto">
                <WebEditor value={constarctValue} setValue={setConstarctValue} />
            </div>
            <StaffItemsContractWriterFooter>
                <StaffItemsContractWriterButton
                    onClick={() => {
                        setShow(false)
                    }}
                    className="bg-gray-400"
                >
                    닫기
                </StaffItemsContractWriterButton>

                <StaffItemsContractWriterButton
                    onClick={() => {
                        handleonClickSaveValue()
                    }}
                >
                    저장
                </StaffItemsContractWriterButton>
            </StaffItemsContractWriterFooter>
        </StaffItemsContractWriterLayout>
    )
}

export default StaffThirdPartyAgreementWriter
