import BackendResponse, { getBackendResponse } from "../../../constants/BackendResponse"
import { authInstance, defaultInstance, defaultOrAuthInstance } from "../../utils"

export const postAlertTalk = async (templateType, datas) => {
    let result = false
    await defaultInstance
        .post(`/api/sms/alert_talk/`, {
            template_type: templateType,
            datas: datas,
        })
        .then((response) => {
            result = true
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}
