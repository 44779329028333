import React, { useEffect, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { UserCombo, UserInput, UserSMSAuthSubmitButton } from "../../components/user/Inputs"
import { getUserBySignup, getUserMarketerSignupRequestData, getUserSignupRequestData } from "../../services/users"
import { MarketerParticipantNames, MarketerParticipantTypes, UserTypes } from "../../types/users/UserTypes"
import Utils from "../../utils/Utils"
import { useUserStore } from "../../zustand/store"
import {
    SignUpCommitButton,
    SignUpInfoCol,
    SignUpInfoInputCol,
    SignUpInfoTitle,
    SignUpUserLayout,
    SignUpUserTitle,
    SignUpUserLayoutMarketer,
    StepsLayout,
    StepsContainer,
    StepLine,
    StepNumer,
    StepTitle,
    SignUpUserMarketerContainer,
    SignUpUserMarketerLayout,
    SignUpStepContainer,
    SignUpBasicInformationContainer,
    SignUpButtonContainer,
    SignUpButton,
    SignUpMainContainer,
    SignUpRecommanderButton,
} from "./SignUpStyle"
import SignUpUser from "./SignUpUser"
import tw from "tailwind-styled-components"
import { Steps } from "./Steps"
import { EditStateTypes } from "../../types/EditorTypes"
import { SignUpAgreement } from "./SignUpAgreement"
import SignUpStepButton from "./SignUpStepButton"
import { getCheckUserDuplicateTel, updateKakaoRegist } from "../../apis/api/users"
import SpinLoading, { SpinLoadingBackgroundType } from "../../components/loading/SpinLoading"
import { setUserToken } from "../../apis/services/users"
import ModalContainerPortal from "../../components/ModalContainerPortal"
import SignUpRecommanderModal from "./SignUpRecommanderModal"
import SignUpUserMarketerRecommand from "./SignUpUserMarketerRecommand"

const SignUpDone = ({ name }) => {
    return (
        <>
            <div className="w-full h-full mt-8 py-4 px-2 flex flex-col items-center">
                <span className="text-4xl font-semibold text-yellow-500">{name}님 환영합니다!</span>

                <div className="w-full max-md:aspect-[1000/500] md:aspect-[1000/300] mt-8 rounded-lg bg-gradient-to-tr from-nightblue-700 to-nightblue-500 text-white flex flex-center">
                    추천인 정보에 대한 설명
                </div>

                <div className="flex flex-col w-full mt-4">
                    <UserInput placeholder={"아파트 및 건물 명칭"} type={"text"} autoComplete={false} maxLength={100} />
                    <UserInput placeholder={"아파트 및 건물 주소"} type={"text"} autoComplete={false} maxLength={100} />
                    <UserInput placeholder={"회원 직책"} type={"text"} autoComplete={false} maxLength={100} />
                    <UserInput placeholder={"사무실 연락처"} type={"text"} autoComplete={false} maxLength={100} />
                    <div className="flex flex-col w-full px-0">
                        <UserInput placeholder={"추천인"} type={"text"}>
                            <SignUpRecommanderButton>검색</SignUpRecommanderButton>
                        </UserInput>
                    </div>
                </div>
            </div>
        </>
    )
}

const SignUpUserMarketer = ({ isOauth = false }) => {
    const [signUpLoading, setSignUpLoading] = useState(false)

    //기본정보
    const [userChecked, setUserChecked] = useState(false) // 유저 기본정보로 회원가입 할 수 있는지 여부
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [nickname, setNickname] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [tel, setTel] = useState("")
    const [birthday, setBirthday] = useState("")
    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [passwordValidate, setPasswordValidate] = useState(false)
    const [telValidate, setTelValidate] = useState(false)
    const [emailValidate, setEmailValidate] = useState(false)
    const [emailState, setEmailState] = useState(EditStateTypes.WAIT)

    //추가정보
    const [location, setLocation] = useState("")
    const [participantType, setParticipantType] = useState(MarketerParticipantTypes.FULL)
    const [profit, setProfit] = useState()
    const [careers, setCareers] = useState([])

    // 추천인 정보
    const [recommanderKeyword, setRecommanderKeyword] = useState("")
    const [recommanderID, setRecommanderID] = useState(-1)
    const [recommanderModalShow, setRecommanderModalShow] = useState(false)

    //개인정보 동의
    const [ischecked, setIsChekced] = useState(false)
    //마케팅 정보 수신 동의
    const [isMarketingChecked, setIsMarketingChecked] = useState(false)

    // 나머지 state
    const [stepIndex, setStepIndex] = useState(0)
    const { user, actions } = useUserStore()
    const navigate = useNavigate()

    const handleParticipantTypeChange = (value) => {
        setParticipantType(value)
    }

    useEffect(() => {
        if (telAuthState === EditStateTypes.DONE) {
            getCheckUserDuplicateTel(UserTypes.MARKETER, tel).then((response) => {
                setUserChecked(response)

                if (!response) {
                    alert("이미 가입된 마케터입니다.")
                }
            })
        }
    }, [telAuthState])

    const signUp = useCallback(() => {
        if (isOauth) {
            const data = {
                tel: tel,
                recommander_id: recommanderID,
            }
            return oAuthSignup(data)
        }
        if (
            !passwordValidate ||
            password1 !== password2 ||
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            Utils.isStringNullOrEmpty(password1) ||
            Utils.isStringNullOrEmpty(password2) ||
            Utils.isStringNullOrEmpty(tel)
        ) {
            if (password1 !== password2) {
                alert("비밀번호가 일치하지 않습니다.")
                return Promise.reject(new Error("비밀번호가 일치하지 않습니다."))
            } else {
                alert("입력 내용을 확인 해주세요")
                return Promise.reject(new Error("입력 내용을 확인 해주세요"))
            }
        }

        if (telAuthState !== EditStateTypes.DONE) {
            alert("전화번호를 인증해주세요")
            return Promise.reject(new Error("전화번호를 인증해주세요"))
        }

        let requestData = {
            ...getUserSignupRequestData(email, name, nickname, password1, tel, UserTypes.MARKETER, true),
        }
        requestData["user"]["recommander_id"] = recommanderID

        return getUserBySignup(requestData)
            .then((user) => {
                setSignUpLoading(false)
                if (user.userType === UserTypes.MARKETER) {
                    actions.setEmail(user.email)
                    actions.setName(user.name)
                    actions.setUserType(user.userType)
                    actions.setUser(user)

                    return Promise.resolve(true)
                } else {
                    alert("마케터 회원으로 회원가입 되지 않았습니다.")
                    throw new Error("마케터 회원으로 회원가입 되지 않았습니다.")
                }
            })
            .catch((error) => {
                setSignUpLoading(false)
                alert("회원가입에 실패했습니다.")
                throw error
            })
    }, [email, name, password1, password2, tel, telAuthState, recommanderID])

    const oAuthSignup = async (data) => {
        if (Utils.isStringNullOrEmpty(tel)) {
            alert("입력 내용을 확인 해주세요")
            return Promise.reject(new Error("입력 내용을 확인 해주세요"))
        }

        const response = await updateKakaoRegist(data)
        const user = setUserToken(response)

        if (user.id >= 0) {
            actions.setEmail(user.email)
            actions.setName(user.name)
            actions.setUserType(user.userType)
            actions.setUser(user)
            setName(user.name)

            return Promise.resolve(true)
        }

        return Promise.reject(new Error("실패했습니다"))
    }

    return (
        <SignUpUserMarketerLayout>
            <SignUpUserMarketerContainer>
                <SignUpUserTitle>마케터 회원가입</SignUpUserTitle>

                <SignUpStepContainer>
                    <Steps stepindex={stepIndex} userType={UserTypes.MARKETER} />
                </SignUpStepContainer>

                <SignUpMainContainer>
                    {stepIndex === 0 && (
                        <SignUpAgreement
                            ischecked={ischecked}
                            setIsChekced={setIsChekced}
                            setIsMarketingChecked={setIsMarketingChecked}
                        />
                    )}
                    {stepIndex === 1 && (
                        <SignUpUser
                            email={email}
                            setEmail={setEmail}
                            name={name}
                            setName={setName}
                            nickname={nickname}
                            setNickname={setNickname}
                            password1={password1}
                            setPassword1={setPassword1}
                            password2={password2}
                            setPassword2={setPassword2}
                            passwordValidate={passwordValidate}
                            setPasswordValidate={setPasswordValidate}
                            tel={tel}
                            setTel={setTel}
                            telValidate={telValidate}
                            setTelValidate={setTelValidate}
                            emailValidate={emailValidate}
                            setEmailValidate={setEmailValidate}
                            telAuthState={telAuthState}
                            setTelAuthState={setTelAuthState}
                            setEmailState={setEmailState}
                            emailState={emailState}
                            isOauth={isOauth}
                        />
                    )}
                    {stepIndex === 2 && <SignUpUserMarketerRecommand user={user} />}
                </SignUpMainContainer>

                <SignUpStepButton
                    userType={UserTypes.MARKETER}
                    signUp={signUp}
                    setSignUpLoading={setSignUpLoading}
                    setStepIndex={setStepIndex}
                    ischecked={ischecked}
                    userChecked={userChecked}
                    maxStep={3}
                />
            </SignUpUserMarketerContainer>

            <SpinLoading
                isLoading={signUpLoading}
                top={"0"}
                backgroundType={SpinLoadingBackgroundType.BLUR}
                message={"회원가입 중입니다."}
            />
        </SignUpUserMarketerLayout>
    )
}

export default SignUpUserMarketer

{
    /* {stepIndex===1 &&
    <React.Fragment>
        <SignUpBasicInformationContainer>
            <SignUpInfoInputCol>
                <UserInput placeholder={"생년월일"} type={"date"} data={birthday} setData={setBirthday} />
                <UserInput placeholder={"지역"} type={"string"} data={location} setData={setLocation} />
                <UserCombo 
                    label={"참여 형식"} 
                    optionKeys={Object.keys(MarketerParticipantTypes)} 
                    names={Object.values(MarketerParticipantNames)}
                    value={participantType}
                    onChange={(e)=>{handleParticipantTypeChange(e.target.selectedIndex)}}
                />
                <UserInput placeholder={"영업 실적"} type={"number"} data={profit} setData={setProfit} />
            </SignUpInfoInputCol>
        </SignUpBasicInformationContainer>
        <SignUpButtonContainer>
            <SignUpButton
            onClick={() => {setStepIndex(stepIndex-1)}}
            >
                이전
            </SignUpButton>
            <SignUpButton
            onClick={() => {signUp();}}
            >
                완료
            </SignUpButton>
        </SignUpButtonContainer>
    </React.Fragment>
} */
}
