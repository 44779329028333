import { PerformColumnFormTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.name = ""
        this.color = ""
        this.bgColor = "#ddd"
        this.brandReadOnly = false
        this.marketerReadOnly = false
        this.isConfirm = false
        this.isRequireComment = false
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["column_id"]
        this.name = response["name"]
        this.color = "#"+response["color"]
        this.bgColor = "#"+response["background_color"]
        this.brandReadOnly = response["brand_read_only"]
        this.marketerReadOnly = response["marketer_read_only"]
        this.isConfirm = response["is_confirm"]
        this.isRequireComment = response["is_require_comment"]
    }

    convertToRequest() {
        let request = {
            id: -1,
            column_id: 0,
            name: "",
            color: "",
            bg_color: "#ddd",
            brand_read_only: false,
            marketer_read_only: false,
            is_confirm: false,
            is_require_comment: false,
        }

        request.id = this.id
        request.column_id = this.columnID
        request.name = this.name
        request.color = this.color
        request.background_color = this.bgColor
        request.brand_read_only = this.brandReadOnly
        request.marketer_read_only = this.marketerReadOnly
        request.is_confirm = this.isConfirm
        request.is_require_comment = this.isRequireComment

        request.color = request.color.replace("#", "")
        request.background_color = request.background_color.replace("#", "")

        return request
    }
}