import React, { useEffect } from "react"
import GlobalStyle from "./styles/Globalstyle"
import Router from "./router/Router"
import { Box } from "./layouts/Box"
import { useUserStore } from "./zustand/store"
import { getUserByRefreshToken } from "./services/users"
import { UserTypes } from "./types/users/UserTypes"
import { ModalContainer } from "./components/ModalContainer"
import Login from "./components/user/login/LoginMain"
import {
    useLoginModalStore,
    marketerContractViewer,
    marketerApplyModal,
    useBrandLoginModalStore,
} from "./zustand/modalstore"
import { getUserDetail } from "./apis/api/users"
import ContractImageViewer from "./components/brandItems/ContractImageViewer"
import { MyPageMarketerDetailContractViewer } from "./components/mypage/marketer/MyPageMarketerDetailContractViewer"
import testStyle from "./testStyle.css"
import MarketerDetail from "./components/user/MarketerDetail"
import { RESPONSIVE_WIDTH } from "./constants/ConstUI"
import { useNavigate } from "react-router-dom"
import { getCookie } from "./utils/Cookie"
import ConstCookie from "./constants/ConstCookie"
import Utils from "./utils/Utils"

function App() {
    const { user, actions } = useUserStore()
    const navigation = useNavigate()

    const loginShow = useLoginModalStore((state) => state.loginShow)
    const setLoginShow = useLoginModalStore((state) => state.setLoginShow)

    const loginBrandShow = useBrandLoginModalStore((state) => state.loginShow)
    const setLoginBrandShow = useBrandLoginModalStore((state) => state.setLoginShow)

    const marketerModalShow = marketerApplyModal((state) => state.marketerDetailShow)
    const setMarketerModalShow = marketerApplyModal((state) => state.setMarketerApplyModal)

    const marketerContractCardShow = marketerContractViewer((state) => state.marketerContractCardShow)
    const setMarketerContractCardShow = marketerContractViewer((state) => state.setMarketerContractCardShow)

    const contractImageSources = marketerContractViewer((state) => state.contractImageSources)
    const setContractImageSources = marketerContractViewer((state) => state.setContractImageSources)

    useEffect(() => {
        refreshUser()

        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_AUTH_KEY)
            // window.Kakao.init('96f07fcb7a8fc6c23702ea9f6a6459c3');
        }
    }, [])

    const refreshUser = () => {
        if (Utils.isStringNullOrEmpty(getCookie(ConstCookie.AUTH_STATE))) {
            actions.setLogout()
            return
        }

        getUserByRefreshToken()
            .then((user) => {
                if (user !== undefined) {
                    actions.setEmail(user.email)
                    actions.setName(user.name)
                    actions.setUserType(user.userType)
                    actions.setUser(user)
                } else {
                    actions.setLogout()
                    alert("로그인 세션이 만료되었습니다..")
                    navigation("/")
                }
            })
            .catch((error) => {
                console.log(error)
                actions.setLogout()
                alert("로그인 세션이 만료되었습니다.")
                navigation("/")
            })
    }

    return (
        <Box className="App flex flex-col w-screen scroll-transparent scroll-ovelay">
            <GlobalStyle />
            <Router></Router>

            <ModalContainer
                show={marketerModalShow}
                setShow={setMarketerModalShow}
                fadeUp={true}
                shutDownNavigateChange={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.MD}
            >
                <MarketerDetail />
            </ModalContainer>

            <ModalContainer show={loginShow} setShow={setLoginShow} fadeUp={true} shutDownNavigateChange={true}>
                <Login setShowComponent={setLoginShow} />
            </ModalContainer>
            <ModalContainer
                show={loginBrandShow}
                setShow={setLoginBrandShow}
                fadeUp={true}
                shutDownNavigateChange={true}
            >
                <Login setShowComponent={setLoginBrandShow} />
            </ModalContainer>

            <MyPageMarketerDetailContractViewer
                show={marketerContractCardShow}
                setShow={setMarketerContractCardShow}
                shutDownNavigateChange={true}
            >
                <ContractImageViewer imageSources={contractImageSources} />
            </MyPageMarketerDetailContractViewer>
        </Box>
    )
}

export default App
