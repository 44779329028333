import React,{useEffect} from "react";
import tw from 'tailwind-styled-components'
import { ItemBody } from "./MyPageItemStyle";
import MyPageItemBrand from "./brand/MyPageItemBrand";
import { ContentContainer } from "../MyPageMainStyle";
import {PmsNavState} from '../../../types/DataEnum'
export default function MyPageItem({setPmsNavState}){

    useEffect (() =>{

        setPmsNavState(PmsNavState.ITEM)

    },[])
    return(
        <ContentContainer>
            <MyPageItemBrand/>
        </ContentContainer>
        // <ItemBody>
        // </ItemBody>
    )
}