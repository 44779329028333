import User from "../users/User"

export default class{
    constructor() {
        this.id = -1
        this.name = ""
        this.size = ""
        this.fileURL = ""
        this.isTemp = false

        this.user = new User()
    }

    convertByResponse(response) {
        if (response.id !== undefined) {
            this.id = response.id
            this.name = response.name
            this.size = response.size
            this.fileURL = response.file
            this.user = new User()
            if (response["user"] !== undefined) {
                this.user.convertByResponse(response["user"])
            }
            if (response["is_temp"] !== null && response["is_temp"] !== undefined) {
                this.isTemp = response["is_temp"]
            }
        }
    }
}