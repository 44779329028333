import React, { useState, useEffect } from "react"
import WebEditor from "../../../../components/WebEditor"
import tw from "tailwind-styled-components"
import {
    updateBrandItemForm,
    getBrandItemForm,
    updateSystemAgreementFrom,
    getSystemAgreementFrom,
} from "../../../../apis/api/commons/index"
import { Agreement, Usage } from "../../../user/agreementData"
import { AgreementForm } from "../../../../types/Agreement"
import { getItemPersonalAgreementPaper, updatePersonalAgreementPaper } from "../../../../apis/api/boardItems"
import CKEditor from "../../../../components/ckeditor/CKEditor"
import { MyPageInputModalLayout } from "../../MyPageMainStyle"

const StaffItemsContractWriterLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen sm:w-[640px]
    h-[80vh]
`

const StaffItemsContractWriterTitle = tw.div`
    w-full h-14 flex items-center px-4
`

const StaffItemsContractWriterFooter = tw(StaffItemsContractWriterTitle)`
    justify-between
`

const StaffItemsContractWriterButton = tw.button`
    px-3 py-1.5 bg-nightblue-600 text-white rounded-md 
`

const ItemPersonalAgreementPaperWriter = ({ itemID, isShow, setShow }) => {
    const [content, setContent] = useState("")

    const handleonClickSaveValue = async () => {
        let bResult = false

        bResult = await updatePersonalAgreementPaper(itemID, content)

        if (bResult) {
            alert("저장되었습니다 ")
            setShow(false)
            setData()
        } else {
            alert("실패했습니다.")
        }
    }

    const setData = async () => {
        const paper = await getItemPersonalAgreementPaper(itemID)
        setContent(paper.content)
    }

    useEffect(() => {
        if (isShow) {
            setData()
        }
    }, [isShow])

    return (
        <StaffItemsContractWriterLayout>
            <StaffItemsContractWriterTitle className="text-lg font-medium">
                개인정보동의서
            </StaffItemsContractWriterTitle>
            <div className="w-full flex-grow overflow-auto">
                <CKEditor value={content} setValue={setContent} />
            </div>
            <StaffItemsContractWriterFooter className="justify-end px-0">
                {/* <StaffItemsContractWriterButton
                    onClick={() => {
                        setShow(false)
                    }}
                    className="bg-gray-400"
                >
                    닫기
                </StaffItemsContractWriterButton> */}

                <StaffItemsContractWriterButton
                    onClick={() => {
                        handleonClickSaveValue()
                    }}
                >
                    저장
                </StaffItemsContractWriterButton>
            </StaffItemsContractWriterFooter>
        </StaffItemsContractWriterLayout>
    )
}

export default ItemPersonalAgreementPaperWriter
