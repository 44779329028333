import React from "react"
import { mobileModalItemDetail } from "../../../../zustand/mobilemodalstore"
import { MyPageItemMessage, MyPageItemNoticeList, MyPageItemProcess } from "./MyPageItemBrand"
import { ImageBase64 } from "../../../../components/ImageContainer"
import FileUtils from "../../../../utils/FileUtils"
import {
    LinkBtn,
    MyPageDetailMobileControlBox,
    MyPageDetailMobileControlButton,
    MyPageDetailMobileControlButtonIconContainer,
    MyPageDetailMobileDataBlock,
    MyPageDetailMobileDataBox,
    MyPageDetailMobileLayout,
    MyPageDetailMobileNoticeBox,
    MyPageDetailMobileProcessBox,
    MyPageDetailMobileThumbnailBox,
    MyPageDetailMobileTitle,
    MyPageDetailMobileTitleBox,
    RegistCancleBtn,
    SetContractBtn,
    SettingBtn,
} from "./MyPageDetailMobileStyle"
import AccessRules from "../../../../rules/AccessRules"
import { Link, useNavigate } from "react-router-dom"
import { CancelIcon, DocumentIcon, FileContractIcon, PerformIcon, SettingIcon } from "../MyPageItemStyle"
import { ItemSalesTypeNames, MarketerTypeNames } from "../../../../types/brandItems/BrandItemTypes"
import { NOT_SCROLLABLE } from "../../../../constants/ConstClassName"
import { getUserThirdAgreementCode } from "../../../../apis/api/users"
import Utils from "../../../../utils/Utils"
import { getItemMarketerID } from "../../../../apis/api/boardItems"

const ItemDetailLabel = ({ label, value }) => {
    return (
        <div className="flex items-center w-full max-lg:text-xs lg:text-sm">
            <span className="mr-2 font-medium flex-shrink-0">{label}</span>
            <span className="text-gray-700 w-full truncate" title={value}>
                {value}
            </span>
        </div>
    )
}

export const MyPageDetailMobile = ({
    user,
    navigate,
    item,
    itemUpdate,
    showItemContract,
    deleteItemMarketer,
    setShowAgreementModal,
    openNoticeModal,
    openAgreementList,
}) => {
    const handleItemUpdate = () => {
        itemUpdate(item)
    }
    const handleItemContract = () => {
        showItemContract(item)
    }
    async function handleOpenAgreement() {
        await getItemMarketerID(user.id, item.id).then((response) => {
            openAgreementList(item.id, response)
            setShowAgreementModal(true)
        })
    }
    const handleIconCancle = () => {
        // 취소 아이콘
        if (confirm(`상품 ${item.detail.title}의 신청을 취소합니다.\n계속하시겠습니까?`)) {
            deleteItemMarketer(item)
        }
    }

    const handleToPerform = () => {
        navigate(`/mypage/perform/${item.id}`)
    }

    return (
        <MyPageDetailMobileLayout>
            <MyPageDetailMobileTitleBox>
                <MyPageDetailMobileThumbnailBox>
                    <ImageBase64 imageURL={FileUtils.getMediaFileURL(item.detail.thumbnailImageURL)} />
                </MyPageDetailMobileThumbnailBox>
                <div className="flex flex-col justify-between w-full h-full pr-3">
                    <MyPageDetailMobileTitle>{item.detail.title}</MyPageDetailMobileTitle>

                    <div className="text-xs px-4">
                        <MyPageItemMessage user={user} item={item} />
                    </div>
                </div>
            </MyPageDetailMobileTitleBox>
            <MyPageDetailMobileDataBox>
                <MyPageDetailMobileDataBlock className="flex-shrink-0 w-36">
                    <ItemDetailLabel label={"상품 유형"} value={ItemSalesTypeNames[item.detail.itemSalesType]} />
                    <ItemDetailLabel label={"마케터 타입"} value={MarketerTypeNames[item.detail.marketerType]} />
                </MyPageDetailMobileDataBlock>
                <MyPageDetailMobileDataBlock className="ml-2 w-full">
                    <ItemDetailLabel label={"실적 보상"} value={item.detail.rewardMessage} />
                    <ItemDetailLabel label={"영업 업무"} value={item.detail.missionMessage} />
                </MyPageDetailMobileDataBlock>
            </MyPageDetailMobileDataBox>
            <MyPageDetailMobileProcessBox>
                <MyPageItemProcess item={item} />
            </MyPageDetailMobileProcessBox>
            <MyPageDetailMobileNoticeBox className={`${NOT_SCROLLABLE}`}>
                <MyPageItemNoticeList item={item} user={user} openNoticeModal={openNoticeModal} />
            </MyPageDetailMobileNoticeBox>
            <MyPageDetailMobileControlBox>
                {/* <MyPageDetailMobileControlButton>1</MyPageDetailMobileControlButton>
                <MyPageDetailMobileControlButton>1</MyPageDetailMobileControlButton>
                <MyPageDetailMobileControlButton>1</MyPageDetailMobileControlButton> */}
                {/* 아이템 세팅 */}
                {AccessRules.brandItemUpdate(item, user) && (
                    <SettingBtn
                        isShow={item.isAccess}
                        onClick={() => {
                            handleItemUpdate()
                        }}
                    >
                        <MyPageDetailMobileControlButtonIconContainer>
                            <SettingIcon />
                        </MyPageDetailMobileControlButtonIconContainer>
                        <span>상품 설정</span>
                    </SettingBtn>
                )}
                {/* 아이템 취소 버튼 */}
                {!item.isAccess && (
                    <RegistCancleBtn
                        onClick={() => {
                            handleIconCancle()
                        }}
                    >
                        <MyPageDetailMobileControlButtonIconContainer>
                            <CancelIcon />
                        </MyPageDetailMobileControlButtonIconContainer>
                        <span>신청 취소</span>
                    </RegistCancleBtn>
                )}

                <SetContractBtn
                    className="hover:border-green-400"
                    onClick={() => {
                        handleItemContract()
                    }}
                >
                    <MyPageDetailMobileControlButtonIconContainer>
                        <FileContractIcon />
                    </MyPageDetailMobileControlButtonIconContainer>
                    <span>계약서 조회</span>
                </SetContractBtn>

                {item.isAccess && AccessRules.marketer(user) && (
                    <SetContractBtn
                        className="bg-nightblue-400 text-white"
                        onClick={() => {
                            handleOpenAgreement()
                        }}
                    >
                        <MyPageDetailMobileControlButtonIconContainer>
                            <DocumentIcon />
                        </MyPageDetailMobileControlButtonIconContainer>
                        <span>고객사 동의</span>
                    </SetContractBtn>
                )}

                <LinkBtn
                    isShow={item.isAccess}
                    onClick={() => {
                        handleToPerform()
                    }}
                >
                    <MyPageDetailMobileControlButtonIconContainer>
                        <PerformIcon />
                    </MyPageDetailMobileControlButtonIconContainer>
                    <span>실적</span>
                </LinkBtn>
                {/* <Link to={`/mypage/perform/${item.id}`} state={{ itemID: item.id }} className='w-auto h-full'>
                </Link> */}
            </MyPageDetailMobileControlBox>
        </MyPageDetailMobileLayout>
    )
}
