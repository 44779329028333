// 아이템 소팅 콤보박스 데이터

export const BrandItemFilterCategory = {
    ItemSales: 0,
    Marketer: 1
}

// 마케터 검색 타입
export const BrandItemMarketerSearchOptionTypes = {
    MARKETER: 0,
    GROUP_NAME: 1,
}
export const BrandItemMarketerSearchOptionNames = {
    0: "마케터",
    1: "그룹 명",
}

// 마케터 리스트 정렬 타입
export const BrandItemMarketerOrderOptionTypes = {
    REGIST: "id",
    MARKETER: "marketer__user__first_name",
    GROUP: "group__name",
    STATUS: "marketer_status_type",
    SUSPEND: "marketer_suspend_status_type",
}
export const BrandItemMarketerOrderOptionNames = {
    "id": "신청 순",
    "marketer__user__first_name": "이름 순",
    "group__name": "그룹 순",
    "marketer_status_type": "상태 순",
    "marketer_suspend_status_type": "정지 상태 순",
}
