import tw from "tailwind-styled-components"
import styled from "styled-components"
import { ContentTopSize, ListControlItem, ListSubControlBox } from "../MyPageMainStyle"
import {
    MyPageContentHeaderColor,
    MyPageContentHeaderHeight,
    MyPageContentRowHeight,
} from "../MyPageMainStyleParameter"
import { EditStateTypes } from "../../../types/EditorTypes"
import { ArrowCounterclockwise } from "@styled-icons/fluentui-system-filled/ArrowCounterclockwise"
import { Crown } from "@styled-icons/boxicons-regular/Crown"
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots"
import { User } from "@styled-icons/typicons/User"

const MarketerRowHeight = "h-[3rem] min-h-[3rem] max-h-[3rem]"

export const MarketerCon = tw.div`
    w-full h-full p-10 
`

const ResponsiveOverflow = styled.div`
    overflow-x: overlay;
    overflow-y: hidden;
    @media (min-width: 1024px) {
        overflow-y: overlay;
    }
`
export const MarketerTableCon = tw(ResponsiveOverflow)`
    lg:absolute
    flex flex-col w-full max-w-full lg:h-full pb-36
    bg-white rounded-lg drop-shadow
    overflow-x-scroll max-lg:overflow-y-hidden scroll-transparent scroll-overlay thumb-lg
`

export const MarketerTopContainer = tw(ContentTopSize)`
    flex py-2
`
export const MarketerControlBar = tw.div`
    flex items-center w-full h-full p-2 justify-between
    bg-white rounded-lg drop-shadow-lg
`
export const MarketerSortVisCon = tw.div`
    w-full  ${(props) => (props.sortvisible ? "visible" : "invisible")}
`

export const MarketerTableHeadRow = tw.div`
    ${() => MyPageContentHeaderHeight}
    ${() => MyPageContentHeaderColor}
    flex flex-row sticky top-0 z-20
`
export const MarketerTableBody = tw.div`
    w-full lg:h-full bg-white overflow-scroll scroll-transparent scroll-overlay 
`

export const MarketerTableSearchCon = tw.div`
    w-48 flex items-center rounded-xl overflow-hidden border border-amber-300
`
export const MarketerTableSearch = tw.input`
    w-full px-3 focus:outline-none
`
export const MarketerTableButton = tw.button`
    bg-amber-300 hover:bg-amber-400 w-14 h-full text-amber-800
`
export const MarketerToggleButton = tw.button`
    max-lg:w-20 max-lg:text-sm lg:w-28 max-lg:h-full lg:py-1 rounded border border-nightblue-500
    max-sm:text-sm
    ${(props) =>
        props.marketerstate === 0 ? "bg-nightblue-500 hover:bg-nightblue-600 text-white " : "text-nightblue-500"}
    duration-200
`

export const MarketerSubControlBox = tw(ListSubControlBox)`
    justify-end
`

export const DefineIsMobileDiv = tw.div`
    ${(props) => (props.isMobile ? "max-lg:flex lg:hidden " : "max-lg:hidden lg:flex ")}
    sticky  justify-center left-0 z-20
    h-full
    ${(props) => (props.isAlt ? "bg-slate-100 " : "bg-white")}
`

export const MarketerTableHead = tw.div`
    h-full max-lg:flex lg:flex items-center justify-center bg-nightblue-600
`
export const MarketerTableDataRow = tw.div`
    max-lg:min-h-[2.5rem] lg:min-h-[3rem]
    flex flex-row items-center border-b group max-lg:py-0 lg:py-2 w-full
    max-sm:text-sm
    ${(props) => (props.isAlt ? "bg-slate-100" : "bg-white")}
`
export const MarketerTableData = tw.div`
    h-full max-lg:flex lg:flex items-center justify-center
    bg-white
`
export const MarketerCellButton = tw.span`
    flex flex-center px-2 py-1 w-full
    rounded-lg bg-slate-200 hover:bg-slate-300
    text-slate-800
    cursor-pointer truncate
    max-lg:text-sm
    duration-200
`
export const MarketerInviteIcon = tw(ThreeDots)`
    h-full
`
export const MarketerStatSelect = tw.select`
    w-20 px-1 py-0.5 rounded-lg
    focus:outline-none
    ${(props) => props.editStateType !== EditStateTypes.WAIT && "ring-2"}
    ${(props) => props.editStateType === EditStateTypes.PENDING && "ring-yellow-400"}
    ${(props) => props.editStateType === EditStateTypes.SUCCESS && "ring-green-400"}
    ${(props) => props.editStateType === EditStateTypes.FAILED && "ring-red-400"}
`
export const MarketerSortOptionButton = tw.button`
    mx-6 py-1 rounded-lg w-20 text-black border-2 border-amber-400 text-amber-800
`
export const MarketerTableSortInput = tw.input`
    w-44 py-1 rounded-xl mx-4
`

export const MarketerTableStatusSelectBox = tw(ListControlItem)`
    flex flex-shrink-0 max-lg:w-28 lg:w-32 px-1 py-1 max-lg:mr-2 lg:ml-2
`
export const MarketerTableSortSelect = tw.select`
    w-full py-1 focus:outline-none
    max-lg:text-sm lg:text-base
`
export const MarketerFilterResetButton = tw(ListControlItem)`
flex flex-center w-10 p-2 ml-2 select-none
text-pink-700 hover:text-pink-900 
cursor-pointer
`

export const MarketerFilterResetIcon = tw(ArrowCounterclockwise)`
    h-full
`

export const BuisnessImageButton = tw.button`

    py-1 px-2 min-w-[5rem] text-white rounded-md
 
    ${(props) => (props.isimage ? "bg-blue-400" : "bg-red-400")}
`

export const CrownContainer = tw.div`
    h-4 text-yellow-400 flex items-center px-1
`

export const UserContainer = tw.div`
    h-4 text-blue-400 flex items-center px-1
`

export const CrownIcon = tw(Crown)`
    h-full
`

export const UserIcon = tw(User)`
    h-full
`

export const InvitedCountContainer = tw.div`
    absolute top-0.5 right-0 flex flex-center w-6 aspect-square rounded-full bg-red-500 text-white text-sm
`
