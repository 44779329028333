import React, { useEffect, useRef, useState } from "react"
import tw from "tailwind-styled-components"
import { getImageFile } from "../../apis/api/files"
import BrandItem from "../../types/brandItems/BrandItem"
import { ImageBase64 } from "../ImageContainer"
import { useMouseHover } from "../../hooks/useMouseHover"
import {
    FileNameContainer,
    ItemInputBox,
    ItemInputLabel,
    ItemInputLabelBox,
    ItemThumbNailLabelContainer,
    ThubmNailPrevContainer,
    ThumbNailComment,
    UploadButtonContainner,
    UploadRule,
} from "../../pages/myPage/item/brand/ItemInputFormStyle"
import WebEditor from "../WebEditor"
import { Pencil } from "@styled-icons/boxicons-solid/Pencil"
import { useFocus } from "../../hooks/useFocus"
import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"
import { InformationHover } from "../../components/brandItems/InformationHover"
import { EditStateTypes } from "../../types/EditorTypes"
import { ItemEditorMessage, ItemEditorMessageContainer } from "../../pages/myPage/item/brand/MyPageItemBrandStyle"
import { FONT_FAMILY } from "../../styles/Globalstyle"

import CKEditor from "../ckeditor/CKEditor"
// import "../../components/ckeditor/ckcontentstyle.css"
import { getSigunguDatas } from "../../apis/api/commons/index"
import Sigungu from "../../types/commons/Sigungu"
import ModalContainer from "../../components/ModalContainerPortal"
import SigunguModal from "./SigunguModal"
import useDropdownDetectClose from "../../hooks/useDropdownDetectClose"
import { CircleChevronDown } from "@styled-icons/fa-solid/CircleChevronDown"

const InputText = tw.input`
    px-3 lg:py-2 bg-transparent rounded-lg
    border focus:border-blue-300 focus:ring-2 focus:ring-blue-300 focus:bg-blue-50 outline-none
    duration-200
    disabled:bg-gray-100 disabled:px-3
    max-lg:h-14 h-10 max-lg:rounded-lg
`
const InputTextArea = tw.textarea`
    px-3 py-2 bg-gray-100 rounded-lg
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50
    duration-200
    max-lg:flex-1
`
const InputBox = tw.div`
    px-3 lg:py-2 bg-transparent rounded-lg
    border hover:bg-blue-50 hover:border-blue-300
    duration-200 cursor-pointer
    disabled:bg-gray-100 disabled:px-3
    max-lg:h-14 h-10 max-lg:rounded-lg
`
const InputSelectBox = tw.div`
    flex max-lg:h-14 h-10 p-2 bg-white rounded-md border
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50 focus:outline-none
    duration-200 
`
const InputSelect = tw.select`
    w-full
`
const InputFile = tw.input`
    p-2 hidden
`
const InputFileButton = tw.label`
    w-64 py-3 
    text-center text-white
    rounded-md bg-nightblue-400 hover:bg-nightblue-500
    cursor-pointer
`
const InputFileName = tw.span`
    px-2 py-1 w-full truncate
    ${(props) => (props.disabled ? "text-black bg-gray-150 rounded-lg" : "text-gray-700")}
`
const InputImageThumbnail = tw.div`
    absolute flex w-44 h-32 left-20 overflow-hidden
    bg-white drop-shadow-xl rounded-lg
    ${(props) => (props.isShow ? "visible opacity-100 translate-y-[-5rem]" : "invisible opacity-0 translate-y-[-4rem]")}
    transform-gpu
    duration-200
`

const ToolTipsTexts = tw.pre`
    max-sm:text-[0.65rem] sm:text-xs overflow-auto max-sm:w-48 sm:w-[max-content]
`
//max-sm:w-48 max-lg:52 lg:w-60

const EditedIcon = tw(Pencil)`
    h-4 text-yellow-600
`

export const BrandItemInput = ({
    type,
    placeholder,
    label,
    data,
    setData,
    onKeyPress,
    disabled = false,
    widthFull = false,
    isEdited = false,
    information = "",
    informationAlignType = null,
    maxLength = undefined,
    helpText = "",
}) => {
    return (
        <ItemInputBox className={`${widthFull && "w-full mx-0"}`}>
            <ItemInputLabelBox>
                <div className="flex h-full items-center">
                    <ItemInputLabel>{label}</ItemInputLabel>
                    {!Utils.isStringNullOrEmpty(information) && (
                        <InformationHover alignType={informationAlignType}>
                            <ToolTipsTexts style={{ fontFamily: FONT_FAMILY }}>{information}</ToolTipsTexts>
                        </InformationHover>
                    )}
                    {!Utils.isStringNullOrEmpty(helpText) && (
                        <span className="ml-2 text-sm text-nightblue-400">{helpText}</span>
                    )}
                </div>
                {isEdited && <EditedIcon />}
            </ItemInputLabelBox>
            <InputText
                type={type}
                value={data}
                onChange={(e) => {
                    setData(e.target.value)
                }}
                onKeyPress={onKeyPress}
                disabled={disabled}
                placeholder={placeholder}
                maxLength={maxLength}
            />
        </ItemInputBox>
    )
}

export const BrandItemTextArea = ({
    type,
    placeholder,
    label,
    data,
    setData,
    onKeyPress,
    disabled = false,
    widthFull = false,
    isEdited = false,
}) => {
    return (
        <ItemInputBox className={`${widthFull && "w-full mx-0 max-lg:flex-1 max-lg:py-4"}`}>
            <ItemInputLabelBox>
                <ItemInputLabel>{label}</ItemInputLabel>
                {isEdited && <EditedIcon />}
            </ItemInputLabelBox>
            <InputTextArea
                rows={4}
                type={type}
                value={data}
                onChange={(e) => {
                    setData(e.target.value)
                }}
                onKeyPress={onKeyPress}
                disabled={disabled}
                placeholder={placeholder}
            />
        </ItemInputBox>
    )
}

export const BrandItemCombo = ({
    label,
    optionKeys,
    names,
    value,
    onChange,
    disabled = false,
    isEdited = false,
    information = "",
    informationAlignType = null,
    widthFull = false,
}) => {
    return (
        <ItemInputBox className={`${widthFull && "w-full mx-0"}`}>
            <ItemInputLabelBox>
                <div className="flex h-full items-center">
                    <ItemInputLabel>{label}</ItemInputLabel>
                    {!Utils.isStringNullOrEmpty(information) && (
                        <InformationHover alignType={informationAlignType}>
                            <ToolTipsTexts style={{ fontFamily: FONT_FAMILY }}>{information}</ToolTipsTexts>
                        </InformationHover>
                    )}
                </div>
                {isEdited && <EditedIcon />}
            </ItemInputLabelBox>
            {disabled ? (
                <InputText type={"text"} value={names[value]} disabled={true} />
            ) : (
                <InputSelectBox>
                    <InputSelect key={value} value={value} onChange={onChange}>
                        {optionKeys.map((optionKey, i) => (
                            <option key={optionKey} value={optionKey}>
                                {names[i]}
                            </option>
                        ))}
                    </InputSelect>
                </InputSelectBox>
            )}
        </ItemInputBox>
    )
}

// 아이템 수정 모달에서만 사용
export const BrandItemEditor = ({ label, value, setValue, disabled, originValue, message = "" }) => {
    const [isEdited, setEdited] = useState(false)
    const [isFocused, setFocus] = useState(false)

    useEffect(() => {
        if (!isFocused && !Utils.isStringNullOrEmpty(value) && !Utils.isStringNullOrEmpty(originValue)) {
            setEdited(!FileUtils.equalsContent(value, originValue))
        }
    }, [isFocused, value, originValue])

    return (
        <ItemInputBox className="w-full h-full absolute mx-0">
            <ItemInputLabelBox>
                <ItemInputLabel>{label}</ItemInputLabel>
                <div className="flex items-center">
                    <ItemEditorMessage>{message}</ItemEditorMessage>
                    {isEdited && <EditedIcon />}
                </div>
            </ItemInputLabelBox>
            <div className="flex w-full h-full relative">
                {disabled ? (
                    <div className="absolute w-full h-full p-3 rounded-lg bg-gray-50 border overflow-y-auto scroll-transparent">
                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: value }}></div>
                    </div>
                ) : (
                    <div className="absolute w-full h-full flex max-md:pb-2">
                        <CKEditor value={value} setValue={setValue} setFocus={setFocus} />
                        {/* <WebEditor value={value} setValue={setValue} setFocus={setFocus} /> */}
                    </div>
                )}
                {/* {(!Utils.isStringNullOrEmpty(message) && !disabled) && (
                    <ItemEditorMessageContainer>
                        <ItemEditorMessage>
                            {message}
                        </ItemEditorMessage>
                    </ItemEditorMessageContainer>
                )} */}
            </div>
        </ItemInputBox>
    )
}

export const BrandItemFile = ({
    label,
    fileID,
    onChange,
    disabled = false,
    isEdited = false,
    editStateType = EditStateTypes.WAIT,
}) => {
    const [imageFile, setImageFile] = useState(new BrandItem())
    const [refMouseHover, isMouseHover] = useMouseHover()

    useEffect(() => {
        setImageFile(new BrandItem())
        if (fileID >= 0) {
            getImageFile(fileID).then((response) => {
                setImageFile(response)
            })
        }
    }, [fileID])

    return (
        <ItemInputBox>
            <ItemThumbNailLabelContainer>
                <div className="flex items-center">
                    <ItemInputLabel>{label}</ItemInputLabel>
                    <UploadRule>※가로:600px 세로:400px 사이즈로 업로드</UploadRule>
                </div>

                {isEdited && <EditedIcon />}
            </ItemThumbNailLabelContainer>
            <div className="w-full h-full flex flex-col flex-center mt-8">
                <ThubmNailPrevContainer>
                    {imageFile.id >= 0 ? (
                        <>
                            <ImageBase64 imageFile={imageFile} />
                        </>
                    ) : (
                        <ThumbNailComment>썸네일을 업로드 해주시기 바랍니다.</ThumbNailComment>
                    )}
                </ThubmNailPrevContainer>
                <div>
                    {/* <FileNameContainer>
                        <span className="overflow-hidden mr-2">파일 이름:</span>
                        <span>{imageFile.name}</span>
                    </FileNameContainer> */}
                </div>
                {!disabled && (
                    <UploadButtonContainner>
                        <InputFileButton for={`input-file-${label}`}>사진 업로드</InputFileButton>
                        <InputFile
                            className="hidden"
                            id={`input-file-${label}`}
                            style={{ width: 0 }}
                            type="file"
                            onChange={(e) => {
                                onChange(e)
                            }}
                        />
                    </UploadButtonContainner>
                )}
            </div>
        </ItemInputBox>
    )
}

export const BrandItemImage = ({
    label,
    fileID,
    onChange,
    disabled = false,
    isEdited = false,
    editStateType = EditStateTypes.WAIT,
    imageWidth,
    imageHeight,
}) => {
    const [imageFile, setImageFile] = useState(new BrandItem())
    const [refMouseHover, isMouseHover] = useMouseHover()

    useEffect(() => {
        setImageFile(new BrandItem())
        if (fileID >= 0) {
            getImageFile(fileID).then((response) => {
                setImageFile(response)
            })
        }
    }, [fileID])

    return (
        <ItemInputBox>
            <ItemThumbNailLabelContainer>
                <div className="flex items-center">
                    <ItemInputLabel>{label}</ItemInputLabel>
                    <UploadRule>
                        ※가로:{imageWidth}px 세로:{imageHeight}px 사이즈로 업로드
                    </UploadRule>
                </div>

                {isEdited && <EditedIcon />}
            </ItemThumbNailLabelContainer>
            <div className="w-full h-full flex flex-col flex-center mt-8">
                <div className={`aspect-[${imageWidth}/${imageHeight}] w-full border border-dashed border-gray-500`}>
                    {imageFile.id >= 0 ? (
                        <>
                            <ImageBase64 imageFile={imageFile} />
                        </>
                    ) : (
                        <ThumbNailComment>썸네일을 업로드 해주시기 바랍니다.</ThumbNailComment>
                    )}
                </div>
                <div>
                    {/* <FileNameContainer>
                        <span className="overflow-hidden mr-2">파일 이름:</span>
                        <span>{imageFile.name}</span>
                    </FileNameContainer> */}
                </div>
                {!disabled && (
                    <UploadButtonContainner>
                        <InputFileButton for={`input-file-${label}`}>사진 업로드</InputFileButton>
                        <InputFile
                            className="hidden"
                            id={`input-file-${label}`}
                            style={{ width: 0 }}
                            type="file"
                            onChange={(e) => {
                                onChange(e)
                            }}
                        />
                    </UploadButtonContainner>
                )}
            </div>
        </ItemInputBox>
    )
}

export const BrandItemSigunguInput = ({
    label,
    data,
    setData,
    onKeyPress,
    disabled = false,
    widthFull = false,
    isEdited = false,
    information = "",
    informationAlignType = null,
    maxLength = undefined,
}) => {
    const [sigungus, setSigungus] = useState([])
    const [title, setTitle] = useState("")

    const [isModalShow, setModalShow] = useState(false)

    useEffect(() => {
        getSigunguDatas().then((response) => {
            if (!Array.isArray(response)) {
                return
            }

            let _sigungus = []
            response.map((sigunguData) => {
                const _sigungu = new Sigungu()
                _sigungu.convertByResponse(sigunguData)
                _sigungus.push(_sigungu)
            })

            setSigungus(_sigungus)
        })
    }, [])

    useEffect(() => {
        setTitle(data.title())
    }, [data])

    const updateValue = (sigungu) => {
        setTitle(sigungu.title())
        setData(sigungu)
    }

    return (
        <ItemInputBox className={`${widthFull && "w-full mx-0"}`}>
            <ItemInputLabelBox>
                <div className="flex h-full items-center">
                    <ItemInputLabel>{label}</ItemInputLabel>
                    {!Utils.isStringNullOrEmpty(information) && (
                        <InformationHover alignType={informationAlignType}>
                            <ToolTipsTexts style={{ fontFamily: FONT_FAMILY }}>{information}</ToolTipsTexts>
                        </InformationHover>
                    )}
                </div>
                {isEdited && <EditedIcon />}
            </ItemInputLabelBox>
            <InputBox
                type="text"
                onClick={() => {
                    if (disabled) {
                        return
                    }
                    setModalShow(true)
                }}
                className={`${disabled && "bg-gray-100"} flex items-center`}
            >
                {title}
            </InputBox>

            <ModalContainer show={isModalShow} setShow={setModalShow}>
                <SigunguModal
                    title="지역 선택"
                    sigunguAll={sigungus}
                    selectSigungu={updateValue}
                    selectedSigungu={data}
                    isShow={isModalShow}
                    setShow={setModalShow}
                />
            </ModalContainer>
        </ItemInputBox>
    )
}

export const ItemSearchCombo = ({ label, optionKeys, options, value, setValue, width = "auto" }) => {
    const optionListRef = useRef(null)
    const [optionIsOpen, optionRef, optionHandler] = useDropdownDetectClose()

    useEffect(() => {
        if (!Utils.isNullOrUndefined(optionListRef.current)) {
            optionListRef.current.scrollTo(0, 0)
        }
    }, [optionKeys])

    const handleOption = (optionKey) => {
        setValue(optionKey)
    }

    return (
        <div
            onClick={optionHandler}
            className={`relative flex flex-shrink-0 justify-between items-center w-${width} px-2 rounded-lg bg-white hover:bg-slate-200 border border-slate-200 text-slate-700 cursor-pointer`}
        >
            <div ref={optionRef} className="flex items-center w-full max-md:text-sm">
                {Utils.isStringNullOrEmpty(value) ? label : options[value]}

                <CircleChevronDown className="ml-auto h-4 aspect-square text-slate-500" />
            </div>
            <div
                ref={optionListRef}
                className={`absolute top-9 left-0 ${
                    optionIsOpen ? "z-30 visible" : "-z-10 invisible"
                } flex flex-col w-full max-h-[12rem] bg-white rounded-lg overflow-y-auto divide-y divide-slate-200 border border-slate-200`}
            >
                {optionKeys.map((optionKey, index) => (
                    <button
                        key={index}
                        className="flex items-center p-2 text-slate-700 text-sm bg-white hover:bg-slate-100"
                        onClick={() => {
                            handleOption(optionKey)
                        }}
                    >
                        {options[optionKey]}
                    </button>
                ))}
            </div>
        </div>
    )
}

export const ItemSearchSigungu = ({ label, sigungus, value, setValue, width = "auto" }) => {
    const [optionShow, setOptionShow] = useState(true)
    const [optionIsOpen, optionRef, optionHandler] = useDropdownDetectClose()

    const handleOption = (optionKey) => {
        setValue(optionKey)
    }

    return (
        <div
            onClick={optionHandler}
            className={`relative flex flex-shrink-0 justify-between items-center w-${width} px-2 rounded-lg bg-white hover:bg-slate-200 border border-slate-200 text-slate-700 cursor-pointer`}
        >
            <div ref={optionRef} className="flex items-center w-full">
                {label}

                <CircleChevronDown className="ml-auto h-4 aspect-square text-slate-500" />
            </div>
            <div
                className={`absolute top-9 left-0 ${
                    optionIsOpen ? "z-30 visible" : "-z-10 invisible"
                } flex flex-col w-full max-h-[12rem] bg-white rounded-lg overflow-y-auto divide-y divide-slate-200 drop-shadow`}
            >
                {sigungus.map((sigungu, index) => (
                    <button
                        key={index}
                        className="flex items-center p-2 text-slate-700 text-sm hover:bg-slate-100"
                        onClick={() => {
                            handleOption(sigungu)
                        }}
                    >
                        {sigungu.subtitle()}
                    </button>
                ))}
            </div>
        </div>
    )
}
