import ConstCookie from "../../../constants/ConstCookie"
import ImageFile from "../../../types/files/ImageFile"
import User from "../../../types/users/User"
import { setCookie } from "../../../utils/Cookie"
import { updateSmarangSign as updateSmarangSignApi } from "../../api/commons"
import { uploadSign } from "../../api/files"

export const convertTypes = (typeList) => {
    let ordered = typeList.sort((a, b) => Number(a.index) - Number(b.index))
    let types = {}

    ordered.map((type) => {
        types[type.id] = type.name
    })

    return types
}

export const updateSmarangSign = async (signImage) => {
    let image = new ImageFile()

    // 1. 사인 이미지 업로드
    const resultSign = await uploadSign(signImage)
    if (!resultSign) {
        return image
    }

    // 2. 사인 확정
    const resultConfirm = await updateSmarangSignApi(resultSign.id)
    if (!resultConfirm) {
        return image
    }
    image = resultSign

    return image
}
