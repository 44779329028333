import { Cookies } from "react-cookie"

const cookies = new Cookies()

export const setCookie = (name, value, httpOnly=false, option) => {
    let defaultOption = {
        path: "/",
        httpOnly: false
    }

    if (process.env.NODE_ENV === "production") {
        defaultOption = {
            ...defaultOption,
            ...option,
            secure: true,
            sameSite: "none",
        }
    }
    
    return cookies.set(name, value, defaultOption)
}

export const getCookie = (name) => {
    return cookies.get(name)
}

export const removeCookie = (name) => {
    cookies.remove(name, {path:"/"})
}