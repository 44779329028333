import React, { useEffect, useRef, useState } from "react"
import { SignUpRecommanderModalLayout } from "./SignUpStyle"
import { useInView } from "react-intersection-observer"
import { getRecommanderUsers } from "../../apis/api/users"
import { FilterInputSearchButton, FilterInputSearchIcon } from "../../components/brandItems/FilterInputsStyle"
import Utils from "../../utils/Utils"

const PAGE_SIZE = 2

export default function SignUpRecommanderUserModal({
    recommanderKeyword,
    setRecommanderKeyword,
    recommanderID,
    setRecommanderID,
    setShow,
}) {
    const [search, setSearch] = useState("")
    const [marketers, setMarketers] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()

    const [isSearched, setSearched] = useState(false) // 한번이라도 검색했는지 여부

    const searchRef = useRef(null)

    useEffect(() => {
        if (searchRef.current !== null) {
            searchRef.current.focus()
        }
    }, [])

    useEffect(() => {
        getRecommanders()
    }, [pageIndex])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && marketers.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            setPageIndex(pageIndex + 1)
        }
    }, [scrollInView])

    const getRecommanders = async () => {
        if (Utils.isStringNullOrEmpty(search)) {
            setMarketers([])
            setPageIndex(1)
            setMaxPage(1)
            return
        }

        const pagination = await getRecommanderUsers(pageIndex, PAGE_SIZE, search)

        if (pagination.count > 0) {
            if (pageIndex === 1) {
                setMarketers(pagination.items)
                setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
            } else {
                setMarketers([...marketers, ...pagination.items])
            }
        } else {
            setMarketers([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        setSearched(true)

        if (pageIndex === 1) {
            getRecommanders()
        } else {
            setPageIndex(1)
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSelect = (marketer) => {
        setRecommanderID(marketer.user.id)
        setRecommanderKeyword(marketer.user.getNickname())
        setShow(false)
        if (marketer.user.id === recommanderID) {
            setShow(false)
        }
    }

    return (
        <SignUpRecommanderModalLayout>
            <span className="text-xl font-semibold">추천인 검색</span>
            <span className="text-slate-600 mt-4 ml-1">추천인을 입력하세요.</span>
            <div className="flex justify-between items-center w-full p-2 bg-slate-100">
                <input
                    ref={searchRef}
                    type="text"
                    className="w-full px-2 focus:outline-none disabled:bg-gray-100 bg-transparent"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    onKeyPress={handleEnter}
                />
                <FilterInputSearchButton
                    className="max-lg:h-7 lg:h-7 px-2"
                    onClick={() => {
                        handleSearch()
                    }}
                >
                    <FilterInputSearchIcon />
                </FilterInputSearchButton>
            </div>
            <div className="flex flex-col w-full py-4 mt-4 space-y-2 bg-white border-t overflow-y-auto scroll-transparent scroll-overlay">
                {marketers.map((marketer, index) => (
                    <div
                        key={index}
                        ref={index !== 0 ? scrollRef : null}
                        className={`flex items-center w-full h-9 p-2 ${
                            marketer.user.id === recommanderID ? "bg-blue-100" : "hover:bg-slate-100"
                        } cursor-pointer space-x-2`}
                        onClick={() => {
                            handleSelect(marketer)
                        }}
                    >
                        <span>{marketer.user.name}</span>
                        {/* <span className="text-slate-500">{marketer.user.email}</span> */}
                        <span className="text-slate-500">{marketer.user.tel}</span>
                    </div>
                ))}
                {marketers.length === 0 && isSearched && (
                    <div className="flex flex-center w-full h-36 text-slate-600">
                        <span>검색 결과가 없습니다.</span>
                    </div>
                )}
            </div>
        </SignUpRecommanderModalLayout>
    )
}
