import { useEffect, useState, useRef } from "react";
import Utils from "../utils/Utils";

const equalsParentElement = (element, target) => {
    if (element.isEqualNode(target)) {
        return true
    }

    const parent = element.parentElement

    if (Utils.isNullOrUndefined(parent)) {
        return false
    }

    // isEqualNode 및 contains가 작동하지않은 경우가 있으므로 임시로 className만 비교한다 (ex: Calendar)
    try {
        if (parent.className === target.className) {
            return true
        }
    } catch {
        //
    }

    return equalsParentElement(parent, target)
}

const refsContains = (refs, target) => {
    if (refs.length === 0) {
        return false
    }

    for (let i=0; i<refs.length; i++) {
        if (equalsParentElement(target, refs[i].current)) {
            return true
        }
    }
    return false
}

const useDropdownDetectClose = (initialState, containRefs=[]) => {
    // containsRef는 className으로만 비교하기 때문에 주의!
    const [isOpen, setIsOpen] = useState(initialState)
    const ref = useRef(null)

    useEffect(() => {
        const onClick = (e) => {
            if (ref.current !== null && (!refsContains(containRefs, e.target) && !ref.current.contains(e.target))) {
                setIsOpen(!isOpen)
            }
        }

        if (isOpen) {
            window.addEventListener("click", onClick)
        }

        return () => {
            window.removeEventListener("click", onClick)
        }
    }, [isOpen])

    const removeHandler = (open=null) => {
        if (open === null) {
            setIsOpen(!isOpen)
        } else {
            setIsOpen(open)
        }
    }

    return [isOpen, ref, removeHandler]
}

export default useDropdownDetectClose