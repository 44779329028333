import tw from 'tailwind-styled-components';

export const TabletitleCon = tw.div`
    border-b-2 border-black h-16 flex
`
export const TableRowCon = tw.div`
    h-fit
`
export const TableRow = tw.div`
    h-14 border-b flex cursor-pointer bg-white hover:bg-gray-100
`
export const Tabletd = tw.div`
    h-full flex items-center justify-center
`
export const TableTitle = tw.div`
    flex items-center justify-center font-medium text-lg
`

export const SelectButton = tw.button`
    py-1 rounded-md text-white w-[130px] mr-4
`

export const ContractWriter = tw.div`
w-full py-20 flex justify-center
`

export const ContractWriterContaienr = tw.div`
w-128 border bg-white rounded-md flex flex-col justify-around px-10 py-8
`

export const ContractWriterTitleContainer = tw.div`
w-full h-10 mb-6
`

export const ContractTitle = tw.span`
text-2xl font-bold
`
export const ContractWriterButtonContainer = tw.div`
w-full mb-6 py-2 border rounded-md px-4 flex items-center justify-between
`

export const ContractWriterButtonTitle= tw.span`
text-lg font-semibold
`
export const ContractWriterButton = tw.button`
px-4 py-1 bg-blue-400 text-white rounded-md
`