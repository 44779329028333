import tw from "tailwind-styled-components"

export const ItemInputFromLayout = tw.div`
    w-full h-full flex flex-col
    stack-overlay
`

export const ItemInputListCol = tw.div`
absolute
flex flex-col w-full h-full pt-2
overflow-y-auto scroll-transparent scroll-overlay
${(props) => (props.isActive ? "opacity-100 z-10" : "opacity-0 -z-10")}
`
export const ItemInputRow = tw.div`
    grid max-md:grid-cols-1 md:grid-cols-3 max-md:gap-y-4 md:gap-x-4 w-full my-3 pb-8 border-gray-100 border-b
`
export const ItemInputRowEditor = tw.div`
    relative
    w-full h-full min-h-[24rem]
`

export const ItemInputBox = tw.div`
    flex flex-col justify-center w-full max-sm:mb-4
`

export const ItemInputLabelBox = tw.div`
    flex justify-between items-center w-full flex-shrink-0
`

export const ItemInputLabel = tw.label`
    flex-shrink-0 font-medium max-sm:text-sm sm:text-base p-1
`

export const BottomButtonCon = tw.div`
    w-full flex items-center justify-between p-2
`

export const DeleteButton = tw.button`
    h-8 px-2 bg-white hover:bg-gray-100
    text-red-500
    rounded
`

export const ItemEditNotificationContainer = tw.div`
    flex-col flex mb-3 pl-2
`

export const ItemEditNotification = tw.span`
    max-md:text-xs md:text-sm text-red-400 ${(props) => (props.isShow === true ? "visible" : "hidden")}
`

const ItemWriteButton = tw.button`
    flex flex-center rounded-full bg-gray-400 text-white hover:bg-gray-500 max-sm:px-2 sm:px-4 py-0.5 ml-2
`

export const SaveButton = tw(ItemWriteButton)`
    bg-nightblue-400 hover:bg-nightblue-500
    disabled:bg-gray-400 disabled:hover:bg-gray-400
`

export const PreviewButton = tw(ItemWriteButton)`
    bg-nightblue-400 hover:bg-nightblue-500
    disabled:bg-gray-400 disabled:hover:bg-gray-400
`

export const ResetButton = tw(ItemWriteButton)`
    bg-gray-500 hover:bg-gray-600
`

export const AcceptButton = tw(ItemWriteButton)`
    bg-green-600 hover:bg-green-700
    disabled:bg-gray-500 disabled:hover:bg-gray-500
`

export const ItemControlBox = tw.div`
    flex max-sm:flex-col sm:flex-row justify-between items-center w-full sm:h-10 px-4 py-2
    rounded-md bg-gray-150
`

export const ItemStatusControlBox = tw.div`
    flex items-center
`

export const ItemThumbNailLabelContainer = tw.div`
    w-full flex justify-between
`

export const ThubmNailPrevContainer = tw.div`
    max-sm:w-full max-sm:aspect-[3/2] sm:w-[600px] sm:h-[400px] border border-dashed border-gray-500
`

export const ThumbNailComment = tw.div`
    h-full w-full flex flex-center text-gray-500
`

export const FileNameContainer = tw.div`
    w-full flex text-lg items-center mt-6
`

export const UploadButtonContainner = tw.div`
    flex flex-col items-center justify-center mt-8 flex-grow
`

export const UploadRule = tw.span`
    top-0 ml-1
    text-nightblue-400 text-sm 
`

export const EditAcceptContainer = tw.div`
    flex relative
`
export const EditAcceptMenuContainer = tw.div`
    top-[2rem] right-0 absolute
    flex
    ${(props) => (props.acceptMenuIsOpen ? "opacity-100 z-20" : "opacity-0 -z-10")}
`
export const EditAcceptMenuBox = tw.div`
    flex flex-col p-2 z-20 w-28
    rounded-lg bg-white drop-shadow-md
`
export const EditAcceptButtonBase = tw.button`
    flex flex-center w-full px-4 py-1
`
export const EditAcceptPreviewButton = tw(EditAcceptButtonBase)`
    mb-2
    rounded-md bg-nightblue-600 hover:bg-nightblue-500
    text-white
`
export const EditAcceptButton = tw(EditAcceptButtonBase)`
    text-nightblue-700
`
