import React, { useEffect, useState, useRef } from "react";
import tw from "tailwind-styled-components"
import { updateItemPerformValue } from "../../../apis/api/boardItems";
import { ModalContainer } from "../../../components/ModalContainer";

import { PerformColumnPreviewTypes, PerformStaticColumnTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import { EditStateTypes } from "../../../types/EditorTypes";
import Utils from "../../../utils/Utils";
import { SaveButton } from "../item/brand/ItemInputFormStyle";
import { HistoryIcon, PerformDateCalc, PerformDateCalcContainer, PerformHistoryContainer, PerformPreviewButton, PerformTextBox, PerformTextCalculateViewButton, PerformTextDetailButton, PerformTextDetailIcon, PerformValueBox, PerformValueInputLayout, PerformValueInputTitle } from "./MyPagePerformStyle";


export const PerformValueFixedDateCell = ({ column, value, openCalcListModal }) => {
    const handleClick = () => {
        if (column.id === PerformStaticColumnTypes.CALCULATED) {
            openCalcListModal(value.performID)
        }
    }

    return (
        <PerformValueBox>
            <div className="flex justify-between items-center w-full group">
                <PerformTextBox isButtonCell={column.id === PerformStaticColumnTypes.CALCULATED} onClick={() => { handleClick() }}>
                    {column.id === PerformStaticColumnTypes.CALCULATED ?
                        <React.Fragment>
                            {value.text === "-" ?
                                <PerformDateCalc>{Utils.isStringNullOrEmpty(value.text) ? "-" : value.text}</PerformDateCalc>
                                :
                                <PerformDateCalcContainer>
                                    <PerformHistoryContainer><HistoryIcon /></PerformHistoryContainer>
                                    <PerformDateCalc>{Utils.isStringNullOrEmpty(value.text) ? "-" : value.text}</PerformDateCalc>
                                </PerformDateCalcContainer>
                            }
                        </React.Fragment>
                        :
                        <PerformDateCalc>{Utils.isStringNullOrEmpty(value.text) ? "-" : value.text}</PerformDateCalc>
                    }
                </PerformTextBox>
            </div>
        </PerformValueBox>
    )
}