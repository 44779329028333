import React, { useEffect } from "react"
// import { useState,useRef,useEffect } from 'react'
import tw from "tailwind-styled-components"
import { BoxMaxMin } from "../../layouts/Box"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { Link } from "react-router-dom"

export const HomeContainer = tw.div`
    flex flex-col w-full items-center
`
export const HomeContentsContainer = tw(BoxMaxMin)`
    flex flex-col w-full max-xl:px-2
`

export const HomeNavSection = tw.div`
    w-full max-lg:h-8 lg:h-16 bg-white
`
export const HomeSection = tw.div`
    flex flex-col items-center w-full max-sm:my-6 max-md:py-2 md:py-10
`
export const HomeSectionTitleBox = tw.div`
    flex w-full justify-between items-center mb-4
`
export const HomeSectionTitle = tw.span`
    flex max-sm:flex-col items-center max-md:text-xl md:text-2xl font-medium
`

// 최신 아이템
export const HomeSectionTitleLink = tw(Link)`
    px-4 py-2 -translate-x-4
    rounded-full 
    hover:bg-slate-200 duration-200
`
export const HomeItemsMoreButton = tw.button`
    flex items-center
    max-md:text-base md:text-lg text-nightblue-500 font-light hover:text-nightblue-700
`
export const HomeItemsList = tw.div`
    grid max-md:grid-cols-1 md:grid-cols-5 max-sm:gap-2 sm:gap-4
    w-full
`

// 주소 필터 박스
export const HomeFilterAddressBox = tw.div`
    grid grid-cols-6 flex-wrap flex-shrink-0 items-center h-20 p-2 mb-4 ml-2
    rounded bg-slate-50 border border-slate-300
    [&>button]:px-1.5 [&>button]:py-0.5 [&>button]:rounded 
    hover:[&>button.sido]:bg-slate-200 [&>button.sido]:duration-200
    [&>button]:text-xs [&>button.sido]:text-slate-600 [&>button.sido.all]:!text-nightblue-500
`

// 아이템 카테고리
export const HomeCategoryBox = tw.div`
    flex flex-col w-full h-full
    max-md:p-3 max-md:bg-slate-100 max-md:rounded-xl
`
export const HomeCategoryListContainer = tw.div`
    flex w-full h-full
    overflow-x-auto scroll-transparent scroll-overlay
`
export const HomeCategoryList = tw.div`
    grid grid-cols-5 gap-4
    max-md:min-w-[64rem] md:min-w-[1200px] max-md:h-56 md:h-64
`

// 아이템 소개 영상
export const HomeSmarangIntroduceBox = tw.div`
    flex max-md:flex-col-reverse md:flex-row
    w-full h-full bg-slate-100 max-sm:p-3 sm:p-2 rounded-xl
`
export const HomeSmarangIntroduceVideo = tw.iframe`
    max-md:w-full md:w-1/2 aspect-video flex-shrink-0 rounded-lg
    drop-shadow-lg
`
export const HomeSmarangIntroduceDescription = tw.div`
    flex flex-col justify-center w-full max-md:h-full md:flex-1 max-md:mb-6 md:ml-8
`
export const HomeSmarangIntroduceDescriptionHead = tw.span`
    max-md:text-xl md:text-2xl lg:text-2xl xl:text-3xl font-semibold
    text-nightblue-800
`
export const HomeSmarangIntroduceDescriptionParagraph = tw.pre`
    mt-4 max-md:text-sm md:text-base lg:text-lg xl:text-xl
    text-yellow-600
`

// 광고 영역
export const HomeAdvertiseSection = tw(BoxMaxMin)`
    flex max-md:flex-col items-center w-full
`
export const HomeAdvertiseSectionItem = tw.div`
    items-center w-full
    lg:rounded-lg bg-slate-200 overflow-hidden
`

// 아이템 검색
export const HomeItemSearchBox = tw.div`
    max-sm:hidden sm:flex items-center h-8 ml-2
    rounded-full border border-nightblue-700
    text-slate-700
`
export const HomeItemSearchInput = tw.input`
    pl-4 w-48 h-full bg-transparent
    text-sm text-nightblue-800 font-light
`
export const HomeItemSearchButton = tw.div`
    pr-3 w-10 h-full
`
export const HomeItemSearchIcon = tw(Search)`
    h-full p-1 -translate-y-0.75
    text-nightblue-700
`

// 홍보 아이템
export const HomeAdLevel1TableHead = tw.div`

`

// 추천 카테고리
export const HomeRecommandCategoryBox = tw.div`
    flex items-center w-full mb-4 space-x-2 overflow-x-auto scroll-transparent
    [&>.category]:flex-shrink-0 [&>.category]:flex-center [&>.category]:px-3 [&>.category]:py-1
    [&>.category]:rounded-full [&>.category]:bg-slate-50 hover:[&>.category]:bg-slate-100
    [&>.category]:border [&>.category]:border-slate-300
    [&>.category]:text-sm [&>.category]:text-slate-500
    [&>.category]:duration-200 [&>.category]:cursor-pointer
`
