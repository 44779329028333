import tw from "tailwind-styled-components"
import styled from "styled-components"
import { Check } from "@styled-icons/boxicons-regular/Check"
import { CircleCheck } from "@styled-icons/fa-regular/CircleCheck"

export const CheckIcon = tw(Check)`
    h-full
`
export const CircleCheckIcon = tw(CircleCheck)`
    h-full 
`

export const SignUpAgreementContainer = tw.div`
    flex flex-col items-center h-full w-full py-3 mt-8 mb-32
    rounded-md
    
`

export const SignUpAgreementAllContainer = tw.div`
    flex w-full mb-8
`

export const SignUpAgreementEachContainer = tw.div`
    flex flex-col w-full space-y-4
`
export const SignUpAgreementEachTextBoxLayout = tw.div`
    flex items-center justify-between w-full px-5 py-4
    font-semibold
    rounded-md
    ${(props) => (props.isSelected ? "bg-blue-50" : "bg-slate-50 hover:bg-slate-100")}
    ${(props) => (props.isSelected ? "text-nightblue-800" : "text-slate-400")}
    cursor-pointer duration-200
    select-none
`
export const SignUpAgreementAllTextLayout = tw(SignUpAgreementEachTextBoxLayout)`
    justify-normal
    ${(props) => (props.isSelected ? "bg-blue-100" : "bg-slate-100 hover:bg-slate-200")}
    ${(props) => (props.isSelected ? "text-nightblue-800" : "text-slate-400")}
`

export const SignUpAgreementEachTextLayout = tw.div`
    h-full flex-1 flex items-center cursor-pointer group
`
export const SignUpAgreementViewButtonLayout = tw.div`
    h-full w-10 flex items-center justify-end cursor-pointer group
    text-gray-700 hover:text-gray-900
`

export const CheckIconContainer = tw.div`
    flex flex-center max-md:h-5 md:h-7 aspect-square border-2 rounded-lg mr-2 text-transparent
    ${(props) => (props.isSelected ? "border-sky-700 text-sky-600" : "border-slate-400")}
`

const TextBalance = styled.span`
    text-wrap: balance;
`

export const AgreementText = tw(TextBalance)`
    max-md:text-md md:text-lg
`

export const SignUpAgreementModalContainer = tw.div`
    flex  flex-col max-lg:w-screen max-lg:h-[70vh] lg:w-[1200px] lg:h-156 bg-white rounded-md overflow-hidden
`
export const SignUpAgreementModalTitleContainer = tw.div`
    flex items-center text-lg font-semibold py-3.5 px-8  w-full border-b
`

export const SignUpAgreementModalDetailContainer = tw.div`
    flex-grow  py-6 w-full  overflow-scroll scroll-transparent scroll-overlay px-6
`
export const SignUpAgreementModalDetail = tw.textarea`
    w-full h-full scroll-transparent scroll-overlay outline-none resize-none px-6 
`
