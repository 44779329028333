import PointSet from "../../../types/points/PointSet"
import Utils from "../../../utils/Utils"
import { authInstance, credentialInstance, defaultInstance, giftiInstance } from "../../utils"
import PointHistory from "../../../types/points/PointHistory"
import PointHistoryList from "../../../types/points/PointHistoryList"
import Coupon from "../../../types/points/Coupon"
import CouponList from "../../../types/points/CouponList"
import CouponApplication from "../../../types/points/CouponApplication"
import CouponApplicationList from "../../../types/points/CouponApplicationList"
import CouponItem from "../../../types/points/CouponItem"
import CouponItemList from "../../../types/points/CouponItemList"
import GiftiCouponList from "../../../types/points/GiftiCouponList"
import GiftiCouponThumbList from "../../../types/points/GiftiCouponThumbList"
import GiftiCoupon from "../../../types/points/GiftiCoupon"
import GiftiCouponOrder from "../../../types/points/GiftiCouponOrder"
import GiftiCouponTrade from "../../../types/points/GiftiCouponTrade"

const CryptoJS = require("crypto-js")

function encryptData(data, key) {
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.toString()
}

function base64Encode(data) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
}

export const getPointSet = async () => {
    const pointSet = new PointSet()

    await authInstance
        .get("/api/points/pointset/")
        .then((response) => {
            pointSet.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return pointSet
}
export const getPointInfo = async (userID = null) => {
    let data = {}

    let request = {}
    if (userID !== null) {
        request["user_id"] = userID
    }

    await authInstance
        .post("/api/points/point_info/", request)
        .then((response) => {
            data = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return data
}
export const updatePointSet = async (data) => {
    const pointSet = new PointSet()

    await authInstance
        .put("/api/points/pointset/", data)
        .then((response) => {
            pointSet.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return pointSet
}

export const getPointHistories = async (pageIndex = 1, pageSize = 1, search = "", userID = null) => {
    const list = new PointHistoryList()

    let params = {
        page: pageIndex,
        page_size: pageSize,
        search: search,
    }
    if (!Utils.isNullOrUndefined(userID)) {
        params["user_id"] = userID
    }

    await authInstance
        .get("/api/points/point_history/", { params: params })
        .then((response) => {
            list.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return list
}
export const giveManualPoint = async (data) => {
    const pointHistory = new PointHistory()

    await authInstance
        .post("/api/points/pointset/", data)
        .then((response) => {
            pointHistory.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return pointHistory
}

export const getCoupons = async (search = "") => {
    const list = new CouponList()

    await authInstance
        .get("/api/points/coupons/", {
            params: {
                search: search,
            },
        })
        .then((response) => {
            list.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return list
}
export const getCoupon = async (couponID) => {
    const coupon = new Coupon()

    await authInstance
        .get(`/api/points/coupon/${couponID}/`)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return coupon
}
export const createCoupon = async (data) => {
    const coupon = new Coupon()

    await authInstance
        .post("/api/points/coupons/", data)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return coupon
}
export const updateCoupon = async (couponID, data) => {
    const coupon = new Coupon()

    await authInstance
        .put(`/api/points/coupon/${couponID}/`, data)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return coupon
}
export const deleteCoupon = async (couponID) => {
    let bResult = false

    await authInstance
        .delete(`/api/points/coupon/${couponID}/`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getCouponApplications = async (
    pageIndex = 1,
    pageSize = 1,
    search = "",
    userID = null,
    couponID = null,
) => {
    const list = new CouponApplicationList()

    let params = {
        page: pageIndex,
        page_size: pageSize,
        search: search,
    }
    if (!Utils.isStringNullOrEmpty(userID)) {
        params["user_id"] = userID
    }
    if (!Utils.isStringNullOrEmpty(couponID)) {
        params["coupon_id"] = couponID
    }

    await authInstance
        .get("/api/points/coupon_applications/", { params: params })
        .then((response) => {
            list.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return list
}
export const getCouponApplication = async (couponApplicationID) => {
    const coupon = new CouponApplication()

    await authInstance
        .get(`/api/points/coupon_application/${couponApplicationID}/`)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return coupon
}
export const createCouponApplication = async (data) => {
    const coupon = new CouponApplication()
    let errors = []

    await authInstance
        .post("/api/points/coupon_applications/", data)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
            try {
                if (Utils.isAvailableErrorData(error)) {
                    errors = Object.values(error.response.data)
                }
            } catch {
                //
            }
        })

    return [coupon, errors]
}
export const updateCouponApplication = async (couponApplicationID, data) => {
    const coupon = new CouponApplication()

    await authInstance
        .put(`/api/points/coupon_application/${couponApplicationID}/`, data)
        .then((response) => {
            coupon.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return coupon
}
export const deleteCouponApplication = async (couponApplicationID) => {
    let bResult = false

    await authInstance
        .delete(`/api/points/coupon_application/${couponApplicationID}/`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {
            console.log(error)
        })

    return bResult
}

export const getCouponItems = async (search = "") => {
    const list = new CouponItemList()

    await authInstance
        .get("/api/points/coupon_items/", {
            params: {
                search: search,
            },
        })
        .then((response) => {
            list.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return list
}
export const getCouponItem = async (couponItemID) => {
    const item = new CouponItem()

    await authInstance
        .get(`/api/points/coupon_item/${couponItemID}/`)
        .then((response) => {
            item.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return item
}

export const getGiftiCouponThumbByGiftiShowOld = async (pageIndex, pageSize, search) => {
    const list = new GiftiCouponThumbList()

    let data = {
        api_code: "0101",
        custom_auth_code: process.env.REACT_APP_GIFTISHOW_AUTH_KEY,
        custom_auth_token: process.env.REACT_APP_GIFTISHOW_TOKEN_KEY,
        dev_yn: "N",
        start: String(pageIndex),
        size: String(pageSize),
    }

    const urlEncodedData = new URLSearchParams(data).toString()
    console.log(urlEncodedData)

    const response = await fetch("https://bizapi.giftishow.com/bizApi/goods", {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "api_code=0101&custom_auth_code=REALa09ea8b84b0943329ecbea97620c9c35&custom_auth_token=/ShkW7npwT4OUjbrmFJHCA%3D%3D&dev_yn=N&start=1&size=20",
    })
    console.log(response)

    // 응답을 JSON 형식으로 파싱
    if (!response.ok) {
        return list
    }

    const responseData = await response.json()
    console.log(responseData)
    const resultCode = responseData["code"]

    if (resultCode !== "0000") {
        return
    }

    let listData = {
        count: Number(responseData["listNum"]) * pageSize,
        items: responseData["goodsList"],
    }
    list.convertByResponse(listData)

    return list
}

export const getGiftiCouponThumbByGiftiShow = async (pageIndex = 1, pageSize = 1, search = "") => {
    const list = new GiftiCouponThumbList()

    let params = {
        page: pageIndex,
        page_size: pageSize,
        search: search,
    }

    await authInstance
        .get("/api/points/gifti_coupons/", { params: params })
        .then((response) => {
            list.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return list
}

export const getGiftiCouponByGiftiShow = async (goodsCode) => {
    const item = new GiftiCoupon()

    await authInstance
        .post("/api/points/gifti_coupon_data/", { goods_code: goodsCode })
        .then((response) => {
            item.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return item
}
export const getGiftiCouponTrade = async (couponApplicationID) => {
    const item = new GiftiCouponTrade()

    await authInstance
        .post("/api/points/gifti_coupon_trade/", { coupon_application_uuid: couponApplicationID })
        .then((response) => {
            item.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return item
}

export const getCouponOrder = async (couponApplicationID) => {
    const item = new GiftiCouponOrder()

    await authInstance
        .post("/api/points/coupon_order/", { coupon_application_uuid: couponApplicationID })
        .then((response) => {
            item.convertByResponse(response.data)
        })
        .catch((error) => {
            console.log(error)
        })

    return item
}

export const requestCouponResend = async (couponApplicationID) => {
    let errorMessage = ""

    await authInstance
        .post("/api/points/coupon_resend/", { coupon_application_uuid: couponApplicationID })
        .then((response) => {
            errorMessage = response.data
        })
        .catch((error) => {
            console.log(error)
            errorMessage = error.response.data
        })

    return errorMessage
}

export const getGiftiBalance = async () => {
    let data = {}

    await authInstance
        .post("/api/points/gifti_coupon_balance/")
        .then((response) => {
            data = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return data
}
export const getGiftiCouponRefresh = async () => {
    let data = {}

    await authInstance
        .post("/api/points/gifti_coupon_refresh/")
        .then((response) => {
            data = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return data
}
