import PerformValue from "./PerformValue";
import { PerformColumnFormTypes } from "./PerformValueTypes";

export default class extends PerformValue{
    constructor() {
        super()
        this.columnID = -1
        this.performID = -1
        this.text = ""

        this.hasDetail = true
    }

    convertByResponse(response) {
        this.columnID = response["column_id"]
        this.performID = response["perform_id"]
        this.text = response["text"]
    }

    convertToRequest() {
    }
}