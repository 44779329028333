import React,{useEffect,useState,useRef} from "react"
import { DateTimeInputLayout, DateTimeUnitBox, DateTimeUnitInput, DateTimeUnitInputBox, DateTimeUnitLabel, DateTimeUnitSection } from "./DateTimeInputStyle"
import Utils from "../../utils/Utils"
import { DateTimeInput } from "./DateTimeInput"

export const DateTimeRangeInput = ({
    startTimeStamp,
    setStartTimeStamp,
    endTimeStamp,
    setEndTimeStamp,
    editYear=true,
    editMonth=true,
    editDay=true,
    editHour=true,
    editMinute=true,
    editSecond=true
}) => {
    return (
        <DateTimeInputLayout>
            <DateTimeInput 
                timeStamp={startTimeStamp}
                setTimeStamp={setStartTimeStamp}
                editYear={editYear}
                editMonth={editMonth}
                editDay={editDay}
                editHour={editHour}
                editMinute={editMinute}
                editSecond={editSecond}
            />
            <span>~</span>
            <DateTimeInput 
                timeStamp={endTimeStamp}
                setTimeStamp={setEndTimeStamp}
                editYear={editYear}
                editMonth={editMonth}
                editDay={editDay}
                editHour={editHour}
                editMinute={editMinute}
                editSecond={editSecond}
            />
        </DateTimeInputLayout>
    )
}