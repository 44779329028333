import React, { useEffect, useState, useRef, useCallback } from "react"
import tw from "tailwind-styled-components"
import { getItemPerformValue } from "../../../apis/api/boardItems"
import { ImageViewer } from "../../../components/ImageViewer"
import PerformValueFile from "../../../types/brandItems/performValues/PerformValueFile"
import { PerformFileValueBox, PerformValueBox } from "./MyPagePerformStyle"

import { FileContainer, FileUpload } from "./PerformValueFileCell"
import {
    FileContentBox,
    FileDeleteIconContainer,
    FileDetailBox,
    FileExpandIcon,
    FileGrid,
    FileGridContainer,
    FileIconBlank,
    FileIconDelete,
    FileIconDownload,
    FileIconImage,
    FileIconImageDownload,
    FileImageBox,
    FileImageContainer,
    FileImageDetailBox,
    FileImageDetailIconContainer,
    FileImageDetailIcons,
    FileInfoBox,
    FileInfoControlBox,
    FileLayout,
    FileNameSpan,
    FileUploadIconContainer,
    FileZeroMessageSpan,
} from "./PerformValueFileStyle"
import AccessRules from "../../../rules/AccessRules"
import { deleteFile, downloadFileZip, getCommonFile } from "../../../apis/api/files"
import CommonFile from "../../../types/files/CommonFile"
import { downloadFile } from "../../../apis/api/files"
import { PerformFileTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { ImageBase64 } from "../../../components/ImageContainer"

const PrePerformFileContainer = ({ fileValue, index, deleteFileValue, handleViewer, readOnly = false }) => {
    const [file, setFile] = useState(new CommonFile())

    useEffect(() => {
        getFile()
    }, [fileValue])

    const getFile = async () => {
        if (fileValue.fileID >= 0) {
            getCommonFile(fileValue.fileID).then((response) => {
                setFile(response)
            })
        }
    }

    const handleDelete = async () => {
        if (confirm("파일을 삭제하시겠습니까?")) {
            const response = await deleteFile(fileValue.fileID)

            if (response) {
                deleteFileValue(fileValue.fileID)
            } else {
                alert("삭제 실패했습니다.")
            }
        }
    }

    const handleDownload = () => {
        downloadFile(file.fileURL, file.name)
    }

    const handleOpenViewer = () => {
        if (handleViewer !== undefined) {
            handleViewer(fileValue.fileID)
        }
    }

    return (
        <FileLayout isTemp={true}>
            <FileInfoBox>
                <FileNameSpan>
                    {index + 1}. {file.name}
                </FileNameSpan>
                <FileInfoControlBox>
                    <FileDeleteIconContainer>
                        <FileIconDelete
                            onClick={() => {
                                handleDelete()
                            }}
                        />
                    </FileDeleteIconContainer>
                </FileInfoControlBox>
            </FileInfoBox>
            <FileContentBox>
                {fileValue.fileType === PerformFileTypes.COMMON && (
                    <FileUploadIconContainer hasFile={file.id >= 0}>
                        {file.id >= 0 ? (
                            <FileIconDownload
                                onClick={() => {
                                    handleDownload()
                                }}
                            />
                        ) : (
                            <FileIconBlank />
                        )}
                    </FileUploadIconContainer>
                )}
                {fileValue.fileType === PerformFileTypes.IMAGE &&
                    (fileValue.fileID >= 0 ? (
                        <FileImageContainer>
                            <FileImageBox>
                                <ImageBase64 imageID={fileValue.fileID} />
                            </FileImageBox>
                            {handleViewer !== undefined && (
                                <FileImageDetailBox>
                                    <FileImageDetailIcons>
                                        <FileImageDetailIconContainer
                                            onClick={() => {
                                                handleOpenViewer()
                                            }}
                                        >
                                            <FileExpandIcon />
                                        </FileImageDetailIconContainer>
                                    </FileImageDetailIcons>
                                </FileImageDetailBox>
                            )}
                        </FileImageContainer>
                    ) : (
                        <FileUploadIconContainer>
                            <FileIconImage />
                        </FileUploadIconContainer>
                    ))}
            </FileContentBox>

            <FileDetailBox>
                <span>{file.user.name}</span>
            </FileDetailBox>
        </FileLayout>
    )
}

export const PerformValueFilePreUpdate = ({
    downLoadZip,
    setDownLoadZip,
    perform,
    performValue,
    showViewerComponent,
    readOnly,
}) => {
    const [fileValues, setFileValues] = useState([])
    const updatedRef = useRef(false)

    useEffect(() => {
        performValue.files = fileValues
    }, [fileValues])

    useEffect(() => {
        if (downLoadZip) {
            handleZipDownload()
        }
    }, [downLoadZip])

    const setUpdated = (b) => {
        updatedRef.current = b
    }

    const handleViewer = (selectedFileID) => {
        const closeViewer = () => {
            showViewerComponent(false)
        }

        showViewerComponent(
            true,
            <ImageViewer selectedFileID={selectedFileID} fileValues={fileValues} closeViewer={closeViewer} />,
        )
    }

    const getFile = async (fileValue) => {
        if (fileValue.fileID >= 0) {
            let response = await getCommonFile(fileValue.fileID)
            return response
        }
    }

    const handleZipDownload = async () => {
        if (fileValues.length === 0) {
            return
        }
        var files = []
        for (let fileValue of fileValues) {
            var file = await getFile(fileValue)
            files.push({ ...file })
        }
        downloadFileZip(files, `${performValue.column.name}_filedown`)
        setDownLoadZip(false)
    }

    const addFileValues = (fileValue) => {
        setFileValues((s) => [...s, fileValue])
    }

    const deleteFileValue = (fileID) => {
        setFileValues((s) => [...s].filter((f) => f.fileID !== fileID))
    }

    const valueReadOnly = readOnly
    // const valueReadOnly = (readOnly || !AccessRules.performValueWrite(column, perform))

    return (
        <PerformFileValueBox>
            {fileValues.length > 0 ? (
                <FileGridContainer>
                    <FileGrid>
                        {fileValues.map((fileValue, idx) => (
                            <PrePerformFileContainer
                                key={idx}
                                fileValue={fileValue}
                                index={idx}
                                deleteFileValue={deleteFileValue}
                                handleViewer={handleViewer}
                                readOnly={valueReadOnly}
                            />
                        ))}
                    </FileGrid>
                </FileGridContainer>
            ) : (
                <FileZeroMessageSpan>파일을 업로드해주세요.</FileZeroMessageSpan>
            )}
            <FileUpload
                itemID={performValue.column.itemID}
                columnID={performValue.column.id}
                performID={-1}
                getFileValue={null}
                setUpdated={setUpdated}
                addUploadedPerformValueFile={addFileValues}
            />
        </PerformFileValueBox>
    )
}
