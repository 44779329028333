import react, {useState, useEffect, useRef} from "react"

export const useMouseHover = () => {
    const ref = useRef(null)

    const [showHover, setShowHover] = useState(false)

    const handleMouseOver = () => {setShowHover(true)}
    const handleMouseOut = () => {setShowHover(false)}

    useEffect(()=>{
        const node = ref.current
        if(node) {
            node.addEventListener("mouseover", handleMouseOver)
            node.addEventListener("mouseout", handleMouseOut)

            return () => {
                node.removeEventListener("mouseover", handleMouseOver)
                node.removeEventListener("mouseout", handleMouseOut)
            }
        }
    }, [ref.current])

    return [ref, showHover]
}