import React, { useState, useEffect, useCallback, useRef } from 'react';
import tw from 'tailwind-styled-components'
import styled, { keyframes } from 'styled-components';
import { WindowInprivate } from 'styled-icons/fluentui-system-filled';
import { containsParentElement } from '../hooks/useDropdownDetectCloseWithoutClassName';
import { MOBILE_MODAL_CONATINER, NOT_SCROLLABLE } from "../constants/ConstClassName"
import Utils from '../utils/Utils';

const slideIn = keyframes`
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-${(props) => props.defaultHeight}%);
    }
`

const slideOut = keyframes`
    from {
        transform: translateY(${(props) => props.translateValue}%);
    }
    to {
        transform: translateY(0%);
    }
`

const ModalContainer = tw.div`
    drop-shadow-xl shadow-slate-200 absolute w-screen  bg-white rounded-t-xl ${(props) => props.canBigger ? 'h-[80vh]' : `h-[70vh]`}
`

const AnimatedModalContainer = styled(ModalContainer)`
    animation: 0.3s ${(props) => props.isopen ? slideIn : slideOut} ease-out;
    will-change: transform;
`;

const ModalInsideContainer = tw.div`
    w-full h-full relative
`

const ModalGuideContainer = tw.div`
    absolute w-full h-2 top-[-10px] flex justify-center
`
const ModalGuide = tw.div`
    w-28 h-[0.25rem] rounded-full bg-slate-200 drop-shadow-xl shadow-slate-200 
`

const ModalBackDrop = tw.div`
    z-50 w-full h-full bg-black duration-300 ${(props) => props.isopen ? "opacity-40" : "opacity-0"} 
    backdrop-blur-sm
`

const MobileModalContainer = tw.div`
    fixed bottom-0 left-0 w-screen h-screen z-50 
`

// MobileModal은 이 모달의 가장 상위 컴포넌트입니다. 
// 이 컴포넌트는 모달이 열려있는지 상태(isOpen)를 받고, 
// 모달을 열거나 닫는 함수(setOpen)를 인자로 받습니다.
export const MobileModal = ({
    isOpen,
    setIsOpen,
    canBigger = true,
    defaultHeight = 60,
    closeBySwip = true, // 모달을 쓸어내려서 닫는다.
    marginResilience = 15, // 모달을 얼만큼 쓸어내려서 닫을지 마진.
    children
}) => {
    // 이 state는 모달이 현재 열려 있는지를 알아내는 데 사용됩니다.
    const [openModal, setOpenModal] = useState(false);
    // isSwiping은 현재 화면에서 swipe 동작이 발생하고 있는지를 알아내는 데 사용됩니다.
    const [isSwiping, setIsSwiping] = useState(false);
    // startY, translationY, defaultY는 모달이 움직일 때의 위치를 계산하는 데 사용됩니다.
    const [startY, setStartY] = useState(0);
    const [translationY, setTranslationY] = useState(0);
    const [defaultY, setDefaultY] = useState(0)
    // isFull은 모달이 전체 화면을 차지하고 있는지를 알아내는 데 사용됩니다.
    const [isFull, setIsFull] = useState(false)

    const scrollableDivRef = useRef(null);

    // 컴포넌트가 마운트될 때, translationY와 defaultY를 -60으로 초기 설정합니다.
    useEffect(() => {
        setTranslationY(-defaultHeight)
        setDefaultY(-defaultHeight)
        return () => {
            handleClose()
        }
    }, [])

    // useEffect를 이용해 isOpen 값이 변화할 때마다 handleOpen, handleClose 함수를 실행합니다.
    useEffect(() => {
        if (isOpen) {
            handleOpen()
            const scrollPosition = window.pageYOffset + 0
            document.body.style.overflow = 'hidden'
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollPosition}px`
            document.body.style.left = '0'
            document.body.style.right = '0'
        
            return () => {
                document.body.style.removeProperty('overflow')
                // document.body.style.removeProperty('pointer-events')
                document.body.style.removeProperty('position')
                document.body.style.removeProperty('top')
                document.body.style.removeProperty('left')
                document.body.style.removeProperty('right')
                window.scrollTo(0, scrollPosition)
            }
        } else {
            if (openModal) {
                handleClose()
            }
        }
    }, [isOpen])

    // useEffect(()=>{
    //     if (!openModal) {
    //         setIsOpen(false)
    //     }
    // }, [openModal])

    // handleOpen은 모달을 열 때 실행되는 함수입니다.
    const handleOpen = () => {
        setOpenModal(true)
        // document.body.style.overflow = 'hidden'  // 모달이 열려 있을 때는 스크롤을 막습니다.
        // document.body.style.pointerEvents = 'none';
    }

    // handleClose는 모달을 닫을 때 실행되는 함수입니다.
    const handleClose = () => {
        if (!isOpen) {
            setTimeout(() => {
                setOpenModal(false)
                setTranslationY(-defaultHeight)  // 모달을 닫을 때는 초기 위치로 되돌립니다.
                setDefaultY(-defaultHeight)      // 모달을 닫을 때는 초기 위치로 되돌립니다.
                setIsFull(false)      // 모달을 닫을 때는 isFull 상태도 초기화합니다.
            }, 300)
        }
    }

    // handleTouchStart는 터치가 시작될 때 실행되는 함수입니다.
    const handleTouchStart = useCallback((e) => {
        if (closeBySwip && scrollableDivRef.current.scrollTop === 0) {
            setStartY(e.touches[0].clientY) // 터치가 시작된 위치를 저장합니다.

            // MobileModal 내에 not-scrollable를 담은 박스를 스크롤 시 swiping을 하지 않는다.
            if (!containsParentElement(e.target, [NOT_SCROLLABLE])) {
                setIsSwiping(true) // 터치가 시작되면 isSwiping 상태를 true로 변경합니다.
            }
        }
    }, [])

    // handleTouchMove는 터치 동작 중에 실행되는 함수입니다.
    const handleTouchMove = useCallback((e) => {
        // 스크롤이 최상단에 도달했을 때 'touchmove' 이벤트의 기본 동작을 방지합니다.
        if (scrollableDivRef.current.scrollTop === 0 && e.touches[0].clientY > startY) {
            e.preventDefault()
        }

        if (isSwiping) {
            const touchY = e.touches[0].clientY
            const difference = (touchY - startY)

            if (canBigger) {
                var topRate = 100
            }
            else {
                topRate = defaultHeight
            }
            const calculateDifference = defaultY + (difference / window.innerHeight) * topRate
            if (calculateDifference > -topRate && calculateDifference < topRate) {
                setTranslationY(calculateDifference)
            }
        }
    }, [startY, defaultY, isSwiping])

    // handleTouchEnd는 터치가 끝날 때 실행되는 함수입니다.
    const handleTouchEnd = useCallback(() => {
        if (translationY > -(defaultHeight - marginResilience) && !isFull) {
            setIsOpen(false)
        } else if (canBigger && translationY < -(defaultHeight + marginResilience) && !isFull) {
            setIsFull(true)
            setTranslationY(-100)
            setDefaultY(-100)
        } else if (canBigger && translationY < -90 && isFull) {
            setTranslationY(-100)
            setDefaultY(-100)
        } else if (canBigger && translationY > -90 && isFull) {
            setIsOpen(false)
        } else {
            setTranslationY(defaultY)
        }
        setIsSwiping(false)  // 터치가 끝났으므로 isSwiping 상태를 false로 변경합니다.
    }, [translationY, isFull])

    // style 객체는 모달의 위치와 transition을 설정합니다.
    const style = {
        transform: `translateY(${translationY}%) translateZ(0)`,
        transition: isSwiping ? 'none' : 'transform 0.5s ease-out',
    }

    // 여기서 실제로 모달 컴포넌트를 반환합니다.
    return (
        <React.Fragment>
            {openModal &&
                <MobileModalContainer className={MOBILE_MODAL_CONATINER}>
                    <ModalBackDrop isopen={isOpen} onClick={() => { setIsOpen(false) }} />
                    <AnimatedModalContainer
                        style={style}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        isopen={isOpen}
                        translateValue={translationY}
                        defaultHeight={defaultHeight}
                        canBigger={canBigger}
                    >
                        <ModalGuideContainer>
                            <ModalGuide />
                        </ModalGuideContainer>

                        <ModalInsideContainer
                            className={`${!canBigger ? "overflow-scroll" : "overflow-hidden"}`}
                            ref={scrollableDivRef}
                        >
                            {children}
                        </ModalInsideContainer>
                    </AnimatedModalContainer>
                </MobileModalContainer>
            }
        </React.Fragment>
    )
}