import tw from 'tailwind-styled-components'

export const DateTimeInputLayout = tw.div`
    flex flex-wrap items-center
`

export const DateTimeUnitSection = tw.div`
    flex max-sm:mb-1
`
export const DateTimeUnitBox = tw.div`
    flex flex-col mx-1
`
export const DateTimeUnitLabel = tw.label`
    flex items-end h-5 px-1 mb-1
    text-xs
    ${(props) => props.focused ? "text-black" : "text-gray-700 "}
    duration-200 select-none
`
export const DateTimeUnitInputBox = tw.div`
    flex px-2 py-1
    rounded-md border bg-white
    ${(props) => props.focused ? "border-nightblue-500" : "border-gray-150"}
    duration-200
`
export const DateTimeUnitInput = tw.input`
    bg-transparent w-full
`
