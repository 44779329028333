import React, { useState, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { UserInput } from "../../components/user/Inputs"
import { getUserBrandRequestData, getUserBySignup, getUserSignupRequestData } from "../../services/users"
import { UserTypes } from "../../types/users/UserTypes"
import Utils from "../../utils/Utils"
import { useUserStore } from "../../zustand/store"
import {
    SignUpCommitButton,
    SignUpInfoCol,
    SignUpMainContainer,
    SignUpInfoInputCol,
    SignUpInfoTitle,
    SignUpUserLayout,
    SignUpUserTitle,
    SignUpUserLayoutMarketer,
    StepsLayout,
    StepsContainer,
    StepLine,
    StepNumer,
    StepTitle,
    SignUpUserMarketerContainer,
    SignUpUserMarketerLayout,
    SignUpStepContainer,
    SignUpBasicInformationContainer,
    SignUpButtonContainer,
    SignUpButton,
    SignUpBuisnessButton,
    SignUpBuisnessPreview,
    BuisnessImageHover,
    BuisnessImage,
} from "./SignUpStyle"
import { Steps } from "./Steps"
import SignUpUser from "./SignUpUser"
import { EditStateTypes } from "../../types/EditorTypes"
import { SignUpAgreement } from "./SignUpAgreement"
import SignUpBrandUser from "./SignUpBrandUser"
import SignUpStepButton from "./SignUpStepButton"
import { useEffect } from "react"
import { getCheckUserDuplicateTel } from "../../apis/api/users"
import SpinLoading, { SpinLoadingBackgroundType } from "../../components/loading/SpinLoading"

const SignUpDone = ({ name }) => {
    return (
        <div className="w-full h-full mt-8 py-4 px-2 flex flex-col items-center">
            <span className="text-4xl font-semibold text-yellow-600">{name}님 환영합니다!</span>
        </div>
    )
}

const SignUpUserBrand = () => {
    const [stepIndex, setStepIndex] = useState(0)

    const [signUpLoading, setSignUpLoading] = useState(false)

    // 브랜드 유저 기본 정보
    const [userChecked, setUserChecked] = useState(false) // 유저 기본정보로 회원가입 할 수 있는지 여부
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [tel, setTel] = useState("")
    const [birthday, setBirthday] = useState("")
    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [passwordValidate, setPasswordValidate] = useState(false)
    const [telValidate, setTelValidate] = useState(false)
    const [emailValidate, setEmailValidate] = useState(false)
    const [emailState, setEmailState] = useState(EditStateTypes.WAIT)

    // 브랜드 유저 추가정보
    const [brandName, setBrandName] = useState("")
    const [brandCaptinName, setBrandCaptinName] = useState("")
    const [address, setAddress] = useState("")
    const [detailAddress, setDetailAddress] = useState("")
    const [brandTelNumber, setBrandTelNumber] = useState("")
    const [businessNo, setBusinessNo] = useState("") // 사업자 등록 번호
    const [businessImageName, setBuisnessImageName] = useState("") //사업자 등록증 이미지 이름
    const [businessImage, setBuisnessImage] = useState(null) // 사업자 등록증 이미지
    const [brandTelValidate, setBrandTelValidate] = useState(false)
    const [businessImageID, setBuisnessImageID] = useState(-1) // 사업자 등록증 id 현재 미사용

    // const [brandName, setBrandName] = useState("")
    // const [address, setAddress] = useState("")
    // const [date, setDate] = useState("")
    // const [price, setPrice] = useState()
    // const [profit, setProfit] = useState()
    // const [creditGrade, setCreditGrade] = useState()
    // const [employees, setEmployees] = useState()
    // const [industry, setIndustry] = useState("")
    // const [businessNo, setBusinessNo] = useState("")

    //개인정보 동의
    const [ischecked, setIsChekced] = useState(false)
    //마케팅 정보 수신 동의
    const [isMarketingChecked, setIsMarketingChecked] = useState(false)
    const { actions } = useUserStore()

    useEffect(() => {
        if (telAuthState === EditStateTypes.DONE) {
            getCheckUserDuplicateTel(UserTypes.BRAND, tel).then((response) => {
                setUserChecked(response)

                if (!response) {
                    alert("이미 가입된 브랜드입니다.")
                }
            })
        }
    }, [telAuthState])

    const validateUserRegist = () => {
        if (
            emailState !== EditStateTypes.DONE ||
            !emailValidate ||
            !passwordValidate ||
            !telValidate ||
            telAuthState !== EditStateTypes.DONE ||
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            password1 !== password2 ||
            Utils.isStringNullOrEmpty(tel)
        ) {
            if (emailState !== EditStateTypes.DONE) {
                alert("이메일 중복 확인을 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (!emailValidate) {
                alert("이메일 형식을 확인 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (!passwordValidate) {
                alert("비밀번호 형식을 확인 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (password1 !== password2) {
                alert("비밀번호가 일치하지 않습니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (!telValidate) {
                alert("전화번호 형식을 확인 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (telAuthState !== EditStateTypes.DONE) {
                alert("전화 인증 번호를 확인 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (Utils.isStringNullOrEmpty(email)) {
                alert("이메일을 입력 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (Utils.isStringNullOrEmpty(name)) {
                alert("이름을 입력 해주시기 바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else if (Utils.isStringNullOrEmpty(tel)) {
                alert("전화번호를 입력 해주시기바랍니다.")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            } else {
                alert("기본정보를 모두 입력 해주시기 바랍니다")
                return Promise.reject(new Error("기본 정보가 모두 입력되지 않습니다."))
            }
        } else {
            return Promise.resolve(true)
        }
    }

    const signUp = () => {
        setBuisnessImageID(-1)
        if (
            !passwordValidate ||
            businessImage === null ||
            password1 !== password2 ||
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            Utils.isStringNullOrEmpty(password1) ||
            Utils.isStringNullOrEmpty(password2) ||
            Utils.isStringNullOrEmpty(tel) ||
            Utils.isStringNullOrEmpty(brandName) ||
            Utils.isStringNullOrEmpty(address) ||
            Utils.isStringNullOrEmpty(detailAddress) ||
            Utils.isStringNullOrEmpty(brandTelNumber) ||
            Utils.isStringNullOrEmpty(businessNo)
        ) {
            if (password1 !== password2) {
                return Promise.reject(new Error("비밀번호가 일치하지 않습니다."))
            } else if (businessImage === null) {
                alert("사업자 등록증을 업로드 해주시기 바랍니다.")
                return Promise.reject(new Error("사업자 등록증을 업로드 해주시기 바랍니다."))
            } else {
                alert("입력 내용을 확인 해주세요")
                return Promise.reject(new Error("입력 내용을 확인 해주세요"))
            }
        }
        if (telAuthState !== EditStateTypes.DONE) {
            alert("전화번호를 인증해주세요")
            return Promise.reject(new Error("전화번호를 인증해주세요"))
        }
        let requestData = {
            ...getUserSignupRequestData(email, name, name, password1, tel, UserTypes.BRAND, true),
            ...getUserBrandRequestData(
                brandName,
                brandCaptinName,
                address,
                detailAddress,
                brandTelNumber,
                businessImageID,
                businessNo,
            ),
        }
        requestData["detail"]["business_image_base64"] = businessImage
        requestData["detail"]["business_image_filename"] = businessImageName

        return getUserBySignup(requestData)
            .then((user) => {
                setSignUpLoading(false)
                if (user.userType === UserTypes.BRAND) {
                    actions.setEmail(user.email)
                    actions.setName(user.name)
                    actions.setUserType(user.userType)
                    actions.setUser(user)
                    return Promise.resolve(true)
                } else {
                    alert("브랜드 회원으로 회원가입 되지 않았습니다.")
                    throw new Error("브랜드 회원으로 회원가입 되지 않았습니다.")
                }
            })
            .catch((error) => {
                setSignUpLoading(false)
                console.log(error)
                alert("회원가입에 실패했습니다.")
                throw error
            })
    }

    return (
        <SignUpUserMarketerLayout>
            <SignUpUserMarketerContainer>
                <SignUpUserTitle>브랜드 회원가입</SignUpUserTitle>

                <SignUpStepContainer>
                    <Steps stepindex={stepIndex} userType={UserTypes.BRAND} />
                </SignUpStepContainer>

                <SignUpMainContainer>
                    {stepIndex === 0 && (
                        <SignUpAgreement
                            ischecked={ischecked}
                            setIsChekced={setIsChekced}
                            setIsMarketingChecked={setIsMarketingChecked}
                        />
                    )}
                    {stepIndex === 1 && (
                        <SignUpUser
                            email={email}
                            setEmail={setEmail}
                            name={name}
                            setName={setName}
                            password1={password1}
                            setPassword1={setPassword1}
                            password2={password2}
                            setPassword2={setPassword2}
                            passwordValidate={passwordValidate}
                            setPasswordValidate={setPasswordValidate}
                            emailValidate={emailValidate}
                            setEmailValidate={setEmailValidate}
                            telValidate={telValidate}
                            setTelValidate={setTelValidate}
                            tel={tel}
                            setTel={setTel}
                            telAuthState={telAuthState}
                            setTelAuthState={setTelAuthState}
                            birthday={birthday}
                            setBirthday={setBirthday}
                            setEmailState={setEmailState}
                            emailState={emailState}
                        />
                    )}
                    {stepIndex === 2 && (
                        <SignUpBrandUser
                            brandName={brandName}
                            setBrandName={setBrandName}
                            brandCaptinName={brandCaptinName}
                            setBrandCaptinName={setBrandCaptinName}
                            address={address}
                            setAddress={setAddress}
                            detailAddress={detailAddress}
                            setDetailAddress={setDetailAddress}
                            brandTelNumber={brandTelNumber}
                            setBrandTelNumber={setBrandTelNumber}
                            businessNo={businessNo}
                            setBusinessNo={setBusinessNo}
                            businessImageName={businessImageName}
                            setBuisnessImageName={setBuisnessImageName}
                            businessImage={businessImage}
                            setBuisnessImage={setBuisnessImage}
                            setBrandTelValidate={setBrandTelValidate}
                            setBuisnessImageID={setBuisnessImageID}
                        />
                    )}
                    {stepIndex === 3 && <SignUpDone name={name} />}
                </SignUpMainContainer>
                <SignUpStepButton
                    userType={UserTypes.BRAND}
                    signUp={signUp}
                    setSignUpLoading={setSignUpLoading}
                    validateUserRegist={validateUserRegist}
                    setStepIndex={setStepIndex}
                    ischecked={ischecked}
                    userChecked={userChecked}
                />
            </SignUpUserMarketerContainer>

            <SpinLoading
                isLoading={signUpLoading}
                top={"0"}
                backgroundType={SpinLoadingBackgroundType.BLUR}
                message={"회원가입 중입니다."}
            />
        </SignUpUserMarketerLayout>
    )
}

export default SignUpUserBrand
