import React,{useState, useEffect} from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import { NoticeDetailTitle, NoticeDeleteButton, NoticeDetailBody,ReturnButton, NoticeDetailBottom, NoticeDetailContainer, NoticeDetailTitlebottom, NoticeDetailHead, NoticeEditButton, NoticeInner, ReturnIcon, NoticeDetailLayout, NoticeDetailInfoRow, NoticeDetailInfos, NoticeDetailInfoBox, NoticeDetailInfoValue, NoticeDetailInfoLabel } from "./MyPageNoticeDetailStyle";
import {PmsOfficeNavState} from '../../../types/DataEnum'
import { addItemNotice, deleteItemNotice, getItemNotice, updateItemNotice } from "../../../apis/api/boardItems";
import BrandItemNotice from "../../../types/brandItems/notices/BrandItemNotice";
import { ContentContainer } from "../MyPageMainStyle";
import BoardDetail from '../../../components/boards/BoardDetail';
import { ModalContainer } from "../../../components/ModalContainer";
import AccessRules from "../../../rules/AccessRules";
import BoardEdit from './../../../components/boards/BoardEdit';


export default function NoticeDetail({setOfficeNavState, selectedItem}) {
    const { itemID } = useParams();
    const { noticeID } = useParams();
    let navigate = useNavigate();

    const [isLoading, setLoading] = useState(false)
    const [notice, setNotice] = useState(new BrandItemNotice())
    const _notice = new BrandItemNotice()

    const [showNoticeEdit,setShowNoticeEdit] = useState(false)

    useEffect(() => {
        setOfficeNavState(PmsOfficeNavState.NOTICE)

        return() => (
            setOfficeNavState(PmsOfficeNavState.NONE)
        )
    },[])

    useEffect(() => {
        getNotice()
    }, [noticeID])

    const getNotice = () => {
        setLoading(true)
        getItemNotice(noticeID).then(response => {
            setTimeout(()=>{
                setNotice(response)
            }, 200)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }

    const handleOpenUpdate = () => {
        setShowNoticeEdit(true)
    }

    const handleCloseUpdate = (refresh) => {
        if (refresh) {
            getNotice()
        }
        setShowNoticeEdit(false)
    }

    const handleDelete = () => {
        deleteItemNotice(noticeID).then(response => {
            if (response) {
                navigate(`/mypage/perform/notice/${itemID}`)
            }
        })
    }

    const handleAddItemNotice = async (board) => {
        const result = await addItemNotice(selectedItem.id, board)
        return result
    }

    return(
        <NoticeDetailContainer full_height>
            <BoardDetail 
            board={notice.board} 
            importance={notice.board_importance_status}
            onUpdate={handleOpenUpdate} 
            onDelete={handleDelete} 
            readOnly={!AccessRules.brandItemUpdate(selectedItem)} isLoading={isLoading} 
            />

            <ModalContainer show={showNoticeEdit} setShow={setShowNoticeEdit}>
                {/* <MyPageNoticeEdit itemID={selectedItem.id} noticeID={notice.id} close={handleCloseUpdate} /> */}
                <BoardEdit
                    mainID={noticeID} 
                    getMain={getItemNotice} 
                    addMain={handleAddItemNotice} 
                    updateMain={updateItemNotice} 
                    close={handleCloseUpdate} 
                />
            </ModalContainer>
        </NoticeDetailContainer>
    )

}