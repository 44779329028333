import { EditStateTypes } from '../EditorTypes';
import User from './User';

export default class {
    constructor() {
        this.businessNo = ""
        this.user = new User()
        this.detail = {
            name: "",
            companyManagerName: "",
            address1: "",
            address2: "",
            tel: "",
            businessImageID: -1,
            date: "",
            price: 0,
            profit: 0,
            creditGrade: 0,
            employees: 0,
            industry: "",
        }
        this.brandStatusType = EditStateTypes.WAIT
    }

    convertByResponse(response) {
        this.businessNo = response["business_no"]
        this.brandStatusType = response["brand_status_type"]

        const userResponse = response["user"]
        this.user = new User()
        if (userResponse !== null && userResponse !== undefined) {
            this.user.convertByResponse(userResponse)
        }

        const detailResponse = response["detail"]
        if (detailResponse !== null && detailResponse !== undefined) {
            this.detail = {
                name: detailResponse["name"],
                companyManagerName: detailResponse["company_manager_name"],
                address1: detailResponse["address1"],
                address2: detailResponse["address2"],
                tel: detailResponse["tel"],
                businessImageID: detailResponse["business_image_id"],
                date: detailResponse["date"],
                price: detailResponse["price"],
                profit: detailResponse["profit"],
                creditGrade: detailResponse["credit_grade"],
                employees: detailResponse["employees"],
                industry: detailResponse["industry"]
            }
        }
    }
}