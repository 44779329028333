import Utils from "../../utils/Utils"
import GiftiCouponThumb from "./GiftiCouponThumb"
import { CouponApplicationStatusTypes } from "./PointTypes"

export default class {
    constructor() {
        this.id = -1
        this.uuid = ""
        this.title = ""
        this.thumbnailImageID = -1
        this.price = 0
        this.dateStart = ""
        this.dateEnd = ""
        this.requireRecommands = 0
        this.requirePoint = 0
        this.applicationType = CouponApplicationStatusTypes.WAIT

        // this.giftiCouponThumbID = -1
        this.giftiCouponThumb = new GiftiCouponThumb()
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.uuid = response["uuid"]
        this.title = response["title"]
        this.thumbnailImageID = response["thumbnail_image_id"]
        this.thumbnailImageURL = response["thumbnail_image_url"]
        this.price = response["price"]
        this.dateStart = response["date_start"]
        this.dateEnd = response["date_end"]
        this.requireRecommands = response["require_recommands"]
        this.requirePoint = response["require_point"]
        this.applicationType = response["application_type"]

        // this.giftiCouponThumbID = response["gifti_coupon_id"]
        const giftiCouponThumbResponse = response["gifti_coupon_thumb"]
        if (!Utils.isNullOrUndefined(giftiCouponThumbResponse)) {
            this.giftiCouponThumb = new GiftiCouponThumb()
            this.giftiCouponThumb.convertByResponse(giftiCouponThumbResponse)
        }
    }

    convertToRequest() {
        let request = {
            id: this.id,
            uuid: this.uuid,
            title: this.title,
            thumbnail_image_id: this.thumbnailImageID,
            price: this.price,
            date_start: this.dateStart,
            date_end: this.dateEnd,
            require_recommands: this.requireRecommands,
            require_point: this.requirePoint,
            application_type: this.applicationType,
            // gifti_coupon_id: this.giftiCouponThumbID,
        }

        return request
    }
}
