import React, { useState, useEffect } from "react"
import {
    BuildingsIcon,
    ManageAccountsIcon,
    PersonIcon,
    SignUpTitleContainer,
    SignUpTitleText,
    SignUpTitleUnderLine,
    SignUpTypeBrand,
    SignUpTypeCardIconBox,
    SignUpTypeCardConTainer,
    SignUpTypeCardContentBox,
    SignUpTypeCardDescriptionBox,
    SignUpTypeCardDescriptionPre,
    SignUpTypeCardGridContainer,
    SignUpTypeCardSubTitle,
    SignUpTypeCardTitle,
    SignUpTypeCardTitleBox,
    SignUpTypeCol,
    SignUpTypeItems,
    SignUpTypeMarketer,
    SignUpTypeStaff,
} from "./SignUpStyle"
import { Link } from "react-router-dom"
import tw from "tailwind-styled-components"
import { FONT_FAMILY } from "../../styles/Globalstyle"
import { KakaoLogo, LoginListButton, LoginListKakaoButton } from "../../components/user/login/LoginStyle"
import Utils from "../../utils/Utils"
import { useLocation } from "react-router-dom"

import BrandIcon from "../../static/signup/brand.png"
import MarketerIcon from "../../static/signup/marketer.png"
import { UserTypes } from "../../types/users/UserTypes"

const CARD_LIST = [
    {
        icon: MarketerIcon,
        title: "마케터 회원",
        subTitle: "영업 프리랜서, 세일즈 마케터",
        value: 0,
        tolink: "/signup/marketer",
        description: "등록된 상품에 대해 영업에 참여하기를 원하는 일반회원",
    },
    {
        icon: BrandIcon,
        title: "브랜드 회원",
        subTitle: "기업, 상품 공급자",
        value: 1,
        tolink: "/signup/brand",
        description: "등록한 상품에 대해 영업자가 영업을 해주기를 원하는 기업회원",
    },
    // {
    //     icon:<ManageAccountsIcon />,
    //     title:'스태프',
    //     value:2,
    //     tolink:'/signup/staff'
    // }
]

const SignUpTypeCards = () => {
    const location = useLocation()

    const [isHovered, setIsHovered] = useState(-1)
    const [isSelected, setIsSelected] = useState(-1)

    const [isHovering, setIsHovering] = useState(false)

    const [userType, setUserType] = useState(UserTypes.NONE)

    useEffect(() => {
        console.log(location.state)
        if (!Utils.isNullOrUndefined(location.state) && !Utils.isNullOrUndefined(location.state.userType)) {
            setUserType(location.state.userType)
        }
    }, [location])

    // 해당 함수는 selected 발생 후에발도 안함
    const handleMouseEnter = (value) => {
        if (isSelected === -1) {
            setIsHovering(true)
            setIsHovered(value)
        }
    }

    // 해당 함수는 selected 발생 후에발도 안함
    const handleMouseLeave = () => {
        if (isSelected === -1) {
            setIsHovering(false)
            setIsHovered(-1)
        }
    }

    const loginWithKakao = () => {
        const baseUrl = Utils.getBaseUrl()
        const kakaoRedirectUrl = `${baseUrl}/oauth/kakao/callback`
        window.Kakao.Auth.authorize({
            redirectUri: kakaoRedirectUrl,
        })
    }

    return (
        <React.Fragment>
            {/* 마케터 */}
            {(userType === UserTypes.NONE || userType == UserTypes.MARKETER) && (
                <SignUpTypeCard cardData={CARD_LIST[0]} loginWithKakao={loginWithKakao} />
            )}
            {(userType === UserTypes.NONE || userType == UserTypes.BRAND) && <SignUpTypeCard cardData={CARD_LIST[1]} />}
        </React.Fragment>
    )
}

const SignUpTypeCard = ({ cardData, loginWithKakao }) => {
    return (
        <SignUpTypeCardConTainer style={{ fontFamily: FONT_FAMILY }}>
            <SignUpTypeCardTitleBox>
                <SignUpTypeCardIconBox>
                    <img src={cardData.icon} />
                </SignUpTypeCardIconBox>
                <SignUpTypeCardTitle>{cardData.title}</SignUpTypeCardTitle>
            </SignUpTypeCardTitleBox>

            <SignUpTypeCardDescriptionBox>
                <SignUpTypeCardDescriptionPre style={{ fontFamily: FONT_FAMILY }}>
                    {cardData.description}
                </SignUpTypeCardDescriptionPre>
            </SignUpTypeCardDescriptionBox>
            <SignUpTypeCardSubTitle>{cardData.subTitle}</SignUpTypeCardSubTitle>

            <div className="flex flex-col w-48 my-6 space-y-4">
                <Link to={cardData.tolink} className="w-full">
                    <LoginListButton>
                        <i className="fa-solid fa-envelope text-nightblue-600 text-lg mr-2"></i>이메일
                    </LoginListButton>
                </Link>
                {!Utils.isNullOrUndefined(loginWithKakao) && (
                    <LoginListKakaoButton
                        onClick={() => {
                            loginWithKakao()
                        }}
                    >
                        <div className="h-full pb-2.5 pt-1 mr-2">
                            <KakaoLogo />
                        </div>
                        카카오
                    </LoginListKakaoButton>
                )}
            </div>
        </SignUpTypeCardConTainer>
    )
}

const SignUp = () => {
    return (
        <SignUpTypeCol>
            <SignUpTypeItems>
                <SignUpTitleContainer>
                    <SignUpTitleText>
                        회원 유형 선택
                        <SignUpTitleUnderLine />
                    </SignUpTitleText>
                </SignUpTitleContainer>
                <SignUpTypeCardGridContainer>
                    <SignUpTypeCards />
                </SignUpTypeCardGridContainer>
            </SignUpTypeItems>
        </SignUpTypeCol>
    )
}

export default SignUp
