import React, { useEffect, useState } from "react"
import { GroupInviteContainer } from "./MyPageGroupStyle"
import { PerformValueInputTitle } from "../perform/MyPagePerformStyle"
import MyPageGroupMarketerInvite from "./MyPageGroupMarketerInvite"

export default function MyPageGroupMarketerList({ itemID, parentItemMarketer, getCurrentMarketers }) {
    return (
        <GroupInviteContainer>
            <PerformValueInputTitle>
                <span>하위 마케터 초대</span>
            </PerformValueInputTitle>
            <MyPageGroupMarketerInvite
                itemID={itemID}
                parentItemMarketer={parentItemMarketer}
                getCurrentMarketers={getCurrentMarketers}
            />
        </GroupInviteContainer>
    )
}
