import React, { useState, useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import {
    FilterCategoryTitle,
    FilterInner,
    FilterTop,
    ItemCardListGrid,
    ItemPageBox,
    ItemPageBoxInner,
    ItemPageBoxInnerLeft,
    ItemPageBoxInnerRight,
    ItemPaginationCon,
    ItemPaginationInner,
    ItemSearchCon,
    ItemSortOptionCon,
    ItemSortSelection,
    PaginationButton,
    SearchButton,
    SearchInput,
    FilterOptionBox,
    CategoryCheckItems,
    SearchCol,
    SearchControlRow,
    SearchTypeSelection,
    ItemSortSwitchCon,
    ItemPageTitle,
    SearchTypeSelectionBox,
    CheckIcon,
    ItemCatgoryCheckContainer,
    ItemCategoryCheckIconContainer,
    ItemCategoryText,
    ItemAdvertiseBox,
    ItemAdvertiseButton,
    ItemAdvertiseImageContainer,
    SearchInputBox,
    FilterToggleButton,
    FilteredIcon,
    FilterIcon,
    ItemMarketerMatchingButton,
} from "./ItemStyle"
// import {CARD_CATEGORY_DATA_LIST} from '../../constants/ConstCard'
import { ItemOrderNames, ItemOrders, ItemOrderValues, ITEM_PAGE_SIZES } from "../../constants/ConstItem"
import { useLocation } from "react-router-dom"
import { BrandItemFilterCategory } from "../../types/brandItems/BrandItemOption"
import { Pagination } from "../../components/Pagination"
import SortSwitch from "../../components/SortSwitch"
import { getBrandItemList } from "../../apis/api/boardItems"
import BrandItemList from "../../types/brandItems/BrandItemList"
import { ItemCardType } from "../../types/DataEnum"
import {
    ItemSalesTypes,
    ItemSearchTypeNames,
    ItemSearchTypes,
    MarketerTypeNames,
    MarketerTypes,
} from "../../types/brandItems/BrandItemTypes"
import { useSearchParams } from "react-router-dom"
import BrandItemCard from "../../components/brandItems/BrandItemCard"
import { PaginationStyles } from "../../types/StyleTypes"
import { SortTypes } from "../../types/EditorTypes"
import BrandItem from "../../types/brandItems/BrandItem"
import Advertisement from "../../components/Advertisement"

import { isMobile } from "react-device-detect"
import { PerformTableFilterIcon } from "../myPage/perform/MyPagePerformStyle"
import { UserTypes } from "../../types/users/UserTypes"
import Utils from "../../utils/Utils"

// 레이아웃, 크기, 위치, 마진, 패딩,
// 텍스트(레이아웃, 사이즈, 무게, 색상(호버)),
// 색상(호버), 보더(호버), 곡률, 그림자
// 트랜지션, 가시성, 투명도,
// 오버플로우, 기타

const MAX_PAGE_BUTTON_LENGTH_DESKTOP = 10
const MAX_PAGE_BUTTON_LENGTH_MOBILE = 5

const CategoryCheckItem = ({ label, itemType, checkList, setFilterList, isLoading }) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        const _isSelected = checkList.includes(itemType)
        setIsSelected(_isSelected)
    }, [checkList])

    const handleCheck = () => {
        if (!isSelected) {
            setFilterList([...checkList, itemType])
        } else {
            setFilterList(checkList.filter((i) => i !== itemType))
        }
    }

    return (
        <ItemCatgoryCheckContainer
            isselected={isSelected}
            isLoading={isLoading}
            onClick={() => {
                handleCheck()
            }}
        >
            <div className="flex items-center h-4">
                <ItemCategoryCheckIconContainer isselected={isSelected}>
                    <CheckIcon isselected={isSelected} />
                </ItemCategoryCheckIconContainer>
                <ItemCategoryText isselected={isSelected}>{label}</ItemCategoryText>
            </div>
        </ItemCatgoryCheckContainer>
    )
}

export default function Items({
    user,
    itemSalesFilter,
    setItemSalesFilter,
    marketerFilter,
    setMarketerFilter,
    itemOrder,
    setItemOrder,
    itemSortType,
    setItemSortType,
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    itemCount,
    setItemCount,
    items,
    setItems,
    searchType,
    setSearchType,
    searchTypeName,
    setSearchTypeName,
    searchValue,
    setSearchValue,
    searchKeyword,
    setSearchKeyword,
}) {
    const location = useLocation()
    const navigate = useNavigate()

    const [showfilter, setShowfilter] = useState(true)

    const [searchParams] = useSearchParams()

    const [paginationSize, setPaginationSize] = useState(
        isMobile ? MAX_PAGE_BUTTON_LENGTH_MOBILE : MAX_PAGE_BUTTON_LENGTH_DESKTOP,
    )
    const [isLoading, setLoading] = useState(false)

    const [showFilters, setShowFilters] = useState(false) // 검색 밑에 나올 필터 보일지 여부 (모바일에서만 사용)
    const [showAdvertisement, setShowAdvertisement] = useState(true)

    useEffect(() => {
        if (location.state !== null) {
            // 스켈레톤 용 더미 20개
            setItems([
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
            ])

            let _itemSalesFilter = []
            let _marketerFilter = []
            let _itemOrder = location.state.itemOrder
            if (location.state.filterCategory === BrandItemFilterCategory.ItemSales) {
                _itemSalesFilter = [location.state.filterID]
                setItemSalesFilter(_itemSalesFilter)
            } else if (location.state.filterCategory === BrandItemFilterCategory.Marketer) {
                _marketerFilter = [location.state.filterID]
                setMarketerFilter(_marketerFilter)
            }

            let _searchKeyword = location.state.searchKeyword
            setSearchKeyword(_searchKeyword)
            setSearchValue(_searchKeyword)

            updateBrandItemList(
                1,
                pageSize,
                ItemOrderValues[_itemOrder],
                itemSortType,
                _itemSalesFilter,
                _marketerFilter,
                _searchKeyword,
                searchType,
            )
        } else {
            if (items.length === 0) {
                // 스켈레톤 용 더미 20개
                setItems([
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                ])
                updateBrandItemList(
                    pageIndex,
                    pageSize,
                    ItemOrderValues[itemOrder],
                    itemSortType,
                    itemSalesFilter,
                    marketerFilter,
                    searchKeyword,
                    searchType,
                )
            }
        }
    }, [])

    useEffect(() => {
        const outlet = document.getElementById("router-outlet")
        setPaginationSize(
            isMobile || outlet.offsetWidth <= 640 ? MAX_PAGE_BUTTON_LENGTH_MOBILE : MAX_PAGE_BUTTON_LENGTH_DESKTOP,
        )

        setShowFilters(false)
    }, [isMobile])

    const updateBrandItemList = (
        pageIndex,
        pageSize,
        ordering,
        sortType,
        itemSalesFilter,
        marketerFilter,
        searchKeyword,
        searchType,
    ) => {
        if (!isLoading && pageIndex >= 0) {
            setLoading(true)
            // 리스트 업데이트 시 입력중인 키워드를 검색에 사용할 키워드로 돌려놓는다.
            setSearchValue(searchKeyword)

            if (sortType === SortTypes.DESC) {
                ordering = "-" + ordering
            }

            getBrandItemList(pageIndex, pageSize, ordering, itemSalesFilter, marketerFilter, searchKeyword, searchType)
                .then((response) => {
                    const brandItemList = new BrandItemList()
                    brandItemList.convertByResponse(response)

                    setItemCount(brandItemList.count)
                    setItems(brandItemList.items)
                    setLoading(false)
                    setPageIndex(pageIndex)
                })
                .catch((error) => {
                    setItems([])
                    setLoading(false)
                })
        }
    }

    const handleItemSalesFilter = (value) => {
        if (isLoading) {
            return
        }
        setItemSalesFilter(value)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            value,
            marketerFilter,
            searchKeyword,
            searchType,
        )
    }

    const handleMarketerFilter = (value) => {
        if (isLoading) {
            return
        }

        setMarketerFilter(value)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            itemSalesFilter,
            value,
            searchKeyword,
            searchType,
        )
    }

    const handleOrder = (value) => {
        if (isLoading) {
            return
        }

        setItemOrder(value)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[value],
            itemSortType,
            itemSalesFilter,
            marketerFilter,
            searchKeyword,
            searchType,
        )
    }

    const handleSort = (sortType) => {
        setItemSortType(sortType)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            sortType,
            itemSalesFilter,
            marketerFilter,
            searchKeyword,
            searchType,
        )
    }

    const handlePageSize = (value) => {
        if (isLoading) {
            return
        }

        setPageSize(ITEM_PAGE_SIZES[value])
        updateBrandItemList(
            1,
            ITEM_PAGE_SIZES[value],
            ItemOrderValues[itemOrder],
            itemSortType,
            itemSalesFilter,
            marketerFilter,
            searchKeyword,
            searchType,
        )
    }

    const handlePageIndex = (value) => {
        if (isLoading) {
            return
        }
        updateBrandItemList(
            value,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            itemSalesFilter,
            marketerFilter,
            searchKeyword,
            searchType,
        )

        const outlet = document.getElementById("router-outlet")
        outlet.scrollTo({
            top: 0,
        })
    }

    const handleSearch = () => {
        if (isLoading) {
            return
        }

        setSearchKeyword(searchValue)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            itemSalesFilter,
            marketerFilter,
            searchValue,
            searchType,
        )
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSearchType = (value) => {
        if (value === 0) {
            setSearchType(ItemSearchTypes.TITLE_ONLY)
            setSearchTypeName(ItemSearchTypeNames.TITLE_ONLY)
        } else if (value === 1) {
            setSearchType(ItemSearchTypes.BRAND_NAME_ONLY)
            setSearchTypeName(ItemSearchTypeNames.BRAND_NAME_ONLY)
        }
    }

    const [testStyle, setTestStyle] = useState(false)

    const handleMarketerMatching = () => {
        navigate("/items/marketer_matching")
    }

    return (
        <ItemPageBox>
            <ItemPageBoxInner>
                <ItemPageBoxInnerLeft>
                    <ItemSearchCon>
                        <SearchCol>
                            <SearchControlRow>
                                <SearchInputBox>
                                    <SearchInput
                                        type="text"
                                        placeholder="상품 혹은 브랜드"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                        }}
                                        onKeyPress={(e) => {
                                            handleSearchKeyPress(e)
                                        }}
                                    />
                                </SearchInputBox>
                                {/* <SearchTypeSelectionBox>
                                    <SearchTypeSelection value={searchTypeName} onChange={(e)=>{handleSearchType(e.target.selectedIndex)}}>
                                        <option key={ItemSearchTypes.TITLE_ONLY}>{ItemSearchTypeNames.TITLE_ONLY}</option>
                                        <option key={ItemSearchTypes.BRAND_NAME_ONLY}>{ItemSearchTypeNames.BRAND_NAME_ONLY}</option>
                                    </SearchTypeSelection>
                                </SearchTypeSelectionBox> */}

                                <SearchButton
                                    onClick={() => {
                                        handleSearch()
                                    }}
                                    isLoading={isLoading}
                                >
                                    검색
                                </SearchButton>
                                <FilterToggleButton
                                    onClick={() => {
                                        setShowFilters(!showFilters)
                                    }}
                                    isActive={showFilters}
                                >
                                    <FilterIcon />
                                </FilterToggleButton>
                            </SearchControlRow>
                        </SearchCol>

                        <FilterOptionBox isShow={showFilters}>
                            {/* <FilterTop onClick={() => {setShowfilter(!showfilter)}} >
                                조건 검색 아래 아이콘
                            </FilterTop> */}
                            <FilterInner showfilter={showfilter.toString()}>
                                <FilterCategoryTitle>상품 유형</FilterCategoryTitle>
                                <CategoryCheckItems>
                                    <CategoryCheckItem
                                        label={"솔루션 상품"}
                                        itemType={ItemSalesTypes.SOLUTON}
                                        checkList={itemSalesFilter}
                                        setFilterList={handleItemSalesFilter}
                                        isLoading={isLoading}
                                    />
                                    <CategoryCheckItem
                                        label={"서비스 상품"}
                                        itemType={ItemSalesTypes.SERVICE}
                                        checkList={itemSalesFilter}
                                        setFilterList={handleItemSalesFilter}
                                        isLoading={isLoading}
                                    />
                                    <CategoryCheckItem
                                        label={"제품 상품"}
                                        itemType={ItemSalesTypes.PRODUCT}
                                        checkList={itemSalesFilter}
                                        setFilterList={handleItemSalesFilter}
                                        isLoading={isLoading}
                                    />
                                </CategoryCheckItems>
                                <FilterCategoryTitle>마케터 카테고리</FilterCategoryTitle>
                                <CategoryCheckItems>
                                    <CategoryCheckItem
                                        label={MarketerTypeNames[MarketerTypes.PRO]}
                                        itemType={MarketerTypes.PRO}
                                        checkList={marketerFilter}
                                        setFilterList={handleMarketerFilter}
                                        isLoading={isLoading}
                                    />
                                    <CategoryCheckItem
                                        label={MarketerTypeNames[MarketerTypes.PRE]}
                                        itemType={MarketerTypes.PRE}
                                        checkList={marketerFilter}
                                        setFilterList={handleMarketerFilter}
                                        isLoading={isLoading}
                                    />
                                </CategoryCheckItems>
                            </FilterInner>
                            {/* <input type="checkbox" value={testStyle} onClick={()=>{setTestStyle(!testStyle)}} /> */}
                        </FilterOptionBox>
                    </ItemSearchCon>
                    <ItemAdvertiseBox>
                        <Advertisement
                            isShow={showAdvertisement}
                            setShow={setShowAdvertisement}
                            imageURL={`${process.env.REACT_APP_API_SERVER_URL}/media/static/advertisement/golf.png`}
                            linkURL={"https://www.volkswagen.co.uk/en.html"}
                        />
                    </ItemAdvertiseBox>

                    {user.userType === UserTypes.STAFF && (
                        <div className="sm:hidden w-full mt-2">
                            <ItemMarketerMatchingButton
                                onClick={() => {
                                    handleMarketerMatching()
                                }}
                            >
                                아이템 매칭
                            </ItemMarketerMatchingButton>
                        </div>
                    )}
                </ItemPageBoxInnerLeft>
                <ItemPageBoxInnerRight>
                    {/* <ItemSortOptionCon>
                        <p></p>
                        <div className="flex items-center h-full">
                            <ItemSortSelection value={itemOrder} onChange={(e)=>{handleOrder(e.target.selectedIndex)}}>
                                <option className="border-none" key={ItemOrders.FAV} value={ItemOrders.FAV}>{ItemOrderNames[0]}</option>
                                <option key={ItemOrders.CREATED} value={ItemOrders.CREATED}>{ItemOrderNames[1]}</option>
                            </ItemSortSelection>

                            <ItemSortSwitchCon>
                                <SortSwitch sortType={itemSortType} setSortType={handleSort} />
                            </ItemSortSwitchCon>
                        </div>
                        <ItemSortSelection value={pageSize} onChange={(e)=>{handlePageSize(e.target.selectedIndex)}}>
                            {ITEM_PAGE_SIZES.map(size => (
                                <option key={size} value={size}>{size}개씩 보기</option>)
                            )}
                        </ItemSortSelection>
                    </ItemSortOptionCon> */}

                    <ItemPageTitle>
                        <span className="text-xl font-semibold">영업 아이템</span>
                        {user.userType === UserTypes.STAFF && (
                            <ItemMarketerMatchingButton
                                onClick={() => {
                                    handleMarketerMatching()
                                }}
                            >
                                아이템 매칭
                            </ItemMarketerMatchingButton>
                        )}
                    </ItemPageTitle>

                    <ItemCardListGrid>
                        {items.map((data, idx) => (
                            <BrandItemCard
                                key={idx}
                                item={data}
                                itemType={ItemCardType.ITEM}
                                isLoading={isLoading}
                                testStyle={testStyle}
                            />
                        ))}
                    </ItemCardListGrid>

                    <ItemPaginationCon>
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={paginationSize}
                            styleType={PaginationStyles.TextButtons}
                        />
                    </ItemPaginationCon>
                </ItemPageBoxInnerRight>
            </ItemPageBoxInner>
        </ItemPageBox>
    )
}
