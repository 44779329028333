import React, { useState, useEffect, useRef } from 'react';
import tw from 'tailwind-styled-components'
import { MOBILE_MODAL_CONATINER, MODAL_CONATINER } from '../../constants/ConstClassName';

const Container = tw.div`
    z-40
    absolute w-full h-full
    max-lg:block lg:hidden
`

const Slider = tw.div`
    fixed
    top-[2.45rem] bottom-0 right-0
    transition-all duration-200 ease-in-out
    z-30 
    overflow-hidden
`

const Content = tw.div`
    overflow-y-auto
`

const Background = tw.div`
    fixed top-[2.45rem] ${(props) => props.isShow ? "block z-30 opacity-100" : "hidden"}
    w-screen h-[calc(100vh-2.5rem)]
    bg-black/30
`

export const SideBarMenu = ({ SideBarRef, isOpen, width = 280, children }) => {
    const [xPosition, setX] = useState(-width)

    useEffect(() => {
        if (isOpen) {
            toggleMenu()

            
            const scrollPosition = window.pageYOffset

            document.body.style.overflow = 'hidden'
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollPosition}px`
            document.body.style.left = '0'
            document.body.style.right= '0'

            return () => {
                const mobileModalContainers = document.getElementsByClassName(MOBILE_MODAL_CONATINER)
                const modalContainers = document.getElementsByClassName(MODAL_CONATINER)
                
                // 사이드바보다 하위인 모달이 켜져있는 경우 스크롤 방지 속성을 없애지않는다.
                if (mobileModalContainers.length === 0 && modalContainers.length === 0) {
                    document.body.style.removeProperty('overflow')
                    document.body.style.removeProperty('position')
                    document.body.style.removeProperty('top')
                    document.body.style.removeProperty('left')
                    document.body.style.removeProperty('right')
                    window.scrollTo(0, scrollPosition)
                }
            }
        } else {
            handleClose()
        }
    }, [isOpen])

    const toggleMenu = () => {
        setX(isOpen ? 0 : -width)
    }
    // 사이드바 외부 클릭시 닫히는 함수
    const handleClose = () => {
        if (!isOpen) {
            setX(-width)
        }
    }

    return (
        <Container>
            <Background isShow={isOpen} />
            <Slider ref={SideBarRef} className={`w-[${width}px] flex-1 transform translate-x-[${-xPosition}px] `}>
                <Content>
                    {children}
                </Content>
            </Slider>
        </Container>
    )
}