import { Outlet, useLocation } from 'react-router-dom'
import React, { useRef, useEffect } from 'react'
import { MyPageNavHorizontal, MyPageNavVertival } from '../../../components/navigation/MyPageNav'
import { useState } from 'react'
import { NavBoxCon, OutletContainer } from '../../../components/navigation/MyPageNavStyle'
import { ModalContainer } from '../../../components/ModalContainer'
import { userDetailModalStore } from '../../../zustand/modalstore'
import MyPageMarketerDetailModal from '../../../components/mypage/marketer/MyPageMarketerDetailModal'
import { SideBarMenu } from '../../../components/navigation/SideBarMenu'
import { MyPageNavContents } from '../../../components/navigation/MyPageNavContents'
import { MobileModal } from '../../../components/MobileModal'
import { RESPONSIVE_WIDTH } from './../../../constants/ConstUI';



export default function MyPageNavOutlet({ officeNavState, selectedItem, pmsNavState, folded, setFolded }) {
    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const { pathname } = useLocation()

    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

    const SideBarRef = useRef()
    const MyPageNavRef = useRef()

    useEffect(() => {
        setIsSideMenuOpen(false)
    }, [pathname])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                SideBarRef.current && !SideBarRef.current.contains(event.target) &&
                MyPageNavRef.current && !MyPageNavRef.current.contains(event.target)
            ) {
                setIsSideMenuOpen(false);
            }
        }
        if (isSideMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [isSideMenuOpen])

    const isMobileSize = window.innerWidth <= RESPONSIVE_WIDTH.LG
    return (
        <div className='w-full flex flex-col'>
            {/* 왼쪽 사이드 네비와 화면 */}
            <MyPageNavVertival
                officeNavState={officeNavState}
                selectedItem={selectedItem}
                pmsNavState={pmsNavState}
                folded={folded}
                setFolded={setFolded}
                isSideMenuOpen={isSideMenuOpen}
            >
                <NavBoxCon />
                <OutletContainer>
                    <div className="absolute w-full min-h-[calc(100vh-2.5rem)] bg-slate-100 h-auto z-40">
                        {/* 상단 네비 */}
                        <MyPageNavHorizontal MyPageNavRef={MyPageNavRef} folded={folded} setFolded={setFolded} isSideMenuOpen={isSideMenuOpen} setIsSideMenuOpen={setIsSideMenuOpen}/>
                        <Outlet/>
                    </div>
                    <SideBarMenu SideBarRef={SideBarRef} setIsOpen={setIsSideMenuOpen} isOpen={isSideMenuOpen} width={192}>
                        <MyPageNavContents folded={folded} pmsNavState={pmsNavState} selectedItem={selectedItem} officeNavState={officeNavState} />
                    </SideBarMenu>
                </OutletContainer>
            </MyPageNavVertival>
            <div className='max-lg:hidden lg:block'>
                <ModalContainer show={userShow && !isMobileSize} setShow={setUserShow} closeButton={false} closeByBackground={true}>
                    <MyPageMarketerDetailModal />
                </ModalContainer>
            </div>
            <div className='max-lg:block lg:hidden'>
                <MobileModal isOpen={userShow && isMobileSize} setIsOpen={setUserShow}>
                    <MyPageMarketerDetailModal />
                </MobileModal>
            </div>
        </div>
    );
}