import React, { useEffect, useState, useRef, useMemo } from "react"
import tw from "tailwind-styled-components"
import {
    PerformColumnSubControlBox,
    PerformColumnSubDeleteButton,
    PerformColumnSubSaveButton,
    StaffPerformUpdateDateContainer,
    StaffPerformUpdateOptionContainer,
    StaffPerformUpdateStatusBox,
    StaffPerformUpdateStatusContainer,
    StaffPerformUpdateStatusControlBox,
    StaffPerformUpdateStatusInputBox,
    StaffPerformUpdateStatusList,
    StaffPerformUpdateStatusMainBox,
} from "./StaffPerformStyle"
import { MyPageDataSubmitButton } from "../myinfo/MyInfoMainStyle"
import Utils from "../../../utils/Utils"
import { QueryTypes } from "../../../types/EditorTypes"
import { PerformValueInputTitle } from "./MyPagePerformStyle"
import { MyPageUserInput } from "../../../components/user/Inputs"
import PerformColumnDate from "../../../types/brandItems/performColumns/PerformColumnDate"

export default function StaffPerformUpdateDate({ selectedColumn, columnSubEdit }) {
    const [dateID, setDateID] = useState(-1)
    const [dateFormat, setDateFormat] = useState("")
    const [hasMemo, setHasMemo] = useState(false)

    useEffect(() => {
        if (selectedColumn.dates.length > 0) {
            const date = selectedColumn.dates[0]
            setDateID(date.id)
            setDateFormat(date.dateFormat)
            setHasMemo(date.hasMemo)
        }
    }, [selectedColumn])

    const handleSaveDate = () => {
        if (Utils.isStringNullOrEmpty(dateFormat)) {
            alert("날짜 포맷을 입력해주세요.")
            return
        }

        if (selectedColumn.id >= 0) {
            let newDate = new PerformColumnDate()
            newDate.id = dateID
            newDate.columnID = selectedColumn.id
            newDate.name = selectedColumn.name
            newDate.dateFormat = dateFormat
            newDate.hasMemo = hasMemo
            columnSubEdit(newDate, QueryTypes.CREATE)
        } else {
            alert("추가할 수 없습니다.")
        }
    }

    return (
        <StaffPerformUpdateDateContainer>
            <PerformValueInputTitle>
                <span>
                    {selectedColumn.name} <span className="text-gray-600">날짜 컬럼 관리</span>
                </span>
            </PerformValueInputTitle>

            <StaffPerformUpdateStatusList>
                <StaffPerformUpdateStatusBox>
                    <StaffPerformUpdateStatusMainBox>
                        <StaffPerformUpdateStatusInputBox>
                            <MyPageUserInput
                                label={"날짜 포맷"}
                                type={"text"}
                                data={dateFormat}
                                setData={setDateFormat}
                            />
                        </StaffPerformUpdateStatusInputBox>
                        <StaffPerformUpdateStatusInputBox>
                            <MyPageUserInput
                                label={"메모 여부"}
                                type={"checkbox"}
                                checked={hasMemo}
                                setData={(e) => {
                                    setHasMemo(!hasMemo)
                                }}
                            />
                        </StaffPerformUpdateStatusInputBox>
                        <PerformColumnSubControlBox>
                            <PerformColumnSubSaveButton
                                onClick={() => {
                                    handleSaveDate()
                                }}
                            >
                                저장
                            </PerformColumnSubSaveButton>
                        </PerformColumnSubControlBox>
                    </StaffPerformUpdateStatusMainBox>
                </StaffPerformUpdateStatusBox>

                <div className="p-2 rounded-md bg-gray-50 border text-red-700">
                    <table>
                        <thead>
                            <tr>
                                <th>Format</th>
                                <th>Output</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr><td><code>YY</code></td><td>18</td><td>Two-digit year</td></tr> */}
                            <tr>
                                <td>
                                    <code>YYYY</code>
                                </td>
                                <td>2018</td>
                                <td>Four-digit year</td>
                            </tr>
                            {/* <tr><td><code>M</code></td><td>1-12</td><td>The month, beginning at 1</td></tr> */}
                            <tr>
                                <td>
                                    <code>MM</code>
                                </td>
                                <td>01-12</td>
                                <td>The month, 2-digits</td>
                            </tr>
                            {/* <tr><td><code>MMM</code></td><td>Jan-Dec</td><td>The abbreviated month name</td></tr>
                    <tr><td><code>MMMM</code></td><td>January-December</td><td>The full month name</td></tr>
                    <tr><td><code>D</code></td><td>1-31</td><td>The day of the month</td></tr> */}
                            <tr>
                                <td>
                                    <code>DD</code>
                                </td>
                                <td>01-31</td>
                                <td>The day of the month, 2-digits</td>
                            </tr>
                            {/* <tr><td><code>d</code></td><td>0-6</td><td>The day of the week, with Sunday as 0</td></tr> */}
                            <tr>
                                <td>
                                    <code>dd</code>
                                </td>
                                <td>Su-Sa</td>
                                <td>The min name of the day of the week</td>
                            </tr>
                            {/* <tr><td><code>ddd</code></td><td>Sun-Sat</td><td>The short name of the day of the week</td></tr>
                    <tr><td><code>dddd</code></td><td>Sunday-Saturday</td><td>The name of the day of the week</td></tr> */}
                            {/* <tr><td><code>H</code></td><td>0-23</td><td>The hour</td></tr> */}
                            <tr>
                                <td>
                                    <code>HH</code>
                                </td>
                                <td>00-23</td>
                                <td>The hour, 2-digits</td>
                            </tr>
                            {/* <tr><td><code>h</code></td><td>1-12</td><td>The hour, 12-hour clock</td></tr>
                    <tr><td><code>hh</code></td><td>01-12</td><td>The hour, 12-hour clock, 2-digits</td></tr>
                    <tr><td><code>m</code></td><td>0-59</td><td>The minute</td></tr> */}
                            <tr>
                                <td>
                                    <code>mm</code>
                                </td>
                                <td>00-59</td>
                                <td>The minute, 2-digits</td>
                            </tr>
                            {/* <tr><td><code>s</code></td><td>0-59</td><td>The second</td></tr> */}
                            <tr>
                                <td>
                                    <code>ss</code>
                                </td>
                                <td>00-59</td>
                                <td>The second, 2-digits</td>
                            </tr>
                            {/* <tr><td><code>SSS</code></td><td>000-999</td><td>The millisecond, 3-digits</td></tr>
                    <tr><td><code>Z</code></td><td>+05:00</td><td>The offset from UTC, ±HH:mm</td></tr>
                    <tr><td><code>ZZ</code></td><td>+0500</td><td>The offset from UTC, ±HHmm</td></tr>
                    <tr><td><code>A</code></td><td>AM PM</td><td></td></tr>
                    <tr><td><code>a</code></td><td>am pm</td><td></td></tr> */}
                        </tbody>
                    </table>
                </div>

                <div className="mt-2 p-2 rounded-md bg-gray-50 border text-blue-700">
                    메모여부: 날짜에 대한 메모 작성란 여부
                </div>
            </StaffPerformUpdateStatusList>
        </StaffPerformUpdateDateContainer>
    )
}
