import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { MarketerTableDataList } from "../../../constants/ConstMarketer"
import { Link, useParams } from "react-router-dom"
import { ModalContainer } from "../../../components/ModalContainer"
import {
    addItemMarketerGroup,
    deleteItemMarketerInvite,
    getItemMarketerByUser,
    getItemMarketerGroups,
    getItemMarketerInviteds,
    getItemMarketers,
    updateItemMarketerInviteState,
} from "../../../apis/api/boardItems"
import MyPageGroupMarketerList from "./MyPageGroupMarketerList"
import BrandItemMarketerGroup from "../../../types/brandItems/marketers/BrandItemMarketerGroup"
import { UserTypes } from "../../../types/users/UserTypes"
import { useUserStore } from "../../../zustand/store"
import MyPageGroupList from "./MyPageGroupList"
import { EditStateTypes, WriteTypes } from "../../../types/EditorTypes"
import AccessRules from "../../../rules/AccessRules"
import MyPageGroupDeclinedList from "./MyPageGroupDeclinedList"
import { FilterInputCombo, FilterInputText } from "../../../components/brandItems/FilterInputs"
import {
    GroupMarketerContentHead,
    GroupMarketerLayout,
    GroupMarketerListContainer,
    GroupMarketerListLayout,
} from "./MyPageGroupStyle"
import MyPageGroupMarketerDetail from "./MyPageGroupMarketerDetail"
import { MarketerStatusTypes } from "../../../types/brandItems/BrandItemTypes"
import { PerformListControlSection } from "../perform/MyPagePerformStyle"
import { Pagination } from "../../../components/Pagination"
import { InvitedCountContainer, MarketerToggleButton } from "../marketer/MyPageMarketerStyle"
import { PaginationStyles } from "../../../types/StyleTypes"
import BrandItemMarketer from "../../../types/brandItems/marketers/BrandItemMarketer"
import MyPageGroupInviteList from "./MyPageGroupInviteList"
import MyPageMarketerTable from "../marketer/MyPageMarketerTable"
import { ITEM_PAGE_SIZES } from "../../../constants/ConstItem"
import Utils from "../../../utils/Utils"
import SpinLoading, { SpinLoadingBackgroundType } from "../../../components/loading/SpinLoading"
import BrandItem from "../../../types/brandItems/BrandItem"

const MAX_PAGE_LENGTH = 5

export default function MyPageGroupMarketer() {
    const { user, actions, groupInviteCount } = useUserStore()

    const { itemID } = useParams()

    const [groupMarketerListShow, setGroupMarketerListShow] = useState(false)
    const [groupMarketerListType, setGroupMarketerListType] = useState(WriteTypes.READ) // Read일 경우 마케터 리스트, Write일 경우 마케터 초대 리스트

    const [marketerHasGroup, setMarketerHasGroup] = useState(true) // 마케터가 그룹에 속해있는지 여부 (혹시 찰나에 그룹 추가나 다른 이벤트를 방지하기 위해 true를 기본값으로 준다.)
    const [selectedGroup, setSelectedGroup] = useState(new BrandItemMarketer())
    const [selectedItemMarketer, setSelectedItemMarketer] = useState(new BrandItemMarketer())

    const [search, setSearch] = useState("")
    const [marketerGroupType, setMarketerGroupType] = useState(MarketerStatusTypes.CONFIRM) // CONFIRM: 그룹원 리스트, WAIT: 초대 리스트, BLOCK: 차단 리스트

    const [isMyGroupLoading, setMyGroupLoading] = useState(true)
    const [myItemMarketer, setMyItemMarketer] = useState(new BrandItemMarketer())
    const [marketers, setMarketers] = useState([])
    const [itemMarketerInvites, setItemMarketerInvites] = useState([])

    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    useEffect(() => {
        getMyItemMarketer()
    }, [itemID])

    useEffect(() => {
        if (marketerGroupType == MarketerStatusTypes.CONFIRM) {
            getMarketers(1, pageSize, "")
        } else {
            getItemMarketerInvites(marketerGroupType, pageIndex, pageSize, search)
        }
    }, [itemID, marketerGroupType])

    const getMarketers = async (_pageIndex, _pageSize, _search) => {
        setPageIndex(_pageIndex)

        const response = await getItemMarketers(itemID, _pageIndex, _pageSize, _search)
        if (response !== undefined) {
            setMarketers(response.items)
            setItemCount(response.count)
        }
    }
    const getItemMarketerInvites = async (_marketerGroupType, _pageIndex, _pageSize, _search) => {
        setPageIndex(_pageIndex)

        const response = await getItemMarketerInviteds(
            itemID,
            user.id,
            _marketerGroupType,
            _pageIndex,
            _pageSize,
            _search,
        )
        if (response !== undefined) {
            setItemMarketerInvites(response.items)
            setItemCount(response.count)
        }
    }

    // 자신의 아이템 마케터 정보를 가져온다.
    const getMyItemMarketer = () => {
        setMyGroupLoading(true)
        getItemMarketerByUser(itemID, user.id)
            .then((response) => {
                if (response.items.length > 0) {
                    setMyItemMarketer(response.items[0])
                } else {
                    setMyItemMarketer(new BrandItemMarketer())
                }
                setMyGroupLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setMyGroupLoading(false)
            })
    }

    const handleSearchMarketer = (_search) => {
        getMarketers(pageIndex, pageSize, _search)
    }
    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }
    const handlePageIndex = (page) => {
        getMarketers(page, pageSize, search)
    }
    const handleMarketerGroupType = (_type) => {
        setMarketerGroupType(_type)
        getItemMarketerInvites(_type, pageIndex, pageSize, search)
    }

    const handleOpenMarketerList = (group) => {
        setSelectedGroup(group)
        setGroupMarketerListType(WriteTypes.READ)
        setGroupMarketerListShow(true)
    }

    const handleOpenInvite = (itemMarketer) => {
        if (myItemMarketer.id < 0) {
            alert("내 정보를 찾을 수 없습니다.")
            return
        }
        if (!myItemMarketer.isInvitePermission) {
            alert("초대 권한이 없습니다.")
            return
        }

        setSelectedItemMarketer(itemMarketer)
        setGroupMarketerListShow(true)
    }

    const handleSubmitInvite = async (inviteID, editStateType) => {
        if (Utils.isNullOrUndefined(inviteID) || inviteID < 0) {
            alert("초대 정보를 찾을 수 없습니다.")
            return
        }
        if (editStateType === EditStateTypes.DONE) {
            if (myItemMarketer.parentID >= 0) {
                alert("이미 하위 마케터로 등록되어있습니다.")
                return
            }

            // 수락
            await updateItemMarketerInviteState(inviteID, MarketerStatusTypes.CONFIRM).then((response) => {
                if (response) {
                    getItemMarketerInvites(marketerGroupType, pageIndex, pageSize, search)
                }
            })
            getMyItemMarketer()
        } else if (editStateType === EditStateTypes.FAILED) {
            // 거절
            await deleteItemMarketerInvite(inviteID).then((response) => {
                if (response) {
                    getItemMarketerInvites(marketerGroupType, pageIndex, pageSize, search)
                }
            })
        } else if (editStateType === EditStateTypes.DISABLED) {
            // 차단
            await updateItemMarketerInviteState(inviteID, MarketerStatusTypes.BLOCK).then((response) => {
                if (response) {
                    getItemMarketerInvites(marketerGroupType, pageIndex, pageSize, search)
                }
            })
        } else if (editStateType === EditStateTypes.WAIT) {
            // 차단 해제
            await updateItemMarketerInviteState(inviteID, MarketerStatusTypes.WAIT).then((response) => {
                if (response) {
                    getItemMarketerInvites(marketerGroupType, pageIndex, pageSize, search)
                }
            })
        }
        actions.updateGroupInviteCount(itemID, user.id)
    }

    if (isMyGroupLoading) {
        return (
            <div className="flex flex-center w-full h-full">
                <SpinLoading
                    isLoading={true}
                    top={"0"}
                    backgroundType={SpinLoadingBackgroundType.NONE}
                    message={"정보를 불러오는 중입니다."}
                />
            </div>
        )
    }

    if (myItemMarketer.marketerStatusType === MarketerStatusTypes.WAIT) {
        return <div className="flex flex-center w-full h-full">브랜드의 승인 대기 중입니다.</div>
    }
    console.log("hi")

    return (
        <div className="flex flex-col w-full h-full">
            <GroupMarketerLayout>
                <MyPageGroupMarketerDetail
                    myItemMarketer={myItemMarketer}
                    getMyItemMarketer={getMyItemMarketer}
                    openMarketerList={handleOpenMarketerList}
                    openInvite={handleOpenInvite}
                    myGroupLoading={isMyGroupLoading}
                />

                <div className="max-lg:block lg:hidden">
                    <GroupMarketerContentHead className="max-lg:mt-12 max-sm:mb-4 h-auto">
                        <span className="text-lg font-semibold">그룹 목록</span>
                    </GroupMarketerContentHead>
                </div>
                <GroupMarketerListLayout>
                    <GroupMarketerContentHead className="max-sm:flex-col-reverse sm:flex-row justify-between max-sm:h-auto sm:h-16 max-sm:mb-2">
                        {marketerGroupType == MarketerStatusTypes.CONFIRM && (
                            <PerformListControlSection className="max-sm:w-full max-lg:w-auto max-sm:mt-2">
                                <FilterInputText
                                    value={search}
                                    setValue={setSearch}
                                    submit={() => {
                                        handleSearchMarketer(search)
                                    }}
                                    width={"48"}
                                    placeholder={"이름 혹은 이메일"}
                                />
                            </PerformListControlSection>
                        )}

                        <PerformListControlSection last className="max-sm:w-full max-lg:w-auto space-x-2">
                            <Pagination
                                page={pageIndex}
                                setPage={handlePageIndex}
                                pageSize={pageSize}
                                itemCount={itemCount}
                                maxPageButtons={MAX_PAGE_LENGTH}
                                styleType={PaginationStyles.NoneBGCircleButtons}
                            />
                            {/* <div className="w-2 max-sm:hidden"></div> */}
                            <MarketerToggleButton
                                className="relative"
                                marketerstate={marketerGroupType == MarketerStatusTypes.CONFIRM ? 0 : 1}
                                onClick={() => {
                                    handleMarketerGroupType(MarketerStatusTypes.CONFIRM)
                                }}
                            >
                                그룹원 목록
                                {/* {itemMarketerInvites.length > 0 &&
                                    <InvitedCountContainer>
                                        {itemMarketerInvites.length}
                                    </InvitedCountContainer>
                                } */}
                            </MarketerToggleButton>
                            <MarketerToggleButton
                                className="relative max-sm:!ml-auto"
                                marketerstate={marketerGroupType == MarketerStatusTypes.WAIT ? 0 : 1}
                                onClick={() => {
                                    handleMarketerGroupType(MarketerStatusTypes.WAIT)
                                }}
                            >
                                초대 목록
                                {groupInviteCount > 0 && (
                                    <InvitedCountContainer className="-top-2 -right-2">
                                        {groupInviteCount}
                                    </InvitedCountContainer>
                                )}
                            </MarketerToggleButton>
                            <MarketerToggleButton
                                marketerstate={marketerGroupType == MarketerStatusTypes.BLOCK ? 0 : 1}
                                onClick={() => {
                                    handleMarketerGroupType(MarketerStatusTypes.BLOCK)
                                }}
                            >
                                차단 목록
                            </MarketerToggleButton>
                        </PerformListControlSection>
                    </GroupMarketerContentHead>

                    <GroupMarketerListContainer>
                        {marketerGroupType == MarketerStatusTypes.CONFIRM ? (
                            <MyPageMarketerTable
                                isManage={false}
                                item={new BrandItem()}
                                marketers={marketers}
                                openInvite={handleOpenInvite}
                            />
                        ) : (
                            <MyPageGroupInviteList
                                itemMarketerInvites={itemMarketerInvites}
                                openMarketerList={handleOpenMarketerList}
                                submitInvite={handleSubmitInvite}
                                marketerGroupType={marketerGroupType}
                            />
                        )}
                    </GroupMarketerListContainer>
                </GroupMarketerListLayout>
            </GroupMarketerLayout>

            <ModalContainer
                show={groupMarketerListShow}
                setShow={setGroupMarketerListShow}
                isBlur={true}
                fadeDown={true}
                closeByBackground
            >
                <MyPageGroupMarketerList
                    itemID={itemID}
                    parentItemMarketer={selectedItemMarketer}
                    getCurrentMarketers={() => {
                        getMarketers(pageIndex, pageSize, search)
                    }}
                />
            </ModalContainer>
        </div>
    )
}
