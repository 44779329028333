import React, { useEffect, useState, useRef, useCallback } from "react";
import tw from 'tailwind-styled-components'
import { deleteItemPerformValue, getItemPerformValue, getItemUserAccess, updateItemPerformValue, updatePerformColumnFileEditState } from "../../../apis/api/boardItems";
import { downloadFile, downloadFileZip, getCommonFile, uploadFile, uploadImage } from "../../../apis/api/files";
import { ImageBase64 } from "../../../components/ImageContainer";
import { useDragAndDrop } from "../../../hooks/useFileDragAndDrop";
import { PerformFileTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import PerformValueFile from "../../../types/brandItems/performValues/PerformValueFile";

import { EditStateTypes, QueryTypes } from "../../../types/EditorTypes";
import CommonFile from "../../../types/files/CommonFile";
import ImageFile from "../../../types/files/ImageFile";
import { PerformFileIcon, PerformValueBox, PerformValueFileDownloadZip, PerformValueInputLayout, PerformValueInputSubTitle, PerformValueInputTitle } from "./MyPagePerformStyle";
import { RightCaretIcon, LeftCaretIcon, FileGrid, FileIconImage, FileIconBlank, FileLayout, FilePreviewCell, FileUploadBoxDesktop, FileTypeTitle, FileUploadIconContainer, FileInputLabel, FileImageContainer, FileImageBox, FileInfoBox, FileIconUpload, FileIconDelete, FileDeleteIconContainer, FileIconDownload, FileNameSpan, FileInfoControlBox, FileContentBox, FileDetailBox, FileUploadLayout, FileUploadIcon, FileUploadDragSpan, FileImageDetailBox, FileExpandIcon, FileIconImageDownload, FileImageDetailIcons, FileImageDetailIconContainer, FileZeroMessageSpan, FileGridContainer, FileUploadBoxMobile } from "./PerformValueFileStyle";
import { ImageViewer } from '../../../components/ImageViewer';
import AccessRules from "../../../rules/AccessRules";
import { useUserStore } from '../../../zustand/store'
import { UserTypes } from './../../../types/users/UserTypes';
import FileUtils from "../../../utils/FileUtils";
import { FileInputText } from "../../../components/boards/BoardFileUploaderStyle";
import Utils from "../../../utils/Utils";


const FileInput = ({ id, onChange }) => {
    return (
        <React.Fragment>
            <FileInputLabel for={`input-perform-file-${id}`}>
                {/* <FileIconUpload /> */}
                파일 선택
            </FileInputLabel>
            <input id={`input-perform-file-${id}`} className="w-0 hidden" type="file" onChange={onChange} />
        </React.Fragment>
    )
}

export const FileContainer = ({ fileValue, column, perform, index, getFileValue, handleViewer, readOnly = false }) => {
    const [file, setFile] = useState(new CommonFile())

    useEffect(() => {
        getFile()
    }, [fileValue])

    const getFile = async () => {
        if (fileValue.fileID >= 0) {
            getCommonFile(fileValue.fileID).then(response => {
                setFile(response)
            })
        }
    }

    const handleDelete = async () => {
        if (confirm("파일을 삭제하시겠습니까?")) {
            const response = await deleteItemPerformValue(column.id, perform.id, { file_id: fileValue.fileID })

            if (response) {
                getFileValue()
            } else {
                alert("삭제 실패했습니다.")
            }
        }
    }

    const handleDownload = () => {
        downloadFile(file.fileURL, file.name)
    }

    const handleOpenViewer = () => {
        if (handleViewer !== undefined) {
            handleViewer(fileValue.fileID)
        }
    }

    return (
        <FileLayout isTemp={fileValue.editType === EditStateTypes.EDITED}>
            <FileInfoBox>
                <FileNameSpan>
                    {index + 1}. {file.name}
                </FileNameSpan>
                <FileInfoControlBox>
                    {(AccessRules.performValueWrite(column, perform) && !readOnly) && (
                        <FileDeleteIconContainer>
                            <FileIconDelete onClick={() => { handleDelete() }} />
                        </FileDeleteIconContainer>
                    )}
                </FileInfoControlBox>
            </FileInfoBox>
            <FileContentBox>
                {fileValue.fileType === PerformFileTypes.COMMON && (
                    <FileUploadIconContainer hasFile={file.id >= 0}>
                        {file.id >= 0 ? (
                            <FileIconDownload onClick={() => { handleDownload() }} />
                        ) : (
                            <FileIconBlank />
                        )}
                    </FileUploadIconContainer>
                )}
                {fileValue.fileType === PerformFileTypes.IMAGE && (
                    fileValue.fileID >= 0 ? (
                        <FileImageContainer>
                            <FileImageBox>
                                <ImageBase64 imageID={fileValue.fileID} />
                            </FileImageBox>
                            {handleViewer !== undefined && (
                                <FileImageDetailBox>
                                    <FileImageDetailIcons>
                                        <FileImageDetailIconContainer onClick={() => { handleOpenViewer() }}>
                                            <FileExpandIcon />
                                        </FileImageDetailIconContainer>
                                        <FileImageDetailIconContainer onClick={() => { handleDownload() }}>
                                            <FileIconImageDownload />
                                        </FileImageDetailIconContainer>
                                    </FileImageDetailIcons>
                                </FileImageDetailBox>
                            )}
                        </FileImageContainer>
                    ) : (
                        <FileUploadIconContainer>
                            <FileIconImage />
                        </FileUploadIconContainer>
                    )
                )}
            </FileContentBox>

            <FileDetailBox>
                <span>{file.user.name}</span>
                <span>{fileValue.updated}</span>
            </FileDetailBox>
        </FileLayout>
    )
}

export const FileUpload = ({ itemID, columnID, performID, getFileValue, setUpdated, addUploadedPerformValueFile=null }) => {
    const [files, setFiles] = useState([])

    const dragRef = useRef()
    const fileID = useRef(0)

    const onChangeFiles = useCallback((e) => {
        let selectFiles = [];
        let tempFiles = files;

        if (e.type === "drop") {
            selectFiles = e.dataTransfer.files;
        } else {
            selectFiles = e.target.files;
        }

        for (const file of selectFiles) {
            tempFiles = [
                ...tempFiles,
                {
                    id: fileID.current++,
                    object: file
                }
            ];
        }

        setFiles(tempFiles);
    }, [files])
    const isDragging = useDragAndDrop({ onChange: onChangeFiles, dragRef: dragRef })

    useEffect(() => {
        if (files.length > 0) {
            getItemUserAccess(itemID, true).then(response => {
                if (response) {
                    uploadFiles()
                    setFiles([])
                } else {
                    alert("업로드 권한이 없습니다.")
                    setFiles([])
                }
            })
            fileID.current = 0
        }
    }, [files])

    const uploadFiles = async () => {
        for (let i=0; i<files.length; i++) {
            const file = files[i].object
            if (FileUtils.isFileOverVolume(FileUtils.getFileSize(file), 10)) {
                alert("크기가 10MB가 초과되는 파일이 있습니다.")
                return
            }
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i].object

            let commonFile = null
            let fileTypeID = PerformFileTypes.NONE

            if (file.type.includes("image")) {
                commonFile = await uploadImage(file)
                fileTypeID = PerformFileTypes.IMAGE
            } else {
                commonFile = await uploadFile(file)
                fileTypeID = PerformFileTypes.COMMON
            }

            if (!(commonFile.id >= 0)) {
                continue
            } 
            if (!Utils.isNullOrUndefined(addUploadedPerformValueFile)) {
                // 실적이 생성되기 전 파일을 업로드할 경우 여기에 업로드한 파일 정보를 넘겨준다.
                let uploadedPerformValueFile = new PerformValueFile()
                uploadedPerformValueFile.columnID = columnID
                uploadedPerformValueFile.performID = performID
                uploadedPerformValueFile.fileID = commonFile.id
                uploadedPerformValueFile.fileType = fileTypeID
                addUploadedPerformValueFile(uploadedPerformValueFile)
            } else {
                const isUploaded = await updateItemPerformValue(columnID, performID, { file_id: commonFile.id, file_type: fileTypeID })
                if (isUploaded) {
                    if (!Utils.isNullOrUndefined(getFileValue)) {
                        getFileValue()
                    }
                } else {
                    // 에러 메세지 - 신규 기능
                    alert("업로드 실패했습니다.")
                }
            }

        }

        // 새로고침을 위해 파일을 한번이라도 업데이트했다는 플래그를 보낸다.
        setUpdated(true)
    }

    return (
        <FileUploadLayout>
            {/* 파일 업로드 */}
            <FileUploadBoxDesktop ref={dragRef}>
                <div className="flex items-center absolute translate-x-11 border">
                    <div className="flex flex-col mr-12">
                        <FileUploadIcon isDragging={isDragging} />
                        <FileUploadDragSpan>
                            파일을 드래그하거나 선택해주세요.
                        </FileUploadDragSpan>
                        <FileInputText className="mt-0 text-blue-500">각 파일 당 최대 파일 크기 : 10MB</FileInputText>
                    </div>
                    <FileInput id={`${columnID}-${performID}`} onChange={onChangeFiles} />
                </div>
            </FileUploadBoxDesktop>
            <FileUploadBoxMobile>
                <div className="flex flex-center w-full h-full absolute">
                    <FileInput id={`${columnID}-${performID}-mobile`} onChange={onChangeFiles} />
                    <FileInputText className="text-sm ml-2 mt-0">각 파일 당 최대 파일 크기 : 10MB</FileInputText>
                </div>
            </FileUploadBoxMobile>
        </FileUploadLayout>
    )
}



const FileEdit = ({column, perform, closeEditComponent, showViewerComponent }) => {
    // const { user, userType, userDetail } = useUserStore()
    const {userType} = useUserStore(state => state)
    const { user } = useUserStore()
    const [fileValues, setFileValues] = useState([])
    const updatedRef = useRef(false)
    
    useEffect(() => {
        getFileValue()
        return () => {
            updatePerformColumnFileEditState(column.id, perform.id, EditStateTypes.DONE)
            closeEditComponent(updatedRef.current)
        }
    }, [])

    const setUpdated = (b) => {
        updatedRef.current = b
    }

    const getFileValue = async () => {
        const valueResponse = await getItemPerformValue(column.id, perform.id)

        let newFileValues = []
        valueResponse.map(response => {
            const newFileValue = new PerformValueFile()
            newFileValue.convertByResponse(response)
            newFileValues.push(newFileValue)
        })
        setFileValues(newFileValues)
    }

    const handleViewer = (selectedFileID) => {
        const closeViewer = () => {
            showViewerComponent(false)
        }

        showViewerComponent(
            true,
            (<ImageViewer
                selectedFileID={selectedFileID}
                fileValues={fileValues}
                closeViewer={closeViewer}
            />)
        )
    }

    const getFile = async (fileValue) => {
        if (fileValue.fileID >= 0) {
            let response = await getCommonFile(fileValue.fileID)
            return(response)
        }
    }
    
    const handleZipDownload = async () => {
        if (fileValues.length === 0) {
            return
        }
        var files = []
        for(let fileValue of fileValues) {
            var file = await getFile(fileValue)
            files.push({...file});
        }
        downloadFileZip(files, `${column.name}_filedown` )
    }

    return (
        <PerformValueInputLayout className="justify-between">
            <div className="flex flex-col h-full overflow-y-auto scroll-transparent scroll-overlay">
                <PerformValueInputTitle>
                    <span>{column.name}</span>
                    <PerformValueFileDownloadZip onClick={() => {handleZipDownload()}}>
                        {`일괄 다운로드`}
                    </PerformValueFileDownloadZip>
                </PerformValueInputTitle>
                {fileValues.length > 0 ? (
                    <FileGridContainer>
                        <FileGrid>
                            {fileValues.map((fileValue, idx) => (
                                <FileContainer
                                    fileValue={fileValue}
                                    column={column}
                                    perform={perform}
                                    key={idx}
                                    index={idx}
                                    getFileValue={getFileValue}
                                    handleViewer={handleViewer}
                                />
                            ))}
                        </FileGrid>
                    </FileGridContainer>
                ) : (
                    <FileZeroMessageSpan>
                        {userType === UserTypes.BRAND ?
                            '업로드 된 파일이 없습니다.'
                            :
                            '파일을 업로드해주세요'
                        }
                    </FileZeroMessageSpan>
                )}
            </div>
            {AccessRules.performValueWrite(column, perform) && (
                <FileUpload itemID={column.itemID} columnID={column.id} performID={perform.id} getFileValue={getFileValue} setUpdated={setUpdated} />
            )}
        </PerformValueInputLayout>
    )
}

export const PerformValueFileCell = ({column, perform, value, setEditComponent, showViewerComponent }) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [showEditComponent, closeEditComponent] = setEditComponent

    const handleChange = (index) => {
        // console.log(column.combos[selectedIndex].id)
    }

    const handleShowEdit = () => {
        showEditComponent(
            <FileEdit
                column={column}
                perform={perform}
                closeEditComponent={closeEditComponent}
                showViewerComponent={showViewerComponent}
            />
        )
    }

    return (
        <PerformValueBox>
            <FilePreviewCell onClick={() => { handleShowEdit() }}>
                <PerformFileIcon />
                <span>{value.fileID}</span>
            </FilePreviewCell>
        </PerformValueBox>
    )
}