/* eslint-disable react/react-in-jsx-scope */
import react, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import tw from "tailwind-styled-components"
import { useMouseHover } from "../../hooks/useMouseHover"

const CustomProgressBarContainer = tw.div`
    py-2
`
const CustomProgressBarStyled = styled.div`
    width: ${(props) => {
        return props.width ? props.width + "rem" : "6rem"
    }};
    height: ${(props) => (props.height ? props.height + "rem" : "0.5rem")};
    border-radius: 9999px;
    position: relative;
`

const CustomProgressBarColor = styled(CustomProgressBarStyled)`
    /* background: linear-gradient(90deg, rgba(0,151,255,1) 0%, rgba(9,9,121,1) 100%); */
    /* background-color: gray; */
`

const CustomProgressBarBack = tw(CustomProgressBarColor)`
    bg-gray-200 shadow-md justify-center
`

const CustomProgressBar = styled.div`
    /* background-color: gray; */
    width: ${({ percent }) => percent}%;

    height: 100%;
    /* border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px; */
    border-radius: 9999px;
    -webkit-mask: linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0);
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: ${({ color }) =>
            color === "green"
                ? "linear-gradient(90deg, rgba(130, 255, 150,1) 0%, rgba(7, 200, 7,1) 100%)"
                : "linear-gradient(90deg, rgba(0,151,255,1) 0%, rgba(9,9,121,1) 100%)"};
    }
`

export const ProgressBar = ({ percent, width = 6, height = 0.5, color = "" }) => {
    return (
        <CustomProgressBarContainer width={width} height={height}>
            <CustomProgressBarBack width={width} height={height}>
                <CustomProgressBar percent={percent} color={color} />
            </CustomProgressBarBack>
        </CustomProgressBarContainer>
    )
}
