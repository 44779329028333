import React, { useEffect, useRef, useState } from "react"
import {
    AcceptButton,
    BottomButtonCon,
    DeleteButton,
    EditAcceptButton,
    EditAcceptContainer,
    EditAcceptMenuBox,
    EditAcceptMenuContainer,
    EditAcceptPreviewButton,
    ItemControlBox,
    ItemInputRow,
    ItemInputRowEditor,
    ItemStatusControlBox,
    PreviewButton,
    ResetButton,
    SaveButton,
} from "../item/brand/ItemInputFormStyle"

import {
    BrandItemCombo,
    BrandItemFile,
    BrandItemInput,
    BrandItemEditor,
    BrandItemTextArea,
    BrandItemImage,
} from "../../../components/brandItems/Inputs"
// import Utils from "../../../../utils/Utils"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import { PointModalTitle, PointSetWriteLayout } from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"

const MyPagePointSetWrite = ({ showModal, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)
    const [pointSet, setPointSet] = useState(new PointSet())

    const [pointReferral, setPointReferral] = useState(0)
    const [pointReferred, setPointReferred] = useState(0)
    const [limitPriceUser, setLimitPriceUser] = useState(0)
    const [limitPriceDay, setLimitPriceDay] = useState(0)

    useEffect(() => {
        if (showModal) {
            getItemData()
        }
    }, [showModal])

    const getItemData = async () => {
        // 아이템 등록시에는 temp만 보여준다.
        setLoading(true)

        const _pointSet = await PointApi.getPointSet()
        setPointSet(_pointSet)

        if (_pointSet.id < 0) {
            setLoading(false)
            return
        }

        setPointReferral(_pointSet.pointReferral)
        setPointReferred(_pointSet.pointReferred)
        setLimitPriceUser(_pointSet.limitPriceUser)
        setLimitPriceDay(_pointSet.limitPriceDay)
        setLoading(false)
    }

    const handleSubmit = async () => {
        let bResult = false

        let errorMessages = []
        if (Utils.isStringNullOrEmpty(pointReferral) || pointReferral < 0) {
            errorMessages.push("추천인이 받을 포인트는 0이상이여야합니다.")
        }
        if (Utils.isStringNullOrEmpty(pointReferred) || pointReferred < 0) {
            errorMessages.push("추천받은 사람이 받을 포인트는 0이상이여야합니다.")
        }
        if (Utils.isStringNullOrEmpty(limitPriceUser) || limitPriceUser < 0) {
            errorMessages.push("인당 하루 제한 포인트는 0이상이여야합니다.")
        }
        if (Utils.isStringNullOrEmpty(limitPriceDay) || limitPriceDay < 0) {
            errorMessages.push("총 하루 제한 포인트는 0이상이여야합니다.")
        }

        if (errorMessages.length > 0) {
            alert(errorMessages.join("\n"))
            return
        }

        let requestData = pointSet.convertToRequest()
        requestData["point_referral"] = pointReferral
        requestData["point_referred"] = pointReferred
        requestData["limit_price_user"] = limitPriceUser
        requestData["limit_price_day"] = limitPriceDay

        bResult = await PointApi.updatePointSet(requestData)

        if (bResult) {
            alert("저장되었습니다.")
            getItemData()
            setShowModal(false)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <PointSetWriteLayout className="sm:h-auto sm:max-h-[90vh]">
            <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} />

            <div className="flex flex-col w-full h-full p-4 overflow-y-auto scroll-transparent scroll-overlay">
                <PointModalTitle>포인트 설정</PointModalTitle>
                <div className="flex flex-col w-full p-4">
                    <ItemInputRow className="md:grid-cols-2">
                        <BrandItemInput
                            type="number"
                            label={"추천한 사람이 받을 포인트"}
                            data={pointReferral}
                            setData={setPointReferral}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                        <BrandItemInput
                            type="number"
                            label={"추천받은 사람이 받을 포인트"}
                            data={pointReferred}
                            setData={setPointReferred}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="md:grid-cols-2">
                        <BrandItemInput
                            type="number"
                            label={"인당 하루 제한 포인트"}
                            data={limitPriceUser}
                            setData={setLimitPriceUser}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                        <BrandItemInput
                            type="number"
                            label={"총 하루 제한 받을 포인트"}
                            data={limitPriceDay}
                            setData={setLimitPriceDay}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                    </ItemInputRow>
                </div>

                <div className="flex justify-end items-center w-full h-12 px-4">
                    <button
                        className="flex flex-center px-6 py-2 text-white bg-nightblue-600 hover:bg-nightblue-700 rounded-full"
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        저장
                    </button>
                </div>
            </div>
        </PointSetWriteLayout>
    )
}

export default MyPagePointSetWrite
