import Paginator from "../Paginator"
import BrandItemContract from "./BrandItemContract"

export default class extends Paginator{
    constructor(count=0) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]
        
        this.items = []
        const resultResponse = response["results"]
        if (resultResponse !== undefined) {
            resultResponse.map(result => {
                const contract = new BrandItemContract()
                contract.convertByResponse(result)
                this.items.push(contract)
            })
        }
    }
}