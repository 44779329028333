import React from 'react';
import { PropTypes } from 'prop-types';

export class Dictionary{
    data = {}
    
    constructor(object=undefined) {
        if (object !== undefined && Object.keys(object.data).length > 0) {
            const oldData = object.data
            this.data = {...oldData}
            // Object.keys(oldData).map(objectKey => {
            //     this.push(objectKey, oldData[objectKey])
            // })
        }
    }

    push(key, value) {
        this.data[key] = value
    }

    getKeys() {
        return Object.keys(this.data)
    }

    getLength() {
        return Object.keys(this.data).length
    }

    getValue(key) {
        if (key === undefined) {
            return undefined
        }

        return this.data[key]
    }

    getValues() {
        return this.getKeys().map((key)=> (this.data[key]))
    }

    getFirst() {
        const values = this.getKeys().map((key)=> (this.data[key]))

        if (values.length > 0) {
            return values[0]
        }
    }

    contains(key) {
        let result = false
        if (key === undefined) {
            return result
        }

        this.getKeys().map((k) => {
            if (k === key.toString()) {
                result = true
                return
            }
        })
        return result
    }

    getLast() {
        const values = this.getKeys().map((key)=> (this.data[key]))

        if (values.length > 0) {
            return values[values.length - 1]
        }
    }

    copy() {
        let newData = new Dictionary()
        this.getKeys().map(key => {
            newData.push(key, this.data[key])
        })
        return newData
    }

    delete(key) {
        delete this.data[key]
    }

    sortByKeys() {
        const sortedKeys = Object.keys(this.data).sort()

        let newData = {}
        sortedKeys.map(sortedKey => {
            newData[sortedKey] = this.data[sortedKey]
        })

        this.data = newData
    }
}
