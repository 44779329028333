import { getImageFile, uploadImageBase64 } from "../apis/api/files"
import { IMAGE_SOURCE_DUMMY, IMAGE_SOURCE_TEMPLATE } from "../constants/ConstFile"
import { Dictionary } from "../types/Dictionary"
import { UserTypes } from "../types/users/UserTypes"
import Utils from "./Utils"
import { downloadExcel } from "./ExcelUtils"
import { PerformColumnFormTypes, PerformStaticColumnTypes } from "../types/brandItems/performColumns/PerformColumnTypes"
import { getComboName, getStatusName } from "./brandItem/BrandItemPerformUtils"
import dayjs from "dayjs"

export default class {
    // 유저 타입에따라 쓰기가 가능한 현황만 가져온다.
    static getWriteOnlyStatus(user, column) {
        let statuses = []
        column.statuses.map((columnStatus) => {
            if (user.userType === UserTypes.BRAND && !columnStatus.brandReadOnly) {
                statuses.push(columnStatus)
            } else if (user.userType === UserTypes.MARKETER && !columnStatus.marketerReadOnly) {
                statuses.push(columnStatus)
            } else if (user.userType === UserTypes.STAFF) {
                statuses.push(columnStatus)
            }
        })

        column.statuses = statuses

        return column
    }

    static isColumnNullOrEmpty(coldata) {
        if (!Utils.isStringNullOrEmpty(coldata)) {
            return coldata
        } else {
            return "-"
        }
    }

    static isFileColumnNullOrEmpty(coldata) {
        if (!Utils.isNullOrUndefined(coldata)) {
            return coldata
        } else {
            return "-"
        }
    }

    static excelPerform(selectedItem, columns, rows) {
        var excelheadID = []
        var excelhead = []
        var excelrow = []
        var excelbody = []

        const columnDic = new Dictionary()
        const fileCountDic = new Dictionary() // 각 실적과 파일 컬럼 별 파일 개수 저장용

        columns.map((colData) => {
            if (
                colData.isExcelShow ||
                colData.id === PerformStaticColumnTypes.CALCULATED ||
                colData.id === PerformStaticColumnTypes.CREATED ||
                colData.id === PerformStaticColumnTypes.MARKETER
            ) {
                excelhead.push({
                    col_head_name: colData.name,
                    col_head_id: colData.id,
                })
            }
            excelheadID.push(colData.id)
            columnDic.push(colData.id, colData)
        })
        excelheadID.push(-4, -3, -2)

        rows.map((rowdata) => {
            rowdata.values.map((rowcolData) => {
                if (columnDic.contains(rowcolData.columnID)) {
                    const column = columnDic.getValue(rowcolData.columnID)
                    if (column.formType === PerformColumnFormTypes.COMBO) {
                        const comboName = getComboName(column, rowcolData.comboID)
                        excelrow.push({
                            col_id: column.id,
                            data: this.isColumnNullOrEmpty(comboName),
                        })
                    } else if (column.formType === PerformColumnFormTypes.STATUS) {
                        const statusName = getStatusName(column, rowcolData.statusID)
                        excelrow.push({
                            col_id: column.id,
                            data: this.isColumnNullOrEmpty(statusName),
                        })
                    } else if (column.formType === PerformColumnFormTypes.DATE) {
                        let dateTimeString = null
                        try {
                            dateTimeString = dayjs(rowcolData.dateTime).format(column.dates[0].dateFormat)
                            if (dateTimeString.includes("Invalid")) {
                                dateTimeString = "-"
                            }
                        } catch {
                            //
                        }
                        excelrow.push({
                            col_id: column.id,
                            data: this.isColumnNullOrEmpty(dateTimeString),
                        })
                    } else if (column.formType === PerformColumnFormTypes.TEXT) {
                        if (column.id === PerformStaticColumnTypes.MARKETER) {
                            excelrow.push({
                                col_id: column.id,
                                data: rowdata.itemMarketer.marketerNameChain,
                            })
                        } else {
                            excelrow.push({
                                col_id: column.id,
                                data: this.isColumnNullOrEmpty(rowcolData.text),
                            })
                        }
                    } else if (column.formType === PerformColumnFormTypes.OPTION) {
                        let optionNameList = []
                        rowcolData.optionItems.map((optionItem) => {
                            const columnOption = column.options.find((s) => s.id === optionItem.optionID)
                            if (columnOption && !Utils.isStringNullOrEmpty(columnOption.name)) {
                                optionNameList.push(columnOption.name)
                            }
                        })
                        excelrow.push({
                            col_id: column.id,
                            data: this.isColumnNullOrEmpty(optionNameList.join(",") + "개"),
                        })
                    } else if (column.formType === PerformColumnFormTypes.FILE) {
                        const fileKey = `${rowcolData.columnID}-${rowcolData.performID}`
                        let fileCount = 1
                        if (fileCountDic.contains(fileKey)) {
                            fileCount = fileCountDic.getValue(fileKey)
                        }

                        excelrow.push({
                            col_id: column.id,
                            data: fileCount.toString(),
                        })

                        fileCount += 1
                        fileCountDic.push(fileKey, fileCount)
                    } else if (column.formType === PerformColumnFormTypes.CHECK_BOX) {
                        excelrow.push({
                            col_id: column.id,
                            data: this.isColumnNullOrEmpty(rowcolData),
                        })
                    }
                }
            })
            excelbody.push(excelrow)
            excelrow = []
        })
        downloadExcel(excelhead, excelbody, selectedItem.detail.title)
    }
}
