import React,{useEffect, useState} from "react";
import tw from 'tailwind-styled-components'
import { Contract } from "../ContractCreate";
import { ImageContainer } from "../ImageContainer";

const ContractImageImg = tw.img`
    max-w-[100%] h-auto mb-4
`
const ContractImageViewerLayout = tw.div`
    flex flex-col max-lg:w-screen lg:w-[1200px] max-lg:h-full lg:h-[90vh] max-lg:px-2 max-lg:py-12 lg:p-12
    max-lg:rounded-none lg:rounded-lg bg-gray-200 max-lg:overflow-x-auto overflow-y-auto 
    scroll-transparent scroll-overlay
`

export const ContractImage = ({imageSource}) => {
    return (
        <ContractImageImg
            style={{aspectRatio:"1-1.4142"}}
            src={imageSource}
            alt={null}
        />
    )
}

const ContractImageViewer = ({imageSources, htmlSources}) => {
    return(
        <ContractImageViewerLayout>
            {imageSources != null && imageSources.map((imageSource, idx) => (
                <ContractImage imageSource={imageSource} key={idx} />
            ))}
            {htmlSources != null && htmlSources.map((htmlSource, idx) => (
                <div key={idx} className="bg-white rounded-md w-full h-full">
                    <Contract htmlContract={htmlSource} />
                </div>
            ))}
        </ContractImageViewerLayout>
    )
}

export default ContractImageViewer