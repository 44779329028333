import Paginator from "../Paginator"
import Utils from "../../utils/Utils"
import { AdvertisementCategories } from "./CommonTypes"

export default class {
    constructor() {
        this.id = -1

        this.category = AdvertisementCategories.MAIN_BANNER
        this.title = ""
        this.dateStart = ""
        this.dateEnd = ""
        this.link = ""
        this.image1ID = -1
        this.image2ID = -1
        this.image3ID = -1
        this.image4ID = -1
        this.image1URL = ""
        this.image2URL = ""
        this.image3URL = ""
        this.image4URL = ""
        this.content = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]

        this.category = response["category"]
        this.title = response["title"]
        this.dateStart = response["date_start"]
        this.dateEnd = response["date_end"]
        this.link = response["link"]
        this.image1ID = response["image1_id"]
        this.image2ID = response["image2_id"]
        this.image3ID = response["image3_id"]
        this.image4ID = response["image4_id"]
        this.image1URL = response["image1_url"]
        this.image2URL = response["image2_url"]
        this.image3URL = response["image3_url"]
        this.image4URL = response["image4_url"]
        this.content = response["content"]

        this.created = response["created_shorten"]
        this.updated = response["updated_shorten"]
    }

    convertToRequest() {
        let request = {
            category: this.category,
            title: this.title,
            date_start: this.dateStart,
            date_end: this.dateEnd,
            link: this.link,
            image1_id: this.image1ID,
            image2_id: this.image2ID,
            image3_id: this.image3ID,
            image4_id: this.image4ID,
            content: this.content,
        }

        return request
    }
}
