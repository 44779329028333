import React, { useEffect, useState, useRef } from "react"
import { UserCombo, UserInput } from "../../components/user/Inputs"
import { SignUpRecommanderButton } from "./SignUpStyle"
import { EditStateTypes, EmailDuplicatedTypes } from "../../types/EditorTypes"
import Utils from "../../utils/Utils"

import ModalContainerPortal from "../../components/ModalContainerPortal"
import SignUpRecommanderModal from "./SignUpRecommanderModal"
import { AddressSearch } from "../../components/user/SignUpAddress"
import SignUpRecommanderUserModal from "./SignUpRecommanderUserModal"

import * as UserApi from "../../apis/api/users"
import { useNavigate } from "react-router-dom"

const SignUpUserMarketerRecommand = ({ user }) => {
    const navigate = useNavigate()

    const [currentWorkBuildingName, setCurrentWorkBuildingName] = useState("")
    const [currentWorkAddress1, setCurrentWorkAddress1] = useState("")
    const [currentWorkAddress2, setCurrentWorkAddress2] = useState("")
    const [currentWorkJob, setCurrentWorkJob] = useState("")
    const [currentWorkTel, setCurrentWorkTel] = useState("")

    // 추천인 정보
    const [recommanderKeyword, setRecommanderKeyword] = useState("")
    const [recommanderID, setRecommanderID] = useState(-1)
    const [recommanderModalShow, setRecommanderModalShow] = useState(false)

    const [isEdited, setEdited] = useState(false)

    useEffect(() => {
        if (
            !Utils.isStringNullOrEmpty(currentWorkBuildingName) ||
            !Utils.isStringNullOrEmpty(currentWorkAddress1) ||
            (!Utils.isStringNullOrEmpty(currentWorkJob) && currentWorkJob > 0) ||
            !Utils.isStringNullOrEmpty(currentWorkTel) ||
            (!Utils.isStringNullOrEmpty(recommanderID) && recommanderID > 0)
        ) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [
        currentWorkBuildingName,
        currentWorkAddress1,
        currentWorkAddress2,
        currentWorkJob,
        currentWorkTel,
        recommanderID,
    ])

    const openRecommanderModal = () => {
        setRecommanderModalShow(true)
    }
    const cancelRecommander = () => {
        setRecommanderKeyword("")
        setRecommanderID(-1)
    }

    const handleRecommand = async () => {
        let errorMessages = []

        if (Utils.isStringNullOrEmpty(currentWorkBuildingName)) {
            errorMessages.push("아파트 및 건물 명칭을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(currentWorkAddress1)) {
            errorMessages.push("아파트 및 건물 주소를 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(currentWorkJob) || currentWorkJob < 0) {
            errorMessages.push("회원 직책을 선택해주세요.")
        }
        if (Utils.isStringNullOrEmpty(currentWorkTel)) {
            errorMessages.push("사무실 연락처를 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(recommanderID) || recommanderID < 0) {
            errorMessages.push("추천인을 선택해주세요.")
        }
        if (errorMessages.length > 0) {
            alert(errorMessages.join("\n"))
            return
        }

        const data = {
            current_work_building_name: currentWorkBuildingName,
            current_work_address1: currentWorkAddress1,
            current_work_address2: currentWorkAddress2,
            current_work_job: currentWorkJob,
            current_work_tel: currentWorkTel,
            recommand_id: recommanderID,
        }

        const response = await UserApi.signUpRecommand(data)

        if (response) {
            alert("추천되었습니다.")
            handleHome()
        } else {
            alert("요청 실패했습니다.\n관리자에게 문의해주세요.")
        }
    }

    const handleHome = () => {
        navigate("/")
    }

    return (
        <div className="w-full h-full mt-8 py-4 px-2 flex flex-col items-center">
            <span className="text-4xl font-semibold text-yellow-500">{user.name}님 환영합니다!</span>

            <div className="w-full max-md:aspect-[1000/500] md:aspect-[1000/300] mt-8 rounded-lg bg-gradient-to-tr from-nightblue-700 to-nightblue-500 text-white flex flex-center">
                추천인 정보에 대한 설명
            </div>

            <div className="flex flex-col w-full mt-4">
                <UserInput
                    placeholder={"아파트 및 건물 명칭"}
                    type={"text"}
                    data={currentWorkBuildingName}
                    setData={setCurrentWorkBuildingName}
                    autoComplete={false}
                    maxLength={100}
                />
                {/* <UserInput
                    placeholder={"아파트 및 건물 주소"}
                    type={"text"}
                    data={currentWorkAddress1}
                    setData={setCurrentWorkAddress1}
                    autoComplete={false}
                    maxLength={100}
                /> */}
                <AddressSearch
                    address={currentWorkAddress1}
                    setAddress={setCurrentWorkAddress1}
                    detailAddress={currentWorkAddress2}
                    setDetailAddress={setCurrentWorkAddress2}
                    formType={2}
                />
                {/* <UserInput
                    placeholder={"회원 직책"}
                    type={"text"}
                    data={currentWorkAddress2}
                    setData={setCurrentWorkAddress2}
                    autoComplete={false}
                    maxLength={100}
                /> */}
                <UserCombo
                    label={"회원 직책"}
                    type={"text"}
                    optionKeys={[1, 2, 3, 4]}
                    names={["name1", "name2", "name3", "name4"]}
                    value={currentWorkJob}
                    onChange={(e) => {
                        setCurrentWorkJob(e.target.value)
                    }}
                />
                <UserInput
                    placeholder={"사무실 연락처"}
                    type={"text"}
                    data={currentWorkTel}
                    setData={setCurrentWorkTel}
                    autoComplete={false}
                    maxLength={100}
                />
                <div
                    className="flex flex-col w-full px-0"
                    onClick={() => {
                        openRecommanderModal()
                    }}
                >
                    <UserInput
                        placeholder={"추천인"}
                        type={"text"}
                        data={recommanderKeyword}
                        setData={setRecommanderKeyword}
                        onKeyPress={() => {
                            openRecommanderModal()
                        }}
                    >
                        {recommanderID >= 0 && (
                            <button
                                className="text-red-500 px-2 text-sm"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    cancelRecommander()
                                }}
                            >
                                취소
                            </button>
                        )}
                        <SignUpRecommanderButton>검색</SignUpRecommanderButton>
                    </UserInput>
                </div>

                <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-8 [&>button]:h-12">
                    <button
                        className="text-nightblue-600 disabled:text-slate-600"
                        disabled={isEdited}
                        onClick={() => {
                            handleHome()
                        }}
                    >
                        세일즈랑으로 가기
                    </button>
                    <button
                        className="bg-nightblue-500 hover:bg-nightblue-600 rounded-lg text-white duration-200"
                        onClick={() => {
                            handleRecommand()
                        }}
                    >
                        추천인 등록하고 포인트 받기
                    </button>
                </div>
            </div>

            <ModalContainerPortal
                show={recommanderModalShow}
                setShow={setRecommanderModalShow}
                isBlur={true}
                closeByBackground={false}
            >
                <SignUpRecommanderUserModal
                    recommanderKeyword={recommanderKeyword}
                    setRecommanderKeyword={setRecommanderKeyword}
                    recommanderID={recommanderID}
                    setRecommanderID={setRecommanderID}
                    setShow={setRecommanderModalShow}
                />
            </ModalContainerPortal>
        </div>
    )
}

export default SignUpUserMarketerRecommand
