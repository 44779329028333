import React, { useEffect, useRef, useState } from "react"

// import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../types/DataEnum"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import {
    CouponApplicationStatusTextColor,
    CouponHistoryLayout,
    CouponHistoryList,
    CouponHistorySearchBox,
    CouponTradeModalLayout,
    CouponViewContentInfo,
    PointModalTitle,
    PointSetWriteLayout,
} from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"
import { useInView } from "react-intersection-observer"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { getImageFile } from "../../../apis/api/files"
import { ImageContainer } from "../../../components/ImageContainer"
import { CouponApplicationStatusTypeNames } from "../../../types/points/PointTypes"

import ModalContainerPortal from "../../../components/ModalContainerPortal"
import { MyPageInputModalLayout } from "../MyPageMainStyle"
import GiftiCouponOrder from "../../../types/points/GiftiCouponOrder"
import GiftiCouponTrade from "../../../types/points/GiftiCouponTrade"

const PAGE_SIZE = 20

const MyPageCouponTradeModal = ({ application, showModal, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)

    const [order, setOrder] = useState(new GiftiCouponOrder())
    const [trade, setTrade] = useState(new GiftiCouponTrade())

    const [couponRequestCount, setCouponRequestCount] = useState(0)

    useEffect(() => {
        getOrderData()
        setCouponRequestCount(application.couponRequestCount)
    }, [application])

    const getOrderData = async () => {
        const trade = await PointApi.getGiftiCouponTrade(application.uuid)
        if (Utils.isStringNullOrEmpty(trade.goodsCd)) {
            alert("발송된 쿠폰 정보를 조회할 수 없습니다.")
            return
        }
        setTrade(trade)

        const order = await PointApi.getCouponOrder(application.uuid)
        if (order.id < 0) {
            alert("쿠폰을 조회할 수 없습니다.")
            return
        }
        setOrder(order)
    }

    const handleResend = async () => {
        if (Utils.isStringNullOrEmpty(trade.goodsCd)) {
            return
        }

        if (!confirm("쿠폰 재발송 하시겠습니까?\n※최대 5회까지 발송 가능합니다.")) {
            return
        }

        const errorMessage = await PointApi.requestCouponResend(application.uuid)

        if (Utils.isStringNullOrEmpty(errorMessage)) {
            setCouponRequestCount(couponRequestCount + 1)
            alert("발송 처리 되었습니다.\n재 발송까지 최대 20분 소요됩니다.\n※최대 5회까지 발송 가능합니다.")
        } else {
            alert(errorMessage)
        }
    }

    return (
        <CouponTradeModalLayout>
            {/* <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} /> */}

            <div className="flex flex-col w-full h-full p-4">
                <div className="aspect-square flex-shrink-0 w-full mb-4 rounded overflow-hidden drop-shadow-lg">
                    <img
                        className="w-full h-full object-cover bg-center"
                        src={application.coupon.giftiCouponThumb.goodsImgB}
                        alt={null}
                    />
                </div>
                <span
                    className={`text-lg font-semibold h-7 duration-200 ${
                        Utils.isStringNullOrEmpty(trade.goodsCd)
                            ? "rounded bg-slate-200 text-transparent w-32 animate-pulse"
                            : ""
                    }`}
                >
                    {trade.goodsNm}
                </span>
                <CouponViewContentInfo className="text-[15px]">
                    <span className="key">발송 상태</span>
                    <span className={`value !font-normal ${Utils.isStringNullOrEmpty(trade.goodsCd) ? "loading" : ""}`}>
                        {trade.sendStatusCd}
                    </span>
                </CouponViewContentInfo>
                <CouponViewContentInfo className="text-[15px]">
                    <span className="key">쿠폰 상태</span>
                    <span className={`value !font-normal ${Utils.isStringNullOrEmpty(trade.goodsCd) ? "loading" : ""}`}>
                        {trade.pinStatusNm}
                    </span>
                </CouponViewContentInfo>
                <CouponViewContentInfo className="text-[15px]">
                    <span className="key">쿠폰 만료일시</span>
                    <span className={`value !font-normal ${Utils.isStringNullOrEmpty(trade.goodsCd) ? "loading" : ""}`}>
                        {trade.formatValidPrdEndDt()}
                    </span>
                </CouponViewContentInfo>

                <div
                    className="flex items-center w-full mt-2
                    [&>button]:w-full [&>button]:px-1.5 [&>button]:py-1 [&>button]:rounded-lg
                    [&>button]:duration-200
                "
                >
                    <button
                        className={`bg-nightblue-100 hover:bg-nightblue-200 text-nightblue-600 border-nightblue-400`}
                        onClick={() => {
                            handleResend()
                        }}
                    >
                        쿠폰 메세지 재발송
                        {/* {couponRequestCount > 0 && `(${couponRequestCount}/5)`} */}
                    </button>
                </div>
            </div>
        </CouponTradeModalLayout>
    )
}

export default MyPageCouponTradeModal
