import PerformCalculate from "./PerformCalculate";

export default class{
    constructor() {
        this.id = -1
        this.name = ""

        this.calculates = []
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.name = response["name"]

        this.calculates = []
        response["calculate"].map(result => {
            if (result.id !== undefined) {
                let calculate = new PerformCalculate()
                calculate.convertByResponse(result)
                this.calculates.push(calculate)
            }
        })
    }

    convertToRequest() {
    }
}