import React, { useState, useEffect } from "react"
import { EmailDuplicatedButton, SignUpTelButton, SignUpTelMessageBox } from "../../pages/user/SignUpStyle"
import { EditStateTypes, EmailDuplicatedTypes } from "../../types/EditorTypes"
import {
    MyPageInput,
    MyPageInputBox,
    MyPageInputChildContainer,
    MyPageInputLabel,
    MyPageInputLayout,
    MyPageInputLocationLabel,
} from "./InputsStyle"
import { FormGroup, FormInput } from "./login/LoginStyle"
import Utils from "../../utils/Utils"

function formattedPhoneNumber(phoneNumber) {
    var hyphenatedNumber = ""

    // 에러 처리
    if (Utils.isNullOrUndefined(phoneNumber)) {
        return hyphenatedNumber
    }
    phoneNumber = String(phoneNumber)

    const phoneNumberWithoutHyphens = phoneNumber.replace(/-/g, "")
    if (phoneNumberWithoutHyphens.length === 9 && phoneNumberWithoutHyphens.startsWith("02")) {
        hyphenatedNumber = phoneNumberWithoutHyphens.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3")
    } else if (phoneNumberWithoutHyphens.length === 10) {
        hyphenatedNumber = phoneNumberWithoutHyphens.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    } else if (phoneNumberWithoutHyphens.length === 11) {
        hyphenatedNumber = phoneNumberWithoutHyphens.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    } else {
        hyphenatedNumber = phoneNumberWithoutHyphens
    }
    return hyphenatedNumber
}

// eslint-disable-next-line react/display-name
export const UserInput = React.forwardRef(
    (
        {
            type,
            placeholder,
            data,
            setData,
            onKeyPress,
            onBlur,
            disabled = false,
            editStateType = EditStateTypes.WAIT,
            children,
            autoComplete = false,
            isTel = false,
            maxLength = undefined,
        },
        ref,
    ) => {
        const handlephoneValue = (data) => {
            if (isTel === true) {
                return formattedPhoneNumber(data)
            } else {
                return data
            }
        }

        const handleChange = (e) => {
            if (isTel) {
                setData(e.target.value.replace(/-/g, ""))
            } else {
                setData(e.target.value)
            }
        }

        return (
            <FormGroup>
                <FormInput
                    ref={ref}
                    type={type}
                    className="form__field"
                    placeholder={placeholder}
                    id="name"
                    name="name"
                    autoComplete={autoComplete ? null : "new-password"}
                    value={handlephoneValue(data)}
                    required
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    onMouseOut={onBlur}
                    onClick={(e) => {
                        e.target.focus()
                    }}
                    disabled={disabled}
                    editStateType={editStateType}
                    maxLength={maxLength}
                />
                <label htmlFor="name" className="form__label slect-none">
                    {placeholder}
                </label>
                <div className="flex justify-between items-center w-full h-8">
                    <p></p>
                    <div className="flex z-10 relative">{children}</div>
                </div>
            </FormGroup>
        )
    },
)

export const UserCombo = ({ label, optionKeys, names, value, onChange }) => {
    return (
        <div className="relative flex flex-col w-full mt-4 mb-1 px-2 py-2 border border-slate-400 rounded-lg">
            <span className="absolute left-3 -top-3 bg-white px-1 text-sm text-gray-500">{label}</span>
            <select
                key={value}
                value={value}
                onChange={onChange}
                className="px-2 py-1 bg-slate-100 text-slate-700 focus:border-slate-500 focus:ring-1 focus:ring-slate-500 rounded-md focus:outline-none"
            >
                {optionKeys.map((optionKey, i) => (
                    <option key={optionKey} value={i}>
                        {names[i]}
                    </option>
                ))}
            </select>
        </div>
    )
}

export const UserSMSAuthRequestButton = ({ telAuthState, telAuth }) => {
    return (
        <SignUpTelButton
            edit_state={telAuthState}
            disabled={telAuthState === EditStateTypes.DISABLED}
            onClick={() => {
                telAuth()

                if (telAuthState === EditStateTypes.PENDING) {
                    alert("재 전송되었습니다.")
                }
            }}
        >
            {telAuthState !== EditStateTypes.DONE
                ? telAuthState === EditStateTypes.PENDING
                    ? "재 전송"
                    : "인증 번호 전송"
                : "수정"}
        </SignUpTelButton>
    )
}

export const UserEamilDuplicatedButton = ({
    emailDuplcatedState,
    setEmailDuplcatedState,
    email,
    editStateType,
    handleOnClick,
}) => {
    useEffect(() => {
        if (editStateType === EditStateTypes.DONE) {
            setEmailDuplcatedState(EmailDuplicatedTypes.WAIT)
        }
    }, [email, editStateType])

    return (
        <div className="relative flex">
            <EmailDuplicatedButton
                emailDuplcatedState={emailDuplcatedState}
                disabled={emailDuplcatedState === EmailDuplicatedTypes.DONE}
                onClick={() => {
                    handleOnClick()
                }}
            >
                {emailDuplcatedState === EmailDuplicatedTypes.DONE ? "중복 확인 완료" : "이메일 중복 확인"}
            </EmailDuplicatedButton>
        </div>
    )
}

export const UserSMSAuthSubmitButton = ({ telAuthState, telSubmitState, telSubmitTimeText, telSubmit }) => {
    return (
        <div className="relative flex">
            {telAuthState === EditStateTypes.PENDING && <span className="flex items-center">{telSubmitTimeText}</span>}

            <SignUpTelButton
                edit_state={telSubmitState}
                disabled={telSubmitState === EditStateTypes.DISABLED}
                onClick={() => {
                    telSubmit()
                }}
            >
                {telSubmitState !== EditStateTypes.DONE ? "인증 번호 확인" : "인증 완료"}
            </SignUpTelButton>
            {telSubmitState === EditStateTypes.FAILED && (
                <SignUpTelMessageBox>
                    <span>인증번호가 틀렸습니다.</span>
                </SignUpTelMessageBox>
            )}
        </div>
    )
}

// eslint-disable-next-line react/display-name
export const MyPageUserInput = React.forwardRef(
    (
        {
            label,
            type,
            data,
            setData,
            onKeyPress,
            disabled,
            readOnly = false,
            checked = false,
            children,
            editStateType = EditStateTypes.WAIT,
            isTel = false,
            placeholder = "",
            isRequired = false,
            maxLength = undefined,
        },
        ref,
    ) => {
        const [isFocus, setFocus] = useState(false)

        const handlephoneValue = (data) => {
            if (isTel === true) {
                return formattedPhoneNumber(data)
            } else {
                return data
            }
        }

        const handleSetData = (edata) => {
            if (isTel === true) {
                setData(edata.replace(/-/g, ""))
            } else {
                setData(edata)
            }
        }

        return (
            <MyPageInputLayout>
                <MyPageInputLabel>
                    {isRequired && <span className="text-red-500 mr-1">*</span>}
                    {label}
                </MyPageInputLabel>
                <MyPageInputBox editStateType={editStateType} readOnly={readOnly} disabled={disabled} isFocus={isFocus}>
                    {readOnly ? (
                        <span>{data}</span>
                    ) : (
                        <MyPageInput
                            ref={ref}
                            type={type}
                            value={handlephoneValue(data)}
                            checked={checked}
                            onChange={(e) => {
                                handleSetData(e.target.value)
                            }}
                            onKeyPress={onKeyPress}
                            disabled={disabled}
                            onFocus={() => {
                                setFocus(true)
                            }}
                            onBlur={() => {
                                setFocus(false)
                            }}
                            placeholder={placeholder}
                            maxLength={maxLength}
                        />
                    )}
                    {children !== undefined && <MyPageInputChildContainer>{children}</MyPageInputChildContainer>}
                </MyPageInputBox>
            </MyPageInputLayout>
        )
    },
)

export const MyPageUserInputCombo = ({ label, optionKeys, names, value, onChange, showDefault = true }) => {
    const [isFocus, setFocus] = useState(false)
    return (
        <MyPageInputLayout>
            <MyPageInputLabel>{label}</MyPageInputLabel>
            <MyPageInputBox isFocus={isFocus}>
                <select
                    key={value}
                    value={value}
                    onChange={onChange}
                    className="w-full focus:outline-none"
                    onFocus={() => {
                        setFocus(true)
                    }}
                    onBlur={() => {
                        setFocus(false)
                    }}
                >
                    {showDefault && <option>선택</option>}
                    {optionKeys.map((optionKey, i) => (
                        <option key={optionKey} value={optionKey}>
                            {names[i]}
                        </option>
                    ))}
                </select>
            </MyPageInputBox>
        </MyPageInputLayout>
    )
}

export const MyPageUserLocationInputCombo = ({ label, optionKeys, names, value, onChange }) => {
    const [isFocus, setFocus] = useState(false)

    return (
        <MyPageInputLayout>
            <MyPageInputLocationLabel>{label}</MyPageInputLocationLabel>
            <MyPageInputBox isFocus={isFocus}>
                <select
                    key={value}
                    value={value}
                    onChange={onChange}
                    className="w-full focus:outline-none"
                    onFocus={() => {
                        setFocus(true)
                    }}
                    onBlur={() => {
                        setFocus(false)
                    }}
                >
                    {optionKeys.map((optionKey, i) => (
                        <option key={optionKey} value={optionKey}>
                            {names[optionKey]}
                        </option>
                    ))}
                </select>
            </MyPageInputBox>
        </MyPageInputLayout>
    )
}
