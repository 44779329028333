import React,{useEffect,useState,useRef,useMemo} from "react";
import tw from 'tailwind-styled-components'
import { MyPageInputModalLayout } from "../MyPageMainStyle";
import { PerformColumnSubConstraintButton, PerformColumnSubControlBox, PerformColumnSubDeleteButton, PerformColumnSubSaveButton, StaffPerformUpdateStatusBox, StaffPerformUpdateStatusColorBox, StaffPerformUpdateStatusColorButton, StaffPerformUpdateStatusColorMessage, StaffPerformUpdateStatusConstraintBox, StaffPerformUpdateStatusConstraintList, StaffPerformUpdateStatusConstraintMessage, StaffPerformUpdateStatusConstraintMessageName, StaffPerformUpdateStatusContainer, StaffPerformUpdateStatusControlBox, StaffPerformUpdateStatusInputBox, StaffPerformUpdateStatusList, StaffPerformUpdateStatusMainBox, StaffPerformUpdateStatusTitle } from "./StaffPerformStyle";
import { MyPageDataSubmitButton } from "../myinfo/MyInfoMainStyle";
import Utils from "../../../utils/Utils";
import PerformColumnStatus from "../../../types/brandItems/performColumns/PerformColumnStatus";
import { QueryTypes } from "../../../types/EditorTypes";
import { PerformValueInputTitle } from "./MyPagePerformStyle";
import { MyPageUserInput } from "../../../components/user/Inputs";
import { Dictionary } from "../../../types/Dictionary";
import { getPerformStatusConstraintList, updatePerformStatusConstraint } from "../../../apis/api/boardItems";
import { MyPageInputLabel } from "../../../components/user/InputsStyle";
import { Link } from "react-router-dom";

const StatusConstraint = ({column, mainStatusID, subStatus, constraintStatusDic, setConstraintStatusDic}) => {
    const [isSelected, setSelected] = useState(false)

    useEffect(()=>{
        setSelected(constraintStatusDic.contains(subStatus.id))
    }, [constraintStatusDic])

    const handleUpdate = () => {
        updatePerformStatusConstraint(column.itemID, column.id, mainStatusID, subStatus.id, !isSelected).then(response => {
            if (response) {
                setSelected(!isSelected)

                let newDic = constraintStatusDic.copy()
                if (!isSelected) {
                    // 제약조건을 추가 했을 경우
                    newDic.push(subStatus.id, subStatus)    
                } else {
                    // 제약조건을 없앴을 경우
                    newDic.delete(subStatus.id)
                }
                setConstraintStatusDic(newDic)
            }
        })
    }

    return (
        <StaffPerformUpdateStatusConstraintBox 
            selected={!isSelected} 
            color={subStatus.color} 
            bgColor={subStatus.bgColor} 
            onClick={()=>{handleUpdate()}}
            disabled={mainStatusID === subStatus.id}
        >
            {subStatus.name}
        </StaffPerformUpdateStatusConstraintBox>
    )
}

const Status = ({column, status, columnSubEdit, index}) => {
    const [name, setName] = useState("")
    const [color, setColor] = useState("")
    const [bgColor, setBgColor] = useState("")
    const [brandReadOnly, setBrandReadOnly] = useState(false)
    const [marketerReadOnly, setMarketerReadOnly] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [isRequireComment, setIsRequireComment] = useState(false)

    const [showConstraints, setShowConstraints] = useState(true)
    const [constraintStatusDic, setConstraintStatusDic] = useState(new Dictionary())

    useEffect(()=>{
        setName(status.name)
        setColor(status.color)
        setBgColor(status.bgColor)
        setBrandReadOnly(status.brandReadOnly)
        setMarketerReadOnly(status.marketerReadOnly)
        setIsConfirm(status.isConfirm)
        setIsRequireComment(status.isRequireComment)

        getConstraintStatusDic()
    }, [status])

    const getConstraintStatusDic = () => {
        getPerformStatusConstraintList(column.itemID, column.id, status.id).then(response => {
            if (response.length > 0) {
                let dic = new Dictionary()
                column.statuses.map(_status => {
                    if (response.includes(_status.id)) {
                        dic.push(_status.id, _status)
                    }
                })

                setConstraintStatusDic(dic)
            }
        })
    }

    const handleColor = (value, setValue) => {
        if (!value.includes("#")) {
            value = "#" + value
        }

        if (value.length <= 7) {
            setValue(value)
        }
    }

    const handleSaveStatus = (status) => {
        if (confirm(`${name} 저장하시겠습니까?`)) {
            let _status = new PerformColumnStatus()

            _status.id = status.id
            _status.columnID = status.columnID
            _status.name = name
            _status.color = color
            _status.bgColor = bgColor
            _status.brandReadOnly = brandReadOnly
            _status.marketerReadOnly = marketerReadOnly
            _status.isConfirm = isConfirm
            _status.isRequireComment = isRequireComment
            columnSubEdit(_status, QueryTypes.CREATE)
        }
    }
    const handleDeleteStatus = (status) => {
        if (confirm(`${name} 삭제하시겠습니까?`)) {
            columnSubEdit(status, QueryTypes.DELETE)
        }
    }

    return (
        <StaffPerformUpdateStatusBox>
            <StaffPerformUpdateStatusMainBox>
                <StaffPerformUpdateStatusInputBox className="flex-col w-28">
                    <MyPageInputLabel>예시</MyPageInputLabel>
                    <StaffPerformUpdateStatusTitle className={`p-1 text-[${color}] bg-[${bgColor}]`}>
                        {status.name}
                    </StaffPerformUpdateStatusTitle>
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox className="w-28">
                    <MyPageUserInput
                        label={"이름"}
                        type={"text"}
                        data={name}
                        setData={setName}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox className="w-32">
                    <MyPageUserInput
                        label={"글씨 색상"}
                        type={"text"}
                        data={color}
                        setData={(value)=>{handleColor(value, setColor)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox className="w-32">
                    <MyPageUserInput
                        label={"배경 색상"}
                        type={"text"}
                        data={bgColor}
                        setData={(value)=>{handleColor(value, setBgColor)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"브랜드 Read"}
                        type={"checkbox"}
                        checked={brandReadOnly}
                        setData={(e)=>{setBrandReadOnly(!brandReadOnly)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"마케터 Read"}
                        type={"checkbox"}
                        checked={marketerReadOnly}
                        setData={(e)=>{setMarketerReadOnly(!marketerReadOnly)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"실적 승인"}
                        type={"checkbox"}
                        checked={isConfirm}
                        setData={(e)=>{setIsConfirm(!isConfirm)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"코멘트 후 적용"}
                        type={"checkbox"}
                        checked={isRequireComment}
                        setData={(e)=>{setIsRequireComment(!isRequireComment)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <PerformColumnSubControlBox>
                    <PerformColumnSubDeleteButton onClick={()=>{handleDeleteStatus(status)}}>
                        삭제
                    </PerformColumnSubDeleteButton>
                    <PerformColumnSubSaveButton onClick={()=>{handleSaveStatus(status)}}>
                        저장
                    </PerformColumnSubSaveButton>
                    {/* <PerformColumnSubConstraintButton onClick={()=>{setShowConstraints(!showConstraints)}}>
                        제약 조건
                    </PerformColumnSubConstraintButton> */}
                </PerformColumnSubControlBox>
            </StaffPerformUpdateStatusMainBox>

            <StaffPerformUpdateStatusConstraintMessage>
                {constraintStatusDic.getLength() === column.statuses.length - 1 ? (
                    <React.Fragment>
                        {column.name}
                        <StaffPerformUpdateStatusConstraintMessageName color={status.bgColor}>
                            {` "${status.name}"`}
                        </StaffPerformUpdateStatusConstraintMessageName>
                        {` 적용`}
                        <StaffPerformUpdateStatusConstraintMessageName>
                            {` 불가능`}
                        </StaffPerformUpdateStatusConstraintMessageName>
                    </React.Fragment>
                ): (
                    <React.Fragment>
                        {column.name}{Utils.isBatchimEnding(column.name) ? "이 ": "가 "}
                        {/* 제약조건에 포함되지않고 자기자신이 아닌 상태들 */}
                        {column.statuses.filter(s => !constraintStatusDic.contains(s.id) && status.id !== s.id).map((s, idx) => (
                            <StaffPerformUpdateStatusConstraintMessageName color={s.bgColor} key={idx}>
                                {s.name},
                            </StaffPerformUpdateStatusConstraintMessageName>
                        ))}
                        {` 상태 일 때 `}
                        <StaffPerformUpdateStatusConstraintMessageName color={status.bgColor}>
                            {`"${status.name}"`}
                        </StaffPerformUpdateStatusConstraintMessageName>
                        {` 적용`}
                        <StaffPerformUpdateStatusConstraintMessageName>
                            {` 가능`}
                        </StaffPerformUpdateStatusConstraintMessageName>
                    </React.Fragment>
                )}

                {index === 0 && (
                    <span className="ml-4 text-red-600 underline underline-offset-4">실적 등록 시 자동으로 선택되는 기본 값</span>
                )}
            </StaffPerformUpdateStatusConstraintMessage>

            {showConstraints && (
                <StaffPerformUpdateStatusConstraintList>
                    {column.statuses.map((subStatus, idx) => status.id !== subStatus.id && (
                        <StatusConstraint 
                            column={column} 
                            mainStatusID={status.id} 
                            subStatus={subStatus} 
                            constraintStatusDic={constraintStatusDic}
                            setConstraintStatusDic={setConstraintStatusDic}
                            key={idx} 
                        />
                    ))}
                </StaffPerformUpdateStatusConstraintList>
            )}
        </StaffPerformUpdateStatusBox>
    )
}

export default function StaffPerformUpdateStatus({selectedColumn, columnSubEdit}){
    const handleAddStatus = () => {
        const comboName = prompt("추가할 현황 이름을 입력해주세요.")
        if (Utils.isStringNullOrEmpty(comboName)) {
            return
        }
        
        if (selectedColumn.id >= 0) {
            let newStatus = new PerformColumnStatus()
            newStatus.columnID = selectedColumn.id
            newStatus.name = comboName
            columnSubEdit(newStatus, QueryTypes.CREATE)
        } else {
            alert("추가할 수 없습니다.")
        }
    }
    
    return(
        <StaffPerformUpdateStatusContainer>
            <PerformValueInputTitle>
                <span>{selectedColumn.name} <span className="text-gray-600">현황 관리</span></span>
            </PerformValueInputTitle>
            <StaffPerformUpdateStatusControlBox>
                <StaffPerformUpdateStatusColorBox>
                    {COLOR_PALETTE.map((color, idx) => <ColorPalette color={color} key={idx} />)}
                    <Link to="https://www.google.com/search?q=%08color+picker" target="_blank">
                        <span className="underline text-nightblue-700">Color Picker</span>
                    </Link>
                </StaffPerformUpdateStatusColorBox>
                <MyPageDataSubmitButton onClick={()=>{handleAddStatus()}}>
                    추가
                </MyPageDataSubmitButton>
            </StaffPerformUpdateStatusControlBox>

            <StaffPerformUpdateStatusList>
                {selectedColumn.statuses.map((status, idx) => (
                    <Status column={selectedColumn} status={status} columnSubEdit={columnSubEdit} index={idx} key={idx} />
                ))}
            </StaffPerformUpdateStatusList>
        </StaffPerformUpdateStatusContainer>
    )
}

const COLOR_PALETTE = ["#777", "#009600", "#960000", "#777700", "#3a3" , "#d33", "#ca1"]
const ColorPalette = ({color}) => {
    const [showMessage, setShowMessage] = useState(false)
    const [message, setMessage] = useState("")
 
    const handleClick = () => {
        navigator.clipboard.writeText(color).then(()=>{
            setMessage(`${color} 복사되었습니다.`)
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)
            }, 2000)
        }).catch(()=>{
            setMessage(`${color} 복사 실패했습니다.`)
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)
            }, 2000)
        })
    }

    return (
        <StaffPerformUpdateStatusColorButton
            className={`bg-[${color}]`}
            onClick={()=>{handleClick()}}
        >
            <StaffPerformUpdateStatusColorMessage isShow={showMessage}>
                {message}
            </StaffPerformUpdateStatusColorMessage>
        </StaffPerformUpdateStatusColorButton>
    )
}