import ImageFile from "../../../types/files/ImageFile"
import CommonFile from "../../../types/files/CommonFile"
import { authInstance, defaultInstance, downloadInstance, fileInstance } from "../../utils"
import Utils from "../../../utils/Utils"
import { convertTypes } from "../../services/commons/index"

export const getJobTypes = async () => {
    const response = await defaultInstance.get("/api/commons/job_type/")
    return convertTypes(response.data)
}

export const getBusinessTypes = async () => {
    const response = await defaultInstance.get("/api/commons/business_type/")
    return convertTypes(response.data)
}
export const getRegionTypes = async () => {
    const response = await defaultInstance.get("/api/commons/region_type/")
    return convertTypes(response.data)
}

export const getBrandItemForm = async () => {
    let resullt = ""

    await authInstance.get("/api/commons/brand_item_contract_form/").then((response) => {
        resullt = response
    })

    return resullt.data
}

export const updateBrandItemForm = async (content) => {
    let bResult = false
    if (Utils.isStringNullOrEmpty(content)) {
        return bResult
    }

    await authInstance
        .post("/api/commons/brand_item_contract_form/", {
            content: content,
        })
        .then((response) => {
            bResult = true
        })
    return bResult
}

export const getStoreSignupAgreementForm = async (urldata) => {
    let resullt = ""

    await authInstance
        .post("/api/commons/store_signup_agreement_form/", {
            url_data: urldata,
        })
        .then((response) => {
            resullt = response
        })
    return resullt.data
}

export const getSystemAgreementFrom = async () => {
    let resullt = ""

    await authInstance.get("/api/commons/system_agreement_form/").then((response) => {
        resullt = response
    })
    return resullt.data
}

export const updateSystemAgreementFrom = async (content, key) => {
    let bResult = false
    if (Utils.isStringNullOrEmpty(content)) {
        return bResult
    }

    await authInstance
        .post("/api/commons/system_agreement_form/", {
            content: content,
            key: key,
        })
        .then((response) => {
            bResult = true
        })
    return bResult
}

export const getSmarangSign = async () => {
    const imageFile = new ImageFile()

    const response = await authInstance.get("/api/commons/smarang_sign/")

    if (response.data.base64 !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const updateSmarangSign = async (signID) => {
    const imageFile = new ImageFile()

    const response = await authInstance.post("/api/commons/smarang_sign/", { sign_id: signID })

    if (response.data.base64 !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const getAdvertisementsAuth = async (page, pageSize, ordering, search = "", category = null) => {
    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }
    if (search !== "") {
        params["search"] = search
    }
    if (category != null) {
        params["category"] = category
    }

    const response = await authInstance.get(`/api/commons/advertisements/`, {
        params: params,
    })

    return response.data
}

export const getAdvertisement = async (id) => {
    const response = await authInstance.get(`/api/commons/advertisement/${id}/`)

    return response.data
}
export const createAdvertisement = async (data) => {
    let bResult = false
    await authInstance
        .post(`/api/commons/advertisements/`, data)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}
export const updateAdvertisement = async (id, data) => {
    let bResult = false
    await authInstance
        .put(`/api/commons/advertisement/${id}/`, data)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}
export const deleteAdvertisement = async (id) => {
    let bResult = false
    await authInstance
        .delete(`/api/commons/advertisement/${id}/`)
        .then((response) => {
            bResult = true
        })
        .catch((error) => {})

    return bResult
}

export const getAdvertisementsHome = async () => {
    const response = await authInstance.get(`/api/commons/advertisements_home/`, {})

    return response.data
}

export const getSigunguDatas = async () => {
    const response = await authInstance.get(`/api/commons/sigungu/`, {})

    return response.data
}
