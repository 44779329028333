import React from "react"
import NavFooterOutlet from "./NavFooterOutlet"
import { Nav } from "../components/navigation/Nav"
import tw from "tailwind-styled-components"
import { Link } from "react-router-dom"

const NoutFoundContainer = tw.div`
    w-screen h-screen flex justify-center items-center bg-gray-50
`
const NoutFoundMessage = tw.div`
    text-[8rem]
`

const NotFoundPageLayout = tw.div`
    w-256 h-128 drop-shadow-lg bg-white flex flex-col justify-center items-center rounded-md
`

const NotFoundButton = tw.button`
    py-2 px-4 bg-nightblue-400 text-white rounded-md mt-10 hover:bg-nightblue-500 
`

// 아웃렛이 아니여서 불가능

export default function NotFound() {
    return (
        <React.Fragment>
            <Nav navsize={1} />
            <NoutFoundContainer>
                <NotFoundPageLayout>
                    <NoutFoundMessage>404</NoutFoundMessage>
                    <span className="text-xl">페이지를 찾을 수 없습니다.</span>
                    <Link to={"/"}>
                        <NotFoundButton>홈으로 돌아가기</NotFoundButton>
                    </Link>
                </NotFoundPageLayout>
            </NoutFoundContainer>
        </React.Fragment>
    )
}
