// 여기 작성하는 타입은 백엔드와 호환되어야한다.

export const MarketerTypes = {
    PRO: 0,
    PRE: 1,
}
export const MarketerTypeNames = {
    0: "프로 마케터",
    1: "프리 마케터",
}

export const ItemSalesTypes = {
    SOLUTON: 0,
    PRODUCT: 1,
    SERVICE: 2,
}
export const ItemSalesTypeNames = {
    0: "솔루션",
    1: "제품",
    2: "서비스",
}

export const ItemSearchTypes = {
    TITLE_ONLY: "search_title_only",
    BRAND_NAME_ONLY: "search_brand_name_only",
}
export const ItemSearchTypeNames = {
    TITLE_ONLY: "상품 명",
    BRAND_NAME_ONLY: "브랜드 명",
}

export const MarketerStatusTypes = {
    NONE: -1,
    WAIT: 0,
    CONFIRM: 1,
    BLOCK: 2,
}
export const MarketerStatusTypeNames = {
    0: "대기",
    1: "승인",
    2: "거절",
    "-1": "-",
}
export const MarketerStatusTypeColors = {
    0: "#b0430c",
    1: "#22b00c",
    2: "#686868",
}

export const MarketerSuspendStatusTypes = {
    NONE: 0,
    WAIT: 1,
    CONFIRM: 2,
}
export const MarketerSuspendStatusTypeNames = {
    0: "활동 중",
    1: "대기",
    2: "정지",
}
export const MarketerSuspendStatusTypeColors = {
    0: "#929292",
    1: "#aab00c",
    2: "#9c2222",
}

export const MarketerInvitePermissionTypes = {
    TRUE: "true",
    FALSE: "false",
}
export const MarketerInvitePermissionTypeNames = {
    true: "허용",
    false: "차단",
}
export const MarketerInvitePermissionTypeColors = {
    true: "#22b00c",
    false: "#9c2222",
}

export const ItemMarketerSearchTypes = {
    MARKETER_NAME_ONLY: "marketer_name_only",
    MARKETER_GROUP_NAME_ONLY: "marketer_group_name_only",
}

export const ItemEditTabTypes = {
    BASE: 0,
    DESCRIPTION: 1,
    MISSION_REWARD: 2,
    CONTRACT: 3,
}

export const AdCategoryTypes = {
    CATEGORY0: 0,
    CATEGORY1: 1,
    CATEGORY2: 2,
    CATEGORY3: 3,
    CATEGORY4: 4,
    CATEGORY5: 5,
    CATEGORY6: 6,
    CATEGORY7: 7,
    CATEGORY8: 8,
    CATEGORY9: 9,
    CATEGORY10: 10,
    CATEGORY11: 11,
    CATEGORY12: 12,
    CATEGORY13: 13,
    CATEGORY14: 14,
    CATEGORY15: 15,
    CATEGORY16: 16,
    CATEGORY17: 17,
    CATEGORY18: 18,
    CATEGORY19: 19,
    CATEGORY20: 20,
    CATEGORY21: 21,
    CATEGORY22: 22,
}
export const AdCategoryTypeNames = {
    0: "전체",
    1: "지사/대리점모집",
    2: "정부지원사업",
    3: "프랜차이즈",
    4: "카드/자금/금융",
    5: "무자본사업",
    6: "소자본사업",
    7: "카페/요식업",
    8: "온라인/쇼핑몰",
    9: "IT/정보통신업",
    10: "휴대폰연관사업",
    11: "앱/웹사이트사업",
    12: "플랫폼사업",
    13: "자동차연관사업",
    14: "의료/병원/약국",
    15: "패션/잡화/뷰티/레저",
    16: "교육/방송/부동산",
    17: "자판기/오락기기",
    18: "PC방/모텔/호텔",
    19: "기계/전기/전자",
    20: "제조업",
    21: "해외사업",
    22: "기타",
}
