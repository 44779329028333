import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { UserCombo, UserInput } from "../../components/user/Inputs"
import { getUserBySignup, getUserMarketerSignupRequestData, getUserSignupRequestData } from "../../services/users"
import { EditStateTypes } from "../../types/EditorTypes"
import { MarketerParticipantNames, MarketerParticipantTypes, StaffTypes, UserTypes } from "../../types/users/UserTypes"
import Utils from "../../utils/Utils"
import { useUserStore } from "../../zustand/store"
import {
    SignUpCommitButton,
    SignUpInfoCol,
    SignUpInfoInputCol,
    SignUpInfoTitle,
    SignUpUserLayout,
    SignUpUserTitle,
} from "./SignUpStyle"
import SignUpUser from "./SignUpUser"

const SignUpUserStaff = (props) => {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [tel, setTel] = useState("")
    const [birthday, setBirthday] = useState("")
    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [passwordValidate, setPasswordValidate] = useState(false)

    const [staffType, setStaffType] = useState(MarketerParticipantTypes.FULL)

    const { actions } = useUserStore()
    const navigate = useNavigate()

    const handleStaffType = (value) => {
        setStaffType(value)
    }

    const signUp = () => {
        if (
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            Utils.isStringNullOrEmpty(password1) ||
            Utils.isStringNullOrEmpty(password2) ||
            Utils.isStringNullOrEmpty(tel) ||
            Utils.isStringNullOrEmpty(birthday) ||
            Utils.isStringNullOrEmpty(staffType)
        ) {
            alert("값을 입력해주세요")
            return
        }

        const requestData = {
            ...getUserSignupRequestData(email, name, password1, tel, UserTypes.STAFF, true),
            staff_type: staffType,
        }

        getUserBySignup(requestData)
            .then((user) => {
                if (user.userType === UserTypes.STAFF) {
                    actions.setEmail(user.email)
                    actions.setName(user.name)
                    actions.setUserType(user.userType)
                    actions.setUser(user)
                    navigate("/")
                } else {
                    alert("가입 실패")
                }
            })
            .catch((error) => {
                alert("가입 실패2")
            })
    }

    return (
        <SignUpUserLayout>
            <SignUpUserTitle>마케터 회원가입</SignUpUserTitle>
            <SignUpUser
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                password1={password1}
                setPassword1={setPassword1}
                password2={password2}
                setPassword2={setPassword2}
                tel={tel}
                setTel={setTel}
                birthday={birthday}
                setBirthday={setBirthday}
                passwordValidate={passwordValidate}
                setPasswordValidate={setPasswordValidate}
                telAuthState={telAuthState}
                setTelAuthState={setTelAuthState}
            />
            <SignUpInfoCol>
                <SignUpInfoTitle>스태프 정보</SignUpInfoTitle>
                <SignUpInfoInputCol>
                    <UserCombo
                        label={"참여 형식"}
                        optionKeys={Object.keys(StaffTypes)}
                        names={["Admin", "Staff"]}
                        value={staffType}
                        onChange={(e) => {
                            handleStaffType(e.target.value)
                        }}
                    />
                </SignUpInfoInputCol>
            </SignUpInfoCol>
            <div className="flex justify-end">
                <SignUpCommitButton
                    onClick={() => {
                        signUp()
                    }}
                >
                    가입
                </SignUpCommitButton>
            </div>
        </SignUpUserLayout>
    )
}

export default SignUpUserStaff
