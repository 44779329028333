import React, { useEffect, useRef } from "react"
import tw from "tailwind-styled-components"
import { ItemCardType } from "../../types/DataEnum"
import { Link } from "react-router-dom"
import { ItemSalesTypeNames, MarketerTypeNames, MarketerTypes } from "../../types/brandItems/BrandItemTypes"
import { useState } from "react"
import { getImageFile } from "../../apis/api/files/index"
import { ImageContainer } from "../ImageContainer"

import { Like } from "@styled-icons/foundation/Like"
import { Buildings } from "@styled-icons/boxicons-regular/Buildings"
import { Reward } from "@styled-icons/fluentui-system-regular/Reward"
import ImageFile from "../../types/files/ImageFile"
import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"
import { MoneyDollarBox } from "@styled-icons/remix-fill/MoneyDollarBox"
import { DollarCircle } from "@styled-icons/boxicons-regular/DollarCircle"
import { useImageLoaded } from "../../hooks/useImageLoaded"
import { Receipt } from "@styled-icons/boxicons-regular/Receipt"

const ItemCardCon = tw.div`
    group cursor-pointer 
    flex max-md:flex-row md:flex-col max-md:w-full
    bg-transparent rounded-lg
    duration-300
`

const ItemCardImgCon = tw.div`
    max-md:w-64 md:w-full bg-slate-300 border group-hover:border-transparent
    aspect-[3/2] relative overflow-hidden
    rounded-lg group-hover:drop-shadow-1.5xl
    duration-300
`

const ItemCardContentCol = tw.div`
    flex max-md:flex-col-reverse md:flex-col w-full max-h-full max-md:pl-2 max-md:pr-1 md:px-0.5 z-10
`
const ItemCardTypeRow = tw.div`
    flex max-md:justify-normal md:justify-between items-center text-sm md:py-2
`
const ItemCardMarketerType = tw.span`
    text-white flex justify-center rounded-sm text-[0.625rem] px-2 font-bold
    ${(props) =>
        props.marketerType === MarketerTypes.PRO ? "bg-sky-100 text-sky-600" : "bg-orange-100 text-orange-600"}
`
const ItemCardSalesType = tw.span`
    font-semibold w-12 flex justify-center text-xs text-slate-600
`

const ItemCardTitleCon = tw.div`
    w-full max-md:h-[3.2rem] md:h-[1.5rem] flex
`
const ItemCardTitle = tw.p`
    w-full mb-0.5
    max-md:text-[15px] md:text-[14px]
    max-md:h-[2.5rem] max-md:line-clamp-2
    md:h-[1.2rem] md:line-clamp-1
`
//${props => props.itemType === ItemCardType.ITEM ? 'text-base' : "text-base"};
const ItemCardSubInfoContainer = tw.div`
    flex items-center
`
const ItemCardSubInfoBox = tw.div`
    flex items-center w-full
    [&>span]:text-medium [&>span]:text-gray-600
`

const ItemCardCompensation = tw.div`
    flex items-center w-full justify-between pl-1
    truncate py-1
    text-gray-800
    ${(props) => (props.itemType === ItemCardType.ITEM ? "text-sm font-semibold" : "text-base font-semibold")};
`

const Thumbnail = tw.img`
    w-full h-full
    object-cover bg-center
`

const ItemDetailIconContainer = tw.div`
    flex flex-center flex-shrink-0 h-full max-md:py-1.5 md:py-1 text-gray-600 mr-1
`
const FavIcon = tw(Like)`
    h-5 text-gray-600 mr-1 pb-px
`
const CompanyIcon = tw(Buildings)`
    h-full 
`
const RewardIcon = tw(Reward)`
    h-full
`

const MoneyIcon = tw(Receipt)`
    h-full
`

const RewardContainer = tw.div`
    w-full flex h-8 max-md:justify-start md:justify-end
`
const RewardTextContainer = tw.div`
    flex items-center h-full
    [&>span]:line-clamp-1 [&>span]:font-semibold
    max-md:text-sm md:text-base
`

export default function BrandItemCard({ itemType, item, isLoading, testStyle = false }) {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) && item.detail.thumbnailImageID >= 0) {
            getImageFile(item.detail.thumbnailImageID).then((response) => {
                setImageSource(response.url)
            })
        } else if (!Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setImageSource(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleLink = (e) => {
        // 로딩 중에는 넘어갈 수 없다.
        if (isLoading) {
            e.preventDefault()
        }
    }

    return (
        <Link to={`/items/${item.id}`} onClick={handleLink}>
            <ItemCardCon itemType={itemType} testStyle={testStyle}>
                <ItemCardImgCon>
                    <ImageContainer loaded={!isLoading && imageLoaded}>
                        <Thumbnail src={imageSource} alt={null} ref={imageRef} onLoad={imageSetLoad} />
                    </ImageContainer>
                </ItemCardImgCon>
                <ItemCardContentCol>
                    {/* 모바일에선 첫번째 블럭과 두번째 블럭의 위치가 바뀐다. */}
                    {/* 첫번째 블럭(아이템 타입) */}
                    <ItemCardTypeRow>
                        {/* 마케터 타입 */}
                        {isLoading ? (
                            <div className="skeleton flex justify-center rounded-lg text-xs px-2 py-px">loading...</div>
                        ) : (
                            <ItemCardMarketerType marketerType={item.detail.marketerType}>
                                {/* {MarketerTypeNames[item.detail.marketerType]} */}
                                {item.detail.marketerType === MarketerTypes.PRO && "PRO"}
                                {item.detail.marketerType === MarketerTypes.PRE && "PRE"}
                            </ItemCardMarketerType>
                        )}
                        {/* 아이템 유형 */}
                        {isLoading ? (
                            <div className="skeleton font-semibold w-12 flex justify-center max-md:ml-1">
                                loading...
                            </div>
                        ) : (
                            <ItemCardSalesType>{ItemSalesTypeNames[item.detail.itemSalesType]}</ItemCardSalesType>
                        )}
                    </ItemCardTypeRow>
                    {/* 두번째 블럭(제목, 보상메세지) */}
                    <div className="flex flex-col h-full">
                        <ItemCardTitleCon>
                            {isLoading ? (
                                <div className="skeleton w-4/5 h-[1.5rem] mb-2">loading...</div>
                            ) : (
                                <ItemCardTitle itemType={itemType}>{item.detail.title}</ItemCardTitle>
                            )}
                        </ItemCardTitleCon>

                        <ItemCardSubInfoContainer className="max-md:mt-auto max-md:mb-0.5">
                            <ItemCardSubInfoBox>
                                {isLoading ? (
                                    <div className="skeleton w-40 h-5 max-md:mb-1">loading...</div>
                                ) : (
                                    <RewardContainer>
                                        <ItemDetailIconContainer>
                                            <MoneyIcon />
                                        </ItemDetailIconContainer>
                                        <RewardTextContainer>
                                            <span>{item.detail.rewardMessage}</span>
                                        </RewardTextContainer>
                                    </RewardContainer>
                                )}
                            </ItemCardSubInfoBox>
                        </ItemCardSubInfoContainer>
                    </div>
                </ItemCardContentCol>
            </ItemCardCon>
        </Link>
    )
}
