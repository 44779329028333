import React, { useState, useEffect } from "react"
import tw from "tailwind-styled-components"
import { ContentContainer } from "../MyPageMainStyle"
import { TextFormats } from "../../../types/DataEnum"
import {
    MyPageCouponBox,
    MyPageCouponContentBox,
    MyPageCouponImageBox,
    MyPageCouponListGrid,
    MyPageCouponListLayout,
    MyPageCouponRequireBox,
    MyPageCouponRequireContainer,
    MyPageCouponRequireTriangle,
    MyPagePointDashBox,
    MyPagePointDashPointBox,
    MyPagePointLayout,
} from "./MyPagePointStyle"
import { UserTypes } from "../../../types/users/UserTypes"
import Coupon from "../../../types/points/Coupon"
import ModalContainerPortal from "../../../components/ModalContainerPortal"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { getImageFile } from "../../../apis/api/files/index"

import { Search } from "@styled-icons/fluentui-system-filled/Search"

import * as PointApi from "../../../apis/api/points/index"
import MyPageCouponWrite from "./MyPageCouponWrite"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { ImageContainer } from "../../../components/ImageContainer"
import MyPageCouponView from "./MyPageCouponView"
import MyPageCouponApplication from "./MyPageCouponApplication"

export default function MyPageCouponList({ user, userType, updateUser, myRecommands, myAccumPoint }) {
    const [showCouponSettingModal, setShowCouponSettingModal] = useState(false)
    const [showCouponViewModal, setShowCouponViewModal] = useState(false)
    const [showCouponApplicationModal, setShowCouponApplicationModal] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState(new Coupon())

    const [filterTitle, setFilterTitle] = useState("")
    const [searchedTitle, setSearchedTitle] = useState("")

    const [coupons, setCoupons] = useState([])

    useEffect(() => {
        if (!showCouponSettingModal && !showCouponViewModal) {
            getCoupons(searchedTitle)
        }
        if (showCouponSettingModal && showCouponViewModal) {
            updateUser()
        }
    }, [showCouponSettingModal, showCouponViewModal])

    const getCoupons = async (_title) => {
        const response = await PointApi.getCoupons(_title)

        setCoupons(response.items)
    }

    const handleSearch = () => {
        setSearchedTitle(filterTitle)
        getCoupons(filterTitle)
    }

    const handleSearchEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSettingModal = (e, coupon) => {
        e.preventDefault()
        e.stopPropagation()

        setSelectedCoupon(coupon)
        setShowCouponSettingModal(true)
    }
    const handleViewModal = (e, coupon) => {
        e.preventDefault()
        e.stopPropagation()

        updateUser()
        setSelectedCoupon(coupon)
        setShowCouponViewModal(true)
    }
    const handleApplicationModal = (e, coupon) => {
        e.preventDefault()
        e.stopPropagation()

        updateUser()
        setSelectedCoupon(coupon)
        setShowCouponApplicationModal(true)
    }

    return (
        <MyPageCouponListLayout>
            <div className="flex items-center w-full max-lg:px-1 py-6">
                <span className="text-2xl mr-auto">쿠폰 목록</span>
                {userType === UserTypes.STAFF && (
                    <button
                        className="px-4 py-2 mr-4 text-sm text-white bg-nightblue-600 hover:bg-nightblue-700 duration-200 rounded-lg"
                        onClick={() => {
                            setSelectedCoupon(new Coupon())
                            setShowCouponSettingModal(true)
                        }}
                    >
                        쿠폰 생성
                    </button>
                )}
                <div className="flex items-center w-48 h-10 px-3 py-1 bg-white rounded drop-shadow">
                    <input
                        type="text"
                        className="w-full text-slate-700"
                        value={filterTitle}
                        onChange={(e) => {
                            setFilterTitle(e.target.value)
                        }}
                        onKeyPress={handleSearchEnter}
                    />
                    <Search
                        className="h-full py-1 ml-2 text-nightblue-600 cursor-pointer"
                        onClick={() => {
                            handleSearch()
                        }}
                    />
                </div>
            </div>

            <MyPageCouponListGrid>
                {coupons.map((coupon, index) => (
                    <CouponItem
                        key={index}
                        coupon={coupon}
                        userType={userType}
                        handleSettingModal={handleSettingModal}
                        handleViewModal={handleViewModal}
                        handleApplicationModal={handleApplicationModal}
                        myRecommands={myRecommands}
                        myAccumPoint={myAccumPoint}
                    />
                ))}
            </MyPageCouponListGrid>

            <ModalContainerPortal
                show={showCouponSettingModal}
                setShow={setShowCouponSettingModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={false}
            >
                <MyPageCouponWrite
                    couponID={selectedCoupon.id}
                    showModal={showCouponSettingModal}
                    setShowModal={setShowCouponSettingModal}
                />
            </ModalContainerPortal>
            <ModalContainerPortal
                show={showCouponViewModal}
                setShow={setShowCouponViewModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageCouponView
                    user={user}
                    updateUser={updateUser}
                    coupon={selectedCoupon}
                    showModal={showCouponViewModal}
                    setShowModal={setShowCouponViewModal}
                />
            </ModalContainerPortal>
            <ModalContainerPortal
                show={showCouponApplicationModal}
                setShow={setShowCouponApplicationModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageCouponApplication
                    user={user}
                    updateUser={updateUser}
                    coupon={selectedCoupon}
                    showModal={showCouponApplicationModal}
                    setShowModal={setShowCouponApplicationModal}
                />
            </ModalContainerPortal>
        </MyPageCouponListLayout>
    )
}

const CouponItem = ({
    coupon,
    userType,
    handleSettingModal,
    handleViewModal,
    handleApplicationModal,
    myRecommands,
    myAccumPoint,
}) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        // if (Utils.isStringNullOrEmpty(coupon.thumbnailImageURL) && coupon.thumbnailImageID >= 0) {
        //     getImageFile(coupon.thumbnailImageID).then((response) => {
        //         setImageSource(response.url)
        //     })
        // } else if (!Utils.isStringNullOrEmpty(coupon.thumbnailImageURL)) {
        // }
        setImageSource(coupon.giftiCouponThumb.goodsImgB)
    }, [coupon])

    return (
        <MyPageCouponBox
            onClick={(e) => {
                handleViewModal(e, coupon)
            }}
        >
            <MyPageCouponImageBox>
                <ImageContainer loaded={!Utils.isStringNullOrEmpty(imageLoaded)}>
                    <img
                        className="w-full h-full object-cover bg-center"
                        src={imageSource}
                        alt={null}
                        ref={imageRef}
                        onLoad={() => {
                            imageSetLoad(true)
                        }}
                    />
                </ImageContainer>
            </MyPageCouponImageBox>
            <MyPageCouponContentBox>
                <span className="title" title={coupon.title}>
                    {coupon.title}
                </span>
                <div className="flex justify-between items-center sub">
                    <span className="price">{Utils.textFormat(coupon.price, TextFormats.NUMBER)}P</span>
                    {userType === UserTypes.STAFF && (
                        <span className="date text-xs">
                            {coupon.dateStart} ~ {coupon.dateEnd}
                        </span>
                    )}
                </div>
            </MyPageCouponContentBox>

            <MyPageCouponRequireContainer className={`top-2`}>
                {coupon.requireRecommands > 0 && (
                    <MyPageCouponRequireBox $is_active={coupon.requireRecommands <= myRecommands}>
                        <span>{`누적 추천인 ${Utils.textFormat(
                            coupon.requireRecommands,
                            TextFormats.NUMBER,
                        )}명 부터`}</span>
                    </MyPageCouponRequireBox>
                )}
            </MyPageCouponRequireContainer>
            <MyPageCouponRequireContainer className={`${coupon.requireRecommands > 0 ? "top-8" : "top-2"}`}>
                {coupon.requirePoint > 0 && (
                    <MyPageCouponRequireBox $is_active={coupon.requirePoint <= myAccumPoint}>
                        <span>{`누적 포인트 ${Utils.textFormat(coupon.requirePoint, TextFormats.NUMBER)}원 부터`}</span>
                    </MyPageCouponRequireBox>
                )}
            </MyPageCouponRequireContainer>
            {/* <MyPageCouponRequireContainer className={`top-1`}>
                {coupon.requireRecommands > 0 && (
                    <MyPageCouponRequireBox>
                        <span>{`누적 추천인 ${Utils.textFormat(
                            coupon.requireRecommands,
                            TextFormats.NUMBER,
                        )}명 부터`}</span>
                    </MyPageCouponRequireBox>
                )}
                {coupon.requireRecommands > 0 && (
                    <svg width="24px" height="24px" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="0,0 50,0 0,100" />
                    </svg>
                )}
            </MyPageCouponRequireContainer>
            <MyPageCouponRequireContainer className={`${coupon.requireRecommands > 0 ? "top-8" : "top-1"}`}>
                {coupon.requirePoint > 0 && (
                    <MyPageCouponRequireBox>
                        <span>{`누적 포인트 ${Utils.textFormat(coupon.requirePoint, TextFormats.NUMBER)}원 부터`}</span>
                    </MyPageCouponRequireBox>
                )}
                {coupon.requirePoint > 0 && (
                    <svg width="24px" height="24px" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="0,0 50,0 0,100" />
                    </svg>
                )}
            </MyPageCouponRequireContainer> */}
            {userType === UserTypes.STAFF && (
                <button
                    className="w-full h-7 mt-2 rounded-full bg-lime-600 hover:bg-lime-700 text-white text-sm duration-200"
                    onClick={(e) => {
                        handleSettingModal(e, coupon)
                    }}
                >
                    수정
                </button>
            )}
            {userType === UserTypes.STAFF && (
                <button
                    className="w-full h-7 mt-2 rounded-full bg-pink-600 hover:bg-pink-700 text-white text-sm duration-200"
                    onClick={(e) => {
                        handleApplicationModal(e, coupon)
                    }}
                >
                    구매 내역
                </button>
            )}
        </MyPageCouponBox>
    )
}
