import React, { useState, useEffect } from "react"
import { getUserDetail, updateUserProfileAvatar } from "../apis/api/users"
import UserAvatar from "./user/UserAvatar"
import tw from "tailwind-styled-components"
import { Telephone } from "@styled-icons/bootstrap/Telephone"
import { Location } from "@styled-icons/ionicons-outline/Location"
import { Email } from "@styled-icons/material-outlined/Email"
import { useMouseHover } from "../hooks/useMouseHover"
import { User } from "@styled-icons/boxicons-regular/User"

const MarketerNameTextBox = tw.div`
    flex w-full
    font-semibold text-[1.0825rem] text-nightblue-800
`
const MarketerDetailTextBox = tw.div`
    flex mt-2 w-full
    text-xs cursor-text
`
const MarketerDetailText = tw.span`
    flex flex-nowrap w-full text-left
`

const TelephoneIcon = tw(Telephone)`
    w-4 h-3 mr-1 mt-0.5
`
const LocationIcon = tw(Location)`
    h-4 mr-1
`

const UserNameIcon = tw(User)`
    h-4 mr-1
`

const EmailIcon = tw(Email)`
    h-4 mr-1 mt-0.25
`

const CardLeftTriangle = tw.div`
    w-0 h-0 absolute -left-[15px]
    border-t-[15px] border-t-transparent
    border-r-[15px] border-r-blue-50
    border-b-[15px] border-b-transparent

`

const HoveredCardContainer = tw.div`
    absolute drop-shadow-xl left-full top-0 z-[100]
    cursor-pointer
`

const HoveredCardLayout = tw.div`
    w-full h-full bg-blue-50 rounded-r-lg rounded-bl-lg p-2 flex flex-col justify-between
`

const CardLongInfoContainer = tw.div`
    w-[17rem] h-full flex flex-col justify-around mt-1 text-nightblue-600
`
const CardShortInfoContainer = tw.div`
    w-full h-full flex flex-grow
`
const CardShortInfoTextLayout = tw.div`
    w-[13rem] flex flex-col
`

export const MarketerInfoBox = ({ marketer }) => {
    return (
        <React.Fragment>
            <MarketerDetailTextBox>
                <UserNameIcon />
                <MarketerDetailText>{marketer.user.name}</MarketerDetailText>
            </MarketerDetailTextBox>
            <MarketerDetailTextBox>
                <TelephoneIcon />
                <MarketerDetailText>{marketer.user.tel}</MarketerDetailText>
            </MarketerDetailTextBox>
            <MarketerDetailTextBox>
                <EmailIcon />
                <MarketerDetailText>{marketer.user.email}</MarketerDetailText>
            </MarketerDetailTextBox>
        </React.Fragment>
    )
}

export function HoverUserCard({ children, userID, onClick }) {
    const [userDetail, setUserDetail] = useState(null)

    const [hoverRef, isHovering] = useMouseHover()

    useEffect(() => {
        if (isHovering) {
            getUserDetails()
        }
    }, [isHovering])

    const getUserDetails = () => {
        getUserDetail(userID).then((response) => {
            setUserDetail(response)
        })
    }

    return (
        <div ref={hoverRef} className={`relative`}>
            {children}

            {userDetail !== null && isHovering && (
                <HoveredCardContainer
                    onClick={() => {
                        onClick()
                    }}
                >
                    <div className="flex">
                        <CardLeftTriangle />
                        <HoveredCardLayout>
                            <CardShortInfoContainer>
                                <CardShortInfoTextLayout>
                                    <MarketerNameTextBox>{userDetail.user.name}</MarketerNameTextBox>
                                </CardShortInfoTextLayout>
                            </CardShortInfoContainer>
                            <CardLongInfoContainer>
                                <MarketerInfoBox marketer={userDetail} />
                            </CardLongInfoContainer>
                            <div className="w-[3.5rem] h-[3.5rem] rounded-full overflow-hidden absolute top-2 right-2">
                                {userDetail !== null && <UserAvatar user={userDetail.user} />}
                            </div>
                        </HoveredCardLayout>
                    </div>
                </HoveredCardContainer>
            )}
        </div>
    )
}
