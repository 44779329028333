import tw from "tailwind-styled-components"
import { BodyInnerBox, BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"
import { Share } from "@styled-icons/boxicons-solid/Share"
import styled from "styled-components"
import { ChevronUp } from "@styled-icons/fa-solid/ChevronUp"

export const ItemDetailPageContainer = tw(ContainerPaddingTop)`
    h-auto max-md:pt-6 md:pt-12 md:mt-6 
`

export const ItemDetailContainer = tw.div`    
    w-full md:max-w-[1200px]
`

export const ItemDetailIntroduce = tw.div`
    w-full flex flex-col mt-4
`

export const ItemDetailThumbnailContainer = tw.div`
   flex flex-col w-[42rem]  pr-2 
`
export const ItemDetailTitleContainer = tw.div`
    flex w-full
`
export const ItemDetailThumbDataContainer = tw.div`
    flex
    w-full
`

export const ItemDetailThumbnailTitle = tw.span`
    flex items-center w-full
    font-semibold text-xl
    overflow-y-auto
`

export const ItemDetailThumbnail = tw.div`
    flex-center w-full aspect-[3/2]
    md:rounded-md overflow-hidden bg-slate-300
    max-md:border-b md:border border-slate-200
`

export const ItemDetailApplyItemContainer = tw.div`
    flex-grow pl-2 
`

export const ItemDetailShareContainer = tw.div`
    h-20 flex items-center justify-end
`

export const ItemDetailApplyItem = tw.div`
    flex flex-col w-full h-full max-md:p-0 md:p-6
    md:rounded-md bg-white max-md:border-b md:border border-slate-200
`
export const ItemDetailApplyItemBox = tw.div`
    flex max-sm:flex-col max-md:p-2
    max-md:rounded max-md:bg-slate-100
`

export const ItemDetailApplyItemDescription = tw.span`
    w-32 max-md:text-xs md:text-sm truncate text-gray-500
    max-md:font-light md:font-base
`
export const ItemDetailApplyItemContents = tw.span`
    w-full flex-grow text-sm
    max-md:font-base md:font-base
    line-clamp-2
`

export const ItemDetailApply = tw.div`
    flex flex-center w-full bg-blue-400 py-2 rouned-md text-white rounded-md text-lg
    select-none
`
export const ItemDetailApplyButton = tw(ItemDetailApply)`
    bg-blue-400 text-white hover:bg-blue-500 duration-200
    cursor-pointer
`
export const ItemDetailApplyMarketerStatus = tw(ItemDetailApply)`
    bg-gray-500 text-white
`

export const ItemDetailApplyTypeContentGrid = tw.div`
    grid grid-cols-3 max-sm:gap-x-2 sm:gap-x-4 w-full h-20
`
export const ItemDetailApplyTypeContentBox = tw.div`
    flex flex-col items-center p-2
    rounded-lg bg-slate-100
`
export const ItemDetailApplyTypeContentTitle = tw.span`
    font-medium text-gray-500 text-sm truncate
`
export const ItemDetailApplyTypeContentValue = tw.span`
    my-auto truncate
`
export const ItemDetailApplyContents = tw.div`
    ${(props) => (props.mobileHidden ? "max-md:hidden md:flex" : "flex")} 
    items-start max-lg:mb-2 max-xl:mb-4 xl:mb-6
`

export const ItemApplyBrandIconContainer = tw.div`
    absolute right-6 top-4 w-20 rounded-full overflow-hidden
`

export const ItemApplyBrandContainer = tw(ItemDetailApplyItem)`
    mb-0 justify-start p-0 relative overflow-hidden
`

export const ItemApplyBrandTitleContainer = tw.div`
    h-14 w-full bg-gray-100 flex items-center p-6
`

export const ItemApplyBrandTitle = tw.span`
    text-lg font-semibold
`

export const ItemApplyBrandDescriptionContainer = tw.div`
    flex-grow w-full p-6 flex flex-col justify-around
`

export const ItemApplyBrandDescription = tw.span`
    w-40 font-semibold  truncate
`

export const ItemApplyBrandContents = tw.span`
    flex-grow max-w-[18rem] truncate
`
export const ShareButton = tw.button`
    w-12 h-12 rounded-full border group hover:bg-gary-50
`
export const ShareIcon = tw(Share)`
    w-8 text-blue-500 group-hover:text-blue-600
`

export const ItemApplyDetail = tw.div`
    w-full mt-4 mb-10
`

export const ItemDetailNavContainer = tw.div`
    sticky webk ${(props) => (props.mainNavHide ? "top-0" : "top-10")} duration-300
    webkit-translate-z
    flex w-full h-12 z-20
    rounded-b-lg
`
export const ItemDetailNav = tw.div`
    grid grid-cols-4 gap-1 p-1.5 w-full h-full 
    md:rounded-lg
    bg-white border border-slate-200
`

export const ItemDetailNavInner = tw.div`
    flex flex-center w-full
    cursor-pointer rounded-md
    ${(props) => (props.isnav ? "text-nightblue-800 bg-blue-100 font-medium" : "text-slate-500 hover:text-slate-700")} 
    select-none
`
// export const ItemDetailNav = tw.div`
//     bg-slate-100 h-12 flex justify-between z-20
//     sticky top-10
// `

// export const ItemDetailNavInner = tw.div`
//     flex justify-center items-center w-4/6
//     text-lg
//     hover:text-blue-400
//     cursor-pointer
//     border-b-2 border-blue-400
//     ${(props) => props.isnav ? "border-t-2 border-x-2 bg-white border-b-0" : ""}
// `

export const ItemDetailContentContainer = tw.div`
    flex flex-col 
    p-4
    max-md:my-2 md:my-4 
    md:rounded-lg bg-white
    max-md:border-y md:border border-nightblue-100
`
export const ItemDetailContentTitle = tw.span`
    font-semibold text-xl text-nightblue-600
`
export const ItemDetailContentDiv = tw.span`
    pt-2
`

export const ItemDetailImageContainer = tw.div`
    my-4
`

export const ItemDetailPreviewControlContainer = tw.div`
    fixed bottom-4 left-0 z-30
    flex justify-center w-full
`
export const ItemDetailPreviewControlLayout = tw(BoxMaxMin)`
    flex justify-end w-full px-1
`
export const ItemDetailPreviewControlBox = tw.div`
    flex items-center w-72 h-10 px-2 py-1
    bg-white rounded-md drop-shadow 
`
export const ItemDetailPreviewCloseButton = tw.button`
    w-1/2 h-full flex-shrink-0
    text-nightblue-700
`
export const ItemDetailPreviewSubmitButton = tw.button`
    w-1/2 h-full flex-shrink-0
    bg-nightblue-600 disabled:bg-gray-400 rounded-lg
    text-white
`

export const ItemDetailMobileControlContainer = tw.div`
    fixed bottom-0 z-50
    max-md:flex md:hidden justify-between items-center w-screen h-12 px-4
    bg-white/80 backdrop-blur-sm
    border-t border-gray-200
`
export const ItemDetailMobileControlBox = tw.div`
    flex items-center h-full
`
export const ItemDetailMobileControlScrollTopButton = tw.div`
    flex flex-center w-8 h-8 py-2
    rounded-full bg-gray-500/60 border
    text-gray-300
`
export const ItemDetailMobileControlScrollTopIcon = tw(ChevronUp)`
    h-full
`
export const ItemDetailMobileApply = tw.div`
    flex flex-center bg-blue-400 px-4 py-2 rounded-full text-white text-sm
`
export const ItemDetailMobileApplyButton = tw(ItemDetailMobileApply)`
    bg-nightblue-400 text-white hover:bg-nightblue-500 duration-200
    cursor-pointer
`
export const ItemDetailMobileApplyMarketerStatus = tw(ItemDetailMobileApply)`
    bg-gray-500 text-white
`
