import React, { useEffect, useState, useRef, useCallback } from "react";
import tw from "tailwind-styled-components";
import {
  getPerformStatusComments,
  getPerformStatusConstraint,
  getPerformStatusConstraintList,
  updateItemPerformValue,
  updatePerformStatusComments,
} from "../../../apis/api/boardItems";
import { useInView } from "react-intersection-observer";

import PerformColumnStatus from "../../../types/brandItems/performColumns/PerformColumnStatus";
import { EditStateTypes } from "../../../types/EditorTypes";
import {
  PerformStatus,
  PerformTableFilterIcon,
  PerformValueBox,
  PerformValueInputLayout,
  PerformValueInputSubTitle,
  PerformValueInputTitle,
} from "./MyPagePerformStyle";

import { RadioCircle } from "@styled-icons/boxicons-regular/RadioCircle";
import { RadioCircleMarked } from "@styled-icons/boxicons-regular/RadioCircleMarked";
import PerformColumnStatusComment from "../../../types/brandItems/performColumns/PerformColumnStatusComment";
import { UserTypes } from "../../../types/users/UserTypes";
import {
  CommentBox,
  CommentInputTextArea,
  StatusCommentLayout,
  CommentMarginBox,
  CommentMessageBox,
  StatusCommentsCol,
  StatusCommentsContainer,
  StatusCommentsLayout,
  StatusCommentTypeBox,
  CommentSubmitButton,
  StatusCommentInputLayout,
  StatusCommentInputBar,
  StatusCommentInputTextAreaContainer,
  CommentSubmitIcon,
  CommentStatusBox,
  CommentStatusSpan,
  CommentDetailBox,
  AlertCommentBox,
  ConfirmApplyBox,
  ConfirmApplyMessage,
} from "./PerformValueStatusStyle";
import { useUserStore } from "../../../zustand/store";
import { Dictionary } from "../../../types/Dictionary";
import AccessRules from "../../../rules/AccessRules";
import useDropdownDetectClose from "../../../hooks/useDropdownDetectClose";
import Utils from "../../../utils/Utils";
import { CloseButton } from "../../../components/ModalContainer";
import useDropdownDetectCloseWithoutClassName from "../../../hooks/useDropdownDetectCloseWithoutClassName";

import { debounce } from "lodash";

const MAX_COMMENT_INPUT_HEIGHT = 120;
const COMMENT_PAGE_SIZE = 10;

const RadioIcon = tw(RadioCircle)`
    h-8
`;
const RadioMarkedIcon = tw(RadioCircleMarked)`
    h-8
`;

const CLASS_NAME_STATUS_COMMENT = "status-comment";

// 현황 수정 시 YesOrNo로만 수정 확정할 경우
const StatusConfirmApply = ({ name, cancle, apply }) => {
  return (
    <ConfirmApplyBox className="max-sm:w-[90vw] sm:w-72">
      <ConfirmApplyMessage>
        <span>
          상태를 {name}
          {Utils.isBatchimEnding(name) ? "으로" : "로"} 적용하시겠습니까?
        </span>
      </ConfirmApplyMessage>
      <div className="grid grid-cols-2 gap-2 h-8">
        <button
          className="text-nightblue-700"
          onClick={() => {
            cancle();
          }}
        >
          취소
        </button>
        <button
          className="rounded-lg bg-nightblue-600 text-white"
          onClick={() => {
            apply();
            cancle();
          }}
        >
          적용
        </button>
      </div>
    </ConfirmApplyBox>
  );
};
// 현황 수정 시 커멘트 후 수정 확정할 경우
const StatusCommentApply = ({ name, cancle, apply }) => {
  const [comment, setComment] = useState("");
  const [availableSubmit, setAvailableSubmit] = useState(false);

  const textAreaRef = useRef();

  const handleTextArea = (value) => {
    updateTextAreaHeight();
    setComment(value);
    handleAvailableSubmit(value);
  };

  const updateTextAreaHeight = debounce((init = false) => {
    textAreaRef.current.style.height = "auto";

    if (!init) {
      let height =
        textAreaRef.current.scrollHeight > MAX_COMMENT_INPUT_HEIGHT
          ? MAX_COMMENT_INPUT_HEIGHT
          : textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = height + "px";
    }
  }, 300);

  const handleAvailableSubmit = debounce((value) => {
    setAvailableSubmit(!Utils.isStringNullOrEmpty(value));
  }, 300);

  const handleSubmitKeyPress = (e) => {
    // if (e.key == "Enter" && !e.shiftKey) {
    //     e.preventDefault()
    //     submit()
    // }
  };

  const submit = useCallback(() => {
    if (Utils.isStringNullOrEmpty(comment)) {
      return;
    }
    apply(comment);
    cancle();
  }, [comment]);

  return (
    <ConfirmApplyBox
      className={`max-sm:w-[90vw] sm:w-96 ${CLASS_NAME_STATUS_COMMENT}`}
    >
      <ConfirmApplyMessage>
        <span>메모를 남긴 후 적용 버튼을 눌러주세요.</span>
      </ConfirmApplyMessage>
      <StatusCommentInputLayout>
        <StatusCommentInputBar>
          <StatusCommentInputTextAreaContainer className="bg-gray-100">
            <CommentInputTextArea
              rows={2}
              ref={textAreaRef}
              className="bg-gray-100 text-black"
              onChange={(e) => {
                handleTextArea(e.target.value);
              }}
              onKeyPress={(e) => {
                handleSubmitKeyPress(e);
              }}
              value={comment}
            />
          </StatusCommentInputTextAreaContainer>
          <div className="flex flex-col">
            <CommentSubmitButton
              className="bg-white hover:bg-white text-nightblue-700"
              onClick={() => {
                cancle();
              }}
            >
              취소
            </CommentSubmitButton>
            <CommentSubmitButton
              onClick={() => {
                submit();
              }}
              disabled={!availableSubmit}
              className="h-8"
            >
              적용
            </CommentSubmitButton>
          </div>
        </StatusCommentInputBar>
      </StatusCommentInputLayout>
    </ConfirmApplyBox>
  );
};

const StatusEditButton = ({
  column,
  status,
  selectedStatusID,
  statusApplyID,
  updateStatus,
  setStatusApplyID,
}) => {
  const buttonRef = useRef(null);
  const [buttonTop, setButtonTop] = useState(0);

  // 모달을 끄면 수정할려는 현황이 없어지므로 -1을 넣는다.
  const handleCancle = () => {
    setStatusApplyID(-1);
  };

  // 클릭한 element가 CLASS_NAME_STATUS_COMMENT를 포함하고 있으면 끄지 않는다
  // 모달이 꺼질 때 handleCancle 이벤트를 실행한다.
  const [isOpen, openRef, setOpen] = useDropdownDetectCloseWithoutClassName(
    false,
    [CLASS_NAME_STATUS_COMMENT],
    [handleCancle]
  );

  useEffect(() => {
    setButtonTop(buttonRef.current.offsetTop);
  }, [buttonRef.current]);

  useEffect(() => {
    // 수정할려는 현황이면 모달을 띄운다.
    if (statusApplyID === status.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [statusApplyID]);

  const handleClick = async () => {
    // 수정할려는 현황은 1개이기 때문에 아이디를 넣어준다.
    if (selectedStatusID !== status.id) {
      // 제약조건에 위배되는 현황인지 확인한다.
      const isConstraint = await getPerformStatusConstraint(
        column.itemID,
        column.id,
        status.id,
        selectedStatusID
      );

      if (isConstraint) {
        alert("선택할 수 없는 현황입니다.");
      } else {
        setStatusApplyID(status.id);
      }
    }
  };
  const handleSubmit = (comment = "") => {
    updateStatus(status, comment);
  };

  return (
    <div
      className={`flex lg:relative w-full h-full ${CLASS_NAME_STATUS_COMMENT}`}
      ref={buttonRef}
    >
      <button
        className="flex flex-center w-full h-full"
        onClick={() => {
          handleClick();
        }}
      >
        {status.name}
      </button>
      {/* 모바일에서는 relative가 아닌 화면 중앙에 표시한다. */}
      <div
        ref={openRef}
        className={`
                    absolute max-lg:top-[${
                      buttonTop + 30
                    }px] lg:top-8 max-md:left-0 max-sm:translate-x-[5%] max-md:translate-x-[50%]
                    ${isOpen ? "opacity-100 z-20" : "opacity-0 -z-10"}
                `}
      >
        {status.isRequireComment ? (
          <React.Fragment>
            {/* 현황 수정 시 내용을 보내야하는 경우 */}
            <StatusCommentApply
              name={status.name}
              cancle={handleCancle}
              apply={handleSubmit}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* 현황 수정 시 YesOrNo를 하는 경우 */}
            <StatusConfirmApply
              name={status.name}
              cancle={handleCancle}
              apply={handleSubmit}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const StatusButton = ({
  column,
  status,
  disabled,
  selectedStatusID,
  updateStatus,
  statusApplyID,
  setStatusApplyID,
}) => {
  const color = status.color;
  const bgColor = status.bgColor;

  return (
    <div
      // color={status.color.toString()}
      className={`
                flex flex-center max-sm:w-16 sm:w-32 mr-2 mb-1
                rounded-sm
                border-2 border-[${bgColor}]
                max-sm:text-sm sm:text-base
                ${
                  status.id === selectedStatusID
                    ? `text-[${color}] bg-[${bgColor}]`
                    : `text-[${bgColor}]`
                }
            `}
    >
      {!disabled && AccessRules.performSubValueWrite(status) ? (
        <StatusEditButton
          column={column}
          status={status}
          selectedStatusID={selectedStatusID}
          setStatusApplyID={setStatusApplyID}
          statusApplyID={statusApplyID}
          updateStatus={updateStatus}
        />
      ) : (
        <span className="flex flex-center w-full h-full cursor-default">
          {status.name}
        </span>
      )}
    </div>
  );
};

export const StatusButtons = ({
  disabled,
  column,
  statuses,
  selectedStatusID,
  updateStatus,
}) => {
  // 수정할 현황의 ID (수정 요청 모달 관리용)
  const [statusApplyID, setStatusApplyID] = useState(-1);

  return (
    <div className="flex flex-wrap">
      {statuses.map((status, idx) => (
        <StatusButton
          column={column}
          status={status}
          disabled={disabled}
          selectedStatusID={selectedStatusID}
          updateStatus={updateStatus}
          statusApplyID={statusApplyID}
          setStatusApplyID={setStatusApplyID}
          key={idx}
        />
      ))}
    </div>
  );
};

const CommentButtons = ({
  statuses,
  selectedStatusIDDic,
  setSelectedStatusIDDic,
}) => {
  const CommentButton = ({ status }) => {
    const bgColor = status.bgColor;
    const handleClick = () => {
      if (selectedStatusIDDic.contains(status.id)) {
        const newDic = new Dictionary(selectedStatusIDDic);
        newDic.delete(status.id);
        setSelectedStatusIDDic(newDic);
      } else {
        const newDic = new Dictionary(selectedStatusIDDic);
        newDic.push(status.id, 0);
        setSelectedStatusIDDic(newDic);
      }
    };

    const isActive = selectedStatusIDDic.contains(status.id);

    return (
      <button
        // color={status.color.toString()}
        className={`
                    flex justify-between items-center max-sm:w-auto sm:w-32 max-sm:mr-1 mb-2 max-sm:px-1 sm:px-2
                    rounded-lg hover:bg-gray-100
                    duration-200
                    max-sm:text-sm sm:text-base
                    ${isActive && `bg-gray-100`}
                `}
        onClick={() => {
          handleClick();
        }}
      >
        {isActive ? (
          <RadioMarkedIcon className={`text-[${bgColor}]`} />
        ) : (
          <RadioIcon className={`text-[${bgColor}]`} />
        )}

        <span className="w-full truncate">{status.name}</span>
      </button>
    );
  };

  return (
    <React.Fragment>
      {statuses.map((status, idx) => (
        <CommentButton status={status} key={idx} />
      ))}
    </React.Fragment>
  );
};

const UserComment = ({ comment, userType }) => {
  const isMainComment = comment.user.userType === userType;

  return (
    <div className="flex justify-between w-full">
      {isMainComment && <CommentMarginBox />}

      <CommentBox isMainComment={isMainComment}>
        <CommentStatusBox className={`bg-[${comment.status.bgColor}]`}>
          <CommentStatusSpan>{comment.status.name}</CommentStatusSpan>
          <CommentDetailBox>
            <span>{comment.user.name}</span>
            <span className="text-gray-600 ml-1">{comment.created}</span>
          </CommentDetailBox>
        </CommentStatusBox>
        {/* <span className="text-xs text-gray-500 px-1">{comment.status.name} {comment.user.name} {comment.created}</span> */}
        <CommentMessageBox isMainComment={isMainComment}>
          <span>{comment.comment}</span>
        </CommentMessageBox>
      </CommentBox>

      {!isMainComment && <CommentMarginBox />}
    </div>
  );
};

const AlertComment = ({ comment }) => {
  return (
    <div className="flex justify-center w-full">
      <AlertCommentBox>
        <span className={`text-[${comment.status.bgColor}] font-semibold`}>
          {comment.status.name}
          {Utils.isBatchimEnding(comment.status.name) ? "으로" : "로"}{" "}
          변경되었습니다.
        </span>
        <span className="ml-1">{comment.created}</span>
      </AlertCommentBox>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const Comment = React.forwardRef(({ comment, userType }, ref) => {
  return (
    <div ref={ref}>
      {comment.isUpdateAlert ? (
        <AlertComment comment={comment} />
      ) : (
        <UserComment comment={comment} userType={userType} />
      )}
    </div>
  );
});

export const StatusComment = ({ column, value, selectedStatusID }) => {
  const [selectedCommentStatusIDDic, setSelectedCommentStatusIDDic] = useState(
    new Dictionary()
  );
  const [comments, setComments] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const { userType } = useUserStore((state) => state);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [scrollRef, scrollInView] = useInView();
  const [isLoading, setLoading] = useState(false);

  const textAreaRef = useRef();

  useEffect(() => {
    setComments([]);
    setPage(1);
    setMaxPage(1);
    getComments(1);
  }, [selectedCommentStatusIDDic]);

  useEffect(() => {
    getCommentRecents();
  }, [selectedStatusID]);

  useEffect(() => {
    // 맨 위의 아이템이 보이면 업데이트
    if (scrollInView && comments.length >= 10 && page + 1 <= maxPage) {
      setPage(page + 1);
      getComments(page + 1);
    }
  }, [scrollInView]);

  const getComments = (pageIndex) => {
    if (!isLoading) {
      setLoading(true);
      getPerformStatusComments(
        column.id,
        value.performID,
        selectedCommentStatusIDDic.getKeys(),
        pageIndex
      ).then((response) => {
        if (response.count > 0) {
          if (pageIndex === 1) {
            setMaxPage(Math.ceil(response.count / COMMENT_PAGE_SIZE));
            setComments(response.items);
          } else {
            setComments([...comments, ...response.items]);
          }
        }
        setLoading(false);
      });
    }
  };

  const getCommentRecents = () => {
    if (!isLoading) {
      setLoading(true);
      getPerformStatusComments(
        column.id,
        value.performID,
        selectedCommentStatusIDDic.getKeys(),
        1
      ).then((response) => {
        if (response.items.length > 0) {
          let newComments = [];

          for (let i = 0; i < response.items.length; i++) {
            const newComment = response.items[i];
            const duplicateComment = comments.find(
              (comment) => comment.id === newComment.id
            );

            if (duplicateComment === undefined) {
              newComments.push(newComment);
            } else {
              break;
            }
          }

          setComments([...newComments, ...comments]);
        }
        setLoading(false);
      });
    }
  };

  const handleTextArea = (value) => {
    updateTextAreaHeight();
    setCommentValue(value);
  };

  const updateTextAreaHeight = (init = false) => {
    textAreaRef.current.style.height = "auto";

    if (!init) {
      let height =
        textAreaRef.current.scrollHeight > MAX_COMMENT_INPUT_HEIGHT
          ? MAX_COMMENT_INPUT_HEIGHT
          : textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = height + "px";
    }
  };

  const handleSubmitKeyPress = (e) => {
    if (e.key == "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitComment();
    }
  };

  const submitComment = () => {
    updatePerformStatusComments(
      column.id,
      value.performID,
      selectedStatusID,
      commentValue
    ).then((response) => {
      if (response) {
        getCommentRecents();
      }
    });
    setCommentValue("");
    updateTextAreaHeight(true);
  };

  return (
    <StatusCommentLayout>
      {/* <StatusCommentTypeBox>
        <CommentButtons
          statuses={column.statuses}
          selectedStatusIDDic={selectedCommentStatusIDDic}
          setSelectedStatusIDDic={setSelectedCommentStatusIDDic}
        />
      </StatusCommentTypeBox> */}
      {/* 크기 관리를 위해 Relative - Absolute 적용 */}
      <StatusCommentsContainer>
        <StatusCommentsLayout>
          <StatusCommentsCol>
            {comments.map((comment, idx) => (
              <React.Fragment key={idx}>
                {comments.length - 1 === idx ? (
                  <Comment
                    comment={comment}
                    userType={userType}
                    ref={scrollRef}
                  />
                ) : (
                  <Comment comment={comment} userType={userType} />
                )}
              </React.Fragment>
            ))}
          </StatusCommentsCol>

          {selectedStatusID >= 0 && (
            <StatusCommentInputLayout>
              <StatusCommentInputBar>
                <StatusCommentInputTextAreaContainer>
                  <CommentInputTextArea
                    rows={1}
                    ref={textAreaRef}
                    onChange={(e) => {
                      handleTextArea(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      handleSubmitKeyPress(e);
                    }}
                    value={commentValue}
                  />
                </StatusCommentInputTextAreaContainer>
                <CommentSubmitButton
                  onClick={() => {
                    submitComment();
                  }}
                >
                  <CommentSubmitIcon />
                </CommentSubmitButton>
              </StatusCommentInputBar>
            </StatusCommentInputLayout>
          )}
        </StatusCommentsLayout>
      </StatusCommentsContainer>
    </StatusCommentLayout>
  );
};

const StatusEdit = ({ column, perform, value, closeEditComponent }) => {
  const { user } = useUserStore();
  const [selectedStatusID, setSelectedStatusID] = useState(value.statusID);

  const handleUpdateStatus = async (status, comment = "") => {
    const response = await updateItemPerformValue(column.id, value.performID, {
      status: status.id,
    });

    if (!response) {
      alert("적용 실패했습니다.");
      return;
    }

    closeEditComponent(true, true);
    setSelectedStatusID(status.id);

    if (!Utils.isStringNullOrEmpty(comment)) {
      updatePerformStatusComments(
        column.id,
        value.performID,
        status.id,
        comment
      );
    }
  };

  return (
    <PerformValueInputLayout>
      <PerformValueInputTitle>
        <span>{column.name}</span>
      </PerformValueInputTitle>
      <PerformValueInputSubTitle>
        <span>현황</span>
      </PerformValueInputSubTitle>
      <StatusButtons
        disabled={!AccessRules.performValueWrite(column, perform, user)}
        column={column}
        statuses={column.statuses}
        selectedStatusID={selectedStatusID}
        updateStatus={handleUpdateStatus}
      />
      <PerformValueInputSubTitle>
        <span>메모</span>
        <div className="h-[1rem] ml-1">
          <PerformTableFilterIcon className="text-gray-500" />
        </div>
      </PerformValueInputSubTitle>
      <StatusComment
        column={column}
        value={value}
        selectedStatusID={selectedStatusID}
      />
    </PerformValueInputLayout>
  );
};

export const PerformValueStatusCell = ({
  column,
  perform,
  value,
  setEditComponent,
}) => {
  // const [editState, setEditState] = useState(EditStateTypes.WAIT)
  const [status, setStatus] = useState(new PerformColumnStatus());

  const [showEditComponent, closeEditComponent] = setEditComponent;

  useEffect(() => {
    if (column.statuses.length > 0 && value.statusID >= 0) {
      const newStatus = column.statuses.find((s) => s.id === value.statusID);
      if (newStatus !== undefined) {
        setStatus(newStatus);
      }
    } else {
      setStatus(new PerformColumnStatus());
    }
  }, [column, value]);

  const handleShowEdit = () => {
    showEditComponent(
      <StatusEdit
        column={column}
        perform={perform}
        value={value}
        closeEditComponent={closeEditComponent}
      />
    );
  };

  return (
    <PerformValueBox>
      <PerformStatus
        style={{
          color: status.color,
          backgroundColor: status.bgColor.toString(),
        }}
        onClick={() => {
          handleShowEdit();
        }}
      >
        {status.name}
      </PerformStatus>
    </PerformValueBox>
  );
};
