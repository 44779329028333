import axios from "axios"
import ConstCookie from "../../constants/ConstCookie"
import { getCookie, removeCookie, setCookie } from "../../utils/Cookie"
import Utils from "../../utils/Utils"
import { getAccessToken, getRefreshToken } from "../api/users"
import { setAccessToken } from "../services/users"
import { logout } from "../../services/users"
import ConstLocalStorage from "../../constants/ConstLocalStorage"

// const URL = "http://172.30.1.46:8000"
// const URL = "http://127.0.0.1:8000"
const URL = process.env.REACT_APP_API_SERVER_URL

const axiosApi = (options) => {
    return axios.create({ baseURL: URL, ...options })
}

const axiosCredentialApi = (options) => {
    const api = axios.create({
        baseURL: URL,
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        ...options,
    })

    return api
}

const axiosAuthApi = (options) => {
    const api = axios.create({
        baseURL: URL,
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        ...options,
    })

    // 요청 시
    api.interceptors.request.use(
        (config) => {
            const accessToken = getCookie(ConstCookie.USER_ACCESS_TOKEN)

            // 쿠키가 없는 경우 http only 쿠키에서 보낸다.
            if (!Utils.isStringNullOrEmpty(accessToken)) {
                config.headers.Authorization = `Bearer ${getCookie(ConstCookie.USER_ACCESS_TOKEN)}`
            }

            return config
        },
        async (error) => {
            return Promise.reject(error)
        },
    )
    // 응답 시
    api.interceptors.response.use(
        (config) => {
            return config
        },
        async (error) => {
            const originalRequest = error.config

            // 재요청 실패
            if (error.response.status === 401 && error._retry) {
                return Promise.reject(error)
            }

            // 재요청 시도
            if (error.response.status === 401) {
                const refreshToken = getRefreshToken()

                // Fullfil
                const response = await getAccessToken(refreshToken)

                if (response !== null && response.access && response.access.length > 0) {
                    setAccessToken(response)
                    // 쿠키가 있는지로 http only 쿠키를 사용하는지 확인한다.
                    if (!Utils.isStringNullOrEmpty(getCookie(ConstCookie.USER_ACCESS_TOKEN))) {
                        originalRequest.headers.Authorization = `Bearer ${getCookie(ConstCookie.USER_ACCESS_TOKEN)}`
                    }

                    return await axios(originalRequest)
                } else {
                    let isAlerted = false
                    // 중복 호출을 막기위해 localStorage의 userType으로 확인한다.
                    if (!Utils.isStringNullOrEmpty(localStorage.getItem(ConstLocalStorage.USER_TYPE))) {
                        alert("로그인 세션 만료로 인해 로그아웃합니다.")
                        isAlerted = true
                    }
                    logout()

                    if (isAlerted) {
                        location.reload()
                    }
                }
            }

            return Promise.reject(error)
        },
    )
    return api
}

const axiosBothApi = (options) => {
    const api = axiosAuthApi(options)
    // 기존 request 를 지운다.
    api.interceptors.request.clear()
    // 요청 시
    api.interceptors.request.use(
        (config) => {
            const cookie = getCookie(ConstCookie.USER_ACCESS_TOKEN)
            if (cookie !== undefined) {
                config.headers.Authorization = `Bearer ${cookie}`
            }
            return config
        },
        async (error) => {
            return Promise.reject(error)
        },
    )
    return api
}

export const credentialInstance = axiosCredentialApi()
export const defaultInstance = axiosApi()
export const authInstance = axiosAuthApi()
export const giftiInstance = axiosAuthApi({ headers: { "Content-Type": "application/x-www-form-urlencoded " } })
export const defaultOrAuthInstance = axiosBothApi()
export const fileInstance = axiosAuthApi({ headers: { "Content-Type": "multipart/form-data" } })
export const downloadInstance = axiosApi({ responseType: "blob", cache: false })
