import React, { useEffect, useState } from "react"
import { getStoreSignupAgreementForm, getSystemAgreementFrom } from "../../apis/api/commons"
import { AgreementForm, AgreementLinkType } from "../../types/Agreement"
import { Check } from "@styled-icons/boxicons-regular/Check"
import tw from "tailwind-styled-components"
import { UserInput, UserSMSAuthRequestButton } from "../../components/user/Inputs"
import { EditStateTypes } from "../../types/EditorTypes"
import { EmailValidate } from "../../components/user/EmailValidate"
import { MobileModal } from "./../../components/MobileModal"
import { ModalContainer } from "../../components/ModalContainer"
import { addStoreData } from "../../apis/api/users"
import { useParams } from "react-router-dom"
import CelebrateAnimation from "./CelebrateAnimation"
import {
    createBrandItemMarketerPersonalAgreement,
    getBrandItemMarketerPersonalAgreement,
    getBrandItemMarketerPersonalAgreements,
    getItemPersonalAgreementPaper,
    updateBrandItemMarketerPersonalAgreement,
} from "../../apis/api/boardItems"
import Utils from "../../utils/Utils"
import ModalContainerPortal from "../../components/ModalContainerPortal"
import CKEditorContent from "../../components/boards/CKEditorContent"
import { useWindowWidth } from "../../hooks/useWindowWidth"

const CheckIcon = tw(Check)`
    h-full mb-2
`
const AgreementSubmit = tw.div`
    flex flex-col 
    w-screen h-screen
`
const AgreementNavigation = tw.nav`
    flex justify-center items-center
    w-full h-14 
    bg-white border-b 
`
const AgreementTitle = tw.span`
    text-lg font-semibold text-nightblue-600
`
const AgreementContainer = tw.div`
    flex flex-col items-center
    w-full h-full 
    px-6 py-6
`
const AgreementThirdParyGrid = tw.div`
    grid grid-flow-row grid-rows-1 gap-4
    max-w-[500px] w-full 
`
const ThirdPartyTitle = tw.span`
    font-semibold text-lg
`
const ThirdPartyAgreement = tw.div`
    max-w-[500px] h-128 mt-4 p-4
    border rounded-md 
    overflow-scroll scroll-transparent
`
const ThirdPartyAgreementButtonContainer = tw.div`
    flex items-center px-4 py-3
    w-full h-14  
    border rounded-md  duration-200
    ${(props) => (props.state ? "border-nightblue-400" : "")}
`
const ThirdPartyAgreementButtonText = tw.span`
    font-semibold
    ${(props) => (props.state ? "text-nightblue-400" : "text-gray-300")}
`
const ThirdPartyAgreementCheckContainer = tw.div`
    flex justify-center items-center p-1.25 mr-2
    h-full aspect-square 
    border-2 rounded-full duration-200
    ${(props) => (props.state ? "border-nightblue-400" : "")}
`
const ThirdPartyAgreementCheck = tw.div`
    h-full aspect-square duration-200
    rounded-full 
    ${(props) => (props.state ? "bg-nightblue-400" : "hidden`")}
`
const AgreementButtonContainer = tw.footer`
    flex flex-col mt-10
    max-w-[500px] w-full 
`
const AgreementButton = tw.button`
    flex justify-center items-center
    w-full h-12 
    rounded-md ${(props) => (props.state ? "bg-nightblue-600" : "bg-gray-300")} text-lg 
    text-white 
`

export default function AgreementSignup() {
    const [agreementPaper, setAgreementPaper] = useState("")
    const [name, setName] = useState("")
    // const [email, setEmail] = useState('')
    const [tel, setTel] = useState("")
    const [agreementPaperID, setAgreementPaperID] = useState(-1)

    const [marketerID, setMarketerID] = useState(-1)
    const [itemID, setItemID] = useState(-1)
    const [itemMarketerID, setItemMarketerID] = useState(-1)

    const [isFinished, setIsFinished] = useState(false)

    const [emailStatus, setEmailStatus] = useState(EditStateTypes.WAIT)
    const [nameStatus, setNameStatus] = useState(false)
    const [telStatus, setTelStatus] = useState(EditStateTypes.WAIT)
    const [readyToAgree, setReadyToAgree] = useState(false)
    const [agreementState, setAgreementState] = useState(false)
    const { item_marketer_code, agreement_id } = useParams()
    const [isOpen, setIsOpen] = useState(false)

    const windowWidth = useWindowWidth()

    const setData = async () => {
        const parsedCode = Utils.parseEncrypt(item_marketer_code)
        const codes = parsedCode.split("%")

        if (codes.length !== 3) {
            alert("정보를 찾을 수 없습니다.")
            return
        }

        const marketerID = codes[0]
        setMarketerID(marketerID)
        const itemID = codes[1]
        setItemID(itemID)
        const itemMarketerID = codes[2]
        setItemMarketerID(itemMarketerID)

        const paper = await getItemPersonalAgreementPaper(itemID)
        if (paper.id < 0) {
            alert("동의서 정보를 찾을 수 없습니다.")
            // return
        }
        setAgreementPaperID(paper.id)
        setAgreementPaper(paper.content)

        const agreementID = agreement_id

        if (Utils.isStringNullOrEmpty(agreementID)) {
            return
        }

        const agreement = await getBrandItemMarketerPersonalAgreement(agreementID)
        if (Utils.isStringNullOrEmpty(agreement)) {
            alert("정보를 찾을 수 없습니다.")
            return
        }
        setName(agreement.name)
        setTel(agreement.tel)
    }

    // useEffect(() => {
    //     const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    //     if (email !== '') {
    //         setEmailStatus(regex.test(email) ? EditStateTypes.DONE : EditStateTypes.FAILED)
    //     } else {
    //         setEmailStatus(EditStateTypes.WAIT)
    //     }
    // }, [email])

    useEffect(() => {
        if (name === "") {
            setNameStatus(false)
        } else {
            setNameStatus(true)
        }
    }, [name])

    useEffect(() => {
        const regex = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/
        if (tel !== "") {
            setTelStatus(regex.test(tel) ? EditStateTypes.DONE : EditStateTypes.FAILED)
        } else {
            setTelStatus(EditStateTypes.WAIT)
        }
    }, [tel])

    useEffect(() => {
        if (nameStatus && telStatus === EditStateTypes.DONE && agreementState) {
            setReadyToAgree(true)
        } else {
            setReadyToAgree(false)
        }
    })

    useEffect(() => {
        setData()
    }, [])

    const handleOnClick = () => {
        setAgreementState(!agreementState)
    }

    const handleStoreAdd = async () => {
        if (Utils.isNullOrUndefined(itemMarketerID) || itemMarketerID < 0) {
            alert("정보를 찾을 수 없습니다.")
            return
        }

        let data = {
            item_marketer_id: itemMarketerID,
            paper_id: agreementPaperID,
            is_agree: true,
            tel: tel,
            name: name,
        }

        let result = false

        // 새로 생성하는 경우
        if (Utils.isStringNullOrEmpty(agreement_id)) {
            result = await createBrandItemMarketerPersonalAgreement(data)
        } else {
            result = await updateBrandItemMarketerPersonalAgreement(agreement_id, data)
        }

        if (result) {
            alert("처리되었습니다.")
        } else {
            alert("실패했습니다.")
        }
        // const data = await addStoreData(name, tel, email, AgreementLinkType.SENDLINK, null, item_marketer_code);
        // if (data.Succeed === true) {
        //     setIsFinished(true)
        // }
        // else {
        //     // alert(data.errors.non_field_errors[0])
        //     alert('이미 존재하는 전화번호 입니다.')
        // }
    }

    return (
        <AgreementSubmit>
            <AgreementNavigation>
                <AgreementTitle>개인 정보 동의</AgreementTitle>
            </AgreementNavigation>

            <AgreementContainer>
                {!isFinished && (
                    <>
                        <AgreementThirdParyGrid className="mb-6">
                            <div className="relative">
                                <UserInput
                                    placeholder={"이름"}
                                    type={"text"}
                                    data={name}
                                    setData={setName}
                                    editStateType={nameStatus ? EditStateTypes.DONE : EditStateTypes.WAIT}
                                />
                            </div>
                            <div className="relative">
                                <UserInput
                                    placeholder={"전화번호"}
                                    isTel={true}
                                    type={"tel"}
                                    data={tel}
                                    setData={setTel}
                                    editStateType={telStatus}
                                />
                                <div
                                    className={`${
                                        telStatus === EditStateTypes.FAILED ? "block" : "hidden"
                                    } right-0 absolute px-4 py-1.5 bg-white border rounded-md z-30`}
                                >
                                    <span className={`text-sm text-red-400`}>전화번호를 확인 해주시기 바랍니다.</span>
                                </div>
                            </div>
                            {/* <div className='relative'>
                                <UserInput placeholder={'이메일'} type={'email'} data={email} setData={setEmail} editStateType={emailStatus} />
                                <div className={`${emailStatus === EditStateTypes.FAILED ? "block" : "hidden"} right-0 absolute px-4 py-1.5 bg-white border rounded-md z-30`}>
                                    <span className={`text-sm text-red-400`}>이메일 형식을 확인 해주시기 바랍니다.</span>
                                </div>
                            </div> */}
                        </AgreementThirdParyGrid>
                        <div className="w-full max-w-[500px]">
                            <ThirdPartyAgreementButtonContainer state={agreementState}>
                                <div
                                    className="flex-1 h-full items-center flex"
                                    onClick={() => {
                                        handleOnClick()
                                    }}
                                >
                                    <ThirdPartyAgreementCheckContainer state={agreementState}>
                                        <ThirdPartyAgreementCheck state={agreementState} />
                                    </ThirdPartyAgreementCheckContainer>

                                    <ThirdPartyAgreementButtonText state={agreementState}>
                                        개인정보 수집·이용 동의
                                    </ThirdPartyAgreementButtonText>
                                </div>
                                <button
                                    className="h-full px-2 font-semibold"
                                    onClick={() => {
                                        setIsOpen(true)
                                    }}
                                >
                                    내용보기
                                </button>
                            </ThirdPartyAgreementButtonContainer>
                            <AgreementButtonContainer>
                                <span className="ml-2 my-2 text-slate-600">개인정보 수집·이용에 동의합니다.</span>
                                <AgreementButton
                                    state={readyToAgree}
                                    disabled={!readyToAgree}
                                    onClick={() => {
                                        handleStoreAdd()
                                    }}
                                >
                                    확인
                                </AgreementButton>
                            </AgreementButtonContainer>
                        </div>
                        <div className="max-lg:hidden lg:block">
                            {windowWidth >= 640 && (
                                <ModalContainerPortal show={isOpen} setShow={setIsOpen}>
                                    <div className="max-sm:hidden sm:min-w-[32rem] bg-white p-8 rounded-md">
                                        <CKEditorContent content={agreementPaper} />
                                    </div>
                                </ModalContainerPortal>
                            )}
                        </div>
                        <div className="max-lg:block lg:hidden">
                            <MobileModal
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                canBigger={false}
                                closeBySwip={false}
                                defaultHeight={100}
                            >
                                <div className="w-full h-full p-4">
                                    <CKEditorContent content={agreementPaper} />
                                </div>
                            </MobileModal>
                        </div>
                    </>
                )}
                {isFinished && (
                    <>
                        <div className="w-full max-w-[500px] flex-1 flex flex-col justify-center items-center">
                            <div className="bg-lime-500 h-20 aspect-square border rounded-full mb-6 animate-bounce duration-300 drop-shadow-lg">
                                <CheckIcon className="text-white" />
                            </div>
                            <div className="h-46 font-semibold">
                                <span className="text-xl">감사합니다!</span>
                            </div>
                            <CelebrateAnimation />
                        </div>
                    </>
                )}
            </AgreementContainer>
        </AgreementSubmit>
    )
}
