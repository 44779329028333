import React, { useState, useRef } from "react"
import { UserInput, MyPageUserInput } from "../../components/user/Inputs"
import tw from "tailwind-styled-components"
import styled from "styled-components"

export const SignUpTelButton = tw.button`
    ml-4 max-lg:px-1 lg:px-3 py-1
    max-lg:text-xs lg:text-sm 
    border rounded-lg
    duration-200
    text-sky-700 bg-white hover:bg-sky-100 border-sky-500
`
export const FormGroup = styled.div`
    position: relative;
    padding: 15px 0 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 100%;
    /* @media screen and (max-width: 800px) {
        width: 80%;
    } */
    height: 3.75rem;
`

export const FormInput = styled.input`
    position: absolute;
    font-family: inherit;
    width: ${(props) => (props.passwidth ? props.passwidth : "100%")};
    border: 0;
    border-bottom: 2px solid gray;

    outline: 0;
    font-size: 1rem;
    color: black;
    padding: 0.5rem 0.5rem;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
        user-select: none;
    }

    &:placeholder-shown ~ label {
        font-size: 1rem;
        cursor: text;
        top: 20px;
        padding: 0rem 0.25rem;
        color: rgb(156, 163, 175); // text-gray-400
    }

    ~ label {
        position: absolute;
        top: -2px;
        padding: 0rem 0.25rem;
        display: block;
        transition: 0.2s;
        font-size: 0.9rem;
        pointer-events: none;
        color: rgb(107, 114, 128); // text-gray-500
    }
    &:focus {
        ~ label {
            position: absolute;
            pointer-events: none;
            top: -2px;
            display: block;
            transition: 0.2s;
            font-size: 0.95rem;
            color: #2073bc;
            font-weight: 500;
        }
        padding-bottom: 6px;
        font-weight: 500;
        border-width: 3px;
        border-color: #2073bc;
        border-image-slice: 1;
    }
`
// border-color:
// ${(props) => props.editStateType === EditStateTypes.WAIT && "gray"}
// ${(props) => props.editStateType === EditStateTypes.FAILED && "#ef4444"}
// ${(props) => props.editStateType === EditStateTypes.DONE && "#22c55e"};

export function AddressSearch({ address, setAddress, detailAddress, setDetailAddress, formType = 0 }) {
    // const [address, setAddress] = useState('');
    // const [detailAddress, setDetailAddress] = useState('');
    const [detailDisableState, setDetailDisableState] = useState(true)

    const [postcode, setPostcode] = useState("") //우편번호 안쓸 예정
    const [extraAddress, setExtraAddress] = useState("") //추가정보 안쓸 예정

    const detailAddressRef = useRef(null)

    const sample6_execDaumPostcode = () => {
        new window.daum.Postcode({
            oncomplete: function (data) {
                let addr = ""
                let extraAddr = ""

                if (data.userSelectedType === "R") {
                    addr = data.roadAddress
                } else {
                    addr = data.jibunAddress
                }

                if (data.userSelectedType === "R") {
                    if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname
                    }
                    if (data.buildingName !== "" && data.apartment === "Y") {
                        extraAddr += extraAddr !== "" ? ", " + data.buildingName : data.buildingName
                    }
                    if (extraAddr !== "") {
                        extraAddr = " (" + extraAddr + ")"
                    }
                } else {
                    extraAddr = ""
                }

                setPostcode(data.zonecode)
                setAddress(addr)
                setExtraAddress(extraAddr)
                setDetailDisableState(false)

                if (detailAddressRef.current !== null) {
                    detailAddressRef.current.focus()
                }
            },
        }).open()
    }

    return (
        <React.Fragment>
            {/* 마케터 추천 정보 주소 */}
            {formType === 2 && (
                <div className="address-search">
                    <div className="flex items-center">
                        <UserInput
                            placeholder={"아파트 및 건물 주소"}
                            type={"string"}
                            data={address}
                            setData={setAddress}
                            disabled
                        >
                            <SignUpTelButton onClick={sample6_execDaumPostcode}>우편번호 찾기</SignUpTelButton>
                        </UserInput>
                    </div>
                    {/* <UserInput
                        placeholder={"상세주소"}
                        type={"string"}
                        data={detailAddress}
                        setData={setDetailAddress}
                        ref={detailAddressRef}
                        disabled={detailDisableState}
                        maxLength={200}
                    /> */}
                </div>
            )}
            {/* 브랜드 회원가입 시 */}
            {formType === 1 && (
                <div className="address-search">
                    <div className="flex items-center">
                        <UserInput placeholder={"주소"} type={"string"} data={address} setData={setAddress} disabled>
                            <SignUpTelButton onClick={sample6_execDaumPostcode}>우편번호 찾기</SignUpTelButton>
                        </UserInput>
                    </div>
                    <UserInput
                        placeholder={"상세주소"}
                        type={"string"}
                        data={detailAddress}
                        setData={setDetailAddress}
                        ref={detailAddressRef}
                        disabled={detailDisableState}
                        maxLength={200}
                    />
                </div>
            )}
            {formType === 0 && (
                <div className="address-search">
                    <div className="mt-2 mb-4">
                        <MyPageUserInput label={"회사 주소"} type={"string"} data={address} setData={setAddress}>
                            <SignUpTelButton onClick={sample6_execDaumPostcode}>우편번호 찾기</SignUpTelButton>
                        </MyPageUserInput>
                    </div>
                    <div className="mt-2 mb-4">
                        <MyPageUserInput
                            label={"회사 상세 주소"}
                            type={"string"}
                            data={detailAddress}
                            setData={setDetailAddress}
                            ref={detailAddressRef}
                            maxLength={200}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
