import { NavSize } from "../../types/DataEnum"
import LogoBig from "../../static/logo-3.png"
import LogoSmall from "../../static/logo_small.png"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { ProfileImageContainer, UserProfileDropDown } from "./Dropdown"
import tw from "tailwind-styled-components"
import { BoxMaxMin } from "../../layouts/Box"
import { ModalContainer } from "../ModalContainer"
import Login from "../user/login/LoginMain"
import { useUserStore } from "../../zustand/store"
import { useLoginModalStore } from "../../zustand/modalstore"
import { UserTypes } from "../../types/users/UserTypes"
import { getUserInfo } from "../../apis/api/users"
import Utils from "../../utils/Utils"
import { debounce } from "lodash"
import { useUiStore } from "../../zustand/uiStore"
import { isMobile } from "react-device-detect"

const MARGIN_SCROLL_HIDE = 100

const NavCon = tw.div`
    w-screen flex align-middle justify-center z-40 shadow-sm duration-300 fixed
    ${(props) => (props.navHide ? "max-md:-top-10 md:top-0" : "top-0")}
    ${(props) => (props.navsize === NavSize.BIG ? "max-md:h-10 md:h-16" : "h-10")};
    bg-white border-b border-slate-100 
    will-change-scroll
`

const NavInnerCon = tw.div`
    h-full items-center
`

// Big Logo : 1608 x 337
const Logo_img = tw.img`
    ${(props) => (props.navsize === NavSize.BIG ? "max-md:w-28 md:w-32 aspect-[1608/337]" : "w-6 aspect-square")};
`

const NavLinkStyle = tw(Link)`
    max-md:mx-4 max-lg:mx-8 lg:mx-10 no-underline  hover:text-blue-600 flex justify-center
    ${(props) => (props.navsize === NavSize.BIG ? "text-base" : "text-sm")};
    duration-200
`

const NavLinkSign = tw(Link)`
    max-lg:ml-4 lg:ml-10 no-underline bg-nightblue-500 flex justify-center items-center rounded-md text-white hover:bg-nightblue-400
    ${(props) => (props.navsize === NavSize.BIG ? "text-base h-9 w-24" : "text-sm h-7 w-20")};
    duration-200
`

const ModalShowBtn = tw.button`
    max-lg:mx-4 lg:mx-10 cursor-pointer w-fit hover:text-blue-600  flex justify-center
    ${(props) => (props.navsize === NavSize.BIG ? "max-md:text-sm md:text-base" : "text-sm")};
    duration-200
`

const TRANSPARENT_NAV_URLS = ["/"]

export const Nav = () => {
    const loginShow = useLoginModalStore((state) => state.loginShow)
    const setLoginShow = useLoginModalStore((state) => state.setLoginShow)

    const { userType } = useUserStore((state) => state)

    const location = useLocation()

    const [isTransaprent, setTransparent] = useState(false)
    const isTop = useRef(false)

    const [count, setCount] = useState(0)

    // const [navHide, setNavHide] = useState(false)
    const { mainNavHide, actions } = useUiStore()
    const [navSize, setNavSize] = useState(NavSize.BIG)
    const position = useRef(0)

    useEffect(() => {
        const outlet = document.querySelector("body")
        if (outlet !== null) {
            window.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (outlet !== null) {
                window.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    const handleScroll = useCallback(
        debounce(() => {
            if (position !== null) {
                const moving = window.scrollY

                // 모바일 환경에서만 스크롤 시 내비게이션을 숨긴다.
                if (window.innerWidth <= 768) {
                    // 스크롤을 마진 이상 해야 숨기기를 시작한다.
                    // 스크롤을 마진보다 더 올려야 보이도록 한다. (마진이 없으면 스크롤이 자꾸 꺼졋다 켜졌다 한다.)
                    actions.setMainNavHide(
                        position.current - MARGIN_SCROLL_HIDE < moving && moving > MARGIN_SCROLL_HIDE,
                    )
                    position.current = moving
                }

                if (!isMobile) {
                    setNavSize(moving < 10 ? NavSize.BIG : NavSize.SMALL)
                }
            }
        }, 300),
        [position],
    )

    // useEffect(()=>{
    //     setTransparent(TRANSPARENT_NAV_URLS.includes(location.pathname))
    // }, [location])

    return (
        <NavCon id="main-nav" navsize={navSize} isTransaprent={isTransaprent} navHide={mainNavHide}>
            <BoxMaxMin className="h-full w-full flex align-middle justify-center">
                <div className="w-full h-full flex justify-between max-md:px-4 md:px-2">
                    <NavInnerCon navlogocon="true" className="flex justify-start flex-shrink-0">
                        <Link to={{ pathname: `/` }}>
                            <Logo_img navsize={navSize} src={navSize === 1 ? LogoBig : LogoSmall} alt={null} />
                        </Link>
                    </NavInnerCon>
                    <NavInnerCon className="max-md:hidden md:flex justify-end">
                        <NavLinkStyle navsize={navSize} to={"/items"}>
                            영업아이템
                        </NavLinkStyle>
                        {/* <NavLinkStyle navsize={navSize} to={"/mypage"}>마이페이지</NavLinkStyle> */}

                        <NavLinkStyle navsize={navSize} to={"/ads"}>
                            사업아이템
                        </NavLinkStyle>
                        <NavLinkStyle navsize={navSize} to={"/notice"}>
                            공지사항
                        </NavLinkStyle>
                        {!Utils.isNullOrUndefined(userType) && userType !== UserTypes.NONE ? (
                            <React.Fragment>
                                <NavLinkStyle navsize={navSize} to={"/mypage/item"}>
                                    마이페이지
                                </NavLinkStyle>
                                <UserProfileDropDown navsize={navSize}></UserProfileDropDown>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <NavLinkStyle to={"/signup"} state={{ userType: UserTypes.BRAND }} navsize={navSize}>
                                    기업등록
                                </NavLinkStyle>
                                <NavLinkSign
                                    className="flex flex-end"
                                    navsize={navSize}
                                    onClick={() => {
                                        setLoginShow(true)
                                    }}
                                >
                                    로그인
                                </NavLinkSign>
                            </React.Fragment>
                        )}
                    </NavInnerCon>
                    <NavInnerCon className="max-md:flex md:hidden justify-end">
                        {!Utils.isNullOrUndefined(userType) && userType !== UserTypes.NONE ? (
                            <UserProfileDropDown navsize={navSize}></UserProfileDropDown>
                        ) : (
                            <ModalShowBtn
                                navsize={navSize}
                                onClick={() => {
                                    setLoginShow(true)
                                }}
                            >
                                <div className="flex flex-center h-7 aspect-square rounded-full bg-slate-600 text-slate-200">
                                    <i className="fa-solid fa-user"></i>
                                </div>
                            </ModalShowBtn>
                        )}
                    </NavInnerCon>
                </div>
            </BoxMaxMin>
        </NavCon>
    )
}
