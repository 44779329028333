import React, { useState, useEffect, useCallback, useRef } from "react"
import tw from "tailwind-styled-components"
import { RESPONSIVE_WIDTH } from "../../constants/ConstUI"
import Utils from "../../utils/Utils"
// import SideBarMenu from './'

export const SpinLoadingBackgroundType = {
    FILL: 0,
    BLUR: 1,
    NONE: 2,
}

const SpinLoad = tw.div`
w-24 aspect-square
border-[12px] rounded-full border-nightblue-500 border-r-amber-400
animate-spin
`
const SpinLoadingContainer = tw.div`
${(props) =>
    props.isScreen
        ? `fixed top-[${props.top}px] left-0 w-screen h-[calc(100vh-${props.top}px)]`
        : `absolute w-full h-full`}
flex flex-col justify-between 
duration-500
opacity-0 -z-50
will-change-auto
${(props) => props.backgroundType === SpinLoadingBackgroundType.FILL && "bg-slate-100"}
${(props) => props.backgroundType === SpinLoadingBackgroundType.BLUR && "bg-slate-100/30 backdrop-blur-sm"}
${(props) => props.backgroundType === SpinLoadingBackgroundType.NONE && "bg-transparent"}
`
const SpinLoadContainer = tw.div`
fixed absolute-center z-[110]
${(props) => (props.show ? "flex" : "hidden")}
flex-col flex-center
`
const SpinLoadNavigation = tw.div`
flex flex-center h-10 w-full
'border-b bg-white 
text-nightblue-600 font-bold  
`
const SpinLoadFooter = tw.div`
w-full flex flex-col
`
const SpinLoadText = tw.span`
flex justify-center items-center w-full h-40
font-bold text-2xl text-nightblue-800 
`

const MessageBox = tw.div`
    flex flex-center px-3 py-2 mt-4
    bg-blue-50 rounded-md border border-nightblue-600
    text-nightblue-700 font-medium
`

function SpinLoading({
    isLoading, // 로딩 여부
    maxWindowWidth = 99999, // 창의 너비가 해당 값 이하에서만 로딩 창이 보인다.
    top = 40, // 로딩 창의 top 위치 40=내비게이션값이므로 40을 기본값으로 사용
    isScreen = true, // 로딩 창의 크기가 화면 만큼인지 여부 (모달 창을 로딩할 경우 모달크기만큼만 사용한다.)
    backgroundType = SpinLoadingBackgroundType.FILL,
    message = "",
}) {
    const [loadingState, setLoadingState] = useState(isLoading)
    const [show, setShow] = useState(true)

    useEffect(() => {
        if (isLoading) {
            setLoadingState(true)
            setShow(true)
        } else {
            setShow(false)
            setTimeout(() => {
                setLoadingState(false)
            }, 1000)
        }
    }, [isLoading])

    return (
        <React.Fragment>
            {loadingState && window.innerWidth < maxWindowWidth && (
                <React.Fragment>
                    <SpinLoadingContainer
                        className={`${show && "opacity-100 z-[100] duration-0"}`}
                        isScreen={isScreen}
                        top={top}
                        backgroundType={backgroundType}
                    >
                        {/* <SpinLoadNavigation>
                            S<span className='font-bold text-amber-400'>.</span>MARANG
                        </SpinLoadNavigation> */}
                        {/* <SpinLoadFooter>
                            <SpinLoadText>
                            Loading...
                            </SpinLoadText>
                            <div className='h-12 w-full border-t bg-white' />
                        </SpinLoadFooter> */}
                    </SpinLoadingContainer>
                    <SpinLoadContainer show={show}>
                        <SpinLoad />
                        {!Utils.isStringNullOrEmpty(message) && (
                            <MessageBox>
                                <span>{message}</span>
                            </MessageBox>
                        )}
                    </SpinLoadContainer>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default SpinLoading
