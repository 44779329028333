import React, { useEffect, useState } from "react"
import { PerformUpdateColumnLayout, PerformUpdateColumnTitle, PerformUpdateValueBox } from "./MyPagePerformStyle"
import { FilterInputSearchButton, FilterInputSearchIcon } from "../../../components/brandItems/FilterInputsStyle"
import { getItemMarketers } from "../../../apis/api/boardItems"
import { useInView } from "react-intersection-observer"
import { BrandItemMarketerSearchOptionTypes } from "../../../types/brandItems/BrandItemOption"
import { useFocus } from "../../../hooks/useFocus"
import ItemMarketerNameChain from "../../../components/brandItems/ItemMarketerNameChain"
import { useMouseHover } from "../../../hooks/useMouseHover"

const PAGE_SIZE = 20

export default function MyPagePerformUpdateMarketerSearch({
    itemID,
    marketerID,
    setMarkerterID,
    initSearch,
    performID,
}) {
    const [search, setSearch] = useState("")
    const [marketers, setMarketers] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()

    const [focusRef, isFocus] = useFocus()
    const [hoverRef, isHover] = useMouseHover()

    useEffect(() => {
        getMarketers()
    }, [itemID, pageIndex])

    useEffect(() => {
        setSearch(initSearch)
    }, [initSearch])

    useEffect(() => {
        getItemMarketers(itemID, 1, PAGE_SIZE, "", BrandItemMarketerSearchOptionTypes.MARKETER, -1, -1, "-id").then(
            (pagination) => {
                setMarketers(pagination.items)
                setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
            },
        )
    }, [performID])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && marketers.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            setPageIndex(pageIndex + 1)
        }
    }, [scrollInView])

    const getMarketers = async () => {
        const pagination = await getItemMarketers(
            itemID,
            pageIndex,
            PAGE_SIZE,
            search,
            BrandItemMarketerSearchOptionTypes.MARKETER,
            -1,
            -1,
            "-id",
        )

        if (pagination.count > 0) {
            if (pageIndex === 1) {
                setMarketers(pagination.items)
                setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
            } else {
                setMarketers([...marketers, ...pagination.items])
            }
        } else {
            setMarketers([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        if (pageIndex === 1) {
            getMarketers()
        } else {
            setPageIndex(1)
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSelect = (itemMarketer) => {
        setMarkerterID(itemMarketer.marketer.user.id)
        setSearch(itemMarketer.marketerName)
    }

    return (
        <PerformUpdateColumnLayout readOnly={false}>
            <PerformUpdateColumnTitle>마케터</PerformUpdateColumnTitle>
            <PerformUpdateValueBox ref={hoverRef} className="relative">
                <div className="flex justify-between items-center w-full p-2 rounded-lg border bg-white">
                    <input
                        ref={focusRef}
                        type="text"
                        className="w-full px-2 focus:outline-none disabled:bg-gray-100"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={handleEnter}
                    />
                    <FilterInputSearchButton
                        className="max-lg:h-7 lg:h-7 px-2"
                        onClick={() => {
                            handleSearch()
                        }}
                    >
                        <FilterInputSearchIcon />
                    </FilterInputSearchButton>
                </div>
                {(isFocus || isHover) && (
                    <div className="absolute z-20 top-9 flex flex-col w-full max-h-[14rem] p-2 bg-white rounded-b-lg border border-t-0 overflow-y-auto scroll-transparent scroll-overlay">
                        {marketers.map((marketer, index) => (
                            <div
                                key={index}
                                ref={index !== 0 ? scrollRef : null}
                                className={`flex items-center w-full h-9 p-2 rounded-lg ${
                                    marketer.marketer.user.id === marketerID ? "bg-blue-100" : "hover:bg-slate-100"
                                } cursor-pointer`}
                                onClick={() => {
                                    handleSelect(marketer)
                                }}
                            >
                                <ItemMarketerNameChain nameChain={marketer.marketerNameChain} />
                            </div>
                        ))}
                    </div>
                )}
            </PerformUpdateValueBox>
        </PerformUpdateColumnLayout>
    )
}
