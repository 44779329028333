import tw from "tailwind-styled-components"
import styled from "styled-components"
import { Settings } from "@styled-icons/feather/Settings"
import { Create } from "@styled-icons/material-sharp/Create"
import { Update } from "@styled-icons/material/Update"
import { Versions } from "@styled-icons/octicons/Versions"
import { DataTrending } from "@styled-icons/fluentui-system-regular/DataTrending"
import { Detail } from "@styled-icons/boxicons-solid/Detail"
import { LinkExternal } from "@styled-icons/boxicons-regular/LinkExternal"
import { Link } from "@styled-icons/feather/Link"
import { ListControlItem } from "../MyPageMainStyle"
import { FilePaper2 } from "@styled-icons/remix-line/FilePaper2"
import { AssignmentInd } from "@styled-icons/material-outlined/AssignmentInd"
import { CancelCircle } from "@styled-icons/icomoon/CancelCircle"
import { NOT_SCROLLABLE } from "../../../constants/ConstClassName"
import { DocumentBriefcase } from "@styled-icons/fluentui-system-regular/DocumentBriefcase"

export const CancelIcon = tw(CancelCircle)`
    mb-1.5
`

export const ItemBody = tw.div`
    w-full h-full flex items-center justify-center
    duration-300
`

export const ItemConMin = styled.div``

export const ItemLayout = tw.div`
    w-full max-sm:max-w-full max-xl:max-w-full xl:max-w-[1280px]
`

export const ItemInnerTop = tw.div`
    flex flex-col justify-between w-full py-4
`

export const ItemList = tw.div`
    w-full h-fit
    overflow-x-auto scroll-transparent
    border-y border-slate-200
    divide-y divide-slate-200
`
export const ItemInnerBottom = tw.div`
    w-full h-fit flex justify-center mt-6
`

export const ItemAddButton = tw.button`

`
export const ItemSortButton = tw(ListControlItem)`
flex p-1.75 w-10 lg:ml-2 text-gray-600
cursor-pointer
`

//여기서 부터 아이템 관리 리스트 뷰

export const ItemCardLayout = tw.div`
    max-lg:h-[7.5rem] lg:h-28 lg:min-w-[68rem] w-full
    max-lg:p-1.5 lg:p-2
    bg-white hover:bg-slate-50
`

// Item Detail
export const ItemDetailBox = tw.div`
    flex cursor-pointer w-full h-full
`
export const ItemDetailTitle = tw.div`
    flex font-medium max-lg:w-full max-sm:h-8 max-lg:h-[2.6rem] lg:h-[2.6rem] 
    max-sm:text-sm max-lg:text-base lg:text-base line-clamp-2 max-sm:leading-4 max-lg:leading-5 lg:leading-6
    cursor-pointer
`

export const ItemThumbnailBox = tw.div`
    relative items-center justify-center flex-shrink-0 h-full max-sm:aspect-[5/4] sm:aspect-[3/2]
    overflow-hidden rounded-lg
`

export const ItemDetailDataBox = tw.div`
    flex flex-col w-full h-full ml-2
`

export const CreatedTimeIcon = tw(Create)`
    h-3 mb-[0.175rem]
`
export const UpdatedTimeIcon = tw(Update)`
    h-3 mb-[0.15rem]
`
export const VersionIcon = tw(Versions)`
    h-3 mb-[0.15rem]
`
export const RegistedIcon = tw(AssignmentInd)`
    h-3 mb-[0.02rem]
`

export const ItemControlBox = tw.div`
    max-lg:hidden lg:flex flex-col h-full px-4
` // ${(props) => props.isShow ? "block" : "hidden"}

export const ItemThumnail = tw.div`
    bg-red-300 h-full w-full rounded-xl

`
export const SettingIcon = tw(Settings)`
    group-hover:text-blue-500 mb-1.5
`
export const LinkIcon = tw(Link)`
    group-hover:text-green-500 mb-1.5
`
export const FileContractIcon = tw(FilePaper2)`
    group-hover:text-green-500 mb-1.5
`
export const PerformIcon = tw(DataTrending)`
    group-hover:text-green-500 mb-1.5
`
export const DetailIcon = tw(Detail)`
    group-hover:text-green-500 mb-1.5
`

export const DocumentIcon = tw(DocumentBriefcase)`
    mb-1.5
`
export const ItemControlButton = tw.button`
group flex flex-center p-1.5 w-[34px] h-[34px] max-h-[34px] mb-2
hover:bg-white rounded-lg 
border border-gray-300 
text-gray-600
`
export const SettingBtn = tw(ItemControlButton)`
     hover:border-blue-400 h-full 
     ${(props) => (props.isShow ? "block" : "hidden")}
`

export const SetContractBtn = tw(ItemControlButton)`
     hover:border-blue-400 h-full
     ${(props) => (props.isShow ? "hidden" : "block")}
`

export const LinkBtn = tw(ItemControlButton)`
     hover:border-green-400 h-full
     ${(props) => (props.isShow ? "block" : "hidden")}
`

export const ItemNoticeListBox = tw.div`
    absolute flex flex-col w-full h-full ${() => NOT_SCROLLABLE}
    overflow-y-scroll scroll-transparent
`
export const ItemNoticeRow = tw.div`
    flex items-center px-2 py-1
    text-sm
    border-b max-sm:border-white sm:border-gray-200 max-sm:hover:bg-gray-100 sm:hover:border-gray-400
    cursor-pointer
`
export const ItemNoticeBlankBox = tw.div`
    flex flex-center w-full h-full
`
export const ItemNoticeBlankMessage = tw.span`
    text-gray-700
`

// Item Process
export const ItemProcessBox = tw.div`
    ${(props) => (props.isShow ? "max-lg:hidden lg:grid" : "hidden")}
    grid-cols-1 grid-rows-4 flex-shrink-0 mr-2 w-52 px-3 py-2
    rounded-lg 
    bg-slate-100
`
// ${(props) => (props.isHover ? "bg-blue-100" : "bg-gray-100")}

export const ItemProcessObjectBox = tw.div`
    flex items-center w-full max-sm:text-xs sm:text-sm
    text-slate-700
`
export const ItemProcessTitle = tw.span`
    flex justify-start items-center w-full
    max-lg:font-medium
`
export const ItemProcessValue = tw.span`
    flex justify-end items-center flex-shrink-0
`

export const ItemMarketerBox = tw.div`
    max-lg:hidden lg:flex flex-col items-center w-full px-2
`

export const ItemPaginationBox = tw.div`
    flex w-auto px-2
    bg-white drop-shadow rounded-full
`
