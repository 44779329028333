import React from "react"
import styled from "styled-components"
import tw from "tailwind-styled-components"
import { BoxMaxMin } from "../../layouts/Box"
import { Link } from "react-router-dom"
import {
    FooterContainer,
    FooterImageBox,
    FooterImageList,
    FooterInfoLayout,
    FooterInfoText,
    FooterInfoTextList,
    FooterNavButton,
    MobileNavMarginBox,
} from "./FooterStyle"
import footer01 from "../../static/footer/footer_01.png"
import footer02 from "../../static/footer/footer_02.png"
import footer03 from "../../static/footer/footer_03.png"

export function Footer() {
    return (
        <FooterContainer>
            <BoxMaxMin className="flex max-md:flex-col md:flex-row justify-between w-full h-full">
                <FooterInfoLayout>
                    <div className="flex items-center mb-4">
                        <Link to={`/termandpolicy`} state={{ key: 0 }}>
                            <FooterNavButton>이용안내</FooterNavButton>
                        </Link>
                        <Link to={`/termandpolicy`} state={{ key: 1 }}>
                            <FooterNavButton className="border-r-0">이용약관 및 개인정보 처리방침</FooterNavButton>
                        </Link>
                    </div>
                    <FooterInfoTextList>
                        {/* <FooterInfoText>
                            상호: (주)네스타 | 대표자명: 조은제 | 전화번호: 070-7714-1314 | 이메일: help@nesta.co.kr |
                            사업자등록번호: 230-87-01450
                        </FooterInfoText>
                        <FooterInfoText>
                            주소: 경기도 광명시 오리로 651번길 8,현대테라타워광명 1007호 | 통신판매업신고:
                            2023-경기광명-0760 | 개인정보보호책임자: 조은제
                        </FooterInfoText>
                        <FooterInfoText className="max-md:text-xs md:text-sm text-gray-500">
                            (주)네스타는 통신판매중개자로서 통신판매의 당사자가 아니며 개별 판매자가 제공하는 서비스에
                            대한 이행, 계약사항 등과 관련한 의무와 책임은 거래당사자에게 있습니다.
                        </FooterInfoText>
                        <FooterInfoText>© 주식회사 네스타 All Right Reserved</FooterInfoText> */}
                        <FooterInfoText>
                            상호: 티지알 | 전화번호: 010-5294-3923 | 이메일: jackchan@naver.com |
                            사업자등록번호: 858-52-00825
                        </FooterInfoText>
                        <FooterInfoText>
                            주소: 경기도 광명시 오리로 651번길 8,현대테라타워광명 1007호 | 개인정보보호책임자: 조은제
                        </FooterInfoText>
                        {/* <FooterInfoText className="max-md:text-xs md:text-sm text-gray-500">
                            (주)네스타는 통신판매중개자로서 통신판매의 당사자가 아니며 개별 판매자가 제공하는 서비스에
                            대한 이행, 계약사항 등과 관련한 의무와 책임은 거래당사자에게 있습니다.
                        </FooterInfoText> */}
                        <FooterInfoText>© 티지알 All Right Reserved</FooterInfoText>
                    </FooterInfoTextList>
                </FooterInfoLayout>

                <FooterImageList>
                    <FooterImageBox>
                        <img src={footer01} />
                    </FooterImageBox>
                    <FooterImageBox>
                        <img src={footer02} />
                    </FooterImageBox>
                    <FooterImageBox>
                        <img src={footer03} />
                    </FooterImageBox>
                </FooterImageList>
                <MobileNavMarginBox />
            </BoxMaxMin>
        </FooterContainer>
    )
}
