import React, { useEffect, useState } from "react"
import tw from 'tailwind-styled-components'
import {CircleCheck} from "@styled-icons/fa-solid/CircleCheck"
import {XCircle} from "@styled-icons/boxicons-solid/XCircle"
import {Close} from "@styled-icons/ionicons-solid/Close"
import { FONT_FAMILY } from "../../styles/Globalstyle"
import { Link } from "react-router-dom"

const Layout = tw.div`
absolute right-0
flex flex-col p-2 w-[max-content]
rounded-lg bg-white
drop-shadow-lg
`
const IconContainer = tw.div`
flex flex-center p-1 mr-1 w-10 h-8
`
const IconCheck = tw(CircleCheck)`
text-green-500 h-full py-0.5
`
const IconX = tw(Close)`
text-red-500 h-full
`
const Message = tw.pre`
font-semibold
${(props) => props.isValidated ? "text-green-600" : "text-gray-500"}
`

const Validate = ({isValidated, children}) => {
    return (
        <div className="flex items-center w-full py-1">
            <IconContainer>
                {isValidated ? (<IconCheck />) : (<IconX />)}
            </IconContainer>
            <Message isValidated={isValidated} style={{fontFamily:FONT_FAMILY}}>
                {children}
            </Message>
        </div>
    )
}



export const BrandValidate = ({isActiveBrand, hasSignBrand, top="-5rem", isShow}) => {    
    return (
        <Layout className={`top-[${top}] ${isShow ? "opacity-100 z-20" : "opacity-0 -z-10"}`}>
            <Validate 
                isValidated={isActiveBrand} 
            >
                {isActiveBrand ? "승인된 브랜드입니다." : "현재 승인 대기 중입니다.\n승인 후 사용가능합니다."} 
            </Validate>
            <Validate 
                isValidated={hasSignBrand} 
            >
                {hasSignBrand ? (
                    "서명이 등록되었습니다." 
                ) : (
                    <div className="flex flex-col">
                        <span>
                            서명 등록 후 사용 가능합니다.
                        </span>
                        <span>
                            <Link to="/mypage/myinfo" className="text-blue-500 underline underline-offset-4">내 정보</Link>
                            에서 서명을 등록해 주세요.
                        </span>
                    </div>
                )} 
            </Validate>
        </Layout>
    )
}