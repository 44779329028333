import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { downloadFile, downloadFileZip } from "../../apis/api/files"
import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"
import {
    BoardDetailTitle,
    NoticeDeleteButton,
    BoardDetailBody,
    ReturnButton,
    BoardDetailBottom,
    BoardDetailContainer,
    BoardDetailTitlebottom,
    BoardDetailHead,
    NoticeEditButton,
    NoticeInner,
    ReturnIcon,
    BoardDetailLayout,
    BoardDetailInfoRow,
    BoardDetailInfos,
    BoardDetailInfoBox,
    BoardDetailInfoValue,
    BoardDetailInfoLabel,
    BoardControlButton,
    BoardFileBody,
    BoardFileTitle,
    BoardFileBox,
    BoardFileName,
    BoardFileHead,
    BoardFileZipDownload,
    BoardFileSize,
    BoardImportanceLabel,
} from "./BoardDetailStyle"
import ReactQuillContent from "./ReactQuillContent"
import { BoardImportnaceStatusTypes } from "../../types/boards/BoardTypes"
import CKEditorContent from "./CKEditorContent"

const BoardFile = ({ file }) => {
    const [isLoading, setLoading] = useState(false)
    const handleDownload = () => {
        if (!isLoading) {
            setLoading(true)
            downloadFile(file.fileURL, file.name).then((response) => {
                setLoading(false)
            })
        }
    }
    return (
        <BoardFileBox
            onClick={() => {
                handleDownload()
            }}
        >
            <BoardFileName title={file.name}>{file.name}</BoardFileName>
            <BoardFileSize>{Utils.getFileSizeText(file.size)}</BoardFileSize>
        </BoardFileBox>
    )
}

export default function BoardDetail({
    board,
    isAuth,
    importance,
    isModal = false, // 모달에서 호출하는 경우 최대 높이 제한을한다.
    onUpdate = null,
    onDelete = null,
    readOnly = false,
    isLoading = false,
}) {
    let navigate = useNavigate()

    const [content, setContent] = useState("")
    const [zipFileSize, setZipFileSize] = useState(0)

    useEffect(() => {
        if (!Utils.isStringNullOrEmpty(board.content)) {
            getProcessContent()

            let zfs = 0
            board.files.map((f) => {
                zfs += f.size
            })
            setZipFileSize(zfs)
        }
    }, [board])

    // 내용을 사용자에게 보여줄 수 있도록 데이터를 처리한다.
    const getProcessContent = async () => {
        const contentView = FileUtils.replaceMedieFileURL(board.content)
        setContent(contentView)
        // FileUtils.convertImageTemplateToSource(setContent, board.content)
    }

    const handleUpdate = () => {
        if (onUpdate !== null) {
            onUpdate()
        }
    }

    const handleDelete = () => {
        if (onDelete !== null && confirm("삭제하시겠습니까?")) {
            onDelete()
        }
    }

    const handleZipDownload = () => {
        if (board.files.length === 0) {
            return
        }
        downloadFileZip(board.files, board.title)
    }

    return (
        <BoardDetailContainer>
            <BoardDetailLayout isModal={isModal}>
                <BoardDetailHead>
                    <div className="flex max-sm:flex-col sm:flex-row max-sm:items-start sm:items-center max-sm:pt-2">
                        {isLoading ? (
                            <div className="skeleton w-36 max-md:text-xl md:text-2xl max-md:my-2 md:mb-4 text-semibold">
                                loading....
                            </div>
                        ) : (
                            <>
                                {importance === BoardImportnaceStatusTypes.IMPORTANT && (
                                    <BoardImportanceLabel>중요공지</BoardImportanceLabel>
                                )}
                                <BoardDetailTitle>{board.title}</BoardDetailTitle>
                            </>
                        )}
                    </div>
                    <BoardDetailInfoRow>
                        <BoardDetailInfos>
                            {isLoading ? (
                                <BoardDetailInfoBox>
                                    <div className="skeleton flex items-center mr-1">작성 일자</div>
                                    <div className="skeleton flex items-center w-24">1901-01-01</div>
                                </BoardDetailInfoBox>
                            ) : (
                                <BoardDetailInfoBox>
                                    <BoardDetailInfoLabel>작성 일자</BoardDetailInfoLabel>
                                    <BoardDetailInfoValue>{board.created}</BoardDetailInfoValue>
                                </BoardDetailInfoBox>
                            )}
                            {isLoading ? (
                                <BoardDetailInfoBox>
                                    <div className="skeleton flex items-center mr-1">작성자</div>
                                    <div className="skeleton flex items-center w-24">가나다</div>
                                </BoardDetailInfoBox>
                            ) : (
                                <BoardDetailInfoBox>
                                    <BoardDetailInfoLabel>작성자</BoardDetailInfoLabel>
                                    <BoardDetailInfoValue>{board.user.name}</BoardDetailInfoValue>
                                </BoardDetailInfoBox>
                            )}
                        </BoardDetailInfos>
                        {!readOnly && (
                            <BoardDetailInfos className="max-sm:justify-end max-sm:mt-2">
                                {isLoading ? (
                                    <React.Fragment>
                                        <div className="skeleton flex flex-center px-3 py-0.25 ml-2">가나</div>
                                        <div className="skeleton flex flex-center px-3 py-0.25 ml-2">가나</div>
                                        <div className="skeleton flex flex-center px-3 py-0.25 ml-2">가나</div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <BoardControlButton
                                            onClick={() => {
                                                handleDelete()
                                            }}
                                        >
                                            삭제
                                        </BoardControlButton>
                                        <BoardControlButton
                                            onClick={() => {
                                                handleUpdate()
                                            }}
                                        >
                                            수정
                                        </BoardControlButton>
                                        <BoardControlButton onClick={() => navigate(-1)}>
                                            {/* 뒤로 돌아가기 버튼 위치 옮기거나 아래 주석 해놓은 아이콘 사용 해도 됨 */}
                                            {/* <ReturnIcon/> */}
                                            목록
                                        </BoardControlButton>
                                    </React.Fragment>
                                )}
                            </BoardDetailInfos>
                        )}
                    </BoardDetailInfoRow>
                </BoardDetailHead>
                <BoardDetailBody>
                    {/* 여기에 정산 관련 내용 들어가면 됨 
                        이 div 내부만 스크롤 가능하도록  할 예정 다른 방안 있으면 말해주세요
                    */}
                    {/* <ReactQuillContent content={content} isLoading={isLoading} /> */}
                    <CKEditorContent content={content} isLoading={isLoading} />
                </BoardDetailBody>
                {board.files.length > 0 && (
                    <BoardFileBody>
                        <BoardFileHead>
                            <BoardFileTitle>첨부 파일</BoardFileTitle>
                            <BoardFileZipDownload
                                onClick={() => {
                                    handleZipDownload()
                                }}
                            >
                                전체 다운로드
                                <span className="ml-1 text-sm">{Utils.getFileSizeText(zipFileSize)}</span>
                            </BoardFileZipDownload>
                        </BoardFileHead>
                        {board.files.map((file, idx) => (
                            <BoardFile file={file} key={idx} />
                        ))}
                    </BoardFileBody>
                )}
            </BoardDetailLayout>
        </BoardDetailContainer>
    )
}
