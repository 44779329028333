import React,{useEffect,useState,useRef,useMemo} from "react";
import tw from 'tailwind-styled-components'
import { FilterBox, FilterCheckBox, FilterCheckedIcon, FilterCheckIcon, FilterColumnName, FilterContainer, FilterListLayout, FilterRowList, FilterShortcutResponsiveBox, FilterValueContainer } from "./MyPagePerformFilterStyle";
import { getComboName, getPerformEmptyValue, getStatusName } from '../../../utils/brandItem/BrandItemPerformUtils';
import { DateRangePicker, PerformItemCombo, PerformItemInput } from "../../../components/brandItems/PerformInputs";
import { EditStateTypes } from "../../../types/EditorTypes";
import { PerformColumnFormTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import Utils from "../../../utils/Utils";
import { PerformValueInputTitle } from "./MyPagePerformStyle";
import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterInputCombo, FilterInputDate, FilterInputText } from "../../../components/brandItems/FilterInputs";
import { FilterInputLabel, FilterInputSelectBox } from "../../../components/brandItems/FilterInputsStyle";

const FilterCombo = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [comboID, setComboID] = useState(-1)

    useEffect(()=>{
        if (filterValue !== undefined)
            setComboID(filterValue.comboID)
    }, [filterValue])

    const handleChange = (_comboID) => {
        if (_comboID >= 0) {
            let filter = {}
            filter.comboID = _comboID
            handleAddFilter(filter)
            setComboID(_comboID)

            filterValue.comboID = _comboID
        } else {
            setDeactive()
        }
    }

    
    return (
        <FilterInputCombo
            label={column.name}
            value={comboID}
            setValue={handleChange}
            width={column.width}
        >
            <option key={-1} value={null}>-</option>
            {column.combos.map((combo, key) => (
                <option key={key} value={combo.id}>{combo.name}</option>
            ))}
        </FilterInputCombo>
    )
}

const FilterText = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [value, setValue] = useState("")
    
    useEffect(()=>{
        if (filterValue !== undefined)
        setValue(filterValue.text)
    }, [filterValue])

    useEffect(()=>{
        if (value === "") {
            setDeactive()
        }
    }, [value])

    const handleChange = () => {
        filterValue.text = value
        if (value !== "") {
            let filter = {}
            filter.text = value
            handleAddFilter(filter)
        }
    }

    return (
        <FilterInputText
            label={column.name}
            value={value}
            setValue={setValue}
            submit={()=>{handleChange()}}
            onBlur={()=>{handleChange()}}
            width={column.width}
        />
    )
}

const FilterStatus = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [statusID, setStatusID] = useState(0)

    useEffect(()=>{
        if (filterValue !== undefined)
            setStatusID(filterValue.statusID)
    }, [filterValue])

    const handleChange = (_statusID) => {
        if (_statusID >= 0) {
            let filter = {}
            filter.statusID = _statusID
            handleAddFilter(filter)
            setStatusID(_statusID)

            filterValue.statusID = _statusID
        } else {
            setDeactive()
        }
    }

    return (
        <FilterInputCombo
            label={column.name}
            value={statusID}
            setValue={handleChange}
            width={column.width}
        >
            <option key={-1} value={null}>-</option>
            {column.statuses.map((status, key) => (
                <option key={key} value={status.id}>{status.name}</option>
            ))}
        </FilterInputCombo>
    )
}

const FilterDate = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [value, setValue] = useState("")

    useEffect(()=>{
        if (filterValue !== "")
            setValue(filterValue.text)
    }, [filterValue])

    useEffect(()=>{
        if (value !== "" && value !== undefined) {
            handleChange()
        }
    }, [value])

    const handleChange = () => {
        let filter = {}
        filter.text = value
        handleAddFilter(filter)
        filterValue.datetime = value
    }

    return (
        <FilterInputDate
            label={column.name}
            value={value}
            setValue={setValue}
            width={column.width}
        >
            <option key={-1} value={null}>-</option>
            {column.statuses.map((status, key) => (
                <option key={key} value={status.id}>{status.name}</option>
            ))}
        </FilterInputDate>
    )
}   

const FilterRow = ({filterValue, filterDic, addFilter, removeFilter}) => {
    const column = filterValue.column
    const [isActive, setActive] = useState(false)
    
    if (column === undefined) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    useEffect(()=>{
        if (filterDic.contains(column.id)) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [filterDic])

    useEffect(()=>{
        if (!isActive) {
            removeFilter(column.id)
        }
    }, [isActive])

    const handleAddFilter = (fv) => {
        addFilter(column.id, fv)
    }

    const setDeactive = () => {
        setActive(false)
    }

    return (
        <FilterShortcutResponsiveBox className="parent-div">
            {column.formType === PerformColumnFormTypes.COMBO && (
                <FilterCombo column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
            )}
            {column.formType === PerformColumnFormTypes.TEXT && (
                <FilterText column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
            )}
            {column.formType === PerformColumnFormTypes.STATUS && (
                <FilterStatus column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
            )}
            {/* {column.formType === PerformColumnFormTypes.DATE && (
                <FilterDate column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
            )} */}
        </FilterShortcutResponsiveBox>
    )
}

export default function MyPagePerformFilterShortcut({columns, filterDic, addFilter, removeFilter}){

    const [filterValues, setFilterValues] = useState([])

    useEffect(()=>{
        if (columns !== undefined && columns.length > 0) {
            let filterValues = []
            columns.map((column) => {
                let filterValue
                if (filterDic.contains(column.id)) {
                    filterValue = filterDic.getValue(column.id)
                    filterValue.column = column
                } else {
                    filterValue = getPerformEmptyValue(column)
                    filterValue.column = column
                }
                filterValues.push(filterValue)
            })
            setFilterValues(filterValues)
        }
    }, [columns])
    
    return(
        <FilterListLayout>
            {filterValues.map((filterValue, idx) => (
                <FilterRow 
                    filterValue={filterValue} 
                    filterDic={filterDic}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    key={idx} 
                />
            ))}
        </FilterListLayout>
    )
}