import React, { useState, useEffect } from "react"
import WebEditor from "../../../../components/WebEditor"
import tw from "tailwind-styled-components"
import {
    updateBrandItemForm,
    getBrandItemForm,
    updateSystemAgreementFrom,
    getSystemAgreementFrom,
} from "../../../../apis/api/commons/index"
import { Agreement, Usage } from "../../../user/agreementData"
import { AgreementForm } from "../../../../types/Agreement"
import { getItemPersonalAgreementPaper, updatePersonalAgreementPaper } from "../../../../apis/api/boardItems"
import CKEditor from "../../../../components/ckeditor/CKEditor"
import { MyPageInputModalLayout } from "../../MyPageMainStyle"
import {
    MarketerMatchingMenuBox,
    MarketerMatchingSettingConfirmButton,
    MarketerMatchingSettingLayout,
    MarketerMatchingSettingSubTitle,
    MarketerMatchingSettingTitleBox,
} from "./MyPageItemBrandStyle"
import { BrandItemInput } from "../../../../components/brandItems/Inputs"
import Utils from "../../../../utils/Utils"

const StaffItemsContractWriterLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[95vw] max-lg:w-[640px] lg:w-[1000px]
    max-sm:h-[90vh] sm:h-[60vh] bg-slate-50 p-6
`

const StaffItemsContractWriterTitle = tw.div`
    w-full h-14 flex items-center
`

const MenuTypes = {
    BRAND_INFO: "BRAND_INFO",
    ITEM_INFO: "ITEM_INFO",
    MARKETER_INFO: "MARKETER_INFO",
}

const MENUS = [
    {
        menuType: MenuTypes.BRAND_INFO,
        title: "1. 브랜드 정보",
        description: "브랜드 정보에 대한 설명",
        caution: "모든 아이템에 공통으로 적용됩니다.",
    },
    {
        menuType: MenuTypes.ITEM_INFO,
        title: "2. 아이템 정보",
        description: "아이템 정보에 대한 설명",
        caution: "아이템에만 적용됩니다.",
    },
    {
        menuType: MenuTypes.MARKETER_INFO,
        title: "3. 마케터 정보",
        description: "마케터 정보에 대한 설명",
        caution: "",
    },
]

const ItemMarketingMatchingSetting = ({ itemID, isShow, setShow }) => {
    const [menuType, setMenuType] = useState(MenuTypes.BRAND_INFO)

    return (
        <StaffItemsContractWriterLayout>
            <StaffItemsContractWriterTitle className="text-xl font-medium">
                마케터 매칭 설정
            </StaffItemsContractWriterTitle>
            {/* 설명란 */}
            <div className="flex flex-col w-full py-2">
                <p className="text-slate-700">
                    마케터 매칭 관련한 설명란입니다.마케터 매칭 관련한 설명란입니다.마케터 매칭 관련한 설명란입니다.
                    마케터 매칭 관련한 설명란입니다.마케터 매칭 관련한 설명란입니다.마케터 매칭 관련한 설명란입니다.
                </p>
            </div>
            {/* 설정 부분 */}
            <div className="flex w-full h-full max-h-full lg:space-x-2 pt-4 border-t">
                {/* [데스크탑] 단계 선택 */}
                <div className="max-lg:hidden lg:flex flex-col w-72 space-y-2">
                    {MENUS.map((menu, index) => (
                        <MarketerMatchingMenuBox
                            key={index}
                            isSelected={menuType === menu.menuType}
                            onClick={() => {
                                setMenuType(menu.menuType)
                            }}
                        >
                            <h3>{menu.title}</h3>
                            <p>{menu.description}</p>
                            <span>{menu.caution}</span>
                        </MarketerMatchingMenuBox>
                    ))}
                </div>
                {/* 설정 화면 */}
                <div className="relative flex flex-col w-full h-full lg:px-2">
                    <div className="absolute flex flex-col w-full h-full lg:px-2 max-lg:space-y-4 overflow-y-scroll scroll-transparent">
                        <MobileSettingTitle index={0} />
                        <BrandInfoSetting isShow={menuType === MenuTypes.BRAND_INFO} />
                        <MobileSettingTitle index={1} />
                        <ItemInfoSetting isShow={menuType === MenuTypes.ITEM_INFO} />
                        <MobileSettingTitle index={2} />
                        <MarketerInfoSetting isShow={menuType === MenuTypes.MARKETER_INFO} />
                    </div>
                </div>
            </div>
            {/* 푸터 */}
            <div className="flex justify-end items-center w-full max-lg:h-10 lg:h-12 mt-2">
                <MarketerMatchingSettingConfirmButton
                    onClick={() => {
                        alert("개발예정입니다.")
                    }}
                >
                    저장
                </MarketerMatchingSettingConfirmButton>
            </div>
        </StaffItemsContractWriterLayout>
    )
}

export default ItemMarketingMatchingSetting

const MobileSettingTitle = ({ index }) => {
    return (
        <MarketerMatchingSettingTitleBox>
            <div className="flex justify-between items-center w-full">
                <span className="font-semibold text-xl">{MENUS[index].title}</span>
                <span className="text-slate-600">{MENUS[index].description}</span>
            </div>
            {!Utils.isStringNullOrEmpty(MENUS[index].caution) && (
                <span className="text-sm text-nightblue-500">{MENUS[index].caution}</span>
            )}
        </MarketerMatchingSettingTitleBox>
    )
}

const BrandInfoSetting = ({ isShow }) => {
    return (
        <MarketerMatchingSettingLayout isShow={isShow}>
            <MarketerMatchingSettingSubTitle>기업현황 정보</MarketerMatchingSettingSubTitle>
            <BrandItemInput
                type="text"
                label={"회사명"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"주소"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"설립일"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"기업규모"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"기업형태"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"매출액"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"영업이익"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"당기손익"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"신용등급"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"사원수"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"상세업종"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <MarketerMatchingSettingSubTitle>마케팅 니즈지수</MarketerMatchingSettingSubTitle>
            <BrandItemInput
                type="text"
                label={"기업연차 대비매출액 (1년차수평균)"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"매출액 대비 영업이익"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"신용등급"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"사원1인당 매출액"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"사원1인당 영업이익"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
        </MarketerMatchingSettingLayout>
    )
}

const ItemInfoSetting = ({ isShow }) => {
    return (
        <MarketerMatchingSettingLayout isShow={isShow}>
            <MarketerMatchingSettingSubTitle>아이템 정보</MarketerMatchingSettingSubTitle>
            <BrandItemInput
                type="text"
                label={"상품유형"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"상품영업단가"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"영업가능지역"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"아이템키워드"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
        </MarketerMatchingSettingLayout>
    )
}

const MarketerInfoSetting = ({ isShow }) => {
    return (
        <MarketerMatchingSettingLayout isShow={isShow}>
            <MarketerMatchingSettingSubTitle>희망 마케터 유형</MarketerMatchingSettingSubTitle>
            <BrandItemInput
                type="text"
                label={"성별"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"나이"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"활동가능한 지역"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"참여형식"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"경력"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
            <BrandItemInput
                type="text"
                label={"실적"}
                // data={title}
                // setData={setTitle}
                disabled={false}
                isEdited={true}
                widthFull={true}
            />
        </MarketerMatchingSettingLayout>
    )
}
