export default () => {
    // 사용자의 페이지 이탈을 막음.
    const listener = (event) => {
        event.preventDefault()
        event.returnValue = ""
    }
    // 뒤로가기를 막음
    const preventGoBack = () => {
        history.pushState(null, "", location.href)
        if (confirm("페이지를 떠나시겠습니까?")) {
            disablePrevent()
            history.back()
            history.back()
        }
    }
    const preventGoBackSafari = () => {
        var historyTraversal =
            event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2)

        if (historyTraversal) {
            // 페이지 뒤로가기 시 confirm 대화 상자 표시
            var confirmMessage = "페이지를 떠나시겠습니까?"
            if (confirm(confirmMessage)) {
                // 사용자가 확인을 누를 경우 처리할 로직
            } else {
                // 사용자가 취소를 누를 경우 처리할 로직
                history.pushState(null, null, location.href)
                event.preventDefault()
            }
        }
    }
    // 페이지 이탈 방지 활성화
    const enablePrevent = () => {
        window.addEventListener("beforeunload", listener)
        // history.pushState(null, "", location.href)
        // window.addEventListener("popstate", preventGoBack)
        window.addEventListener("pagehide", preventGoBackSafari)
    }
    // 이탈 방지 비활성화
    const disablePrevent = () => {
        window.removeEventListener("beforeunload", listener)
        // window.removeEventListener("popstate", preventGoBack)
        window.removeEventListener("pagehide", preventGoBackSafari)
    }
    return { enablePrevent, disablePrevent }
}
