import Paginator from "../Paginator"
import CouponApplication from "./CouponApplication"

export default class extends Paginator {
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.items = []
        response["results"].map((result) => {
            let item = new CouponApplication()
            item.convertByResponse(result)
            this.items.push(item)
        })
    }
}
