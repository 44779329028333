import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { PerformDateValueBox, PerformOptionValueBox } from "./MyPagePerformStyle"

import { OptionControlBox, OptionControlResetButton, OptionItemList } from "./PerformValueOptionStyle"
import { OptionItem } from "./PerformValueOptionCell"
import { Dictionary } from "../../../types/Dictionary"
import { QueryTypes } from "../../../types/EditorTypes"
import { PerformDateInputBox, PerformDateTimeMemoInput, PerformDateTimeSubTitle } from "./PerformValueDateStyle"
import { DateTimeInput } from "../../../components/inputs/DateTimeInput"
import dayjs from "dayjs"
import Utils from "../../../utils/Utils"

export const PerformValueDateUpdate = ({ perform, performValue, readOnly }) => {
    const column = performValue.column

    const [dateTimeString, setDateTimeString] = useState("")
    const [timeStamp, setTimeStamp] = useState(null) // 저장된 혹은 저장한 시간 값
    const [timeStampValue, setTimeStampValue] = useState(null) // 수정 중인 시간 값

    const [editYear, setEditYear] = useState(false)
    const [editMonth, setEditMonth] = useState(false)
    const [editDay, setEditDay] = useState(false)
    const [editHour, setEditHour] = useState(false)
    const [editMinute, setEditMinute] = useState(false)
    const [editSecond, setEditSecond] = useState(false)

    const [memo, setMemo] = useState("")

    useEffect(() => {
        setTimeStamp(performValue.timeStamp)
        setMemo(performValue.memo)

        const columnDate = column.dates.find((s) => s.columnID === column.id)
        const dateFormat = String(columnDate.dateFormat)

        // 날짜 포맷에 따른 수정 여부
        setEditYear(dateFormat.includes("YYYY"))
        setEditMonth(dateFormat.includes("MM"))
        setEditDay(dateFormat.includes("DD"))
        setEditHour(dateFormat.includes("HH"))
        setEditMinute(dateFormat.includes("mm"))
        setEditSecond(dateFormat.includes("ss"))

        if (performValue.timeStamp !== null) {
            setDateTimeString(dayjs(performValue.timeStamp).format(dateFormat))
        }
    }, [performValue])

    useEffect(() => {
        performValue.timeStamp = timeStampValue
    }, [timeStampValue])

    useEffect(() => {
        performValue.memo = memo
    }, [memo])

    return (
        <PerformDateValueBox>
            <PerformDateInputBox>
                {readOnly ? (
                    <span>{Utils.isStringNullOrEmpty(dateTimeString) ? "-" : dateTimeString}</span>
                ) : (
                    <DateTimeInput
                        timeStamp={timeStamp}
                        setTimeStamp={setTimeStampValue}
                        editYear={editYear}
                        editMonth={editMonth}
                        editDay={editDay}
                        editHour={editHour}
                        editMinute={editMinute}
                        editSecond={editSecond}
                    />
                )}
            </PerformDateInputBox>
            <PerformDateTimeSubTitle>메모</PerformDateTimeSubTitle>
            <PerformDateTimeMemoInput
                value={memo}
                onChange={(e) => {
                    setMemo(e.target.value)
                }}
                disabled={readOnly}
            />
        </PerformDateValueBox>
    )
}
