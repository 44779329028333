import React, { useEffect, useState } from 'react'
import { getStoreSignupAgreementForm, getSystemAgreementFrom } from '../../apis/api/commons';
import { AgreementForm, AgreementLinkType } from '../../types/Agreement';
import { Check } from '@styled-icons/boxicons-regular/Check'
import tw from 'tailwind-styled-components'
import { UserInput, UserSMSAuthRequestButton } from '../../components/user/Inputs';
import { EditStateTypes } from '../../types/EditorTypes';
import { EmailValidate } from '../../components/user/EmailValidate';
import { MobileModal } from './../../components/MobileModal';
import { ModalContainer } from '../../components/ModalContainer';
import { SignCanvas } from '../../components/brandItems/SignCanvas';
import { useRef } from 'react';
import { AgreementCanvas } from './AgreementCanvas';
import { addStoreData } from '../../apis/api/users';
import { useParams } from 'react-router-dom';
import CelebrateAnimation from './CelebrateAnimation';

const CheckIcon = tw(Check)`
    h-full mb-2
`
const AgreementSubmit = tw.div`
    flex flex-col 
    w-screen 
`
const AgreementNavigation = tw.nav`
    flex justify-center items-center
    w-full h-14 
    bg-white border-b 
`
const AgreementTitle = tw.span`
    text-lg font-semibold text-nightblue-600
`
const AgreementContainer = tw.div`
    flex flex-col items-center justify-between 
    w-full h-full 
    px-6 py-6
`
const AgreementThirdParyGrid = tw.div`
    grid grid-flow-row grid-rows-1 gap-4
    max-w-[500px] w-full 
`
const ThirdPartyTitle = tw.span`
    font-semibold text-lg
`
const ThirdPartyAgreement = tw.div`
    max-w-[500px] h-128 mt-4 
    border rounded-md 
    overflow-scroll scroll-transparent
`
const ThirdPartyAgreementButtonContainer = tw.div`
    flex items-center px-4 py-3
    w-full h-14  
    border rounded-md  duration-200
    ${(props) => props.state ? "border-nightblue-400" : ""}
`
const ThirdPartyAgreementButtonText = tw.span`
    font-semibold
    ${(props) => props.state ? "text-nightblue-400" : "text-gray-300"}
`
const ThirdPartyAgreementCheckContainer = tw.div`
    flex justify-center items-center p-1.25 mr-2
    h-full aspect-square 
    border-2 rounded-full duration-200
    ${(props) => props.state ? "border-nightblue-400" : ""}
`
const ThirdPartyAgreementCheck = tw.div`
    h-full aspect-square duration-200
    rounded-full 
    ${(props) => props.state ? "bg-nightblue-400" : "hidden`"}
`
const AgreementButtonContainer = tw.footer`
    flex flex-col mt-10
    max-w-[500px] w-full 
`
const AgreementButton = tw.button`
    flex justify-center items-center
    w-full h-12 
    rounded-md ${(props) => props.state ? "bg-nightblue-600" : "bg-gray-300"} text-lg 
    text-white 
`

export default function AgreementSignupMarketer() {

    const [thirdData, setThirdData] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')

    const [emailStatus, setEmailStatus] = useState(EditStateTypes.WAIT)
    const [nameStatus, setNameStatus] = useState(false)
    const [telStatus, setTelStatus] = useState(EditStateTypes.WAIT)
    const [readyToAgree, setReadyToAgree] = useState(false)
    const [agreementState, setAgreementState] = useState(false)

    const [isFinished, setIsFinished] = useState(false)

    const [signImage, setSignImage] = useState(null)
    const { item_marketer_code } = useParams();

    const canvasRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const setData = async () => {
        const data = await getStoreSignupAgreementForm(item_marketer_code);
        setThirdData(data.content)
    };


    useEffect(() => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (email !== '') {
            setEmailStatus(regex.test(email) ? EditStateTypes.DONE : EditStateTypes.FAILED)
        } else {
            setEmailStatus(EditStateTypes.WAIT)
        }
    }, [email])

    useEffect(() => {
        if (name === '') {
            setNameStatus(false)
        } else {
            setNameStatus(true)
        }
    }, [name])

    useEffect(() => {
        const regex = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/
        if (tel !== '') {
            setTelStatus(regex.test(tel) ? EditStateTypes.DONE : EditStateTypes.FAILED)
        } else {
            setTelStatus(EditStateTypes.WAIT)
        }
    }, [tel])

    useEffect(() => {
        if (nameStatus && telStatus === EditStateTypes.DONE && agreementState) {
            setReadyToAgree(true)
        } else {
            setReadyToAgree(false)
        }
    })

    useEffect(() => {
        setData()
    }, [])

    const handleOnClick = () => {
        setAgreementState(!agreementState)
    }

    const handleStoreAdd = async () => {
        const imageBase54 = await handleSubmit()
        const data = await addStoreData(name, tel, email, AgreementLinkType.SHOWLINK, imageBase54, item_marketer_code);
        if (data.Succeed === true) {
            setIsFinished(true)
        }
        else {
            // alert(data.errors.non_field_errors[0])
            alert('이미 존재하는 전화번호 입니다.')
        }
    }

    const handleSubmit = () => {
        // 원본 Canvas 요소 및 Context 생성
        const originalCanvas = canvasRef.current.getCanvas()
        const originalContext = originalCanvas.getContext('2d');

        // 새로운 Canvas 요소 및 Context 생성
        const scaledCanvas = document.createElement('canvas');
        const scaledContext = scaledCanvas.getContext('2d');

        // 새로운 Canvas의 크기를 설정하고 scale 적용
        const scale = 500 / 320
        scaledCanvas.width = originalCanvas.width * scale;
        scaledCanvas.height = originalCanvas.height * scale;

        // scale이 적용된 Context를 사용하여 원본 Canvas의 내용을 그려줌
        scaledContext.scale(scale, scale);
        scaledContext.drawImage(originalCanvas, 0, 0);

        // 이미지 데이터를 다운로드하거나 저장
        const image = scaledCanvas.toDataURL('image/png')
        return (image)
    }


    return (
        <AgreementSubmit>
            <AgreementNavigation>
                <AgreementTitle>
                    개인 정보 동의
                </AgreementTitle>
            </AgreementNavigation>

            <AgreementContainer>
                {!isFinished &&
                    <>
                        <AgreementThirdParyGrid>
                            <div className='relative'>
                                <UserInput placeholder={'이름'} type={'text'} data={name} setData={setName} editStateType={nameStatus ? EditStateTypes.DONE : EditStateTypes.WAIT} />
                            </div>
                            <div className='relative'>
                                <UserInput placeholder={'전화번호'} isTel={true} type={'tel'} data={tel} setData={setTel} editStateType={telStatus} />
                                <div className={`${telStatus === EditStateTypes.FAILED ? "block" : "hidden"} right-0 absolute px-4 py-1.5 bg-white border rounded-md z-30`}>
                                    <span className={`text-sm text-red-400`}>전화번호를 확인 해주시기 바랍니다.</span>
                                </div>
                            </div>
                            {/* <div className='relative'>
                                <UserInput placeholder={'이메일'} type={'email'} data={email} setData={setEmail} editStateType={emailStatus} />
                                <div className={`${emailStatus === EditStateTypes.FAILED ? "block" : "hidden"} right-0 absolute px-4 py-1.5 bg-white border rounded-md z-30`}>
                                    <span className={`text-sm text-red-400`}>이메일 형식을 확인 해주시기 바랍니다.</span>
                                </div>
                            </div> */}
                        </AgreementThirdParyGrid>

                        <div className='w-full max-w-[500px] mt-7 mb-2'>
                            <ThirdPartyAgreementButtonContainer state={agreementState}>
                                <div className='flex-1 h-full items-center flex' onClick={() => { handleOnClick() }}>
                                    <ThirdPartyAgreementCheckContainer state={agreementState}>
                                        <ThirdPartyAgreementCheck state={agreementState} />
                                    </ThirdPartyAgreementCheckContainer>

                                    <ThirdPartyAgreementButtonText state={agreementState}>개인정보 수집․제공 동의</ThirdPartyAgreementButtonText>
                                </div>
                                <button className='h-full px-2 font-semibold' onClick={() => { setIsOpen(true) }}>
                                    더보기
                                </button>
                            </ThirdPartyAgreementButtonContainer>
                        </div>

                        <div className='w-full max-w-[500px] flex-1 my-6'>
                            <AgreementCanvas setImage={setSignImage} ref={canvasRef} isStore={true} />
                        </div>

                        <div className='w-full max-w-[500px] mb-6'>
                                <AgreementButton state={readyToAgree} disabled={!readyToAgree} onClick={() => { handleStoreAdd() }}>
                                    완료
                                </AgreementButton>
                        </div>
                        
                        <div className='max-lg:hidden lg:block'>
                            <ModalContainer show={isOpen} setShow={setIsOpen}>
                                <div className='w-full h-full bg-white p-8 rounded-md'>
                                    <ThirdPartyAgreement>
                                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: thirdData }} />
                                    </ThirdPartyAgreement>
                                </div>
                            </ModalContainer>
                        </div>
                        <div className='max-lg:block lg:hidden'>
                            <MobileModal isOpen={isOpen} setIsOpen={setIsOpen} canBigger={false} closeBySwip={false} defaultHeight={100}>
                                <div className='w-full h-full p-4'>
                                    <ThirdPartyAgreement>
                                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: thirdData }} />
                                    </ThirdPartyAgreement>
                                </div>
                            </MobileModal>
                        </div>
                    </>
                }
                {isFinished &&
                    <>
                        <div className='w-full h-[70vh] max-w-[500px] flex flex-col justify-center items-center'>
                            <div className='bg-lime-500 h-20 aspect-square border rounded-full mb-6 animate-bounce duration-300 drop-shadow-lg'>
                                <CheckIcon className='text-white' />
                            </div>
                            <div className='h-46 font-semibold'>
                                <span className='text-xl'>감사합니다!</span>
                            </div>
                            <CelebrateAnimation />
                        </div>
                    </>
                }
            </AgreementContainer>
        </AgreementSubmit>
    )
}
