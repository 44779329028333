import React from "react"
import styled from "styled-components"
import tw from "tailwind-styled-components"
import { Link } from "react-router-dom"
import { EditStateTypes } from "../../../types/EditorTypes"
import { KakaoTalk } from "@styled-icons/remix-fill/KakaoTalk"

export const FormGroup = styled.div`
    position: relative;
    padding: 15px 0 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    width: 100%;
    /* @media screen and (max-width: 800px) {
        width: 80%;
    } */
    height: 3.75rem;
`

export const FormInput = styled.input`
    position: absolute;
    font-family: inherit;
    width: ${(props) => (props.passwidth ? props.passwidth : "100%")};
    border: 0;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    border-color: ${(props) => props.editStateType === EditStateTypes.WAIT && "gray"}
        ${(props) => props.editStateType === EditStateTypes.FAILED && "#ef4444"}
        ${(props) => props.editStateType === EditStateTypes.DONE && "#22c55e"};
    outline: 0;
    font-size: 1rem;
    color: ${(props) => (props.disabled ? "gray" : "black")};
    padding: 0.5rem 0.5rem;
    background: transparent;
    transition: border-color 0.3s, border-width 0.3s ease-in-out;

    &::placeholder {
        color: transparent;
        user-select: none;
    }

    &:placeholder-shown ~ label {
        font-size: 1rem;
        cursor: text;
        top: 20px;
        padding: 0rem 0.25rem;
        color: rgb(156, 163, 175); // text-gray-400
    }

    ~ label {
        position: absolute;
        top: -2px;
        padding: 0rem 0.25rem;
        display: block;
        transition: 0.2s;
        font-size: 0.9rem;
        pointer-events: none;
        color: rgb(107, 114, 128); // text-gray-500
    }
    &:focus {
        ~ label {
            position: absolute;
            pointer-events: none;
            top: -2px;
            display: block;
            transition: 0.2s;
            font-size: 0.95rem;
            color: #2073bc;
            font-weight: 500;
        }
        padding-bottom: 6px;
        font-weight: 500;
        border-width: 3px;
        border-color: #2073bc;
        border-image-slice: 1;
    }
`

export const LoginLayout = tw.div`
    flex h-[32rem] bg-white
    rounded-lg shadow-lg
    overflow-hidden
`

const BaseButton = tw.button`w-full h-9 my-2 text-base rounded-md duration-300`

export const LoginContainer = tw.div`
flex flex-col justify-center items-center w-[22rem] h-full
`

export const LoginSplitterBox = tw.div`
    flex items-center w-full
`
export const LoginSplitterTitle = tw.div`
    text-slate-500 flex-shrink-0 mr-2
`
export const LoginSplitterLine = tw.div`
    w-full
    border-b border-slate-400
`
export const LoginListButtonList = tw.div`
    flex flex-col w-full space-y-4 px-2 py-4
`
export const LoginListButton = tw.button`
    flex justify-center items-center
    w-full h-9
    rounded-full
    bg-nightblue-200 hover:shadow-lg shadow-nightblue-300/100 duration-300
    text-nightblue-700
`
export const LoginListKakaoButton = tw(LoginListButton)`
    relative
    flex flex-center
    bg-yellow-400 hover:shadow-lg shadow-yellow-500/100 duration-300
    text-amber-900
`

export const LoginTypeBox = tw.div`
    flex items-center w-full mb-2
`
export const LoginTypeButton = tw.div`
    flex flex-center w-full h-8 
    rounded-full 
    ${(props) =>
        props.$is_active
            ? "bg-nightblue-500 text-white"
            : "bg-white hover:bg-slate-100 text-nightblue-600 cursor-pointer"}
`
export const LoginButton = tw(BaseButton)`
    relative
    bg-nightblue-400 hover:bg-nightblue-500 text-white
`

export const KakaoLoginButton = tw(BaseButton)`
    relative flex justify-center items-center
    bg-yellow-400 hover:bg-yellow-500 text-amber-900
`
export const LoginRecentBox = tw.span`
    absolute -top-2 -left-2
    px-1.5 py-0.5
    rounded-full bg-nightblue-100
    text-nightblue-800 text-xs font-light
    before:content-['최근'] before:mr-1 after:content-['로그인']
`

export const KakaoLogoContainer = tw.div`
    absolute py-2 px-10 left-0 top-0 h-full
`

export const KakaoLogo = tw(KakaoTalk)`
    h-full
`

export const LoginImage = tw.img`
    w-full h-full object-cover rounded-md
`

export const LoginRegistSpan = tw.span`
    mr-4
    font-light text-slate-500 text-sm
`
export const SignupButton = tw(BaseButton)`
    flex justify-center items-center
    bg-slate-100 text-slate-700
    font-medium text-base px-4 text-nightblue-500
    rounded-xl
    drop-shadow-md hover:drop-shadow-xl
`

export const LoginImageContainer = tw.div`
    max-sm:hidden sm:flex
    h-full aspect-[12/16] items-center justify-center 
`

export const LoginCol = tw.div`
    flex flex-col justify-evenly items-center max-sm:w-72 sm:w-64 h-full
`
export const LoginFindPasswordCol = tw.div`
    flex flex-col items-center w-72 h-full
`
export const LoginInputCol = tw.div`
    flex flex-col items-center w-full h-full
`
export const LoginTitle = tw.span`
    font-semibold text-2xl mt-10 mb-6
`
export const LoginOptionCol = tw.div`
    flex justify-between items-start w-full
`
export const LoginOptionItem = tw.div`
    flex h-4 justify-start items-center
    cursor-pointer
`
export const LoginOptionSpan = tw.span`
    ml-2 
    text-sm text-gray-500
`
export const LoginFindPassword = tw.button`
    text-gray-400 hover:text-gray-500 text-xs p-1 text-right
`
export const LoginRegistBox = tw.div`
    flex justify-center w-full pt-2 pb-4
    border-t
`
export const LoginRegistCol = tw.div`
    flex flex-row justify-between items-center max-sm:w-72 sm:w-60 h-full
`

export const FindPasswordControlBox = tw.div`
    flex justify-between items-center w-full px-1 py-4
`
export const FindPasswordGotoLoginButton = tw.button`
    text-gray-500 pr-2 py-1
`
const FindPasswordButton = tw.button`
flex flex-center px-6 py-1
rounded-md
`
export const FindPasswordUserCheckButton = tw(FindPasswordButton)`
    text-white bg-slate-400
`
export const FindPasswordSetPasswordButton = tw(FindPasswordButton)`
    text-white bg-nightblue-600
`
