import React, { useEffect, useRef, useState } from "react"
import tw from 'tailwind-styled-components'
import {CircleCheck} from "@styled-icons/fa-solid/CircleCheck"
import {XCircle} from "@styled-icons/boxicons-solid/XCircle"
import {Close} from "@styled-icons/ionicons-solid/Close"
import { ProgressBar } from "../loading/ProgressBar"
import { REGEX_EMAIL } from '../../constants/ConstRegex';

const Layout = tw.div`
absolute right-0 z-10
flex flex-col w-56 h-24 p-2
rounded-lg bg-white
drop-shadow-lg
`
const IconContainer = tw.div`
flex flex-center p-1 mr-1 w-10 h-8
`
const IconCheck = tw(CircleCheck)`
text-green-500 h-full py-0.5
`
const IconX = tw(Close)`
text-red-500 h-full
`
const Message = tw.span`
font-semibold px-2
${(props) => props.isValidated ? "text-green-600" : "text-gray-500"}
`

const Validate = ({email, isValidated, setValidate, message, regex}) => {
    useEffect(()=>{
        setValidate(regex.test(email))
    }, [email])

    return (
        <div className="flex items-center w-full py-1">
            <IconContainer>
                {isValidated ? (<IconCheck />) : (<IconX />)}
            </IconContainer>
            <Message isValidated={isValidated}>
                {message}
            </Message>
        </div>
    )
}

const Duplicate = ({isDuplicated, message}) => {
    return (
        <div className="flex items-center w-full py-1">
            <IconContainer>
                {!isDuplicated ? (<IconCheck />) : (<IconX />)}
            </IconContainer>
            <Message isValidated={!isDuplicated}>
                {message}
            </Message>
        </div>
    )
}

export const EmailValidate = ({email, setValidate, isDuplicated, duplicateCheckTimer=false, top="-5rem", isShow}) => {
    const [valSpecial, setValSpecial] = useState(false)

    useEffect(()=>{
        if (valSpecial) {
            setValidate(true)
        } else {
            setValidate(false)
        }
    }, [valSpecial])
    
    return (
        <Layout className={`top-[${top}] ${isShow ? "opacity-100 z-50" : "opacity-0 z-0"} w-auto h-auto`}>
            <Validate 
                email={email} 
                isValidated={valSpecial} 
                setValidate={setValSpecial} 
                message="유효한 이메일 형식 입니다."
                regex={REGEX_EMAIL}
            />
            <div className={`${duplicateCheckTimer && "animate-pulse-fast"}`}>
                <Duplicate 
                    isDuplicated={isDuplicated} 
                    message={isDuplicated ? "존재하는 이메일입니다." : "중복 확인 완료되었습니다."}
                />
            </div>
        </Layout>
    )
}