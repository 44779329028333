import React, { useState, useEffect, useRef } from "react"
import { BodyBox, BodyInnerBox, BoxMaxMin } from "../../layouts/Box"
import { SlideOutsideCon } from "./BannerSlide"
import { CARD_CATEGORY_DATA_LIST } from "../../constants/ConstCard"
import { getAdItemListHome, getBrandItemList } from "../../apis/api/boardItems"
import { getAdvertisementsHome } from "../../apis/api/commons"
import BrandItemList from "../../types/brandItems/BrandItemList"
import { ItemCardType } from "../../types/DataEnum"
import BrandCategoryCard from "../../components/brandItems/BrandCategoryCard"
import BrandItemCard from "../../components/brandItems/BrandItemCard"
import { ChevronRight } from "@styled-icons/boxicons-solid/ChevronRight"
import tw from "tailwind-styled-components"
import { Link, useNavigate } from "react-router-dom"
// import {Footer} from '../../components/navigation/Footer'
import { ItemOrders } from "../../constants/ConstItem"
import BrandItem from "../../types/brandItems/BrandItem"
import {
    HomeAdvertiseSection,
    HomeAdvertiseSectionItem,
    HomeCategoryBox,
    HomeCategoryList,
    HomeCategoryListContainer,
    HomeContainer,
    HomeContentsContainer,
    HomeFilterAddressBox,
    HomeItemSearchBox,
    HomeItemSearchButton,
    HomeItemSearchIcon,
    HomeItemSearchInput,
    HomeItemsList,
    HomeItemsMoreButton,
    HomeNavSection,
    HomeRecommandCategoryBox,
    HomeSection,
    HomeSectionTitle,
    HomeSectionTitleBox,
    HomeSectionTitleLink,
    HomeSmarangIntroduceBox,
    HomeSmarangIntroduceDescription,
    HomeSmarangIntroduceDescriptionHead,
    HomeSmarangIntroduceDescriptionParagraph,
    HomeSmarangIntroduceVideo,
} from "./HomeStyle"
import { FONT_FAMILY } from "../../styles/Globalstyle"
import Utils from "../../utils/Utils"
import FileUtils from "../../utils/FileUtils"
import { AdLevel1Card, AdLevel2Card, AdLevel3Card } from "../../components/brandItems/AdItemCard"

import ImageHomeAdvertise1 from "../../static/home_advertise1.png"
import ImageHomeIntroduce from "../../static/home_introduce.png"
import Advertisement from "../../types/commons/Advertisement"
import Sigungu from "../../types/commons/Sigungu"
import { AdvertisementCategories } from "../../types/commons/CommonTypes"
import AdvertiseSlide from "../../components/home/AdvertiseSlide"
import { AdCategoryTypeNames, AdCategoryTypes } from "../../types/brandItems/BrandItemTypes"
import { getSigunguDatas } from "../../apis/api/commons/index"

import BrandItemIntroduceImage from "../../static/introduce_brand_item.png"
import AdItemIntroduceImage from "../../static/introduce_ad_item.png"

const MoreIcon = tw(ChevronRight)`
    
`

export default function Home() {
    const [isLoading, setLoading] = useState(false)

    const [mainBannerImages, setMainBannerImages] = useState([])
    const [items, setItems] = useState([
        new BrandItem(),
        new BrandItem(),
        new BrandItem(),
        new BrandItem(),
        new BrandItem(),
    ])

    const [adRecommandCategoryKeys, setAdRecommandCategoryKeys] = useState([])
    const [isAdLoading, setAdLoading] = useState(false)
    const [adLevel3s, setAdLevel3s] = useState([new BrandItem(), new BrandItem(), new BrandItem(), new BrandItem()])
    const [adLevel2s, setAdLevel2s] = useState([new BrandItem(), new BrandItem(), new BrandItem(), new BrandItem()])
    const [adLevel1s, setAdLevel1s] = useState([new BrandItem(), new BrandItem(), new BrandItem(), new BrandItem()])
    const [adLevel3ms, setAdLevel3ms] = useState([])
    const [adLevel2ms, setAdLevel2ms] = useState([])
    const [adLevel1ms, setAdLevel1ms] = useState([])
    const [itemSearchValue, setItemSearchValue] = useState("")
    const [adSearchValue, setAdSearchValue] = useState("")

    const [sigunguAll, setSigunguAll] = useState([])
    const [sidoOptionKeys, setSidoOptionKeys] = useState([])
    const [sidoOptions, setSidoOptions] = useState([])

    const navigate = useNavigate()

    const brandItemListRef = useRef(null)
    const adItemListRef = useRef(null)

    useEffect(() => {
        setLoading(true)
        getAdvertisements()
        getBrandItemList(1, 20)
            .then((response) => {
                const brandItemList = new BrandItemList()
                brandItemList.convertByResponse(response)
                setItems(brandItemList.items.slice(0, 5))
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
        getAdRecommandCategories()
        getAdItems()
        getSigunguAll()
    }, [])

    const getAdvertisements = async () => {
        const response = await getAdvertisementsHome()

        if (!Array.isArray(response) || response.length === 0) {
            return
        }

        let mainBanners = []
        response.map((adData) => {
            const ad = new Advertisement()
            ad.convertByResponse(adData)

            if (ad.category == AdvertisementCategories.MAIN_BANNER) {
                mainBanners.push(ad)
            }
        })

        setMainBannerImages(mainBanners)
    }

    const getAdRecommandCategories = () => {
        // 아직은 카테고리 중 랜덤으로 뽑아서 쓴다.
        const categoriesKeys = Object.values(AdCategoryTypes).filter((v) => v != 0)
        const shuffled = categoriesKeys.sort(() => 0.5 - Math.random())

        setAdRecommandCategoryKeys(shuffled.slice(0, 8))
    }

    const getAdItems = async () => {
        setAdLoading(true)
        const response = await getAdItemListHome()

        const convertItems = (data) => {
            let convertedItems = []
            if (Array.isArray(data) && data.length > 0) {
                data.map((result) => {
                    let item = new BrandItem()
                    item.convertByResponse(result)
                    convertedItems.push(item)
                })
            }

            return convertedItems
        }
        setAdLevel3s(convertItems(response["level3"]))
        setAdLevel2s(convertItems(response["level2"]))
        setAdLevel1s(convertItems(response["level1"]))
        setAdLevel3ms(convertItems(response["level3m"]))
        setAdLevel2ms(convertItems(response["level2m"]))
        setAdLevel1ms(convertItems(response["level1m"]))

        setAdLoading(false)
    }

    const getSigunguAll = async () => {
        const response = await getSigunguDatas()

        if (!Array.isArray(response)) {
            return
        }

        let _sigungus = []
        response.map((sigunguData) => {
            const _sigungu = new Sigungu()
            _sigungu.convertByResponse(sigunguData)
            _sigungus.push(_sigungu)
        })

        setSigunguAll(_sigungus)
        let _sidoOptionKeys = []
        let _sidoOptions = {}
        _sigungus
            .filter((s) => Utils.isNullOrUndefined(s.sigungu))
            .sort((a, b) => a.index - b.index)
            .map((s) => {
                _sidoOptionKeys.push(s.id)
                _sidoOptions[s.id] = s.sidoShort
            })
        setSidoOptionKeys(_sidoOptionKeys)
        setSidoOptions(_sidoOptions)
    }

    const handleItemSearch = () => {
        if (Utils.isStringNullOrEmpty(itemSearchValue)) {
            return
        }

        navigate("/items", { state: { searchKeyword: itemSearchValue } })
    }
    const handleItemSearchEnter = (e) => {
        if (e.key == "Enter") {
            handleItemSearch()
        }
    }
    const handleAdSearch = () => {
        if (Utils.isStringNullOrEmpty(adSearchValue)) {
            return
        }

        navigate("/ads", { state: { searchKeyword: adSearchValue } })
    }
    const handleAdSearchSido = (sidoKey) => {
        if (Utils.isStringNullOrEmpty(sidoKey)) {
            return
        }

        navigate("/ads", { state: { searchSido: sidoKey } })
    }
    const handleAdSearchEnter = (e) => {
        if (e.key == "Enter") {
            handleAdSearch()
        }
    }

    return (
        <HomeContainer>
            {/* <SlideOutsideCon /> */}
            {/* 내비게이션 높이 채움용 */}
            <HomeNavSection />
            {/* <HomeSection className="bg-white">
            </HomeSection> */}

            {/* 안내 */}
            <HomeSection className="max-sm:mt-0 md:pb-12 bg-white">
                <HomeAdvertiseSection className="mb-12">
                    <HomeAdvertiseSectionItem className="max-lg:flex lg:hidden aspect-[1200/600]">
                        {mainBannerImages.length > 0 && <AdvertiseSlide ads={mainBannerImages} />}
                    </HomeAdvertiseSectionItem>
                    <HomeAdvertiseSectionItem className="max-lg:hidden lg:flex aspect-[1200/120]">
                        {mainBannerImages.length > 0 && <AdvertiseSlide ads={mainBannerImages} />}
                    </HomeAdvertiseSectionItem>
                </HomeAdvertiseSection>

                <HomeContentsContainer>
                    <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 w-full max-sm:gap-2 sm:gap-4 [&>.box]:flex [&>.box]:flex-center [&>.box]:text-white [&>.box]:rounded-lg">
                        {/* <TestBox color={"sky"} moveRef={brandItemListRef} />
                        <TestBox color={"orange"} moveRef={adItemListRef} /> */}
                        <Introduce1 image={BrandItemIntroduceImage} moveRef={brandItemListRef} to={"/items"} />
                        <Introduce1 image={AdItemIntroduceImage} moveRef={adItemListRef} to={"/ads"} />
                    </div>
                </HomeContentsContainer>
            </HomeSection>

            {/* 홍보 상품 */}
            <HomeSection className="max-md:py-6 bg-slate-100">
                <HomeContentsContainer ref={brandItemListRef}>
                    <div className="flex w-full items-end">
                        <div className="flex flex-col w-full">
                            <HomeSectionTitleBox className="mb-1">
                                <HomeSectionTitle className="px-1 mb-2">
                                    <HomeSectionTitleLink to={"/ads"} state={{ itemOrder: ItemOrders.CREATED }}>
                                        사업 아이템
                                    </HomeSectionTitleLink>
                                    {/* <HomeItemSearchBox>
                                <HomeItemSearchInput
                                    type="text"
                                    value={adSearchValue}
                                    onChange={(e) => {
                                        setAdSearchValue(e.target.value)
                                    }}
                                    onKeyPress={handleAdSearchEnter}
                                />
                                <HomeItemSearchButton
                                    onClick={() => {
                                        handleAdSearch()
                                    }}
                                >
                                    <HomeItemSearchIcon />
                                </HomeItemSearchButton>
                            </HomeItemSearchBox> */}
                                </HomeSectionTitle>
                                {/* <Link to={"/ads"} state={{ itemOrder: ItemOrders.CREATED }}>
                            <HomeItemsMoreButton>
                                전체보기
                                <div className="w-6 max-md:pb-0.25 md:pb-0.75">
                                    <MoreIcon className="text-nightblue-700" />
                                </div>
                            </HomeItemsMoreButton>
                        </Link> */}
                            </HomeSectionTitleBox>
                            <HomeRecommandCategoryBox>
                                {adRecommandCategoryKeys.map((categoryKey, index) => (
                                    <Link
                                        key={index}
                                        className={`category ${index >= 4 ? "max-md:hidden md:flex" : "flex"}`}
                                        to={"/ads"}
                                        state={{ adCategory: categoryKey }}
                                    >
                                        {AdCategoryTypeNames[categoryKey]}
                                    </Link>
                                ))}
                            </HomeRecommandCategoryBox>
                        </div>
                        <HomeFilterAddressBox>
                            {sidoOptionKeys.map((sidoOption, index) => {
                                return (
                                    <button
                                        key={index}
                                        className={`sido ${index == 0 ? "all" : ""}`}
                                        onClick={() => {
                                            handleAdSearchSido(sidoOption)
                                        }}
                                    >
                                        {sidoOptions[sidoOption]}
                                    </button>
                                )
                            })}
                        </HomeFilterAddressBox>
                    </div>
                    {adLevel3s.length > 0 && (
                        <HomeItemsList className="max-md:grid-cols-2 md:grid-cols-4 mb-12">
                            {adLevel3s.map((data, idx) => (
                                <AdLevel2Card key={idx} item={data} isLoading={isAdLoading} />
                            ))}
                            {/* 데스크탑 용 */}
                            {adLevel3ms.slice(0, 4 - (adLevel3s.length % 4)).map((data, idx) => (
                                <AdLevel2Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:hidden md:flex"}
                                />
                            ))}
                            {/* 모바일 용 */}
                            {adLevel3ms.slice(0, 2 - (adLevel3s.length % 2)).map((data, idx) => (
                                <AdLevel2Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:flex md:hidden"}
                                />
                            ))}
                        </HomeItemsList>
                    )}
                    {adLevel2s.length > 0 && (
                        <HomeItemsList className="max-md:grid-cols-2 md:grid-cols-5 mb-12">
                            {adLevel2s.map((data, idx) => (
                                <AdLevel2Card key={idx} item={data} isLoading={isAdLoading} />
                            ))}
                            {/* 데스크탑 용 */}
                            {adLevel2ms.slice(0, 5 - (adLevel2s.length % 5)).map((data, idx) => (
                                <AdLevel2Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:hidden md:flex"}
                                />
                            ))}
                            {/* 모바일 용 */}
                            {adLevel2ms.slice(0, 2 - (adLevel2s.length % 2)).map((data, idx) => (
                                <AdLevel2Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:flex md:hidden"}
                                />
                            ))}
                        </HomeItemsList>
                    )}
                    {adLevel1s.length > 0 && (
                        <HomeItemsList className="max-md:grid-cols-2 md:grid-cols-5">
                            {adLevel1s.map((data, idx) => (
                                <AdLevel1Card key={idx} item={data} isLoading={isAdLoading} />
                            ))}
                            {/* 데스크탑 용 */}
                            {adLevel1ms.slice(0, 5 - (adLevel1s.length % 5)).map((data, idx) => (
                                <AdLevel1Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:hidden md:flex"}
                                />
                            ))}
                            {/* 모바일 용 */}
                            {adLevel1ms.slice(0, 2 - (adLevel1s.length % 2)).map((data, idx) => (
                                <AdLevel1Card
                                    key={idx}
                                    item={data}
                                    isLoading={isAdLoading}
                                    classStyle={"max-md:flex md:hidden"}
                                />
                            ))}
                        </HomeItemsList>
                    )}
                </HomeContentsContainer>
            </HomeSection>

            {/* 최신 상품 */}
            <HomeSection className="">
                <HomeContentsContainer ref={adItemListRef}>
                    <HomeSectionTitleBox>
                        <HomeSectionTitle className="px-1">
                            <HomeSectionTitleLink to={"/items"} state={{ itemOrder: ItemOrders.CREATED }}>
                                영업 아이템
                            </HomeSectionTitleLink>
                            <HomeItemSearchBox>
                                <HomeItemSearchInput
                                    type="text"
                                    value={itemSearchValue}
                                    onChange={(e) => {
                                        setItemSearchValue(e.target.value)
                                    }}
                                    onKeyPress={handleItemSearchEnter}
                                />
                                <HomeItemSearchButton
                                    onClick={() => {
                                        handleItemSearch()
                                    }}
                                >
                                    <HomeItemSearchIcon />
                                </HomeItemSearchButton>
                            </HomeItemSearchBox>
                        </HomeSectionTitle>
                        {/* <Link to={"/items"} state={{ itemOrder: ItemOrders.CREATED }}>
                            <HomeItemsMoreButton>
                                전체보기
                                <div className="w-6 max-md:pb-0.25 md:pb-0.75">
                                    <MoreIcon className="text-nightblue-700" />
                                </div>
                            </HomeItemsMoreButton>
                        </Link> */}
                    </HomeSectionTitleBox>
                    <HomeItemsList>
                        {items.map((data, idx) => (
                            <BrandItemCard key={idx} item={data} itemType={ItemCardType.MAIN} isLoading={isLoading} />
                        ))}
                    </HomeItemsList>
                </HomeContentsContainer>
            </HomeSection>

            {/* 세일즈랑 소개 영상 */}
            <HomeSection className="bg-nightblue-10">
                <HomeContentsContainer>
                    <HomeSmarangIntroduceBox>
                        <HomeSmarangIntroduceVideo
                            src="https://www.youtube.com/embed/OSbiiltg4OU"
                            title="기업과 세일즈마케터를 매칭하는 플랫폼, 세일즈랑!"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />

                        <HomeSmarangIntroduceDescription>
                            <HomeSmarangIntroduceDescriptionHead>
                                기업과 영업프리랜서 영업자 매칭 플랫폼, 세일즈랑!
                            </HomeSmarangIntroduceDescriptionHead>
                            <HomeSmarangIntroduceDescriptionParagraph style={{ fontFamily: FONT_FAMILY }}>
                                {`"세일즈랑은 기업(브랜드)와 영업 프리랜서(마케터)를 \n연결하는 플랫폼으로,영업분야 고용 문제를 유연하게 해결합니다."`}
                            </HomeSmarangIntroduceDescriptionParagraph>
                        </HomeSmarangIntroduceDescription>
                    </HomeSmarangIntroduceBox>
                </HomeContentsContainer>
            </HomeSection>
            {/* 상품 카테고리 */}
            <HomeSection className="bg-nightblue-10 hidden">
                <HomeContentsContainer>
                    <HomeCategoryBox>
                        <HomeSectionTitleBox>
                            <HomeSectionTitle>상품 유형</HomeSectionTitle>
                        </HomeSectionTitleBox>
                        <HomeCategoryListContainer>
                            <HomeCategoryList>
                                {CARD_CATEGORY_DATA_LIST.map((data, idx) => (
                                    <BrandCategoryCard key={idx} index={idx} data={data} />
                                ))}
                            </HomeCategoryList>
                        </HomeCategoryListContainer>
                    </HomeCategoryBox>
                </HomeContentsContainer>
            </HomeSection>
        </HomeContainer>
    )
}

const Introduce1 = ({ image, color = "slate", moveRef, to }) => {
    const [width, setWidth] = useState(600)
    const [height, setHeight] = useState(224)

    const ref = useRef()

    // useEffect(() => {
    //     const updateSize = () => {
    //         if (ref.current) {
    //             setWidth(ref.current.offsetWidth)
    //             setHeight(ref.current.offsetHeight)
    //         }
    //     }

    //     // 초기 크기 설정
    //     updateSize()

    //     // 윈도우 리사이즈 이벤트 핸들러 등록
    //     window.addEventListener("resize", updateSize)

    //     setTimeout(() => {
    //         setWidth(ref.current.offsetWidth)
    //         setHeight(ref.current.offsetHeight)
    //     }, 100)

    //     // 클린업: 윈도우 리사이즈 이벤트 핸들러 제거
    //     return () => {
    //         window.removeEventListener("resize", updateSize)
    //     }
    // }, [])

    const handleMove = () => {
        // let scrollOption = {
        //     top: moveRef.current.offsetTop - 80,
        //     behavior: "smooth",
        // }
        // window.scrollTo(scrollOption)
        // 링크 이동으로 수정
    }

    return (
        <Link to={to}>
            <img
                ref={ref}
                src={image}
                className={`box flex flex-col flex-center w-full aspect-[3/1] cursor-pointer select-none`}
                onClick={handleMove}
            >
                {/* <span>이미지 사이즈 600*200</span>
            <span>
            현재 사이즈 {width}*{height}
        </span> */}
            </img>
        </Link>
    )
}
