import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import { updateItemPerformValue } from "../../../apis/api/boardItems";
import { PerformItemCombo } from "../../../components/brandItems/PerformInputs";
import { EditStateTypes } from "../../../types/EditorTypes";
import Utils from "../../../utils/Utils";
import { PerformTextBox, PerformValueBox } from "./MyPagePerformStyle";
import { AccessRules } from '../../../rules/AccessRules';


export const PerformValueComboCell = ({column, perform, value}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [selectedIndex, setIndex] = useState(0)
    const [name, setName] = useState(column.combos.length > 0 ? column.combos[0].name : "")
    const [comboID, setComboID] = useState(0)

    useEffect(()=>{
        setComboID(value.comboID)
    }, [value])

    useEffect(()=>{
        const combo = column.combos.find(s => s.id === comboID)
        if (combo !== undefined && !Utils.isStringNullOrEmpty(combo.name)) {
            setName(combo.name)
        }
        if (comboID < 0) {
            setName("-")
        }
    }, [comboID])

    const handleChange = (_comboID) => {
        if (_comboID >= 0) {
            updateItemPerformValue(column.id, value.performID, {combo: _comboID}).then(response => {
                if (!response) {
                    alert("수정 실패했습니다.")
                }
            })
            setComboID(_comboID)
        }
    }

    return (
        <PerformValueBox>
            {AccessRules.performValueWrite(column, perform) ? (
                <PerformItemCombo
                    optionKeys={column.combos.map(combo => combo.id)} 
                    names={column.combos.map(combo => combo.name)} 
                    value={comboID}
                    onChange={(e)=>{handleChange(e.target.value)}}
                    editState={editState}
                    color="white"
                />
            ) : (
                <PerformTextBox>
                    <span className="px-1 truncate">{name}</span>
                </PerformTextBox>
            )}
        </PerformValueBox>
    )
}
