import React, { useState, useEffect } from "react"
import { CheckCircleIcon, StepsLayout, StepLine, CircleIcon } from "./SignUpStyle"
import { UserTypes } from "../../types/users/UserTypes"

const MARKETER_STEP_LIST = [
    {
        title: "약관 동의",
        description: "",
    },
    {
        title: "기본 정보",
        description: "",
    },
    {
        title: "추천정보",
        description: "(선택)",
    },
]

const BRAND_STEP_LIST = [
    {
        title: "약관 동의",
        description: "",
    },
    {
        title: "기본 정보",
        description: "",
    },
    {
        title: "추가정보",
        description: "",
    },
]

export const Steps = ({ stepindex, userType }) => {
    const [stepData, setStepData] = useState([])

    useEffect(() => {
        if (userType === UserTypes.BRAND) {
            setStepData(BRAND_STEP_LIST)
        } else {
            setStepData(MARKETER_STEP_LIST)
        }
    }, [])

    return (
        <StepsLayout>
            {stepData.map((data, idx) => (
                <>
                    <div
                        className={`${stepindex >= idx ? "text-nightblue-600" : "text-gray-400"} h-6 relative`}
                        key={idx}
                    >
                        {stepindex > idx ? (
                            <CheckCircleIcon />
                        ) : (
                            <CircleIcon
                                className={`${
                                    stepindex === idx
                                        ? "drop-shadow-xl shadow-nightblue-600 border-2 border-nightblue-500 rounded-full"
                                        : ""
                                }`}
                            />
                        )}
                        <div className="absolute left-[-6px]">
                            <span className="text-sm">{"step" + (idx + 1)}</span>
                            <p className="text-sm w-[72px]">{data.title}</p>
                            <p className="text-xs w-[72px]">{data.description}</p>
                        </div>
                    </div>
                    {idx + 1 !== stepData.length && (
                        <StepLine
                            isorderd={stepindex >= idx + 1}
                            className={stepindex > idx + 1 ? "to-nightblue-600" : ""}
                        />
                    )}
                </>
            ))}
        </StepsLayout>
    )
}
