import React from 'react';
import { PropTypes } from 'prop-types';

export class Response{
    constructor(responseMessage) {
        this._responseMessage = responseMessage
    }

    get responseMessage() {
        return this._responseMessage
    }
}
