export const UserTypes = {
    NONE: -1,
    STAFF: 0,
    BRAND: 1,
    MARKETER: 2
}

export const MarketerParticipantTypes = {
    FULL: 0,
    SIDE: 1,
    NONE: 2
}

export const MarketerParticipantNames = {
    0: "전업",
    1: "부업",
    2: "-"
}

export const StaffTypes = {
    ADMIN: 0,
    STAFF: 1
}

export const UserGenderTypes = {
    MALE: 0,
    FEMALE: 1,
    NONE: 2
}

export const UserGenderNames = {
    0: "남성",
    1: "여성",
    2: "-"
}