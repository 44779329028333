import React from "react"

export default function ItemMarketerNameChainParent({ nameChain }) {
    const names = String(nameChain).split(" > ")

    if (names.length <= 1) {
        return <div>{nameChain}</div>
    } else {
        const parent = names[names.length - 2]
        const name = names[names.length - 1]
        return (
            <div className="flex flex-col">
                <span className="text-sm text-nightblue-500">{parent}</span>
                <span>{`> ${name}`}</span>
            </div>
        )
    }
}
