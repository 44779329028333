import React, { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components'
import { PmsNavState } from '../../../../types/DataEnum'
import { ModalContainer } from '../../../../components/ModalContainer'
import StaffItemsContractWriter from './StaffItemsContractWriter'
import StaffThirdPartyAgreementWriter from './StaffThirdPartyAgreementWriter';
import { ContractTitle, ContractWriter, ContractWriterButton, ContractWriterButtonContainer, ContractWriterButtonTitle, ContractWriterContaienr, ContractWriterTitleContainer } from './StaffItemsStyle';
import StaffUsageWriter from './StaffUsageWriter';
import StaffPersonalAgreementWriter from './StaffPersonalAgreementWriter';



export default function StaffItems({ setPmsNavState }) {
    const [showContractWriter, setShowContractWriter] = useState(false)
    const [showUsageWriter, setShowUsageWriter] = useState(false) // 이용약관
    const [showPersonalWriter, setShowPersonalWriter] = useState(false) // 개인정보 이용 동의
    const [showThirdPartyWriter, setShowThirdPartytWriter] = useState(false) // 3자정보 동의 모달 state

    // 네비게이션 하이라이트 상태 변경
    useEffect(() => {
        setPmsNavState(PmsNavState.STAFFITEM)

        return () => (
            setPmsNavState(PmsNavState.NONE)
        )
    }, []);

    const handeOnClickContract = () => { // 계약서 작성
        setShowContractWriter(true)
    }

    const handeOnClickUsage = () => { // 이용약관
        setShowUsageWriter(true)
    }

    const handeOnClickPersonal = () => { // 개인정보 수집 이용
        setShowPersonalWriter(true)
    }

    const handeOnClickThirdParty = () => { // 3자정보 동의서
        setShowThirdPartytWriter(true)
    }

    return (
        <React.Fragment>
            <ContractWriter>
                <ContractWriterContaienr>

                    <ContractWriterTitleContainer>
                        <ContractTitle>
                            계약서 및 동의서 설정
                        </ContractTitle>
                    </ContractWriterTitleContainer>

                    <ContractWriterButtonContainer>
                        <ContractWriterButtonTitle>계약서</ContractWriterButtonTitle>
                        <ContractWriterButton onClick={() => { handeOnClickContract() }}>
                            작성
                        </ContractWriterButton>
                    </ContractWriterButtonContainer>

                    <ContractWriterButtonContainer>
                        <ContractWriterButtonTitle>이용약관 (회원가입)</ContractWriterButtonTitle>
                        <ContractWriterButton onClick={() => { handeOnClickUsage() }}>
                            작성
                        </ContractWriterButton>
                    </ContractWriterButtonContainer>

                    <ContractWriterButtonContainer>
                        <ContractWriterButtonTitle>개인정보 이용 동의서 (회원가입)</ContractWriterButtonTitle>
                        <ContractWriterButton onClick={() => { handeOnClickPersonal() }}>
                            작성
                        </ContractWriterButton>
                    </ContractWriterButtonContainer>

                    <ContractWriterButtonContainer>
                        <ContractWriterButtonTitle>3자 정보 동의서 (QRCODE)</ContractWriterButtonTitle>
                        <ContractWriterButton onClick={() => { handeOnClickThirdParty() }}>
                            작성
                        </ContractWriterButton>
                    </ContractWriterButtonContainer>

                </ContractWriterContaienr>
            </ContractWriter>

            <ModalContainer show={showContractWriter} setShow={setShowContractWriter} closeButton={true}>
                <div className='w-156  bg-white'>
                    <StaffItemsContractWriter setShow={setShowContractWriter} />
                </div>
            </ModalContainer>

            <ModalContainer show={showUsageWriter} setShow={setShowUsageWriter} closeButton={true}>
                <div className='w-156  bg-white'>
                    <StaffUsageWriter setShow={setShowUsageWriter} />
                </div>
            </ModalContainer>

            <ModalContainer show={showPersonalWriter} setShow={setShowPersonalWriter} closeButton={true}>
                <div className='w-156  bg-white'>
                    <StaffPersonalAgreementWriter setShow={setShowPersonalWriter} />
                </div>
            </ModalContainer>

            <ModalContainer show={showThirdPartyWriter} setShow={setShowThirdPartytWriter} closeButton={true}>
                <div className='w-156  bg-white'>
                    <StaffThirdPartyAgreementWriter setShow={setShowThirdPartytWriter} />
                </div>
            </ModalContainer>

        </React.Fragment>
    )
}