import PerformValue from "./PerformValue";
import { PerformColumnFormTypes } from "./PerformValueTypes";

export default class extends PerformValue{
    constructor() {
        super()
        this.columnID = -1
        this.performID = -1
        
        this.dateTime = ""
        this.timeStamp = null
        this.memo = ""
    }

    convertByResponse(response) {
        this.columnID = response["column_id"]
        this.performID = response["perform_id"]

        this.dateTime = response["datetime"]
        this.timeStamp = Number(response["timestamp"]) * 1000
        this.memo = response["memo"]
    }

    convertToRequest() {
    }
}