import Paginator from "../Paginator";
import Brand from "./Brand";

export default class extends Paginator{
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.items = []
        response["results"].map(result => {
            let item = new Brand()
            item.convertByResponse(result)
            this.items.push(item)
        })
    }
}