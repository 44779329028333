import Utils from "../../utils/Utils"
import User from "./User"
import UserMarketerCareer from "./UserMarketerCareer"
import { MarketerParticipantTypes, UserTypes } from "./UserTypes"
export default class {
    constructor() {
        this.user = new User()
        this.detail = {
            age: 0,
            location1: 0,
            location2: 0,
            location3: 0,
            participantType: MarketerParticipantTypes.SIDE,
            profit: 0,
            currentWorkBuildingName: 0,
            currentWorkAddress1: 0,
            currentWorkAddress2: "",
            currentWorkJob: 0,
            currentWorkTel: 0,
        }
        this.careers = []
    }

    convertByResponse(response) {
        const userResponse = response["user"]
        this.user = new User()
        if (!Utils.isNullOrUndefined(userResponse)) {
            this.user.convertByResponse(userResponse)
        }

        const detailResponse = response["detail"]
        this.detail = {}
        if (!Utils.isNullOrUndefined(detailResponse)) {
            this.detail.age = detailResponse["age"]
            this.detail.location1 = detailResponse["location1"]
            this.detail.location2 = detailResponse["location2"]
            this.detail.location3 = detailResponse["location3"]
            this.detail.participantType = detailResponse["participant_type"]
            this.detail.profit = detailResponse["profit"]
            this.detail.currentWorkBuildingName = detailResponse["current_work_building_name"]
            this.detail.currentWorkAddress1 = detailResponse["current_work_address1"]
            this.detail.currentWorkAddress2 = detailResponse["current_work_address2"]
            this.detail.currentWorkJob = detailResponse["current_work_job"]
            this.detail.currentWorkTel = detailResponse["current_work_tel"]
        }

        const careersResponse = response["careers"]
        this.careers = []
        if (!Utils.isNullOrUndefined(careersResponse)) {
            careersResponse.map((careerResponse) => {
                let career = new UserMarketerCareer()
                career.convertByResponse(careerResponse)
                this.careers.push(career)
            })
        }
    }
}
