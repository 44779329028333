import { useEffect,useState } from "react";
import { useLocation } from 'react-router-dom';

export default function ScrollToTop({isTop, setIsTop, smooth=false}){
    const {pathname} = useLocation();
    // const [outlet,setOutlet] = useState(document.getElementById("router-outlet"))
    // const checkScrollPosition = () => {
    //     console.log(outlet.scrollTop)
    //     setIsTop(outlet.scrollTop === 0);
    // }

    // useEffect(() => {
    //     if (outlet !== null) {
    //         outlet.addEventListener("scroll", checkScrollPosition);
    //         checkScrollPosition();
    //         return () => {
    //             outlet.removeEventListener("scroll", checkScrollPosition);
    //         };
    //     }
    // }, [outlet]);
    
    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0
        })
        // const outlet = document.getElementById("router-outlet")
        // if (outlet !== null) {
        //     outlet.scrollTo({
        //         left: 0,
        //         top: 0,
        //     })
        // }
        // const waitForOutlet = async () => {
        //     const timeout = 3000; // 3초
        //     const startTime = new Date().getTime();
    
        //     while (!document.getElementById("router-outlet")) {
        //         await new Promise(resolve => setTimeout(resolve, 100));
        //         const currentTime = new Date().getTime();
        //         if (currentTime - startTime >= timeout) {
        //             break;
        //         }
        //     }
        //     setOutlet(document.getElementById("router-outlet"));
        // }
        // waitForOutlet();
    }, [pathname]);
    

    // useEffect(() => {
    //     if( outlet !== null ){
    //         let option = {
    //             left:0,
    //             top:0,
    //         }
    //         if (smooth){
    //             option['behavior'] = 'smooth'
    //         }
    //         outlet.scrollTo(option)
    //     }
        
    // }, [location]);
    
    return null;

}
    
