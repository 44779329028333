import React, { useState, useEffect } from "react"
import MyInfoMarketerCareer from "./MyInfoMarketerCareer"
import MyInfoMarketer from "./MyInfoMarketer"
import {
    MarketerParticipantNames,
    MarketerParticipantTypes,
    UserGenderNames,
    UserGenderTypes,
} from "../../../types/users/UserTypes"
import { getUserMarketerCareerRequestData, getUserMarketerSignupRequestData } from "../../../services/users"
import {
    deleteMarketerCareers,
    getAuthTokenByToken,
    updateMarketerCareers,
    updateUserDetail,
} from "../../../apis/api/users"
import UserMarketerCareer from "../../../types/users/UserMarketerCareer"
import UserMarketer from "../../../types/users/UserMarketer"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { MyInfoMoreDataBox, MyPageDataControlBox, MyPageDataSubmitButton } from "./MyInfoMainStyle"
import User from "../../../types/users/User"
import { REGION_KEYS } from "../../../types/DataEnum"

export function MyInfoMarketerMore({
    marketer,
    setApplyMarketerData = null,
    isApply = false,
    submitFlag = false,
    setSubmitFlag = null,
    isSubmitted,
    setIsSubmitted = null,
}) {
    const { user, userDetail, actions } = useUserStore()

    const [location1, setLocation1] = useState(0)
    const [location2, setLocation2] = useState(0)
    const [location3, setLocation3] = useState(0)
    const [participantType, setParticipantType] = useState(MarketerParticipantTypes.NONE)
    const [userGenderType, setUserGenderType] = useState(UserGenderTypes.NONE)
    const [profit, setProfit] = useState()
    const [careers, setCareers] = useState([])
    const [locationTypes, setLocationTypes] = useState({})
    const [birthday, setBirthday] = useState("")
    const [jobTypes, setJobTypes] = useState({})
    const [businessTypes, setBusinessTypes] = useState({})
    const [marketerCareers, setMarketerCareers] = useState([])

    useEffect(() => {
        if (setSubmitFlag !== null) {
            if (submitFlag === true) {
                handleSubmit()
            }
            setSubmitFlag(false)
        }
    }, [submitFlag])

    const submitCareeres = () => {
        return new Promise((resolve, reject) => {
            const filteredData = marketerCareers.filter((obj) => obj.jobType !== null)
            const requestData = {
                careers: filteredData.map((career) => getUserMarketerCareerRequestData(career)),
            }
            updateMarketerCareers(requestData)
                .then((response) => {
                    let careers = []

                    response.data.map((careerResponse) => {
                        const _career = new UserMarketerCareer()
                        _career.convertByResponse(careerResponse)
                        careers.push(_career)
                    })

                    setMarketerCareers(careers)

                    resolve(true)
                })
                .catch((error) => {
                    reject(new Error("값을 입력해주세요"))
                })
        })
    }

    const submit = () => {
        return new Promise((resolve, reject) => {
            if (
                Utils.isStringNullOrEmpty(birthday) ||
                Utils.isStringNullOrEmpty(location1) ||
                userGenderType === UserGenderTypes.NONE
            ) {
                alert("값을 입력해주세요")
                reject(new Error("값을 입력해주세요"))
            }

            // 경력은 여기서 업데이트 되진 않는다.
            // 지역이 선택되지않으면 null을 넣는다.
            const requestData = {
                user: {
                    gender: userGenderType,
                    birthday: birthday,
                },
                ...getUserMarketerSignupRequestData(
                    Object.keys(locationTypes).includes(String(location1)) ? location1 : null,
                    Object.keys(locationTypes).includes(String(location2)) ? location2 : null,
                    Object.keys(locationTypes).includes(String(location3)) ? location3 : null,
                    participantType,
                    userGenderType,
                    careers,
                ),
            }

            const updateValue = (_marketer) => {
                setLocation1(_marketer.detail.location1)
                setLocation2(_marketer.detail.location2)
                setLocation3(_marketer.detail.location3)
                setProfit(_marketer.detail.profit)
                setCareers(_marketer.careers)
                setBirthday(_marketer.user.birthday)
                setUserGenderType(_marketer.user.gender)
                // setParticipantType(_marketer.detail.participantType)
            }

            updateUserDetail(requestData)
                .then((response) => {
                    const _marketer = new UserMarketer()
                    _marketer.convertByResponse(response.data)
                    updateValue(_marketer)
                    if (setApplyMarketerData !== null) {
                        setApplyMarketerData(_marketer)
                    }

                    resolve(true)
                })
                .catch((error) => {
                    reject(new Error("값을 확인 해주세요"))
                })
        })
    }

    const handleSubmit = async () => {
        if (marketerCareers.length === 0 || Utils.isNullOrUndefined(marketerCareers[0].jobType)) {
            alert("경력을 입력해주세요.")
            return
        }
        const isSubmitted = await submit()
        const isCareeresSubmitted = await submitCareeres()

        if (isSubmitted && isCareeresSubmitted) {
            alert("저장되었습니다.")

            const userData = await getAuthTokenByToken()
            if (userData !== undefined) {
                const user = new User()
                user.convertByResponse(userData.user)
                actions.setUser(user)
            }

            if (setIsSubmitted !== null) {
                setIsSubmitted(true)
            }
        } else {
            if (!isSubmitted) {
                alert("추가 정보를 확인 해주시기 바랍니다.")
            } else if (!isCareeresSubmitted) {
                alert("경력 정보를 확인 해주시기 바랍니다.")
            }
        }
    }

    return (
        <MyInfoMoreDataBox className="mt-10">
            <MyInfoMarketer
                marketer={marketer}
                isApply={isApply}
                location1={location1}
                setLocation1={setLocation1}
                location2={location2}
                setLocation2={setLocation2}
                location3={location3}
                setLocation3={setLocation3}
                participantType={participantType}
                setParticipantType={setParticipantType}
                userGenderType={userGenderType}
                setUserGenderType={setUserGenderType}
                profit={profit}
                setProfit={setProfit}
                careers={careers}
                setCareers={setCareers}
                locationTypes={locationTypes}
                setLocationTypes={setLocationTypes}
                birthday={birthday}
                setBirthday={setBirthday}
                setApplyMarketerData={setApplyMarketerData}
            />
            <MyInfoMarketerCareer
                marketer={marketer}
                isApply={isApply}
                jobTypes={jobTypes}
                setJobTypes={setJobTypes}
                businessTypes={businessTypes}
                setBusinessTypes={setBusinessTypes}
                careers={marketerCareers}
                setCareers={setMarketerCareers}
                submitCareeres={submitCareeres}
            />
            {!isApply && (
                <MyPageDataControlBox className="w-full mb-6 px-10 flex flex-center">
                    <MyPageDataSubmitButton
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        저장
                    </MyPageDataSubmitButton>
                </MyPageDataControlBox>
            )}
        </MyInfoMoreDataBox>
    )
}
