import React, { useState, useEffect, useRef } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { getBrandItemView, registItemMarketer } from "../../apis/api/boardItems"
import { ImageBase64, ImageContainer } from "../../components/ImageContainer"
import { ITEM_DATA } from "../../constants/ConstItem"
import BrandItem from "../../types/brandItems/BrandItem"
import { ItemSalesTypeNames, MarketerTypeNames } from "../../types/brandItems/BrandItemTypes"
import {
    ItemApplyBrandContainer,
    ItemApplyBrandContents,
    ItemApplyBrandDescription,
    ItemApplyBrandDescriptionContainer,
    ItemApplyBrandIconContainer,
    ItemApplyBrandTitle,
    ItemApplyBrandTitleContainer,
    ItemApplyDetail,
    ItemDetailApplyContents,
    ItemDetailApplyItem,
    ItemDetailApplyItemContainer,
    ItemDetailApplyItemContents,
    ItemDetailApplyItemDescription,
    ItemDetailApplyButton,
    ItemDetailContainer,
    ItemDetailContentContainer,
    ItemDetailContentDiv,
    ItemDetailContentTitle,
    ItemDetailImageContainer,
    ItemDetailIntroduce,
    ItemDetailNav,
    ItemDetailNavInner,
    ItemDetailPageContainer,
    ItemDetailShareContainer,
    ItemDetailThumbnail,
    ItemDetailThumbnailContainer,
    ItemDetailThumbnailTitle,
    ShareButton,
    ShareIcon,
    ItemDetailApplyMarketerStatus,
    ItemDetailTitleContainer,
    ItemDetailThumbDataContainer,
    ItemDetailNavContainer,
    ItemDetailMobileControlContainer,
    ItemDetailMobileControlBox,
    ItemDetailMobileControlScrollTopButton,
    ItemDetailMobileControlScrollTopIcon,
    ItemDetailMobileApplyMarketerStatus,
    ItemDetailMobileApplyButton,
    ItemDetailApplyTypeContentGrid,
    ItemDetailApplyTypeContentBox,
    ItemDetailApplyTypeContentTitle,
    ItemDetailApplyTypeContentValue,
} from "./ItemDetailStyle"
import { UserTypes } from "../../types/users/UserTypes"
import { useUserStore } from "../../zustand/store"
import { useLoginModalStore, marketerApplyModal } from "../../zustand/modalstore"
import UserAvatar from "../../components/user/UserAvatar"
import tw from "tailwind-styled-components"
import AccessRules from "../../rules/AccessRules"
import { MarketerStatusTypes } from "../../types/brandItems/BrandItemTypes"
import FileUtils from "../../utils/FileUtils"
import { registItemByMarketer } from "../../apis/services/brandItems"
import Utils from "../../utils/Utils"
import ReactQuillContent from "../../components/boards/ReactQuillContent"
import { useUiStore } from "../../zustand/uiStore"
import { isMobile } from "react-device-detect"
import CKEditorContent from "../../components/boards/CKEditorContent"

export default function ItemDetail() {
    const { user } = useUserStore((state) => state)
    const { mainNavHide } = useUiStore()

    const { itemID } = useParams()
    const setLoginShow = useLoginModalStore((state) => state.setLoginShow)
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(false)
    const [item, setItem] = useState(new BrandItem())

    const [descriptionContent, setDescriptionContent] = useState("")
    const [missionContent, setMissionContent] = useState("")
    const [rewardContent, setRewardContent] = useState("")
    const [contractContent, setContractContent] = useState("")

    const [marketerStatusType, setMarketerStatusType] = useState(null)

    const [activeSection, setActiveSection] = useState(1)
    const DescriptionRef = useRef(null)
    const MissionRef = useRef(null)
    const RewardRef = useRef(null)
    const ContractRef = useRef(null)
    const navContainerRef = useRef(null)

    //zustand로 가져온 데이터
    const setItemID = marketerApplyModal((state) => state.setItemID)
    const setMarketerModalShow = marketerApplyModal((state) => state.setMarketerApplyModal)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            getBrandItem()
        }, 150)
    }, [itemID])

    const getBrandItem = () => {
        setLoading(true)
        getBrandItemView(itemID)
            .then((response) => {
                const responseItem = new BrandItem()
                responseItem.convertByResponse(response)
                setItem(responseItem)
                setMarketerStatusType(response.marketer_status_type)
                initContractContent(responseItem)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert("찾을 수 없습니다.")
                navigate(-1)
            })
    }

    const initContractContent = (_item) => {
        setDescriptionContent(FileUtils.replaceMedieFileURL(_item.detail.description))
        setMissionContent(FileUtils.replaceMedieFileURL(_item.contract.mission))
        setRewardContent(FileUtils.replaceMedieFileURL(_item.contract.reward))
        setContractContent(FileUtils.replaceMedieFileURL(_item.contract.contract))
    }

    const handleScroll = () => {
        let offset = 106
        const descripTop = DescriptionRef.current.offsetTop - offset
        const missionTop = MissionRef.current.offsetTop - offset
        const reawrdTop = RewardRef.current.offsetTop - offset
        const contractTop = ContractRef.current.offsetTop - offset
        const scrollY = window.scrollY

        if (scrollY > descripTop && scrollY < missionTop) {
            setActiveSection(1)
        } else if (scrollY > missionTop && scrollY < reawrdTop) {
            setActiveSection(2)
        } else if (scrollY > reawrdTop && scrollY < contractTop) {
            setActiveSection(3)
        } else if (scrollY > contractTop) {
            setActiveSection(4)
        } else {
            setActiveSection(1)
        }
    }

    const handleNavLinkClick = (section) => {
        let ref
        let offset = 104
        switch (section) {
            case 1:
                ref = DescriptionRef
                break
            case 2:
                ref = MissionRef
                break
            case 3:
                ref = RewardRef
                break
            case 4:
                ref = ContractRef
                break
            default:
                break
        }

        if (ref) {
            let scrollOption = {
                top: ref.current.offsetTop - offset,
                behavior: "smooth",
            }

            window.scrollTo(scrollOption)
        }
    }

    // 임시 코드
    const handleRegist = () => {
        registItemMarketer(item.id).then((response) => {
            if (response) {
                alert("신청 되었습니다.")
                getBrandItem()
            }
        })
    }

    const NotUser = () => {
        setLoginShow(true)
        alert("로그인 후 이용 가능 합니다")
    }

    const goApply = () => {
        // setMarketerModalShow(true)
        // setItemID(itemID)
        navigate(`/apply/${itemID}`)
    }

    const handleApplyClick = () => {
        {
            !Utils.isNullOrUndefined(user.userType) && user.userType !== UserTypes.NONE ? goApply() : NotUser()
        }
    }
    // const handleReqItem = () => {
    //     {(user.userType !== UserTypes.NONE)?
    //         navigate(`/apply/${itemID}`)
    //     :
    //         NotUser()
    //     }
    // }

    const handleScrollTop = () => {
        window.scrollTo({ top: 0 })
    }

    return (
        <ItemDetailPageContainer>
            <ItemDetailContainer>
                <ItemDetailMobileControlContainer>
                    <ItemDetailMobileControlBox>
                        <ItemDetailMobileControlScrollTopButton
                            onClick={() => {
                                handleScrollTop()
                            }}
                        >
                            <ItemDetailMobileControlScrollTopIcon />
                        </ItemDetailMobileControlScrollTopButton>
                    </ItemDetailMobileControlBox>
                    <ItemDetailMobileControlBox>
                        {isLoading ? (
                            <React.Fragment>
                                {!AccessRules.brandItemManagement(user) && (
                                    <div className="skeleton flex flex-center px-4 py-2 rounded-full text-sm">
                                        loading....
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {item.id >= 0 && AccessRules.brandItemDetailRegist(marketerStatusType, user) && (
                                    <ItemDetailMobileApplyButton
                                        onClick={() => {
                                            handleApplyClick()
                                        }}
                                    >
                                        영업 지원하기
                                    </ItemDetailMobileApplyButton>
                                )}
                                {marketerStatusType === MarketerStatusTypes.WAIT && AccessRules.marketer(user) && (
                                    <ItemDetailMobileApplyMarketerStatus>
                                        승인 대기 중 입니다.
                                    </ItemDetailMobileApplyMarketerStatus>
                                )}
                                {marketerStatusType === MarketerStatusTypes.CONFIRM && (
                                    <ItemDetailMobileApplyMarketerStatus className="bg-green-500">
                                        영업 중인 상품입니다.
                                    </ItemDetailMobileApplyMarketerStatus>
                                )}
                                {marketerStatusType === MarketerStatusTypes.BLOCK && (
                                    <ItemDetailMobileApplyMarketerStatus>
                                        영업 불가능한 상품입니다.
                                    </ItemDetailMobileApplyMarketerStatus>
                                )}
                            </React.Fragment>
                        )}
                    </ItemDetailMobileControlBox>
                </ItemDetailMobileControlContainer>
                <ItemDetailIntroduce>
                    {/* <ItemDetailTitleContainer>
                        <ItemDetailThumbnailTitle>
                            {item.detail.title}
                        </ItemDetailThumbnailTitle>
                        <ItemDetailShareContainer>
                            <ShareButton>
                                <ShareIcon/>
                            </ShareButton>
                        </ItemDetailShareContainer>
                    </ItemDetailTitleContainer> */}
                    <ItemDetailThumbDataContainer>
                        {/* 썸네일 이미지 */}
                        <ItemDetailThumbnail>
                            <ImageBase64 imageID={item.detail.thumbnailImageID} duration={true} />
                        </ItemDetailThumbnail>
                        {/* 정보 박스 */}
                        <ItemDetailApplyItem>
                            {isLoading ? (
                                <div className="skeleton flex items-center w-4/5 font-semibold text-xl max-lg:mb-4 lg:mb-8">
                                    loading....
                                </div>
                            ) : (
                                <ItemDetailThumbnailTitle>{item.detail.title}</ItemDetailThumbnailTitle>
                            )}

                            {localStorage.getItem("test") === "true" ? (
                                <ItemDetailApplyContents>
                                    <ItemDetailApplyTypeContentGrid>
                                        <ItemDetailApplyTypeContentBox>
                                            <ItemDetailApplyTypeContentTitle>상품 유형</ItemDetailApplyTypeContentTitle>
                                            <ItemDetailApplyTypeContentValue>
                                                {ItemSalesTypeNames[item.detail.itemSalesType]}
                                            </ItemDetailApplyTypeContentValue>
                                        </ItemDetailApplyTypeContentBox>
                                        <ItemDetailApplyTypeContentBox>
                                            <ItemDetailApplyTypeContentTitle>
                                                마케터 타입
                                            </ItemDetailApplyTypeContentTitle>
                                            <ItemDetailApplyTypeContentValue>
                                                {MarketerTypeNames[item.detail.marketerType]}
                                            </ItemDetailApplyTypeContentValue>
                                        </ItemDetailApplyTypeContentBox>
                                        <ItemDetailApplyTypeContentBox>
                                            <ItemDetailApplyTypeContentTitle>
                                                영업 난이도
                                            </ItemDetailApplyTypeContentTitle>
                                            <ItemDetailApplyTypeContentValue>보통</ItemDetailApplyTypeContentValue>
                                        </ItemDetailApplyTypeContentBox>
                                    </ItemDetailApplyTypeContentGrid>
                                </ItemDetailApplyContents>
                            ) : (
                                <React.Fragment>
                                    <ItemDetailApplyContents>
                                        {isLoading ? (
                                            <div className="skeleton w-40 font-semibold text-base">loading....</div>
                                        ) : (
                                            <React.Fragment>
                                                <ItemDetailApplyItemDescription>
                                                    상품 유형
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {ItemSalesTypeNames[item.detail.itemSalesType]}
                                                </ItemDetailApplyItemContents>
                                            </React.Fragment>
                                        )}
                                    </ItemDetailApplyContents>

                                    <ItemDetailApplyContents>
                                        {isLoading ? (
                                            <div className="skeleton w-40 font-semibold text-base">loading....</div>
                                        ) : (
                                            <React.Fragment>
                                                <ItemDetailApplyItemDescription>
                                                    마케터 타입
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {MarketerTypeNames[item.detail.marketerType]}
                                                </ItemDetailApplyItemContents>
                                            </React.Fragment>
                                        )}
                                    </ItemDetailApplyContents>
                                </React.Fragment>
                            )}
                            <ItemDetailApplyContents>
                                {isLoading ? (
                                    <div className="skeleton w-40 font-semibold text-base">loading....</div>
                                ) : (
                                    <React.Fragment>
                                        <ItemDetailApplyItemDescription>영업 업무</ItemDetailApplyItemDescription>
                                        <ItemDetailApplyItemContents>
                                            {item.detail.missionMessage}
                                        </ItemDetailApplyItemContents>
                                    </React.Fragment>
                                )}
                            </ItemDetailApplyContents>
                            <ItemDetailApplyContents>
                                {isLoading ? (
                                    <div className="skeleton w-40 font-semibold text-base">loading....</div>
                                ) : (
                                    <React.Fragment>
                                        <ItemDetailApplyItemDescription>실적 보상</ItemDetailApplyItemDescription>
                                        <ItemDetailApplyItemContents>
                                            {item.detail.rewardMessage}
                                        </ItemDetailApplyItemContents>
                                    </React.Fragment>
                                )}
                            </ItemDetailApplyContents>
                            {/* <ItemDetailApplyContents>
                                <ItemDetailApplyItemDescription>
                                    기간
                                </ItemDetailApplyItemDescription>
                                <ItemDetailApplyItemContents >
                                    {item.detail.dateStart} ~ {item.detail.dateEnd}
                                </ItemDetailApplyItemContents>
                            </ItemDetailApplyContents> */}
                            {/* 지원하기 버튼 */}
                            <ItemDetailApplyContents className="mt-auto mb-0" mobileHidden>
                                {isLoading ? (
                                    <React.Fragment>
                                        {!AccessRules.brandItemManagement(user) && (
                                            <div className="skeleton flex flex-center w-full py-2 text-lg">
                                                loading....
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {item.id >= 0 &&
                                            AccessRules.brandItemDetailRegist(marketerStatusType, user) && (
                                                <ItemDetailApplyButton
                                                    onClick={() => {
                                                        handleApplyClick()
                                                    }}
                                                >
                                                    지원하기
                                                </ItemDetailApplyButton>
                                            )}
                                        {marketerStatusType === MarketerStatusTypes.WAIT &&
                                            AccessRules.marketer(user) && (
                                                <ItemDetailApplyMarketerStatus>
                                                    승인 대기 중 입니다.
                                                </ItemDetailApplyMarketerStatus>
                                            )}
                                        {marketerStatusType === MarketerStatusTypes.CONFIRM && (
                                            <ItemDetailApplyMarketerStatus className="bg-green-500">
                                                영업 중인 상품입니다.
                                            </ItemDetailApplyMarketerStatus>
                                        )}
                                        {marketerStatusType === MarketerStatusTypes.BLOCK && (
                                            <ItemDetailApplyMarketerStatus>
                                                영업 불가능한 상품입니다.
                                            </ItemDetailApplyMarketerStatus>
                                        )}
                                    </React.Fragment>
                                )}
                            </ItemDetailApplyContents>
                        </ItemDetailApplyItem>
                    </ItemDetailThumbDataContainer>
                </ItemDetailIntroduce>
                <ItemApplyDetail>
                    <ItemDetailNavContainer mainNavHide={mainNavHide} ref={navContainerRef}>
                        <ItemDetailNav>
                            <ItemDetailNavInner isnav={activeSection === 1} onClick={() => handleNavLinkClick(1)}>
                                상품 상세
                            </ItemDetailNavInner>
                            <ItemDetailNavInner isnav={activeSection === 2} onClick={() => handleNavLinkClick(2)}>
                                영업 업무
                            </ItemDetailNavInner>
                            <ItemDetailNavInner isnav={activeSection === 3} onClick={() => handleNavLinkClick(3)}>
                                실적 보상
                            </ItemDetailNavInner>
                            <ItemDetailNavInner isnav={activeSection === 4} onClick={() => handleNavLinkClick(4)}>
                                특약 사항
                            </ItemDetailNavInner>
                        </ItemDetailNav>
                    </ItemDetailNavContainer>
                    <ItemDetailContentContainer ref={DescriptionRef}>
                        <ItemDetailContentTitle>상품 상세</ItemDetailContentTitle>
                        {/* <ReactQuillContent content={descriptionContent} isLoading={isLoading} /> */}
                        <CKEditorContent content={descriptionContent} isLoading={isLoading} />
                        {/* <ItemDetailImageContainer>
                            <ImageBase64 imageID={item.detail.descriptionImageID} useImageHeight={true} />
                        </ItemDetailImageContainer> */}
                    </ItemDetailContentContainer>
                    <ItemDetailContentContainer ref={MissionRef}>
                        <ItemDetailContentTitle>영업 업무</ItemDetailContentTitle>
                        {/* <ReactQuillContent content={missionContent} isLoading={isLoading} /> */}
                        <CKEditorContent content={missionContent} isLoading={isLoading} />
                    </ItemDetailContentContainer>
                    <ItemDetailContentContainer ref={RewardRef}>
                        <ItemDetailContentTitle>실적 보상</ItemDetailContentTitle>
                        {/* <ReactQuillContent content={rewardContent} isLoading={isLoading} /> */}
                        {user.id < 0 ? (
                            <BlindItemContent
                                loginAction={() => {
                                    setLoginShow(true)
                                }}
                            />
                        ) : (
                            <CKEditorContent content={rewardContent} isLoading={isLoading} />
                        )}
                    </ItemDetailContentContainer>
                    <ItemDetailContentContainer ref={ContractRef}>
                        <ItemDetailContentTitle>특약 사항</ItemDetailContentTitle>
                        {/* <ReactQuillContent content={contractContent} isLoading={isLoading} /> */}
                        {user.id < 0 ? (
                            <BlindItemContent
                                loginAction={() => {
                                    setLoginShow(true)
                                }}
                            />
                        ) : (
                            <CKEditorContent content={contractContent} isLoading={isLoading} />
                        )}
                    </ItemDetailContentContainer>
                </ItemApplyDetail>
            </ItemDetailContainer>
        </ItemDetailPageContainer>
    )
}

const BlindItemContent = ({ loginAction }) => {
    return (
        <div className="flex flex-center w-full h-32">
            <div
                className="flex px-8 py-4 bg-slate-100 hover:bg-slate-150 rounded-lg cursor-pointer"
                onClick={() => {
                    loginAction()
                }}
            >
                <span className="text-gray-700">로그인 후 확인 가능합니다</span>
            </div>
        </div>
    )
}
