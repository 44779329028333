export const ItemTypes = {
    ITEM: 0,
    AD: 1,
}
export const AdTypes = {
    FREE: 0,
    LEVEL1: 1,
    LEVEL2: 2,
    LEVEL3: 3,
    LEVEL1M: 4,
    LEVEL2M: 5,
    LEVEL3M: 6,
}
export const AdTypeNames = {
    0: "무료",
    1: "레벨1",
    2: "레벨2",
    3: "레벨3",
    4: "레벨1-",
    5: "레벨2-",
    6: "레벨3-",
}
export const EditStateTypes = {
    NONE: -1,
    WAIT: 0,
    DISABLED: 1,
    EDITED: 2,
    PENDING: 3,
    DONE: 4,
    FAILED: 5,
}
export const EditStateTypeColors = {
    "-1": "#929292",
    0: "#0c66b0",
    1: "#686868",
    2: "#b07c0c",
    3: "#aab00c",
    4: "#22b00c",
    5: "#9c2222",
}

export const EmailDuplicatedTypes = {
    WAIT: 0,
    DONE: 1,
    FAILED: 2,
    DISABLED: 3,
}

export const QueryTypes = {
    NONE: 0,
    CREATE: 1,
    UPDATE: 2,
    CREATE_OR_UPDATE: 3,
    DELETE: 4,
}

export const WriteTypes = {
    READ: 0,
    WRITE: 1,
    SELECT: 2,
    DELETE: 3,
}

export const SortTypes = {
    ASC: 0,
    DESC: 1,
}
