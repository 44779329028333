import ConstCookie from "../../../constants/ConstCookie"
import User from "../../../types/users/User"
import { removeCookie, setCookie } from "../../../utils/Cookie"
import { uploadSign } from "../../api/files"
import { updateUserSign as updateUserSignApi } from "../../api/users"
import ImageFile from "../../../types/files/ImageFile"
import Utils from "../../../utils/Utils"
import ConstLocalStorage from "../../../constants/ConstLocalStorage"
import { UserTypes } from "../../../types/users/UserTypes"

export const loginService = () => {
    setCookie(ConstCookie.AUTH_STATE, "1")
}

export const logoutService = () => {
    removeCookie(ConstCookie.AUTH_STATE)
}

export const getUserData = (response) => {
    const user = new User()
    user.convertByResponse(response)
    return user
}

// [개발,빌드] 일반 로그인 시 사용
// [개발,빌드] 유저정보를 업데이트하기 위해 사용
export const setUserToken = (response) => {
    const user = getUserData(response.user)

    const token = response.token
    if (token !== undefined) {
        setCookie(ConstCookie.USER_REFRESH_TOKEN, token.refresh, true)
        setCookie(ConstCookie.USER_ACCESS_TOKEN, token.access)
        setStorageUserType(token.refresh)
    }

    return user
}

// [개발,빌드] 접속 시 유저정보를 가져오기 위해 사용 (임시 아님)
export const setAccessToken = (response) => {
    setCookie(ConstCookie.USER_ACCESS_TOKEN, response.access)
    setStorageUserType(response.refresh)
}

const setStorageUserType = (refreshToken) => {
    const payload = Utils.decodeJWT(refreshToken)
    if (Object.keys(payload).includes("user_type")) {
        const userType = payload.user_type
        let userTypeCode = ""

        if (userType === UserTypes.MARKETER) {
            userTypeCode = process.env.REACT_APP_USER_TYPE_MARKETER
        } else if (userType === UserTypes.BRAND) {
            userTypeCode = process.env.REACT_APP_USER_TYPE_BRAND
        } else if (userType === UserTypes.STAFF) {
            userTypeCode = process.env.REACT_APP_USER_TYPE_STAFF
        }
        localStorage.setItem(ConstLocalStorage.USER_TYPE, userTypeCode)
    }
}

export const updateUserSign = async (signImage) => {
    let image = new ImageFile()

    // 1. 사인 이미지 업로드
    const resultSign = await uploadSign(signImage)
    if (!resultSign) {
        return image
    }

    // 2. 사인 확정
    const resultConfirm = await updateUserSignApi(resultSign.id)
    if (!resultConfirm) {
        return image
    }
    image = resultSign

    return image
}
