import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { QueryTypes } from "../../../../types/EditorTypes"
import {
    PerformUpdateColumnLayout,
    PerformUpdateColumnTitle,
    PerformUpdateControlBox,
    PerformUpdateLayout,
    PerformUpdateValueList,
    PerformValueInputTitle,
} from "../../perform/MyPagePerformStyle"
import { StaffUserPointUpdateInput, StaffUserPointUpdateLayout, StaffUserPointUpdateTextArea } from "./StaffUserStyle"
import { DeleteButton, SaveButton } from "../../item/brand/ItemInputFormStyle"
import StaffUserPointMarketerSearch from "./StaffUserPointMarketerSearch"
import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../../types/DataEnum"
import { createUserRecommander, deleteUserRecommander, updateUserRecommander } from "../../../../apis/api/users"

export default function StaffUserPointUpdate({ recommander, getCurrentRecommanders, close }) {
    const [writeType, setWriteType] = useState(QueryTypes.CREATE)

    const [marketerID, setMarketerID] = useState(-1)
    const [marketerInitName, setMarketerInitName] = useState("")
    const [keyword, setKeyword] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [pointMain, setPointMain] = useState(0)
    const [pointSub, setPointSub] = useState(0)
    const [description, setDescription] = useState("")

    useEffect(() => {
        const _writeType = recommander.id >= 0 ? QueryTypes.UPDATE : QueryTypes.CREATE
        setWriteType(_writeType)

        if (_writeType === QueryTypes.UPDATE) {
            updateState()
        }
    }, [recommander])

    const updateState = () => {
        setMarketerID(recommander.user.id)
        setMarketerInitName(recommander.user.name)
        setKeyword(recommander.keyword)
        setDateStart(recommander.dateStart)
        setDateEnd(recommander.dateEnd)
        setPointMain(recommander.pointMain)
        setPointSub(recommander.pointSub)
        setDescription(recommander.description)
    }

    const handleSave = async () => {
        if (marketerID < 0) {
            alert("추천인을 선택해주세요.")
            return
        }
        if (Utils.isStringNullOrEmpty(dateStart) || Utils.isStringNullOrEmpty(dateEnd)) {
            alert("적용기간을 입력해주세요.")
            return
        }

        let data = {}
        data["user_id"] = marketerID
        data["keyword"] = keyword
        data["date_start"] = dateStart
        data["date_end"] = dateEnd
        data["point_main"] = pointMain
        data["point_sub"] = pointSub
        data["description"] = description

        let bResult = false
        if (writeType === QueryTypes.CREATE) {
            const result = await createUserRecommander(data)

            bResult = result.id >= 0
        } else {
            const result = await updateUserRecommander(recommander.id, data)

            bResult = result.id >= 0
        }

        if (bResult) {
            alert("저장되었습니다.")
            getCurrentRecommanders()
            close()
        }
    }

    const handleDelete = async () => {
        if (!confirm("삭제하시겠습니까?")) {
            return
        }

        const result = await deleteUserRecommander(recommander.id)

        if (result) {
            alert("삭제되었습니다.")
            getCurrentRecommanders()
            close()
        }
    }

    return (
        <StaffUserPointUpdateLayout>
            <PerformValueInputTitle>
                <span>실적 {writeType === QueryTypes.UPDATE ? "수정" : "등록"}</span>
            </PerformValueInputTitle>
            <PerformUpdateValueList>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>회원명</PerformUpdateColumnTitle>
                    <StaffUserPointMarketerSearch
                        recommander={recommander}
                        marketerID={marketerID}
                        setMarketerID={setMarketerID}
                        initSearch={recommander.user.name}
                    />
                </PerformUpdateColumnLayout>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>추천인 검색명</PerformUpdateColumnTitle>
                    <StaffUserPointUpdateInput
                        type="text"
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value)
                        }}
                    />
                </PerformUpdateColumnLayout>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>적용기간</PerformUpdateColumnTitle>
                    <div className="flex items-center w-full space-x-2">
                        <StaffUserPointUpdateInput
                            type={"date"}
                            value={dateStart}
                            onChange={(e) => {
                                setDateStart(e.target.value)
                            }}
                        />
                        <span>~</span>
                        <StaffUserPointUpdateInput
                            type={"date"}
                            value={dateEnd}
                            onChange={(e) => {
                                setDateEnd(e.target.value)
                            }}
                        />
                    </div>
                </PerformUpdateColumnLayout>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>적립포인트 (추천받는 사람)</PerformUpdateColumnTitle>
                    <StaffUserPointUpdateInput
                        type="text"
                        value={pointMain}
                        onChange={(e) => {
                            setPointMain(Utils.textFormatInput(e.target.value, TextFormats.NUMBER_ONLY))
                        }}
                    />
                </PerformUpdateColumnLayout>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>적립포인트 (추천하는 사람)</PerformUpdateColumnTitle>
                    <StaffUserPointUpdateInput
                        type="text"
                        value={pointSub}
                        onChange={(e) => {
                            setPointSub(Utils.textFormatInput(e.target.value, TextFormats.NUMBER_ONLY))
                        }}
                    />
                </PerformUpdateColumnLayout>
                <PerformUpdateColumnLayout>
                    <PerformUpdateColumnTitle>설명</PerformUpdateColumnTitle>
                    <StaffUserPointUpdateTextArea
                        className="h-32"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />
                </PerformUpdateColumnLayout>
            </PerformUpdateValueList>
            <PerformUpdateControlBox className="h-14 px-2 py-3">
                {writeType === QueryTypes.UPDATE ? (
                    <DeleteButton
                        onClick={() => {
                            handleDelete()
                        }}
                    >
                        실적 삭제
                    </DeleteButton>
                ) : (
                    <p></p>
                )}
                <SaveButton
                    onClick={() => {
                        handleSave()
                    }}
                >
                    {writeType === QueryTypes.UPDATE ? "저장" : "등록"}
                </SaveButton>
            </PerformUpdateControlBox>
        </StaffUserPointUpdateLayout>
    )
}
