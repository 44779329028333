import { CouponApplicationStatusTypes } from "./PointTypes"

export default class {
    constructor() {
        this.id = -1
        this.resCode = "" // 결과코드 (코드목록 참조)
        this.resMsg = "" // 결과메시지 (코드목록 참조)
        this.brandNm = "" // 브랜드명
        this.cnsmPriceAmt = "" // 정상판매단가
        this.correcDtm = "" // 변경일자
        this.goodsCd = "" // 상품코드
        this.goodsNm = "" // 상품명
        this.mmsBrandThumImg = "" // 브랜드 썸네일이미지
        this.recverTelNo = "" // 수신자번호
        this.sellPriceAmt = "" // 실판매단가
        this.remailAmt = "" // 잔액 정보
        this.sendBasicCd = "" // 기본번호
        this.sendRstCd = "" // 거래번호
        this.sendRstMsg = "" // 발송상태코드
        this.sendStatusCd = "" // 발송상태명
        this.senderTelNo = "" // 발신자번호
        this.validPrdEndDt = "" // 유효기간만료일
        this.pinStatusCd = "" // 핀상태 코드
        this.pinStatusNm = "" // 핀상태 코드 이름

        this.created = ""
        this.updated = ""
    }

    formatValidPrdEndDt() {
        const dateTimeStr = this.validPrdEndDt
        if (dateTimeStr.length < 14) {
            return dateTimeStr
        }

        // 연도, 월, 일, 시, 분, 초를 추출
        const year = dateTimeStr.slice(0, 4)
        const month = dateTimeStr.slice(4, 6)
        const day = dateTimeStr.slice(6, 8)
        const hour = dateTimeStr.slice(8, 10)
        const minute = dateTimeStr.slice(10, 12)
        const second = dateTimeStr.slice(12, 14)

        // 원하는 형식으로 조합
        const formattedDateTime = `${year}년${month}월${day}일 ${hour}:${minute}:${second}`

        return formattedDateTime
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.resCode = response["res_code"]
        this.resMsg = response["res_msg"]
        this.brandNm = response["brand_nm"]
        this.cnsmPriceAmt = response["cnsm_price_amt"]
        this.correcDtm = response["correc_dtm"]
        this.goodsCd = response["goods_cd"]
        this.goodsNm = response["goods_nm"]
        this.mmsBrandThumImg = response["mms_brand_thum_img"]
        this.recverTelNo = response["recver_tel_no"]
        this.sellPriceAmt = response["sell_price_amt"]
        this.remailAmt = response["remail_amt"]
        this.sendBasicCd = response["send_basic_cd"]
        this.sendRstCd = response["send_rst_cd"]
        this.sendRstMsg = response["send_rst_msg"]
        this.sendStatusCd = response["send_status_cd"]
        this.senderTelNo = response["sender_tel_no"]
        this.validPrdEndDt = response["valid_prd_end_dt"]
        this.pinStatusCd = response["pin_status_cd"]
        this.pinStatusNm = response["pin_status_nm"]

        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertByGiftishow(response) {
        this.code = response["code"]
        this.message = response["message"]
        this.goodsNo = response["goodsNo"]
        this.goodsCode = response["goodsCode"]
        this.goodsName = response["goodsName"]
        this.brandCode = response["brandCode"]
        this.brandName = response["brandName"]
        this.content = response["content"]
        this.contentAddDesc = response["contentAddDesc"]
        this.goodsTypeCd = response["goodsTypeCd"]
        this.goodstypeNm = response["goodstypeNm"]
        this.goodsImgS = response["goodsImgS"]
        this.goodsImgB = response["goodsImgB"]
        this.goodsDescImgWeb = response["goodsDescImgWeb"]
        this.brandIconImg = response["brandIconImg"]
        this.mmsGoodsImg = response["mmsGoodsImg"]
        this.realPrice = response["realPrice"]
        this.salePrice = response["salePrice"]
        this.categoryName1 = response["categoryName1"]
        this.rmIdBuyCntFlagCd = response["rmIdBuyCntFlagCd"]
        this.discountRate = response["discountRate"]
        this.goldPrice = response["goldPrice"]
        this.saleDiscountPrice = response["saleDiscountPrice"]
        this.discountPrice = response["discountPrice"]
        this.vipPrice = response["vipPrice"]
        this.platinumPrice = response["platinumPrice"]
        this.vipDiscountRate = response["vipDiscountRate"]
        this.platinumDiscountRate = response["platinumDiscountRate"]
        this.goldDiscountRate = response["goldDiscountRate"]
        this.saleDiscountRate = response["saleDiscountRate"]
        this.goodsStateCd = response["goodsStateCd"]
        this.sellDisCntCost = response["sellDisCntCost"]
        this.sellDisRate = response["sellDisRate"]
        this.rmCntFlag = response["rmCntFlag"]
        this.goodsTypeDtlNm = response["goodsTypeDtlNm"]
        this.saleDateFlagCd = response["saleDateFlagCd"]
        this.saleDateFlag = response["saleDateFlag"]
        this.mmsReserveFlag = response["mmsReserveFlag"]
        this.categorySeq1 = response["categorySeq1"]
        this.limitday = response["limitday"]
        this.affiliate = response["affiliate"]
    }
}
