import { CouponApplicationStatusTypes } from "./PointTypes"

export default class {
    constructor() {
        this.id = -1
        this.mdCode = "" // 상품 번호
        this.goodsNo = "" // 상품 번호
        this.goodsCode = "" // 상품 아이디
        this.goodsName = "" // 상품명
        this.brandCode = "" // 브랜드 코드
        this.brandName = "" // 브랜드 명
        this.content = "" // 상품설명
        this.contentAddDesc = "" // 상품추가설명
        this.goodsTypeCd = "" // 상품유형코드
        this.goodsTypeNm = "" // 상품유형명
        this.goodsImgS = "" // 상품이미지 소(250X250)
        this.goodsImgB = "" // 상품이미지 대(500X500)
        this.goodsDescImgWeb = "" // 상품 설명 이미지
        this.brandIconImg = "" // 브랜드 아이콘 이미지
        this.mmsGoodsImg = "" // 상품 MMS 이미지
        this.realPrice = "" // 실판매가격(등급별 할인율 적용금액)
        this.salePrice = "" // 판매가격
        this.categoryName1 = "" // 전시카테고리명1
        this.rmIdBuyCntFlagCd = "" // ID당구매가능수량설정코드
        this.discountRate = "" // 최종판매할인률
        this.goldPrice = "" // 골드가격
        this.saleDiscountPrice = "" // 판매가격
        this.discountPrice = "" // 최종판매가격(원단위절삭)
        this.vipPrice = "" // VIP가격
        this.platinumPrice = "" // 플래티넘 가격
        this.vipDiscountRate = "" // VIP 할인률
        this.platinumDiscountRate = "" // 플래티넘 할인률
        this.goldDiscountRate = "" // 골드 할인률
        this.saleDiscountRate = "" // 할인률
        this.goodsStateCd = "" // 상품상태코드 (판매중: SALE, 판매중지: SUS)
        this.sellDisCntCost = "" // 할인금액
        this.sellDisRate = "" // 공급할인
        this.rmCntFlag = "" // 총판매수량설정여부
        this.goodsTypeDtlNm = "" // 상세상품유형명
        this.saleDateFlagCd = "" // 판매일시 설정코드
        this.saleDateFlag = "" // 판매일시설정여부
        this.mmsReserveFlag = "" // 예약발송노출여부
        this.categorySeq1 = "" // 전시카테고리1
        this.limitDay = "" // 유효기간(일자)
        this.affiliate = "" // 교환처명

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.mdCode = response["md_code"]
        this.goodsNo = response["goods_no"]
        this.goodsCode = response["goods_code"]
        this.goodsName = response["goods_name"]
        this.brandCode = response["brand_code"]
        this.brandName = response["brand_name"]
        this.content = response["content"]
        this.contentAddDesc = response["content_add_desc"]
        this.goodsTypeCd = response["goods_type_cd"]
        this.goodsTypeNm = response["goods_type_nm"]
        this.goodsImgS = response["goods_img_s"]
        this.goodsImgB = response["goods_img_b"]
        this.goodsDescImgWeb = response["goods_desc_img_web"]
        this.brandIconImg = response["brand_icon_img"]
        this.mmsGoodsImg = response["mms_goods_img"]
        this.realPrice = response["real_price"]
        this.salePrice = response["sale_price"]
        this.categoryName1 = response["category_name1"]
        this.rmIdBuyCntFlagCd = response["rm_id_buy_cnt_flag_cd"]
        this.discountRate = response["discount_rate"]
        this.goldPrice = response["gold_price"]
        this.saleDiscountPrice = response["sale_discount_price"]
        this.discountPrice = response["discount_price"]
        this.vipPrice = response["vip_price"]
        this.platinumPrice = response["platinum_price"]
        this.vipDiscountRate = response["vip_discount_rate"]
        this.platinumDiscountRate = response["platinum_discount_rate"]
        this.goldDiscountRate = response["gold_discount_rate"]
        this.saleDiscountRate = response["sale_discount_rate"]
        this.goodsStateCd = response["goods_state_cd"]
        this.sellDisCntCost = response["sell_dis_cnt_cost"]
        this.sellDisRate = response["sell_dis_rate"]
        this.rmCntFlag = response["rm_cnt_flag"]
        this.goodsTypeDtlNm = response["goods_type_dtl_nm"]
        this.saleDateFlagCd = response["sale_date_flag_cd"]
        this.saleDateFlag = response["sale_date_flag"]
        this.mmsReserveFlag = response["mms_reserve_flag"]
        this.categorySeq1 = response["category_seq1"]
        this.limitDay = response["limit_day"]
        this.affiliate = response["affiliate"]

        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertByGiftishow(response) {
        this.code = response["code"]
        this.message = response["message"]
        this.goodsNo = response["goodsNo"]
        this.goodsCode = response["goodsCode"]
        this.goodsName = response["goodsName"]
        this.brandCode = response["brandCode"]
        this.brandName = response["brandName"]
        this.content = response["content"]
        this.contentAddDesc = response["contentAddDesc"]
        this.goodsTypeCd = response["goodsTypeCd"]
        this.goodstypeNm = response["goodstypeNm"]
        this.goodsImgS = response["goodsImgS"]
        this.goodsImgB = response["goodsImgB"]
        this.goodsDescImgWeb = response["goodsDescImgWeb"]
        this.brandIconImg = response["brandIconImg"]
        this.mmsGoodsImg = response["mmsGoodsImg"]
        this.realPrice = response["realPrice"]
        this.salePrice = response["salePrice"]
        this.categoryName1 = response["categoryName1"]
        this.rmIdBuyCntFlagCd = response["rmIdBuyCntFlagCd"]
        this.discountRate = response["discountRate"]
        this.goldPrice = response["goldPrice"]
        this.saleDiscountPrice = response["saleDiscountPrice"]
        this.discountPrice = response["discountPrice"]
        this.vipPrice = response["vipPrice"]
        this.platinumPrice = response["platinumPrice"]
        this.vipDiscountRate = response["vipDiscountRate"]
        this.platinumDiscountRate = response["platinumDiscountRate"]
        this.goldDiscountRate = response["goldDiscountRate"]
        this.saleDiscountRate = response["saleDiscountRate"]
        this.goodsStateCd = response["goodsStateCd"]
        this.sellDisCntCost = response["sellDisCntCost"]
        this.sellDisRate = response["sellDisRate"]
        this.rmCntFlag = response["rmCntFlag"]
        this.goodsTypeDtlNm = response["goodsTypeDtlNm"]
        this.saleDateFlagCd = response["saleDateFlagCd"]
        this.saleDateFlag = response["saleDateFlag"]
        this.mmsReserveFlag = response["mmsReserveFlag"]
        this.categorySeq1 = response["categorySeq1"]
        this.limitday = response["limitday"]
        this.affiliate = response["affiliate"]
    }
}
