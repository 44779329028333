import tw from "tailwind-styled-components"
import { FileEarmarkArrowUp } from "@styled-icons/bootstrap/FileEarmarkArrowUp"
import { Detail } from "@styled-icons/boxicons-solid/Detail"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { FilterAlt } from "@styled-icons/boxicons-regular/FilterAlt"
import { Filter } from "@styled-icons/bootstrap/Filter"
import { BoxMaxMin } from "../../../layouts/Box"
import {
    MyPageContentHeaderColor,
    MyPageContentHeaderHeight,
    MyPageContentRowHeight,
} from "../MyPageMainStyleParameter"
import { ContentTopSize, ListControlItem, MyPageInputModalLayout } from "../MyPageMainStyle"
import { FileExcel2 } from "@styled-icons/remix-line/FileExcel2"
import { History } from "@styled-icons/fluentui-system-regular/History"
import { Crown } from "@styled-icons/boxicons-regular/Crown"

export const CrownIcon = tw(Crown)`
    h-full mb-1
`

export const ExcelIcon = tw(FileExcel2)`
    h-6 p-0.5 text-green-50
`

export const HistoryIcon = tw(History)`
    h-full
`

export const PerformCon = tw.div`
    w-full h-full flex items-center flex-col px-10 py-2
`

export const PerformConInner = tw.div`
    lg:absolute
    w-full lg:h-full flex flex-col pb-1
    bg-white 
    scroll-transparent scroll-overlay thumb-lg
`

export const PerformListControlBox = tw(ContentTopSize)`
    flex z-10
    max-lg:w-full max-lg:h-[3rem] max-lg:min-h-[3rem]
    max-lg:flex-col max-lg:items-center justify-between
    lg:flex-col
    
    xl:flex-row xl:items-center
`

export const PerformListControlSection = tw.div`
    max-lg:flex lg:flex items-center lg:my-1
    max-lg:h-9
    ${(props) => props.last && "ml-auto"}
`

export const PerformListControlSectionLast = tw.div`
    h-full w-full 
    max-lg:items-stretch max-lg lg:items-center max-lg:flex-1 
    flex lg:my-1
    ${(props) => props.last && "ml-auto"}
`

export const PerformTableContainer = tw.div`
    flex flex-col justify-between w-full lg:h-full
    bg-white drop-shadow-lg rounded-md overflow-hidden
`

export const PerformTableHeaderBox = tw.div`
    w-full flex sticky top-0 z-20 bg-white
    ${() => MyPageContentHeaderHeight}
`

export const PerformTableHeader = tw.div`
    h-full flex flex-col justify-center items-center relative
    ${() => MyPageContentHeaderColor}
`
export const PerformTableHeaderFiltered = tw(Filter)`
    absolute w-4 h-4 text-gray-200
    left-0.5 top-0.5
    rounded-full
`

export const PerformTableColumnCellLayout = tw.div`
    sticky top-0
    flex-shrink-0 ${(props) => `w-[${props.column.widthCalculated}px]`}
    ${(props) =>
        props.idx <= props.frozenColumn || props.idx === props.columnLength - 1
            ? `
        z-20 left-[${props.column.columnLeft}px]
        bg-white
    `
            : `
    `}
`

export const PerformTableSort = tw.div`
    bg-white w-full flex flex-col items-center
`

export const PerformTableSortTop = tw.div`
    flex items-center justify-between w-full max-lg:h-8 lg:h-10 bg-white
`
export const PerformTableFilterBox = tw.div`
    flex items-center w-full h-full px-1 py-0.5 overflow-x-scroll scroll-transparent scroll-overlay
`

export const PerformTableSortBottom = tw.div`
     w-full ${(props) => (props.sortVisible ? "visible h-16" : "invisible h-0")}   duration-300
`

export const PerformTableRowLayout = tw.div`
    border-b flex items-center w-full relative
    max-lg:min-h-[2.5rem] lg:min-h-[3rem]
    text-sm
`

export const PerformValueLayout = tw.div`
    h-full py-2 flex justify-center items-center bg-white
`
export const PerformTableValueCellLayout = tw.div`
    sticky top-0
    flex-shrink-0 ${(props) => `w-[${props.column.widthCalculated}px]`} py-2
    ${(props) =>
        props.idx <= props.frozenColumn || props.idx === props.columnLength - 1
            ? `
        z-10 left-[${props.column.columnLeft}px]
        bg-white
    `
            : `
    `}
`

export const PerformValueBox = tw.div`
    w-full h-8 flex flex-center px-2
`
export const PerformTableSearchBox = tw(ListControlItem)`
    justify-between w-32
`

export const PerformTableSearch = tw.input`
w-full px-2
bg-transparent focus:outline-none
`
export const PerformTableSearchButton = tw.button`
flex flex-center h-7 px-2 py-1
text-nightblue-600 hover:text-nightblue-400 font-bold
rounded-lg
`
export const PerformTableSearchIcon = tw(Search)`
h-full
`

export const PerformTableFilterButton = tw.button`
flex flex-center h-9 p-2
bg-white
rounded-md drop-shadow
`
export const PerformTableFilterIcon = tw(FilterAlt)`
h-full text-nightblue-600 hover:text-nightblue-400
`

export const PerformSortOptionButton = tw.button`
    flex flex-center mx-1 max-lg:w-16 lg:w-20
    text-black max-lg:text-sm lg:text-base
    rounded-md border border-gray-500
`

export const PerformExcelButton = tw(PerformSortOptionButton)`
    text-white
    bg-green-600 border-transparent
    disabled:bg-gray-500
`

export const PerformAddButton = tw(PerformSortOptionButton)`
    text-white
    bg-nightblue-400 border-transparent
    disabled:bg-gray-500
`
export const PerformBatchUpdateButton = tw(PerformSortOptionButton)`
    text-white
    bg-gray-500 border-transparent
    disabled:bg-gray-500
`

export const PerformBatchUpdateSubmitButton = tw(PerformSortOptionButton)`
    text-white
    bg-nightblue-400 border-transparent
`

export const PerformTableSortInput = tw.input`
    w-44 h-10 rounded-xl mx-4
`

export const PerformTableSortSelect = tw.select`
    w-44 h-10 rounded-xl mx-4 border
`

export const PerformFileIcon = tw(FileEarmarkArrowUp)`
    h-4 mr-1
`

export const PerformPreviewButton = tw.button`
    w-full max-w-[4rem] h-full flex flex-center
    bg-nightblue-200 p-1
    rounded-md
`

export const PerformStatus = tw.button`
    w-[80px] h-full flex flex-center
    rounded-md
`

export const PerformTextDetailButton = tw.button`
    absolute right-0 w-5 h-5 flex flex-center
    text-gray-600
    invisible group-hover:visible opacity-0 group-hover:opacity-100
    duration-100
`

export const PerformTextDetailIcon = tw(Detail)`
    w-4 h-4 bg-white
`

export const PerformDateCalcContainer = tw.div`
    flex items-center h-full px-1
`

export const PerformHistoryContainer = tw.div`
    flex justify-center h-5 py-0.25 text-nightblue-600 mr-1
`

export const PerformDateCalc = tw.span`
    text-sm truncate
`

export const PerformTextBox = tw.div`
flex flex-center w-full py-1
${(props) => props.isButtonCell && "rounded-lg lg:hover:bg-slate-100 cursor-pointer"}
`

export const PerformValueInputLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen max-md:w-136 max-md:h-[75vh] md:w-156 md:h-128 lg:min-h-[75vh]
`

export const PerformValueInputTitle = tw.div`
    flex items-center 
    w-full lg:px-2 pt-1 pb-2 max-sm:[&>span]:text-lg sm:[&>span]:text-xl [&>span]:font-semibold
    select-none
`

export const PerformValueFileDownloadZip = tw.button`
    py-1 px-2
    text-nightblue-500 font-normal text-base
`

export const PerformValueInputSubTitle = tw.div`
    flex w-full px-2 py-1 mt-2 mb-0 max-md:[&>span]:text-base md:[&>span]:text-lg [&>span]:font-medium
`

export const PerformUpdateLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen sm:w-[40rem] max-lg:h-full lg:h-[90vh]
    max-sm:rounded-none max-md:rounded-xl md:rounded-xl
`
export const PerformUpdateColumnLayout = tw.div`
    flex flex-col max-sm:px-0 sm:px-2 py-4 border-b border-gray-200
    ${(props) => !props.readOnly && "hover:border-gray-200"}
`
export const PerformUpdateColumnTitle = tw.span`
    flex items-center justify-between px-1 mb-2 max-md:text-base md:text-lg font-semibold
`

export const PerformUpdateValueList = tw.div`
    flex flex-col w-full h-full sm:px-2
    overflow-y-auto scroll-transparent
`
export const PerformUpdateValueBox = tw.div`
    flex flex-col w-full
`

export const PerformUpdateControlBox = tw.div`
    flex justify-between flex-shrink-0 w-full py-2
`

export const PerformStatusValueBox = tw(PerformUpdateValueBox)`
    flex-col
`
export const PerformFileValueBox = tw(PerformUpdateValueBox)`
    flex-col max-lg:max-h-[22rem] lg:h-128
`
export const PerformOptionValueBox = tw(PerformUpdateValueBox)`
    flex-col
`
export const PerformDateValueBox = tw(PerformUpdateValueBox)`
    flex-col
`

export const MarketerContentGroupName = tw.span`
    px-1 truncate max-sm:text-[0.6rem] sm:text-xs sm:mb-1 text-nightblue-800
`
export const MarketerNameContainer = tw.div`
    flex h-full items-center
`

export const CrownContainer = tw.div`
    flex flex-center max-lg:h-3 lg:h-4 mt-0.5
    text-yellow-400 
`
