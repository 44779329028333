import React, { useEffect, useState } from "react"
import {
    BrandIcon,
    CalIcon,
    GroupIcon,
    NotiFicationIcon,
    HomeIcon,
    ItemIcon,
    ItemManageBoxIcon,
    ItemManageIcon,
    Logo_img,
    MarketerIcon,
    MyPageCon,
    MyPageInner,
    NavCon,
    NavFoldBtn,
    NavFoldIcon,
    NavInnerCon,
    NavLinkStyle,
    NoticeIcon,
    PerformIcon,
    VerticalLinkText,
    VerticalNavBoxcon,
    VerticalNavcon,
    VerticalNavLink,
    VerticalNavCategoryTitle,
    MyPageVerticalNavIconContainer,
    VerticalNavCategoryBox,
    VerticalNavCategoryContent,
    VerticalNavLinkHead,
    VerticalSubNavLink,
    VerticalSubNavLinkHead,
    VerticalSubNavCategoryContent,
    VerticalNavBr,
    ItemAdBoxIcon,
    AdvertisementIcon,
    PointIcon,
} from "./MyPageNavStyle"
import { PmsNavState, PmsOfficeNavState } from "../../types/DataEnum"
import { Link } from "react-router-dom"
import { ImageBase64 } from "../../components/ImageContainer"
import { useUserStore } from "../../zustand/store"
import { UserTypes } from "../../types/users/UserTypes"

function MyPageNavVerticalOffice({
    folded,
    isSelected,
    selectedItem,
    setNeedload,
    needload,
    officeNavState,
    navpathname,
}) {
    const { userType } = useUserStore((state) => state)

    // to={(selectedItem.id >= 0) ? `/mypage/cal/${selectedItem.id}` : `/mypage/perform`}
    return (
        <div className="flex flex-col w-full pt-2">
            {/* <div className="flex items-center justify-center w-full mt-3 mb-2 px-0.5 text-white">
                <div className="h-9 w-9 rounded-md bg-white overflow-hidden flex-shrink-0">
                    <ImageBase64 imageID={selectedItem.detail.thumbnailImageID} />
                </div>
                <VerticalLinkText isfold={folded} className="px-0 ml-2" title={selectedItem.detail.title}>
                    <span className={`${isSelected ? "text-nightblue-100" : "text-slate-400"}`}>
                        {selectedItem.id >= 0 ? selectedItem.detail.title : "상품 선택"}
                    </span>
                </VerticalLinkText>
            </div> */}

            <Link
                to={selectedItem.id >= 0 ? `/mypage/perform/${selectedItem.id}` : `/mypage/perform`}
                onClick={() => {
                    setNeedload(!needload)
                }}
            >
                <VerticalSubNavLink
                    is_selected={isSelected}
                    is_office={officeNavState === PmsOfficeNavState.PERFORM}
                    isfold={folded}
                >
                    <VerticalSubNavLinkHead>
                        <MyPageVerticalNavIconContainer>
                            <PerformIcon is_selected={navpathname === PmsNavState.PERFORM} />
                        </MyPageVerticalNavIconContainer>
                        <VerticalLinkText is_selected={navpathname === PmsNavState.PERFORM} isfold={folded}>
                            실적 관리
                        </VerticalLinkText>
                    </VerticalSubNavLinkHead>
                </VerticalSubNavLink>
            </Link>
            {userType === UserTypes.MARKETER ? (
                <React.Fragment>
                    <Link
                        to={selectedItem.id >= 0 ? `/mypage/perform/group/${selectedItem.id}` : `/mypage/perform`}
                        onClick={() => {
                            setNeedload(!needload)
                        }}
                    >
                        <VerticalSubNavLink
                            is_selected={isSelected}
                            is_office={officeNavState === PmsOfficeNavState.GROUP}
                            isfold={folded}
                        >
                            <VerticalSubNavLinkHead>
                                <MyPageVerticalNavIconContainer>
                                    <GroupIcon is_selected={navpathname === PmsNavState.PERFORM} />
                                </MyPageVerticalNavIconContainer>
                                <VerticalLinkText is_selected={navpathname === PmsNavState.PERFORM} isfold={folded}>
                                    그룹 관리
                                </VerticalLinkText>
                            </VerticalSubNavLinkHead>
                        </VerticalSubNavLink>
                    </Link>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Link
                        to={selectedItem.id >= 0 ? `/mypage/perform/marketer/${selectedItem.id}` : `/mypage/perform`}
                        onClick={() => {
                            setNeedload(!needload)
                        }}
                    >
                        <VerticalSubNavLink
                            is_selected={isSelected}
                            is_office={officeNavState === PmsOfficeNavState.MARKETER}
                            isfold={folded}
                        >
                            <VerticalSubNavLinkHead>
                                <MyPageVerticalNavIconContainer>
                                    <GroupIcon is_selected={navpathname === PmsNavState.PERFORM} />
                                </MyPageVerticalNavIconContainer>
                                <VerticalLinkText is_selected={navpathname === PmsNavState.PERFORM} isfold={folded}>
                                    마케터 관리
                                </VerticalLinkText>
                            </VerticalSubNavLinkHead>
                        </VerticalSubNavLink>
                    </Link>
                    <Link
                        to={selectedItem.id >= 0 ? `/mypage/perform/cal/${selectedItem.id}` : `/mypage/perform`}
                        onClick={() => {
                            setNeedload(!needload)
                        }}
                    >
                        <VerticalSubNavLink
                            is_selected={isSelected}
                            is_office={officeNavState === PmsOfficeNavState.CAL}
                            isfold={folded}
                        >
                            <MyPageVerticalNavIconContainer>
                                <CalIcon is_selected={navpathname === PmsNavState.PERFORM} />
                            </MyPageVerticalNavIconContainer>
                            <VerticalLinkText is_selected={navpathname === PmsNavState.PERFORM} isfold={folded}>
                                정산 관리
                            </VerticalLinkText>
                        </VerticalSubNavLink>
                    </Link>
                </React.Fragment>
            )}

            <Link
                to={selectedItem.id >= 0 ? `/mypage/perform/notice/${selectedItem.id}` : `/mypage/perform`}
                onClick={() => {
                    setNeedload(!needload)
                }}
            >
                <VerticalSubNavLink
                    is_selected={isSelected}
                    is_office={officeNavState === PmsOfficeNavState.NOTICE}
                    isfold={folded}
                >
                    <VerticalSubNavLinkHead>
                        <MyPageVerticalNavIconContainer>
                            <NotiFicationIcon is_selected={navpathname === PmsNavState.PERFORM} />
                        </MyPageVerticalNavIconContainer>
                        <VerticalLinkText is_selected={navpathname === PmsNavState.PERFORM} isfold={folded}>
                            공지사항
                        </VerticalLinkText>
                    </VerticalSubNavLinkHead>
                </VerticalSubNavLink>
            </Link>
        </div>
    )
}

export function MyPageNavContents({ folded, pmsNavState, selectedItem, officeNavState }) {
    const [navpathname, setNavpathname] = useState("")
    const [needload, setNeedload] = useState(true)
    const { userType } = useUserStore((state) => state)
    useEffect(() => {
        setNavpathname(document.location.pathname)
    }, [needload])

    return (
        <React.Fragment>
            <VerticalNavcon isfold={folded}>
                <div className="flex flex-col w-full mt-2">
                    <VerticalNavCategoryBox>
                        {/* <div className='w-full h-14 flex flex-center text-white'>
                            <VerticalNavCategoryTitle folded={folded}>
                                <span>
                                    {userType === UserTypes.BRAND ? "브랜드 mypage" : "마케터 mypage"}
                                </span>
                            </VerticalNavCategoryTitle>
                        </div> */}
                        <VerticalNavCategoryContent>
                            {/* 대시보드 */}
                            {/* <Link to={"/mypage"} onClick={() => {setNeedload(!needload)}}>
                                <VerticalNavLink is_selected={pmsNavState===PmsNavState.HOME} isfold={folded}>
                                <VerticalNavLinkHead>
                                    <MyPageVerticalNavIconContainer>
                                        <HomeIcon is_selected={pmsNavState===PmsNavState.HOME}/>
                                    </MyPageVerticalNavIconContainer>    
                                        <VerticalLinkText is_selected={pmsNavState===PmsNavState.HOME} isfold={folded}>
                                            홈
                                        </VerticalLinkText>
                                    </VerticalNavLink>
                                </VerticalNavLinkHead>
                            </Link> */}
                            {/* 영업 아이템 */}
                            {/* <Link
                                to={"/mypage/item"}
                                onClick={() => {
                                    setNeedload(!needload)
                                }}
                            >
                                <VerticalNavLink is_selected={pmsNavState === PmsNavState.ITEM} isfold={folded}>
                                    <VerticalNavLinkHead>
                                        <MyPageVerticalNavIconContainer>
                                            <ItemManageIcon is_selected={pmsNavState === PmsNavState.ITEM} />
                                        </MyPageVerticalNavIconContainer>
                                        <VerticalLinkText
                                            is_selected={pmsNavState === PmsNavState.ITEM}
                                            isfold={folded}
                                        >
                                            영업 아이템
                                        </VerticalLinkText>
                                    </VerticalNavLinkHead>
                                </VerticalNavLink>
                            </Link> */}
                            {/* 상품 관리 */}
                            <Link
                                to={"/mypage/item"}
                                onClick={() => {
                                    setNeedload(!needload)
                                }}
                            >
                                <VerticalNavLink
                                    is_selected={pmsNavState === PmsNavState.PERFORM}
                                    isfold={folded}
                                    className="pt-1"
                                >
                                    <VerticalNavLinkHead>
                                        <MyPageVerticalNavIconContainer>
                                            <ItemManageIcon is_selected={pmsNavState === PmsNavState.ITEM} />
                                        </MyPageVerticalNavIconContainer>
                                        <VerticalLinkText
                                            is_selected={pmsNavState === PmsNavState.ITEM}
                                            isfold={folded}
                                        >
                                            영업 아이템
                                        </VerticalLinkText>
                                    </VerticalNavLinkHead>
                                    {selectedItem.id >= -2 && (
                                        <VerticalSubNavCategoryContent isfold={folded}>
                                            <MyPageNavVerticalOffice
                                                folded={folded}
                                                isSelected={pmsNavState === PmsNavState.PERFORM}
                                                selectedItem={selectedItem}
                                                setNeedload={setNeedload}
                                                needload={needload}
                                                officeNavState={officeNavState}
                                                navpathname={navpathname}
                                            />
                                        </VerticalSubNavCategoryContent>
                                    )}
                                </VerticalNavLink>
                            </Link>
                        </VerticalNavCategoryContent>
                    </VerticalNavCategoryBox>
                    {userType !== UserTypes.MARKETER && (
                        <VerticalNavCategoryBox>
                            <VerticalNavCategoryContent>
                                <Link
                                    to={"/mypage/item_ad"}
                                    onClick={() => {
                                        setNeedload(!needload)
                                    }}
                                >
                                    <VerticalNavLink is_selected={pmsNavState === PmsNavState.AD} isfold={folded}>
                                        <VerticalNavLinkHead>
                                            <MyPageVerticalNavIconContainer>
                                                <ItemAdBoxIcon is_selected={pmsNavState === PmsNavState.AD} />
                                            </MyPageVerticalNavIconContainer>
                                            <VerticalLinkText
                                                is_selected={pmsNavState === PmsNavState.AD}
                                                isfold={folded}
                                            >
                                                사업아이템
                                            </VerticalLinkText>
                                        </VerticalNavLinkHead>
                                    </VerticalNavLink>
                                </Link>
                            </VerticalNavCategoryContent>
                        </VerticalNavCategoryBox>
                    )}
                    {userType === UserTypes.STAFF && (
                        <VerticalNavCategoryBox>
                            <VerticalNavCategoryContent>
                                <Link
                                    to={"/mypage/advertisement"}
                                    onClick={() => {
                                        setNeedload(!needload)
                                    }}
                                >
                                    <VerticalNavLink
                                        is_selected={pmsNavState === PmsNavState.ADVERTISEMENT}
                                        isfold={folded}
                                    >
                                        <VerticalNavLinkHead>
                                            <MyPageVerticalNavIconContainer>
                                                <AdvertisementIcon
                                                    is_selected={pmsNavState === PmsNavState.ADVERTISEMENT}
                                                />
                                            </MyPageVerticalNavIconContainer>
                                            <VerticalLinkText
                                                is_selected={pmsNavState === PmsNavState.ADVERTISEMENT}
                                                isfold={folded}
                                            >
                                                광고 관리
                                            </VerticalLinkText>
                                        </VerticalNavLinkHead>
                                    </VerticalNavLink>
                                </Link>
                            </VerticalNavCategoryContent>
                        </VerticalNavCategoryBox>
                    )}
                    {userType === UserTypes.STAFF && (
                        <VerticalNavCategoryBox>
                            <VerticalNavBr />
                            <VerticalNavCategoryContent>
                                <Link
                                    to={"/mypage/staff/user/brand"}
                                    onClick={() => {
                                        setNeedload(!needload)
                                    }}
                                >
                                    <VerticalNavLink
                                        is_selected={pmsNavState === PmsNavState.STAFFUSER}
                                        isfold={folded}
                                    >
                                        <VerticalNavLinkHead>
                                            <MyPageVerticalNavIconContainer>
                                                <GroupIcon is_selected={pmsNavState === PmsNavState.STAFFUSER} />
                                            </MyPageVerticalNavIconContainer>
                                            <VerticalLinkText
                                                is_selected={pmsNavState === PmsNavState.STAFFUSER}
                                                isfold={folded}
                                            >
                                                회원 관리
                                            </VerticalLinkText>
                                        </VerticalNavLinkHead>
                                    </VerticalNavLink>
                                </Link>
                                <Link
                                    to={"/mypage/staff/item"}
                                    onClick={() => {
                                        setNeedload(!needload)
                                    }}
                                >
                                    <VerticalNavLink
                                        is_selected={pmsNavState === PmsNavState.STAFFITEM}
                                        isfold={folded}
                                    >
                                        <VerticalNavLinkHead>
                                            <MyPageVerticalNavIconContainer>
                                                <ItemManageIcon is_selected={pmsNavState === PmsNavState.STAFFITEM} />
                                            </MyPageVerticalNavIconContainer>
                                            <VerticalLinkText
                                                is_selected={pmsNavState === PmsNavState.STAFFITEM}
                                                isfold={folded}
                                            >
                                                계약서 설정
                                            </VerticalLinkText>
                                        </VerticalNavLinkHead>
                                    </VerticalNavLink>
                                </Link>
                            </VerticalNavCategoryContent>
                        </VerticalNavCategoryBox>
                    )}
                </div>

                <div className="flex flex-col w-full">
                    {userType !== UserTypes.BRAND && (
                        <VerticalNavCategoryBox>
                            <VerticalNavCategoryContent>
                                {/* 포인트 */}
                                <Link
                                    to={"/mypage/point"}
                                    onClick={() => {
                                        setNeedload(!needload)
                                    }}
                                >
                                    <VerticalNavLink
                                        is_selected={pmsNavState === PmsNavState.POINT}
                                        isfold={folded}
                                        className="mt-auto"
                                    >
                                        <VerticalNavLinkHead>
                                            <MyPageVerticalNavIconContainer>
                                                <PointIcon is_selected={pmsNavState === PmsNavState.POINT} />
                                            </MyPageVerticalNavIconContainer>
                                            <VerticalLinkText
                                                is_selected={pmsNavState === PmsNavState.POINT}
                                                isfold={folded}
                                                className="px-0"
                                            >
                                                <span>포인트</span>
                                            </VerticalLinkText>
                                        </VerticalNavLinkHead>
                                    </VerticalNavLink>
                                </Link>
                            </VerticalNavCategoryContent>
                        </VerticalNavCategoryBox>
                    )}
                    <VerticalNavCategoryBox>
                        <VerticalNavCategoryContent>
                            {/* 내 정보 */}
                            <Link
                                to={"/mypage/myinfo"}
                                onClick={() => {
                                    setNeedload(!needload)
                                }}
                            >
                                <VerticalNavLink is_selected={pmsNavState === PmsNavState.PROFIL} isfold={folded}>
                                    <VerticalNavLinkHead>
                                        <MyPageVerticalNavIconContainer>
                                            {(userType === UserTypes.BRAND || userType === UserTypes.STAFF) && (
                                                <BrandIcon is_selected={pmsNavState === PmsNavState.PROFIL} />
                                            )}
                                            {userType === UserTypes.MARKETER && (
                                                <MarketerIcon is_selected={pmsNavState === PmsNavState.PROFIL} />
                                            )}
                                        </MyPageVerticalNavIconContainer>
                                        <VerticalLinkText
                                            is_selected={pmsNavState === PmsNavState.PROFIL}
                                            isfold={folded}
                                            className="px-0"
                                        >
                                            <span>내 정보</span>
                                        </VerticalLinkText>
                                    </VerticalNavLinkHead>
                                </VerticalNavLink>
                            </Link>
                        </VerticalNavCategoryContent>
                    </VerticalNavCategoryBox>
                </div>
            </VerticalNavcon>
        </React.Fragment>
    )
}
