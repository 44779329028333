import * as XLSX from "xlsx"
import React from "react"

export const downloadExcel = (head, body, title) => {
    const now = new Date()
    const dateNow = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate()

    const headObject = head.reduce((obj, item) => {
        obj[item.col_head_id] = item.col_head_name
        return obj
    }, {})

    const headerIds = head.map((item) => item.col_head_id)

    // Body 배열을 재정렬하고 데이터만 추출
    const orderedBody = body.map((row) => {
        let rowData = {}
        row.forEach((item) => {
            if (headerIds.includes(item.col_id)) {
                // head에 없는 값은 무시
                rowData[item.col_id] = item.data
            }
        })
        return headerIds.map((id) => rowData[id] || "-") // head에 있는데 body에 없는 값은 '-'로 채우기
    })

    // Head를 순서대로 정렬하고 이름만 추출
    const orderedHead = headerIds.map((id) => headObject[id])

    const data = [orderedHead, ...orderedBody]

    const ws = XLSX.utils.aoa_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "엑셀 데이터")

    //엑셀 파일을 생성하고 다운로드
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    })
    const url = window.URL.createObjectURL(dataBlob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", title + "_" + dateNow + ".xlsx")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
