export const AgreementForm = {
  NULL: -1,
  USAGE: 0,
  PERSONAL: 1,
  THIRDPARTY: 2,
}

export const AgreementLinkType = {
  SHOWLINK: 0,
  SENDLINK: 1,
}