import React,{useEffect,useState,useRef,useMemo} from "react";
import tw from 'tailwind-styled-components'
import { FilterBox, FilterCheckBox, FilterCheckedIcon, FilterCheckIcon, FilterColumnName, FilterContainer, FilterRowList, FilterValueContainer } from "./MyPagePerformFilterStyle";
import { getComboName, getPerformEmptyValue, getStatusName } from '../../../utils/brandItem/BrandItemPerformUtils';
import { DateRangePicker, PerformItemCombo, PerformItemInput } from "../../../components/brandItems/PerformInputs";
import { EditStateTypes, QueryTypes } from "../../../types/EditorTypes";
import { PerformColumnFormTypes, PerformStaticColumnTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import Utils from "../../../utils/Utils";
import { PerformValueInputTitle } from "./MyPagePerformStyle";
import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OptionItemList } from "./PerformValueOptionStyle";
import { OptionItem } from "./PerformValueOptionCell";
import { Dictionary } from "../../../types/Dictionary";

export const FilterCombo = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [comboID, setComboID] = useState(-1)

    useEffect(()=>{
        if (filterValue !== undefined)
            setComboID(filterValue.comboID)
    }, [filterValue])

    const handleChange = (_comboID) => {
        if (_comboID >= 0) {
            let filter = {}
            filter.comboID = _comboID
            handleAddFilter(filter)
            setComboID(_comboID)

            filterValue.comboID = _comboID
        } else {
            setDeactive()
        }
    }

    
    return (
        <React.Fragment>
            <PerformItemCombo
                optionKeys={column.combos.map(combo => combo.id)} 
                names={column.combos.map(combo => combo.name)} 
                value={comboID}
                onChange={(e)=>{handleChange(e.target.value)}}
                editState={editState}
                color={"white"}
            />
        </React.Fragment>
    )
}

export const FilterText = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [value, setValue] = useState("")
    
    useEffect(()=>{
        if (filterValue !== undefined)
        setValue(filterValue.text)
    }, [filterValue])

    useEffect(()=>{
        if (value === "") {
            setDeactive()
        }
    }, [value])

    const handleChange = () => {
        filterValue.text = value
        if (value !== "") {
            let filter = {}
            filter.text = value
            handleAddFilter(filter)
        }
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleChange()
        }
    }

    return (
        <PerformItemInput
            type="text"
            color={"white"}
            value={value}
            setValue={setValue}
            onBlur={()=>{handleChange()}}
            onKeyPress={handleSearchKeyPress}
        />
    )
}

export const FilterStatus = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [statusID, setStatusID] = useState(0)

    useEffect(()=>{
        if (filterValue !== undefined)
            setStatusID(filterValue.statusID)
    }, [filterValue])

    const handleChange = (_statusID) => {
        if (_statusID >= 0) {
            let filter = {}
            filter.statusID = _statusID
            handleAddFilter(filter)
            setStatusID(_statusID)

            filterValue.statusID = _statusID
        } else {
            setDeactive()
        }
    }

    return (
        <PerformItemCombo
            optionKeys={column.statuses.map(status => status.id)} 
            names={column.statuses.map(status => status.name)} 
            value={statusID}
            onChange={(e)=>{handleChange(e.target.value)}}
            editState={editState}
            color={"white"}
        />
    )
}

export const FilterFixedDate = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [value, setValue] = useState("")

    

    useEffect(()=>{
        if (filterValue !== "")
            setValue(filterValue.text)
    }, [filterValue])

    useEffect(()=>{
        if (value !== "" && value !== undefined) {
            handleChange()
        }
    }, [value])

    const handleChange = () => {
        let filter = {}
        filter.text = value
        handleAddFilter(filter)
        filterValue.text = value
    }

    return (
        <DateRangePicker setValue={setValue} value={value} />
    )
}

export const FilterDate = ({column, filterValue, handleAddFilter, setDeactive}) => {
    const [value, setValue] = useState("")

    useEffect(()=>{
        if (filterValue !== "")
            setValue(filterValue.text)
    }, [filterValue])

    useEffect(()=>{
        if (value !== "" && value !== undefined) {
            handleChange()
        }
    }, [value])

    const handleChange = () => {
        let filter = {}
        filter.text = value
        handleAddFilter(filter)
        filterValue.datetime = value
    }

    return (
        <DateRangePicker setValue={setValue} value={value} />
    )
}

export const FilterOption = ({column, filterValue, handleAddFilter, handleRemoveFilter}) => {
    const [optionItemDic, setOptionItemDic] = useState(new Dictionary()) // 체크된 항목 관리

    useEffect(()=>{
        let _optionItemDic = new Dictionary()

        filterValue.optionItems.map(optionItem => {
            _optionItemDic.push(optionItem.optionID, optionItem)
        })

        setOptionItemDic(_optionItemDic)
    }, [filterValue])

    const updateOptionDic = (optionItem, queryType) => {
        let _optionItemDic = optionItemDic.copy()

        if (queryType === QueryTypes.CREATE_OR_UPDATE) {
            _optionItemDic.push(optionItem.optionID, optionItem)
        } else if (queryType === QueryTypes.DELETE) {
            _optionItemDic.delete(optionItem.optionID)
        }

        setOptionItemDic(_optionItemDic)
        
        if (_optionItemDic.getLength() === 0) {
            filterValue.optionItems = []
            handleRemoveFilter(column.id)
        } else {
            let filter = {}
            filter.optionItems = _optionItemDic.getValues()
            handleAddFilter(filter)
            filterValue.optionItems = _optionItemDic.getValues()
        }
    }

    return (
        <OptionItemList>
            {column.options.map((option, idx) => 
                <OptionItem option={option} optionItemDic={optionItemDic} updateOptionDic={updateOptionDic} multiSelect key={idx} />
            )}
        </OptionItemList>
    )
}

const FilterRow = ({filterValue, filterDic, addFilter, removeFilter}) => {
    const column = filterValue.column
    const [isActive, setActive] = useState(false)
    
    if (column === undefined) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    useEffect(()=>{
        if (filterDic.contains(column.id)) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [filterDic])

    useEffect(()=>{
        if (!isActive) {
            removeFilter(column.id)
        }
    }, [isActive])

    const handleActive = () => {
        if (isActive) {
            setActive(false)
            return
        }
        // 컬럼 타입 마다 필터를 활성화 할 수 있는 조건이 다르다.
        if (column.formType === PerformColumnFormTypes.COMBO &&
            filterValue.comboID >= 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.TEXT &&
            filterValue.text !== ""
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.STATUS &&
            filterValue.statusID >= 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.DATE &&
            filterValue.text !== "" && filterValue.text !== undefined
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.OPTION &&
            filterValue.optionItems.length > 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
    }

    const handleAddFilter = (fv) => {
        addFilter(column.id, fv)
    }

    const setDeactive = () => {
        setActive(false)
    }
    


    return (
        <FilterBox isActive={isActive}>
            <FilterCheckBox onClick={() => {handleActive()}}>
                {isActive ? (<FilterCheckedIcon />) : (<FilterCheckIcon />)}
            </FilterCheckBox>
            <FilterColumnName >
                {column.name}
            </FilterColumnName>
            <FilterValueContainer>
                {column.formType === PerformColumnFormTypes.COMBO && (
                    <FilterCombo column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {column.formType === PerformColumnFormTypes.TEXT && (
                    <FilterText column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {column.formType === PerformColumnFormTypes.STATUS && (
                    <FilterStatus column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {(column.formType === PerformColumnFormTypes.DATE && column.id < 0) && (
                    <FilterFixedDate column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {(column.formType === PerformColumnFormTypes.DATE && column.id >= 0) && (
                    <FilterDate column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {(column.formType === PerformColumnFormTypes.OPTION && column.id >= 0) && (
                    <FilterOption column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} handleRemoveFilter={removeFilter} />
                )}
            </FilterValueContainer>
        </FilterBox>
    )
}

export default function MyPagePerformFilter({columns, filterDic, addFilter, removeFilter}){
    const [filterValues, setFilterValues] = useState([])

    useEffect(()=>{
        if (columns !== undefined && columns.length > 0) {
            let filterValues = []
            columns.map((column) => {
                if (
                    column.id === PerformStaticColumnTypes.SELECT ||
                    column.id === PerformStaticColumnTypes.MARKETER ||
                    column.id === PerformStaticColumnTypes.UPDATE ||
                    column.formType === PerformColumnFormTypes.FILE
                ) {
                    return
                }

                let filterValue
                if (filterDic.contains(column.id)) {
                    filterValue = filterDic.getValue(column.id)
                    filterValue.column = column
                } else {
                    filterValue = getPerformEmptyValue(column)
                    filterValue.column = column
    
                    // 콤보 박스를 가진 타입은 처음 값을 지정해준다.
                    // if (column.formType === PerformColumnFormTypes.COMBO && column.combos.length > 0) {
                    //     filterValue.comboID = column.combos[0].id
                    // } else if (column.formType === PerformColumnFormTypes.STATUS && column.statuses.length > 0) {
                    //     filterValue.statusID = column.statuses[0].id
                    // } 
                    // else if (column.formType === PerformColumnFormTypes.DATE ) {
                    //     // filterValue.dateText = column.texts[0].id
                    // }
                }
                filterValues.push(filterValue)
            })
            setFilterValues(filterValues)
        }
    }, [columns])
    
    return(
        <FilterContainer >
            <PerformValueInputTitle>
                <span>실적 필터</span>
            </PerformValueInputTitle>
            <FilterRowList className='parent-div'>
                {filterValues.map((filterValue, idx) => (
                    <FilterRow 
                        filterValue={filterValue} 
                        filterDic={filterDic}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        key={idx} 
                    />
                ))}
            </FilterRowList>
        </FilterContainer>
    )
}