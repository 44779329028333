import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import {
    MyInfoLayout,
    MyInfoContainer,
    MyInfoProfileLayout,
    MyInfoDatasContainer,
    MyInfoInput,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataEditButton,
    MyInfoDataImageEditButton,
    MyInfoDataTitleBox,
    MyInfoProfileContainer,
    MyInfoProfileAvatarContainer,
    MyInfoProfileAvatarEditContainer,
    MyInfoProfileAvatarEditIconContainer,
    MyInfoProfileAvatarUploadIcon,
    MyInfoProfileAvatarDeleteIcon,
    PenIcon,
    MyInfoPenIconContainer,
    MyInfoProfileUserDeleteContainer,
    MyInfoProfileUserDeleteButton,
} from "./MyInfoMainStyle"
import MyInfoMarketer from "./MyInfoMarketer"
import MyInfoBrand from "./MyInfoBrand"
import { PmsNavState } from "../../../types/DataEnum"
import MyInfoUser from "./MyInfoUser"
import { useUserStore } from "../../../zustand/store"
import { UserTypes } from "../../../types/users/UserTypes"
import UserAvatar from "../../../components/user/UserAvatar"
import User from "../../../types/users/User"
import {
    deleteUserProfileAvatar,
    getUserDetail as getUserDetailApi,
    updateUserProfileAvatar,
} from "../../../apis/api/users"
import MyInfoMarketerCareer from "./MyInfoMarketerCareer"
import { uploadImage } from "../../../apis/api/files"
import { getUserByAccessToken } from "../../../services/users"
import MyInfoSign from "./MyInfoSign"
import { MyInfoMarketerMore } from "./MyInfoMarketerMore"
import { useRef } from "react"
import { ModalContainer } from "../../../components/ModalContainer"
import MyInfoDelete from "./MyInfoDelete"
import { RESPONSIVE_WIDTH } from "../../../constants/ConstUI"
import MyInfoDeleteContainer from "./MyInfoDeleteContainer"
const FileInputLabel = tw.label`
`

const AvatarInput = ({ id, onChange, children }) => {
    return (
        <React.Fragment>
            <FileInputLabel for={`input-file-user-profile-avatar`}>
                {/* <FileIconUpload /> */}
                {/* 파일 선택 */}
                {children}
            </FileInputLabel>
            <input id={`input-file-user-profile-avatar`} className="w-0 hidden" type="file" onChange={onChange} />
        </React.Fragment>
    )
}

export default function MyInfoMain({ setPmsNavState }) {
    const { user, actions } = useUserStore()

    const [userDetail, setUserDetail] = useState(null)

    const [mobileClickAvatar, setMobileClickAvatar] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const refAvatar = useRef(null)

    useEffect(() => {
        setPmsNavState(PmsNavState.PROFIL)
        getUserDetail()
    }, [user])

    useEffect(() => {
        function handleClickOutside(event) {
            if (refAvatar.current && !refAvatar.current.contains(event.target)) {
                setMobileClickAvatar(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [refAvatar])

    const getUserDetail = () => {
        getUserDetailApi(user.id).then((response) => {
            setUserDetail(response)
        })
    }

    const handleProfileAvatarUpload = async (e) => {
        if (e.target.files.length !== 1) {
            return
        }

        const image = await uploadImage(e.target.files[0])

        if (!(image.id >= 0)) {
            return
        }

        const uploaded = await updateUserProfileAvatar(image.id)

        if (uploaded) {
            const user = await getUserByAccessToken()
            actions.setUser(user)
        }
    }
    const handleProfileAvatarDelete = async () => {
        if (confirm("프로필 이미지를 제거하시겠습니까?")) {
            const deleted = await deleteUserProfileAvatar()

            if (deleted) {
                const user = await getUserByAccessToken()
                actions.setUser(user)
            }
        }
    }

    return (
        <MyInfoLayout>
            <MyInfoContainer>
                <MyInfoProfileLayout>
                    <div className="max-lg:hidden lg:block w-full h-20" />
                    <MyInfoProfileContainer>
                        <MyInfoProfileAvatarContainer
                            onClick={() => {
                                setMobileClickAvatar(true)
                            }}
                            ref={refAvatar}
                        >
                            <MyInfoPenIconContainer>
                                <PenIcon />
                            </MyInfoPenIconContainer>
                            <div className="w-full aspect-square rounded-full overflow-hidden group">
                                <UserAvatar user={user} />
                                <MyInfoProfileAvatarEditContainer mobileClickAvatar={mobileClickAvatar}>
                                    <AvatarInput
                                        onChange={(e) => {
                                            handleProfileAvatarUpload(e)
                                        }}
                                    >
                                        <MyInfoProfileAvatarEditIconContainer>
                                            <MyInfoProfileAvatarUploadIcon />
                                        </MyInfoProfileAvatarEditIconContainer>
                                    </AvatarInput>
                                    <MyInfoProfileAvatarEditIconContainer
                                        onClick={() => {
                                            handleProfileAvatarDelete()
                                        }}
                                    >
                                        <MyInfoProfileAvatarDeleteIcon />
                                    </MyInfoProfileAvatarEditIconContainer>
                                </MyInfoProfileAvatarEditContainer>
                            </div>
                        </MyInfoProfileAvatarContainer>
                    </MyInfoProfileContainer>
                </MyInfoProfileLayout>
                <MyInfoDatasContainer>
                    {/* 로딩 전에는 Store에 있는 user 사용 */}
                    {/* 공통 회원 정보 */}
                    <MyInfoUser user={userDetail === null ? user : userDetail.user} />
                    {/* 브랜드 정보 */}
                    {user.userType === UserTypes.BRAND && <MyInfoBrand brand={userDetail} />}
                    {/* 마케터 정보 */}
                    {user.userType === UserTypes.MARKETER && (
                        <React.Fragment>
                            <MyInfoMarketerMore marketer={userDetail} />
                        </React.Fragment>
                    )}
                    {/* {user.userType === UserTypes.MARKETER && (
                        <MyInfoMarketer marketer={userDetail} />
                        
                    )}
                    {user.userType === UserTypes.MARKETER && (
                        <MyInfoMarketerCareer marketer={userDetail} />
                    )} */}
                    {/* 회원 서명 */}
                    {user.userType !== UserTypes.MARKETER && <MyInfoSign marketer={userDetail} />}

                    {/* 회원 탈퇴 */}
                    {user.userType === UserTypes.MARKETER && (
                        <MyInfoDeleteContainer
                            showDeleteModal={() => {
                                setShowDeleteModal(true)
                            }}
                        />
                    )}
                </MyInfoDatasContainer>
            </MyInfoContainer>

            <ModalContainer
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                isBlur={true}
                fadeUp={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.MD}
            >
                <MyInfoDelete userDetail={userDetail} />
            </ModalContainer>
        </MyInfoLayout>
    )
}
