import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { Link, useParams } from "react-router-dom"
import { useUserStore } from "../../../zustand/store"
import {
    GroupAddButton,
    GroupDetailExitGroupButton,
    GroupDetailInfoBox,
    GroupDetailInfoLabel,
    GroupDetailInfoValue,
    GroupDetailMarketerControlBox,
    GroupDetailMarketerControlButton,
    GroupDetailMasterMarketerAvatarBox,
    GroupDetailMasterMarketerBox,
    GroupDetailMasterMarketerDetailBox,
    GroupDetailMasterMarketerTitle,
    GroupDetailTitleBox,
    GroupDetailTitleInput,
    GroupDetailTitle,
    GroupMarketerContentHead,
    GroupMarketerDetailBox,
    GroupMarketerDetailLayout,
    PlusIcon,
    GroupDetailTitleIconContainer,
} from "./MyPageGroupStyle"
import BrandItemMarketerGroup from "../../../types/brandItems/marketers/BrandItemMarketerGroup"
import {
    addItemMarketerGroup,
    deleteItemMarketerInvite,
    getItemMarketerGroups,
    updateItemMarketerGroup,
    updateItemMarketerName,
} from "../../../apis/api/boardItems"
import AccessRules from "../../../rules/AccessRules"
import UserAvatar from "../../../components/user/UserAvatar"
import { MarketerInfoBox } from "../../../components/HoverComponants"
import { EditStateTypes } from "../../../types/EditorTypes"
import {
    CalcTableTitleIconContainer,
    CalcTableTitleCalcEditIcon,
    CalcTableTitleSaveIcon,
    CalcTableTitleCancleIcon,
} from "../calculate/MyPageCalcStyle"

const MAX_PAGE_LENGTH = 5

const GroupInfoBox = ({ label, value }) => {
    return (
        <div className="flex items-center mb-1">
            <GroupDetailInfoLabel>{label}</GroupDetailInfoLabel>
            <GroupDetailInfoValue>{value}</GroupDetailInfoValue>
        </div>
    )
}

const GroupDetail = ({ itemMarketer }) => {
    const [titleEditState, setTitleEditState] = useState(EditStateTypes.WAIT)
    const [titleValue, setTitleValue] = useState(itemMarketer.marketerName)

    const handleTitleEditState = () => {
        if (titleEditState === EditStateTypes.WAIT) {
            setTitleEditState(EditStateTypes.EDITED)
        } else {
            handleTitleUpdate()
        }
    }
    const handleTitleUpdate = () => {
        updateItemMarketerName(itemMarketer.id, titleValue).then((response) => {
            if (response) {
                setTitleEditState(EditStateTypes.WAIT)
                itemMarketer.marketerName = titleValue
            }
        })
    }
    const handleUpdateKeyPress = (e) => {
        if (e.key == "Enter") {
            handleTitleUpdate()
        }
    }

    return (
        <div className="flex flex-col w-full">
            <GroupDetailMasterMarketerBox>
                <GroupDetailTitleBox editState={titleEditState}>
                    {titleEditState === EditStateTypes.WAIT ? (
                        <GroupDetailTitle>{itemMarketer.marketerName}</GroupDetailTitle>
                    ) : (
                        <GroupDetailTitleInput
                            type="text"
                            value={titleValue}
                            onChange={(e) => {
                                setTitleValue(e.target.value)
                            }}
                            onKeyPress={handleUpdateKeyPress}
                        />
                    )}

                    {titleEditState === EditStateTypes.WAIT ? (
                        <div className="flex h-full items-center">
                            <GroupDetailTitleIconContainer
                                onClick={() => {
                                    handleTitleEditState()
                                }}
                            >
                                <CalcTableTitleCalcEditIcon />
                            </GroupDetailTitleIconContainer>
                        </div>
                    ) : (
                        <div className="flex h-full items-center">
                            <GroupDetailTitleIconContainer
                                onClick={() => {
                                    handleTitleEditState()
                                }}
                            >
                                <CalcTableTitleSaveIcon />
                            </GroupDetailTitleIconContainer>
                            <GroupDetailTitleIconContainer
                                onClick={() => {
                                    setTitleEditState(EditStateTypes.WAIT)
                                }}
                            >
                                <CalcTableTitleCancleIcon />
                            </GroupDetailTitleIconContainer>
                        </div>
                    )}
                </GroupDetailTitleBox>
                <GroupDetailMasterMarketerDetailBox>
                    <GroupDetailMasterMarketerAvatarBox>
                        <UserAvatar user={itemMarketer.marketer.user} />
                    </GroupDetailMasterMarketerAvatarBox>
                    <div className="flex flex-col text-nightblue-100 ml-2">
                        <MarketerInfoBox marketer={itemMarketer.marketer} />
                    </div>
                </GroupDetailMasterMarketerDetailBox>
            </GroupDetailMasterMarketerBox>
            {/* <GroupDetailInfoBox>
                <GroupInfoBox label={"그룹 원"} value={itemMarketer.marketers.length} />
                <GroupInfoBox label={"총 등록 실적"} value={itemMarketer.performs} />
                <GroupInfoBox label={"총 승인 실적"} value={itemMarketer.performConfirms} />
                <GroupInfoBox label={"총 정산 완료"} value={itemMarketer.calculates} />
            </GroupDetailInfoBox> */}
        </div>
    )
}

export default function MyPageGroupMarketerDetail({
    myItemMarketer,
    getMyItemMarketer,
    openMarketerList,
    openInvite,
    myGroupLoading,
}) {
    const { user } = useUserStore()

    return (
        <GroupMarketerDetailLayout>
            <GroupMarketerContentHead className="h-16">
                <span className="text-lg font-semibold">내 활동</span>
            </GroupMarketerContentHead>
            <GroupMarketerDetailBox>
                <GroupDetail itemMarketer={myItemMarketer} user={user} myGroupLoading={myGroupLoading} />
                {/* 하단 */}
                <div className="max-lg:hidden lg:flex mt-12"></div>
            </GroupMarketerDetailBox>
        </GroupMarketerDetailLayout>
    )
}
