import { PerformColumnFormTypes, PerformFileTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.name = ""
        this.fileType = PerformFileTypes.NONE
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["column_id"]
        this.name = response["name"]
        this.fileType = response["file_type"]
    }

    convertToRequest() {
    }
}