import { Response } from "../types/Response"

const BackendResponse = {
    SUCCESS: new Response("Success"), // 성공한 경우는 메세지가 없다.
    INVALID: new Response("Invalid"),
    NOT_FOUND: new Response("NotFound"),
    EXIST_PERFORMS: new Response("Exist Performs"),
    NOT_FOUND_MARKETER_DETAIL: new Response("NotFound Marketer Detail"),
    NOT_FOUND_CONFIRM: new Response("NotFound Confirm"),
}
export default BackendResponse

export const getBackendResponse = (responseMessage) => {
    let response = null

    const brKeys = Object.keys(BackendResponse)
    let responseSamples = []
    for (let i=0; i<brKeys.length; i++) {
        const brKey = brKeys[i]
        if (responseMessage.includes(BackendResponse[brKey].responseMessage)) {
            responseSamples.push(BackendResponse[brKey])
        }
    }
    
    if (responseSamples.length === 0) {
        response = BackendResponse.SUCCESS
    } else {
        // 에러 메세지의 글자수가 가장 많은게 가장 정확한걸로 보고 반환한다.
        responseSamples = responseSamples.sort((a,b) => b.responseMessage.length - a.responseMessage.length)
        response = responseSamples[0]
    }

    return response
}