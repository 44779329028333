import React, { useState, useEffect, useRef } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { UserTypes } from "../../types/users/UserTypes"
import { useUserStore } from "../../zustand/store"
import Utils from "../../utils/Utils"
import { useUiStore } from "../../zustand/uiStore"
import { isMobile } from "react-device-detect"
import {
    ItemMarketerMatchingContainer,
    ItemMarketerMatchingPageContainer,
    ItemMarketerMatchingResultBox,
    ItemMarketerMatchingSearchBox,
    ItemMarketerMatchingSearchButton,
} from "./ItemMarketerMatchingStyle"
import { getBrandItemList } from "../../apis/api/boardItems"
import BrandItemList from "../../types/brandItems/BrandItemList"
import { ItemCardListGrid } from "./ItemStyle"
import BrandItemCard from "../../components/brandItems/BrandItemCard"
import { ItemCardType } from "../../types/DataEnum"

export default function ItemMarketerMatchingPage() {
    const { user } = useUserStore((state) => state)

    const [isLoading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    const getItems = async () => {
        if (isLoading) {
            return
        }

        setLoading(true)

        setTimeout(() => {
            getBrandItemList(1, 30)
                .then((response) => {
                    const brandItemList = new BrandItemList()
                    brandItemList.convertByResponse(response)

                    // setItemCount(brandItemList.count)
                    setItems(brandItemList.items)
                    setLoading(false)
                    // setPageIndex(pageIndex)
                })
                .catch((error) => {
                    setItems([])
                    setLoading(false)
                })
        }, [3000])
    }

    return (
        <ItemMarketerMatchingPageContainer>
            <ItemMarketerMatchingContainer>
                {/* 소개화면 */}
                <div className="flex flex-center w-full h-128 rounded bg-transparent border border-slate-200">
                    소개 화면입니다. (마케터 전용 화면입니다.)
                </div>
                {/* 마케터 설정 화면 */}
                <div className="flex flex-center w-full h-64 mt-2 rounded bg-transparent border border-slate-200">
                    마케터 정보 설정 결과 화면입니다. (설정하는 페이지는 따로 만들 예정입니다.)
                </div>
                <ItemMarketerMatchingSearchBox>
                    <ItemMarketerMatchingSearchButton
                        className={`${isLoading ? "loading" : ""}`}
                        onClick={() => {
                            getItems()
                        }}
                        disabled={isLoading}
                    >
                        Ai <span className="font-light">상품 매칭</span>
                    </ItemMarketerMatchingSearchButton>
                </ItemMarketerMatchingSearchBox>
                {items.length > 0 && (
                    <ItemMarketerMatchingResultBox>
                        <p className="mb-8 text-slate-700">
                            세일즈랑의 강력한 인공지능 알고리즘을 통해 회원님께 어울리는 상품 결과를 제공합니다.
                        </p>
                        <ItemCardListGrid>
                            {items.map((data, idx) => (
                                <BrandItemCard
                                    key={idx}
                                    item={data}
                                    itemType={ItemCardType.ITEM}
                                    isLoading={isLoading}
                                    testStyle={false}
                                />
                            ))}
                        </ItemCardListGrid>
                    </ItemMarketerMatchingResultBox>
                )}
            </ItemMarketerMatchingContainer>
        </ItemMarketerMatchingPageContainer>
    )
}
