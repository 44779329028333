import { PerformColumnFormTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.name = ""

        this.dateFormat = "" // 날짜 표기 포맷
        this.hasMemo = false
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["column_id"]
        this.name = response["name"]

        this.dateFormat = response["date_format"]
        this.hasMemo = response["has_memo"]
    }

    convertToRequest() {
        let request = {
            id: -1,
            column_id: 0,
            name: "",
        }

        request.id = this.id
        request.column_id = this.columnID
        request.name = this.name

        request.date_format = this.dateFormat
        request.has_memo = this.hasMemo
        return request
    }
}