import React, { useEffect, useRef, useState } from "react"
import { EditStateTypes, ItemTypes, QueryTypes } from "../../../types/EditorTypes"
import { TextFormats } from "../../../types/DataEnum"

import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { getImageFile, uploadImage } from "../../../apis/api/files/index"
import Coupon from "../../../types/points/Coupon"
import * as PointApi from "../../../apis/api/points/index"
import {
    CouponViewContentBox,
    CouponViewContentColInfo,
    CouponViewContentInfo,
    CouponViewContentLayout,
    CouponViewControlBox,
    CouponViewCouponDetailPre,
    CouponViewLayout,
    CouponViewPurchaseButton,
    CouponViewThumbnailBox,
    CouponWriteLayout,
    PointModalTitle,
} from "./MyPagePointStyle"
import { CouponApplicationStatusTypeNames, CouponApplicationStatusTypes } from "../../../types/points/PointTypes"
import SpinLoading from "../../../components/loading/SpinLoading"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import CouponApplication from "../../../types/points/CouponApplication"
import { UserTypes } from "../../../types/users/UserTypes"
import GiftiCoupon from "../../../types/points/GiftiCoupon"

import ModalContainerPortal from "../../../components/ModalContainerPortal"
import { MyPageInputModalLayout } from "../MyPageMainStyle"

const MyPageCouponView = ({ user, updateUser, coupon, showModal, setShowModal }) => {
    const [isLoading, setLoading] = useState(false)
    const [giftiCoupon, setGiftiCoupon] = useState(new GiftiCoupon())

    const [applicationStatus, setApplicationStatus] = useState(CouponApplicationStatusTypes.WAIT)

    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    const [showContentModal, setShowContentModal] = useState(false)

    const [isPurchasing, setPurchasing] = useState(false)

    useEffect(() => {
        setLoading(false)
        getGiftiCoupon()
        setLoading(true)
    }, [coupon])

    const getGiftiCoupon = async () => {
        if (Utils.isStringNullOrEmpty(coupon.giftiCouponThumb.goodsCode)) {
            return
        }

        const giftiCoupon = await PointApi.getGiftiCouponByGiftiShow(coupon.giftiCouponThumb.goodsCode)
        setGiftiCoupon(giftiCoupon)

        if (Utils.isStringNullOrEmpty(giftiCoupon.goodsCode)) {
            alert("상품을 찾을 수 없습니다.")
            return
        }

        setImageSource("")
        imageSetLoad(false)
        setImageSource(giftiCoupon.goodsImgB)
    }

    const handlePurchase = async () => {
        if (isPurchasing) {
            alert("구매 요청 중입니다.")
            return
        }

        setPurchasing(true)
        let data = {}
        data["coupon_id"] = coupon.id
        data["marketer_id"] = user.id
        data["payment_price"] = coupon.price
        data["application_status"] = coupon.applicationType

        const [response, errors] = await PointApi.createCouponApplication(data)
        setPurchasing(false)

        if (Utils.isStringNullOrEmpty(response.uuid)) {
            if (errors.length > 0) {
                alert(errors.join("\n"))
            } else {
                alert("요청 실패했습니다.")
            }
            return
        } else {
            setApplicationStatus(coupon.applicationType)
        }

        updateUser()
        if (response.applicationStatus === CouponApplicationStatusTypes.DONE) {
            const couponItem = await PointApi.getCouponItem(response.uuid)
            alert(`쿠폰 지급 : ${couponItem.uuid}`)
        }
    }

    return (
        <CouponViewLayout>
            <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} />

            <div className="flex max-lg:flex-col w-full h-full p-4 max-lg:space-y-4 lg:space-x-4">
                <CouponViewThumbnailBox>
                    <img
                        className="w-full h-full object-cover bg-center"
                        src={imageSource}
                        alt={null}
                        ref={imageRef}
                        onLoad={imageSetLoad}
                    />
                </CouponViewThumbnailBox>
                <CouponViewContentLayout>
                    <CouponViewContentBox>
                        <span className="text-lg font-semibold">{coupon.title}</span>

                        <span className="section-title mt-2">쿠폰 정보</span>
                        <span className="text-[15px] text-slate-800 font-medium mt-1">{giftiCoupon.goodsName}</span>
                        {/* <div className="flex justify-between items-center w-full mt-1 text-slate-500 text-sm font-light">
                            <span>{giftiCoupon.goodsTypeDtlNm}</span>
                            <span>{giftiCoupon.brandName}</span>
                        </div> */}

                        <CouponViewContentInfo className="text-sm">
                            <span className="key">카테고리</span>
                            <span className="value !font-normal">{giftiCoupon.goodsTypeDtlNm}</span>
                        </CouponViewContentInfo>
                        <CouponViewContentInfo className="text-sm">
                            <span className="key">브랜드</span>
                            <span className="value !font-normal">{giftiCoupon.brandName}</span>
                        </CouponViewContentInfo>
                        <CouponViewContentInfo className="text-sm">
                            <span className="key">교환처</span>
                            <span className="value !font-normal">{giftiCoupon.affiliate}</span>
                        </CouponViewContentInfo>

                        <span className="section-title mt-3 flex justify-between">
                            상세 정보 및 유의사항
                            <button
                                className="font-light text-slate-600 px-1.5 rounded hover:bg-slate-200 duration-200"
                                onClick={() => {
                                    setShowContentModal(true)
                                }}
                            >
                                자세히 보기
                            </button>
                        </span>
                        <div className="relative w-full h-full my-1 max-sm:min-h-[8rem]">
                            <CouponViewCouponDetailPre
                                style={{ fontFamily: "Pretendard" }}
                                onClick={() => {
                                    setShowContentModal(true)
                                }}
                            >
                                {giftiCoupon.content}
                            </CouponViewCouponDetailPre>
                        </div>

                        <span className="section-title mt-auto">구매 정보</span>
                        <CouponViewContentInfo>
                            <span className="key">가격</span>
                            <span className="value">{Utils.textFormat(coupon.price, TextFormats.NUMBER)}P</span>
                        </CouponViewContentInfo>
                        {user.userType === UserTypes.MARKETER && (
                            <CouponViewContentInfo className="mt-0">
                                <span className="key">구입 후 보유 포인트</span>
                                <span className="value !text-red-500">
                                    {Utils.textFormat(user.points - coupon.price, TextFormats.NUMBER)}P
                                </span>
                            </CouponViewContentInfo>
                        )}
                    </CouponViewContentBox>
                    <CouponViewControlBox>
                        <CouponViewPurchaseButton
                            $status={applicationStatus}
                            onClick={() => {
                                handlePurchase()
                            }}
                            disabled={applicationStatus !== CouponApplicationStatusTypes.WAIT}
                        >
                            {applicationStatus === CouponApplicationStatusTypes.WAIT && (
                                <>
                                    {coupon.applicationType === CouponApplicationStatusTypes.APPLY && "쿠폰 구입 신청"}
                                    {coupon.applicationType === CouponApplicationStatusTypes.DONE && "쿠폰 구입"}
                                </>
                            )}
                            {applicationStatus === CouponApplicationStatusTypes.APPLY && "구입 신청 완료"}
                            {applicationStatus === CouponApplicationStatusTypes.DONE && "구입 완료"}
                        </CouponViewPurchaseButton>
                    </CouponViewControlBox>
                </CouponViewContentLayout>
            </div>

            <ModalContainerPortal
                show={showContentModal}
                setShow={setShowContentModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageInputModalLayout>
                    <div className="relative max-sm:w-[70vw] sm:w-[600px] max-sm:min-h-[30vh] max-sm:max-h-[70vh] sm:min-h-[480px] sm:max-h-[60vh]">
                        <pre
                            style={{ fontFamily: "Pretendard" }}
                            className="w-full h-full text-sm overflow-y-auto scroll-transparent scroll-overlay"
                        >
                            {giftiCoupon.content}
                        </pre>
                    </div>
                </MyPageInputModalLayout>
            </ModalContainerPortal>
        </CouponViewLayout>
    )
}

export default MyPageCouponView
