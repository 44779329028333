import React, { useEffect, useState } from "react"
import { getItemMarketerInviteds, updateItemMarketerInviteState } from "../../../apis/api/boardItems"
import { MarketerStatusTypes } from "../../../types/brandItems/BrandItemTypes"
import { useInView } from "react-intersection-observer"
import { SortTypes } from "../../../types/EditorTypes"

const PAGE_SIZE = 20

export default function MyPageGroupManagerInvite({ itemMarketer, getCurrentMarketers }) {
    const [marketerInvites, setMarketerInvites] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()

    useEffect(() => {
        getInvites()
    }, [itemMarketer, pageIndex])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && marketerInvites.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            setPageIndex(pageIndex + 1)
        }
    }, [scrollInView])

    const getInvites = async () => {
        const pagination = await getItemMarketerInviteds(
            itemMarketer.itemID,
            itemMarketer.marketer.user.id,
            MarketerStatusTypes.WAIT,
            pageIndex,
            PAGE_SIZE,
            "",
            SortTypes.DESC,
        )

        if (pagination.count > 0) {
            if (pageIndex === 1) {
                setMarketerInvites(pagination.items)
                setMaxPage(Math.ceil(pagination.count / PAGE_SIZE))
            } else {
                setMarketerInvites([...marketerInvites, ...pagination.items])
            }
        } else {
            setMarketerInvites([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleInviteReceive = async (invite) => {
        // 수락
        await updateItemMarketerInviteState(invite.id, MarketerStatusTypes.CONFIRM).then((response) => {
            if (response) {
                if (pageIndex > 1) {
                    setPageIndex(1)
                } else {
                    getInvites()
                }
                getCurrentMarketers()
            }
        })
    }

    return (
        <div className="relative flex flex-col w-full h-full">
            <div className="absolute grid max-lg:grid-cols-1 lg:grid-cols-2 gap-2 w-full h-full overflow-y-auto scroll-transparent">
                {marketerInvites.map((marketerInvite, index) => {
                    const isReceived = marketerInvite.itemMarketerSenderID === itemMarketer.parentID
                    return (
                        <div
                            ref={index !== 0 ? scrollRef : null}
                            key={index}
                            className={`flex flex-col w-full h-fit px-4 py-2 rounded-md bg-slate-100 hover:bg-slate-200 duration-200 cursor-default`}
                        >
                            <div className="flex items-center">
                                <span>{marketerInvite.marketerSender.user.name}</span>
                                <span className="text-slate-600 text-sm ml-auto">
                                    {marketerInvite.marketerSender.user.tel}
                                </span>
                            </div>
                            <div className="flex items-center text-sm text-slate-700 mt-1">
                                <span>{marketerInvite.memo}</span>
                            </div>
                            <div className="flex justify-between items-center w-full h-6 mt-1">
                                <div className="flex items-center text-xs text-slate-500">
                                    <span>초대날짜 {marketerInvite.created}</span>
                                </div>
                                {isReceived ? (
                                    <span className="text-green-500 text-sm">초대 수락되었습니다.</span>
                                ) : (
                                    <button
                                        onClick={() => {
                                            handleInviteReceive(marketerInvite)
                                        }}
                                        className="px-2 py-0.5 rounded-md bg-nightblue-500 hover:bg-nightblue-600 text-white text-sm"
                                    >
                                        수락
                                    </button>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
