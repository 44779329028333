import Paginator from "../Paginator"
import Utils from "../../utils/Utils"
import { AdvertisementCategories } from "./CommonTypes"

export default class {
    constructor() {
        this.id = -1

        this.sido = ""
        this.sidoEnglish = ""
        this.sidoShort = ""
        this.sigungu = ""
        this.sigunguEnglish = ""
        this.index = -1
    }

    title() {
        if (Utils.isStringNullOrEmpty(this.sigungu)) {
            return this.sido
        }
        return `${this.sido} ${this.sigungu}`
    }

    subtitle() {
        if (Utils.isStringNullOrEmpty(this.sigungu)) {
            return this.sido
        }
        return this.sigungu
    }

    convertByResponse(response) {
        if (Utils.isNullOrUndefined(response)) {
            return
        }

        this.id = response["id"]

        this.sido = response["sido"]
        this.sidoEnglish = response["sido_english"]
        this.sidoShort = response["sido_short"]
        this.sigungu = response["sigungu"]
        this.sigunguEnglish = response["sigungu_english"]
        this.index = response["index"]
    }

    convertToRequest() {
        let request = {
            id: this.id,
            sido: this.sido,
            sido_english: this.sidoEnglish,
            sido_short: this.sidoShort,
            sigungu: this.sigungu,
            sigungu_english: this.sigunguEnglish,
            index: this.index,
        }

        return request
    }
}
