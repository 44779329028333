import React from "react";
import tw from 'tailwind-styled-components'

export const ItemCreatCon = tw.div`
    flex items-center flex-col h-full w-full 
`

export const ItemCreateInnerTop = tw.div`
    flex flex-col h-156 w-[90rem] bg-white 
    rounded-lg drop-shadow-lg 
`

export const ItemCreateTitle = tw.div`
    w-[90rem] h-20 text-3xl flex items-center
`

export const ItemCreateInnerBot = tw.div`
    h-156 w-[90rem] bg-white rounded-lg drop-shadow-lg flex flex-col
`