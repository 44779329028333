import React, { useEffect } from 'react'
import tw from 'tailwind-styled-components'
import {BrandType,ItemCardType} from '../../types/DataEnum' 
import { Link } from 'react-router-dom'
import BrandItem from '../../types/brandItems/BrandItem'
import { ItemSalesTypeNames, MarketerTypeNames, MarketerTypes } from '../../types/brandItems/BrandItemTypes'
import { useState } from 'react'
import { getImageFile } from '../../apis/api/files/index';
import { ImageContainer } from '../ImageContainer'

import {Like} from '@styled-icons/foundation/Like'
import {Buildings} from '@styled-icons/boxicons-regular/Buildings'
import {Reward} from '@styled-icons/fluentui-system-regular/Reward'
import styled from 'styled-components'

const BrandCardBox = tw.div`
    relative group
    h-full p-2
    ${props => `bg-[${props.color}]`} ${props => `hover:bg-[${props.hoverColor}]`}
    duration-300 rounded-2xl
    cursor-pointer
`

export default function BrandCategoryCard(props) {
    return(
        <Link to={'/items'} state={{ filterCategory: props.data.filterCategory, filterID: props.data.filterID }}> 
            <BrandCardBox color={props.data.colorbox} hoverColor={props.data.colorboxHover}>
                <div className="h-3/6 w-full flex flex-col p-2">
                    <div className={`text-sm text-[${props.data.colorIcon}] brightness-75`}>{props.data.smtitle}</div>
                    <div className="text-[1.6rem] text-white font-semibold">{props.data.title}</div>
                    {/* <div className='w-full h-full bg-gray-600 opacity-0 group-hover:opacity-10 absolute absolute-center rounded-xl'/> */}
                    <div className="opacity-0 group-hover:opacity-100 duration-300 mt-1 z-10">
                        {/* <pre className="text-white text-base line-clamp-4">
                                {props.data.description}
                        </pre> */}
                        {/* <p>
                            동해물과 백두산이 마르고 닳도록
                            하느님이 보우하사 우리 나라 만세
                            무궁화 삼천리 화려강산
                            대한사람 대한으로 길이 보전하세
                        </p> */}
                    </div>
                </div>
                <div className="h-3/6 w-full flex items-end justify-end">
                    <div className={`h-32 w-32 rounded-full flex items-center justify-center text-[${props.data.colorIcon}] duration-300`} >
                        {props.data.icon}
                    </div>
                </div>
            </BrandCardBox>
        </Link>

    )
}