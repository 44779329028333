import React, { useEffect, useRef, useState } from "react"
import tw from "tailwind-styled-components"

import { uploadFile, uploadImage } from "../apis/api/files"

// React Quill 관련 모듈
import ReactQuill, { Quill, FormatStatic } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { RangeStatic } from "quill"
import ImageResize from "quill-image-resize-module-react"
import { debounce } from "lodash"
import styled from "styled-components"
import { FONT_SIZES } from "./quill/Consts"
import Toolbar from "./quill/ToolBar"

const EditorCon = tw.div`
flex relative
w-full 
${(props) => `h-[calc(100%-${props.toolbarHeight}px)]`}
`

// 이미지 모듈 등록 (리사이즈, 정렬)
Quill.register("modules/imageResize", ImageResize)

// 폰트 모듈 등록
const Size = Quill.import("formats/size")
Size.whitelist = FONT_SIZES
Quill.register(Size, true)

const BaseImageFormat = Quill.import("formats/image")
const ImageFormatAttributesList = ["alt", "height", "width", "style", "class"]
const STYLE_WHITE_LIST = ["margin", "display", "float"]

// 이미지 포맷 모듈 등록 (이미지 관련 클래스 유지)
class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        const result = ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute)
            }
            return formats
        }, {})
        return result
    }
    format(name, value) {
        // 이미지의 속성이 포함되는지 확인
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            // 속성에 값이 있는 경우만 속성 추가
            if (value) {
                // 이미지 속성의 경우 속성 처리 로직 실행
                if (name === "style") {
                    value = this.sanitize_style(value)
                }
                this.domNode.setAttribute(name, value)
            } else {
                this.domNode.removeAttribute(name)
            }
        } else {
            super.format(name, value)
        }
    }

    sanitize_style(style) {
        let style_arr = style.split(";")
        let allow_style = ""
        style_arr.forEach((v, i) => {
            // 허용되는 css 속성만 포함
            if (STYLE_WHITE_LIST.indexOf(v.trim().split(":")[0]) !== -1) {
                allow_style += v + ";"
            }
        })
        return allow_style
    }
}
Quill.register(ImageFormat, true)

const uploadImageHandler = async (quillRef, files) => {
    if (files.length === 0) {
        return
    }

    let imageFile = null
    imageFile = await uploadImage(files[0])

    if (quillRef.current) {
        // 현재 Editor 커서 위치에 서버로부터 전달받은 이미지 불러오는 url을 이용하여 이미지 태그 추가
        const index = quillRef.current.getEditor().getSelection().index

        const quillEditor = quillRef.current.getEditor()
        quillEditor.setSelection(index, 1)

        quillEditor.clipboard.dangerouslyPasteHTML(index, `<img src=${imageFile.getSource()} />`)
    }
}

const preventGoBack = () => {
    history.pushState(null, "", location.href)
}

const WebEditor = ({ value, setValue, setFocus = null }) => {
    const [toolbarHeight, setToolbarHeight] = useState(0) // 웹에디터 높이에서 툴바높이 만큼 빼야 높이가 맞는다.
    const quillRef = useRef(null)

    useEffect(() => {
        history.pushState(null, "", location.href)
        window.addEventListener("popstate", preventGoBack)
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("popstate", preventGoBack)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        handleResize()
    }, [quillRef])

    const handleResize = debounce(() => {
        if (quillRef !== null) {
            const editorContainer = quillRef.current.editor.container
            const parent = editorContainer.parentElement
            const toolbar = parent.children[0]
            setToolbarHeight(toolbar.offsetHeight)
        }
    }, 300)

    // 이미지 업로드 핸들러, modules 설정보다 위에 있어야 정상 적용
    const imageHandler = () => {
        // file input 임의 생성
        const input = document.createElement("input")
        input.setAttribute("type", "file")
        input.click()

        input.onchange = async () => {
            await uploadImageHandler(quillRef, input.files)
        }
    }

    // useMemo를 사용하지 않고 handler를 등록할 경우 타이핑 할때마다 focus가 벗어남
    const modules = React.useMemo(
        () => ({
            toolbar: {
                // container에 등록되는 순서대로 tool 배치
                // container: "#toolbar",
                container: [
                    [{ font: [] }], // font 설정
                    [{ header: [1, 2, 3, 4, 5, 6, false] }], // header 설정
                    [{ size: Size.whitelist }],
                    ["bold", "italic", "underline", "strike", "blockquote"], // 굵기, 기울기, 밑줄 등 부가 tool 설정
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }], // 리스트, 인덴트 설정
                    ["link", "image", "video"], // 링크, 이미지, 비디오 업로드 설정
                    [{ align: [] }, { color: [] }, { background: [] }], // 정렬, 글씨 색깔, 글씨 배경색 설정
                    ["clean"], // toolbar 설정 초기화 설정
                ],

                // custom 핸들러 설정
                handlers: {
                    image: imageHandler, // 이미지 tool 사용에 대한 핸들러 설정
                },
            },

            imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize", "Toolbar"],
                displayStyles: {
                    backgroundColor: "rgb(219,234,254)",
                    border: "1px solid",
                    color: "rgb(37,99,235)",
                },
            },
        }),
        [],
    )

    // toolbar에 사용되는 tool format
    const formats = [
        "font",
        "header",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align",
        "color",
        "background",
        "clean",
        // 'alt', 'width', 'height', 'style', 'size', 'margin'
    ]

    const handleFocus = (e) => {
        if (setFocus !== null) {
            setFocus(true)
        }
    }
    const handleBlur = (e) => {
        if (setFocus !== null) {
            setFocus(false)
        }
    }

    // 데이터를 delta값으로 받기
    return (
        <EditorCon toolbarHeight={toolbarHeight}>
            {/* <Toolbar /> */}
            <CustomReactQuill
                ref={quillRef}
                className="custom-react-quill"
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                placeholder="내용을 입력하세요."
                onChange={(content, delta, source, editor) => {
                    setValue(editor.getHTML())
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </EditorCon>
    )
}

const CustomReactQuill = tw(ReactQuill)`
    w-full h-full absolute
`

export default WebEditor
