import React, { useEffect, useState } from "react";
import tw from 'tailwind-styled-components'
import {FileEarmarkPdf} from '@styled-icons/bootstrap/FileEarmarkPdf'
import {MyPageContractViewer} from './MyPageContractViewer'
import { getItemContractImages, getItemContractThumbs } from "../../../../apis/api/boardItems";
import BrandItemContract from "../../../../types/brandItems/BrandItemContract";
export const ItemContractLayout = tw.div`
    flex flex-col p-4
    max-lg:h-full max-lg:max-h-full lg:max-h-[90vh]
    bg-white rounded-lg drop-shadow-lg
`

export const ItemContractTitle = tw.span`
    text-2xl font-medium px-4 py-2
`
export const ItemContractContainer= tw.div`
    w-256 h-156 pt-2 overflow-scroll scroll-transparent scroll-overlay
`
export const ItemClauseText = tw.span`
    text-sm select-none
`
export const FileEarmarkIcon = tw(FileEarmarkPdf)`
    h-full w-full text-gray-800
`
export const ContractContainer = tw.div`
    w-full h-48 border-gray-200 border rounded-md cursor-pointer flex flex-col items-end justify-between p-2
    hover:shadow-lg
`


const Contract = ({contract, openViewer}) => {
    const handleOpenView = () => {
        openViewer(contract)
    }

    return(
        <ContractContainer onClick={()=>{handleOpenView()}}>
            <div className="w-full flex-grow flex items-center justify-center">
                <div className="w-full h-28 p-8"><FileEarmarkIcon/></div>
            </div>
            <div className="w-full flex justify-between pt-2">
                <ItemClauseText>{contract.confirmed}</ItemClauseText> 
                <ItemClauseText>{contract.rev}</ItemClauseText>
            </div>
        </ContractContainer>
    )
}

const MyPageItemContract = ({item, showContractImageModal, close}) => {
    const [contracts, setContract] = useState([])
    const [selectedContract, setSelectedContract] = useState(new BrandItemContract())

    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)
    
    useEffect(()=>{
        getItemContractThumbs(item.id, pageIndex, pageSize).then(response => {
            setContract(response.items)
        })
    }, [item])

    // const closeViewer = () => { setShowViwer(false); console.log("hi")}
    const handleOpenView = async (contract) => {
        // getItemContractImages(item.id, contract.rev).then(response => {
        //     showContractImageModal(true, response)
        // })
        // setShowViwer(true)
    }
    
    //여긴 아직 어떻게 할지 모르겠다
    
    return(
        <ItemContractLayout>
            <ItemContractTitle>
                계약서
            </ItemContractTitle>
            <ItemContractContainer>
                <div className="grid grid-cols-4 gap-10 w-full px-4 pb-4">
                    {contracts.map((contract,idx) => (
                        <Contract contract={contract} openViewer={handleOpenView} key={idx}/>
                    ))}
                </div>
            </ItemContractContainer>
            {/* <MyPageContractViewer contract={selectedContract} closeViewer={closeViewer} /> */}
        </ItemContractLayout>
    )
}

export default MyPageItemContract