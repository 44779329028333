import create from 'zustand' // create로 zustand를 불러옵니다.

export const mobileModalItemDetail = create((set) => ({
  openNoticeModal:null,
  setOpenNoticeModal:(openNoticeState) => set({ openNoticeModal: openNoticeState }),
  item:null,
  setItem:(itemState) => set({ item: itemState }),
  user:null,
  setUser :(userState) => set({ user: userState }),
  openMobileItemDetailModal: false,
  setOpenMobileItemDetailModal: (showState) => set({ openMobileItemDetailModal: showState }),
}))

// const openMobileItemDetailModal = mobileModalItemDetail((state) => state.openMobileItemDetailModal)
// const setOpenMobileItemDetailModal = mobileModalItemDetail((state) => state.setOpenMobileItemDetailModal)

{/* <MobileModal isOpen={openMobileItemDetailModal} setIsOpen={setOpenMobileItemDetailModal} canBigger={false} defaultHeight={100} >
                
                </MobileModal> */}