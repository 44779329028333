import tw from 'tailwind-styled-components';
import { PencilSquare } from '@styled-icons/bootstrap/PencilSquare';
import { EditOutline } from '@styled-icons/evaicons-outline/EditOutline';
import { Delete } from '@styled-icons/material/Delete';
import { CheckSquare } from '@styled-icons/boxicons-regular/CheckSquare';
import { Save } from '@styled-icons/entypo/Save';
import {CloseOutline} from "@styled-icons/evaicons-outline/CloseOutline"
import {DataTrending} from '@styled-icons/fluentui-system-regular/DataTrending'
import { MyPageContentHeaderColor, MyPageContentHeaderHeight } from '../MyPageMainStyleParameter';
import { ContentTopSize, ListControlItem, MyPageInputModalLayout } from '../MyPageMainStyle';
import { WriteTypes } from '../../../types/EditorTypes';
import {SortDown} from '@styled-icons/bootstrap/SortDown'
import {SortUpAlt} from '@styled-icons/bootstrap/SortUpAlt'
import {CalculatorMultiple} from '@styled-icons/fluentui-system-filled/CalculatorMultiple'

export const CalcNoneMessageContainer = tw.div`
    absolute absolute-center flex flex-col items-center justify-center
`

export const CalcNoneIconContainer = tw.div`
    w-44 h-44 text-gray-500

`

export const CalcNoneMessage = tw.span`
    font-bold text-gray-600
`


export const CalcGroupIcon = tw(CalculatorMultiple)`
    w-full
`

export const SortDownIcon = tw(SortDown)`
    w-full
`
export const SortUpAltIcon = tw(SortUpAlt)`
    w-full text-sm
`

const CalcRowHeight = "max-lg:h-[2rem] max-lg:min-h-[2rem] max-lg:max-h-[2rem] lg:h-[2.25rem] lg:min-h-[2.25rem] lg:max-h-[2.25rem] max-lg:text-[0.85rem] lg:text-sm"

export const CalcSelectContainer = tw(MyPageInputModalLayout)`
    max-sm:w-screen max-md:w-136 max-md:h-[60vh] md:w-156 md:h-128 max-h-[100vh]
`
export const CalcSelectList = tw.div`
    flex flex-col w-full h-full max-lg:py-0 lg:py-2
    border
    rounded-md bg-slate-100
    overflow-y-scroll scroll-transparent scroll-overlay
`

export const CalcGroupControlBox = tw(ContentTopSize)`
    flex items-center justify-end py-2
    ${(props) => props.writeType !== WriteTypes.WRITE && "hidden"}
`;

export const CalcGridContainer = tw.div`
    w-full lg:h-full
`
export const CalcGridBox = tw.div`
    w-full grid gap-6
    ${(props) => props.gridWidth > 1300 && "gap-6 grid-cols-2"}
    ${(props) => props.gridWidth > 2000 && "grid-cols-3"}
`;
// xl:gap-6 xl:grid-cols-2
// 2xl:grid-cols-3


export const CalcTableCon = tw.div`
    flex flex-col w-full max-lg:h-[18rem] lg:h-[24rem] rounded overflow-hidden drop-shadow-lg  duration-300
`;

export const CalcTableHeaderBox = tw.div`
    flex justify-between items-center w-full px-2
    ${()=>CalcRowHeight} 
    ${()=>MyPageContentHeaderColor}
`;
export const CalcTableTitleBox = tw.div`
    flex items-center h-full py-1
    [&>span]:font-semibold [&>span]:pl-2
`
export const CalcTableTitleInput = tw.input`
    px-2 py-0.5
    bg-nightblue-800 rounded-lg
    focus:ring-2 ring-nightblue-500
    focus:outline-none
`
export const CalcTableTitleIconContainer = tw.div`
    flex flex-center h-full p-1.5
    rounded-lg hover:bg-nightblue-800
`
export const CalcTableTitleCalcEditIcon = tw(EditOutline)`
    h-full
`
export const CalcTableTitleCalcDeleteIcon = tw(Delete)`
    h-full
`
export const CalcTableTitleSaveIcon = tw(Save)`
    h-full py-0.25
`
export const CalcTableTitleCancleIcon = tw(CloseOutline)`
    h-full scale-125
`

export const CalcAddButton = tw.button`
    px-2 py-0.25 rounded bg-nightblue-400 text-white hover:bg-nightblue-500
    ${(props) => props.writeType !== WriteTypes.WRITE && "hidden"}
`;

export const CalcFoldButton = tw.button`
    px-2 py-0.25 rounded bg-nightblue-400 text-white hover:bg-nightblue-500
    ${(props) => props.writeType === WriteTypes.WRITE && "hidden"}
`;

export const CalcTable = tw.div`
    flex flex-col w-full h-full bg-white duration
   transition-opacity duration-300 ease-in-out transform 
   ${(props) => props.isOpen ?"opacity-0 -translate-y-100 ":"opacity-100 translate-y-0"}
`;

export const CalcTableBody = tw.div`
flex flex-col w-full h-[20rem]
overflow-y-scroll scroll-transparent scroll-overlay
`;

export const CalcTableRow = tw.div`
    ${(props) => props.isMobile ? "lg:hidden max-lg:flex" : "max-lg:hidden lg:flex"}
    w-full border-b
    ${()=>CalcRowHeight} 
`;
export const CalcColumnBox = tw.div`
    flex w-full border-b
    ${()=>CalcRowHeight} 
`

export const CalcColumnCell = tw.div`
    ${(props) => props.mobilehide ? "max-lg:hidden lg:flex" : "flex"}
    bg-gray-150 items-center h-full justify-center font-medium
`;

export const CalcValueCell = tw.div`
    ${(props) => props.mobilehide ? "max-lg:hidden lg:flex" : "flex"}
    items-center justify-center cursor-pointer truncate
    h-full
`;

export const CalcEditButton = tw.button`
    w-14 rounded text-gray-200 hover:text-white 
`;

export const CalcGroupAddButton = tw(ListControlItem)`
    px-4 py-1 bg-amber-300 text-amber-900 hover:bg-amber-400 cursor-pointer
    select-none
    max-lg:text-sm lg:text-base
`;




export const CalcDetailIconStyle = "h-6 px-2 py-1 rounded-md text-nightblue-600 hover:text-nightblue-500 hover:bg-gray-100 duration-200"
export const CalcDetailUpdateIcon = tw(PencilSquare)`
    ${()=>CalcDetailIconStyle}
`;
export const CalcDetailSelectIcon = tw(CheckSquare)`
    ${()=>CalcDetailIconStyle}
`;
export const CalcDetailPerformIcon = tw(DataTrending)`
    ${()=>CalcDetailIconStyle} py-0.5
`;
export const CalcDetailDeleteIcon = tw(Delete)`
    ${()=>CalcDetailIconStyle}
`;


export const CalcEditLayout = tw(MyPageInputModalLayout)`
    ${(props) => props.isMobile ? "w-full h-full" : "w-96 h-auto"}
    
`

export const SortIconContainer = tw.div`
    w-5 
`

// export const SortButton = tw.div`
//     px-3 pb-[5px] pt-[2.4px] bg-white rounded-lg text-gray-600 hover:bg-blue-400 mr-2 cursor pointer
//     drop-shadow
// `
export const SortButton = tw(ListControlItem)`
    mr-2 p-2 aspect-square
    cursor-pointer
`