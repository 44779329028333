import React from "react"
import { UserTypes } from "../types/users/UserTypes"
import { useUserStore } from "../zustand/store"
import { EditStateTypes } from "../types/EditorTypes"

export class AccessRules {
    static marketer(user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (user.userType === UserTypes.MARKETER) {
            return true
        }

        return false
    }
    // 브랜드 아이템 페이지 신청하는 부분 (신청하지않은 마케터만 신청)
    static brandItemDetailRegist(marketerStatusType, user = null) {
        if (user === null) {
            user = useUserStore().user
        }
        if (marketerStatusType === null && (user.userType === UserTypes.MARKETER || user.userType === UserTypes.NONE)) {
            return true
        }
        return false
    }

    static noticeWrite() {
        const { user } = useUserStore()

        if (user.userType === UserTypes.STAFF) {
            return true
        }

        return false
    }

    static staff(user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (user.userType === UserTypes.STAFF) {
            return true
        }

        return false
    }

    static brandItemManagement(user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (user.userType === UserTypes.BRAND || user.userType === UserTypes.STAFF) {
            return true
        }

        return false
    }
    static brandItemUpdate(item, user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (
            (user.userType === UserTypes.BRAND && item.brand.user.id === user.id) ||
            user.userType === UserTypes.STAFF
        ) {
            return true
        }

        return false
    }
    static brandItemStatusWait(item, user) {
        // 아이템 상태를 일반으로 바꿀 수 있는 권한

        if (
            user.userType === UserTypes.BRAND &&
            (item.editStateType === EditStateTypes.DISABLED || item.editStateType === EditStateTypes.WAIT) &&
            item.editStateType !== EditStateTypes.PENDING &&
            item.id >= 0
        ) {
            // 브랜드는 일시 정지 상태일때만 일반으로 바꿀 수 있다.
            return true
        } else if (user.userType === UserTypes.STAFF) {
            return true
        }

        return false
    }
    static brandItemStatusDisabled(item, user) {
        // 아이템 상태를 일시정지로 바꿀 수 있는 권한
        if (user.userType === UserTypes.BRAND && item.editStateType !== EditStateTypes.FAILED && item.id >= 0) {
            // 브랜드는 일시 정지 상태일때만 일반으로 바꿀 수 있다.
            return true
        } else if (user.userType === UserTypes.STAFF) {
            return true
        }

        return false
    }
    static performManagement(item, user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (user.userType === UserTypes.BRAND && item.brand.user.id === user.id) {
            return true
        }

        return false
    }
    // 아이템을 이용하는 마케터
    static performCreate(user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (user.userType === UserTypes.MARKETER) {
            return true
        }

        return false
    }
    static performUpdate(perform) {
        const { user } = useUserStore()

        if (user.userType === UserTypes.MARKETER && perform.marketer.user.id === user.id) {
            return true
        }

        return false
    }

    static performValueWrite(column, perform, user = null) {
        if (user === null) {
            user = useUserStore().user
        }

        if (perform.isConfirmed && !column.isEditableConfirmed) {
            return false
        }

        if (user.userType === UserTypes.MARKETER && !column.marketerReadOnly) {
            return true
        }
        if (user.userType === UserTypes.BRAND && !column.brandReadOnly) {
            return true
        }

        return false
    }
    static performSubValueWrite(column) {
        const { user } = useUserStore()

        if (user.userType === UserTypes.MARKETER && !column.marketerReadOnly) {
            return true
        }
        if (user.userType === UserTypes.BRAND && !column.brandReadOnly) {
            return true
        }

        return false
    }
    static marketerHasGroup(marketerHasGroup, user = null) {
        if (user === null) {
            user = useUserStore().user
        }
        if (user.userType === UserTypes.MARKETER && marketerHasGroup) {
            return true
        }
        return false
    }
    static marketerGroupMaster(marketerGroup, user = null, staff = false) {
        if (user === null) {
            user = useUserStore().user
        }

        // 그룹 장이거나 staff 옵션이 true면 스태프도 가능.
        if (
            (user.userType === UserTypes.MARKETER && user.id === marketerGroup.masterMarketer.user.id) ||
            (staff && user.userType === UserTypes.STAFF)
        ) {
            return true
        }

        return false
    }
}

export default AccessRules
