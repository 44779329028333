import React, { useState, useEffect, useCallback } from "react"
import { getKakaoToken } from "../../apis/api/users"
import { setUserToken } from "../../apis/services/users"
import { useNavigate } from "react-router-dom"
import { useUserStore } from "../../zustand/store"
import Utils from "../../utils/Utils"

export const KakaoAuth = () => {
    const code = new URL(window.location.href).searchParams.get("code")
    const navigation = useNavigate() //변수 할당시켜서 사용
    const { actions } = useUserStore()

    useEffect(() => {
        handleLogin(code)
    }, [])

    // const getAccessTokenByKakao = async (code) => {
    //     let user = undefined

    //     await getKakaoToken(code)
    //         .then((response) => {
    //             console.log(response)
    //             user = setUserToken(response)
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             alert("이메일 또는 아이디를 확인 해주시기 바랍니다.")
    //         })

    //     return user
    // }

    const handleLogin = async (code) => {
        let result = false

        const response = await getKakaoToken(code)

        if (response === "duplicate_user") {
            alert("이미 가입되어있는 회원입니다.")
            navigation("/")
            return
        }

        try {
            const user = setUserToken(response)
            if (user !== undefined) {
                result = true

                actions.setEmail(user.email)
                actions.setName(user.name)

                if (Utils.isStringNullOrEmpty(user.tel)) {
                    navigation("/signup/marketer_oauth")
                } else {
                    actions.setUser(user)
                    actions.setUserType(user.userType)
                    navigation("/")
                }
            }
        } catch {
            alert("실패했습니다.")
            navigation("/")
        }

        return result
    }
}

export default KakaoAuth
