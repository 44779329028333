import React, { useState, useEffect } from "react";
import MyInfoMarketer from "../../pages/myPage/myinfo/MyInfoMarketer";
import {MyInfoMarketerMore} from '../../pages/myPage/myinfo/MyInfoMarketerMore'
import { deleteUserProfileAvatar, getUserDetail as getUserDetailApi, updateUserProfileAvatar } from "../../apis/api/users";
import { useUserStore } from "../../zustand/store";
import tw from 'tailwind-styled-components'
import MyInfoMarketerCareer from "../../pages/myPage/myinfo/MyInfoMarketerCareer";
import { useNavigate } from "react-router-dom";
import { marketerApplyModal } from '../../zustand/modalstore'
import { ApplyWarnningMessage, MarketerDetailContainer, MarketerDetailInputBox, MarkterDetailButton, MarkterDetailButtonBox } from "./MarketerDetailStyle";
import Utils from "../../utils/Utils";


export default function MarketerDetail() {
    const [userDetail, setUserDetail] = useState(null)
    const [applyMarketerData, setApplyMarketerData] = useState(false)
    const [submitFlag , setSubmitFlag] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const { user, actions } = useUserStore()
    const itemID = marketerApplyModal((state) => state.itemID)
    const setMarketerApplyModal = marketerApplyModal((state) => state.setMarketerApplyModal)
    const navigate = useNavigate();
    
    const getUserDetail = () => {
        getUserDetailApi(user.id).then(response => {
            setUserDetail(response)
        })
    }
    useEffect(() => {
        getUserDetail()
    }, [user])
    
    useEffect(() => {
        if(isSubmitted === true){
            if (applyMarketerData.detail.location1 > 0 && 
                !Utils.isStringNullOrEmpty(applyMarketerData.user.birthday) && 
                applyMarketerData.user.gender != 2
            ) {
                navigate(`/apply/${itemID}`)
                setMarketerApplyModal(false)
            }
            else {
                alert('추가정보를 모두 입력 하고 저장 해주시기 바랍니다.')
            }
            setIsSubmitted(false)
        }
    },[isSubmitted])
    
    const marketerApply = () => {
        setSubmitFlag(true)
    }

    return (
        <MarketerDetailContainer>
            <ApplyWarnningMessage>※ 필수 정보를 입력 후 저장 버튼을 눌러 주시기 바랍니다.</ApplyWarnningMessage>
            <MarketerDetailInputBox>
                <MyInfoMarketerMore 
                    marketer={userDetail} isApply={true} 
                    submitFlag={submitFlag} setSubmitFlag={setSubmitFlag} 
                    isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted}
                    setApplyMarketerData={setApplyMarketerData} 
                />
            </MarketerDetailInputBox>
            {/* <MarketerDetailInputBox>
                <MyInfoMarketerCareer marketer={userDetail} isApply={true}  />
            </MarketerDetailInputBox> */}
            <MarkterDetailButtonBox>
                <MarkterDetailButton onClick={() => { marketerApply() }}>저장 후 신청</MarkterDetailButton>
            </MarkterDetailButtonBox>
        </MarketerDetailContainer>
    )
}