import tw from "tailwind-styled-components"
import styled from "styled-components"
import { AlertFill } from "@styled-icons/octicons/AlertFill"
import { EditStateTypes } from "../../../../types/EditorTypes"
import { ListControlItem, MyPageInputModalLayout } from "../../MyPageMainStyle"
import { ItemEditTabTypes } from "../../../../types/brandItems/BrandItemTypes"
import { PerformTableSearchBox } from "../../perform/MyPagePerformStyle"
import { TooltipHoverBox } from "../../../../components/brandItems/InformationHover"

export const ItemLayout = tw.div`
    w-full h-full p-1
`

export const ItemInnerTop = tw.div`
    w-full h-14 flex items-center justify-between mt-6
`

export const ItemList = tw.div`
    w-full h-fit
`
export const ItemInnerBottom = tw.div`
    w-full h-fit flex justify-center mt-6
`

export const ItemSearchBox = tw(ListControlItem)`
    max-lg:w-full lg:w-56
`

export const ItemWriteLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen max-md:w-screen max-lg:w-screen lg:w-[64rem]
    max-sm:h-full sm:h-[90vh]
    max-md:rounded-none md:rounded-md
    max-lg:rounded-none lg:rounded-md
    p-0 overflow-hidden
    relative
`

export const ItemWriteTitle = tw.span`
    text-xl font-medium px-4 py-2
`

export const ItemWriteViewTypeButton = tw.button`
    font-medium mr-2
    ${(props) => (props.isShow ? "text-black" : "text-gray-500")}
`

export const ItemStatusComboBox = tw.div`
    flex items-center text-sm
`
export const ItemStatusComboTitle = tw.button`
    pr-2 py-0.5
    hover:bg-gray-300 rounded-full
`
export const ItemStatusComboSelectBox = tw.div`
    flex px-1 py-0.5 ml-2 w-24 h-6
    bg-white rounded-md focus:outline-none
    ${(props) => props.editState !== EditStateTypes.WAIT && "ring-2"}
    ${(props) => props.editState === EditStateTypes.EDITED && "ring-yellow-500"}
    ${(props) => props.editState === EditStateTypes.PENDING && "ring-orange-500"}
    ${(props) => props.editState === EditStateTypes.DONE && "ring-green-500"}
    ${(props) => props.editState === EditStateTypes.FAILED && "ring-red-500"}
`
export const ItemStatusComboSelect = tw.select`
    w-full focus:outline-none
`
export const ItemStatusComboApplyButton = tw.button`
    px-2 py-0.5 mx-1
    font-medium
    hover:bg-gray-50 rounded-md
    disabled:hover:bg-transparent disabled:text-gray-500
`

export const ItemViewToggleBox = tw.div`
    flex items-center relative w-36 h-6 mx-2
    bg-gray-300 rounded-full overflow-hidden
    first:left-0
`
// 현재 last child가 인식되지 않는다. (230413)
export const ItemViewToggleButton = tw.button`
    w-[52%] px-2 py-0.5 absolute
    font-medium text-sm
    text-black
    bg-gray-300 
    ${(props) => (props.isShow ? "bg-white rounded-full z-10" : "hover:bg-gray-400")}
    ${(props) => props.isLeft && "left-0"}
    ${(props) => props.isRight && "right-0"}
`
export const ItemViewToggleStackButton = tw.button`
    absolute z-0
    w-full h-full px-2 py-0.5
    font-medium text-sm text-black
    rounded-full bg-white
    ${(props) => (props.isShow ? "z-10" : "")}
`

export const ItemSubmitControlBox = tw.div`
    flex max-sm:ml-auto max-md:text-sm md:text-base
`

export const ItemEditorMessageContainer = tw.div`
    absolute z-10 -bottom-8 left-0
    flex justify-center w-full
    bg-transparent
`
export const ItemEditorMessage = tw.span`
    text-xs text-red-400
`

export const ItemEditorTabBar = tw.div`
    relative z-30
    flex w-fit mt-2 ml-[1px]
    rounded ring-1 ring-slate-300
`

export const ItemEditorMenuButton = tw.button`
    mt-2 px-3 py-1 
    rounded bg-slate-100 
    border border-slate-300 
    text-sm text-slate-600
`
export const AdLevelButton = tw.button`
    flex flex-center px-2 py-0.5
    rounded-full border border-orange-500
    ${(props) => (props.isActive ? "bg-orange-500 text-white" : "bg-white hover:bg-slate-100 text-orange-500")}
    text-sm
`
export const AdLevelMinusButton = tw.button`
    flex flex-center px-2 py-0.5
    rounded-full border border-slate-500
    ${(props) => (props.isActive ? "bg-slate-500 text-white" : "bg-white hover:bg-slate-100 text-slate-500")}
    text-sm
`

const IItemEditorTabButton = tw.div`
    max-sm:w-20 sm:w-32 h-full
    duration-300
`
export const ItemEditorTabButton = tw(IItemEditorTabButton)`
    group z-10
    relative cursor-pointer
    flex flex-center h-8
    bg-transparent rounded
    ${(props) => (props.isActive ? "text-blue-500" : "text-gray-400")}
    font-normal
    max-sm:text-sm sm:text-base
`
export const ItemEditorTabIndexBox = tw.div`
    flex flex-center max-sm:w-3 sm:w-5 sm:mr-1.5 aspect-square
    rounded-full
    font-normal
    sm:border sm:text-xs
    ${(props) =>
        props.isActive ? "sm:bg-blue-500 sm:border-blue-500 sm:text-white" : "sm:border-gray-400 sm:text-gray-400"}
    max-sm:text-sm
    ${(props) => (props.isActive ? "max-sm:text-blue-500" : "max-sm:text-gray-400")}
    duration-300
`
export const ItemEditorTabButtonArrowSvg = tw.svg`
    h-8 stroke-0.5 stroke-slate-300
`
export const ItemEditorTabWarningBox = tw.div`
    absolute -top-2 left-2
    flex flex-center w-4 h-4 rounded-full
    text-red-600
`
export const ItemEditorTabWarningIcon = tw(AlertFill)`
    h-full
`
export const ItemEditorTabWarningHover = tw.div`
    hidden group-hover:flex
    absolute z-50 top-10 max-sm:left-0 sm:left-0
    flex-col w-[max-content] px-3 pt-2 pb-1
    rounded-md bg-white
    drop-shadow-xl border
    font-normal text-sm
`
export const ItemEditorTabWarningHoverMessage = tw.span`
    flex items-center h-4 mb-1 z-0
    text-red-600
`
export const ItemEditorTabWarningHoverMessage2 = tw.span`
    mb-1 z-0
    text-slate-800
`

const IItemEditorTabGuide = tw(IItemEditorTabButton)`
    absolute
    ${(props) => props.tabIndex === ItemEditTabTypes.BASE && "left-0"}
    ${(props) => props.tabIndex === ItemEditTabTypes.DESCRIPTION && "max-sm:left-20 sm:left-24"}
    ${(props) => props.tabIndex === ItemEditTabTypes.MISSION_REWARD && "max-sm:left-40 sm:left-48"}
    ${(props) => props.tabIndex === ItemEditTabTypes.CONTRACT && "max-sm:left-60 sm:left-72"}
`
export const ItemEditorTabGuide = tw(IItemEditorTabGuide)`
    rounded-full bg-nightblue-500
`
export const ItemEditorTabGuide2 = tw(IItemEditorTabGuide)`
    border-b-2 border-nightblue-600
    will-change-transform
`

export const ItemEditorTabContentContainer = tw.div`
    relative
    flex w-full h-full
`
export const ItemEditorTabContent = tw.div`
    absolute
    flex flex-col w-full h-full max-md:px-1 md:px-2 pt-2
    ${(props) => (props.isActive ? "opacity-100 z-10" : "opacity-0 -z-10")}
`
export const ItemEditorTabContentScroll = tw(ItemEditorTabContent)`
    overflow-y-auto scroll-transparent scroll-overlay
`

export const OpenAgreementLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen sm:w-[640px]
    min-h-[32rem] max-h-[90vh]
    max-md:rounded-none md:rounded-md
    max-lg:rounded-none lg:rounded-md
    overflow-hidden
    relative
`

export const ItemListStatusFilterButton = tw(ListControlItem)`
    relative
    flex-shrink-0 px-3 max-lg:h-7 lg:h-8
    ${(props) => (props.isSelected ? "bg-white" : "bg-slate-200 text-slate-500")}
    text-sm
    cursor-pointer
    duration-300 select-none
`
export const ItemListAdTypeFilterButton = tw.div`
    relative
    flex flex-center flex-shrink-0 px-3 max-lg:h-6 lg:h-7
    rounded-full border border-orange-500
    ${(props) => (props.isSelected ? "bg-orange-500 text-white" : "bg-white text-orange-500")}
    text-sm
    cursor-pointer select-none
`
export const ItemListAdTypeMinusFilterButton = tw.div`
    relative
    flex flex-center flex-shrink-0 px-3 max-lg:h-6 lg:h-7
    rounded-full border border-slate-500
    ${(props) => (props.isSelected ? "bg-slate-500 text-white" : "bg-white text-slate-500")}
    text-sm
    cursor-pointer select-none
`
export const ItemListStatusFilterCount = tw.div`
    absolute -top-2 -right-2 z-10
    flex flex-center h-5 aspect-square
    rounded-full bg-rose-500
    text-xs text-white
`

export const MarketerMatchingMenuBox = tw.div`
    flex flex-col h-28 p-3
    rounded border
    ${(props) =>
        props.isSelected ? "bg-blue-100 border-blue-200" : "hover:bg-blue-100 border-transparent hover:border-blue-200"}
    duration-200 cursor-pointer
    [&>h3]:mb-1 [&>h3]:text-base [&>h3]:font-medium
    [&>p]:text-sm [&>p]:text-slate-800
    [&>span]:mt-auto [&>span]:text-xs [&>span]:text-nightblue-500
`

export const MarketerMatchingSettingLayout = tw.div`
    ${(props) => (props.isShow ? "grid" : "max-lg:grid lg:hidden")}
    max-sm:grid-cols-1 sm:grid-cols-2 gap-4 w-full max-lg:p-4
    rounded
    max-lg:bg-white max-lg:border max-lg:border-slate-200
`
export const MarketerMatchingSettingTitleBox = tw.div`
    max-sm:flex sm:hidden flex-col w-full
`
export const MarketerMatchingSettingSubTitle = tw.div`
    sm:col-span-2
    flex items-center max-lg:pb-2 lg:py-2
    font-semibold text-lg text-nightblue-700
`

export const MarketerMatchingSettingConfirmButton = tw.div`
    flex flex-center px-6 h-full
    text-center text-white
    rounded-md bg-nightblue-400 hover:bg-nightblue-500
    cursor-pointer duration-200
`
