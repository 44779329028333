import React, { useEffect } from "react"
import tw from "tailwind-styled-components"
import { ItemBody } from "./MyPageItemStyle"
import MyPageItemAd from "./ad/MyPageItemAd"
import { ContentContainer } from "../MyPageMainStyle"
import { PmsNavState } from "../../../types/DataEnum"
import MyPageAdvertisement from "./advertisement/MyPageAdvertisement"

export default function MyPageItemAdvertisementContainer({ setPmsNavState }) {
    useEffect(() => {
        setPmsNavState(PmsNavState.ADVERTISEMENT)
    }, [])
    return (
        <ContentContainer>
            <MyPageAdvertisement />
        </ContentContainer>
        // <ItemBody>
        // </ItemBody>
    )
}
