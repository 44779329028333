import React, { useEffect, useRef, useState } from "react"
import { EditStateTypes, ItemTypes, QueryTypes } from "../../../types/EditorTypes"
import { TextFormats } from "../../../types/DataEnum"
import {
    AcceptButton,
    BottomButtonCon,
    DeleteButton,
    EditAcceptButton,
    EditAcceptContainer,
    EditAcceptMenuBox,
    EditAcceptMenuContainer,
    EditAcceptPreviewButton,
    ItemControlBox,
    ItemInputRow,
    ItemInputRowEditor,
    ItemStatusControlBox,
    PreviewButton,
    ResetButton,
    SaveButton,
} from "../item/brand/ItemInputFormStyle"

import {
    BrandItemCombo,
    BrandItemFile,
    BrandItemInput,
    BrandItemEditor,
    BrandItemTextArea,
    BrandItemImage,
} from "../../../components/brandItems/Inputs"
import Utils from "../../../utils/Utils"
import { uploadImage } from "../../../apis/api/files/index"
import Coupon from "../../../types/points/Coupon"
import GiftiCoupon from "../../../types/points/GiftiCoupon"
import * as PointApi from "../../../apis/api/points/index"
import { CouponWriteLayout, PointModalTitle } from "./MyPagePointStyle"
import { CouponApplicationStatusTypeNames, CouponApplicationStatusTypes } from "../../../types/points/PointTypes"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import ModalContainerPortal from "../../../components/ModalContainerPortal"
import MyPageGiftiCouponList from "./MyPageGiftiCouponList"
import { ImageContainer } from "../../../components/ImageContainer"

const MyPageCouponWrite = ({ couponID, showModal, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)
    const [queryType, setWriteType] = useState(QueryTypes.NONE)
    const [coupon, setCoupon] = useState(new Coupon())

    const [title, setTitle] = useState("")
    const [thumbnailImageID, setThumbnailImageID] = useState(-1)
    const [price, setPrice] = useState(0)
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [requireRecommands, setRequireRecommands] = useState(0)
    const [requirePoint, setRequirePoint] = useState(0)
    const [applicationType, setApplicationType] = useState(CouponApplicationStatusTypes.APPLY)

    const [image1EditStateType, setThumbnailImageEditStateType] = useState(EditStateTypes.WAIT)

    const [giftiCoupon, setGiftiCoupon] = useState(new GiftiCoupon())
    const [showGiftiCouponModal, setShowGiftiCouponModal] = useState(false)

    useEffect(() => {
        if (!showModal) {
            return
        }

        setWriteType(couponID >= 0 ? QueryTypes.UPDATE : QueryTypes.CREATE)

        if (couponID >= 0) {
            getItemData()
        }
    }, [couponID, showModal])

    const getItemData = async () => {
        // 아이템 등록시에는 temp만 보여준다.
        setLoading(true)

        const _coupon = await PointApi.getCoupon(couponID)
        setCoupon(_coupon)

        if (_coupon.id < 0) {
            setLoading(false)
            return
        }

        setTitle(_coupon.title)
        setThumbnailImageID(_coupon.thumbnailImageID)
        setPrice(_coupon.price)
        setDateStart(_coupon.dateStart)
        setDateEnd(_coupon.dateEnd)
        setRequireRecommands(_coupon.requireRecommands)
        setRequirePoint(_coupon.requirePoint)
        setApplicationType(_coupon.applicationType)
        setGiftiCoupon(_coupon.giftiCouponThumb)
        setLoading(false)
    }

    const handleSubmit = async () => {
        let response = new Coupon()

        let errorMessages = []
        if (Utils.isStringNullOrEmpty(title)) {
            errorMessages.push("제목을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(dateStart) || Utils.isStringNullOrEmpty(dateEnd)) {
            errorMessages.push("판매 기간을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(price)) {
            errorMessages.push("가격을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(requireRecommands)) {
            errorMessages.push("최소 조건을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(requirePoint)) {
            errorMessages.push("최소 조건을 입력해주세요.")
        }

        if (errorMessages.length > 0) {
            alert(errorMessages.join("\n"))
            return
        }

        let requestData = coupon.convertToRequest()
        requestData["title"] = title
        // requestData["thumbnail_image_id"] = thumbnailImageID
        requestData["price"] = price
        requestData["date_start"] = dateStart
        requestData["date_end"] = dateEnd
        requestData["require_recommands"] = requireRecommands
        requestData["require_point"] = requirePoint
        requestData["application_type"] = applicationType
        requestData["gifti_coupon_thumb_id"] = giftiCoupon.goodsCode

        if (queryType === QueryTypes.UPDATE) {
            response = await PointApi.updateCoupon(couponID, requestData)
        } else if (queryType === QueryTypes.CREATE) {
            response = await PointApi.createCoupon(requestData)
        }

        if (response.id >= 0) {
            alert("저장되었습니다.")
            // getItemData()
            setShowModal(false)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    const handleItemDelete = async () => {
        // 스태프만 삭제가 가능하다. (논리삭제)
        let bResult = false

        if (queryType === QueryTypes.UPDATE) {
            bResult = await PointApi.deleteCoupon(coupon.id)
        }

        if (bResult) {
            alert("삭제 완료되었습니다.")
            setShowModal(false)
        } else {
            alert("삭제 실패했습니다.")
        }
    }

    const handleThumbnailImage = (e, setImageID) => {
        if (e.target.files.length !== 1) {
            return
        }
        setThumbnailImageEditStateType(EditStateTypes.PENDING)
        uploadImage(e.target.files[0])
            .then((response) => {
                setImageID(response.id)
                setThumbnailImageEditStateType(EditStateTypes.WAIT)
            })
            .catch((error) => {
                uploadImage(e.target.files[0])
                setThumbnailImageEditStateType(EditStateTypes.FAILED)
            })
    }

    return (
        <CouponWriteLayout className="sm:h-auto sm:max-h-[90vh]">
            <SpinLoading isLoading={false} top={"0"} isScreen={false} />

            <div className="flex flex-col w-full h-full p-4 overflow-y-auto scroll-transparent scroll-overlay">
                <PointModalTitle>쿠폰 {queryType === QueryTypes.UPDATE ? "수정" : "등록"}</PointModalTitle>
                <div className="flex flex-col w-full p-4">
                    <ItemInputRow className="md:grid-cols-1">
                        <BrandItemInput
                            type="text"
                            label={"제목"}
                            data={title}
                            setData={setTitle}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="md:grid-cols-2">
                        <BrandItemInput
                            type="number"
                            label={"판매 포인트"}
                            data={price}
                            setData={setPrice}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            helpText=""
                        />
                        <BrandItemCombo
                            label={"구입 시 상태"}
                            optionKeys={[CouponApplicationStatusTypes.APPLY, CouponApplicationStatusTypes.DONE]}
                            names={[
                                CouponApplicationStatusTypeNames[CouponApplicationStatusTypes.APPLY],
                                CouponApplicationStatusTypeNames[CouponApplicationStatusTypes.DONE],
                            ]}
                            value={applicationType}
                            onChange={(e) => {
                                setApplicationType(e.target.value)
                            }}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="md:grid-cols-2">
                        <BrandItemInput
                            type="date"
                            label={"판매 기간 (시작)"}
                            data={dateStart}
                            setData={setDateStart}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                        <BrandItemInput
                            type="date"
                            label={"판매 기간 (종료)"}
                            data={dateEnd}
                            setData={setDateEnd}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="md:grid-cols-2">
                        <BrandItemInput
                            type="number"
                            label={"최소조건: 누적 추천인 수"}
                            data={requireRecommands}
                            setData={setRequireRecommands}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                        <BrandItemInput
                            type="number"
                            label={"최소조건: 누적 포인트"}
                            data={requirePoint}
                            setData={setRequirePoint}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                    </ItemInputRow>
                    <div className="flex flex-col w-full">
                        <button
                            className="w-full h-10 rounded-full bg-red-500 hover:bg-red-600 duration-200 text-white"
                            onClick={() => {
                                setShowGiftiCouponModal(true)
                            }}
                        >
                            기프티쇼 쿠폰 선택
                        </button>

                        {!Utils.isStringNullOrEmpty(giftiCoupon.goodsCode) && (
                            <div className="flex w-full h-32 mt-2 p-4 bg-slate-100 rounded-lg">
                                <div className="flex flex-col w-full">
                                    <span className="font-semibold text-black">{giftiCoupon.goodsName}</span>
                                    <span className="flex items-center font-light text-slate-700 text-sm mt-auto">
                                        {giftiCoupon.brandName}
                                    </span>
                                    <span className="font-light text-slate-500 text-xs">
                                        {giftiCoupon.goodsTypeDtlNm} - {giftiCoupon.goodsTypeNm}
                                    </span>
                                    <div className="flex items-center text-sm">
                                        <span className="text-slate-700 font-light mr-1">쿠폰 실제가</span>
                                        <span>{Utils.textFormat(giftiCoupon.realPrice, TextFormats.PRICE)}</span>
                                        <span className="text-slate-700 font-light mx-1">쿠폰 실제가(할인반영)</span>
                                        <span>{Utils.textFormat(giftiCoupon.discountPrice, TextFormats.PRICE)}</span>
                                    </div>
                                </div>
                                <div className="aspect-square flex-shrink-0 h-full ml-2 rounded bg-white drop-shadow-lg overflow-hidden">
                                    <img
                                        className="w-full h-full object-cover bg-center"
                                        src={giftiCoupon.goodsImgB}
                                        alt={null}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <ItemInputRowEditor className="min-h-[280px]">
                        <BrandItemImage
                            type="file"
                            label={"이미지"}
                            fileID={thumbnailImageID}
                            onChange={(e) => {
                                handleThumbnailImage(e, setThumbnailImageID)
                            }}
                            disabled={false}
                            editStateType={image1EditStateType}
                            imageWidth={500}
                            imageHeight={500}
                        />
                    </ItemInputRowEditor> */}
                </div>

                <div className="flex justify-between items-center w-full h-12 px-4">
                    {queryType === QueryTypes.UPDATE ? (
                        <DeleteButton
                            onClick={() => {
                                handleItemDelete()
                            }}
                        >
                            쿠폰 삭제
                        </DeleteButton>
                    ) : (
                        <p></p>
                    )}
                    <button
                        className="flex flex-center px-6 py-2 text-white bg-nightblue-600 hover:bg-nightblue-700 rounded-full"
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        저장
                    </button>
                </div>
            </div>
            <ModalContainerPortal
                show={showGiftiCouponModal}
                setShow={setShowGiftiCouponModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageGiftiCouponList
                    selectedGiftiCouponCode={giftiCoupon.goodsCode}
                    selectGiftiCoupon={(g) => {
                        setGiftiCoupon(g)
                    }}
                    showModal={showGiftiCouponModal}
                    setShowModal={setShowGiftiCouponModal}
                    // userType={userType}
                    // defaultUser={new User()}
                />
            </ModalContainerPortal>
        </CouponWriteLayout>
    )
}

export default MyPageCouponWrite
