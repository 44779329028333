import { uploadImage } from "../../apis/api/files"

// CKEditor 이미지 업로드에 사용되는 어댑터
export default class ImageUploadAdapter {
    constructor(loader) {
        this.loader = loader
    }

    upload() {
        return this.loader.file.then(file => {
            return new Promise((resolve, reject) => {
                uploadImage(file).then(imageFile => {
                    resolve({
                        default: imageFile.getSource()
                    })
                }).catch(error => {
                    reject(error)
                })
            })
        })
    }
}