import React, { useEffect, useState } from "react";
import { PmsOfficeNavState, PerformCalcFoldType } from '../../../types/DataEnum'
import { CalcTableList } from '../../../constants/ConstCalc'
import { CalcGroupAddButton, SortDownIcon, SortUpAltIcon, CalcValueCell, CalcFoldButton, CalcGridBox, CalcAddButton, CalcTable, CalcTableBody, CalcTableCon, CalcTableRow, CalcTableHeaderBox, CalcColumnCell, CalcGroupControlBox, CalcDetailUpdateIcon, CalcTableTitleInput, CalcTableTitleBox, CalcTableTitleIconContainer, CalcTableTitleCalcEditIcon, CalcTableTitleSaveIcon, CalcTableTitleCancleIcon, CalcColumnBox, CalcGridContainer, CalcDetailSelectIcon, CalcDetailPerformIcon, CalcTableTitleCalcDeleteIcon, CalcDetailDeleteIcon, SortIconContainer, SortButton, CalcGroupIcon, CalcNoneMessageContainer, CalcNoneIconContainer, CalcNoneMessage } from "./MyPageCalcStyle";
import { ContentContainer } from "../MyPageMainStyle";
import { addCalc, addCalcGroup, deleteCalc, deleteCalcGroup, getCalculateGroups, getPerformCalc, updateCalc, updateCalcGroup } from "../../../apis/api/boardItems";
import PerformCalculateGroup from "../../../types/brandItems/calculates/PerformCalculateGroup";
import PerformCalculate from "../../../types/brandItems/calculates/PerformCalculate";
import { EditStateTypes, SortTypes, WriteTypes } from "../../../types/EditorTypes";
import { ModalContainer } from "../../../components/ModalContainer";
import MyPageCalcEdit from "./MyPageCalcEdit";
import { useGettingWidth } from '../../../hooks/useGettingWidth';
import { Link, useNavigate } from "react-router-dom";
import BackendResponse from "../../../constants/BackendResponse";
import { AccessRules } from '../../../rules/AccessRules';
import { useUserStore } from "../../../zustand/store";
import SortSwitch from "../../../components/SortSwitch";
import Utils from "../../../utils/Utils";
import MyPageCalcGroupAdd from "./MyPageCalcGroupAdd";
import { MobileModal } from "../../../components/MobileModal";
import { RESPONSIVE_WIDTH } from './../../../constants/ConstUI';
import { UserTypes } from './../../../types/users/UserTypes';

const CalcCellWidth = {
    ID: "max-lg:min-w-[2rem] lg:min-w-[4rem]",
    Title: "max-lg:w-full lg:min-w-[6rem]",
    Description: "w-full",
    Calculated: "max-lg:min-w-[6rem] lg:min-w-[8rem]",
    Perform: "min-w-[4rem]",
    Detail: "min-w-[4rem]",
    Delete: "min-w-[4rem]",
}

const CalTableRowContainer = ({ children,user, setShowEdit, setEditCalc, calc }) => {
    const handleOpenCalcModal = () => {
            setEditCalc(calc)
            setShowEdit(true)
    }
    return (
        <>
            <CalcTableRow isMobile={false}>
                {children}
            </CalcTableRow>
            <CalcTableRow isMobile={true} onClick={() => { handleOpenCalcModal() }}>
                {children}
            </CalcTableRow>
        </>
    )
}

const CalcRow = ({ 
    calc,
    user, 
    showCalcModal, 
    selectedItemID, 
    writeType, 
    selectCalc, 
    deleteCalc, 
    setShowEdit, 
    setEditCalc, 
}) => {
    // const calc = new PerformCalculate()

    const handleShowCalcModal = () => {
        showCalcModal(calc)
    }

    const handleSelectCalc = () => {
        if (selectCalc !== undefined) {
            selectCalc(calc)
        }
    }

    const handleDeleteCalc = () => {
        deleteCalc(calc)
    }

    return (
        <CalTableRowContainer user={user} setShowEdit={setShowEdit} setEditCalc={setEditCalc} calc={calc} >
            <CalcValueCell className={CalcCellWidth.ID}>
                {calc.index}
            </CalcValueCell>
            <CalcValueCell className={CalcCellWidth.Title}>
                {calc.name}
            </CalcValueCell>
            <CalcValueCell  mobilehide={(writeType === WriteTypes.SELECT)} className={CalcCellWidth.Description + " truncate justify-center "}>
                {calc.description}
            </CalcValueCell>
            <CalcValueCell className={CalcCellWidth.Calculated}>
                {calc.created}
            </CalcValueCell>
            {writeType === WriteTypes.WRITE && (
                <Link className="max-lg:hidden lg:block" to={`/mypage/perform/${selectedItemID}`} state={{ itemID: selectedItemID, filteredCalc: calc }}>
                    <CalcValueCell className={CalcCellWidth.Perform}>
                        <CalcDetailPerformIcon />
                    </CalcValueCell>
                </Link>
            )}
            {writeType !== WriteTypes.READ && (
                <React.Fragment>
                    {writeType === WriteTypes.WRITE && (
                        <CalcValueCell mobilehide={true} className={CalcCellWidth.Detail}>
                            <CalcDetailUpdateIcon onClick={() => { handleShowCalcModal() }} />
                        </CalcValueCell>
                    )}
                    {writeType === WriteTypes.SELECT && (
                        <CalcValueCell mobilehide={!(writeType === WriteTypes.SELECT)} className={CalcCellWidth.Detail} onClick={() => { handleSelectCalc() }} >
                            <div className="flex flex-center bg-nightblue-400 text-white rounded-md px-3 py-0.5 hover:bg-nightblue-500">
                                적용
                            </div>
                        </CalcValueCell>
                    )}
                </React.Fragment>
            )}
            {writeType === WriteTypes.WRITE && (
                <CalcValueCell mobilehide={true} className={CalcCellWidth.Detail}>
                    <CalcDetailDeleteIcon onClick={() => { handleDeleteCalc() }} />
                </CalcValueCell>
            )}
        </CalTableRowContainer>
    )
}

const CalcGroupContainer = ({
    calcGroup,
    user,
    updateCalcGroup,
    deleteCalcGroup,
    addCalcModal,
    deleteCalc,
    showCalcModal,
    selectedItemID,
    writeType,
    selectCalc,
    setShowEdit,
    setEditCalc,
}) => {
    // const calcGroup = new PerformCalculateGroup()
    const [titleEditState, setTitleEditState] = useState(EditStateTypes.WAIT)
    const [titleValue, setTitleValue] = useState("")
    const [tableOpen, setTableOpen] = useState(0)

    useEffect(() => {
        if (titleEditState === EditStateTypes.WAIT) {
            setTitleValue(calcGroup.name)
        }
    }, [calcGroup, titleEditState])

    const handleTitleEditState = () => {
        if (titleEditState === EditStateTypes.WAIT) {
            setTitleEditState(EditStateTypes.EDITED)
        } else {
            handleTitleUpdate()
        }
    }

    const handleTitleUpdate = async () => {
        await updateCalcGroup(calcGroup.id, titleValue)
        setTitleEditState(EditStateTypes.WAIT)
    }
    const handleGroupDelete = async () => {
        deleteCalcGroup(calcGroup)
    }

    const handleUpdateKeyPress = (e) => {
        if (e.key == "Enter") {
            handleTitleUpdate()
        }
    }

    const handleAddCalc = () => {
        addCalcModal(calcGroup.id)
    }

    const handleFoldCalc = () => {
        setTableOpen(!tableOpen)
    }



    return (
        <CalcTableCon className={`${tableOpen == PerformCalcFoldType.CLOSE ? "h-10" : ""}`}>
            <CalcTableHeaderBox>
                <CalcTableTitleBox>
                    {titleEditState === EditStateTypes.WAIT ? (
                        <React.Fragment>
                            <span className="mr-2">{calcGroup.name}</span>
                        </React.Fragment>
                    ) : (
                        <CalcTableTitleInput
                            type="text"
                            value={titleValue}
                            onChange={(e) => { setTitleValue(e.target.value) }}
                            onKeyPress={handleUpdateKeyPress}
                        />
                    )}
                    {titleEditState === EditStateTypes.WAIT ? (
                        writeType === WriteTypes.WRITE && (
                            <React.Fragment>
                                <CalcTableTitleIconContainer onClick={() => { handleTitleEditState() }}>
                                    <CalcTableTitleCalcEditIcon />
                                </CalcTableTitleIconContainer>
                                <CalcTableTitleIconContainer onClick={() => { handleGroupDelete() }}>
                                    <CalcTableTitleCalcDeleteIcon />
                                </CalcTableTitleIconContainer>
                            </React.Fragment>
                        )
                    ) : (
                        <React.Fragment>
                            <CalcTableTitleIconContainer onClick={() => { handleTitleEditState() }}>
                                <CalcTableTitleSaveIcon />
                            </CalcTableTitleIconContainer>
                            <CalcTableTitleIconContainer onClick={() => { setTitleEditState(EditStateTypes.WAIT) }}>
                                <CalcTableTitleCancleIcon />
                            </CalcTableTitleIconContainer>
                        </React.Fragment>
                    )}
                </CalcTableTitleBox>
                <CalcAddButton onClick={() => { handleAddCalc() }} writeType={writeType}>
                    정산 추가
                </CalcAddButton>
                <CalcFoldButton className="mr-4" writeType={writeType} onClick={() => { handleFoldCalc() }}>{tableOpen == PerformCalcFoldType.CLOSE ? "열기" : "닫기"}</CalcFoldButton>
            </CalcTableHeaderBox>
            <CalcTable isOpen={tableOpen == PerformCalcFoldType.CLOSE}>
                <CalcColumnBox>
                    <CalcColumnCell className={CalcCellWidth.ID}>
                        NO
                    </CalcColumnCell>
                    <CalcColumnCell className={CalcCellWidth.Title}>
                        제목
                    </CalcColumnCell>
                    <CalcColumnCell  mobilehide={(writeType === WriteTypes.SELECT)} className={CalcCellWidth.Description}>
                        내용
                    </CalcColumnCell>
                    <CalcColumnCell className={CalcCellWidth.Calculated}>
                        정산 날짜
                    </CalcColumnCell>
                    {writeType === WriteTypes.WRITE && (
                        <CalcColumnCell mobilehide={true} className={CalcCellWidth.Perform}>
                            실적
                        </CalcColumnCell>
                    )}
                    {writeType !== WriteTypes.READ && (
                        <CalcColumnCell mobilehide={!(writeType === WriteTypes.SELECT)} className={CalcCellWidth.Detail}>
                            {writeType === WriteTypes.WRITE && "수정"}
                            {writeType === WriteTypes.SELECT && "선택"}
                        </CalcColumnCell>
                    )}
                    {writeType === WriteTypes.WRITE && (
                        <CalcColumnCell mobilehide={true} className={CalcCellWidth.Delete}>
                            삭제
                        </CalcColumnCell>
                    )}
                </CalcColumnBox>

                <CalcTableBody>
                    <div className="flex flex-col">
                        {calcGroup.calculates.map((calc, idx) => (
                            <CalcRow
                                calc={calc}
                                user={user}
                                showCalcModal={showCalcModal}
                                writeType={writeType}
                                selectCalc={selectCalc}
                                selectedItemID={selectedItemID}
                                deleteCalc={deleteCalc}
                                setShowEdit={setShowEdit}
                                setEditCalc={setEditCalc}
                                key={idx}
                            />
                        ))}
                    </div>
                    {/* <CalcTableBody> 
                    </CalcTableBody> */}
                </CalcTableBody>
            </CalcTable>
        </CalcTableCon>
    )
}

export default function MyPageCalc({ selectedItem, selectCalc = undefined, setOfficeNavState = undefined, performID = undefined, isReadable }) {
    const [calcGroups, setCalcGroups] = useState([])

    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [showGroupCreate, setShowGroupCreate] = useState(false) // 정산 그룹 생성 모달 여부
    const [showCreate, setShowCreate] = useState(false) // 정산 생성 모달 여부
    const [createGroupId, setCreateGroupId] = useState()
    const [showEdit, setShowEdit] = useState(false) // 정산 편집 모달 여부
    const [editCalc, setEditCalc] = useState(new PerformCalculate()) // 정산 편집에 사용될 정산
    const [writeType, setWriteType] = useState(WriteTypes.WRITE)
    const [gridWidth, gridWidthRef] = useGettingWidth()
    // const [isReversed, setIsReversed] = useState(false);
    const [sortType, setSortType] = useState(SortTypes.ASC)

    const { user } = useUserStore()
    const navigate = useNavigate()

    useEffect(() => {
        if (setOfficeNavState !== undefined) {
            setOfficeNavState(PmsOfficeNavState.CAL)
        }

        return () => {
            if (setOfficeNavState !== undefined) {
                setOfficeNavState(PmsOfficeNavState.NONE)
            }
        }
    }, [])

    useEffect(() => {
        if (user.id >= 0 && performID === undefined && !AccessRules.brandItemManagement(user)) {
            alert("잘못된 접근입니다.")
            navigate(`/mypage`);
        }
    }, [user])

    useEffect(() => {
        if (selectedItem.id >= 0) {
            getGroups(sortType)
        }
    }, [selectedItem])

    useEffect(() => {
        if (selectCalc !== undefined) {
            setWriteType(WriteTypes.SELECT)
        } else if (isReadable) {
            setWriteType(WriteTypes.READ)
        }
    }, [selectCalc])

    const getGroups = async (_sortType) => {
        const ordering = Utils.getOrdering("id", _sortType)
        if (performID === undefined) {
            const response = await getCalculateGroups(selectedItem.id, pageSize, ordering)
            setCalcGroups(response.items)
        } else {
            const response = await getPerformCalc(performID)
            setCalcGroups(response.items.reverse())
        }
    }

    const handleAddCalcGroupModal = async () => {
        setShowGroupCreate(true)
    }

    const handleAddCalcGroup = async (name) => {
        const groupName = name
        const response = await addCalcGroup(selectedItem.id, groupName)

        // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
        if (response) {
            await getGroups(sortType)
        }

        setShowGroupCreate(false)
    }

    const handleUpdateCalcGroup = async (groupID, groupName) => {
        const response = await updateCalcGroup(groupID, groupName)

        // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
        if (response) {
            await getGroups(sortType)
        }
        return response
    }

    const handleDeleteCalcGroup = async (group) => {
        if (confirm(`${group.name} 삭제하시겠습니까?`)) {
            const response = await deleteCalcGroup(group.id)
            // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
            if (response === BackendResponse.SUCCESS) {
                await getGroups(sortType)
                alert("삭제되었습니다.")
            } else if (response === BackendResponse.EXIST_PERFORMS) {
                alert("그룹 내 정산된 실적이 존재합니다.")
            } else {
                alert("삭제 실패했습니다.")
            }
        }
    }

    // 정산추가
    const handleAddCalcModal = (groupID) => {
        setCreateGroupId(groupID)
        setShowCreate(true)
    }

    const handleAddCalc = async (groupID, name, desc) => {
        const calcName = name
        const calcDescription = desc
        const response = await addCalc(groupID, calcName, calcDescription)

        // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
        if (response) {
            await getGroups(sortType)
        }
        setShowCreate(false)
    }

    const handleUpdateCalc = async (calcID, calcName, description) => {
        const response = await updateCalc(calcID, calcName, description)

        // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
        if (response) {
            await getGroups(sortType)
            alert("수정되었습니다.")
        }
        handleCloseCalcModal()

        return response
    }

    const handleDeleteCalc = async (calc) => {
        if (confirm(`${calc.name} 삭제하시겠습니까?`)) {
            const response = await deleteCalc(calc.id)

            // 그룹 명 인덱스 맞추기 위해서 추가 시 동기 실행
            if (response === BackendResponse.SUCCESS) {
                await getGroups(sortType)
                alert("삭제되었습니다.")
            } else if (response === BackendResponse.EXIST_PERFORMS) {
                alert("정산된 실적이 존재합니다.")
            } else {
                alert("삭제 실패했습니다.")
            }
        }
    }

    const handleShowCalcModal = (calc) => {
        setEditCalc(calc)
        setShowEdit(true)
    }
    const handleCloseCalcModal = () => {
        setShowEdit(false)
    }

    const handleSortType = (_sortType) => {
        // if (sortType === SortTypes.ASC) {
        //     setCalcGroups(prevCalcGroups => [...prevCalcGroups].reverse())
        // }
        getGroups(_sortType)
        setSortType(_sortType)
    }

    const isMobileSize = window.innerWidth <= RESPONSIVE_WIDTH.LG
    return (
        <ContentContainer>
            {AccessRules.brandItemManagement() && (
                <CalcGroupControlBox writeType={writeType}>
                    <SortButton>
                        <SortSwitch sortType={sortType} setSortType={handleSortType} />
                        {/* <SortIconContainer>
                            {isReversed ? <><SortUpAltIcon /></> : <><SortDownIcon /></>}
                        </SortIconContainer> */}
                    </SortButton>
                    <CalcGroupAddButton onClick={() => { handleAddCalcGroupModal() }}>
                        정산 그룹 추가
                    </CalcGroupAddButton>
                </CalcGroupControlBox>
            )}
            <CalcGridContainer ref={gridWidthRef} >
                <CalcGridBox gridWidth={gridWidth} >
                    {calcGroups.length > 0 ?
                        <React.Fragment>
                            {
                                calcGroups.map((calcGroup, idx) => (
                                    <CalcGroupContainer
                                        key={idx}
                                        user={user}
                                        calcGroup={calcGroup}
                                        updateCalcGroup={handleUpdateCalcGroup}
                                        deleteCalcGroup={handleDeleteCalcGroup}
                                        addCalcModal={handleAddCalcModal}
                                        deleteCalc={handleDeleteCalc}
                                        showCalcModal={handleShowCalcModal}
                                        writeType={writeType}
                                        selectedItemID={selectedItem.id}
                                        selectCalc={selectCalc}
                                        setShowEdit={setShowEdit}
                                        setEditCalc={setEditCalc}
                                    />
                                ))
                            }
                        </React.Fragment>
                        :
                        <CalcNoneMessageContainer>
                            <CalcNoneIconContainer>
                                <CalcGroupIcon />
                            </CalcNoneIconContainer>
                            <div>
                                <CalcNoneMessage>
                                    아직 정산 그룹이 없어요!
                                </CalcNoneMessage>
                            </div>
                        </CalcNoneMessageContainer>
                    }
                </CalcGridBox>
            </CalcGridContainer>
            <div className="max-lg:hidden lg:block">
                <ModalContainer show={showEdit && !isMobileSize} setShow={setShowEdit} fadeDown isBlur closeByBackground>
                    <MyPageCalcEdit calc={editCalc} updateCalc={handleUpdateCalc} />
                </ModalContainer>
            </div>

            <div className="max-lg:block lg:hidden">
                <MobileModal isOpen={showEdit && isMobileSize} setIsOpen={setShowEdit} canBigger={false} defaultHeight={100}>
                    <MyPageCalcEdit setIsOpen={setShowEdit} handleDeleteCalc={handleDeleteCalc} isMobile={true} calc={editCalc} updateCalc={handleUpdateCalc} />
                </MobileModal>
            </div>

            <div className="max-lg:hidden lg:block">
                <ModalContainer show={showCreate && !isMobileSize} setShow={setShowCreate} fadeDown isBlur closeByBackground>
                    <MyPageCalcEdit updateCalc={handleUpdateCalc} create={true} createGroupId={createGroupId} handleAddCalc={handleAddCalc} />
                </ModalContainer>
            </div>

            <div className="max-lg:block lg:hidden">
                <MobileModal isOpen={showCreate && isMobileSize} setIsOpen={setShowCreate} canBigger={false} defaultHeight={100}>
                    <MyPageCalcEdit isMobile={true} updateCalc={handleUpdateCalc} create={true} createGroupId={createGroupId} handleAddCalc={handleAddCalc} />
                </MobileModal>
            </div>

            <div className="max-lg:hidden lg:block">
                <ModalContainer show={showGroupCreate && !isMobileSize} setShow={setShowGroupCreate} fadeDown isBlur closeByBackground>
                    <MyPageCalcGroupAdd handleAddCalcGroup={handleAddCalcGroup} />
                </ModalContainer>
            </div>

            <div className="max-lg:block lg:hidden">
                <MobileModal isOpen={showGroupCreate && isMobileSize} setIsOpen={setShowGroupCreate} canBigger={false} defaultHeight={100}>
                    <MyPageCalcGroupAdd isMobile={true} handleAddCalcGroup={handleAddCalcGroup} />
                </MobileModal>
            </div>
        </ContentContainer>
    )
}