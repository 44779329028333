import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import {Link} from "@styled-icons/entypo/Link"

export const UploadLayout = tw.div`
absolute flex max-sm:flex-col sm:flex-row max-sm:justify-between w-full h-full p-2
bg-gray-150 rounded-lg
`
export const UploadBoxDesktop = tw.div`
max-sm:hidden sm:flex flex-center flex-shrink-0 w-72 h-full px-3 py-2 ml-2 relative
rounded-lg
bg-blue-100
`
export const UploadBoxMobile = tw.div`
max-sm:flex sm:hidden flex-center flex-shrink-0 w-full h-16 px-3 py-2 relative
rounded-lg
bg-blue-100
`

export const UploadedListBox = tw.div`
flex flex-col w-full max-sm:h-auto sm:h-full p-1
overflow-y-auto scroll-transparent scroll-overlay
`
export const UploadedBox = tw.div`
flex items-center w-full h-8 border-b mb-1 p-1
${(props) => props.is_temp ? "border-yellow-300" : "border-gray-300"}
`
export const UploadedFileName = tw.span`
w-full max-md:text-xs md:text-sm truncate
`
export const UploadedFileIconBox = tw.div`
flex flex-center flex-shrink-0 h-full aspect-square ml-2 p-0.25
text-gray-600 hover:text-gray-800
cursor-pointer
`
export const LinkIcon = tw(Link)`
    h-full
`
export const FileInputLabel = tw.label`
    flex flex-center mt-2 px-4 py-2
    text-white text-sm
    bg-blue-400 hover:bg-blue-300 rounded-md
    duration-100 cursor-pointer
`
export const FileInputText = tw.span`
    mt-2 text-blue-600 text-sm
`