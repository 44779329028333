import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import AccessRules from "../../../rules/AccessRules"
import { PerformValueBox } from "./MyPagePerformStyle"
import { PerformTextBox } from "./MyPagePerformStyle"
import { Edit } from "@styled-icons/boxicons-solid/Edit"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { useUserStore } from "../../../zustand/store"

const EditIcon = tw(Edit)`
    h-full
`
const ViewIconBox = tw.div`
    flex flex-center w-full h-full py-0.5
`
const ViewIcon = tw(Search)`
    h-full
`

export const PerformValueUpdateCell = ({ column, perform, showUpdateComponent }) => {
    const { user } = useUserStore()
    const handleShowUpdateComponent = () => {
        showUpdateComponent(perform)
    }

    return (
        <PerformValueBox>
            <PerformTextBox
                isButtonCell={true}
                onClick={() => {
                    handleShowUpdateComponent()
                }}
            >
                <div className="flex flex-center max-lg:h-6 lg:h-7 text-green-700">
                    {!perform.isReadOnly(user) ? (
                        <ViewIconBox>
                            <EditIcon />
                        </ViewIconBox>
                    ) : (
                        <ViewIconBox>
                            <ViewIcon />
                        </ViewIconBox>
                    )}
                </div>
            </PerformTextBox>
        </PerformValueBox>
    )
}
