import React, { useState, useEffect, useCallback } from "react"
import {
    LoginButton,
    SignupButton,
    LoginCol,
    LoginTitle,
    LoginOptionCol,
    LoginOptionItem,
    LoginOptionSpan,
    LoginRegistCol,
    LoginRegistSpan,
    LoginInputCol,
    LoginRegistBox,
    LoginContainer,
    LoginFindPassword,
    LoginImage,
    KakaoLogo,
    KakaoLoginButton,
    KakaoLogoContainer,
    LoginListButton,
    LoginSplitterBox,
    LoginSplitterTitle,
    LoginSplitterLine,
    LoginListButtonList,
    LoginListKakaoButton,
} from "./LoginStyle"
import { Link, useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import ConstCookie from "../../../constants/ConstCookie"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { getUserByLogin } from "../../../services/users"
import { UserInput } from "../Inputs"
import { LoginComponentTypes } from "./LoginMain"
import kakaoLoginImage from "../../../static/kakao_login.png"

const Splitter = ({ title }) => {
    return (
        <LoginSplitterBox>
            <LoginSplitterTitle>{title}</LoginSplitterTitle>
            <LoginSplitterLine />
        </LoginSplitterBox>
    )
}

const LoginList = ({ login, setShowComponent, setLoginComponentType }) => {
    const loginWithKakao = () => {
        const baseUrl = Utils.getBaseUrl()
        const kakaoRedirectUrl = `${baseUrl}/oauth/kakao/callback`
        window.Kakao.Auth.authorize({
            redirectUri: kakaoRedirectUrl,
            prompt: "select_account",
        })
    }

    return (
        <LoginContainer>
            <LoginCol>
                <LoginTitle className="mb-6">로그인</LoginTitle>
                {/* <Splitter title={"마케터로 로그인"} /> */}
                <div className="relative flex flex-col w-[calc(100%-1rem)] p-2 rounded-2xl border border-slate-300">
                    <div className="absolute left-4 -top-2 w-fit h-fit px-1 py-0 text-sm text-slate-500 bg-white select-none">
                        마케터회원 로그인
                    </div>
                    <LoginListButtonList>
                        <LoginListButton
                            onClick={() => {
                                setLoginComponentType(LoginComponentTypes.EMAIL_LOGIN)
                            }}
                        >
                            <i className="fa-solid fa-envelope text-nightblue-600 text-lg mr-2"></i>
                            이메일 로그인
                        </LoginListButton>
                        <LoginListKakaoButton
                            onClick={() => {
                                loginWithKakao()
                            }}
                        >
                            <div className="h-full pb-2.5 pt-1 mr-2">
                                <KakaoLogo />
                            </div>
                            카카오 로그인
                        </LoginListKakaoButton>
                    </LoginListButtonList>
                </div>
                <div className="mt-6"></div>
                {/* <Splitter title={"브랜드로 로그인"} /> */}
                <LoginListButtonList className="py-0 mb-12">
                    <LoginListButton
                        className="bg-nightblue-400 hover:bg-nightblue-500 text-white"
                        onClick={() => {
                            setLoginComponentType(LoginComponentTypes.EMAIL_LOGIN)
                        }}
                    >
                        브랜드회원 로그인
                    </LoginListButton>
                </LoginListButtonList>
            </LoginCol>
            <LoginRegistBox>
                <LoginRegistCol>
                    <LoginRegistSpan>처음이신가요?</LoginRegistSpan>
                    <Link to={"/signup"}>
                        <SignupButton
                            onClick={() => {
                                setShowComponent(false)
                            }}
                        >
                            회원가입
                        </SignupButton>
                    </Link>
                </LoginRegistCol>
            </LoginRegistBox>
        </LoginContainer>
    )
}

export default LoginList
