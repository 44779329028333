import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { PerformStatusValueBox, PerformValueInputSubTitle, PerformTableFilterIcon } from "./MyPagePerformStyle"

import AccessRules from "../../../rules/AccessRules"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import { StatusButtons, StatusComment } from "./PerformValueStatusCell"

export const PerformValueStatusUpdate = ({ perform, performValue, readOnly }) => {
    // const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [column, setColumn] = useState(new PerformColumn())
    const [selectedStatusID, setSelectedStatusID] = useState(0)

    useEffect(() => {
        setColumn(performValue.column)
        setSelectedStatusID(performValue.statusID)
    }, [performValue])

    const handleUpdateStatus = (_status) => {
        setSelectedStatusID(_status.id)
        performValue.statusID = _status.id
    }

    const valueReadOnly = readOnly
    // const valueReadOnly = (readOnly || !AccessRules.performValueWrite(column, perform))

    return (
        <PerformStatusValueBox>
            <PerformValueInputSubTitle>
                <span>현황</span>
            </PerformValueInputSubTitle>
            <StatusButtons
                disabled={valueReadOnly}
                statuses={column.statuses}
                selectedStatusID={selectedStatusID}
                updateStatus={handleUpdateStatus}
            />
            {performValue.performID >= 0 && (
                <div className="flex flex-col w-full h-96">
                    <PerformValueInputSubTitle>
                        <span>메모</span>
                        <div className="h-[1rem] ml-1">
                            <PerformTableFilterIcon className="text-gray-500" />
                        </div>
                    </PerformValueInputSubTitle>
                    {/* StatusComment에서 column을 바로 불러와야해서 performValue의 column을 넣어준다. */}
                    <StatusComment
                        column={performValue.column}
                        value={performValue}
                        selectedStatusID={selectedStatusID}
                    />
                </div>
            )}
        </PerformStatusValueBox>
    )
}
