import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { updateUserDetail } from "../../../apis/api/users"
import { MyPageUserInput, MyPageUserInputCombo, MyPageUserLocationInputCombo } from "../../../components/user/Inputs"
import { getUserMarketerSignupRequestData } from "../../../services/users"
import UserMarketer from "../../../types/users/UserMarketer"
import {
    MarketerParticipantNames,
    MarketerParticipantTypes,
    UserGenderNames,
    UserGenderTypes,
} from "../../../types/users/UserTypes"
import Utils from "../../../utils/Utils"
import {
    MyInfoDataContainer,
    MyInfoDataEditButton,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyPageDataGrid,
    MyPageDataControlBox,
    MyPageDataSubmitButton,
    MarketerWarnningMessage,
    MyInfoDataTitleContainer,
} from "./MyInfoMainStyle"
import { getJobTypes, getRegionTypes } from "../../../apis/api/commons"
import { MyPageInputLabel } from "../../../components/user/InputsStyle"

import { marketerApplyModal } from "../../../zustand/modalstore"
import { ExcelIcon } from "../perform/MyPagePerformStyle"
import { REGION_KEYS } from "../../../types/DataEnum"

export default function MyInfoMarketer({
    marketer,
    isApply = false,
    setApplyMarketerData,
    location1,
    setLocation1,
    location2,
    setLocation2,
    location3,
    setLocation3,
    participantType,
    setParticipantType,
    userGenderType,
    setUserGenderType,
    profit,
    setProfit,
    careers,
    setCareers,
    locationTypes,
    setLocationTypes,
    birthday,
    setBirthday,
}) {
    // const [location1, setLocation1] = useState(0)
    // const [location2, setLocation2] = useState(0)
    // const [location3, setLocation3] = useState(0)
    // const [participantType, setParticipantType] = useState(MarketerParticipantTypes.NONE)
    // const [userGenderType, setUserGenderType] = useState(UserGenderTypes.NONE)
    // const [profit, setProfit] = useState()
    // const [careers, setCareers] = useState([])
    // const [locationTypes, setLocationTypes] = useState({})
    // const [birthday, setBirthday] = useState("")
    //zustand로 가져온 데이터

    const setMarketerModalShow = marketerApplyModal((state) => state.setMarketerApplyModal)

    const [inputDataComplete, setInputDataComplete] = useState(false)

    useEffect(() => {
        if (
            !Utils.isStringNullOrEmpty(birthday) &&
            (location1 > 0 || location2 > 0 || location3 > 0) &&
            Number(userGenderType) !== UserGenderTypes.NONE
        ) {
            setInputDataComplete(true)
        } else {
            setInputDataComplete(false)
        }
    }, [birthday, location1, location2, location3, userGenderType])

    useEffect(() => {
        // 현재 날짜 생성
        const today = new Date()

        // 비교할 날짜 문자열
        const dateString = birthday

        // 비교할 날짜 생성
        const futureDate = new Date(dateString)

        // 비교
        if (futureDate > today) {
            alert("사용할 수 없는 날짜입니다.")

            // 날짜를 원하는 형식으로 변환
            const year = today.getFullYear()
            const month = (today.getMonth() + 1).toString().padStart(2, "0") // 월은 0부터 시작하므로 +1 해줌
            const day = today.getDate().toString().padStart(2, "0")

            // "YYYY-MM-DD" 형식으로 조합
            const formattedDate = year + "-" + month + "-" + day
            setBirthday(formattedDate)
        }
    }, [birthday])

    useEffect(() => {
        if (setApplyMarketerData !== null) {
            setApplyMarketerData(marketer)
        }
    }, [marketer])

    useEffect(() => {
        if (marketer !== null) {
            updateValue(marketer)
        }
    }, [marketer])

    useEffect(() => {
        getTypes()
    }, [])

    const getTypes = () => {
        getRegionTypes().then((response) => {
            let regionTypes = { ...response }
            regionTypes[0] = "-"
            setLocationTypes(regionTypes)
        })
    }

    const updateValue = (_marketer) => {
        setLocation1(_marketer.detail.location1)
        setLocation2(_marketer.detail.location2)
        setLocation3(_marketer.detail.location3)
        // setParticipantType(_marketer.detail.participantType)
        setProfit(_marketer.detail.profit)
        setCareers(_marketer.careers)
        setBirthday(_marketer.user.birthday)
        setUserGenderType(_marketer.user.gender)
    }

    const isLocationDuplicated = (locationData, setLocation) => {
        if (
            locationData != 0 &&
            (locationData == location1 || locationData == location2 || locationData == location3)
        ) {
            alert("중복된 지역 입니다.")
            setLocation(0)
        } else {
            setLocation(locationData)
        }
    }

    const submit = () => {
        if (
            Utils.isStringNullOrEmpty(birthday) ||
            Utils.isStringNullOrEmpty(location1) ||
            userGenderType === UserGenderTypes.NONE
        ) {
            alert("값을 입력해주세요")
            return
        }

        // 경력은 여기서 업데이트 되진 않는다.
        // 지역이 선택되지않으면 null을 넣는다.
        const requestData = {
            user: {
                gender: userGenderType,
                birthday: birthday,
            },
            ...getUserMarketerSignupRequestData(
                Object.keys(locationTypes).includes(String(location1)) ? location1 : null,
                Object.keys(locationTypes).includes(String(location2)) ? location2 : null,
                Object.keys(locationTypes).includes(String(location3)) ? location3 : null,
                participantType,
                userGenderType,
                careers,
            ),
        }

        updateUserDetail(requestData)
            .then((response) => {
                const _marketer = new UserMarketer()
                _marketer.convertByResponse(response.data)
                updateValue(_marketer)
                if (setApplyMarketerData !== null) {
                    setApplyMarketerData(_marketer)
                }
                alert("수정되었습니다.")
            })
            .catch((error) => {
                alert("실패했습니다.")
            })
    }

    return (
        <MyInfoDataContainer className="mb-0">
            <MyInfoDataTitleContainer className="px-6">
                <MyInfoDataTitle>마케터 추가 정보 (필수정보)</MyInfoDataTitle>
                {/* <MyInfoDataEditButton>
                    정보 수정
                </MyInfoDataEditButton> */}
            </MyInfoDataTitleContainer>

            <MyInfoDataBox>
                {inputDataComplete === false && (
                    <React.Fragment>
                        <MarketerWarnningMessage>
                            ※ 해당 데이터를 모두 입력 해야 마케터 신청이 가능 합니다.
                        </MarketerWarnningMessage>
                    </React.Fragment>
                )}
                <MyPageDataGrid>
                    {/* <MyPageUserInput
                        label={"활동 지역"}
                        type={"text"}
                        data={location}
                        setData={setLocation}
                    /> */}
                    <MyPageUserInput
                        label={"생년월일"}
                        type={"date"}
                        data={birthday}
                        setData={setBirthday}
                        isRequired
                    />
                    {/* <MyPageUserInputCombo
                        label={"참여 형식"} 
                        optionKeys={Object.values(MarketerParticipantTypes)} 
                        names={Object.values(MarketerParticipantNames)}
                        value={participantType}
                        onChange={(e)=>{setParticipantType(e.target.value)}}
                    /> */}
                    <MyPageUserInputCombo
                        label={"성별"}
                        optionKeys={Object.values(UserGenderTypes)}
                        names={Object.values(UserGenderNames)}
                        value={userGenderType}
                        onChange={(e) => {
                            setUserGenderType(e.target.value)
                        }}
                        isRequired
                    />
                    {/* <MyPageUserInput
                        label={"영업 실적"}
                        type={"number"}
                        data={profit}
                        setData={setProfit}
                    /> */}
                </MyPageDataGrid>

                <div className="flex flex-col w-full mt-4">
                    <MyPageInputLabel className="font-semibold">
                        <span className="text-red-500 mr-1">*</span>활동 가능 지역
                    </MyPageInputLabel>
                    <div className="grid max-sm:grid-cols-1 sm:grid-cols-3 gap-4 py-1">
                        <MyPageUserLocationInputCombo
                            label={`지역 (1순위)`}
                            optionKeys={REGION_KEYS}
                            names={Object.values(locationTypes)}
                            value={location1}
                            onChange={(e) => {
                                isLocationDuplicated(e.target.value, setLocation1)
                            }}
                        />
                        <MyPageUserLocationInputCombo
                            label={`지역 (2순위)`}
                            optionKeys={REGION_KEYS}
                            names={Object.values(locationTypes)}
                            value={location2}
                            onChange={(e) => {
                                isLocationDuplicated(e.target.value, setLocation2)
                            }}
                        />
                        <MyPageUserLocationInputCombo
                            label={`지역 (3순위)`}
                            optionKeys={REGION_KEYS}
                            names={Object.values(locationTypes)}
                            value={location3}
                            onChange={(e) => {
                                isLocationDuplicated(e.target.value, setLocation3)
                            }}
                        />
                    </div>
                </div>

                {/* <MyPageDataControlBox>
                    <MyPageDataSubmitButton onClick={() => { submit() }}>
                        저장
                    </MyPageDataSubmitButton>
                </MyPageDataControlBox> */}
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
