import React, { useState, useEffect, useRef } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { createBrandItem, getBrandItemView, registItemMarketer, updateBrandItem } from "../../apis/api/boardItems"
import { ImageBase64, ImageContainer } from "../../components/ImageContainer"
import { ITEM_DATA } from "../../constants/ConstItem"
import BrandItem from "../../types/brandItems/BrandItem"
import { AdCategoryTypeNames, ItemSalesTypeNames, MarketerTypeNames } from "../../types/brandItems/BrandItemTypes"
import {
    ItemApplyBrandContainer,
    ItemApplyBrandContents,
    ItemApplyBrandDescription,
    ItemApplyBrandDescriptionContainer,
    ItemApplyBrandIconContainer,
    ItemApplyBrandTitle,
    ItemApplyBrandTitleContainer,
    ItemApplyDetail,
    ItemDetailApplyContents,
    ItemDetailApplyItem,
    ItemDetailApplyItemContainer,
    ItemDetailApplyItemContents,
    ItemDetailApplyItemDescription,
    ItemDetailApplyButton,
    ItemDetailContainer,
    ItemDetailContentContainer,
    ItemDetailContentDiv,
    ItemDetailContentTitle,
    ItemDetailImageContainer,
    ItemDetailIntroduce,
    ItemDetailNav,
    ItemDetailNavInner,
    ItemDetailPageContainer,
    ItemDetailShareContainer,
    ItemDetailThumbnail,
    ItemDetailThumbnailContainer,
    ItemDetailThumbnailTitle,
    ShareButton,
    ShareIcon,
    ItemDetailApplyMarketerStatus,
    ItemDetailTitleContainer,
    ItemDetailThumbDataContainer,
    ItemDetailNavContainer,
    ItemDetailPreviewControlContainer,
    ItemDetailPreviewControlLayout,
    ItemDetailPreviewControlBox,
    ItemDetailPreviewCloseButton,
    ItemDetailPreviewSubmitButton,
    ItemDetailApplyItemBox,
} from "./ItemDetailStyle"
import { UserTypes } from "../../types/users/UserTypes"
import { useUserStore } from "../../zustand/store"
import { useLoginModalStore, marketerApplyModal } from "../../zustand/modalstore"
import UserAvatar from "../../components/user/UserAvatar"
import tw from "tailwind-styled-components"
import AccessRules from "../../rules/AccessRules"
import { MarketerStatusTypes } from "../../types/brandItems/BrandItemTypes"
import FileUtils from "../../utils/FileUtils"
import { registItemByMarketer } from "../../apis/services/brandItems"
import Utils from "../../utils/Utils"
import ReactQuillContent from "../../components/boards/ReactQuillContent"
import ConstSession from "../../constants/ConstSession"
import { EditStateTypes, QueryTypes, ItemTypes } from "../../types/EditorTypes"
import ConstLocalStorage from "../../constants/ConstLocalStorage"
import BrandItemUtils from "../../utils/brandItem/BrandItemUtils"
import CKEditorContent from "../../components/boards/CKEditorContent"

const getPreviewItemByLocalStorage = () => {
    const itemDataJson = sessionStorage.getItem(ConstSession.ITEM_PREVIEW_DATA)
    // 미리보기에서 승인 요청을 했는지 확인하기 위한 값 초기화
    localStorage.setItem(ConstLocalStorage.ITEM_PREVIEW_SUBMITTED, "0")
    const itemData = JSON.parse(itemDataJson)
    const item = new BrandItem()
    item.convertByRequest(itemData)

    return item
}

export default function ItemDetailPreivew() {
    const { user } = useUserStore((state) => state)
    const navigate = useNavigate()

    const previewBodyRef = useRef(null)

    const [queryType, setWriteType] = useState(QueryTypes.NONE)
    const [item, setItem] = useState(new BrandItem())

    const [descriptionContent, setDescriptionContent] = useState("")
    const [missionContent, setMissionContent] = useState("")
    const [rewardContent, setRewardContent] = useState("")
    const [contractContent, setContractContent] = useState("")

    const [activeSection, setActiveSection] = useState(1)
    const DescriptionRef = useRef(null)
    const MissionRef = useRef(null)
    const RewardRef = useRef(null)
    const ContractRef = useRef(null)

    const [isConfirmed, setConfirmed] = useState(false) // 스크롤을 맨 아래까지 내려서 내용을 다 확인했는지 여부
    const [maxScroll, setMaxScroll] = useState(-1) // 스크롤을 어디까지 내렸는지에 저장
    const [contentHeight, setContentHeight] = useState(0) // 스크롤 길이 저장

    const close = () => {
        sessionStorage.removeItem(ConstSession.ITEM_PREVIEW_DATA)
        window.close()
    }

    useEffect(() => {
        if (window !== null) {
            window.addEventListener("scroll", handleScroll)
        }

        getPreviewItem()

        return () => {
            if (window !== null) {
                window.removeEventListener("scroll", handleScroll)
            }
            close()
        }
    }, [])

    useEffect(() => {
        setConfirmed(false)
    }, [contentHeight])

    useEffect(() => {
        // 초기값인 경우 제외
        if (maxScroll >= 0 && maxScroll >= contentHeight - 150) {
            setConfirmed(true)
        }
    }, [maxScroll])

    const getPreviewItem = () => {
        const item = getPreviewItemByLocalStorage()
        // if (item.id < 0) {
        //     alert("내용을 찾을 수 없습니다.\n다시 시도해주세요.")
        //     navigate("/")
        // }

        try {
            setItem(item)
            initContractContent(item)

            setWriteType(item.id >= 0 ? QueryTypes.UPDATE : QueryTypes.CREATE)

            setTimeout(() => {
                handleScrollConfirm()
            }, 300)
        } catch {
            //
        }
    }

    const initContractContent = (_item) => {
        setDescriptionContent(FileUtils.replaceMedieFileURL(_item.detail.description))
    }

    const handleScroll = () => {
        handleScrollConfirm()
    }

    // 스크롤을 다 내려서 내용을 확인했는지 여부를 위한 함수
    const handleScrollConfirm = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement
        setContentHeight(scrollHeight)

        const scroll = scrollTop + clientHeight
        if (maxScroll < scroll) {
            setMaxScroll(scroll)
        }
    }

    const submit = async () => {
        let bResult = false

        const localStorageItem = getPreviewItemByLocalStorage()
        if (item.id !== localStorageItem.id) {
            alert("요청할 수 없는 상품입니다.\n미리보기를 다시 실행해주세요.")
            navigate("/")
            return
        }

        if (!BrandItemUtils.validateItemAdUpdate(item)) {
            alert("유효하지 않은 내용입니다.")
            return
        }

        if (
            !confirm(
                `승인 요청을하면 내용을 수정하거나 요청을 취소할 수 없습니다.\n${
                    queryType === QueryTypes.UPDATE ? "수정" : "등록"
                } 승인 요청 하시겠습니까?`,
            )
        ) {
            return
        }

        if (Utils.isStringNullOrEmpty(item.detail.title)) {
            alert("제목은 빈 값으로 설정할 수 없습니다.")
            return
        }

        let requestData = item.convertToRequest()
        requestData["item_type"] = ItemTypes.AD

        if (queryType === QueryTypes.UPDATE || item.editStateType === EditStateTypes.PENDING) {
            bResult = await updateBrandItem(item.id, requestData)
        } else if (queryType === QueryTypes.CREATE) {
            bResult = await createBrandItem(requestData)
        }

        if (bResult) {
            alert("승인 요청되었습니다.")
            localStorage.setItem(ConstLocalStorage.ITEM_PREVIEW_SUBMITTED, "1")
            close()
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <div className="w-full h-full overflow-y-auto scroll-transparent scoll-overlay" ref={previewBodyRef}>
            {!item.isEditRequested && (
                <ItemDetailPreviewControlContainer>
                    <ItemDetailPreviewControlLayout>
                        <ItemDetailPreviewControlBox>
                            <ItemDetailPreviewCloseButton
                                onClick={() => {
                                    close()
                                }}
                            >
                                닫기
                            </ItemDetailPreviewCloseButton>
                            <ItemDetailPreviewSubmitButton
                                onClick={() => {
                                    submit()
                                }}
                                disabled={!isConfirmed}
                            >
                                {queryType === QueryTypes.UPDATE ? "수정" : "등록"}
                            </ItemDetailPreviewSubmitButton>
                        </ItemDetailPreviewControlBox>
                    </ItemDetailPreviewControlLayout>
                </ItemDetailPreviewControlContainer>
            )}

            <ItemDetailPageContainer className="max-md:pt-0 md:pt-0 pb-8">
                <ItemDetailContainer>
                    <ItemDetailIntroduce>
                        {/* <ItemDetailTitleContainer>
                            <ItemDetailThumbnailTitle>
                                {item.detail.title}
                            </ItemDetailThumbnailTitle>
                            <ItemDetailShareContainer>
                                <ShareButton>
                                    <ShareIcon/>
                                </ShareButton>
                            </ItemDetailShareContainer>
                        </ItemDetailTitleContainer> */}
                        <ItemDetailThumbDataContainer>
                            {/* 썸네일 이미지 */}
                            {/* 정보 박스 */}
                            <ItemDetailApplyItem>
                                {/* 첫번째 줄 */}
                                <div className="flex max-md:flex-col-reverse justify-between w-full">
                                    <div className="flex flex-col max-md:p-2">
                                        <ItemDetailThumbnailTitle>{item.detail.title}</ItemDetailThumbnailTitle>
                                        <span className="text-slate-700 text-lg mt-2">{item.detail.adDescription}</span>

                                        <div className="grid grid-cols-3 max-md:gap-x-2 md:gap-x-6 w-full max-md:mt-6 md:mt-auto">
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>브랜드</ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.brand.detail.name}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>
                                                    카테고리
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {AdCategoryTypeNames[item.detail.adCategory]}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>지역</ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.sigungu.title()}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                        </div>
                                        <div className="grid grid-cols-3 max-md:gap-x-2 md:gap-x-6 w-full mt-2">
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>상호명</ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyName}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>
                                                    담당자 전화
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyTel}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>
                                                    담당자 이메일
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyEmail}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                        </div>
                                    </div>
                                    <ItemDetailThumbnail className="w-auto md:h-36">
                                        <ImageBase64 imageID={item.detail.thumbnailImageID} duration={true} />
                                    </ItemDetailThumbnail>
                                </div>
                                {/* <ItemDetailApplyContents>
                                <ItemDetailApplyItemDescription>
                                    기간
                                </ItemDetailApplyItemDescription>
                                <ItemDetailApplyItemContents >
                                    {item.detail.dateStart} ~ {item.detail.dateEnd}
                                </ItemDetailApplyItemContents>
                            </ItemDetailApplyContents> */}
                            </ItemDetailApplyItem>
                        </ItemDetailThumbDataContainer>
                    </ItemDetailIntroduce>
                    <ItemApplyDetail>
                        <ItemDetailContentContainer ref={DescriptionRef}>
                            <ItemDetailContentTitle>상품 상세</ItemDetailContentTitle>
                            {/* <ReactQuillContent content={descriptionContent} /> */}
                            <CKEditorContent content={descriptionContent} />
                            {/* <ItemDetailImageContainer>
                                <ImageBase64 imageID={item.detail.descriptionImageID} useImageHeight={true} />
                            </ItemDetailImageContainer> */}
                        </ItemDetailContentContainer>
                    </ItemApplyDetail>
                </ItemDetailContainer>
            </ItemDetailPageContainer>
        </div>
    )
}
