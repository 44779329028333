export const PerformColumnTypes = {
    STATIC: 0,
    DYNAMIC: 1,
    CALCULATE: 2,
}

export const PerformColumnFormTypes = {
    NONE: "-1",
    COMBO: 0,
    FILE: 1,
    TEXT: 2,
    STATUS: 3,
    OPTION: 4,
    DATE: 5,
    CHECK_BOX: 999,
}

export const PerformColumnTypeNames = {
    "-1": "-",
    0: "콤보박스",
    1: "파일",
    2: "텍스트",
    3: "상태",
    4: "항목선택",
    5: "날짜",
    999: "체크박스",
}

export const PerformColumnPreviewTypes = {
    NONE: "-1",
    TRUNCATE: 0,
    BUTTON: 1,
}

export const PerformColumnPreviewTypeNames = {
    "-1": "-",
    0: "간략한 내용",
    1: "버튼",
}

export const PerformStaticColumnTypes = {
    SELECT: -999,
    ID: -1,
    MARKETER: -2,
    CREATED: -3,
    CALCULATED: -4,
    UPDATE: -5,
}

export const PerformStaticColumnTypeNames = {
    "-999": "선택",
    "-1": "ID",
    "-2": "마케터",
    "-3": "생성 일",
    "-4": "정산 일",
    "-5": "상세",
}

export const PerformFileTypes = {
    NONE: -1,
    COMMON: 0,
    IMAGE: 1,
}

export const PerformFileTypeNames = {
    "-1": "없음",
    0: "COMMON",
    1: "이미지",
}
