import User from './User';
import { MarketerParticipantTypes, UserTypes } from './UserTypes';
export default class {
    constructor() {
        this.id = -1,
        this.jobType = null
        this.careerYear = 0
        this.businessType = 0
        this.jobDetail = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.jobType = response["job_type"]
        this.jobDetail = response["job_detail"]
        this.careerYear = response["career_year"]
        this.businessType = response["business_type"]
    }
}