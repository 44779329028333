import React, { useCallback, useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { useUserStore } from "../../../zustand/store"
import {
    MyInfoDeleteButton,
    MyInfoDeleteContainer,
    MyInfoDeleteContents,
    MyInfoDeleteControlBox,
    MyInfoDeleteHead,
    MyInfoDeleteNotMessage,
    MyInfoDeleteOptionEtc,
    MyInfoDeleteOptionItemBox,
    MyInfoDeleteOptionItemName,
    MyInfoDeleteSectionBox,
    MyInfoDeleteSectionConfirmButton,
    MyInfoDeleteSectionControlBox,
    MyInfoDeleteSectionDescription,
    MyInfoDeleteSectionSubTitle,
    MyInfoDeleteSectionTitle,
    MyInfoDeleteTitle,
} from "./MyInfoDeleteStyle"
import { TableNonColumnSimple } from "../../../components/TableNonColumnSimple"
import { OptionIconRadioCheck, OptionIconRadioUncheck } from "../perform/PerformValueOptionStyle"
import { MyPageUserInput, UserSMSAuthRequestButton, UserSMSAuthSubmitButton } from "../../../components/user/Inputs"
import { checkUserCertificate, deleteUser, sendAuthSMS, sendAuthSubmitSMS, updateUser } from "../../../apis/api/users"
import { UserTypes } from "../../../types/users/UserTypes"
import { useNavigate } from "react-router-dom"
import { logout } from "../../../services/users"
import Utils from "../../../utils/Utils"
import { EditStateTypes } from "../../../types/EditorTypes"

const BRAND_DELETE_DATA = [
    ["개인 정보", "이메일, 비밀번호, 전화번호"],
    ["브랜드 정보", "브랜드 정보"],
]
const BRAND_NOT_DELETE_DATA = [["상품", "상품 정보, 실적, 정산, 공지사항"]]
const BRAND_DELETE_REASON = ["마음에 들지 않는다", "회원 탈퇴 해야할 것 같다.", "난 브랜드다"]

const MARKETER_DELETE_DATA = [
    ["개인 정보", "이메일, 비밀번호, 전화번호"],
    ["마케터 정보", "성별, 경력, 생년월일, 활동 가능 지역"],
]
const MARKETER_NOT_DELETE_DATA = [["상품", "상품 신청 기록, 실적, 정산 기록, 상품 마케터 그룹"]]
const MARKETER_DELETE_REASON = ["마음에 들지 않는다", "회원 탈퇴 해야할 것 같다.", "난 마케터다"]

export default function MyInfoDelete({ userDetail }) {
    const { user, actions } = useUserStore()

    const [stepInfo, setStepInfo] = useState(false)
    const [stepReason, setStepReason] = useState(false)
    const [stepCertificate, setStepCertificate] = useState(false)

    const [reasonType, setReasonType] = useState(-1)
    const [reasonEtc, setReasonEtc] = useState("")

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [tel, setTel] = useState("")
    const [telSubmitCode, setTelSubmitCode] = useState("")

    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [telSubmitState, setTelSubmitState] = useState(EditStateTypes.DISABLED)
    const [telSubmitTime, setTelSubmitTime] = useState(0)
    const [telSubmitTimeText, setTelSubmitTimeText] = useState("")

    const naviagte = useNavigate()

    useEffect(() => {
        setStepReason(false)
    }, [reasonType])

    useEffect(() => {
        if (telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.WAIT) {
            setTelSubmitState(EditStateTypes.DISABLED)
            setTelSubmitCode("")
        }
        if (telAuthState === EditStateTypes.PENDING) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
    }, [telAuthState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelSubmitState(EditStateTypes.DISABLED)
        }

        let newTel = tel
        newTel = tel.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
        if (newTel.length > 12) {
            newTel = newTel.slice(0, 12)
        }
        setTel(newTel)
    }, [tel])

    useEffect(() => {
        if (telSubmitState !== EditStateTypes.DISABLED) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
        if (telSubmitCode.length > 20) {
            setTelSubmitCode(telSubmitCode.slice(0, 20))
        }
    }, [telSubmitCode])

    useEffect(() => {
        const time = setInterval(() => {
            const second = telSubmitTime - 1
            setTelSubmitTimeText(`${Math.floor(second / 60)} : ${second % 60}`)
            setTelSubmitTime(second)
        }, 1000)

        if (telSubmitTime === 0 || telAuthState !== EditStateTypes.PENDING) {
            clearInterval(time)
        }

        return () => clearInterval(time)
    }, [telSubmitTime])

    const telAuth = async () => {
        if (tel.replaceAll("-", "") !== userDetail.user.tel.replaceAll("-", "")) {
            alert("회원님의 전화번호와 일치하지 않습니다.")
            return
        }

        if (
            !Utils.isStringNullOrEmpty(tel) &&
            (telAuthState === EditStateTypes.WAIT || telAuthState === EditStateTypes.PENDING)
        ) {
            const requestData = { phone_number: tel }

            // 전화번호로 SMS 전송
            await sendAuthSMS(requestData)

            setTelAuthState(EditStateTypes.PENDING)
            setTelSubmitState(EditStateTypes.WAIT)
            setTelSubmitTime(60 * 5)
        }
        if (telAuthState === EditStateTypes.DONE) {
            setTelAuthState(EditStateTypes.WAIT)
        }
    }

    const telSubmit = async () => {
        if (!Utils.isStringNullOrEmpty(telSubmitCode)) {
            const requestData = {
                phone_number: tel,
                auth_number: telSubmitCode,
            }

            // 전화번호로 SMS 전송
            const response = await sendAuthSubmitSMS(requestData)
            const smsResult = response.result

            if (smsResult) {
                setTelAuthState(EditStateTypes.DONE)
                setTelSubmitState(EditStateTypes.DONE)
                setStepCertificate(true)
            } else {
                setTelAuthState(EditStateTypes.PENDING)
                setTelSubmitState(EditStateTypes.FAILED)
            }
        }
    }

    const handleCertificate = () => {
        checkUserCertificate(email, password)
            .then((response) => {
                setStepCertificate(response)

                if (!response) {
                    alert("이메일 및 비밀번호가 틀렸습니다.")
                }
            })
            .catch((error) => {
                alert("에러가 발생했습니다.")
            })
    }

    const handleSubmit = () => {
        if (!confirm("탈퇴 하시겠습니까?")) {
            return
        }

        let reason = ""

        if (reasonType === -1) {
            reason = reasonEtc
        } else {
            if (userDetail.user.userType === UserTypes.BRAND) {
                reason = BRAND_DELETE_REASON[reasonType]
            } else if (userDetail.user.userType === UserTypes.MARKETER) {
                reason = MARKETER_DELETE_REASON[reasonType]
            }
        }

        deleteUser(reason)
            .then((response) => {
                alert("회원 탈퇴 처리되었습니다.")
                logout()
                actions.setLogout()
                naviagte("/")
            })
            .catch((error) => {
                alert("에러가 발생했습니다.")
            })
    }

    const getInfoDeleteData = () => {
        if (userDetail.user.userType === UserTypes.BRAND) {
            return BRAND_DELETE_DATA
        } else if (userDetail.user.userType === UserTypes.MARKETER) {
            return MARKETER_DELETE_DATA
        } else {
            return []
        }
    }
    const getInfoNotDeleteData = () => {
        if (userDetail.user.userType === UserTypes.BRAND) {
            return BRAND_NOT_DELETE_DATA
        } else if (userDetail.user.userType === UserTypes.MARKETER) {
            return MARKETER_NOT_DELETE_DATA
        } else {
            return []
        }
    }

    const handleKeyPress = useCallback(
        (e) => {
            if (e.key == "Enter" && !Utils.isStringNullOrEmpty(email) && !Utils.isStringNullOrEmpty(password)) {
                handleCertificate()
            }
        },
        [email, password],
    )

    const availableDelete = stepInfo && stepReason && stepCertificate

    return (
        <MyInfoDeleteContainer>
            <MyInfoDeleteHead>
                <MyInfoDeleteTitle>회원 탈퇴</MyInfoDeleteTitle>
            </MyInfoDeleteHead>

            <MyInfoDeleteContents>
                <MyInfoDeleteSectionBox active={stepInfo}>
                    <MyInfoDeleteSectionTitle active={stepInfo}>안내 사항</MyInfoDeleteSectionTitle>

                    <MyInfoDeleteSectionDescription>
                        {userDetail.user.name}님의 회원 탈퇴 후에도 웹사이트에서 제공하는 비회원 제공 서비스는 사용할 수
                        있으며, 무분별한 회원가입을 막기 위해서 회원 탈퇴 후 6개월 동안 회원가입할 수 없습니다.
                    </MyInfoDeleteSectionDescription>

                    <MyInfoDeleteSectionSubTitle className="mt-0">
                        아래 항목은 회원 탈퇴 시 삭제됩니다.
                    </MyInfoDeleteSectionSubTitle>
                    <TableNonColumnSimple data={getInfoDeleteData()} />

                    <MyInfoDeleteSectionSubTitle>아래 항목은 회원 탈퇴 시 남아 있습니다.</MyInfoDeleteSectionSubTitle>
                    <TableNonColumnSimple data={getInfoNotDeleteData()} />

                    <MyInfoDeleteSectionControlBox>
                        <MyInfoDeleteSectionConfirmButton
                            onClick={() => {
                                setStepInfo(true)
                            }}
                        >
                            상기 내용에 동의합니다
                        </MyInfoDeleteSectionConfirmButton>
                    </MyInfoDeleteSectionControlBox>
                </MyInfoDeleteSectionBox>

                <MyInfoDeleteSectionBox active={stepReason}>
                    <MyInfoDeleteSectionTitle active={stepReason}>탈퇴 사유</MyInfoDeleteSectionTitle>
                    <MyInfoDeleteSectionDescription>탈퇴하시는 사유를 적어주세요.</MyInfoDeleteSectionDescription>
                    {userDetail.user.userType === UserTypes.BRAND &&
                        BRAND_DELETE_REASON.map((reason, index) => (
                            <OptionItem
                                key={index}
                                isActive={reasonType === index}
                                setActive={() => {
                                    setReasonType(index)
                                }}
                                name={reason}
                            />
                        ))}
                    {userDetail.user.userType === UserTypes.MARKETER &&
                        MARKETER_DELETE_REASON.map((reason, index) => (
                            <OptionItem
                                key={index}
                                isActive={reasonType === index}
                                setActive={() => {
                                    setReasonType(index)
                                }}
                                name={reason}
                            />
                        ))}
                    <OptionItem
                        isActive={reasonType === -1}
                        setActive={() => {
                            setReasonType(-1)
                        }}
                        name={"기타"}
                    />

                    <MyInfoDeleteOptionEtc value={reasonEtc} onChange={(e) => setReasonEtc(e.target.value)} />

                    <MyInfoDeleteSectionControlBox>
                        <MyInfoDeleteSectionConfirmButton
                            onClick={() => {
                                setStepReason(true)
                            }}
                        >
                            작성
                        </MyInfoDeleteSectionConfirmButton>
                    </MyInfoDeleteSectionControlBox>
                </MyInfoDeleteSectionBox>

                <MyInfoDeleteSectionBox active={stepCertificate}>
                    <MyInfoDeleteSectionTitle active={stepCertificate}>본인 인증</MyInfoDeleteSectionTitle>
                    <MyInfoDeleteSectionDescription>
                        본인 인증을 위해 전화번호로 인증번호 전송 후 인증코드를 입력해주세요.
                    </MyInfoDeleteSectionDescription>
                    {/* <MyPageUserInput
                        label={"이메일"}
                        type={"text"}
                        data={email}
                        isTel={true}
                        setData={setEmail}
                        onKeyPress={handleKeyPress}
                    />
                    <MyPageUserInput
                        label={"비밀번호"}
                        type={"password"}
                        data={password}
                        isTel={true}
                        setData={setPassword}
                        onKeyPress={handleKeyPress}
                    /> */}

                    <MyPageUserInput
                        label={"전화번호"}
                        type={"text"}
                        data={tel}
                        isTel={true}
                        setData={setTel}
                        maxLength={20}
                    >
                        <UserSMSAuthRequestButton telAuthState={telAuthState} telAuth={telAuth} />
                    </MyPageUserInput>
                    <MyPageUserInput
                        label={"전화번호 인증"}
                        type={"text"}
                        data={telSubmitCode}
                        setData={setTelSubmitCode}
                        disabled={telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE}
                    >
                        <UserSMSAuthSubmitButton
                            telAuthState={telAuthState}
                            telSubmitState={telSubmitState}
                            telSubmitTimeText={telSubmitTimeText}
                            telSubmit={telSubmit}
                        />
                    </MyPageUserInput>

                    {/* <MyInfoDeleteSectionControlBox>
                        <MyInfoDeleteSectionConfirmButton
                            onClick={() => {
                                handleCertificate()
                            }}
                        >
                            인증
                        </MyInfoDeleteSectionConfirmButton>
                    </MyInfoDeleteSectionControlBox> */}
                </MyInfoDeleteSectionBox>

                <MyInfoDeleteControlBox>
                    {!availableDelete && (
                        <MyInfoDeleteNotMessage className="mr-4">모든 절차를 완료해 주세요.</MyInfoDeleteNotMessage>
                    )}
                    <MyInfoDeleteButton
                        disabled={!availableDelete}
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        회원 탈퇴
                    </MyInfoDeleteButton>
                </MyInfoDeleteControlBox>
            </MyInfoDeleteContents>
        </MyInfoDeleteContainer>
    )
}

const OptionItem = ({ isActive, setActive, name }) => {
    return (
        <MyInfoDeleteOptionItemBox
            onClick={() => {
                setActive()
            }}
        >
            <div className="flex flex-center h-4 aspect-square mr-2">
                {isActive ? <OptionIconRadioCheck /> : <OptionIconRadioUncheck />}
            </div>
            <MyInfoDeleteOptionItemName>{name}</MyInfoDeleteOptionItemName>
        </MyInfoDeleteOptionItemBox>
    )
}
