import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import {debounce} from "lodash"

import {PmsOfficeNavState} from '../../../types/DataEnum'
// import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd"
import { Dictionary } from "../../../types/Dictionary";
import { createPerformColumn, deletePerformColumn, deletePerformColumnForm, getCountPerformColumnValue, getItemPerformColumnOrders, getItemPerformColumns, importItemPerformColumn, updateItemPerformColumnOrders, updatePerformColumn, updatePerformColumnForm } from "../../../apis/api/boardItems";
import { getPerformValueStatics } from "../../../types/brandItems/performValues/PerformValueStatics";
import { performColumnStatics } from "../../../types/brandItems/performColumns/PerformColumnStatics";
import { PerformColumnFormTypes, PerformColumnTypes, PerformStaticColumnTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import { ContentContainer } from "../MyPageMainStyle";
import { useLocation, useSearchParams } from "react-router-dom";
import { useUserStore } from "../../../zustand/store";
import { PerformAddButton, PerformBatchUpdateButton, PerformListControlBox, PerformTableContainer, PerformTableSort, PerformTableSortTop } from "./MyPagePerformStyle";
import StaffPerformTable from "./StaffPerformTable";
import { QueryTypes } from '../../../types/EditorTypes';
import Utils from "../../../utils/Utils";
import { ModalContainer } from "../../../components/ModalContainer";
import StaffPerformUpdateStatus from "./StaffPerformUpdateStatus";
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn";
import StaffPerformUpdateOption from "./StaffPerformUpdateOption";
import StaffPerformUpdateCombo from "./StaffPerformUpdateCombo";
import StaffPerformUpdateDate from "./StaffPerformUpdateDate";

const CELL_MIN_WIDTH = 100

// 각 행에 고정 컬럼 정보를 넣는다.
const updateRowsStaticValue = (performs) => {
    if (performs === undefined) {
        return performs
    }

    performs.map((perform) => {
        const staticValues = getPerformValueStatics(perform)
        staticValues.map(staticValue => {
            perform.values.push(staticValue)
        })
    })
    return performs
}

// 동적 컬럼과 고정 컬럼을 합친다.
const updateColumnsStatic = (columns) =>{
    const newColumns = [...columns, ...performColumnStatics]
    return newColumns
}

// 각 컬럼의 너비를 테이블 너비에 맞춰 계산한다.
const updateColumnSize = (columns, tableWidth) => {
    // 고정 너비들의 합.
    const widthStaticSum = columns.reduce(function add(sum, currValue) {
        if (!currValue.width.includes("*")) {
            return sum + Number(currValue.width)
        } else {
            return sum
        }
    }, 0)
    // 비율 너비들의 비율 합. (비율 너비는 *을 포함)
    const widthRatioSum = columns.reduce(function add(sum, currValue) {
        if (currValue.width.includes("*")) {
            const widthRatio = Number(currValue.width.replace("*", ""))
            return sum + widthRatio
        } else {
            return sum
        }
    }, 0)
    // 테이블 너비에서 고정 너비들의 합을 뺀다. 고정 너비의 영향을 받지 않고 비율 너비들의 너비값 구할 때 사용.
    const tableWidthOnlyRatio = tableWidth - widthStaticSum

    // 해당 값보다 낮아지면 최소값을 사용한다.
    const useMinWidth = tableWidthOnlyRatio*(1/widthRatioSum) < CELL_MIN_WIDTH
    let newColumns = []

    columns.map((column) => {
        let newColumn = {...column}

        if (useMinWidth) {
            if (newColumn.width.includes("*")) {
                const widthRatio = Number(column.width.replace("*", ""))
                newColumn.widthCalculated = CELL_MIN_WIDTH * widthRatio
            } else {
                newColumn.widthCalculated = newColumn.width
            }
        } else {
            if (newColumn.width.includes("*")) {
                const widthRatio = Number(column.width.replace("*", ""))
                newColumn.widthCalculated = tableWidthOnlyRatio*(widthRatio/widthRatioSum)
            } else {
                newColumn.widthCalculated = newColumn.width
            }
        }
        newColumns.push(newColumn)
    })

    return newColumns
}

export default function StaffPerformColumn({selectedItem, setOfficeNavState}){
    const tableRef = useRef(0);
    const {user} = useUserStore()

    const [isLoading, setLoading] = useState(true)

    // 실적과 동적 컬럼 정보. (아직 테이블에 사용하기 위해 가공되기 전 상태)
    const [rawColumns, setRawColumns] = useState([])
    const [rawColumnOrder, setRawColumnOrder] = useState({})

    const [columnOrders, setColumnOrders] = useState([]) // DB에 저장한 Column 정렬 정보 [columnID, columnID, columnID, ...]
    const [columnDic, setColumnDic] = useState(new Dictionary()) // 컬럼의 아이디를 키로 딕셔너리화
    const [columns, setColumns] = useState([]) // 컬럼 정렬 및 너비 계산이 완료된 배열
    const [sortVisible, setSortVisible] = useState(false) // 필터 바 표시
    const [tableWidth, setTableWidth] = useState(tableRef.current.offsetWidth) // 테이블 너비 (브라우저 크기에 따른 컬럼 너비 계산을 위해 사용)

    const [selectedColumn, setSelectedColumn] = useState(new PerformColumn())
    const [showUpdateColumn, setShowUpdateColumn] = useState(false) // 현황 수정 모달 여부

    useEffect(() => {
        setOfficeNavState(PmsOfficeNavState.PERFORM_COLUMN)
        window.addEventListener('resize', handleResize)
        
        setLoading(false)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(()=>{
        getColumnData()
    }, [selectedItem])

    useEffect(()=>{
        setTableWidth(tableRef.current.offsetWidth)
    }, [tableRef.current.offsetWidth])

    useEffect(()=>{
        const staticUpdatedColumns = updateColumnsStatic(rawColumns)
        const sizeUpdatedColumns = updateColumnSize(staticUpdatedColumns, tableWidth)

        const newColumnDic = new Dictionary()
        sizeUpdatedColumns.map(column => {
            newColumnDic.push(column.id.toString(), column)
        })

        setColumnDic(newColumnDic)
    }, [tableWidth, rawColumns])

    useEffect(()=>{
        let newColumns = []

        if (columnDic.getLength() > 0 && columnOrders.length > 0) {
            columnOrders.map((columnID) => {
                const column = columnDic.getValue(columnID)
                if (column === undefined) {
                    return
                }
                newColumns.push(column)
            })
        }

        setColumns(newColumns)
    }, [columnDic, columnOrders])

    const getColumnData = async () => {
        setRawColumns([])

        const columns =  await getItemPerformColumns(selectedItem.id)
        setRawColumns(columns)
        const columnIDs = columns.map(c => c.id)

        getColumnOrder(columnIDs)

        // 수정을 위해 선택한 컬럼이 있다면 선택한 컬럼 데이터도 새로 업데이트한다.
        if (selectedColumn.id >= 0) {
            const updatedSelectedColumns = columns.filter(c => c.id === selectedColumn.id)

            if (updatedSelectedColumns.length > 0) {
                setSelectedColumn(updatedSelectedColumns[0])
            }
        }
    }

    const getColumnOrder = (columnIDs) => {
        getItemPerformColumnOrders(selectedItem.id).then((response) => {
            // 컬럼 순서 업데이트 시 사용
            setRawColumnOrder({...response})

            response[-1] = PerformStaticColumnTypes.SELECT
            const keys = Object.keys(response).map(k => Number(k))
            const sortedKeys = keys.sort((a,b) => (a-b))
            let orders = []
            sortedKeys.map(sortedKey => {
                // columnOrders가 없는 column을 가져오는 경우도 있다.
                if (columnIDs.includes(response[sortedKey]) || response[sortedKey] < 0) {
                    orders.push(response[sortedKey])
                }
            })

            setColumnOrders(orders)
        })
    }
    
    const handleResize = debounce(() => {
        setTableWidth(tableRef.current.offsetWidth)
    }, 300)

    const handleColumnSave = (column) => {
        updatePerformColumn(column).then(response => {
            if (response) {
                getColumnData()
            }
        })
    }

    const handleColumnOrder = (column, direction=0) => {
        if (column.id === PerformStaticColumnTypes.SELECT) {
            alert("이동할 수 없는 컬럼입니다.")
            return
        }

        let newColumnOrders = [...columnOrders]
        let mainColumnIndex = -1
        newColumnOrders.map((columnID, orderIndex) => {
            if (column.id === columnID) {
                mainColumnIndex = orderIndex
                return
            }
        })

        if (mainColumnIndex >= 0) {
            const movedIndex = mainColumnIndex + direction
            if (movedIndex === 0 || movedIndex >= newColumnOrders.length) {
                alert("이동할 수 없는 위치입니다.")
                return
            }

            newColumnOrders[mainColumnIndex] = columnOrders[mainColumnIndex + direction]
            newColumnOrders[mainColumnIndex + direction] = column.id

            // 체크 컬럼 제거
            newColumnOrders.shift()

            let newColumnOrderDic = {}
            newColumnOrders.map((columnID, orderIndex) => {
                newColumnOrderDic[orderIndex] = columnID
            })

            updateItemPerformColumnOrders(selectedItem.id, newColumnOrderDic).then(response => {
                if (response) {
                    getColumnOrder(rawColumns.map(c => c.id))
                }
            })
        }
    }

    const handleColumnSub = (columnForm, queryType) => {
        if (queryType === QueryTypes.CREATE) {
            updatePerformColumnForm(columnForm).then(response => {
                if (response) {
                    getColumnData()
                }
            })
        } else if (queryType === QueryTypes.DELETE) {
            deletePerformColumnForm(columnForm).then(response => {
                if (response) {
                    getColumnData()
                }
            })
        }
    }

    const handleColumnAdd = () => {
        createPerformColumn(selectedItem.id).then(response => {
            if (response) {
                getColumnData()
            }
        })
    }

    const handleColumnImport = () => {
        if (!confirm("등록된 실적이 없어야합니다. 진행하시겠습니까?")) {
            return
        }

        const importItemID = Number(prompt("불러올 상품의 ID를 입력해주세요"))
        if (!isNaN(importItemID)) {
            importItemPerformColumn(selectedItem.id, importItemID).then(response => {
                if (response) {
                    alert("실적 컬럼 정보를 불러왔습니다.")
                    getColumnData()
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    alert("이미 실적이 존재합니다. (삭제된 실적 포함)")
                }
            })
        }
    }

    const handleColumnDelete = async (columnID) => {
        if (confirm(`컬럼을 삭제하시겠습니까?\n모든 실적에 해당 컬럼에 값이 없어야합니다.`)) {
            const performValueCount = await getCountPerformColumnValue(columnID)

            if (performValueCount === 0) {
                deletePerformColumn(columnID).then(response => {
                    if (response) {
                        getColumnData()
                    }
                })
            }
        }
    }

    const handleShowUpdateColumn = (column) => {
        setSelectedColumn(column)
        setShowUpdateColumn(true)
    }
    
    return(
        <ContentContainer full_height>
            <PerformListControlBox>
            </PerformListControlBox>

            <PerformTableContainer>
                <PerformTableSort sortVisible={sortVisible} ref={tableRef}>
                    <PerformTableSortTop>
                        <p></p>
                        <div className="flex items-center">
                            <PerformBatchUpdateButton className="max-lg:w-auto lg:w-auto px-2" onClick={() => {handleColumnImport()}}>컬럼 불러오기</PerformBatchUpdateButton>
                            <PerformAddButton onClick={() => {handleColumnAdd()}}>컬럼 추가</PerformAddButton>
                        </div>
                    </PerformTableSortTop>
                </PerformTableSort>
                
                <div className="relative w-full h-full">
                    {columns.length > 0 && (
                        <StaffPerformTable 
                            columns={columns}
                            columnSave={handleColumnSave}
                            columnOrder={handleColumnOrder}
                            columnSubEdit={handleColumnSub}
                            columnDelete={handleColumnDelete}
                            showUpdateColumn={handleShowUpdateColumn}
                        />
                    )}
                </div>
            </PerformTableContainer>

            <ModalContainer show={showUpdateColumn} setShow={setShowUpdateColumn} fadeDown isBlur closeByBackground>
                {selectedColumn.formType == PerformColumnFormTypes.STATUS && (
                    <StaffPerformUpdateStatus
                        selectedColumn={selectedColumn}
                        columnSubEdit={handleColumnSub}
                    />
                )}
                {selectedColumn.formType == PerformColumnFormTypes.OPTION && (
                    <StaffPerformUpdateOption
                        selectedColumn={selectedColumn}
                        columnSubEdit={handleColumnSub}
                    />
                )}
                {selectedColumn.formType == PerformColumnFormTypes.COMBO && (
                    <StaffPerformUpdateCombo
                        selectedColumn={selectedColumn}
                        columnSubEdit={handleColumnSub}
                    />
                )}
                {selectedColumn.formType == PerformColumnFormTypes.DATE && (
                    <StaffPerformUpdateDate
                        selectedColumn={selectedColumn}
                        columnSubEdit={handleColumnSub}
                    />
                )}
            </ModalContainer>
        </ContentContainer>
    )
}