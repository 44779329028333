import React, { useState, useEffect } from "react"
import tw from "tailwind-styled-components"
import { ContentContainer } from "../MyPageMainStyle"
import { PmsNavState, TextFormats } from "../../../types/DataEnum"
import { MyPagePointDashBox, MyPagePointDashPointBox, MyPagePointLayout } from "./MyPagePointStyle"
import { useUserStore } from "../../../zustand/store"
import { UserTypes } from "../../../types/users/UserTypes"
import PointSet from "../../../types/points/PointSet"
import ModalContainerPortal from "../../../components/ModalContainerPortal"
import Utils from "../../../utils/Utils"
import MyPagePointSetWrite from "./MyPagePointSetWrite"

import * as PointApi from "../../../apis/api/points/index"
import MyPageCouponList from "./MyPageCouponList"
import MyPageCouponHistory from "./MyPageCouponHistory"
import MyPagePointHistory from "./MyPagePointHistory"
import User from "../../../types/users/User"
import CouponInfoImage from "../../../static/coupon_info.png"

export default function MyPagePointMain({ setPmsNavState }) {
    const { user, userType, userDetail, actions } = useUserStore()

    // 스태프
    const [pointInfoLoading, setPointInfoLoading] = useState(false)
    const [pointSet, setPointSet] = useState(new PointSet())
    const [pointAll, setPointAll] = useState(0)
    const [accumPointAll, setAccumPointAll] = useState(0)
    const [showPointSetModal, setShowPointSetModal] = useState(false)

    const [giftiInfoLoading, setGiftiInfoLoading] = useState(false)
    const [giftiBalance, setGiftiBalance] = useState("")
    const [giftiRefreshLatest, setGiftiRefreshLatest] = useState("")

    // 마케터
    const [myPoint, setMyPoint] = useState(0)
    const [myRecommands, setMyRecommands] = useState(0)
    const [myAccumPoint, setMyAccumPoint] = useState(0)
    const [myCouponsDone, setCouponsDone] = useState(0)
    const [myCouponsApply, setCouponsApply] = useState(0)
    const [showCouponHistoryModal, setShowCouponHistoryModal] = useState(false)
    const [showPointHistoryModal, setShowPointHistoryModal] = useState(false)

    useEffect(() => {
        setPmsNavState(PmsNavState.POINT)
        updateUser()
    }, [])

    useEffect(() => {
        if (!showPointSetModal) {
            getPointInfo()
            getGiftiInfo()
        }
    }, [showPointSetModal])

    const getPointSet = async () => {
        if (userType !== UserTypes.STAFF) {
            return
        }

        const _pointSet = await PointApi.getPointSet()
        setPointSet(_pointSet)
    }

    const getPointInfo = async () => {
        setPointInfoLoading(true)
        await getPointSet()

        const response = await PointApi.getPointInfo()
        setPointAll(response["point_all"])
        setAccumPointAll(response["accum_point_all"])

        setMyPoint(response["my_point"])
        setMyRecommands(response["my_recommands"])
        setMyAccumPoint(response["my_accum_point"])
        setCouponsDone(response["my_coupons_done"])
        setCouponsApply(response["my_coupons_apply"])
        setPointInfoLoading(false)
    }

    const getGiftiInfo = async () => {
        if (userType !== UserTypes.STAFF) {
            return
        }

        setGiftiInfoLoading(true)
        const response = await PointApi.getGiftiBalance()
        setGiftiInfoLoading(false)

        try {
            setGiftiRefreshLatest(response["thumb_last_datetime"])
            setGiftiBalance(response["balance"])
        } catch {
            //
        }
    }

    const updateUser = () => {
        actions.updateUserDetail(user)
        getPointInfo()
    }

    const ImageBanner = () => {
        return (
            <div className="relative flex w-full max-md:mb-2 md:mr-4 rounded-lg bg-gradient-to-tr from-slate-400 to-slate-200 overflow-hidden">
                <img
                    className="absolute absolute-center center aspect-[864/128] w-auto h-full bg-nightblue-500 object-cover"
                    src={CouponInfoImage}
                ></img>
            </div>
        )
    }

    const handleGiftiInfo = async () => {
        if (!confirm("잔액을 새로고침 하시겠습니까?")) {
            return
        }

        getGiftiInfo()
    }

    const handleThumbRefresh = async () => {
        if (!confirm("갱신하시겠습니까?\n10초가량 소요되며 트래픽이 적을 때 갱신해주세요.")) {
            return
        }

        alert("데이터 갱신 시작되었습니다.")
        const response = await PointApi.getGiftiCouponRefresh()

        if (response) {
            getGiftiInfo()
            alert("갱신 완료되었습니다.")
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <ContentContainer>
            <MyPagePointLayout>
                <div className="text-2xl max-lg:px-1 py-6">포인트</div>
                {userType === UserTypes.STAFF && (
                    <MyPagePointDashBox>
                        <ImageBanner />
                        <div className="flex items-center max-md:w-full max-md:space-x-2 md:space-x-4">
                            <MyPagePointDashPointBox className="max-md:w-0 md:w-44 cursor-default">
                                <span className="title">기프티쇼 내역</span>
                                <span className="label">현재 남은 잔액</span>
                                <span
                                    className={`value ${
                                        giftiInfoLoading ? "loading" : ""
                                    } px-1 rounded hover:bg-white/20 duration-200 cursor-pointer`}
                                    onClick={() => {
                                        handleGiftiInfo()
                                    }}
                                >
                                    {Utils.textFormat(giftiBalance, TextFormats.PRICE)}
                                </span>
                                <span className="label">최근 기프티쇼 상품 갱신 일시</span>
                                <span
                                    className={`value ${
                                        giftiInfoLoading ? "loading" : ""
                                    } px-1 rounded hover:bg-white/20 duration-200 cursor-pointer`}
                                    onClick={() => {
                                        handleThumbRefresh()
                                    }}
                                >
                                    {giftiRefreshLatest}
                                </span>
                            </MyPagePointDashPointBox>
                            <MyPagePointDashPointBox
                                onClick={() => {
                                    setShowPointHistoryModal(true)
                                }}
                            >
                                <span className="title">회원 포인트 내역</span>
                                <span className="label">총 지급된 포인트</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(pointAll, TextFormats.NUMBER)}
                                </span>
                                <span className="label">총 보유 중인 포인트</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(accumPointAll, TextFormats.NUMBER)}
                                </span>
                            </MyPagePointDashPointBox>
                            <MyPagePointDashPointBox
                                onClick={() => {
                                    setShowPointSetModal(true)
                                }}
                            >
                                <span className="title">포인트 설정</span>
                                <span className="label">추천인 (한 사람/받은 사람)</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(pointSet.pointReferral, TextFormats.NUMBER)} /{" "}
                                    {Utils.textFormat(pointSet.pointReferred, TextFormats.NUMBER)}
                                </span>
                                <span className="label">제한 (유저/하루) | 오늘 누적</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(pointSet.limitPriceUser, TextFormats.NUMBER)} /{" "}
                                    {Utils.textFormat(pointSet.limitPriceDay, TextFormats.NUMBER)}
                                </span>
                                {/* <span className="label">오늘 전체 포인트 사용량</span> */}
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(pointSet.usedPointDay, TextFormats.NUMBER)}P
                                </span>
                            </MyPagePointDashPointBox>
                        </div>
                    </MyPagePointDashBox>
                )}
                {userType === UserTypes.MARKETER && (
                    <MyPagePointDashBox>
                        <ImageBanner />
                        <div className="flex items-center max-md:w-full max-md:space-x-2 md:space-x-4">
                            <MyPagePointDashPointBox
                                onClick={() => {
                                    setShowPointHistoryModal(true)
                                }}
                            >
                                <span className="title">내 포인트</span>
                                <span className="label">보유 중인 포인트</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(myPoint, TextFormats.NUMBER)}
                                </span>
                                <span className="label">총 누적 포인트 / 추천인</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(myAccumPoint, TextFormats.NUMBER)} /{" "}
                                    {Utils.textFormat(myRecommands, TextFormats.NUMBER)}
                                </span>
                            </MyPagePointDashPointBox>
                            <MyPagePointDashPointBox
                                onClick={() => {
                                    setShowCouponHistoryModal(true)
                                }}
                            >
                                <span className="title">내 쿠폰</span>
                                {myCouponsApply > 0 && (
                                    <>
                                        <span className="label">신청한 쿠폰</span>
                                        <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                            {Utils.textFormat(myCouponsApply, TextFormats.NUMBER)}
                                        </span>
                                    </>
                                )}
                                <span className="label">받은 쿠폰</span>
                                <span className={`value ${pointInfoLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(myCouponsDone, TextFormats.NUMBER)}
                                </span>
                            </MyPagePointDashPointBox>
                        </div>
                    </MyPagePointDashBox>
                )}

                <MyPageCouponList
                    user={user}
                    userType={userType}
                    updateUser={updateUser}
                    myRecommands={myRecommands}
                    myAccumPoint={myAccumPoint}
                />
            </MyPagePointLayout>

            <ModalContainerPortal
                show={showPointSetModal}
                setShow={setShowPointSetModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPagePointSetWrite showModal={showPointSetModal} setShowModal={setShowPointSetModal} />
            </ModalContainerPortal>
            <ModalContainerPortal
                show={showPointHistoryModal}
                setShow={setShowPointHistoryModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPagePointHistory
                    showModal={showPointHistoryModal}
                    setShowModal={setShowPointHistoryModal}
                    userType={userType}
                    defaultUser={new User()}
                />
            </ModalContainerPortal>
            <ModalContainerPortal
                show={showCouponHistoryModal}
                setShow={setShowCouponHistoryModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageCouponHistory showModal={showCouponHistoryModal} setShowModal={setShowCouponHistoryModal} />
            </ModalContainerPortal>
        </ContentContainer>
    )
}
