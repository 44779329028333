import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { Pagination } from "../../../components/Pagination"
import {
    NoticeAddButton,
    NoticeBody,
    NoticeListBody,
    NoticeListData,
    NoticeSearchContainer,
    NoticeListRow,
    NoticeEditEditContainer,
    NoticePagination,
    NoticeTable,
    NoticeTableHeader,
    NoticeTableHeaderBox,
    NoticeTitle,
    NoticeNoticeSearchInput,
    NoticeSearchButton,
    NoticeResetButton,
    SearchIcon,
    ResetIcon,
} from "./MyPageNoticeStyle"
import { ModalContainer } from "../../../components/ModalContainer"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ContentContainer } from "../MyPageMainStyle"
import WebEditor from "../../../components/WebEditor"
import { PaginationStyles } from "../../../types/StyleTypes"
import { addItemNotice, getItemNotice, getItemNotices, updateItemNotice } from "../../../apis/api/boardItems"
import BrandItemNotice from "../../../types/brandItems/notices/BrandItemNotice"
import AccessRules from "../../../rules/AccessRules"
import { useUserStore } from "../../../zustand/store"
import BoardEdit from "../../../components/boards/BoardEdit"
import { FilterInputReset, FilterInputText } from "../../../components/brandItems/FilterInputs"
import { RESPONSIVE_WIDTH } from "../../../constants/ConstUI"
import { BoardImportnaceStatusTypes } from "../../../types/boards/BoardTypes"
import { BoardImportanceLabel } from "../../../components/boards/BoardDetailStyle"

const MAX_PAGE_LENGTH = 10
const CellWidth = {
    Index: "max-lg:min-w-[2rem] lg:min-w-[4rem] flex-shrink-0",
    Title: "w-full",
    User: "max-lg:min-w-[3rem]  lg:min-w-[8rem] flex-shrink-0",
    Created: "max-lg:min-w-[7rem] lg:min-w-[8rem] flex-shrink-0",
}

const NoticeRow = ({ notice, importance }) => {
    const navigation = useNavigate()
    // console.log(notice)
    const handleLink = () => {
        navigation(notice.id.toString())
    }

    return (
        <NoticeListRow importance={importance}>
            {/* 공지사항 데이터 row 형식 */}
            <NoticeListData className={CellWidth.Index}>{notice.index}</NoticeListData>
            {/* link to 에 해당 공지 사항의 id값 넣으면 됨 */}
            <NoticeListData
                className={`${CellWidth.Title} max-sm:flex-col sm:flex-row justify-start max-sm:items-start sm:items-center`}
                onClick={() => {
                    handleLink()
                }}
            >
                {importance === BoardImportnaceStatusTypes.IMPORTANT && (
                    <BoardImportanceLabel className="max-sm:mb-1">중요공지</BoardImportanceLabel>
                )}
                {notice.board.title}
            </NoticeListData>
            <NoticeListData className={CellWidth.User}>{notice.board.user.name}</NoticeListData>
            <NoticeListData className={CellWidth.Created}>{notice.created}</NoticeListData>
        </NoticeListRow>
    )
}

const NoticeColumns = () => {
    return (
        <NoticeTableHeaderBox>
            <NoticeTableHeader className={CellWidth.Index}>NO</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.Title}>제목</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.User}>작성자</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.Created}>날짜</NoticeTableHeader>
        </NoticeTableHeaderBox>
    )
}

export default function MyPageNotice({ setOfficeNavState, selectedItem }) {
    const { user } = useUserStore()

    const [showNoticeEdit, setShowNoticeEdit] = useState(false)
    const [noticeID, setNoticeID] = useState(-1)

    const [notices, setNotices] = useState([])
    const [defaultNotice, setDefaultNotice] = useState([])

    const [pageSize, setPageSize] = useState(10)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        setOfficeNavState(PmsOfficeNavState.NOTICE)
        return () => setOfficeNavState(PmsOfficeNavState.NONE)
    }, [])

    useEffect(() => {
        getNotices(pageIndex, searchValue)
    }, [selectedItem])

    const getNotices = async (_pageIndex, _searchValue) => {
        const response = await getItemNotices(selectedItem.id, _pageIndex, pageSize, _searchValue)
        if (response !== undefined) {
            setDefaultNotice(response.items)
            const importantNotices = response.items.filter(
                (notice) => notice.board_importance_status === BoardImportnaceStatusTypes.IMPORTANT,
            )
            const regularNotices = response.items.filter(
                (notice) => notice.board_importance_status !== BoardImportnaceStatusTypes.IMPORTANT,
            )
            const sortedNotices = [...importantNotices, ...regularNotices]
            setNotices(sortedNotices)
            setItemCount(response.count)
        }
    }

    const handleAddItemNotice = async (board) => {
        const result = await addItemNotice(selectedItem.id, board)
        return result
    }

    const handleCloseUpdate = (refresh = false) => {
        if (refresh) {
            if (pageIndex > 1) {
                setPageIndex(1)
            } else {
                getNotices(pageIndex, searchValue)
            }
        }
        setShowNoticeEdit(false)
    }

    const handlePageIndex = (_pageIndex) => {
        getNotices(_pageIndex, searchValue)
        setPageIndex(_pageIndex)
    }

    const handleSearch = () => {
        getNotices(pageIndex, searchValue)
    }

    const handleReset = () => {
        setPageIndex(1)
        setSearchValue("")
        getNotices(1, "")
    }

    return (
        <ContentContainer>
            <div className="flex flex-col w-full max-w-[66rem] rounded-lg ">
                <NoticeTitle>공지사항</NoticeTitle>
                <NoticeSearchContainer>
                    <FilterInputText
                        value={searchValue}
                        setValue={setSearchValue}
                        submit={() => {
                            handleSearch()
                        }}
                        width={"52"}
                        placeholder={"공지사항 검색"}
                    />

                    <FilterInputReset reset={handleReset} />
                    {/* <NoticeNoticeSearchInput type="search" placeholder="검색"  
                        onChange={(e)=>{setSearchValue(e.target.value)}}
                        onKeyDown={handleOnKeydown}
                    />
                    <NoticeSearchButton
                        onClick={() => {searchSort()}}
                    >
                        <SearchIcon/>
                    </NoticeSearchButton>
                    <NoticeResetButton
                        onClick={() => {resetSort()}}
                    >   
                        <ResetIcon/>
                    </NoticeResetButton> */}
                </NoticeSearchContainer>
                <NoticeBody>
                    <NoticeTable>
                        <NoticeColumns />
                        <NoticeListBody>
                            {/* {notices.map((notice, idx) => (
                                <NoticeRow notice={notice} key={idx} />
                            ))} */}
                            {notices.map((notice, idx) => (
                                <>
                                    <NoticeRow
                                        notice={notice}
                                        importance={notice.board_importance_status}
                                        key={idx}
                                        maxidx={notices.length}
                                        idx={idx}
                                    />
                                </>
                            ))}
                        </NoticeListBody>
                    </NoticeTable>
                    <NoticePagination>
                        <div className="mx-auto">
                            <Pagination
                                page={pageIndex}
                                setPage={handlePageIndex}
                                pageSize={pageSize}
                                itemCount={itemCount}
                                maxPageButtons={MAX_PAGE_LENGTH}
                                styleType={PaginationStyles.NoneBGCircleButtons}
                            />
                        </div>
                        {AccessRules.brandItemUpdate(selectedItem, user) && (
                            <NoticeAddButton
                                onClick={() => {
                                    setShowNoticeEdit(!showNoticeEdit)
                                }}
                            >
                                등록
                            </NoticeAddButton>
                        )}
                    </NoticePagination>
                </NoticeBody>
            </div>
            <ModalContainer show={showNoticeEdit} setShow={setShowNoticeEdit} mobileScreenHeight={RESPONSIVE_WIDTH.SM}>
                {/* <MyPageNoticeEdit itemID={selectedItem.id} noticeID={-1} close={handleCloseUpdate} /> */}
                <BoardEdit
                    mainID={noticeID}
                    getMain={getItemNotice}
                    addMain={handleAddItemNotice}
                    updateMain={updateItemNotice}
                    close={handleCloseUpdate}
                />
            </ModalContainer>
        </ContentContainer>
    )
}
