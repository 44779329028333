export const PaginationStyles = {
    CircleButtons: "CircleButtons",
    NoneBGCircleButtons: "NoneBGCircleButtons",
    TextButtons: "TextButtons",
}

export const AlignTypes = {
    NONE: 0,
    LEFT: 1,
    RIGHT: 2,
    TOP: 3,
    BOTTOM: 4,
}