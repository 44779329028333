import PerformValue from "./PerformValue";
import { PerformColumnFormTypes } from "./PerformValueTypes";

export default class extends PerformValue{
    constructor() {
        super()
        this.columnID = -1
        this.performID = -1
        this.statusID = -1
        this.memo = ""
    }

    convertByResponse(response) {
        this.columnID = response["column_id"]
        this.performID = response["perform_id"]
        this.statusID = response["status"]
        this.memo = response["memo"]
    }

    convertToRequest() {
    }
}