import React, { useState, useEffect } from "react"
import { CheckCircleIcon, StepsLayout, StepLine, CircleIcon } from "../user/SignUpStyle"

export const ItemApplySteps = ({ stepindex }) => {
    var steplist = [
        {
            title: "추가정보",
        },
        {
            title: "전자계약",
        },
    ]

    return (
        <StepsLayout>
            {steplist.map((data, idx) => (
                <>
                    <div
                        className={`${stepindex >= idx ? "text-nightblue-600" : "text-gray-400"} h-6 relative`}
                        key={idx}
                    >
                        {stepindex > idx ? (
                            <CheckCircleIcon />
                        ) : (
                            <CircleIcon
                                className={`${
                                    stepindex === idx
                                        ? "drop-shadow-xl shadow-nightblue-600 border-2 border-nightblue-500 rounded-full"
                                        : ""
                                }`}
                            />
                        )}
                        <div className="absolute left-[-6px]">
                            <span className="text-sm">{"step" + (idx + 1)}</span>
                            <p className="text-sm w-[52px]">{data.title}</p>
                        </div>
                    </div>
                    {idx + 1 !== steplist.length && (
                        <StepLine
                            isorderd={stepindex >= idx + 1}
                            className={stepindex > idx + 1 ? "to-nightblue-600" : ""}
                        />
                    )}
                </>
            ))}
        </StepsLayout>
    )
}
