import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import { addPerformCalc } from "../../../apis/api/boardItems";
import MyPageCalc from "../calculate/MyPageCalc";
import { CalcSelectContainer, CalcSelectList } from "../calculate/MyPageCalcStyle";
import { PerformValueInputTitle } from "./MyPagePerformStyle";

export default function MyPagePerformCalcList({selectedItem, selectedPerformID}){
    return(
        <CalcSelectContainer>
            <PerformValueInputTitle>
                <span>정산 내역</span>
            </PerformValueInputTitle>
            <CalcSelectList>
                <MyPageCalc selectedItem={selectedItem} performID={selectedPerformID} isReadable={true} />
            </CalcSelectList>
        </CalcSelectContainer>
    )
}