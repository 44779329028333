import { ItemEditTabTypes } from "../../types/brandItems/BrandItemTypes"
import FileUtils from "../FileUtils"
import Utils from "../Utils"

export default class {
    static validateItemUpdate(item) {
        let validated = false

        if (Utils.isStringNullOrEmpty(item.detail.title)) {
            alert("제목은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (item.detail.marketerType < 0) {
            alert("마케터 유형은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (item.detail.itemSalesType < 0) {
            alert("상품 유형은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (Utils.isStringNullOrEmpty(item.detail.rewardMessage)) {
            alert("실적 보상(메세지)은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (Utils.isStringNullOrEmpty(item.detail.missionMessage)) {
            alert("영업 업무(메세지)는 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (item.detail.thumbnailImageID < 0) {
            alert("썸네일 이미지를 등록해주세요.")
            return validated
        }
        if (Utils.isStringNullOrEmpty(item.detail.description) || item.detail.description === FileUtils.BLANK_CONTENT) {
            alert("상세 내용은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (Utils.isStringNullOrEmpty(item.contract.mission) || item.contract.mission === FileUtils.BLANK_CONTENT) {
            alert("영업 업무는 빈 값을 넣을 수 없습니다.")
            return validated
        }
        if (Utils.isStringNullOrEmpty(item.contract.reward) || item.contract.reward === FileUtils.BLANK_CONTENT) {
            alert("실적 보상은 빈 값을 넣을 수 없습니다.")
            return validated
        }

        return true
    }
    static validateItemAdUpdate(item, setTabIndex = null) {
        let validated = false

        if (Utils.isStringNullOrEmpty(item.detail.title)) {
            alert("제목은 빈 값을 넣을 수 없습니다.")
            return validated
        }
        // if (Utils.isStringNullOrEmpty(item.detail.description) || item.detail.description === FileUtils.BLANK_CONTENT) {
        //     alert("상세 내용은 빈 값을 넣을 수 없습니다.")

        //     // if (!Utils.isNullOrUndefined(setTabIndex)) {
        //     //     setTabIndex(ItemEditTabTypes.DESCRIPTION)
        //     // }
        //     return validated
        // }
        if (item.detail.adCategory < 1) {
            alert("카테고리를 선택해주세요.")
            return validated
        }
        if (item.detail.sigungu.id < 0) {
            alert("지역은 빈 값을 넣을 수 없습니다.")
            return validated
        }

        return true
    }
}
