import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { MarketerTableDataList } from "../../../constants/ConstMarketer"
import {
    CrownContainer,
    CrownIcon,
    DefineIsMobileDiv,
    MarketerCellButton,
    MarketerCon,
    MarketerControlBar,
    MarketerSortOptionButton,
    MarketerSortVisCon,
    MarketerStatSelect,
    MarketerTableBody,
    MarketerTableButton,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
    MarketerTableSearch,
    MarketerTableSearchCon,
    MarketerTableSortInput,
    MarketerTableSortSelect,
    MarketerToggleButton,
    UserContainer,
    UserIcon,
} from "./MyPageMarketerStyle"
import {
    MarketerInvitePermissionTypeColors,
    MarketerInvitePermissionTypeNames,
    MarketerInvitePermissionTypes,
    MarketerStatusTypeColors,
    MarketerStatusTypeNames,
    MarketerStatusTypes,
    MarketerSuspendStatusTypeColors,
    MarketerSuspendStatusTypeNames,
    MarketerSuspendStatusTypes,
} from "../../../types/brandItems/BrandItemTypes"
import BrandItemMarketer from "../../../types/brandItems/marketers/BrandItemMarketer"
import {
    updateItemMarketerInviteStatePermission,
    updateItemMarketerStatus,
    updateItemMarketerSuspendStatus,
} from "../../../apis/api/boardItems"
import { EditStateTypes } from "../../../types/EditorTypes"
import { userDetailModalStore, marketerContractViewer } from "../../../zustand/modalstore"
import { HoverUserCard } from "../../../components/HoverComponants"
import {
    deleteUserProfileAvatar,
    getUserDetail as getUserDetailApi,
    updateUserProfileAvatar,
} from "../../../apis/api/users"
import { getRegionTypes } from "../../../apis/api/commons"
import { MobileModal } from "./../../../components/MobileModal"
import ItemMarketerNameChain from "../../../components/brandItems/ItemMarketerNameChain"
import { useUserStore } from "../../../zustand/store"
import { sendAlertTalk } from "../../../apis/services/sms"
import { AlertTalkTemplateTypes } from "../../../types/SmsTypes"

const MarketerCellWidth = {
    Index: "min-w-[4rem]",
    Name: "max-lg:w-[10rem] lg:w-[16rem] z-20 sticky left-0 h-10",
    Email: "max-lg:hidden lg:min-w-[16rem]",
    Tel: "max-lg:hidden lg:min-w-[10rem]",
    Age: "max-lg:hidden lg:min-w-[8rem]",
    Location: "max-lg:hidden lg:min-w-[12rem]",
    Performs: "max-lg:min-w-[6rem] lg:min-w-[8rem]",
    Status: "max-lg:min-w-[6rem] lg:min-w-[8rem]",
    Created: "max-lg:min-w-[6rem] lg:min-w-[8rem]",
    Invite: "max-lg:min-w-[7rem] lg:min-w-[8rem]",
}

const getRowMinWidth = (isManage) => {
    if (isManage) {
        return "max-lg:min-w-[41rem] lg:min-w-[102rem] w-full"
    } else {
        return "max-lg:min-w-[23rem] lg:min-w-[78rem] w-full"
    }
}

export const HoverUserCardContainer = ({ isAlt, itemMarketer, handeRowClick, children }) => {
    return (
        <>
            <DefineIsMobileDiv>
                <HoverUserCard
                    isMobile={false}
                    userID={itemMarketer.marketer.user.id}
                    onClick={() => {
                        handeRowClick(itemMarketer)
                    }}
                >
                    {children}
                </HoverUserCard>
            </DefineIsMobileDiv>
            <DefineIsMobileDiv
                className={`${MarketerCellWidth.Name}`}
                isMobile={true}
                isAlt={isAlt}
                onClick={() => {
                    handeRowClick(itemMarketer)
                }}
            >
                {children}
            </DefineIsMobileDiv>
        </>
    )
}

const MarketerTableRow = ({
    isManage,
    item,
    itemMarketer,
    locationTypes,
    openInvite,
    openGroupManager,
    updateWaitMarketers,
    idx,
}) => {
    const [marketerStatusID, setStatusID] = useState(itemMarketer.marketerStatusType)
    const [marketerStatusEditStateType, setMarketerStatusEditStateType] = useState(EditStateTypes.WAIT)
    const [marketerSuspendStatusID, setSuspendStatusID] = useState(itemMarketer.marketerStatusType)
    const [marketerSuspendStatusEditStateType, setMarketerSuspendStatusEditStateType] = useState(EditStateTypes.WAIT)
    const [marketerInvitePermissionID, setInvitePermissionID] = useState(itemMarketer.marketerStatusType)
    const [marketerInvitePermissionEditStateType, setMarketerInvitePermissionEditStateType] = useState(
        EditStateTypes.WAIT,
    )

    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    useEffect(() => {
        setStatusID(itemMarketer.marketerStatusType)
        setSuspendStatusID(itemMarketer.marketerSuspendStatusType)
        setInvitePermissionID(itemMarketer.isInvitePermission)
    }, [itemMarketer])

    const handleMarketerStatus = (value) => {
        if (marketerStatusEditStateType !== EditStateTypes.PENDING) {
            setMarketerStatusEditStateType(EditStateTypes.PENDING)
            updateItemMarketerStatus(itemMarketer.id, value)
                .then((response) => {
                    if (response) {
                        setStatusID(value)
                        setMarketerStatusEditStateType(EditStateTypes.SUCCESS)

                        if (value == MarketerStatusTypes.CONFIRM) {
                            // 알림톡 발송
                            let alertTalkDatas = [
                                {
                                    tel: String(itemMarketer.marketer.user.tel),
                                    variables: {
                                        "#{아이템명}": item.detail.title,
                                        "#{타입}": "승인",
                                    },
                                },
                            ]
                            sendAlertTalk(AlertTalkTemplateTypes.ITEM_APPLY_ACCEPT, alertTalkDatas)
                        }
                    } else {
                        setMarketerStatusEditStateType(EditStateTypes.FAILED)
                    }
                    updateWaitMarketers()
                })
                .catch((error) => {
                    setMarketerStatusEditStateType(EditStateTypes.FAILED)
                })
        } else {
            alert("로딩 중 입니다.")
        }
    }
    const handleMarketerSuspendStatus = (value) => {
        if (marketerSuspendStatusEditStateType !== EditStateTypes.PENDING) {
            setMarketerSuspendStatusEditStateType(EditStateTypes.PENDING)
            setSuspendStatusID(value)
            updateItemMarketerSuspendStatus(itemMarketer.id, value)
                .then((response) => {
                    if (response) {
                        setSuspendStatusID(value)
                        setMarketerSuspendStatusEditStateType(EditStateTypes.SUCCESS)
                    } else {
                        setMarketerSuspendStatusEditStateType(EditStateTypes.FAILED)
                    }
                })
                .catch((error) => {
                    setMarketerSuspendStatusEditStateType(EditStateTypes.FAILED)
                })
        } else {
            alert("로딩 중 입니다.")
        }
    }
    const handleMarketerInvitePermission = (value) => {
        if (marketerInvitePermissionEditStateType !== EditStateTypes.PENDING) {
            setMarketerInvitePermissionEditStateType(EditStateTypes.PENDING)
            setInvitePermissionID(value)
            updateItemMarketerInviteStatePermission(itemMarketer.id, value === "true")
                .then((response) => {
                    if (response) {
                        setInvitePermissionID(value)
                        setMarketerInvitePermissionEditStateType(EditStateTypes.SUCCESS)
                    } else {
                        setMarketerInvitePermissionEditStateType(EditStateTypes.FAILED)
                    }
                })
                .catch((error) => {
                    setMarketerInvitePermissionEditStateType(EditStateTypes.FAILED)
                })
        } else {
            alert("로딩 중 입니다.")
        }
    }
    const handeRowClick = () => {
        setUserId(itemMarketer.marketer.user.id)
        setUserShow(true)
    }

    return (
        <MarketerTableDataRow className={`${getRowMinWidth(isManage)}`}>
            {/* <MarketerTableData className={`${MarketerCellWidth.Index}`}>
                {itemMarketer.index}
            </MarketerTableData> */}
            <HoverUserCardContainer
                isAlt={idx % 2 != 0}
                setUserId={setUserId}
                itemMarketer={itemMarketer}
                handeRowClick={handeRowClick}
            >
                <MarketerTableData className={`${MarketerCellWidth.Name} px-2 justify-start`}>
                    <ItemMarketerNameChain nameChain={itemMarketer.marketerNameChain} minify={true} />
                </MarketerTableData>
            </HoverUserCardContainer>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>
                {itemMarketer.marketer.user.email}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>
                {itemMarketer.marketer.user.tel}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>
                {itemMarketer.marketer.detail.age}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Location}`}>
                {locationTypes[itemMarketer.marketer.detail.location1]},
                {locationTypes[itemMarketer.marketer.detail.location2]},
                {locationTypes[itemMarketer.marketer.detail.location3]}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Performs}`}>{itemMarketer.performs}</MarketerTableData>
            {isManage ? (
                <>
                    <MarketerTableData className={`${MarketerCellWidth.Status} px-1`}>
                        <MarketerCellButton
                            onClick={() => {
                                openGroupManager(itemMarketer)
                            }}
                        >
                            그룹 관리 <span className="ml-2 text-slate-600">{itemMarketer.invites}</span>
                        </MarketerCellButton>
                    </MarketerTableData>
                    <MarketerTableData className={`${MarketerCellWidth.Status}`}>
                        <MarketerStatSelect
                            value={marketerStatusID}
                            className={`bg-[${MarketerStatusTypeColors[marketerStatusID]}] text-white`}
                            onChange={(e) => {
                                handleMarketerStatus(e.target.value)
                            }}
                            editStateType={marketerStatusEditStateType}
                        >
                            <option value={MarketerStatusTypes.WAIT}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.WAIT]}
                            </option>
                            <option value={MarketerStatusTypes.CONFIRM}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.CONFIRM]}
                            </option>
                            <option value={MarketerStatusTypes.BLOCK}>
                                {MarketerStatusTypeNames[MarketerStatusTypes.BLOCK]}
                            </option>
                        </MarketerStatSelect>
                    </MarketerTableData>
                    <MarketerTableData className={`${MarketerCellWidth.Status}`}>
                        <MarketerStatSelect
                            value={marketerSuspendStatusID}
                            className={`bg-[${MarketerSuspendStatusTypeColors[marketerSuspendStatusID]}] text-white`}
                            onChange={(e) => {
                                handleMarketerSuspendStatus(e.target.value)
                            }}
                            editStateType={marketerSuspendStatusEditStateType}
                        >
                            <option value={MarketerSuspendStatusTypes.NONE}>
                                {MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.NONE]}
                            </option>
                            {/* <option value={MarketerSuspendStatusTypes.WAIT}>{MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.WAIT]}</option> */}
                            <option value={MarketerSuspendStatusTypes.CONFIRM}>
                                {MarketerSuspendStatusTypeNames[MarketerSuspendStatusTypes.CONFIRM]}
                            </option>
                        </MarketerStatSelect>
                    </MarketerTableData>
                    <MarketerTableData className={`${MarketerCellWidth.Status}`}>
                        <MarketerStatSelect
                            value={marketerInvitePermissionID}
                            className={`bg-[${MarketerInvitePermissionTypeColors[marketerInvitePermissionID]}] text-white`}
                            onChange={(e) => {
                                handleMarketerInvitePermission(e.target.value)
                            }}
                            editStateType={marketerInvitePermissionEditStateType}
                        >
                            <option value={MarketerInvitePermissionTypes.TRUE}>
                                {MarketerInvitePermissionTypeNames[MarketerInvitePermissionTypes.TRUE]}
                            </option>
                            <option value={MarketerInvitePermissionTypes.FALSE}>
                                {MarketerInvitePermissionTypeNames[MarketerInvitePermissionTypes.FALSE]}
                            </option>
                        </MarketerStatSelect>
                    </MarketerTableData>
                </>
            ) : (
                <MarketerTableData className={`${MarketerCellWidth.Invite} px-1`}>
                    <MarketerCellButton
                        onClick={() => {
                            openInvite(itemMarketer)
                        }}
                    >
                        하위 마케터 초대
                    </MarketerCellButton>
                </MarketerTableData>
            )}
        </MarketerTableDataRow>
    )
}

const Columns = ({ isManage }) => {
    return (
        <MarketerTableHeadRow className={`sticky top-0 z-10 ${getRowMinWidth(isManage)}`}>
            {/* <MarketerTableHead className={`${MarketerCellWidth.Index}`}>
                NO
            </MarketerTableHead> */}
            <MarketerTableHead className={`${MarketerCellWidth.Name}`}>마케터</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Email} z-10`}>이메일</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Tel} z-10`}>휴대전화</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Age} z-10`}>나이</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Location} z-10`}>활동 지역</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Performs} z-10`}>실적 수</MarketerTableHead>
            {isManage ? (
                <>
                    <MarketerTableHead className={`${MarketerCellWidth.Status} z-10`}>그룹 관리</MarketerTableHead>
                    <MarketerTableHead className={`${MarketerCellWidth.Status} z-10`}>승인 상태</MarketerTableHead>
                    <MarketerTableHead className={`${MarketerCellWidth.Status} z-10`}>정지 상태</MarketerTableHead>
                    <MarketerTableHead className={`${MarketerCellWidth.Status} z-10`}>초대 권한</MarketerTableHead>
                </>
            ) : (
                <MarketerTableHead className={`${MarketerCellWidth.Invite} z-10`}>하위 마케터 초대</MarketerTableHead>
            )}
        </MarketerTableHeadRow>
    )
}

export default function MyPageMarketerTable({
    isManage,
    item,
    marketers,
    openInvite,
    openGroupManager,
    updateWaitMarketers,
}) {
    const [locationTypes, setLocationTypes] = useState({})

    useEffect(() => {
        getTypes()
    }, [])

    const getTypes = () => {
        getRegionTypes().then((response) => {
            let regionTypes = { ...response }
            regionTypes[0] = "-"
            setLocationTypes(regionTypes)
        })
    }

    return (
        <MarketerTableCon>
            <Columns isManage={isManage} />
            {marketers.map((itemMarketer, idx) => (
                <MarketerTableRow
                    isManage={isManage}
                    item={item}
                    itemMarketer={itemMarketer}
                    locationTypes={locationTypes}
                    openInvite={openInvite}
                    openGroupManager={openGroupManager}
                    updateWaitMarketers={updateWaitMarketers}
                    idx={idx}
                    key={idx}
                />
            ))}
            {/* <MarketerTableBody>
            </MarketerTableBody> */}
        </MarketerTableCon>
    )
}
