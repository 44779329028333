import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { getUserSign, updateUserDetail } from "../../../apis/api/users"
import { MyPageUserInput, MyPageUserInputCombo } from "../../../components/user/Inputs"
import { getUserMarketerSignupRequestData } from "../../../services/users"
import UserMarketer from "../../../types/users/UserMarketer"
import { MarketerParticipantNames, MarketerParticipantTypes, UserTypes } from "../../../types/users/UserTypes"
import Utils from "../../../utils/Utils"
import {
    MyInfoDataContainer,
    MyInfoDataEditButton,
    MyInfoDataBox,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyPageDataGrid,
    MyPageDataControlBox,
    MyPageDataSubmitButton,
    MyInfoDataTitleContainer,
} from "./MyInfoMainStyle"
import { ItemDetailApplyBrandUser } from "./../../brandItems/ItemDetailStyle"
import { DrawingBoard } from "../../../components/brandItems/SignDrawingBoard"
import { updateUserSign } from "../../../apis/services/users"
import { useUserStore } from "../../../zustand/store"
import { updateSmarangSign } from "../../../apis/services/commons"
import { SignCanvas } from "../../../components/brandItems/SignCanvas"
import { getSmarangSign } from "../../../apis/api/commons"

const InformationContainer = tw.div`
    flex my-4
`

const PreviewImageContainer = tw.div`
    max-lg:hidden lg:block w-28 h-28 bg-slate-100 flex-shrink-0
    rounded-md border border-slate-300
    overflow-hidden
`

const PreviewImage = tw.img`
    w-full h-full bg-slate-100
`

const DrawingBoardContainer = tw.div`
    flex-grow h-full
`

export default function MyInfoSign() {
    const { user } = useUserStore()

    const [signImage, setSignImage] = useState(null)
    const signCanvasRef = useRef(null)

    useEffect(() => {
        if (user.userType === UserTypes.BRAND) {
            getUserSign().then((response) => {
                if (response.id >= 0) {
                    setSignImage(response.base64)
                }
            })
        }
        if (user.userType === UserTypes.STAFF) {
            getSmarangSign().then((response) => {
                if (response.id >= 0) {
                    setSignImage(response.base64)
                }
            })
        }
    }, [user])

    const handleRegist = async (signImage) => {
        if (user.userType === UserTypes.BRAND) {
            updateUserSign(signImage).then((response) => {
                if (response.id >= 0) {
                    alert("저장되었습니다.")
                    setSignImage(response.base64)
                } else {
                    alert("저장 실패했습니다.")
                }
            })
        } else if (user.userType === UserTypes.STAFF) {
            updateSmarangSign(signImage).then((response) => {
                if (response.id >= 0) {
                    alert("저장되었습니다.")
                    setSignImage(response.base64)
                } else {
                    alert("저장 실패했습니다.")
                }
            })
        }
    }

    return (
        <MyInfoDataContainer>
            <MyInfoDataTitleContainer>
                <MyInfoDataTitle>대표자 서명</MyInfoDataTitle>
            </MyInfoDataTitleContainer>
            <MyInfoDataBox>
                <InformationContainer>
                    <PreviewImageContainer>
                        {!Utils.isStringNullOrEmpty(signImage) && signImage !== "data:image;base64," && (
                            <PreviewImage src={signImage} alt={null} />
                        )}
                    </PreviewImageContainer>
                    <DrawingBoardContainer>
                        {/* <DrawingBoard regist={handleRegist} signImage={signImage} setSignImage={setSignImage} /> */}
                        <SignCanvas
                            ref={signCanvasRef}
                            submit={handleRegist}
                            signImage={signImage}
                            setImage={setSignImage}
                            hasOldSign={!Utils.isStringNullOrEmpty(signImage)}
                        />
                    </DrawingBoardContainer>
                </InformationContainer>
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
