import create from "zustand" // create로 zustand를 불러옵니다.
import { getUserDetail } from "../apis/api/users"
import { getUserTypeByRefreshToken } from "../services/users"
import User from "../types/users/User"
import { UserTypes } from "../types/users/UserTypes"
import { getItemMarketerInviteds, getItemMarketers } from "../apis/api/boardItems"
import { MarketerStatusTypes } from "../types/brandItems/BrandItemTypes"
import { loginService, logoutService } from "../apis/services/users"

export const useStore = create((set) => ({
    link: "localhost",
    local_link: () => set((state) => ({ link: "localhost" })),
    server_link: () => set((state) => ({ link: "192." })),
}))

//모바일 푸터 state 확인

export const useMobile_Footer_link = create((set) => ({
    link_state: "home",
    set_home: () => set((state) => ({ link_state: "home" })),
    set_item: () => set((state) => ({ link_state: "item" })),
    set_mypage: () => set((state) => ({ link_state: "mypage" })),
    set_notice: () => set((state) => ({ link_state: "notice" })),
    set_none: () => set((state) => ({ link_state: "" })),
}))

const initialUser = {
    accessToken: "",
    email: "",
    name: "",
    userType: getUserTypeByRefreshToken(),
    user: new User(),
    userDetail: null,
    groupInviteCount: 0,
}
const noneUser = {
    accessToken: "",
    email: "",
    name: "",
    userType: null,
    user: new User(),
    userDetail: null,
    marketerWaitCount: 0, // 브랜드 기준: 마케터 승인 요청받은 수
    groupInviteCount: 0, // 마케터 기준: 그룹 초대받은 수
}
export const useUserStore = create((set) => ({
    ...initialUser,
    actions: {
        setAccessToken: (accessToken) => set(() => ({ accessToken: accessToken })),
        setEmail: (email) => set(() => ({ email: email })),
        setName: (name) => set(() => ({ name: name })),
        setUserType: (userType) => {
            loginService()
            set(() => ({ userType: userType }))
        },
        setUser: (user) => {
            loginService()
            set(() => ({ user: user }))
            if (user.id >= 0) {
                getUserDetail(user.id).then((response) => {
                    set(() => ({ userDetail: response }))
                })
            }
        },
        updateUserDetail: (user) => {
            if (user.id >= 0) {
                getUserDetail(user.id).then((response) => {
                    set(() => response)
                })
            }
        },
        setLogout: () => {
            logoutService()
            set(() => ({ ...noneUser }))
        },
        setMarketerWaitCount: (marketerWaitCount) => set(() => ({ marketerWaitCount: marketerWaitCount })),
        updateMarketerWaitCount: async (itemID) => {
            const pagination = await getItemMarketers(itemID, 1, 1, "", "", MarketerStatusTypes.WAIT)
            return set(() => ({ marketerWaitCount: pagination.count }))
        },
        setGroupInviteCount: (groupInviteCount) => set(() => ({ groupInviteCount: groupInviteCount })),
        updateGroupInviteCount: async (itemID, userID) => {
            const pagination = await getItemMarketerInviteds(itemID, userID, MarketerStatusTypes.WAIT, 1, 1, "")
            return set(() => ({ groupInviteCount: pagination.count }))
        },
    },
}))
