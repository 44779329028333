import React, { useState, useEffect } from "react"
import MyInfoMarketer from "../../pages/myPage/myinfo/MyInfoMarketer"
import {
    deleteUserProfileAvatar,
    getUserDetail as getUserDetailApi,
    updateUserProfileAvatar,
} from "../../apis/api/users"
import { useUserStore } from "../../zustand/store"
import tw from "tailwind-styled-components"
import MyInfoMarketerCareer from "../../pages/myPage/myinfo/MyInfoMarketerCareer"
import { useNavigate } from "react-router-dom"
import { marketerApplyModal } from "../../zustand/modalstore"

export const MarketerDetailContainer = tw.div`
    flex flex-col max-sm:w-screen max-md:h-full sm:w-[36rem] max-sm:p-2 sm:p-4
    max-md:rounded-none md:rounded-md bg-gray-100 
    overflow-scroll scroll-transparent scroll-overlay
`
export const ApplyWarnningMessage = tw.span`
    text-gray-900 mt-2 px-2 pr-6
`

export const MarketerDetailTitle = tw.span`
    text-nightblue-700 text-lg font-semibold
`

export const MarkterDetailButtonBox = tw.div`
    w-full h-14 flex items-center justify-end px-4
`

export const MarkterDetailButton = tw.button`
    bg-nightblue-400 text-white px-6 py-2 rounded-md
`

export const MarketerDetailInputBox = tw.div`
    flex flex-col w-full p-2 pt-0
`
