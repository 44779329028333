import React, { useEffect, useState } from "react"
import MyPageGroupMarketerInvite from "./MyPageGroupMarketerInvite"

export default function MyPageGroupManagerTree({ itemID, parentItemMarketer, getCurrentMarketers }) {
    return (
        <MyPageGroupMarketerInvite
            itemID={itemID}
            parentItemMarketer={parentItemMarketer}
            getCurrentMarketers={getCurrentMarketers}
        />
    )
}
