import React,{useEffect,useState,useRef,useMemo} from "react";
import tw from 'tailwind-styled-components'
import { PerformColumnSubControlBox, PerformColumnSubDeleteButton, PerformColumnSubSaveButton, StaffPerformUpdateOptionContainer, StaffPerformUpdateStatusBox, StaffPerformUpdateStatusContainer, StaffPerformUpdateStatusControlBox, StaffPerformUpdateStatusInputBox, StaffPerformUpdateStatusList, StaffPerformUpdateStatusMainBox, } from "./StaffPerformStyle";
import { MyPageDataSubmitButton } from "../myinfo/MyInfoMainStyle";
import Utils from "../../../utils/Utils";
import { QueryTypes } from "../../../types/EditorTypes";
import { PerformValueInputTitle } from "./MyPagePerformStyle";
import { MyPageUserInput } from "../../../components/user/Inputs";
import PerformColumnCombo from "../../../types/brandItems/performColumns/PerformColumnCombo";

const Combo = ({column, combo, columnSubEdit}) => {
    const [name, setName] = useState("")
    const [isMultiSelect, setIsMultiSelect] = useState(false)
    const [hasEtc, setHasEtc] = useState(false)

    useEffect(()=>{
        setName(combo.name)
        setIsMultiSelect(combo.isMultiSelect)
        setHasEtc(combo.hasEtc)
    }, [combo])

    const handleSaveStatus = (combo) => {
        if (confirm(`${name} 저장하시겠습니까?`)) {
            let _combo = new PerformColumnCombo()

            _combo.id = combo.id
            _combo.columnID = combo.columnID
            _combo.name = name
            columnSubEdit(_combo, QueryTypes.CREATE)
        }
    }
    const handleDeleteStatus = (combo) => {
        if (confirm(`${name} 삭제하시겠습니까?`)) {
            columnSubEdit(combo, QueryTypes.DELETE)
        }
    }

    return (
        <StaffPerformUpdateStatusBox>
            <StaffPerformUpdateStatusMainBox>
                <StaffPerformUpdateStatusInputBox className="w-full">
                    <MyPageUserInput
                        label={"이름"}
                        type={"text"}
                        data={name}
                        setData={setName}
                    />
                </StaffPerformUpdateStatusInputBox>
                <PerformColumnSubControlBox>
                    <PerformColumnSubDeleteButton onClick={()=>{handleDeleteStatus(combo)}}>
                        삭제
                    </PerformColumnSubDeleteButton>
                    <PerformColumnSubSaveButton onClick={()=>{handleSaveStatus(combo)}}>
                        저장
                    </PerformColumnSubSaveButton>
                    {/* <PerformColumnSubConstraintButton onClick={()=>{setShowConstraints(!showConstraints)}}>
                        제약 조건
                    </PerformColumnSubConstraintButton> */}
                </PerformColumnSubControlBox>
            </StaffPerformUpdateStatusMainBox>
        </StaffPerformUpdateStatusBox>
    )
}

export default function StaffPerformUpdateCombo({selectedColumn, columnSubEdit}){
    const handleAddStatus = () => {
        const comboName = prompt("추가할 항목 이름을 입력해주세요.")
        if (Utils.isStringNullOrEmpty(comboName)) {
            return
        }

        if (selectedColumn.id >= 0) {
            let newCombo = new PerformColumnCombo()
            newCombo.columnID = selectedColumn.id
            newCombo.name = comboName
            columnSubEdit(newCombo, QueryTypes.CREATE)
        } else {
            alert("추가할 수 없습니다.")
        }
    }
    
    return(
        <StaffPerformUpdateOptionContainer>
            <PerformValueInputTitle>
                <span>{selectedColumn.name} <span className="text-gray-600">콤보 항목 관리</span></span>
            </PerformValueInputTitle>
            <StaffPerformUpdateStatusControlBox>
                <MyPageDataSubmitButton onClick={()=>{handleAddStatus()}}>
                    추가
                </MyPageDataSubmitButton>
            </StaffPerformUpdateStatusControlBox>

            <StaffPerformUpdateStatusList>
                {selectedColumn.combos.map((combo, idx) => (
                    <Combo column={selectedColumn} combo={combo} columnSubEdit={columnSubEdit} key={idx} />
                ))}
            </StaffPerformUpdateStatusList>
        </StaffPerformUpdateOptionContainer>
    )
}