import tw from "tailwind-styled-components"
import styled from "styled-components"
import { ListUl } from "@styled-icons/bootstrap/ListUl"
import { BoxMaxMin } from "../../layouts/Box"

export const BoardDetailContainer = tw.div`
flex justify-center w-full h-auto
`

export const BoardDetailLayout = tw(BoxMaxMin)`
flex flex-col items-center w-full h-full max-md:p-0 md:p-4 lg:p-10 min-h-[480px]
${(props) => props.isModal && "max-h-[90vh]"}
bg-white
`

export const BoardDetailHead = tw.div`
    w-full border-b border-gray-300 px-4
    flex flex-col
`

export const ReturnIcon = tw(ListUl)`
    h-9
`

export const BoardDetailTitle = tw.span`
    max-md:text-xl md:text-2xl max-md:my-2
    text-semibold
`
export const BoardDetailInfoRow = tw.div`
    flex max-sm:flex-col sm:flex-row sm:justify-between sm:items-center py-1 text-sm
`
export const BoardDetailInfos = tw.div`
    flex place-items-center
`
export const BoardDetailInfoBox = tw.div`
    flex items-center mr-2
`
export const BoardDetailInfoLabel = tw.span`
    flex items-center mr-1
    font-medium
    text-gray-600
`
export const BoardDetailInfoValue = tw.span`
    flex items-center w-24
    text-gray-800
`

export const ReturnButton = tw.button`
    w-12 h-12 hover:bg-gray-100 rounded-full duration-300
`

export const BoardDetailTitlebottom = tw.div`
    w-full h-14 flex justify-between items-center text-lg
`

export const BoardDetailBottom = tw.div`
    w-full h-24 flex justify-between items-center
`
export const BoardControlButton = tw.button`
    flex flex-center px-3 py-0.25 ml-2
    rounded-md max-md:ring-1 md:hover:ring-1 max-md:ring-gray-500 md:ring-black duration-200
`
export const NoticeEditButton = tw.button`
    bg-blue-400 text-blue-50 px-5 py-1 rounded-md hover:bg-blue-300
`

export const NoticeDeleteButton = tw.button`
    bg-red-400 text-red-50 px-5 py-1 rounded-md hover:bg-red-300
`

export const BoardDetailBody = tw.div`
    w-full h-full max-md:px-4 md:px-4 max-md:py-2 md:py-4
    scroll-transparent scroll-overlay
`

export const BoardFileBody = tw.div`
    flex flex-col w-full pt-8 pb-4 max-md:px-2 mt-auto
`
export const BoardFileHead = tw.div`
    flex justify-between items-center w-full mb-2
`
export const BoardFileZipDownload = tw.button`
    px-3 py-1 text-sm
    text-blue-600
    rounded-full bg-blue-50 hover:bg-blue-100
`
export const BoardFileTitle = tw.span`
    max-md:text-base md:text-lg font-semibold pl-1
`
export const BoardFileBox = tw.div`
    flex w-full px-2 py-1
    rounded-sm hover:bg-slate-100
    cursor-pointer
    max-md:text-sm
`
export const BoardFileName = tw.span`
    w-full text-gray-600 hover:text-gray-800 truncate
`
export const BoardFileSize = tw.span`
    flex-shrink-0 text-gray-600 text-sm
`

export const BoardImportanceLabel = tw.span`
    px-2 mr-2 flex-shrink-0
    text-red-500 max-md:text-xs md:text-sm
    rounded-md border border-red-500
`
