import Paginator from "../../Paginator"
import Perform from "./Perform"

export default class extends Paginator {
    constructor(count = 0) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.performs = []
        const resultResponse = response["results"]
        if (resultResponse !== undefined) {
            response["results"].map((result) => {
                const perform = new Perform()
                perform.convertByResponse(result)
                this.performs.push(perform)
            })
        }
    }
}
