import PerformColumnCombo from "./PerformColumnCombo";
import PerformColumnFile from "./PerformColumnFile";
import PerformColumnStatus from "./PerformColumnStatus";
import PerformColumnText from "./PerformColumnText";
import PerformColumnOption from "./PerformColumnOption";
import PerformColumnDate from "./PerformColumnDate";
import { PerformColumnFormTypes, PerformColumnTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.itemID = -1
        this.type = PerformColumnTypes.DYNAMIC
        this.formType = PerformColumnFormTypes.NONE
        this.name = ""
        this.width = "1*"
        this.brandReadOnly = false
        this.marketerReadOnly = false
        this.isBaseAdd = false // 실적 추가 모달 포함 여부
        this.isBaseEdit = false // 실적 수정 모달 퐇마 여부
        this.isFilterShortcut = false // 실적 필터 숏컷 여부
        this.isExcelShow = false
        this.isEditableConfirmed = false // 실적 필터 숏컷 여부
        this.combos = []
        this.files = []
        this.texts = []
        this.statuses = []
        this.options = []
        this.dates = []

        // UI에 사용
        this.widthCalculated = 0
        this.columnLeft = 0
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.itemID = response["item_id"]
        this.formType = response["form_type"]
        this.name = response["name"]
        this.width = response["width"]
        this.brandReadOnly = response["brand_read_only"]
        this.marketerReadOnly = response["marketer_read_only"]
        this.isBaseAdd = response["is_base_add"]
        this.isBaseEdit = response["is_base_edit"]
        this.isFilterShortcut = response["is_filter_shortcut"]
        this.isExcelShow = response["is_excel_show"]
        this.isEditableConfirmed = response["is_editable_confirmed"]

        const comboResponse = response["perform_column_combo"]
        if (comboResponse !== null) {
            comboResponse.map(c => {
                const combo = new PerformColumnCombo()
                combo.convertByResponse(c)
                this.combos.push(combo)
            })
        }
        const fileResponse = response["perform_column_file"]
        if (fileResponse !== null) {
            fileResponse.map(f => {
                const file = new PerformColumnFile()
                file.convertByResponse(f)
                this.files.push(file)
            })
        }
        const textResponse = response["perform_column_text"]
        if (textResponse !== null) {
            textResponse.map(t => {
                const text = new PerformColumnText()
                text.convertByResponse(t)
                this.texts.push(text)
            })
        }
        const statusResponse = response["perform_column_status"]
        if (statusResponse !== null) {
            statusResponse.map(s => {
                const status = new PerformColumnStatus()
                status.convertByResponse(s)
                this.statuses.push(status)
            })
        }
        const optionResponse = response["perform_column_option"]
        if (optionResponse !== null) {
            optionResponse.map(s => {
                const option = new PerformColumnOption()
                option.convertByResponse(s)
                this.options.push(option)
            })
        }
        const dateResponse = response["perform_column_date"]
        if (dateResponse !== null) {
            dateResponse.map(s => {
                const date = new PerformColumnDate()
                date.convertByResponse(s)
                this.dates.push(date)
            })
        }
    }

    /* 컬럼 정보 업데이트에 사용 */
    convertToRequest() {
        let request = {
            id: -1,
            form_type: 0,
            name: "",
            width: "",
            brand_read_only: false,
            marketer_read_only: false,
            is_base_add: false,
            is_base_edit: false,
            is_filter_shortcut: false,
            is_excel_show: false,
            is_editable_confirmed: false
        }

        request.id = this.id
        request.form_type = this.formType
        request.name = this.name
        request.width = this.width
        request.brand_read_only = this.brandReadOnly
        request.marketer_read_only = this.marketerReadOnly
        request.is_base_add = this.isBaseAdd
        request.is_base_edit = this.isBaseEdit
        request.is_filter_shortcut = this.isFilterShortcut
        request.is_excel_show = this.isExcelShow
        request.is_editable_confirmed = this.isEditableConfirmed
        
        return request
    }
}