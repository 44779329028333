import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { updateItemPerformValue } from "../../../apis/api/boardItems"
import { ModalContainer } from "../../../components/ModalContainer"
import AccessRules from "../../../rules/AccessRules"
import {
    PerformColumnPreviewTypes,
    PerformStaticColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { EditStateTypes } from "../../../types/EditorTypes"
import Utils from "../../../utils/Utils"
import { SaveButton } from "../item/brand/ItemInputFormStyle"
import {
    PerformPreviewButton,
    PerformTextBox,
    PerformTextCalculateViewButton,
    PerformTextDetailButton,
    PerformTextDetailIcon,
    PerformValueBox,
    PerformValueInputLayout,
    PerformValueInputTitle,
} from "./MyPagePerformStyle"
import { HoverUserCard } from "../../../components/HoverComponants"

const TextEdit = ({ column, perform, text, closeEditComponent }) => {
    const [textValue, setTextValue] = useState(text)
    const handleSave = () => {
        updateItemPerformValue(column.id, perform.id, { text: textValue }).then((response) => {
            if (response) {
                closeEditComponent(true)
            } else {
                alert("수정 실패했습니다.")
            }
        })
    }

    return (
        <PerformValueInputLayout className={"max-sm:w-screen sm:w-[40rem]"}>
            <PerformValueInputTitle>
                <span>{column.name}</span>
            </PerformValueInputTitle>
            <textarea
                className="w-full h-full p-2 rounded-lg border focus:outline-none"
                value={textValue}
                onChange={(e) => {
                    setTextValue(e.target.value)
                }}
                readOnly={!AccessRules.performValueWrite(column, perform)}
            />
            {AccessRules.performValueWrite(column, perform) && (
                <div className="flex justify-end p-2">
                    <SaveButton
                        onClick={() => {
                            handleSave()
                        }}
                    >
                        저장
                    </SaveButton>
                </div>
            )}
        </PerformValueInputLayout>
    )
}

export const PerformValueTextCell = ({ column, perform, value, setEditComponent }) => {
    const [previewType, setPreviewType] = useState(
        column.texts.length > 0 ? column.texts[0].previewType : PerformColumnPreviewTypes.NONE,
    )

    const [showEditComponent, closeEditComponent] = setEditComponent

    const handleShowEdit = () => {
        showEditComponent(
            <TextEdit column={column} perform={perform} text={value.text} closeEditComponent={closeEditComponent} />,
        )
    }

    return (
        <PerformValueBox>
            {previewType === PerformColumnPreviewTypes.TRUNCATE && (
                <div className="flex justify-between items-center w-full group">
                    <PerformTextBox>
                        <span className="px-1 truncate">
                            {Utils.isStringNullOrEmpty(value.text) ? "-" : value.text}
                        </span>
                    </PerformTextBox>

                    {value.hasDetail && (
                        <PerformTextDetailButton
                            onClick={() => {
                                handleShowEdit()
                            }}
                        >
                            <PerformTextDetailIcon />
                        </PerformTextDetailButton>
                    )}
                </div>
            )}
            {previewType === PerformColumnPreviewTypes.BUTTON && value.hasDetail && (
                <PerformPreviewButton
                    onClick={() => {
                        handleShowEdit()
                    }}
                >
                    보기
                </PerformPreviewButton>
            )}
        </PerformValueBox>
    )
}
