import React, { useState, useEffect } from "react"
import {
    LoginButton,
    SignupButton,
    LoginCol,
    LoginTitle,
    LoginOptionCol,
    LoginOptionItem,
    LoginOptionSpan,
    LoginRegistCol,
    LoginRegistSpan,
    LoginInputCol,
    LoginRegistBox,
    LoginContainer,
    LoginFindPassword,
    LoginFindPasswordCol,
    FindPasswordControlBox,
    FindPasswordGotoLoginButton,
    FindPasswordUserCheckButton,
    FindPasswordSetPasswordButton,
} from "./LoginStyle"
import { Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import ConstCookie from "../../../constants/ConstCookie"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { getUserByLogin } from "../../../services/users"
import { UserInput, UserSMSAuthRequestButton, UserSMSAuthSubmitButton } from "../Inputs"
import { LoginComponentTypes } from "./LoginMain"
import { EditStateTypes } from "../../../types/EditorTypes"
import { PasswordValidate } from "../PasswordValidate"
import { useFocus } from "../../../hooks/useFocus"
import {
    checkTelBySetPassword,
    findEmails,
    sendAuthSMS,
    sendAuthSubmitSMS,
    setPasswordByFindPassword,
} from "../../../apis/api/users"

const FindEmail = ({ setLoginComponentType }) => {
    const [name, setName] = useState("")
    const [tel, setTel] = useState("")

    const [telSubmitCode, setTelSubmitCode] = useState("")
    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [telSubmitState, setTelSubmitState] = useState(EditStateTypes.DISABLED)
    const [telSubmitTime, setTelSubmitTime] = useState(0)
    const [telSubmitTimeText, setTelSubmitTimeText] = useState("")

    const [isUserCheck, setUserCheck] = useState(false)
    const [emails, setEmails] = useState([])

    useEffect(() => {
        if (telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.WAIT) {
            setTelSubmitState(EditStateTypes.DISABLED)
            setTelSubmitCode("")
        }
        if (telAuthState === EditStateTypes.PENDING) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
    }, [telAuthState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelSubmitState(EditStateTypes.DISABLED)
        }

        let newTel = tel
        newTel = tel.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
        if (newTel.length > 12) {
            newTel = newTel.slice(0, 12)
        }
        setTel(newTel)
    }, [tel])

    useEffect(() => {
        if (telSubmitState !== EditStateTypes.DISABLED) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
        if (telSubmitCode.length > 20) {
            setTelSubmitCode(telSubmitCode.slice(0, 20))
        }
    }, [telSubmitCode])

    useEffect(() => {
        const time = setInterval(() => {
            const second = telSubmitTime - 1
            setTelSubmitTimeText(`${Math.floor(second / 60)} : ${second % 60}`)
            setTelSubmitTime(second)
        }, 1000)

        if (telSubmitTime === 0 || telAuthState !== EditStateTypes.PENDING) {
            clearInterval(time)
        }

        return () => clearInterval(time)
    }, [telSubmitTime])

    const telAuth = async () => {
        if (
            !Utils.isStringNullOrEmpty(tel) &&
            (telAuthState === EditStateTypes.WAIT || telAuthState === EditStateTypes.PENDING)
        ) {
            const requestData = { phone_number: tel }

            // 전화번호로 SMS 전송
            await sendAuthSMS(requestData)

            setTelAuthState(EditStateTypes.PENDING)
            setTelSubmitState(EditStateTypes.WAIT)
            setTelSubmitTime(60 * 5)
        }
        if (telAuthState === EditStateTypes.DONE) {
            setTelAuthState(EditStateTypes.WAIT)
        }
    }

    const telSubmit = async () => {
        if (!Utils.isStringNullOrEmpty(telSubmitCode)) {
            const requestData = {
                phone_number: tel,
                auth_number: telSubmitCode,
            }

            // 전화번호로 SMS 전송
            const response = await sendAuthSubmitSMS(requestData)
            const smsResult = response.result

            if (smsResult) {
                setTelAuthState(EditStateTypes.DONE)
                setTelSubmitState(EditStateTypes.DONE)
            } else {
                setTelAuthState(EditStateTypes.PENDING)
                setTelSubmitState(EditStateTypes.FAILED)
            }
        }
    }

    const handleGotoLogin = () => {
        setLoginComponentType(LoginComponentTypes.EMAIL_LOGIN)
    }

    const handleCheckUser = async () => {
        if (Utils.isStringNullOrEmpty(name) || Utils.isStringNullOrEmpty(tel)) {
            alert("입력 정보를 확인 해주세요")
            return
        }

        if (telAuthState !== EditStateTypes.DONE) {
            alert("전화번호를 인증해주세요")
            return
        }

        const emails = await findEmails(name, tel)

        if (!Utils.isStringNullOrEmpty(emails)) {
            setEmails(emails)
            setUserCheck(true)
        } else {
            alert("유저를 찾을 수 없습니다.")
        }
    }

    return (
        <LoginContainer>
            <LoginFindPasswordCol>
                <LoginTitle>이메일 찾기</LoginTitle>
                <LoginInputCol>
                    {!isUserCheck ? (
                        <React.Fragment>
                            <UserInput placeholder={"이름"} type={"string"} data={name} setData={setName} />
                            <UserInput
                                placeholder={"전화번호"}
                                type={"string"}
                                data={tel}
                                setData={setTel}
                                disabled={
                                    telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE
                                }
                            >
                                <UserSMSAuthRequestButton telAuthState={telAuthState} telAuth={telAuth} />
                            </UserInput>
                            <UserInput
                                placeholder={"전화번호 인증"}
                                type={"string"}
                                data={telSubmitCode}
                                setData={setTelSubmitCode}
                                disabled={
                                    telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE
                                }
                            >
                                <UserSMSAuthSubmitButton
                                    telAuthState={telAuthState}
                                    telSubmitState={telSubmitState}
                                    telSubmitTimeText={telSubmitTimeText}
                                    telSubmit={telSubmit}
                                />
                            </UserInput>
                        </React.Fragment>
                    ) : (
                        <EmailResult emails={emails} />
                    )}

                    <FindPasswordControlBox>
                        <FindPasswordGotoLoginButton
                            onClick={() => {
                                handleGotoLogin()
                            }}
                        >
                            돌아가기
                        </FindPasswordGotoLoginButton>
                        {!isUserCheck && (
                            <FindPasswordUserCheckButton
                                onClick={() => {
                                    handleCheckUser()
                                }}
                            >
                                찾기
                            </FindPasswordUserCheckButton>
                        )}
                    </FindPasswordControlBox>
                </LoginInputCol>
            </LoginFindPasswordCol>
        </LoginContainer>
    )
}

const EmailResult = ({ emails }) => {
    return (
        <div className="flex flex-col w-64 max-h-[14rem] p-4 overflow-scroll scroll-transparent scroll-overlay mt-4 rounded-lg bg-gray-200">
            {emails.map((email, idx) => (
                <span className={`font-semibold text-lg ${idx > 0 && "mt-2"}`} key={idx}>
                    {email}
                </span>
            ))}
        </div>
    )
}

export default FindEmail
