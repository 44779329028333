import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { PerformItemCombo } from "../../../components/brandItems/PerformInputs"
import { EditStateTypes } from "../../../types/EditorTypes"
import { PerformTextBox, PerformUpdateValueBox } from "./MyPagePerformStyle"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import Utils from "../../../utils/Utils"
import AccessRules from "../../../rules/AccessRules"

export const PerformValueComboUpdate = ({ perform, performValue, readOnly }) => {
    const [column, setColumn] = useState(new PerformColumn())
    const [name, setName] = useState(column.combos.length > 0 ? column.combos[0].name : "")

    const [comboID, setComboID] = useState(0)

    useEffect(() => {
        const combo = column.combos.find((s) => s.id === comboID)
        if (combo !== undefined && !Utils.isStringNullOrEmpty(combo.name)) {
            setName(combo.name)
        }
    }, [comboID])

    useEffect(() => {
        setColumn(performValue.column)
        setComboID(performValue.comboID)
    }, [performValue])

    const handleChange = (_comboID) => {
        setComboID(_comboID)
        performValue.comboID = Number(_comboID)
    }

    const valueReadOnly = readOnly
    // const valueReadOnly = (readOnly || !AccessRules.performValueWrite(column, perform))

    return (
        <PerformUpdateValueBox>
            {valueReadOnly ? (
                <PerformTextBox className="justify-start">
                    <span className="w-full p-2 rounded-lg bg-gray-100 truncate border">
                        {Utils.isStringNullOrEmpty(name) ? "-" : name}
                    </span>
                </PerformTextBox>
            ) : (
                <PerformItemCombo
                    optionKeys={column.combos.map((combo) => combo.id)}
                    names={column.combos.map((combo) => combo.name)}
                    value={comboID}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    color="white"
                />
            )}
        </PerformUpdateValueBox>
    )
}
