import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { updateItemPerformValue } from "../../../apis/api/boardItems"
import { ModalContainer } from "../../../components/ModalContainer"

import {
    PerformColumnPreviewTypes,
    PerformStaticColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { EditStateTypes, QueryTypes } from "../../../types/EditorTypes"
import Utils from "../../../utils/Utils"
import { SaveButton } from "../item/brand/ItemInputFormStyle"
import {
    HistoryIcon,
    PerformDateCalc,
    PerformDateCalcContainer,
    PerformHistoryContainer,
    PerformPreviewButton,
    PerformTextBox,
    PerformTextCalculateViewButton,
    PerformTextDetailButton,
    PerformTextDetailIcon,
    PerformValueBox,
    PerformValueInputLayout,
    PerformValueInputTitle,
} from "./MyPagePerformStyle"
import {
    OptionControlBox,
    OptionControlResetButton,
    OptionIconContainer,
    OptionIconRadioCheck,
    OptionIconRadioUncheck,
    OptionIconSelectCheck,
    OptionIconSelectUncheck,
    OptionItemBox,
    OptionItemList,
    OptionItemName,
    OptionItemTextInput,
    OptionItemTextInputBox,
    PerformOptionInputLayout,
} from "./PerformValueOptionStyle"
import { Dictionary } from "../../../types/Dictionary"
import PerformValueOptionItem from "../../../types/brandItems/performValues/PerformValueOptionItem"
import AccessRules from "../../../rules/AccessRules"

export const OptionItem = ({ option, optionItemDic, updateOptionDic, multiSelect = false, readOnly = false }) => {
    const [isSelected, setSelected] = useState(false)
    const [etc, setEtc] = useState("")

    useEffect(() => {
        if (optionItemDic.contains(option.id)) {
            setSelected(true)
            const optionItem = optionItemDic.getValue(option.id)
            setEtc(optionItem.etc)
        } else {
            setSelected(false)
            setEtc("")
        }
    }, [optionItemDic])

    const handleClick = () => {
        if (readOnly) {
            return
        }

        let optionItem = new PerformValueOptionItem()
        optionItem.convertByOption(option, option.id, etc)

        if (isSelected) {
            // 체크 해제
            updateOptionDic(optionItem, QueryTypes.DELETE)
        } else {
            // 체크
            updateOptionDic(optionItem, QueryTypes.CREATE_OR_UPDATE)
        }
    }

    const handleEtc = (value) => {
        let optionItem = new PerformValueOptionItem()
        optionItem.convertByOption(option, option.id, value)

        // etc에 아무 내용도 없거나 내용이 같으면 업데이트하지않는다.
        if (optionItemDic.contains(option.id)) {
            const beforeItem = optionItemDic.getValue(option.id)

            if (beforeItem.etc === optionItem.etc) {
                return
            }
        } else {
            if (Utils.isStringNullOrEmpty(optionItem.etc)) {
                return
            }
        }

        // if (!isSelected) {
        //     // 체크 해제
        //     updateOptionDic(optionItem, QueryTypes.DELETE)
        // } else {
        //     // 체크
        // }
        updateOptionDic(optionItem, QueryTypes.CREATE_OR_UPDATE)
    }

    return (
        <OptionItemBox readOnly={readOnly}>
            <OptionIconContainer
                onClick={() => {
                    handleClick()
                }}
            >
                {!option.isMultiSelect && !multiSelect ? (
                    isSelected ? (
                        <OptionIconRadioCheck />
                    ) : (
                        <OptionIconRadioUncheck />
                    )
                ) : isSelected ? (
                    <OptionIconSelectCheck />
                ) : (
                    <OptionIconSelectUncheck />
                )}
            </OptionIconContainer>
            <OptionItemName
                onClick={() => {
                    handleClick()
                }}
            >
                {option.name}
            </OptionItemName>
            {option.hasEtc && (
                <OptionItemTextInputBox>
                    <OptionItemTextInput
                        type="text"
                        placeholder={!readOnly && "직접 입력"}
                        value={etc}
                        onChange={(e) => {
                            setEtc(e.target.value)
                        }}
                        onBlur={(e) => {
                            handleEtc(e.target.value)
                        }}
                        disabled={readOnly}
                    />
                </OptionItemTextInputBox>
            )}
        </OptionItemBox>
    )
}

const OptionEdit = ({ column, perform, value, closeEditComponent }) => {
    const [optionItemDic, setOptionItemDic] = useState(new Dictionary()) // 체크된 항목 관리
    const [radioOptionDic, setRadioOptionDic] = useState(new Dictionary()) // 라디오 버튼인 항목은 하나만 체크되도록 관리

    useEffect(() => {
        let _radioOptionDic = new Dictionary()

        column.options.map((option) => {
            if (!option.isMultiSelect) {
                _radioOptionDic.push(option.id, option.id)
            }
        })

        setRadioOptionDic(_radioOptionDic)
    }, [column])

    useEffect(() => {
        let _optionItemDic = new Dictionary()

        value.optionItems.map((optionItem) => {
            _optionItemDic.push(optionItem.optionID, optionItem)
        })

        setOptionItemDic(_optionItemDic)
    }, [value])

    const updateOptionDic = (optionItem, queryType) => {
        let _optionItemDic = optionItemDic.copy()

        if (queryType === QueryTypes.CREATE_OR_UPDATE) {
            // 라디오 버튼인 경우 이미 체크된 다른 라디오버튼을 해제한다.
            if (radioOptionDic.contains(optionItem.optionID)) {
                _optionItemDic.getKeys().map((optionID) => {
                    if (radioOptionDic.contains(optionID)) {
                        _optionItemDic.delete(optionID)
                    }
                })
            }

            _optionItemDic.push(optionItem.optionID, optionItem)
        } else if (queryType === QueryTypes.DELETE) {
            _optionItemDic.delete(optionItem.optionID)
        }

        setOptionItemDic(_optionItemDic)
    }

    const handleReset = () => {
        setOptionItemDic(new Dictionary())
    }

    const handleSave = () => {
        updateItemPerformValue(column.id, perform.id, { option_json: JSON.stringify(optionItemDic.data) }).then(
            (response) => {
                if (response) {
                    if (!Utils.isNullOrUndefined(closeEditComponent)) {
                        closeEditComponent(true)
                    }
                } else {
                    alert("수정 실패했습니다.")
                }
            },
        )
    }

    return (
        <div className="flex flex-col h-full overflow-y-auto scroll-transparent scroll-overlay">
            <PerformValueInputTitle>
                <span>{column.name}</span>
            </PerformValueInputTitle>
            <OptionItemList>
                {column.options.map((option, idx) => (
                    <OptionItem
                        option={option}
                        optionItemDic={optionItemDic}
                        updateOptionDic={updateOptionDic}
                        key={idx}
                    />
                ))}
            </OptionItemList>
            <OptionControlBox>
                <OptionControlResetButton
                    onClick={() => {
                        handleReset()
                    }}
                >
                    선택 초기화
                </OptionControlResetButton>
                <SaveButton
                    onClick={() => {
                        handleSave()
                    }}
                >
                    저장
                </SaveButton>
            </OptionControlBox>
        </div>
    )
}

export const PerformValueOptionCell = ({ column, perform, value, setEditComponent }) => {
    const [optionNames, setOptionNames] = useState("")
    const [showEditComponent, closeEditComponent] = setEditComponent

    useEffect(() => {
        let optionNameList = []

        value.optionItems.map((option) => {
            const columnOption = column.options.find((s) => s.id === option.optionID)
            if (columnOption && !Utils.isStringNullOrEmpty(columnOption.name)) {
                optionNameList.push(columnOption.name)
            }
        })

        setOptionNames(optionNameList.join(","))
    }, [value])

    const handleShowEdit = () => {
        showEditComponent(
            <PerformOptionInputLayout className="justify-between bg-white">
                <OptionEdit column={column} perform={perform} value={value} closeEditComponent={closeEditComponent} />
            </PerformOptionInputLayout>,
        )
    }

    return (
        <PerformValueBox>
            {AccessRules.performValueWrite(column, perform) ? (
                <div className="flex justify-between items-center w-full group">
                    <PerformTextBox
                        isButtonCell={true}
                        onClick={() => {
                            handleShowEdit()
                        }}
                    >
                        <PerformDateCalc>{Utils.isStringNullOrEmpty(optionNames) ? "-" : optionNames}</PerformDateCalc>
                    </PerformTextBox>
                </div>
            ) : (
                <PerformTextBox>
                    <span className="px-1 truncate">{Utils.isStringNullOrEmpty(optionNames) ? "-" : optionNames}</span>
                </PerformTextBox>
            )}
        </PerformValueBox>
    )
}
