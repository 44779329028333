import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import {
    MyInfoDataBox,
    MyInfoDataContainer,
    MyInfoDataTitle,
    MyInfoDataTitleBox,
    MyInfoDataTitleContainer,
} from "./MyInfoMainStyle"
import { MyInfoDeleteButton, MyInfoDeleteControlBox, MyInfoDeleteNotMessage } from "./MyInfoDeleteStyle"

export default function MyInfoDeleteContainer({ showDeleteModal }) {
    return (
        <MyInfoDataContainer className="mt-20">
            {/* <MyInfoDataTitleContainer>
                <MyInfoDataTitle>
                    회원 탈퇴
                </MyInfoDataTitle>
            </MyInfoDataTitleContainer> */}

            <MyInfoDataBox>
                <MyInfoDeleteControlBox>
                    {/* <MyInfoDeleteNotMessage>
                        모든 절차를 완료해 주세요.
                    </MyInfoDeleteNotMessage> */}
                    <button
                        disabled={false}
                        onClick={() => {
                            showDeleteModal()
                        }}
                        className="ml-auto text-slate-500"
                    >
                        회원 탈퇴
                    </button>
                </MyInfoDeleteControlBox>
            </MyInfoDataBox>
        </MyInfoDataContainer>
    )
}
