import styled from "styled-components"
import tw from "tailwind-styled-components"
import { Link } from "react-router-dom"
import { PmsNavFolded, PmsNavUrlState } from "../../types/DataEnum"

import { Menu } from "@styled-icons/evaicons-solid/Menu"
import { Menu2 } from "@styled-icons/evaicons-solid/Menu2"
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline"
import { Home } from "@styled-icons/boxicons-regular/Home"
import { MoneyCalculator } from "@styled-icons/fluentui-system-regular/MoneyCalculator"
import { DataTrending } from "@styled-icons/fluentui-system-regular/DataTrending"
import { Person } from "@styled-icons/bootstrap/Person"
import { Group } from "@styled-icons/boxicons-regular/Group"
import { Bell } from "@styled-icons/bootstrap/Bell"
import { Boxes } from "@styled-icons/bootstrap/Boxes"
import { Buildings } from "@styled-icons/boxicons-regular/Buildings"
import { Notification } from "@styled-icons/boxicons-regular/Notification"
import { Box } from "@styled-icons/bootstrap/Box"
import { Megaphone } from "@styled-icons/bootstrap/Megaphone"
import { Balloon } from "@styled-icons/fluentui-system-regular/Balloon"
import { CartShopping } from "@styled-icons/fa-solid/CartShopping"

export const NavCon = tw.div`
w-screen
left-0 top-0 right-0 bottom-0
flex align-middle justify-center border-gray-200 border-b bg-white z-50 fixed 
h-10 px-4
`
export const NavBoxCon = tw.div`
w-full min-h-[2.5rem] px-5 bg-gray-150
`

export const NoticeIcon = tw(Bell)`
    h-5
`
export const ItemIcon = tw(Boxes)`
    h-5
`

export const NavInnerCon = tw.div`
    h-full  flex items-center 
    ${(props) => (props.navlogocon ? "justify-start w-4/12" : "justify-end w-8/12")};
`

export const Logo_img = tw.img`
    duration-200 z-50
    ${(props) => (props.folded ? "w-6 h-6" : "w-26 h-8")};
    
`
export const NavLinkStyle = tw(Link)`
    max-lg:hidden lg:flex justify-center
    mr-8 w-fit 
    lg:hover:text-blue-600 text-black text-base
    duration-200
    no-underline
`

export const NavLinkSign = tw(Link)`
    ml-10 no-underline   bg-blue-400 flex justify-center items-center rounded-md text-white lg:hover:bg-blue-300
    text-base h-7 w-20
    duration-200
`
export const NavMenuIcon = tw(Menu)`
    h-8
`
export const NavFoldIcon = tw(Menu2)`
    h-8
`
export const NavCloseIcon = tw(CloseOutline)`
    h-8
`

export const NavFoldBtn = tw.button`
    max-lg:hidden lg:block text-gray-400 lg:hover:text-gray-600
`

export const MyPageCon = tw.div`
    w-full lg:h-full flex
`

export const OutletContainer = tw.div`
    relative
    flex flex-col 
    max-lg:w-screen lg:w-[calc(100%)]
`

export const VerticalNavcon = tw.div`
    flex flex-col justify-between items-center max-lg:pt-4 lg:pt-16
    fixed h-full bg-nightblue-800
    ${(props) => (props.isfold === PmsNavFolded.FOLDED ? "max-lg:w-48 lg:w-16" : "max-lg:w-48 lg:w-48")}
`

const MyPageInnerStyle = styled.div`
    min-height: calc(100vh - 2.5rem);
`

export const VerticalNavBoxcon = tw.div`
    max-lg:hidden lg:block
    h-full
    ${(props) => (props.isfold === PmsNavFolded.FOLDED ? "lg:w-16" : "w-48")}
`

export const MyPageInner = tw.div`
    max-lg:w-full ${(props) =>
        props.isfold === PmsNavFolded.FOLDED ? "lg:w-[calc(100%-4rem)]" : "lg:w-[calc(100%-12rem)]"}
    
    lg:h-full bg-gray-100 flex flex-col
`
// min-h-[calc(100vh-2.5rem)]

export const VerticalNavCategoryBox = tw.div`
    flex flex-col w-full
`
export const VerticalNavCategoryTitle = tw.div`
duration-300 text-nightblue-200 h-5 w-full p-5 my-5
${(props) => (props.folded ? "lg:[&>span]:hidden" : "flex items-center")}
`
export const VerticalNavCategoryContent = tw.div`
    flex flex-col px-2 w-full
`

export const VerticalNavBr = tw.div`
    w-[calc(100%-2rem)] mb-4 mx-4
    border-b border-nightblue-500
`
export const VerticalNavLink = tw.div`
    ${(props) => (props.is_selected ? "bg-nightblue-500 text-white  " : "lg:hover:bg-nightblue-600")}
    ${(props) => (props.is_office ? "text-gray-400" : "text-blue-100")}
    will-change-contents
    rounded-xl cursor-pointer
    flex flex-col items-center justify-center mb-5
    w-full min-h-[3rem]
    duration-200
`
export const VerticalNavLinkHead = tw.div`
    flex flex-center w-full h-[2.75rem] px-2 flex-shrink-0
`
export const VerticalLinkText = tw.div`
    ${(props) => (props.isfold === PmsNavFolded.FOLDED ? "lg:hidden" : "flex")}
    my-2 px-2 w-full justify-start items-center text-left max-lg:text-sm lg:text-md
    duration-300 truncate [&>span]:w-full [&>span]:truncate [&>span]:text-center
`

export const MyPageVerticalNavIconContainer = tw.div`
    flex flex-center flex-shrink-0 max-lg:p-[0.7rem] lg:p-[0.625rem]
    h-full  aspect-square
`

export const VerticalSubNavCategoryContent = tw.div`
    flex flex-col w-full
    ${(props) => (props.isfold === PmsNavFolded.FOLDED ? "lg:px-1" : "px-3")}
`
export const VerticalSubNavLink = tw.div`
    ${(props) =>
        props.is_selected
            ? props.is_office
                ? "text-nightblue-50 font-semibold"
                : "text-nightblue-300 hover:text-nightblue-200 font-light"
            : "text-gray-400 lg:hover:bg-nightblue-400"}
    rounded-xl cursor-pointer
    flex flex-center mb-2
    w-full h-[2.5rem]
`
export const VerticalSubNavLinkHead = tw.div`
    flex w-full h-[2.5rem] flex-shrink-0
`

export const HomeIcon = tw(Home)`
    h-full
`
export const CalIcon = tw(MoneyCalculator)`
    h-full
`
export const GroupIcon = tw(Group)`
    h-full
`
export const MarketerIcon = tw(Person)`
    h-full
`
export const ItemManageIcon = tw(Boxes)`
    h-full
`
export const PerformIcon = tw(DataTrending)`
    h-full
`
export const BrandIcon = tw(Buildings)`
    h-full
`

export const NotiFicationIcon = tw(Notification)`
    h-full
`

export const ItemManageBoxIcon = tw(Box)`
    h-full
`
export const ItemAdBoxIcon = tw(Megaphone)`
    h-full
`
export const AdvertisementIcon = tw(Balloon)`
    h-full
`
export const PointIcon = tw(CartShopping)`
    h-full
`
