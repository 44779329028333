import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import { addPerformCalc } from "../../../apis/api/boardItems";
import BackendResponse from "../../../constants/BackendResponse";
import MyPageCalc from "../calculate/MyPageCalc";
import { CalcSelectContainer, CalcSelectList } from "../calculate/MyPageCalcStyle";
import { PerformValueInputTitle } from "./MyPagePerformStyle";

export default function MyPagePerformCalcAdd({selectedItem, performCheckDic, closeAddCalcModal}){
    const handleSelectCalc = (calc) => {
        const performIDs = performCheckDic.getKeys()
        
        if (confirm(`${performIDs.length}개의 실적을 ${calc.name} 정산에 등록하시겠습니까?`)) {
            addPerformCalc(performIDs, calc.id).then(response => {
                if (response === BackendResponse.SUCCESS) {
                    closeAddCalcModal()
                } else if (response === BackendResponse.NOT_FOUND_CONFIRM) {
                    alert("승인되지 않은 실적이 포함되어있습니다.")
                } else {
                    alert("등록에 실패했습니다.")
                }
            })
        }
    }
    
    return(
        <CalcSelectContainer>
            <PerformValueInputTitle>
                <span>정산 등록</span>
            </PerformValueInputTitle>
            <CalcSelectList>
                <MyPageCalc selectedItem={selectedItem} selectCalc={handleSelectCalc} />
            </CalcSelectList>
        </CalcSelectContainer>
    )
}