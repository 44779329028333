import ConstCookie from "../../../constants/ConstCookie"
import ImageFile from "../../../types/files/ImageFile"
import Brand from "../../../types/users/Brand"
import BrandList from "../../../types/users/BrandList"
import UserMarketerList from "../../../types/users/UserMarketerList"
import UserMarketer from "../../../types/users/UserMarketer"
import UserStaff from "../../../types/users/UserStaff"
import { UserTypes } from "../../../types/users/UserTypes"
import { getCookie } from "../../../utils/Cookie"
import Utils from "../../../utils/Utils"
import { getUserData } from "../../services/users"
import { authInstance, credentialInstance, defaultInstance } from "../../utils"
import axios from "axios"
import RecommanderList from "../../../types/users/RecommanderList"
import Recommander from "../../../types/users/Recommander"

// [개발,빌드] 접속 시 유저정보를 가져오기 위해 사용 (임시 아님)
export const getAccessToken = async (refreshToken) => {
    let result = null
    await credentialInstance
        .post("/api/users/jwt_auth/refresh/", { refresh: refreshToken })
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return result
    // let result = null
    // await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/jwt_auth/refresh/`, {
    //     headers: {
    //         "Accept": "application/json",
    //         "Content-Type": "application/json",
    //     },
    //     credentials: "include"
    // }).then(response => {
    //     console.log(response)
    //     return response.json()
    // }).then(data => {
    //     console.log(data)
    //     result = data
    // })

    // return result
}

export const getRefreshToken = () => {
    const token = getCookie(ConstCookie.USER_REFRESH_TOKEN)

    return token
}

export const getKakaoToken = async (code) => {
    let result = {}
    await defaultInstance
        .post(
            "/api/users/kakao_auth/",
            {
                code: code,
            },
            { withCredentials: true },
        )
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })
    return result
}

export const updateKakaoRegist = async (data) => {
    let result = {}
    await authInstance
        .put("/api/users/kakao_auth/", data, { withCredentials: true })
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })
    return result
}

// [개발,빌드] 일반 로그인 시 사용
export const getAuthTokenByLogin = async (email, password) => {
    let result = {}
    await defaultInstance
        .post(
            "/api/users/login/",
            {
                email: email,
                password: password,
            },
            { withCredentials: true },
        )
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })
    // console.log(result)
    // await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/users/login/`, {
    //     email: email,
    //     password: password,
    // }, {
    //     headers: {'Content-Type': 'application/json'},
    //     withCredentials: true
    // }).then(response => {
    //     console.log(response)
    //     result = response.data
    // }).catch(error => {
    //     console.log(error)
    // })
    // console.log(result)

    // await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/login/`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({email, password}),
    //     credentials: "include"
    // }).then(response => {
    //     return response.json()
    // }).then(data => {
    //     result = data
    // }).catch(error => {
    //     console.log(error)
    // })

    return result
}

// [개발,빌드] 유저정보를 업데이트하기 위해 사용
export const getAuthTokenByToken = async () => {
    const response = await authInstance.post("/api/users/refresh/")
    return response.data
}

export const getUserDetail = async (userID) => {
    const { data } = await defaultInstance.post("/api/users/detail_info/", {
        user_id: userID,
    })

    let userDetail = null
    if (data.user.user_type === UserTypes.BRAND) {
        userDetail = new Brand()
        userDetail.convertByResponse(data)
    } else if (data.user.user_type === UserTypes.MARKETER) {
        userDetail = new UserMarketer()
        userDetail.convertByResponse(data)
    } else if (data.user.user_type === UserTypes.STAFF) {
        userDetail = new UserStaff()
        userDetail.convertByResponse(data)
    }

    return userDetail
}

export const getUserBrands = async (page, pageSize, search, brandStatus, ordering) => {
    let brandList = new BrandList()

    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }

    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }
    if (brandStatus !== null) {
        params["brand_status_type"] = brandStatus
    }

    const { data } = await authInstance.get("/api/users/user_brand_list/", {
        params: params,
    })
    brandList.convertByResponse(data)

    return brandList
}

export const getUserMarketers = async (page, pageSize, search, ordering) => {
    let marketerList = new UserMarketerList()

    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }

    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }

    const { data } = await authInstance.get("/api/users/user_marketer_list/", { params })
    marketerList.convertByResponse(data)

    return marketerList
}

// 하나의 유저타입(브랜드, 마케터, 스태프)에 전화번호가 중복되는지 확인한다.
export const getCheckUserDuplicateTel = async (userType, tel) => {
    const response = await defaultInstance.post("/api/users/regist_tel_check/", {
        user_type: userType,
        tel: tel,
    })

    return response.data
}

export const getAuthTokenBySignup = async (requestData) => {
    const response = await credentialInstance.post("/api/users/register/", requestData)

    return response.data
}

export const getLogout = async () => {
    await credentialInstance.post("/api/users/logout/")
}

export const sendAuthSMS = async (requestData) => {
    const response = await defaultInstance.post("/api/users/auth_sms/", requestData)

    return response.data
}

export const sendAuthSubmitSMS = async (requestData) => {
    const response = await defaultInstance.get("/api/users/auth_sms/", { params: requestData })

    return response.data
}

export const updateUser = async (requestData) => {
    const response = await authInstance.put("/api/users/info_auth/", requestData)

    return response
}

export const updateUserDetail = async (requestData) => {
    const response = await authInstance.put("/api/users/detail_info_auth/", requestData)

    return response
}

export const updateMarketerCareers = async (requestData) => {
    const response = await authInstance.post("/api/users/career_view/", requestData)

    return response
}

export const deleteMarketerCareers = async (careerID) => {
    const response = await authInstance.delete("/api/users/career_view/", { params: { career_id: careerID } })

    return response
}

export const updateUserProfileAvatar = async (imageID) => {
    let bResult = false

    await authInstance.post("/api/users/profile_view/", { image_id: imageID }).then((response) => {
        bResult = true
    })

    return bResult
}
export const deleteUserProfileAvatar = async () => {
    let bResult = false

    await authInstance.delete("/api/users/profile_view/").then((response) => {
        bResult = true
    })

    return bResult
}

export const getBuisnessImage = async () => {
    const imageFile = new ImageFile()

    const response = await authInstance.get("/api/users/detail_business_image/")
    if (response.data.base64 !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const getUserSign = async () => {
    const imageFile = new ImageFile()

    const response = await authInstance.get("/api/users/user_sign/")
    if (response.data.base64 !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }

    return imageFile
}

export const getUserHasSign = async (userID = -1) => {
    let bResult = false

    let request = {}
    if (userID >= 0) {
        request["user_id"] = userID
    }

    const response = await authInstance.get("/api/users/user_sign/", request)
    bResult = response.data

    return bResult
}

export const getUserThirdAgreementCode = async (userID = -1, itemID) => {
    let idResult = false

    const response = await authInstance.post("/api/users/user_third_agreement_code/", {
        user_id: userID,
        item_id: itemID,
    })
    idResult = response.data

    return idResult
}

export const updateUserSign = async (signID) => {
    const imageFile = new ImageFile()

    const response = await authInstance.post("/api/users/user_sign/", { sign_id: signID })

    if (response.data.base64 !== undefined) {
        imageFile.convertByResponse(response.data)
        return imageFile
    }
    return imageFile
}

// 이메일이 존재하는지 체크.
export const checkEmail = async (email) => {
    let bResult = false

    if (Utils.isStringNullOrEmpty(email)) {
        return bResult
    }

    await defaultInstance.post("/api/users/user_check_email/", { email: email }).then((response) => {
        bResult = response.data
    })

    return bResult
}

export const findEmails = async (name, tel) => {
    let result = []

    if (Utils.isStringNullOrEmpty(name) || Utils.isStringNullOrEmpty(tel)) {
        return result
    }

    await defaultInstance.post("/api/users/user_find_email/", { name: name, tel: tel }).then((response) => {
        result = response.data
    })

    return result
}

// 비밀번호 재설정 전 이메일, 이름, 전화번호를 이용하여 해당 유저가 존재하는지 체크.
export const checkTelBySetPassword = async (email, name, tel) => {
    let bResult = false

    if (Utils.isStringNullOrEmpty(email) || Utils.isStringNullOrEmpty(name) || Utils.isStringNullOrEmpty(tel)) {
        return bResult
    }

    await defaultInstance
        .post("/api/users/user_set_password_check_tel/", { email: email, name: name, tel: tel })
        .then((response) => {
            bResult = response.data
        })

    return bResult
}

// 비밀번호 재설정.
export const setPasswordByFindPassword = async (email, name, tel, password) => {
    let bResult = false

    if (
        Utils.isStringNullOrEmpty(email) ||
        Utils.isStringNullOrEmpty(name) ||
        Utils.isStringNullOrEmpty(tel) ||
        Utils.isStringNullOrEmpty(password)
    ) {
        return bResult
    }

    await defaultInstance
        .post("/api/users/user_set_password/", {
            email: email,
            name: name,
            tel: tel,
            password: password,
        })
        .then((response) => {
            bResult = response.data
        })

    return bResult
}

export const addStoreData = async (name, phone_number, email, show_state, sign_image, url_data) => {
    let bResult = false

    await defaultInstance
        .post("/api/users/store_user_data/", {
            name: name,
            phone_number: phone_number,
            email: email,
            show_state: show_state,
            sign_image: sign_image,
            url_data: url_data,
        })
        .then((response) => {
            bResult = response.data
        })
    return bResult
}

export const checkUserCertificate = async (email, password) => {
    let result = false
    await authInstance
        .post("/api/users/delete/", {
            email: email,
            password: password,
        })
        .then((response) => {
            result = response.data
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}
export const deleteUser = async (reason) => {
    let result = false
    await authInstance
        .put("/api/users/delete/", {
            reason: reason,
        })
        .then((response) => {
            result = true
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}
export const reviveUser = async (userID) => {
    let result = false
    await authInstance
        .delete("/api/users/delete/", {
            params: {
                user_id: userID,
            },
        })
        .then((response) => {
            result = true
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}

export const getSignUpRecommanders = async (page, pageSize, keyword) => {
    let brandList = new RecommanderList()

    let params = {
        page: page,
        page_size: pageSize,
    }

    if (!Utils.isStringNullOrEmpty(keyword)) {
        params["search"] = keyword
    }

    const { data } = await defaultInstance.get("/api/users/recommander_signup_list/", {
        params: params,
    })
    brandList.convertByResponse(data)

    return brandList
}
export const getUserRecommanders = async (page, pageSize, search, dateStart, dateEnd, ordering) => {
    let brandList = new RecommanderList()

    let params = {
        page: page,
        page_size: pageSize,
        ordering: ordering,
    }

    if (!Utils.isStringNullOrEmpty(search)) {
        params["search"] = search
    }
    if (!Utils.isStringNullOrEmpty(dateStart)) {
        params["date_start"] = dateStart
    }
    if (!Utils.isStringNullOrEmpty(dateEnd)) {
        params["date_end"] = dateEnd
    }

    const { data } = await authInstance.get("/api/users/recommander_list/", {
        params: params,
    })
    brandList.convertByResponse(data)

    return brandList
}
export const createUserRecommander = async (data) => {
    const result = new Recommander()

    await authInstance.post("/api/users/recommander_list/", data).then((response) => {
        result.convertByResponse(response.data)
    })

    return result
}
export const updateUserRecommander = async (recommanderID, data) => {
    const result = new Recommander()

    await authInstance.put(`/api/users/recommander/${recommanderID}/`, data).then((response) => {
        result.convertByResponse(response.data)
    })

    return result
}
export const deleteUserRecommander = async (recommanderID) => {
    let result = false

    await authInstance.delete(`/api/users/recommander/${recommanderID}/`).then((response) => {
        result = true
    })

    return result
}

export const getRecommanderUsers = async (page, pageSize, keyword) => {
    let marketerList = new UserMarketerList()

    let params = {
        page: page,
        page_size: pageSize,
    }

    if (!Utils.isStringNullOrEmpty(keyword)) {
        params["search"] = keyword
    }

    const { data } = await defaultInstance.get("/api/users/recommander_user_list/", {
        params: params,
    })
    marketerList.convertByResponse(data)

    return marketerList
}

// 회원 가입 시 추천인 설정
export const signUpRecommand = async (data) => {
    let result = false

    await authInstance
        .post(`/api/users/recommander_user/`, data)
        .then((response) => {
            result = true
        })
        .catch((error) => {
            console.log(error)
        })

    return result
}
