import { ContainerPaddingTop } from "../../layouts/Box"
import tw from "tailwind-styled-components"
import { Check } from "@styled-icons/boxicons-regular/Check"
import { CheckboxUnchecked } from "@styled-icons/fluentui-system-filled/CheckboxUnchecked"
import { CheckboxChecked } from "@styled-icons/fluentui-system-filled/CheckboxChecked"
import { Phone } from "@styled-icons/fluentui-system-filled/Phone"

export const CheckIcon = tw(Check)`
    h-full
`
export const CheckBoxIcon = tw(CheckboxChecked)`
    h-full
`
export const UnCheckBoxIcon = tw(CheckboxUnchecked)`
    h-full
`
export const PhoneIcon = tw(Phone)`
    h-full -translate-y-1
`
export const ItemApplyContainer = tw(ContainerPaddingTop)`
    bg-gray-100 h-auto max-md:pt-0 md:pt-0 pb-0
`

export const ItemApplyLayout = tw.div`
    max-w-[900px] w-full h-full bg-white flex flex-col items-center
    max-md:pt-16 md:pt-24
`

export const ItemApplyContractPaperContainer = tw.div`
    max-lg:hidden lg:flex w-full flex flex-col px-8
`

export const ItemApplyContractButtonContainer = tw.div`
    w-full flex flex-col px-6
`

export const ContractTitle = tw.span`
    w-full flex flex-start max-md:px-6 md:px-10 mt-8 mb-2
    max-md:text-xl md:text-2xl font-bold text-nightblue-600
`

export const ContractDescription = tw.p`
    w-full 
    max-md:px-6 md:px-10
    max-md:mb-4 md:mb-6
`

export const ContractImageContainer = tw.div`
    w-full h-auto pb-4
`

export const DrawingBoardContainer = tw.div`
    w-full px-4 mb-10 py-2
`

export const ItemApplyAgreeContainer = tw.div`
    border-b flex items-center pb-8 mt-4 group cursor-pointer
`

export const CheckIconContainer = tw.div`
    flex flex-center h-5 aspect-square border-[2.5px] mr-2 rounded
    ${(props) => (props.isAgreed ? "border-gray-800 text-gray-800" : "border-gray-400 text-white")}
`

export const ItemApplyAgreeText = tw.span`
    ${(props) => (props.isAgreed ? "text-gray-800" : "text-gray-500")}
`

export const ItemApplyButton = tw.button`
    flex-center py-2 px-4 text-white rounded-md duration-200
    ${(props) => (!props.disabled ? "bg-nightblue-500" : "bg-gray-400")}
`
