import React, { useEffect, useRef, useState } from "react"

// import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../types/DataEnum"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import {
    CouponApplicationStatusTextColor,
    CouponHistoryLayout,
    CouponHistoryList,
    CouponHistorySearchBox,
    PointModalTitle,
    PointSetWriteLayout,
} from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"
import { useInView } from "react-intersection-observer"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { getImageFile } from "../../../apis/api/files"
import { ImageContainer } from "../../../components/ImageContainer"
import { CouponApplicationStatusTypeNames } from "../../../types/points/PointTypes"

const PAGE_SIZE = 20

const MyPageGiftiCouponList = ({ selectedGiftiCouponCode, selectGiftiCoupon, showModal, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)

    const [search, setSearch] = useState("")
    const [searchedValue, setSearchedValue] = useState("")
    const [giftiCoupons, setGiftiCoupons] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()
    const listRef = useRef(null)

    const [giftiBalance, setGiftiBalance] = useState("")
    const [giftiRefreshLatest, setGiftiRefreshLatest] = useState("")

    useEffect(() => {
        getCouponHistories(1, PAGE_SIZE, "")
        getGiftiInfo()
    }, [])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && giftiCoupons.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            getCouponHistories(pageIndex + 1, PAGE_SIZE, searchedValue)
        }
    }, [scrollInView])

    const getCouponHistories = async (_pageIndex, _pageSize, _search) => {
        const pagination = await PointApi.getGiftiCouponThumbByGiftiShow(_pageIndex, _pageSize, _search)

        if (pagination.count > 0) {
            if (_pageIndex > 1) {
                setGiftiCoupons([...giftiCoupons, ...pagination.items])
            } else {
                setGiftiCoupons(pagination.items)
            }
            setMaxPage(Math.ceil(pagination.count / _pageSize))
            setPageIndex(_pageIndex)
            setSearchedValue(_search)
        } else {
            setGiftiCoupons([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        getCouponHistories(1, PAGE_SIZE, search)
        if (listRef.current !== null) {
            listRef.current.scrollTo({ top: 0 })
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const getGiftiInfo = async () => {
        const response = await PointApi.getGiftiBalance()

        try {
            setGiftiRefreshLatest(response["thumb_last_datetime"])
            setGiftiBalance(response["balance"])
        } catch {
            //
        }
    }

    const handleThumbRefresh = async () => {
        if (!confirm("갱신하시겠습니까?\n10초가량 소요되며 트래픽이 적을 때 갱신해주세요.")) {
            return
        }

        alert("데이터 갱신 시작되었습니다.")
        const response = await PointApi.getGiftiCouponRefresh()

        if (response) {
            getCouponHistories(1, PAGE_SIZE, "")
            getGiftiInfo()
            alert("갱신 완료되었습니다.")
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <CouponHistoryLayout>
            {/* <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} /> */}

            <div className="flex flex-col w-full h-full p-6">
                <PointModalTitle className="px-2">기프티쇼 쿠폰 목록</PointModalTitle>
                <CouponHistorySearchBox>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={handleEnter}
                        placeholder="상품, 브랜드, 카테고리"
                    />

                    <Search
                        onClick={() => {
                            handleSearch()
                        }}
                    />
                </CouponHistorySearchBox>

                <div className="flex justify-between items-center w-full px-3 py-2 my-1 text-sm rounded bg-slate-200">
                    <span>
                        최근 데이터 갱신 : {giftiRefreshLatest} | 기프티쇼 잔액 :{" "}
                        {Utils.textFormat(giftiBalance, TextFormats.PRICE)}
                    </span>
                    <button
                        className="px-3 py-0.5 rounded bg-red-500 hover:bg-red-600 duration-200 text-white"
                        onClick={() => {
                            handleThumbRefresh()
                        }}
                    >
                        데이터 갱신
                    </button>
                </div>

                <CouponHistoryList ref={listRef}>
                    {giftiCoupons.map((coupon, index) => (
                        <Item
                            key={index}
                            coupon={coupon}
                            selectedGiftiCouponCode={selectedGiftiCouponCode}
                            selectGiftiCoupon={selectGiftiCoupon}
                        />
                    ))}
                    <div className="w-full h-2" ref={scrollRef}></div>
                </CouponHistoryList>
            </div>
        </CouponHistoryLayout>
    )
}

export default MyPageGiftiCouponList

const Item = ({ selectedGiftiCouponCode, selectGiftiCoupon, coupon }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        setImageSource(coupon.goodsImgB)
    }, [coupon])

    return (
        <div className="flex w-full h-32 p-3">
            <div className="flex flex-col w-full">
                <span className="font-semibold text-black">{coupon.goodsName}</span>
                <span className="flex items-center font-light text-slate-700 text-sm mt-auto">{coupon.brandName}</span>
                <span className="font-light text-slate-500 text-xs">
                    {coupon.goodsTypeDtlNm} - {coupon.goodsTypeNm}
                </span>

                <div className="flex items-center space-x-2 mt-1">
                    <button
                        className={`flex flex-center px-2 py-0.5 text-sm border rounded-lg border-red-600 hover:ring-1 ring-red-500 duration-200 ${
                            coupon.goodsCode === selectedGiftiCouponCode ? "bg-red-500 text-white" : "text-red-600"
                        }`}
                        onClick={() => {
                            selectGiftiCoupon(coupon)
                        }}
                    >
                        선택
                    </button>
                    <div className="flex items-center h-full text-sm">
                        <span className="text-slate-700 font-light mr-1">쿠폰 실제가</span>
                        <span>{Utils.textFormat(coupon.realPrice, TextFormats.PRICE)}</span>
                        <span className="text-slate-700 font-light mx-1">쿠폰 실제가(할인반영)</span>
                        <span>{Utils.textFormat(coupon.discountPrice, TextFormats.PRICE)}</span>
                    </div>
                </div>
            </div>
            <div className="aspect-square flex-shrink-0 h-full ml-2 rounded bg-white drop-shadow-lg overflow-hidden">
                <img
                    className="w-full h-full object-cover bg-center"
                    src={imageSource}
                    alt={null}
                    ref={imageRef}
                    onLoad={imageSetLoad}
                />
            </div>
        </div>
    )
}
