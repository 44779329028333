import React, { useRef, useEffect } from "react"
import {
    SignUpCommitButton,
    SignUpInfoCol,
    SignUpInfoInputCol,
    SignUpInfoTitle,
    SignUpUserLayout,
    SignUpUserTitle,
    SignUpUserLayoutMarketer,
    StepsLayout,
    StepsContainer,
    StepLine,
    StepNumer,
    StepTitle,
    SignUpUserMarketerContainer,
    SignUpUserMarketerLayout,
    SignUpStepContainer,
    SignUpBasicInformationContainer,
    SignUpButtonContainer,
    SignUpButton,
    SignUpBuisnessButton,
    SignUpBuisnessPreview,
    BuisnessImageHover,
    BuisnessImage,
    SignInputDescriptionBox,
    SignInputDescription,
} from "./SignUpStyle"
import { UserInput } from "../../components/user/Inputs"
import { useFocus } from "../../hooks/useFocus"
import { TelValidate } from "../../components/user/TelValidate"
import { AddressSearch } from "../../components/user/SignUpAddress"
import { useMouseHover } from "../../hooks/useMouseHover"
import { uploadImage } from "../../apis/api/files/index"

export default function SignUpBrandUser({
    brandName,
    setBrandName,
    brandCaptinName,
    setBrandCaptinName,
    address,
    setAddress,
    detailAddress,
    setDetailAddress,
    brandTelNumber,
    setBrandTelNumber,
    businessNo,
    setBusinessNo,
    businessImageName,
    setBuisnessImageName,
    businessImage,
    setBuisnessImage,
    setBrandTelValidate,
    setBuisnessImageID,
}) {
    const fileInputRef = useRef(null)
    const [refMouseHover, isMouseHover] = useMouseHover()
    const [brandTelFocusRef, isBrandTelFocus] = useFocus()

    //이미지 업로드 api 실행
    async function ImageUpload(file) {
        const imageFile = await uploadImage(file)
        setBuisnessImageID(imageFile.id)
    }

    // 이미지 바뀌는 함수
    const handleImageChange = (event) => {
        const file = event.target.files[0]
        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            const reader = new FileReader()
            reader.onloadend = () => {
                const base64Image = reader.result
                setBuisnessImage(base64Image)
            }
            reader.readAsDataURL(file)
            setBuisnessImageName(file.name)
        }
        ImageUpload(file)
    }
    // 이미지 업로드 하는 버튼 클릭시, 해당 ref의 input 실행
    const handleButtonClick = () => {
        fileInputRef.current.click()
    }

    return (
        <React.Fragment>
            <SignUpInfoInputCol>
                <UserInput
                    placeholder={"회사명"}
                    type={"string"}
                    data={brandName}
                    setData={setBrandName}
                    maxLength={100}
                />
                <UserInput
                    placeholder={"대표자명"}
                    type={"string"}
                    data={brandCaptinName}
                    setData={setBrandCaptinName}
                    maxLength={100}
                />
                <div className="flex items-center relative">
                    <TelValidate
                        tel={brandTelNumber}
                        setValidate={setBrandTelValidate}
                        isShow={isBrandTelFocus}
                        top={"2.5rem"}
                        maxLength={20}
                    />
                    <UserInput
                        isTel={true}
                        placeholder={"회사 전화"}
                        type={"string"}
                        data={brandTelNumber}
                        setData={setBrandTelNumber}
                        ref={brandTelFocusRef}
                    />
                </div>
                <AddressSearch
                    address={address}
                    setAddress={setAddress}
                    detailAddress={detailAddress}
                    setDetailAddress={setDetailAddress}
                    formType={1}
                />
                <UserInput
                    placeholder={"사업자 등록 번호"}
                    type={"string"}
                    data={businessNo}
                    setData={setBusinessNo}
                    maxLength={45}
                />
                <UserInput placeholder={"사업자 등록증"} type={"string"} data={businessImageName} disabled={true}>
                    <div>
                        <SignUpBuisnessPreview isshow={businessImage === null} ref={refMouseHover}>
                            미리보기
                        </SignUpBuisnessPreview>
                        {businessImage && (
                            <BuisnessImageHover isshow={isMouseHover}>
                                <BuisnessImage src={businessImage} alt={null} />
                            </BuisnessImageHover>
                        )}
                    </div>
                    <SignUpBuisnessButton onClick={handleButtonClick}>업로드</SignUpBuisnessButton>
                    <input
                        type="file"
                        accept="image/jpeg,image/png"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                    />
                </UserInput>
                <SignInputDescriptionBox>
                    <SignInputDescription>
                        이미지 파일만 업로드 가능합니다. 업로드 가능한 확장자:png, jpeg
                    </SignInputDescription>
                </SignInputDescriptionBox>
            </SignUpInfoInputCol>
        </React.Fragment>
    )
}
