import React, { useEffect, useRef, useState } from "react"
import { ItemCreatCon, ItemCreateInnerBot, ItemCreateTitle } from "../brand/MyPageItemCreateStyle"
import {
    AdLevelButton,
    ItemStatusComboApplyButton,
    ItemStatusComboBox,
    ItemStatusComboSelect,
    ItemStatusComboSelectBox,
    ItemStatusComboTitle,
    ItemSubmitControlBox,
    ItemViewToggleBox,
    ItemViewToggleButton,
    ItemViewToggleStackButton,
    ItemWriteLayout,
    ItemWriteTitle,
    ItemWriteViewTypeButton,
} from "../brand/MyPageItemBrandStyle"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { EditStateTypes, ItemTypes, QueryTypes, AdTypeNames } from "../../../../types/EditorTypes"
import {
    acceptBrandItemUpdate,
    createBrandItem,
    deleteBrandItem,
    deleteBrandItemTemp,
    getBrandItemTempView,
    getBrandItemView,
    updateBrandItem,
    updateBrandItemState,
} from "../../../../apis/api/boardItems"
import {
    AcceptButton,
    EditAcceptButton,
    EditAcceptContainer,
    EditAcceptMenuBox,
    EditAcceptMenuContainer,
    EditAcceptPreviewButton,
    ItemControlBox,
    ItemStatusControlBox,
    PreviewButton,
    ResetButton,
    SaveButton,
} from "../brand/ItemInputFormStyle"
import Utils from "../../../../utils/Utils"
import { useUserStore } from "../../../../zustand/store"
import AccessRules from "../../../../rules/AccessRules"
import useDropdownDetectClose from "../../../../hooks/useDropdownDetectClose"
import ConstSession from "../../../../constants/ConstSession"
import ConstLocalStorage from "../../../../constants/ConstLocalStorage"
import FileUtils from "../../../../utils/FileUtils"
import BrandItemUtils from "../../../../utils/brandItem/BrandItemUtils"
import { UserTypes } from "../../../../types/users/UserTypes"
import { useWindowHeight } from "../../../../hooks/useWindowHeight"
import SpinLoading from "../../../../components/loading/SpinLoading"
import { sendAlertTalk } from "../../../../apis/services/sms"
import { AlertTalkTemplateTypes } from "../../../../types/SmsTypes"
import AdItemInputForm from "./AdItemInputForm"

const MyPageItemAdWrite = ({ item, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const windowInnerHeight = useWindowHeight() // 모바일에서 최대사이즈를 이용하기 위해 windowInnerHeight를 이용한다.
    const [height, setHeight] = useState(window.innerWidth <= 640 ? window.innerHeight : window.innerHeight * 0.9)

    const [viewType, setViewType] = useState(EditStateTypes.EDITED) // 아이템 정보를 원본으로 보여줄지 수정본으로 보여줄지 설정(Wait: 원본, EDITED: 수정본)

    const [isLoading, setLoading] = useState(true)

    const [queryType, setWriteType] = useState(QueryTypes.NONE)
    const [itemDetail, setItemDetail] = useState(new BrandItem())
    const [itemTempDetail, setItemTempDetail] = useState(new BrandItem())

    const submitRef = useRef(null)

    const [itemStateType, setItemStateType] = useState(EditStateTypes.PENDING)
    const [itemStateEditType, setItemStateEditType] = useState(EditStateTypes.WAIT)

    const [previewLoading, setPreviewLoading] = useState(false)
    const previewRef = useRef(null)
    const [acceptMenuIsOpen, acceptMenuRef, acceptMenuHandler] = useDropdownDetectClose(false) // 아이템 수정(등록) 승인 요청 시 미리보기 승인 요청 버튼 팝업

    useEffect(() => {
        return () => {
            if (previewRef !== null) {
                try {
                    previewRef.current.close()
                } catch {
                    //
                }
                previewRef.current = null
            }
        }
    }, [])

    useEffect(() => {
        setHeight(window.innerWidth <= 640 ? windowInnerHeight : windowInnerHeight * 0.9)
    }, [windowInnerHeight])

    useEffect(() => {
        setWriteType(
            item.id >= 0 && item.editStateType !== EditStateTypes.PENDING ? QueryTypes.UPDATE : QueryTypes.CREATE,
        )

        getItemData()
    }, [item])

    const getItemData = () => {
        // 아이템 등록시에는 temp만 보여준다.
        setLoading(true)
        if (item.id >= 0) {
            getBrandItemView(item.id)
                .then((response) => {
                    const responseItem = new BrandItem()
                    responseItem.convertByResponse(response)

                    // 아이템의 제목이 없는 경우 생성 중인 아이템으로 보고 콤보 아이디들을 -1로 바꾼다. (아이템 수정 비교 확인을 위해)
                    if (Utils.isStringNullOrEmpty(responseItem.detail.title)) {
                        responseItem.detail.itemSalesType = -1
                        responseItem.detail.marketerType = -1
                    }

                    setItemDetail(responseItem)
                    setItemStateType(responseItem.editStateType)

                    getBrandItemTempView(item.id)
                        .then((response) => {
                            const responseItem = new BrandItem()
                            responseItem.convertByResponse(response)

                            // 수정본에 제목이 없고 아이템 아이디가 있다면 아직 작성하지 않은 수정본이라 가정하고 수정본에 원본으로 채운다.
                            if (Utils.isStringNullOrEmpty(responseItem.detail.title) && item.id >= 0) {
                                getBrandItemView(item.id)
                                    .then((response) => {
                                        const responseItem = new BrandItem()
                                        responseItem.convertByResponse(response)
                                        setItemTempDetail(responseItem)
                                        setLoading(false)
                                    })
                                    .catch((error) => {
                                        setLoading(false)
                                    })
                            } else {
                                // 등록 신청한 아이템일 때 항상 이렇게 나와서 주석
                                // responseItem.detail.itemSalesType = -1
                                // responseItem.detail.marketerType = -1
                                setItemTempDetail(responseItem)
                                setLoading(false)
                            }
                        })
                        .catch((error) => {
                            setLoading(false)
                        })
                })
                .catch((error) => {
                    setLoading(false)
                })
        } else {
            const responseItem = item
            responseItem.detail.itemSalesType = -1
            responseItem.detail.marketerType = -1
            setItemDetail(responseItem)
            setItemTempDetail(responseItem)
            setLoading(false)
        }
    }

    const getSubmitItem = () => {
        let item = new BrandItem()

        if (submitRef.current) {
            item = submitRef.current.getItem()
        }

        return item
    }

    const handleAccept = async () => {
        const error = () => {
            alert("승인 실패했습니다.\n실적 컬럼이 존재하는지 확인해주세요.")
        }

        if (!AccessRules.staff(user) || !confirm("승인 하시겠습니까?")) {
            return
        }

        const submitItem = getSubmitItem()
        if (!BrandItemUtils.validateItemAdUpdate(submitItem)) {
            return
        }

        let requestData = submitItem.convertToRequest()
        requestData["item_type"] = ItemTypes.AD

        let bResult = false

        // 등록 요청인 경우 승인 먼저 진행한다.
        if (item.editStateType === EditStateTypes.PENDING) {
            bResult = await acceptBrandItemUpdate(item.id)
        }

        if (queryType === QueryTypes.UPDATE || item.editStateType === EditStateTypes.PENDING) {
            bResult = await updateBrandItem(item.id, requestData)
        } else if (queryType === QueryTypes.CREATE) {
            bResult = await createBrandItem(requestData)
        }

        if (bResult) {
            bResult = await acceptBrandItemUpdate(item.id)
        }

        if (bResult) {
            getItemData()

            // 알림톡 발송
            // let alertTalkDatas = [
            //     {
            //         tel: String(item.brand.user.tel),
            //         variables: {
            //             "#{아이템명}": itemTempDetail.detail.title,
            //             "#{타입}": queryType === QueryTypes.CREATE ? "등록" : "수정",
            //         },
            //     },
            // ]
            // sendAlertTalk(AlertTalkTemplateTypes.ITEM_EDIT_ACCEPT, alertTalkDatas)

            alert("승인되었습니다.")
            setShowModal(false)
        } else {
            error()
        }
    }

    const submit = async () => {
        let bResult = false

        const submitItem = getSubmitItem()
        if (!BrandItemUtils.validateItemAdUpdate(submitItem, submitRef.current.setTabIndex)) {
            return
        }

        if (
            !confirm(
                `승인 요청을하면 내용을 수정하거나 요청을 취소할 수 없습니다.\n${
                    queryType === QueryTypes.UPDATE ? "수정" : "등록"
                } 승인 요청 하시겠습니까?`,
            )
        ) {
            return
        }

        let requestData = submitItem.convertToRequest()
        requestData["item_type"] = ItemTypes.AD

        if (queryType === QueryTypes.UPDATE || item.editStateType === EditStateTypes.PENDING) {
            bResult = await updateBrandItem(item.id, requestData)
        } else if (queryType === QueryTypes.CREATE) {
            bResult = await createBrandItem(requestData)
        }

        if (bResult) {
            // 알림톡 발송
            let alertTalkDatas = [
                {
                    tel: "010-5294-3923",
                    variables: {
                        "#{아이템명}": submitItem.detail.title,
                        "#{타입}": queryType === QueryTypes.CREATE ? "등록" : "수정",
                    },
                },
            ]
            // sendAlertTalk(AlertTalkTemplateTypes.ITEM_EDIT_REQUEST, alertTalkDatas)

            alert("승인 요청되었습니다.")
            getItemData()
            setShowModal(false)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    const handleResetEdited = async () => {
        if (
            item.id >= 0 &&
            confirm(
                `${
                    queryType === QueryTypes.UPDATE ? "수정" : "등록"
                } 승인 요청을 취소하고 수정본을 삭제합니다.\n계속하시겠습니까?`,
            )
        ) {
            deleteBrandItemTemp(item.id).then((response) => {
                if (response) {
                    getItemData()
                }
            })
        }
    }

    const handleItemStateHelp = () => {
        alert(
            "광고 중 : 리스트 조회 및 실적, 정산 등록이 가능합니다.\n일시 정지 : 리스트 조회 및 실적, 정산 등록이 불가능합니다.\n차단 : 세일즈랑에서 모든 기능을 차단한 상태입니다.",
        )
    }

    const handleItemStateType = (value) => {
        if (value === EditStateTypes.PENDING || value === EditStateTypes.EDITED) {
            alert("선택할 수 없는 옵션입니다.")
            return
        }
        setItemStateType(value)

        if (value === itemDetail.editStateType) {
            setItemStateEditType(EditStateTypes.WAIT)
        } else {
            setItemStateEditType(EditStateTypes.EDITED)
        }
    }

    const handleItemStateApply = async () => {
        let bResult = false
        if (
            (itemStateType == EditStateTypes.WAIT &&
                confirm(
                    `상태를 광고 중으로 바꾸시겠습니까?${
                        itemDetail.editStateType == EditStateTypes.EDITED
                            ? "\n작성 중인 수정본이있다면 수정본이 삭제 됩니다."
                            : ""
                    }`,
                )) ||
            (itemStateType == EditStateTypes.DISABLED && confirm("상태를 일시정지로 바꾸시겠습니까?")) ||
            (itemStateType == EditStateTypes.FAILED && confirm("상태를 차단으로 바꾸시겠습니까?"))
        ) {
            setItemStateEditType(EditStateTypes.PENDING)
            bResult = await updateBrandItemState(item.id, itemStateType)
        }

        if (bResult) {
            getItemData()
            setItemStateEditType(EditStateTypes.DONE)
        } else {
            setItemStateEditType(EditStateTypes.FAILED)
        }
    }

    const handleItemDelete = async (_item) => {
        // 스태프만 삭제가 가능하다. (논리삭제)
        let bResult = false

        if (queryType === QueryTypes.UPDATE || item.editStateType === EditStateTypes.PENDING) {
            bResult = await deleteBrandItem(_item.id, _item.convertToRequest())
        }

        if (bResult) {
            alert("삭제 완료되었습니다.")
            setShowModal(false)
        } else {
            alert("삭제 실패했습니다.")
        }
    }

    const handlePreview = async () => {
        if (previewLoading) {
            return
        }
        setPreviewLoading(true)

        const submitItem = getSubmitItem()

        // 수정중일 때 제출할 아이템이 없으면 진행할 수 없다.
        if (queryType === QueryTypes.UPDATE && submitItem.id < 0) {
            setPreviewLoading(false)
            return
        }

        // 수정한 아이템 데이터를 sessionstorage에 담는다.
        let itemData = submitItem.convertToRequest()
        itemData["id"] = itemDetail.id
        itemData["is_edit_requested"] = itemDetail.isEditRequested // 이미 요청이된 아이템인지 여부
        if (user.userType === UserTypes.STAFF) {
            itemData["is_edit_requested"] = true
        }
        const itemDataJson = JSON.stringify(itemData)

        sessionStorage.setItem(ConstSession.ITEM_PREVIEW_DATA, itemDataJson)

        const width = 1280
        const height = (window.screen.availHeight * 95) / 100
        let left = document.body.offsetWidth / 2 - width / 2
        let tops = (window.screen.availHeight * 2.5) / 100

        left += window.screenLeft

        const _previewRef = window.open(
            "/ads/preview",
            "new",
            `width=${width}, height=${height}, top=${tops}, left=${left}, scrollbars=no, status=no, location=no, directories=no;`,
        )
        previewRef.current = _previewRef

        setPreviewLoading(false)

        // 1초마다 미리보기 창이 꺼져있는지 확인 후 꺼져있으면 데이터를 불러온다.
        const checkWindowClosed = setInterval(() => {
            if (previewRef.current !== null && previewRef.current.closed) {
                // 미리보기에서 승인 요청을 했다면 데이터를 다시 가져온다.
                const submitted = localStorage.getItem(ConstLocalStorage.ITEM_PREVIEW_SUBMITTED)
                if (submitted === "1") {
                    getItemData()
                }
                previewRef.current = null

                clearInterval(checkWindowClosed)
            }

            if (previewRef.current === null) {
                clearInterval(checkWindowClosed)
            }
        }, 1000)
    }

    const updateAdLevel = async (adType) => {
        let bResult = false

        const submitItem = getSubmitItem()
        if (!BrandItemUtils.validateItemAdUpdate(submitItem)) {
            return
        }

        let requestData = submitItem.convertToRequest()
        requestData["ad_type"] = adType

        bResult = await updateBrandItem(item.id, requestData)

        if (bResult) {
            // 알림톡 발송
            let alertTalkDatas = [
                {
                    tel: "010-5294-3923",
                    variables: {
                        "#{아이템명}": submitItem.detail.title,
                        "#{타입}": queryType === QueryTypes.CREATE ? "등록" : "수정",
                    },
                },
            ]
            // sendAlertTalk(AlertTalkTemplateTypes.ITEM_EDIT_REQUEST, alertTalkDatas)

            alert("홍보 타입 변경 요청되었습니다.")
            getItemData()
            // setShowModal(false)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    return (
        <ItemWriteLayout>
            <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} />

            <div className="flex flex-col w-full h-full p-4">
                <ItemWriteTitle>상품 {queryType === QueryTypes.UPDATE ? "수정" : "등록"}</ItemWriteTitle>

                <ItemControlBox>
                    <ItemStatusControlBox className="max-sm:w-full max-sm:justify-between">
                        <ItemStatusComboBox>
                            <div className="flex">
                                <ItemStatusComboTitle
                                    onClick={() => {
                                        handleItemStateHelp()
                                    }}
                                >
                                    상품 상태
                                </ItemStatusComboTitle>
                                <ItemStatusComboSelectBox editState={itemStateEditType}>
                                    <ItemStatusComboSelect
                                        value={itemStateType}
                                        onChange={(e) => {
                                            handleItemStateType(e.target.value)
                                        }}
                                    >
                                        <option
                                            value={EditStateTypes.WAIT}
                                            hidden={!AccessRules.brandItemStatusWait(itemDetail, user)}
                                        >
                                            광고 중
                                        </option>
                                        <option
                                            value={EditStateTypes.DISABLED}
                                            hidden={!AccessRules.brandItemStatusDisabled(itemDetail, user)}
                                        >
                                            일시 정지
                                        </option>
                                        <option value={EditStateTypes.FAILED} hidden={!AccessRules.staff(user)}>
                                            차단
                                        </option>
                                        <option value={EditStateTypes.PENDING} hidden>
                                            등록 요청
                                        </option>
                                    </ItemStatusComboSelect>
                                </ItemStatusComboSelectBox>
                                <ItemStatusComboApplyButton
                                    onClick={() => {
                                        handleItemStateApply()
                                    }}
                                    disabled={itemStateType === itemDetail.editStateType}
                                >
                                    적용
                                </ItemStatusComboApplyButton>
                                {itemDetail.isEditRequested && (
                                    <span className="max-sm:hidden sm:block text-yellow-700">
                                        {queryType === QueryTypes.UPDATE ? "수정" : "등록"} 승인 요청되었습니다.
                                    </span>
                                )}
                            </div>
                        </ItemStatusComboBox>
                        {queryType !== QueryTypes.CREATE && (
                            <div className="max-sm:block sm:hidden relative w-16 h-6">
                                <ItemViewToggleStackButton
                                    onClick={() => {
                                        setViewType(EditStateTypes.EDITED)
                                    }}
                                    isShow={viewType === EditStateTypes.WAIT}
                                    className="bg-gray-50"
                                >
                                    원본
                                </ItemViewToggleStackButton>
                                <ItemViewToggleStackButton
                                    onClick={() => {
                                        setViewType(EditStateTypes.WAIT)
                                    }}
                                    isShow={viewType === EditStateTypes.EDITED}
                                    className="bg-nightblue-100"
                                >
                                    수정본
                                </ItemViewToggleStackButton>
                            </div>
                        )}
                    </ItemStatusControlBox>
                    <ItemStatusControlBox className={`max-sm:w-full max-sm:justify-between max-sm:mt-2`}>
                        {itemDetail.isEditRequested && (
                            <span className="max-sm:flex sm:hidden text-sm text-yellow-700">
                                {queryType === QueryTypes.UPDATE ? "수정" : "등록"} 승인 요청되었습니다.
                            </span>
                        )}
                        {queryType !== QueryTypes.CREATE && (
                            <div className="max-sm:hidden sm:block">
                                <ItemViewToggleBox>
                                    <ItemViewToggleButton
                                        onClick={() => {
                                            setViewType(EditStateTypes.WAIT)
                                        }}
                                        isShow={viewType === EditStateTypes.WAIT}
                                        isLeft
                                    >
                                        원본
                                    </ItemViewToggleButton>
                                    <ItemViewToggleButton
                                        onClick={() => {
                                            setViewType(EditStateTypes.EDITED)
                                        }}
                                        isShow={viewType === EditStateTypes.EDITED}
                                        isRight
                                    >
                                        수정본
                                    </ItemViewToggleButton>
                                </ItemViewToggleBox>
                            </div>
                        )}

                        <AdLevelButton isActive={true} disabled>
                            광고상태: {AdTypeNames[item.adType]}
                        </AdLevelButton>
                        {/* 여기에 마우스를 올리면 수정한 아이템 내용을 끌어올린다. 미리보기나 승인 요청에 끌어올린 아이템을 사용한다. */}
                        <ItemSubmitControlBox>
                            <PreviewButton
                                onClick={() => {
                                    handlePreview()
                                }}
                            >
                                미리보기
                            </PreviewButton>
                            {!AccessRules.staff(user) && (
                                <React.Fragment>
                                    <EditAcceptContainer ref={acceptMenuRef}>
                                        {itemDetail.isEditRequested ? (
                                            <ResetButton
                                                className="bg-red-800 hover:bg-red-700"
                                                onClick={() => {
                                                    handleResetEdited()
                                                }}
                                            >
                                                수정 철회
                                            </ResetButton>
                                        ) : (
                                            <SaveButton
                                                onClick={() => {
                                                    submit()
                                                }}
                                                disabled={itemDetail.isEditRequested}
                                            >
                                                {queryType === QueryTypes.UPDATE ? "수정" : "등록"}
                                            </SaveButton>
                                        )}
                                    </EditAcceptContainer>
                                </React.Fragment>
                            )}
                            {/* {!AccessRules.staff(user) && (
                                <EditAcceptContainer ref={acceptMenuRef}>
                                    <SaveButton onClick={()=>{handleAccpetMenu()}} disabled={itemDetail.isEditRequested}>
                                        {queryType === QueryTypes.UPDATE ? "수정" : "등록"}
                                    </SaveButton>
                                    <EditAcceptMenuContainer acceptMenuIsOpen={acceptMenuIsOpen}>
                                        <EditAcceptMenuBox>
                                            <EditAcceptPreviewButton onClick={()=>{handlePreview()}}>미리보기</EditAcceptPreviewButton>
                                            <EditAcceptButton onClick={()=>{submit()}}>승인 요청</EditAcceptButton>
                                        </EditAcceptMenuBox>
                                    </EditAcceptMenuContainer>
                                </EditAcceptContainer>
                            )} */}
                            {AccessRules.staff(user) && (
                                <React.Fragment>
                                    <ResetButton
                                        onClick={() => {
                                            handleResetEdited()
                                        }}
                                    >
                                        수정 철회
                                    </ResetButton>
                                    <AcceptButton
                                        onClick={() => {
                                            handleAccept()
                                        }}
                                        // disabled={!itemDetail.isEditRequested}
                                    >
                                        저장 및 승인
                                    </AcceptButton>
                                </React.Fragment>
                            )}
                        </ItemSubmitControlBox>
                    </ItemStatusControlBox>
                </ItemControlBox>
                <div className="relative w-full h-full">
                    {/* 원본 */}
                    {queryType !== QueryTypes.CREATE && (
                        <AdItemInputForm
                            ref={null}
                            item={itemDetail}
                            queryType={queryType}
                            itemEditStateType={item.editStateType}
                            submitFlag={false}
                            setSubmitItem={() => {}}
                            isShow={!isLoading && viewType === EditStateTypes.WAIT}
                            disabled={true}
                            userType={user.userType}
                            updateAdLevel={updateAdLevel}
                        />
                    )}
                    {/* 수정본 */}
                    <AdItemInputForm
                        ref={submitRef}
                        item={itemTempDetail}
                        queryType={queryType}
                        itemEditStateType={item.editStateType}
                        isShow={!isLoading && viewType === EditStateTypes.EDITED}
                        viewType={EditStateTypes.EDITED}
                        itemOrigin={itemDetail}
                        deleteItem={handleItemDelete}
                        userType={user.userType}
                        updateAdLevel={updateAdLevel}
                    />
                </div>
            </div>
        </ItemWriteLayout>
    )
}

export default MyPageItemAdWrite
