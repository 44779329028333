import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'
import {BoxMaxMin} from "../../layouts/Box"
import {Link} from 'react-router-dom'

export const FooterContainer = tw.div`
    w-full max-md:min-h-[12rem] md:h-[12rem] flex flex-shrink-0 items-center justify-center border-t bg-slate-50 max-md:text-xs md:text-sm
    max-md:pb-14 max-xl:p-4
`

export const FooterNavButton = tw.button`
border-r border-gray-400 mr-4 pr-4 py-0.5
hover:text-black
`

export const FooterInfoLayout = tw.div`
    max-lg:flex-shrink-0 lg:flex-grow-1 flex flex-col justify-center text-gray-600
`
export const FooterImageList = tw.div`
    max-lg:hidden lg:flex flex-col w-72 p-4 lg:ml-4
`

export const FooterInfoTextList = tw.div`
    flex flex-col
`
export const FooterInfoText = tw.span`
    mb-1
`


export const FooterImageBox = tw.div`
    lg:w-full lg:h-24 lg:mb-4 lg:pl-16
    bg-transparent
`

// 모바일 하단 내비만큼 마진을 주기위한 박스
export const MobileNavMarginBox = tw.div`
    max-md:block md:hidden
    w-full h-10 flex-shrink-0 bg-transparent
`