import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import {Send} from "@styled-icons/bootstrap/Send"
import { PerformValueInputLayout } from "./MyPagePerformStyle";
import { CheckBox, CheckBoxOutlineBlank, RadioButtonChecked, RadioButtonUnchecked } from "styled-icons/material";

export const PerformOptionInputLayout = tw(PerformValueInputLayout)`
    max-md:h-auto md:h-auto lg:h-auto
    max-md:max-h-full md:max-h-[75vh] lg:min-h-fit lg:max-h-[75vh]
`

export const OptionItemList = tw.div`
    flex flex-wrap w-full
`

export const OptionItemBox = tw.div`
    flex items-center h-8 px-2 py-1 mr-2 mb-1
    max-sm:text-sm sm:text-base
    rounded-lg
    border-spacing-4
    ${(props) => props.readOnly ? "cursor-default" : "cursor-pointer lg:hover:bg-gray-400/20"}
`

export const OptionIconContainer = tw.div`
    flex flex-center h-full py-0.5 pr-1
    text-nightblue-600
`
export const OptionIconRadioUncheck = tw(RadioButtonUnchecked)`
    h-full
`
export const OptionIconRadioCheck = tw(RadioButtonChecked)`
    h-full
`
export const OptionIconSelectUncheck = tw(CheckBoxOutlineBlank)`
    h-full
`
export const OptionIconSelectCheck = tw(CheckBox)`
    h-full
`
export const OptionItemName = tw.span`
    max-sm:text-sm sm:text-base
`

export const OptionItemTextInputBox = tw.div`
    flex flex-center w-32 h-full px-2 py-1 ml-2
    rounded-md border bg-white
`
export const OptionItemTextInput = tw.input`
    w-full h-full 
    bg-transparent
    max-sm:text-sm
`

export const OptionControlBox = tw.div`
    flex justify-between items-center w-full mt-2
`
export const OptionControlResetButton = tw.button`
    px-2 text-red-600
`