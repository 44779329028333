export const TitleTips=
`세일즈마케터에게 상품을 잘 표현할수 있는 영업 상품으로 표현`

export const MarketerTypeTips=
`1) 프리 마케터(Pre-Marketer): 미팅 주선, 소개/추천 정도의 가벼운 영업
2) 프로 마케터(Pro-Marketer): 방문 미팅 예약후(또는 미팅 예약 없이),
   현장방문을 통한 제안, 시연 영업
`

export const ItemCategoryTips=
`1) 솔루션: 유/무형의 제품에 전문적인 기술을 포함한 통합 상품
2) 제품: 유형의 상품
3) 서비스: 무형의 가치를 가지는 서비스 제품, 예) SNS마케팅 교육 서비스 상품
`

export const RewardTips=
`영업에 대한 보상 내역을 1)금액 또는 2)판매가 대비 비율(%)로 표현
예) 건당 50만원 부터, 건당 판매가의 15% 부터
`

export const MissionTips= 
`해당 상품에 대한 보상을 받기 위한 명확한 업무의 범위를 한줄로 표현 
예) 방문 제안을 위한 담당자 정보(이름, 연락처) 전달
`