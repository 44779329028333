import tw from "tailwind-styled-components"
import { ImageAdd } from "@styled-icons/remix-fill/ImageAdd"
import { Delete } from "@styled-icons/material/Delete"
import { Pen } from "@styled-icons/bootstrap/Pen"
import { Pencil } from "@styled-icons/icomoon/Pencil"
export const PenIcon = tw(Pencil)`
h-4
`

export const MyInfoLayout = tw.div`
    flex flex-col items-center max-lg:w-screen lg:w-full lg:py-16 h-full
    bg-gray-150
`

export const MyInfoContainer = tw.div`
    flex max-lg:flex-col lg:flex-row lg:pb-10 w-full lg:min-w-[32rem] lg:max-w-[64rem] lg:px-4
`

export const MyInfoProfileLayout = tw.div`
    flex items-center flex-col lg:pt-1 
    max-lg:w-full lg:w-64 max-lg:h-40 lg:h-192 
`
export const MyInfoProfileContainer = tw.div`
    flex items-center max-lg:justify-center flex-col lg:px-4 max-lg:py-4 lg:py-4 lg:mr-16
    max-lg:w-full lg:w-64 h-full  
    lg:rounded-lg bg-nightblue-600
`
export const MyInfoPenIconContainer = tw.div`
max-lg:absolute lg:hidden top-2 right-0
max-lg:flex justify-center items-center aspect-square h-7 pl-[0.1rem] 
bg-slate-700 text-gray-400 
rounded-full 
`
export const MyInfoProfileAvatarContainer = tw.div`
relative
flex flex-center lg:mb-10
max-lg:h-full lg:h-52 aspect-square   
bg-blue-100 rounded-full

`
export const MyInfoProfileAvatarEditContainer = tw.div`
${(props) => (props.mobileClickAvatar ? "max-lg:visible" : "max-lg:invisible")}
absolute top-0 rounded-full lg:invisible lg:group-hover:visible
flex flex-center w-full h-full
bg-black/70
select-none
`
export const MyInfoProfileAvatarEditIconContainer = tw.div`
flex flex-center h-12 mx-2 p-1
text-white hover:text-gray-200
`
export const MyInfoProfileAvatarUploadIcon = tw(ImageAdd)`
h-full
`
export const MyInfoProfileAvatarDeleteIcon = tw(Delete)`
h-full
`

export const MyInfoProfileUserDeleteContainer = tw.div`
    flex w-full mt-auto
`
export const MyInfoProfileUserDeleteButton = tw.button`
    text-nightblue-200
    hover:underline underline-offset-4
`

export const MyInfoDatasContainer = tw.div`
    flex flex-col w-full 
`
export const MyInfoDataContainer = tw.div`
    flex flex-col w-full lg:mb-6 
`

export const MyInfoDataTitleBox = tw.div`
    max-lg:hidden lg:flex justify-between items-center
    w-full h-20
`

export const MyInfoDataTitleContainer = tw.div`
    flex justify-between items-center
    w-full lg:h-20 max-lg:px-6
`

export const MyInfoDataTitle = tw.div`
    w-full min-h-[4rem] flex items-center text-nightblue-700 max-lg:text-lg lg:text-2xl font-semibold
`
export const MyInfoDataImageEditButton = tw.button`
    bg-nightblue-400 px-4 py-3 text-white text-lg rounded-md
`

export const MyInfoDataEditButton = tw.button`
    w-32 bg-blue-400 h-12 text-white text-lg rounded-md
`

export const MyInfoMoreDataBox = tw.div`
    flex flex-col w-full 
    bg-white
    lg:rounded-md
`

export const MyInfoDataBox = tw(MyInfoMoreDataBox)`
    py-4 max-lg:px-6 lg:px-10
`

export const MyPageDataGrid = tw.div`
grid sm:grid-cols-1 md:grid-cols-2 gap-6 py-2
`
export const MyPageDataControlBox = tw.div`
    flex justify-end items-center w-full
`
export const MyPageDataSubmitButton = tw.button`
    flex flex-center max-lg:px-6 max-lg:py-1 lg:px-6 lg:py-1.5
    rounded-lg bg-nightblue-500
    text-white
`
export const MyPageDataAddButton = tw.button`
    flex flex-center px-4 py-1
    text-nightblue-500
    rounded-lg ring-1 ring-nightblue-500
`

export const MyPageCareerInfoBox = tw.div`
    flex justify-between items-center my-2
`
export const MyPageCareerTitle = tw.span`
    font-medium text-lg
`
export const MyPageCareerDelete = tw.button`
    text-red-500 px-2 py-0.5
`
export const MyPageCareerGrid = tw(MyPageDataGrid)`
    pb-4 mb-4
`
export const MyPageBusnessImageUploadContainer = tw.div`
    flex items-center justify-between h-10 mt-2 mb-8
    rounded-md border
    bg-white lg:hover:bg-gray-50 lg:duration-200
    select-none
`

export const MyPageBusinessImageUploadButton = tw.button`
    flex-shrink-0
    bg-nightblue-500 text-white max-lg:px-4 lg:px-4 py-2 rounded-md pointer-cursor 
`

export const MyPageBusinessImageTitleContainer = tw.div`
    relative 
    flex items-center w-full max-w-full h-full
`

export const MyPageBusinessImageTitle = tw.span`
    w-full h-6 px-4 
    line-clamp-1 text-right
    select-none
`

export const BuisnessImage = tw.img`
    h-full object-contain
`

export const BuisnessImageHover = tw.div`
    absolute bottom-0 right-0 z-20
    max-md:h-48 md:h-64
    rounded bg-slate-100 ring-1 ring-slate-200
    shadow
    overflow-hidden

    ${(props) =>
        props.ishovershow ? "visible opacity-100 translate-y-[-3rem]" : "invisible opacity-0 translate-y-[-2rem]"}
    transform-gpu
    duration-100
`

export const MarketerWarnningMessage = tw.span`
    text-red-500
`
