import { authInstance, defaultInstance } from "../../utils"
import BoardNoticeList from "../../../types/notices/BoardNoticeList"
import BoardNotice from "../../../types/notices/BoardNotice"
import Board from "../../../types/boards/Board"

// 파일을 Board에 등록한다. (현재는 board.files에 등록할 파일을 넣으면 serializer에서 처리해준다.)
export const updateBoardFileIDs = async (boardID, fileIDs) => {
    let bResult = false

    if (!(boardID >= 0)) {
        return bResult
    }

    await authInstance.post(`api/boards/board_file_upload/`, {
        board_id: boardID,
        file_ids: fileIDs
    }).then((response)=>{
        console.log(response)
        bResult = true
    }).catch((error)=>{
        console.log(error)
    })

    return bResult
}

export const getBoardNotices = async (pageIndex, pageSize, search) => {
    let result = new BoardNoticeList()

    let params = {
        page: pageIndex,
        page_size: pageSize,
        search: search
    }

    const {data} = await defaultInstance.get(`api/boards/notice_list/`, {params: params})
    result.convertByResponse(data)

    return result
}

export const getBoardNotice = async (noticeID) => {
    let result = new BoardNotice()

    if (!(noticeID >= 0)) {
        return result
    }

    const {data} = await defaultInstance.get(`api/boards/notice_view/${noticeID}`)
    result.convertByResponse(data)

    return result
}


export const addBoardNotice = async (board) => {
    let result = new BoardNotice()
    
    const {data} = await authInstance.post(`api/boards/notice_create/`, {
        board: board.convertToRequest()
    })
    result.convertByResponse(data)

    return result
}

export const updateBoardNotice = async (noticeID, board) => {
    let result = new BoardNotice()

    if (!(noticeID >= 0)) {
        return result
    }
    
    const {data} = await authInstance.put(`api/boards/notice_view/${noticeID}`, {
        board: board.convertToRequest(),
        notice_id:noticeID
    })
    result.convertByResponse(data)

    return result
}

export const deleteBoardNotice = async (noticeID) => {
    let bResult = false

    if (!(noticeID >= 0)) {
        return bResult
    }

    await authInstance.delete(`api/boards/notice_view/${noticeID}`).then(()=>{
        bResult = true
    })

    return bResult
}