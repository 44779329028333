import React, { useEffect, useState } from "react"
import tw from 'tailwind-styled-components'
import {CircleCheck} from "@styled-icons/fa-solid/CircleCheck"
import {XCircle} from "@styled-icons/boxicons-solid/XCircle"
import {Close} from "@styled-icons/ionicons-solid/Close"

const Layout = tw.div`
absolute right-0
flex flex-col w-56 h-24 p-2
rounded-lg bg-white
drop-shadow-lg
`
const IconContainer = tw.div`
flex flex-center p-1 mr-1 w-10 h-8
`
const IconCheck = tw(CircleCheck)`
text-green-500 h-full py-0.5
`
const IconX = tw(Close)`
text-red-500 h-full
`
const Message = tw.span`
font-semibold
${(props) => props.isValidated ? "text-green-600" : "text-gray-500"}
`

const Validate = ({passWord, isValidated, setValidate, message, regex}) => {
    useEffect(()=>{
        setValidate(regex.test(passWord))
    }, [passWord])

    return (
        <div className="flex items-center w-full py-1">
            <IconContainer>
                {isValidated ? (<IconCheck />) : (<IconX />)}
            </IconContainer>
            <Message isValidated={isValidated}>
                {message}
            </Message>
        </div>
    )
}



export const TelValidate = ({tel, setValidate, top="-5rem", isShow}) => {
    const [valTextLength, setValTextLength] = useState(false)
    const [valSpecial, setValSpecial] = useState(false)
    useEffect(()=>{
        if (valSpecial) {
            setValidate(true)
        } else {
            setValidate(false)
        }
    }, [valTextLength, valSpecial])
    
    return (
        <Layout className={`top-[${top}] ${isShow ? "opacity-100 z-50" : "opacity-0 z-0"} w-auto h-14 `}>
            <Validate 
                passWord={tel} 
                isValidated={valSpecial} 
                setValidate={setValSpecial} 
                message={!valSpecial ? "유효하지 않은 전화번호 형식입니다." : "유효한 전화번호 형식입니다."}
                regex={/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/}
            />
        </Layout>
    )
}