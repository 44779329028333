import React, { useEffect, useState } from "react";
import { BrandItemInput, BrandItemTextArea } from "../../../components/brandItems/Inputs";
import { PerformValueInputTitle } from "../perform/MyPagePerformStyle";
import { CalcEditLayout } from "./MyPageCalcStyle";
import Utils from "../../../utils/Utils";
import { useUserStore } from "../../../zustand/store";
import { UserTypes } from "../../../types/users/UserTypes";


export default function MyPageCalcEdit({ isMobile = false, setIsOpen = null, handleDeleteCalc = null, calc = null, updateCalc, create = false, handleAddCalc = null, createGroupId = null }) {
    const { user } = useUserStore()
    const [name, setName] = useState("")
    const [desc, setDesc] = useState("")

    useEffect(() => {
        if (!create) {
            setName(calc.name)
            setDesc(calc.description)
        }
    }, [calc])

    const handleUpdate = () => {
        if (!create) {
            updateCalc(calc.id, name, desc)
        } else {
            if (Utils.isStringNullOrEmpty(name)) {
                alert('정산 이름을 입력 해주시기 바랍니다.')
            } else {
                handleAddCalc(createGroupId, name, desc)
            }
        }
    }

    return (
        <CalcEditLayout isMobile={isMobile}>
            <PerformValueInputTitle>
                <span>
                    {create ? "정산 등록" : "정산 수정"}
                </span>
            </PerformValueInputTitle>
            <div className="max-lg:flex-1 max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-1 lg:gap-4 py-4">
                <BrandItemInput
                    type="text"
                    label={"이름"}
                    data={name}
                    setData={setName}
                    widthFull
                    disabled={user.userType === UserTypes.MARKETER}
                />
                <BrandItemTextArea
                    type="text"
                    label={"내용"}
                    placeholder={"ex) 2023-03-10 부터 2023-04-10 까지"}
                    data={desc}
                    setData={setDesc}
                    widthFull
                    disabled={user.userType === UserTypes.MARKETER}
                />
            </div>
            {isMobile && !create && !(user.userType === UserTypes.MARKETER) ?
                <div className="w-full flex justify-between">
                    <button className="w-20 h-full py-3 text-red-400" onClick={() => { handleDeleteCalc(calc); setIsOpen(false) }}>
                        삭제
                    </button>
                    <button className="w-20 h-full py-3 bg-nightblue-500 text-white rounded-md" onClick={() => { handleUpdate(); setIsOpen(false) }}>
                        저장
                    </button>
                </div>
                :
                <>
                    {user.userType === UserTypes.MARKETER ?
                        <></>
                        :
                        <button className="max-lg:mb-2 lg:mt-4 px-4 max-lg:py-4 lg:py-2 bg-nightblue-500 text-white rounded-md" onClick={() => { handleUpdate() }}>
                            저장
                        </button>
                    }
                </>
            }
        </CalcEditLayout>
    )
}