import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { PmsOfficeNavState } from "../../../types/DataEnum"
import { MarketerTableDataList } from "../../../constants/ConstMarketer"
import { Link, useParams } from "react-router-dom"
import { ModalContainer } from "../../../components/ModalContainer"
import { GroupMarketerState } from "../../../types/DataEnum"
import { ContentContainer } from "../MyPageMainStyle"
import { Pagination } from "../../../components/Pagination"
import {
    PerformListControlBox,
    PerformListControlSection,
    PerformListControlSectionLast,
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSearchButton,
    PerformTableSearchIcon,
} from "../perform/MyPagePerformStyle"
import { PaginationStyles } from "../../../types/StyleTypes"
import { MarketerSubControlBox, MarketerToggleButton } from "../marketer/MyPageMarketerStyle"
import MyPageGroupGrid from "./MyPageGroupGrid"
import {
    addItemMarketerGroup,
    deleteItemMarketerInvite,
    getItemMarketerGroups,
    getItemMarketerInviteds,
} from "../../../apis/api/boardItems"
import MyPageGroupMarketerList from "./MyPageGroupMarketerList"
import BrandItemMarketerGroup from "../../../types/brandItems/marketers/BrandItemMarketerGroup"
import { useUserStore } from "../../../zustand/store"
import { WriteTypes } from "../../../types/EditorTypes"
import AccessRules from "../../../rules/AccessRules"
import { FilterInputText } from "../../../components/brandItems/FilterInputs"
import MyPageGroupInviteList from "./MyPageGroupInviteList"

const MAX_PAGE_LENGTH = 5

export default function MyPageGroupBrand() {
    const { user } = useUserStore()

    const { itemID } = useParams()
    const [sortvisible, setSortvisible] = useState(0)

    const [groupShow, setGroupShow] = useState(false)
    const [groupMarketerListShow, setGroupMarketerListShow] = useState(false)
    const [groupMarketerListType, setGroupMarketerListType] = useState(WriteTypes.READ) // Read일 경우 마케터 리스트, Write일 경우 마케터 초대 리스트

    const [marketerHasGroup, setMarketerHasGroup] = useState(true) // 마케터가 그룹에 속해있는지 여부 (혹시 찰나에 그룹 추가나 다른 이벤트를 방지하기 위해 true를 기본값으로 준다.)
    const [selectedGroup, setSelectedGroup] = useState(new BrandItemMarketerGroup())
    const [search, setSearch] = useState("")

    const [marketerGroups, setMarketerGroups] = useState([])

    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    useEffect(() => {
        getMarketerGroups()
    }, [itemID])

    const getMarketerGroups = async () => {
        const response = await getItemMarketerGroups(itemID, pageIndex, pageSize, search)
        if (response !== undefined) {
            setMarketerGroups(response.items)
            setItemCount(response.count)
            setMarketerHasGroup(response.count > 0)
        } else {
            setMarketerHasGroup(false)
        }
    }

    const handleSearchMarketer = () => {}

    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }
    const handlePageIndex = (page) => {}

    const handleOpenMarketerList = (group) => {
        setSelectedGroup(group)
        setGroupMarketerListType(WriteTypes.READ)
        setGroupMarketerListShow(true)
    }

    return (
        <div className="flex flex-col w-full h-full">
            <PerformListControlBox className="max-lg:h-[5rem]">
                <PerformListControlSection className="max-lg:mb-2">
                    <FilterInputText
                        value={search}
                        setValue={setSearch}
                        submit={() => {
                            handleSearchMarketerEnter()
                        }}
                        width={"52"}
                        placeholder={"그룹"}
                    />
                </PerformListControlSection>

                <PerformListControlSectionLast last>
                    <div className="h-8">
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={MAX_PAGE_LENGTH}
                            styleType={PaginationStyles.NoneBGCircleButtons}
                        />
                    </div>
                    <div className="max-lg:hidden lg:w-2"></div>
                    {/* <div className="flex flex-1 justify-end">
                        <Link to={`/mypage/perform/marketer/${itemID}`}>
                            <MarketerToggleButton marketerstate={1}>마케터 관리</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/perform/group/${itemID}`}>
                            <MarketerToggleButton marketerstate={0}>그룹 관리</MarketerToggleButton>
                        </Link>
                    </div> */}
                </PerformListControlSectionLast>
            </PerformListControlBox>
            <MarketerSubControlBox></MarketerSubControlBox>

            <div className="w-full h-full relative">
                <MyPageGroupInviteList marketerGroups={marketerGroups} openMarketerList={handleOpenMarketerList} />
            </div>

            <ModalContainer
                show={groupMarketerListShow}
                setShow={setGroupMarketerListShow}
                isBlur={true}
                fadeDown={true}
                closeByBackground
            >
                <MyPageGroupMarketerList
                    itemID={itemID}
                    marketerGroup={selectedGroup}
                    groupMarketerListType={groupMarketerListType}
                />
            </ModalContainer>
        </div>
    )
}
