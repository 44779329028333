import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"

import {
    PerformConInner,
    PerformTableRowLayout,
    PerformUpdateColumnLayout,
    PerformUpdateColumnTitle,
    PerformUpdateControlBox,
    PerformUpdateLayout,
    PerformUpdateValueList,
    PerformValueFileDownloadZip,
    PerformValueInputLayout,
    PerformValueInputTitle,
} from "./MyPagePerformStyle"
import { PerformColumnFormTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { PerformValueComboCell } from "./PerformValueComboCell"
import { PerformValueFileCell } from "./PerformValueFileCell"
import { PerformValueTextCell } from "./PerformValueTextCell"
import { PerformValueStatusCell } from "./PerformValueStatusCell"
import { PerformValueCheckBoxCell } from "./PerformValueCheckBoxCell"
import { getPerformEmptyValue } from "../../../utils/brandItem/BrandItemPerformUtils"
import { PerformValueFixedDateCell } from "./PerformValueFixedDateCell"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import { PerformValueComboUpdate } from "./PerformValueComboUpdate"
import { DeleteButton, SaveButton } from "../item/brand/ItemInputFormStyle"
import { PerformValueTextUpdate } from "./PerformValueTextUpdate"
import { PerformValueStatusUpdate } from "./PerformValueStatusUpdate"
import { PerformValueFileUpdate, PerformValueFileUpdateNonPerform } from "./PerformValueFileUpdate"
import { EditStateTypes, QueryTypes } from "../../../types/EditorTypes"
import {
    createItemPerform,
    deleteItemPerform,
    deleteItemPerformValue,
    updateItemPerform,
    updateItemPerformValue,
    updatePerformColumnFileEditState,
} from "../../../apis/api/boardItems"
import { PerformValueOptionUpdate } from "./PerformValueOptionUpdate"
import { PerformValueDateUpdate } from "./PerformValueDateUpdate"
import { PerformValueFilePreUpdate } from "./PerformValueFilePreUpdate"
import { UserTypes } from "../../../types/users/UserTypes"
import AccessRules from "../../../rules/AccessRules"
import MyPagePerformUpdateMarketerSearch from "./MyPagePerformUpdateMarketerSearch"
import Utils from "../../../utils/Utils"

const PerformColumnComponent = ({ perform, performValue, showViewerComponent, readOnly }) => {
    const column = performValue.column
    const [downLoadZip, setDownLoadZip] = useState(false)

    return (
        <PerformUpdateColumnLayout readOnly={readOnly}>
            <PerformUpdateColumnTitle>
                {column.name}
                {column.formType === PerformColumnFormTypes.FILE && perform.id >= 0 && (
                    <PerformValueFileDownloadZip
                        onClick={() => {
                            setDownLoadZip(true)
                        }}
                    >
                        일괄 다운로드
                    </PerformValueFileDownloadZip>
                )}
            </PerformUpdateColumnTitle>
            {column !== null && (
                <React.Fragment>
                    {column.formType === PerformColumnFormTypes.COMBO && (
                        <PerformValueComboUpdate perform={perform} performValue={performValue} readOnly={readOnly} />
                    )}
                    {column.formType === PerformColumnFormTypes.TEXT && (
                        <PerformValueTextUpdate perform={perform} performValue={performValue} readOnly={readOnly} />
                    )}
                    {column.formType === PerformColumnFormTypes.STATUS && (
                        <PerformValueStatusUpdate perform={perform} performValue={performValue} />
                    )}
                    {column.formType === PerformColumnFormTypes.FILE && perform.id >= 0 && (
                        <PerformValueFileUpdate
                            downLoadZip={downLoadZip}
                            setDownLoadZip={setDownLoadZip}
                            perform={perform}
                            performValue={performValue}
                            showViewerComponent={showViewerComponent}
                            readOnly={readOnly}
                        />
                    )}
                    {column.formType === PerformColumnFormTypes.FILE && perform.id < 0 && (
                        <PerformValueFilePreUpdate performValue={performValue} />
                    )}
                    {column.formType === PerformColumnFormTypes.OPTION && column.id >= 0 && (
                        <PerformValueOptionUpdate perform={perform} performValue={performValue} readOnly={readOnly} />
                    )}
                    {column.formType === PerformColumnFormTypes.DATE && column.id >= 0 && (
                        <PerformValueDateUpdate perform={perform} performValue={performValue} readOnly={readOnly} />
                    )}
                    {/* {column.formType === PerformColumnFormTypes.COMBO && (<PerformValueComboCell column={column} value={value} />)}
                    {column.formType === PerformColumnFormTypes.CHECK_BOX && (<PerformValueCheckBoxCell column={column} value={value} />)}
                {column.formType === PerformColumnFormTypes.DATE && (<PerformValueFixedDateCell column={column} value={value} />)} */}
                </React.Fragment>
            )}
        </PerformUpdateColumnLayout>
    )
}

export default function MyPagePerformUpdate({
    user,
    selectedItem,
    columns,
    perform,
    isShow,
    close,
    showViewerComponent,
}) {
    const [writeType, setWriteType] = useState(QueryTypes.CREATE)
    const [performValues, setPerformValues] = useState([])

    const [marketerID, setMarketerID] = useState(-1)
    const [readOnly, setReadOnly] = useState(true)

    useEffect(() => {
        if (AccessRules.performCreate(user)) {
            setMarketerID(user.id)
        } else {
            if (perform.id >= 0) {
                setMarketerID(perform.marketer.user.id)
            }
        }

        setReadOnly(perform.isReadOnly(user) || selectedItem.editStateType !== EditStateTypes.WAIT)
    }, [user, perform])

    useEffect(() => {
        if (!isShow) {
            editCancle()
        }
    }, [isShow])

    useEffect(() => {
        const _writeType = perform.id >= 0 ? QueryTypes.UPDATE : QueryTypes.CREATE
        setWriteType(_writeType)

        let _values = []
        columns.map((column) => {
            if (column.id < 0) {
                return
            }

            // 실적 추가일 때 컬럼이 추가 모달에서 설정가능한지 확인
            if (_writeType === QueryTypes.CREATE && !column.isBaseAdd) {
                return
            }
            // 실적 수정일 때 컬럼이 수정 모달에서 설정가능한지 확인
            if (_writeType == QueryTypes.UPDATE && !column.isBaseEdit) {
                return
            }

            // 읽기 모드에서는 현황은 확인하지 않는다.
            if (column.formType === PerformColumnFormTypes.STATUS && (readOnly || _writeType === QueryTypes.UPDATE)) {
                return
            }

            // 권한이 없는 경우 저장하지 않는다.
            if (!readOnly && column.marketerReadOnly) {
                let isExcept = false
                // 단 새로 생성하는 실적이고 상태 컬럼은 무조건 기본값을 추가한다.
                if (_writeType && QueryTypes.CREATE && column.formType === PerformColumnFormTypes.STATUS) {
                    isExcept = true
                }

                if (!isExcept) {
                    return
                }
            }

            let performValue = perform.values.find((pv) => pv.columnID === column.id)

            if (performValue !== undefined) {
                performValue.column = column
                // 파일 타입인 경우 FileID에 업로드된 파일 개수를 넣는다. (FileID를 사용하지 않기 때문)
                if (column.formType === PerformColumnFormTypes.FILE) {
                    performValue.fileID = perform.values.reduce(
                        (cnt, element) => cnt + (column.id === element.columnID),
                        0,
                    )
                }
                _values.push(performValue)
            } else {
                // 실적에 컬럼에 해당하는 값이 없으면 빈 값을 만들어준다.
                let performValue = getPerformEmptyValue(column)

                if (performValue !== null) {
                    performValue.column = column
                    performValue.columnID = column.id
                    performValue.performID = perform.id

                    if (column.formType === PerformColumnFormTypes.STATUS && column.statuses.length > 0) {
                        performValue.statusID = column.statuses[0].id
                    }
                    _values.push(performValue)
                }
            }
        })

        setPerformValues(_values)
    }, [columns, perform])

    const handleSave = async () => {
        let requests = 0 // 요청 횟수
        let successes = 0 // 요청 성공 횟수

        let performID = perform.id

        if (writeType === QueryTypes.CREATE) {
            const perform = await createItemPerform(selectedItem.id, marketerID)

            if (perform.id >= 0) {
                performID = perform.id
            } else {
                submit(false)
                return
            }
        } else {
            const updated = await updateItemPerform(perform.id, selectedItem.id, marketerID)

            if (!updated) {
                submit(false)
                return
            }
        }

        await Promise.all(
            performValues.map(async (performValue) => {
                let requestValue = null
                if (performValue.column.formType === PerformColumnFormTypes.COMBO && performValue.comboID >= 0) {
                    requestValue = { combo: performValue.comboID }
                } else if (
                    writeType === QueryTypes.CREATE &&
                    performValue.column.formType === PerformColumnFormTypes.STATUS &&
                    performValue.statusID >= 0
                ) {
                    requestValue = { status: performValue.statusID }
                    // return
                } else if (performValue.column.formType === PerformColumnFormTypes.TEXT) {
                    requestValue = { text: performValue.text }
                } else if (
                    performValue.column.formType === PerformColumnFormTypes.FILE &&
                    writeType === QueryTypes.CREATE
                ) {
                    // 업로드한 파일 확정
                    await updatePerformColumnFileEditState(
                        performValue.column.id,
                        performID,
                        EditStateTypes.DONE,
                        performValue.files,
                    )
                } else if (
                    performValue.column.formType === PerformColumnFormTypes.FILE &&
                    writeType === QueryTypes.UPDATE
                ) {
                    // 업로드한 파일 확정
                    await updatePerformColumnFileEditState(performValue.column.id, performID, EditStateTypes.DONE)
                } else if (performValue.column.formType === PerformColumnFormTypes.OPTION) {
                    let optionItemDic = {}
                    performValue.optionItems.map((optionItem) => {
                        optionItemDic[optionItem.optionID] = optionItem
                    })
                    requestValue = { option_json: JSON.stringify(optionItemDic) }
                } else if (performValue.column.formType === PerformColumnFormTypes.DATE) {
                    requestValue = { timestamp: performValue.timeStamp, memo: performValue.memo }
                }
                if (requestValue !== null) {
                    requests += 1
                    await updateItemPerformValue(performValue.columnID, performID, requestValue)
                        .then(async (response) => {
                            if (response) {
                                successes += 1
                            }
                        })
                        .catch((error) => {})
                }
            }),
        )

        submit(successes === requests, performID)
    }

    const editCancle = async () => {
        await Promise.all(
            performValues.map(async (performValue) => {
                if (performValue.column.formType === PerformColumnFormTypes.FILE) {
                    // 업로드한 파일 취소 및 삭제
                    await updatePerformColumnFileEditState(performValue.column.id, perform.id, EditStateTypes.DISABLED)
                }
            }),
        )
    }

    const submit = (bResult, performID) => {
        if (bResult) {
            alert(`${writeType === QueryTypes.UPDATE ? "수정" : "등록"}되었습니다.`)

            close(true)
        } else {
            alert(`${writeType === QueryTypes.UPDATE ? "수정" : "등록"} 실패 했습니다.`)
            if (writeType === QueryTypes.CREATE && !Utils.isNullOrUndefined(performID)) {
                deleteItemPerform(performID)
            }
        }
    }

    const handleDelete = () => {
        if (confirm("실적을 삭제하시겠습니까?\n승인되지 않은 실적만 삭제가 가능합니다.")) {
            deleteItemPerform(perform.id)
                .then((response) => {
                    if (response) {
                        alert("실적이 삭제되었습니다.")
                        close(true)
                    } else {
                        alert("삭제 실패했습니다.")
                    }
                })
                .catch((error) => {
                    alert("삭제 실패했습니다.")
                })
        }
    }

    return (
        <PerformUpdateLayout>
            <PerformValueInputTitle>
                <span>실적 {readOnly ? "" : writeType === QueryTypes.UPDATE ? "수정" : "등록"}</span>
            </PerformValueInputTitle>
            <PerformUpdateValueList>
                {AccessRules.performManagement(selectedItem, user) && !readOnly && (
                    <MyPagePerformUpdateMarketerSearch
                        itemID={selectedItem.id}
                        marketerID={marketerID}
                        setMarkerterID={setMarketerID}
                        initSearch={perform.itemMarketer.marketerName}
                        performID={perform.id}
                    />
                )}
                {isShow &&
                    performValues.map((performValue, idx) => (
                        <PerformColumnComponent
                            perform={perform}
                            performValue={performValue}
                            showViewerComponent={showViewerComponent}
                            readOnly={readOnly}
                            key={idx}
                        />
                    ))}
            </PerformUpdateValueList>
            {!readOnly && (
                <PerformUpdateControlBox>
                    {writeType === QueryTypes.UPDATE ? (
                        <DeleteButton
                            onClick={() => {
                                handleDelete()
                            }}
                        >
                            실적 삭제
                        </DeleteButton>
                    ) : (
                        <p></p>
                    )}
                    <SaveButton
                        onClick={() => {
                            handleSave()
                        }}
                    >
                        {writeType === QueryTypes.UPDATE ? "저장" : "등록"}
                    </SaveButton>
                </PerformUpdateControlBox>
            )}
        </PerformUpdateLayout>
    )
}
