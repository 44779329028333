import styled from "styled-components"
import tw from "tailwind-styled-components"
import { BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"
import { EditStateTypes, EmailDuplicatedTypes } from "../../types/EditorTypes"
import { Person } from "@styled-icons/bootstrap/Person"
import { Buildings } from "@styled-icons/boxicons-regular/Buildings"
import { ManageAccounts } from "@styled-icons/material/ManageAccounts"
import { Circle } from "@styled-icons/boxicons-solid/Circle"
import { CheckCircle } from "@styled-icons/boxicons-solid/CheckCircle"
import { MyPageInputModalLayout } from "../myPage/MyPageMainStyle"

export const CircleIcon = tw(Circle)`
    h-full
`
export const CheckCircleIcon = tw(CheckCircle)`
    h-full
`

export const SignUpLayout = tw(BoxMaxMin)`
    flex flex-col items-center w-full h-auto    
`

// 높이는 screen - footer 크기
export const SignUpTypeCol = tw(ContainerPaddingTop)`
    flex flex-col justify-center items-center w-full md:h-[calc(100vh-0rem)]
    max-md:bg-gray-100 lg:px-40
`

export const SignUpTypeItems = tw.div`
    flex items-center w-full max-w-[720px] max-sm:p-4 sm:p-10 flex-col
`

const SignUpTypeItem = tw.button`
    flex w-36 h-36
    justify-center items-center
    text-3xl text-white
    rounded-full
    hover-scale
`

export const SignUpTypeBrand = tw(SignUpTypeItem)`
    bg-red-500 shadow-2xl shadow-red-500
`

export const SignUpTypeMarketer = tw(SignUpTypeItem)`
    bg-blue-500 shadow-2xl shadow-blue-500
`

export const SignUpTypeStaff = tw(SignUpTypeItem)`
    bg-purple-500 shadow-2xl shadow-purple-500
`

export const SignUpUserLayout = tw.div`
    flex flex-col w-128 h-full px-8 py-8 my-16
    rounded-lg shadow-2xl 
`

export const SignUpUserTitle = tw.span`
    px-2
    max-md:text-xl md:text-2xl font-bold text-nightblue-800
    max-md:w-full
`

export const SignUpInfoCol = tw.div`
    flex flex-col w-full my-4
`

export const SignUpInfoTitle = tw.span`
    px-2 pb-1 mb-2
    text-lg font-semibold
    border-b-2 border-black
`
export const SignUpInfoInputCol = tw.div`
    flex flex-col px-2
`

export const SignUpCommitButton = tw.button`
    m-2 px-4 py-1
    font-semibold text-white
    bg-sky-500 hover:bg-sky-400 rounded-lg
    shadow-sm hover:shadow-lg
    duration-300
`

export const EmailDuplicatedButton = tw.button`
    ml-4 px-3 py-1
    text-sm 
    border rounded-lg
    duration-200
    
    text-gray-700 bg-white border-gray-500
    ${(props) =>
        props.emailDuplcatedState === EmailDuplicatedTypes.WAIT &&
        "text-sky-700 bg-white hover:bg-sky-100 border-sky-500"}
    ${(props) =>
        props.emailDuplcatedState === EmailDuplicatedTypes.DONE &&
        "text-green-700 bg-white hover:bg-green-100 border-green-500"}
    ${(props) =>
        props.emailDuplcatedState === EmailDuplicatedTypes.FAILED &&
        "text-red-700 bg-white hover:bg-red-100 border-red-500"}
`

export const SignUpTelButton = tw.button`
    ml-4 px-3 py-1
    text-sm 
    border rounded-lg
    duration-200

    text-gray-700 bg-white border-gray-500
    ${(props) => props.edit_state === EditStateTypes.WAIT && "text-white bg-sky-600 hover:bg-sky-500 border-sky-600"}
    ${(props) =>
        props.edit_state === EditStateTypes.PENDING && "text-yellow-700 bg-white hover:bg-yellow-100 border-yellow-500"}
    ${(props) =>
        props.edit_state === EditStateTypes.DONE && "text-green-700 bg-white hover:bg-green-100 border-green-500"}
    ${(props) => props.edit_state === EditStateTypes.FAILED && "text-red-700 bg-white hover:bg-red-100 border-red-500"}
`

export const SignUpTelMessageBox = tw.div`
absolute grid justify-items-end w-full -top-5 
text-xs text-red-500
`

export const SignUpRecommanderButton = tw.button`
    ml-4 px-3 py-1
    text-sm 
    border rounded-lg
    duration-200

    text-sky-700 bg-white hover:bg-sky-100 border-sky-500
`

export const StepsLayout = tw.div`
    flex items-center justify-between w-full h-24 p-2
`

export const StepsContainer = tw.div`
    flex w-full items-center
`

export const StepLine = tw.div`
    flex-1 h-1 mt-[0.175rem]
    ${(props) => (props.isorderd ? "bg-gradient-to-r from-nightblue-600 to-gray-400 to-80%" : "bg-gray-400")}
`

export const StepNumer = tw.span`
    pt-2 font-semibold
    ${(props) => (props.isorderd ? "text-nightblue-600" : "text-gray-600")}
`

export const StepTitle = tw.span`
    max-md:text-xs md:text-lg font-semibold mb-4
    ${(props) => (props.isorderd ? "text-nightblue-600" : "text-gray-600")}
`

export const SignUpUserMarketerLayout = tw(ContainerPaddingTop)`
    flex justify-center items-center w-screen h-auto max-lg:px-2
`

export const SignUpUserMarketerContainer = tw.div`
    flex flex-col items-center w-full h-auto max-w-[48rem] pt-10 pb-8
    max-md:px-2 md:px-8
    bg-white
    md:rounded-lg md:drop-shadow
`

export const SignUpStepContainer = tw.div`
    w-full  mt-5 mb-5 max-sm:px-2 sm:px-2
`

export const SignUpMainContainer = tw.div`
    relative 
    w-full h-auto mt-2
`

export const SignUpBasicInformationContainer = tw.div`
    ${(props) => (props.isStep ? `opacity-100 z-20` : `opacity-0 -z-10`)}
    w-full h-full absolute bg-white
`

export const SignUpBackButton = tw.button`
    w-24 h-full max-md:py-1 md:py-2 pr-4  
    ${(props) => (props.isdisabled ? "text-gray-400" : "text-nightblue-600 hover:text-nightblue-400")}
`
export const SignUpButton = tw.button`
    w-24 h-full max-md:py-1 md:py-2 px-4  
    text-white
    rounded-md
    ${(props) => (props.isdisabled ? "bg-gray-300" : "bg-nightblue-500 hover:bg-nightblue-600")}
    duration-200
`

export const SignUpButtonContainer = tw.div`
    max-md:sticky max-md:z-50 max-md:bottom-4
    flex items-end w-full h-20
`

export const SignUpButtonBox = tw.div`
    flex justify-between items-center w-full max-lg:h-12 max-lg:px-1 max-lg:py-1
    max-md:bg-white max-md:rounded-lg max-md:drop-shadow-lg
    max-md:border
`

export const SignUpTypeCardGridContainer = tw.div`
    flex max-sm:flex-col sm:flex-row w-full mb-8 max-sm:space-y-4 sm:space-x-4
    justify-around
`
export const SignUpTypeCardConTainer = tw.div`
    group
    flex flex-col justify-center items-center max-sm:w-full sm:w-1/2 h-[24rem]
    rounded-md bg-white
    shadow-md hover:shadow-xl border-nightblue-200
    cursor-pointer
    overflow-hidden
    duration-300
`
export const PersonIcon = tw(Person)`
`
export const BuildingsIcon = tw(Buildings)`
`
export const ManageAccountsIcon = tw(ManageAccounts)`
`
export const SignUpTypeCardIconBox = tw.div`
    group
    flex flex-center h-16 aspect-square
    text-nightblue-500 group-hover:text-nightblue-600
    duration-300
    [&>svg]:h-full
    max-sm:[&>img]:h-20 sm:[&>img]:h-full
`

export const SignUpTypeCardContentBox = tw.div`
    flex flex-col w-full h-full py-5 pr-5 max-sm:pl-5
`
export const SignUpTypeCardDescriptionBox = tw.div`
    flex items-center max-h-full px-4 my-auto
`
export const SignUpTypeCardDescriptionPre = tw.pre`
    text-gray-500 text-base
`
export const SignUpTypeCardTitleBox = tw.div`
    flex items-center pt-16 space-x-4
`
export const SignUpTypeCardTitle = tw.span`
    font-bold text-2xl text-nightblue-700
`
export const SignUpTypeCardSubTitle = tw.span`
    text-nightblue-500 max-sm:text-base sm:text-lg
    duration-300
`

export const SignUpTitleContainer = tw.div`
    w-full flex items-center max-lg:mt-0 lg:mt-8 max-lg:mb-10 lg:mb-10
`

export const SignUpTitleText = tw.div`
    font-bold max-lg:text-2xl lg:text-3xl text-nightblue-800
`

export const SignUpTitleUnderLine = tw.div`
    w-28 h-1 mt-1 bg-nightblue-600
`

export const SignUpBuisnessButton = tw.button`
    py-1 px-2  rounded-md hover:bg-sky-100
    text-sm text-nightblue-700
`
export const SignUpBuisnessPreview = tw.div`
     ${(props) => (props.isshow ? "hidden" : "visible")}
    text-gray-500 text-sm py-1 px-2 rounded-md mx-4 select-none cursor-pointer
`

export const BuisnessImage = tw.img`
    h-full object-contain
`

export const BuisnessImageHover = tw.div`
    absolute bottom-0 h-40 border 
    
    ${(props) => (props.isshow ? "visible opacity-100 translate-y-[-3rem]" : "invisible opacity-0 translate-y-[-2rem]")}
    transform-gpu
    duration-100
`

export const SignInputDescriptionBox = tw.div`
    flex justify-between w-full px-1
`
export const SignInputDescription = tw.span`
    text-sm text-nightblue-600
`

export const SignUpRecommanderModalLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen sm:w-[24rem] max-lg:h-full lg:max-h-[80vh]
    max-sm:rounded-none max-md:rounded-xl md:rounded-xl p-6
    duration-300
`
