import tw from "tailwind-styled-components"
import styled from "styled-components"
import { MyPageContentRowHeight } from "../MyPageMainStyleParameter"
import { ListControlItem, MyPageInputModalLayout } from "../MyPageMainStyle"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { ArrowCounterclockwise } from "@styled-icons/bootstrap/ArrowCounterclockwise"
import { BoardImportnaceStatusTypes } from "../../../types/boards/BoardTypes"

export const NoticeTitle = tw.div`
    flex flex-center w-full max-md:min-h-[4rem] md:min-h-[8rem]
    font-semibold text-gray-800 max-md:text-2xl md:text-3xl
`
export const NoticeBody = tw.div`
    h-full w-full flex flex-col items-center
`
export const NoticeTable = tw.div`
    w-full h-full 
`

export const NoticeTableHeaderBox = tw.div`
    w-full flex border-b-2 border-gray-600
`

export const NoticeTableHeader = tw.div`
    flex flex-center h-10 font-semibold max-lg:text-md text-gray-600 text-ellipsis overflow-hidden
`

export const NoticeListBody = tw.div` 
    w-full h-full flex flex-col
`

export const NoticeListRow = tw.div`
    flex w-full items-center py-2
    border-b border-gray-300 cursor-pointer
    hover:bg-slate-150
    ${(props) => (props.importance === BoardImportnaceStatusTypes.IMPORTANT ? " bg-rose-50 hover:bg-rose-100" : "")}
`

export const NoticeListData = tw.div`
    flex max-sm:items-center sm:items-center justify-center h-full px-2 max-lg:text-sm lg:text-base
`

export const NoticePagination = tw.div`
    w-full h-9 flex items-center justify-between mt-6
`

export const NoticeAddButton = tw(ListControlItem)`
    px-5 bg-nightblue-400 py-2 text-white rounded-lg 
    cursor-pointer
`

export const NoticeEditEditContainer = tw(MyPageInputModalLayout)`
    bg-white rounded flex flex-col
`
export const NoticeEditTitleContainer = tw.div`
    flex items-center w-full my-2
`
export const NoticeEditTitleInput = tw.input`
px-2 py-1 bg-gray-100 rounded-lg w-full
focus:ring-2 focus:ring-blue-300 focus:bg-blue-50
duration-200
`
export const NoticeEditEditorContainer = tw.div`
    w-full h-full relative
`

export const NoticeEditControlBox = tw.div`
    w-full flex justify-end py-1
`

export const NoticeEditSubmitButton = tw.button`
    px-4 py-1
    text-medium text-nightblue-700
    rounded-lg hover:bg-nightblue-100
`

export const NoticeSearchContainer = tw.div`
    h-10 py-1 flex items-center justify-end mb-4
`

export const NoticeEditFileContainer = tw.div`
    flex flex-col flex-shrink-0 w-full h-32 mt-2 relative
`
export const NoticeNoticeSearchInput = tw.input`
    h-full bg-gray-75 rounded-md px-2
    outline-0 focus:outline-1 outline-gray-200 focus:bg-white
`
export const NoticeSearchButton = tw.button`
    px-2 py-1 bg-gray-50 h-full ml-2 text-blue-400 rounded-md hover:bg-white
`

export const NoticeResetButton = tw.button`
    px-2 py-1 bg-gray-50 h-full ml-2 text-red-400 rounded-md hover:bg-white
`

export const SearchIcon = tw(Search)`   
    h-full mb-1
`

export const ResetIcon = tw(ArrowCounterclockwise)`
    h-full mb-1
`
