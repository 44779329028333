import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import Utils from "../../../utils/Utils"
import { SaveButton } from "../item/brand/ItemInputFormStyle"
import { PerformDateCalc, PerformTextBox, PerformValueBox, PerformValueInputTitle } from "./MyPagePerformStyle"
import {
    DateControlBox,
    PerformDateInputBox,
    PerformDateInputLayout,
    PerformDateMemoInputBox,
    PerformDateTimeMemoInput,
    PerformDateTimeSubTitle,
} from "./PerformValueDateStyle"
import { DateTimeInput } from "../../../components/inputs/DateTimeInput"
import { updateItemPerformValue } from "../../../apis/api/boardItems"
import dayjs from "dayjs"
import AccessRules from "../../../rules/AccessRules"

const DateEdit = ({ column, perform, value, closeEditComponent }) => {
    const [timeStamp, setTimeStamp] = useState(null) // 저장된 혹은 저장한 시간 값
    const [timeStampValue, setTimeStampValue] = useState(null) // 수정 중인 시간 값

    const [memo, setMemo] = useState("")

    useEffect(() => {
        setTimeStamp(value.timeStamp)
        setMemo(value.memo)
    }, [value])

    const handleSave = () => {
        updateItemPerformValue(column.id, perform.id, { timestamp: timeStampValue, memo: memo }).then((response) => {
            if (response) {
                closeEditComponent(true)
                setTimeStamp(timeStampValue)
            } else {
                alert("수정 실패했습니다.")
            }
        })
    }

    return (
        <div className="flex flex-col h-full overflow-y-auto scroll-transparent scroll-overlay">
            <PerformValueInputTitle>
                <span>{column.name}</span>
            </PerformValueInputTitle>
            <PerformDateInputBox>
                <DateTimeInput timeStamp={timeStamp} setTimeStamp={setTimeStampValue} />
            </PerformDateInputBox>
            <PerformDateTimeSubTitle>메모</PerformDateTimeSubTitle>
            <PerformDateTimeMemoInput
                value={memo}
                onChange={(e) => {
                    setMemo(e.target.value)
                }}
            />
            {/* <PerformDateMemoInputBox>
            </PerformDateMemoInputBox> */}
            <DateControlBox>
                <SaveButton
                    onClick={() => {
                        handleSave()
                    }}
                >
                    저장
                </SaveButton>
            </DateControlBox>
        </div>
    )
}

export const PerformValueDateCell = ({ column, perform, value, setEditComponent }) => {
    const [dateTimeString, setDateTimeString] = useState("")

    const [showEditComponent, closeEditComponent] = setEditComponent

    useEffect(() => {
        const timeStamp = value.timeStamp

        if (timeStamp !== null && timeStamp > 0) {
            const columnDate = column.dates.find((s) => s.columnID === column.id)
            const dateFormat = columnDate.dateFormat

            setDateTimeString(dayjs(timeStamp).format(dateFormat))
            // setDateTimeString(date.toLocaleString(dateFormat))
        }
    }, [value])

    const handleShowEdit = () => {
        showEditComponent(
            <PerformDateInputLayout className="justify-between bg-white">
                <DateEdit column={column} perform={perform} value={value} closeEditComponent={closeEditComponent} />
            </PerformDateInputLayout>,
        )
    }

    return (
        <PerformValueBox>
            {AccessRules.performValueWrite(column, perform) ? (
                <div className="flex justify-between items-center w-full group">
                    <PerformTextBox
                        isButtonCell={true}
                        onClick={() => {
                            handleShowEdit()
                        }}
                    >
                        <PerformDateCalc>
                            {Utils.isStringNullOrEmpty(dateTimeString) ? "-" : dateTimeString}
                        </PerformDateCalc>
                    </PerformTextBox>
                </div>
            ) : (
                <PerformTextBox>
                    <span className="px-1 truncate">
                        {Utils.isStringNullOrEmpty(dateTimeString) ? "-" : dateTimeString}
                    </span>
                </PerformTextBox>
            )}
        </PerformValueBox>
    )
}
