export const BoardStatusTypes = {
    VISIBLE: 0,
    INVISIBLE: 1,
    BLOCK: 2,
    TEMPORARY: 3,
}

export const BoardImportnaceStatusTypes = {
    DEFAULT:0,
    IMPORTANT:1,
}