import React, { useState, useRef, useCallback } from "react"
import { ItemApplyContainer, ItemApplyLayout } from "./ItemApplyStyle"
import {
    SignUpBackButton,
    SignUpBasicInformationContainer,
    SignUpButton,
    SignUpButtonBox,
    SignUpButtonContainer,
    SignUpStepContainer,
    SignUpUserTitle,
} from "../user/SignUpStyle"
import { ItemApplySteps } from "./ItemApplySteps"
import ItemApply from "./ItemApply"
import MyPageMarketerDetailModal from "../../components/mypage/marketer/MyPageMarketerDetailModal"
import { MyInfoMarketerMore } from "../myPage/myinfo/MyInfoMarketerMore"
import MarketerDetail from "../../components/user/MarketerDetail"
import ItemApplyMarketerDetail from "./ItemApplyMarketerDetail"
import Utils from "../../utils/Utils"

export default function ItemApplyPage() {
    const [stepIndex, setStepIndex] = useState(0)

    const [submitFlag, setSubmitFlag] = useState(false) // 1차에서 마케터 정보 저장용
    const [isAppliable, setAppliable] = useState(false)

    const itemApplyRef = useRef(null)

    const handleApply = () => {
        if (Utils.isNullOrUndefined(itemApplyRef.current)) {
            return
        }

        itemApplyRef.current.handleApply()
    }

    return (
        <ItemApplyContainer className={`flex justify-center items-center`}>
            <ItemApplyLayout>
                <SignUpUserTitle>영업 지원하기</SignUpUserTitle>
                <SignUpStepContainer className="max-sm:px-6 sm:px-16">
                    <ItemApplySteps stepindex={stepIndex} isBrand={true} />
                </SignUpStepContainer>
                <div className="flex flex-col w-full">
                    {stepIndex === 0 && (
                        <>
                            <ItemApplyMarketerDetail
                                submitFlag={submitFlag}
                                setSubmitFlag={setSubmitFlag}
                                nextStep={() => {
                                    setStepIndex(1)
                                }}
                            />

                            <SignUpButtonContainer className={`mt-auto md:z-50 max-md:bottom-0 p-8`}>
                                <SignUpButtonBox>
                                    <p></p>
                                    <SignUpButton
                                        onClick={() => {
                                            setSubmitFlag(true)
                                        }}
                                    >
                                        다음
                                    </SignUpButton>
                                </SignUpButtonBox>
                            </SignUpButtonContainer>
                        </>
                    )}

                    {stepIndex === 1 && (
                        <>
                            <ItemApply ref={itemApplyRef} setAppliable={setAppliable} />
                            <SignUpButtonContainer className={`mt-auto md:z-50 max-md:bottom-0 p-8`}>
                                <SignUpButtonBox>
                                    <SignUpBackButton
                                        onClick={() => {
                                            setStepIndex(0)
                                        }}
                                    >
                                        이전
                                    </SignUpBackButton>
                                    <SignUpButton
                                        onClick={() => {
                                            if (isAppliable) {
                                                handleApply()
                                            }
                                        }}
                                        isdisabled={!isAppliable}
                                    >
                                        신청
                                    </SignUpButton>
                                </SignUpButtonBox>
                            </SignUpButtonContainer>
                        </>
                    )}
                </div>
                {/* <div
                    className={`relative flex flex-col justify-between w-full ${
                        stepIndex === 0 ? "max-md:h-[1040px] md:h-[720px]" : "max-md:h-[1140px] md:h-[1280px]"
                    }`}
                >
                    <SignUpBasicInformationContainer isStep={stepIndex === 0}>
                        <ItemApplyMarketerDetail
                            submitFlag={submitFlag}
                            setSubmitFlag={setSubmitFlag}
                            nextStep={() => {
                                setStepIndex(1)
                            }}
                        />

                        <SignUpButtonContainer className={`mt-auto md:z-50 max-md:bottom-0 p-8`}>
                            <SignUpButtonBox>
                                <p></p>
                                <SignUpButton
                                    onClick={() => {
                                        setSubmitFlag(true)
                                    }}
                                >
                                    다음
                                </SignUpButton>
                            </SignUpButtonBox>
                        </SignUpButtonContainer>
                    </SignUpBasicInformationContainer>
                    <SignUpBasicInformationContainer isStep={stepIndex === 1}>
                        <ItemApply ref={itemApplyRef} setAppliable={setAppliable} />
                        <SignUpButtonContainer className={`mt-auto md:z-50 max-md:bottom-0 p-8`}>
                            <SignUpButtonBox>
                                <SignUpBackButton
                                    onClick={() => {
                                        setStepIndex(0)
                                    }}
                                >
                                    이전
                                </SignUpBackButton>
                                <SignUpButton
                                    onClick={() => {
                                        if (isAppliable) {
                                            handleApply()
                                        }
                                    }}
                                    isdisabled={!isAppliable}
                                >
                                    신청
                                </SignUpButton>
                            </SignUpButtonBox>
                        </SignUpButtonContainer>
                    </SignUpBasicInformationContainer>
                </div> */}
            </ItemApplyLayout>
        </ItemApplyContainer>
    )
}
