import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import { FilterBox, FilterCheckBox, FilterCheckedIcon, FilterCheckIcon, FilterColumnName, FilterContainer, FilterRowList, FilterValueContainer } from "./MyPagePerformFilterStyle";
import { getComboName, getPerformEmptyValue, getStatusName } from '../../../utils/brandItem/BrandItemPerformUtils';
import { PerformItemCombo, PerformItemInput, PerformItemTextarea } from "../../../components/brandItems/PerformInputs";
import { EditStateTypes, QueryTypes } from "../../../types/EditorTypes";
import { PerformColumnFormTypes, PerformColumnPreviewTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes";
import Utils from "../../../utils/Utils";
import { PerformBatchUpdateSubmitButton, PerformValueInputTitle } from "./MyPagePerformStyle";
import { Dictionary } from "../../../types/Dictionary";
import { batchUpdateItemPerformValue } from "../../../apis/api/boardItems";
import { UserTypes } from "../../../types/users/UserTypes";
import PerformUtils from "../../../utils/PerformUtils";
import { FilterCombo, FilterOption, FilterStatus, FilterText } from "./MyPagePerformFilter";
import { DateTimeInput } from "../../../components/inputs/DateTimeInput";
import { PerformDateTimeMemoInput } from "./PerformValueDateStyle";
import { OptionItemList } from "./PerformValueOptionStyle";
import { OptionItem } from "./PerformValueOptionCell";

export const UpdateDate = ({column, filterValue, handleAddFilter}) => {
    const [timeStamp, setTimeStamp] = useState(null) // 저장된 혹은 저장한 시간 값
    const [timeStampValue, setTimeStampValue] = useState(null) // 수정 중인 시간 값

    const [hasMemo, setHasMemo] = useState(false)
    const [memo, setMemo] = useState("")

    useEffect(()=>{
        const columnDate = column.dates.find(s => s.columnID === column.id)
        setHasMemo(columnDate.hasMemo)
    }, [column])

    useEffect(()=>{
        if (timeStampValue !== null) {
            let filter = {}
            filter.text = timeStampValue
            filter.timestamp = timeStampValue
            filter.memo = memo
            filterValue.timestamp = timeStampValue
            filterValue.memo = memo
    
            handleAddFilter(filter)
        }
    }, [timeStampValue])

    return (
        <div className="flex flex-col w-full">
            <DateTimeInput
                timeStamp={timeStamp} 
                setTimeStamp={setTimeStampValue} 
            />

            <PerformDateTimeMemoInput
                value={memo}
                onChange={(e)=>{setMemo(e.target.value)}}
            />
        </div>
    )
}

const UpdateOption = ({column, filterValue, handleAddFilter, handleRemoveFilter}) => {
    const [optionItemDic, setOptionItemDic] = useState(new Dictionary()) // 체크된 항목 관리
    const [radioOptionDic, setRadioOptionDic] = useState(new Dictionary()) // 라디오 버튼인 항목은 하나만 체크되도록 관리

    useEffect(()=>{
        let _radioOptionDic = new Dictionary()

        column.options.map(option => {
            if (!option.isMultiSelect) {
                _radioOptionDic.push(option.id, option.id)
            }
        })

        setRadioOptionDic(_radioOptionDic)
    }, [column])

    useEffect(()=>{
        let _optionItemDic = new Dictionary()

        filterValue.optionItems.map(optionItem => {
            _optionItemDic.push(optionItem.optionID, optionItem)
        })

        setOptionItemDic(_optionItemDic)
    }, [filterValue])

    const updateOptionDic = (optionItem, queryType) => {
        let _optionItemDic = optionItemDic.copy()

        if (queryType === QueryTypes.CREATE_OR_UPDATE) {
            // 라디오 버튼인 경우 이미 체크된 다른 라디오버튼을 해제한다.
            if (radioOptionDic.contains(optionItem.optionID)) {
                _optionItemDic.getKeys().map(optionID => {
                    if (radioOptionDic.contains(optionID)) {
                        _optionItemDic.delete(optionID)
                    }
                })
            }
            
            _optionItemDic.push(optionItem.optionID, optionItem)
        } else if (queryType === QueryTypes.DELETE) {
            _optionItemDic.delete(optionItem.optionID)
        }

        setOptionItemDic(_optionItemDic)
        
        if (_optionItemDic.getLength() === 0) {
            filterValue.optionItems = []
            handleRemoveFilter(column.id)
        } else {
            let filter = {}
            filter.optionItems = _optionItemDic.getValues()
            handleAddFilter(filter)
            filterValue.optionItems = _optionItemDic.getValues()
        }
    }

    return (
        <OptionItemList>
            {column.options.map((option, idx) => 
                <OptionItem option={option} optionItemDic={optionItemDic} updateOptionDic={updateOptionDic} multiSelect={option.isMultiSelect} key={idx} />
            )}
        </OptionItemList>
    )
}

const FilterRow = ({filterValue, updateDic, addFilter, removeFilter}) => {
    const column = filterValue.column
    const [isActive, setActive] = useState(false)
    
    if (column === undefined) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    useEffect(()=>{
        if (updateDic.contains(column.id)) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [updateDic])

    useEffect(()=>{
        if (!isActive) {
            removeFilter(column.id)
        }
    }, [isActive])

    const handleActive = () => {
        if (isActive) {
            setActive(false)
            return
        }
        
        // 컬럼 타입 마다 필터를 활성화 할 수 있는 조건이 다르다.
        if (column.formType === PerformColumnFormTypes.COMBO &&
            filterValue.comboID >= 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.TEXT &&
            filterValue.text !== ""
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.STATUS &&
            filterValue.statusID >= 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.OPTION &&
            filterValue.optionItems.length > 0
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
        else if (column.formType === PerformColumnFormTypes.DATE &&
            (filterValue.timeStamp !== null || !Utils.isStringNullOrEmpty(filterValue.memo))
        ) {
            setActive(true)
            handleAddFilter(filterValue)
        }
    }

    const handleAddFilter = (fv) => {
        addFilter(column.id, fv)
    }

    const setDeactive = () => {
        setActive(false)
    }

    return (
        <FilterBox isActive={isActive}>
            <FilterCheckBox onClick={()=>{handleActive()}}>
                {isActive ? (<FilterCheckedIcon />) : (<FilterCheckIcon />)}
            </FilterCheckBox>
            <FilterColumnName onClick={()=>{handleActive()}}>
                {column.name}
            </FilterColumnName>
            <FilterValueContainer>
                {column.formType === PerformColumnFormTypes.COMBO && (
                    <FilterCombo column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {column.formType === PerformColumnFormTypes.TEXT && (
                    <FilterText column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {column.formType === PerformColumnFormTypes.STATUS && (
                    <FilterStatus column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {(column.formType === PerformColumnFormTypes.DATE && column.id >= 0) && (
                    <UpdateDate column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} setDeactive={setDeactive} />
                )}
                {column.formType === PerformColumnFormTypes.OPTION && (
                    <UpdateOption column={column} filterValue={filterValue} handleAddFilter={handleAddFilter} handleRemoveFilter={removeFilter} />
                )}
            </FilterValueContainer>
        </FilterBox>
    )
}

export default function MyPagePerformBatchUpdate({user, columns, performCheckDic, reloadPerforms, offModal}){
    const [filterValues, setFilterValues] = useState([])
    const [updateDic, setUpdateDic] = useState(new Dictionary())

    useEffect(()=>{
        if (columns !== undefined && columns.length > 0) {
            let filterValues = []
            columns.map((column) => {
                // 컬럼이 읽기 권한일 경우 추가하지 않는다.
                if (column.id >= 0 && column.formType !== PerformColumnFormTypes.FILE &&
                    !(user.userType === UserTypes.BRAND && column.brandReadOnly) &&
                    !(user.userType === UserTypes.MARKETER && column.marketerReadOnly)) {

                    let filterValue = getPerformEmptyValue(column)

                    // 유저 타입에따라 쓰기가 가능한 현황만 가져온다.
                    if (column.formType === PerformColumnFormTypes.STATUS) {
                        filterValue.column = PerformUtils.getWriteOnlyStatus(user, {...column})
                    } else {
                        filterValue.column = {...column}
                    }
    
                    filterValues.push(filterValue)
                }
            })
            setFilterValues(filterValues)
        }
    }, [columns])

    const handleAddFilter = (columnID, filterValue) => {
        let newUpdateDic = new Dictionary(updateDic)
        newUpdateDic.push(columnID.toString(), filterValue)

        setUpdateDic(newUpdateDic)
    }

    const handleRemoveFilter = (columnID) => {
        if (updateDic.contains(columnID)) {
            let newUpdateDic = new Dictionary(updateDic)
            newUpdateDic.delete(columnID.toString())

            setUpdateDic(newUpdateDic)
        }
    }

    const handleUpdate = () => {
        let columnNames = []
        columns.map(column => {
            if (updateDic.contains(Number(column.id))) {
                columnNames.push(column.name)
            }
        })

        if (!confirm(`${performCheckDic.getKeys().length}개 실적의 ${columnNames.join(",")} 항목을 일괄 수정하시겠습니까?`)) {
            return
        }

        batchUpdateItemPerformValue(updateDic.data, performCheckDic.getKeys()).then(response => {
            if (response) {
                alert("일괄 수정 되었습니다.")
                reloadPerforms()
                offModal()
            } else {
                alert("수정 실패했습니다.")
            }
        })
    }

    return(
        <FilterContainer>
            <PerformValueInputTitle>
                <span>일괄 수정</span>
            </PerformValueInputTitle>
            <FilterRowList className='parent-div'>
                {filterValues.map((filterValue, idx) => (
                    <FilterRow
                        filterValue={filterValue} 
                        updateDic={updateDic}
                        addFilter={handleAddFilter}
                        removeFilter={handleRemoveFilter}
                        key={idx} 
                    />
                ))}
            </FilterRowList>
            <div className="w-full flex justify-end items-center mt-3">
                <PerformBatchUpdateSubmitButton onClick={()=>{handleUpdate()}}>일괄 수정</PerformBatchUpdateSubmitButton>
            </div>
        </FilterContainer>
    )
}