import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"
import CommonFile from "./CommonFile"

export default class extends CommonFile{
    constructor() {
        super()
        this.base64 = ""
        this.url = ""
        this.width = ""
        this.height = ""
    }

    convertByResponse(response) {
        if (response.id !== undefined) {
            this.base64 = response.base64
            this.url = FileUtils.getMediaFileURL(response.file)
            this.width = response.width
            this.height = response.height

            super.convertByResponse(response)
        }
    }

    getSource() {
        if (!Utils.isStringNullOrEmpty(this.base64)) {
            return this.base64
        } else if (!Utils.isStringNullOrEmpty(this.url)) {
            return this.url
        } else {
            return ""
        }
    }
}