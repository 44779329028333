import React,{useEffect,useState,useRef,useMemo} from "react";
import tw from 'tailwind-styled-components'
import { PerformColumnSubControlBox, PerformColumnSubDeleteButton, PerformColumnSubSaveButton, StaffPerformUpdateOptionContainer, StaffPerformUpdateStatusBox, StaffPerformUpdateStatusContainer, StaffPerformUpdateStatusControlBox, StaffPerformUpdateStatusInputBox, StaffPerformUpdateStatusList, StaffPerformUpdateStatusMainBox, } from "./StaffPerformStyle";
import { MyPageDataSubmitButton } from "../myinfo/MyInfoMainStyle";
import Utils from "../../../utils/Utils";
import { QueryTypes } from "../../../types/EditorTypes";
import { PerformValueInputTitle } from "./MyPagePerformStyle";
import { MyPageUserInput } from "../../../components/user/Inputs";
import PerformColumnOption from "../../../types/brandItems/performColumns/PerformColumnOption";



const Option = ({column, option, columnSubEdit}) => {
    const [name, setName] = useState("")
    const [isMultiSelect, setIsMultiSelect] = useState(false)
    const [hasEtc, setHasEtc] = useState(false)

    useEffect(()=>{
        setName(option.name)
        setIsMultiSelect(option.isMultiSelect)
        setHasEtc(option.hasEtc)
    }, [option])

    const handleSaveStatus = (option) => {
        if (confirm(`${name} 저장하시겠습니까?`)) {
            let _option = new PerformColumnOption()

            _option.id = option.id
            _option.columnID = option.columnID
            _option.name = name
            _option.isMultiSelect = isMultiSelect
            _option.hasEtc = hasEtc
            columnSubEdit(_option, QueryTypes.CREATE)
        }
    }
    const handleDeleteStatus = (option) => {
        if (confirm(`${name} 삭제하시겠습니까?`)) {
            columnSubEdit(option, QueryTypes.DELETE)
        }
    }

    return (
        <StaffPerformUpdateStatusBox>
            <StaffPerformUpdateStatusMainBox>
                <StaffPerformUpdateStatusInputBox className="w-48">
                    <MyPageUserInput
                        label={"이름"}
                        type={"text"}
                        data={name}
                        setData={setName}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"다중 선택 가능 여부"}
                        type={"checkbox"}
                        checked={isMultiSelect}
                        setData={(e)=>{setIsMultiSelect(!isMultiSelect)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <StaffPerformUpdateStatusInputBox>
                    <MyPageUserInput
                        label={"직접 입력 항목 여부"}
                        type={"checkbox"}
                        checked={hasEtc}
                        setData={(e)=>{setHasEtc(!hasEtc)}}
                    />
                </StaffPerformUpdateStatusInputBox>
                <PerformColumnSubControlBox>
                    <PerformColumnSubDeleteButton onClick={()=>{handleDeleteStatus(option)}}>
                        삭제
                    </PerformColumnSubDeleteButton>
                    <PerformColumnSubSaveButton onClick={()=>{handleSaveStatus(option)}}>
                        저장
                    </PerformColumnSubSaveButton>
                    {/* <PerformColumnSubConstraintButton onClick={()=>{setShowConstraints(!showConstraints)}}>
                        제약 조건
                    </PerformColumnSubConstraintButton> */}
                </PerformColumnSubControlBox>
            </StaffPerformUpdateStatusMainBox>
        </StaffPerformUpdateStatusBox>
    )
}

export default function StaffPerformUpdateOption({selectedColumn, columnSubEdit}){
    const handleAddStatus = () => {
        const comboName = prompt("추가할 항목 이름을 입력해주세요.")
        if (Utils.isStringNullOrEmpty(comboName)) {
            return
        }

        if (selectedColumn.id >= 0) {
            let newOption = new PerformColumnOption()
            newOption.columnID = selectedColumn.id
            newOption.name = comboName
            columnSubEdit(newOption, QueryTypes.CREATE)
        } else {
            alert("추가할 수 없습니다.")
        }
    }
    
    return(
        <StaffPerformUpdateOptionContainer>
            <PerformValueInputTitle>
                <span>{selectedColumn.name} <span className="text-gray-600">항목 선택 관리</span></span>
            </PerformValueInputTitle>
            <StaffPerformUpdateStatusControlBox>
                <MyPageDataSubmitButton onClick={()=>{handleAddStatus()}}>
                    추가
                </MyPageDataSubmitButton>
            </StaffPerformUpdateStatusControlBox>

            <StaffPerformUpdateStatusList>
                {selectedColumn.options.map((option, idx) => (
                    <Option column={selectedColumn} option={option} columnSubEdit={columnSubEdit} key={idx} />
                ))}
            </StaffPerformUpdateStatusList>
        </StaffPerformUpdateOptionContainer>
    )
}