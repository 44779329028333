import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    BottomButtonCon,
    DeleteButton,
    ItemInputBox,
    ItemInputRow,
    ItemInputFromLayout,
    SaveButton,
    ItemInputLabel,
    ItemControlBox,
    ItemInputListCol,
    ItemStatusControlBox,
    ItemEditNotification,
    ItemEditNotificationContainer,
    ItemInputRowEditor,
} from "../brand/ItemInputFormStyle"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { QueryTypes, EditStateTypes, AdTypes, AdTypeNames } from "../../../../types/EditorTypes"
import {
    BrandItemCombo,
    BrandItemFile,
    BrandItemInput,
    BrandItemEditor,
    BrandItemTextArea,
    BrandItemSigunguInput,
} from "../../../../components/brandItems/Inputs"
import {
    AdCategoryTypeNames,
    ItemEditTabTypes,
    ItemSalesTypeNames,
    ItemSalesTypes,
    MarketerTypeNames,
    AdCategoryTypes,
} from "../../../../types/brandItems/BrandItemTypes"
import { uploadImage } from "../../../../apis/api/files"
import { createBrandItem, deleteBrandItem, updateBrandItem } from "../../../../apis/api/boardItems"
import { AccessRules } from "../../../../rules/AccessRules"
import { Dictionary } from "../../../../types/Dictionary"
import FileUtils from "../../../../utils/FileUtils"
import Utils from "../../../../utils/Utils"
import { InformationHover, TooltipHoverBox } from "../../../../components/brandItems/InformationHover"
import { ItemCategoryTips, MarketerTypeTips, MissionTips, RewardTips, TitleTips } from "../../../../constants/ToolTips"
import { AlignTypes } from "../../../../types/StyleTypes"
import {
    AdLevelButton,
    AdLevelMinusButton,
    ItemEditorMenuButton,
    ItemEditorTabBar,
    ItemEditorTabButton,
    ItemEditorTabButtonArrowSvg,
    ItemEditorTabContent,
    ItemEditorTabContentContainer,
    ItemEditorTabContentScroll,
    ItemEditorTabGuide,
    ItemEditorTabGuide2,
    ItemEditorTabIndexBox,
    ItemEditorTabWarningBox,
    ItemEditorTabWarningHover,
    ItemEditorTabWarningHoverMessage,
    ItemEditorTabWarningHoverMessage2,
    ItemEditorTabWarningIcon,
} from "../brand/MyPageItemBrandStyle"
import { filter } from "lodash"
import ModalContainerPortal from "../../../../components/ModalContainerPortal"
import ItemPersonalAgreementPaperWriter from "../brand/ItemPersonalAgreementPaperWriter"
import ItemMarketingMatchingSetting from "../brand/ItemMarketingMatchingSetting"
import { UserTypes } from "../../../../types/users/UserTypes"
import Sigungu from "../../../../types/commons/Sigungu"

const RevInfo = ({ label, value }) => {
    return (
        <div className="flex items-center ml-2">
            <span className="font-semibold text-gray-800">{label}</span>
            <span className="ml-1">{value}</span>
        </div>
    )
}

// eslint-disable-next-line react/display-name
const AdItemInputForm = React.forwardRef(
    (
        {
            item,
            queryType,
            itemEditStateType,
            isShow,
            disabled = false,
            itemOrigin = null,
            deleteItem,
            viewType,
            userType,
            updateAdLevel,
        },
        ref,
    ) => {
        const [title, setTitle] = useState()
        const [thumbnailImageID, setThumbnailImageID] = useState()
        const [sigungu, setSigungu] = useState(new Sigungu())
        const [thumbnailLink, setThumbnailLink] = useState("")
        const [adCategory, setAdCategory] = useState("")
        const [adDescription, setAdDescription] = useState("")
        const [adCompanyName, setAdCompanyName] = useState("")
        const [adCompanyTel, setAdCompanyTel] = useState("")
        const [adCompanyEmail, setAdCompanyEmail] = useState("")

        const [descriptionContent, setDescriptionContent] = useState()

        const [isCheckableDetail, setCheckableDetail] = useState(false) // 항목이 수정됬는지 체크할수 있는지 여부
        const [isCheckableContract, setCheckableContract] = useState(false) // 항목이 수정됬는지 체크할수 있는지 여부

        const [originDescription, setOriginDescription] = useState("")

        const [thumbnailEditStateType, setThumbnailEditStateType] = useState(EditStateTypes.WAIT)

        const [tabIndex, setTabIndex] = useState(ItemEditTabTypes.BASE)

        React.useImperativeHandle(ref, () => ({
            getItem,
            setTabIndex,
        }))

        useEffect(() => {
            initData()

            handleCheckableEdited()
        }, [queryType, item])

        useEffect(() => {
            handleCheckableEdited()

            try {
                setOriginDescription(FileUtils.replaceMedieFileURL(itemOrigin.detail.description))
            } catch {
                //
            }
        }, [itemOrigin])

        const initData = async () => {
            if (queryType === QueryTypes.UPDATE || itemEditStateType === EditStateTypes.PENDING) {
                setTitle(item.detail.title)
                setThumbnailImageID(item.detail.thumbnailImageID)
                setSigungu(item.detail.sigungu)
                setThumbnailLink(item.detail.thumbnailLink)
                setAdCategory(item.detail.adCategory)
                setAdDescription(item.detail.adDescription)
                setAdCompanyName(item.detail.adCompanyName)
                setAdCompanyTel(item.detail.adCompanyTel)
                setAdCompanyEmail(item.detail.adCompanyEmail)
                setDescriptionContent(FileUtils.replaceMedieFileURL(item.detail.description))

                // const oldDescriptionImageDic = await FileUtils.convertImageTemplateToSource(setDescriptionContent, item.detail.description)
                // setDescriptionImageDic(oldDescriptionImageDic)
                // const oldMissionImageDic = await FileUtils.convertImageTemplateToSource(setMissionContent, item.contract.mission)
                // setMissionImageDic(oldMissionImageDic)
                // const oldRewardImageDic = await FileUtils.convertImageTemplateToSource(setRewardContent, item.contract.reward)
                // setRewardImageDic(oldRewardImageDic)
                // const oldContractImageDic = await FileUtils.convertImageTemplateToSource(setContractContent, item.contract.contract)
                // setContractImageDic(oldContractImageDic)
            } else {
                setTitle("")
                setThumbnailImageID(Number(process.env.REACT_APP_AD_DEFAULT_THUMBNAIL))
                setSigungu(new Sigungu())
                setThumbnailLink("")
                setAdCategory(0)
                setAdDescription("")
                setAdCompanyName(itemOrigin.brand.detail.name)
                setAdCompanyTel("")
                setAdCompanyEmail("")
                setDescriptionContent("")
            }
        }

        const handleCheckableEdited = () => {
            // 원본과 수정본의 차이를 비교할 수 있는지 확인하는걸 실행할지 여부
            if (
                !disabled &&
                itemOrigin !== null &&
                Object.keys(item).includes("detail") &&
                Object.keys(itemOrigin).includes("detail")
            ) {
                setCheckableDetail(true)
            }
            if (
                !disabled &&
                itemOrigin !== null &&
                Object.keys(item).includes("contract") &&
                Object.keys(itemOrigin).includes("contract")
            ) {
                setCheckableContract(true)
            }
        }

        const handleUploadImage = (e, setImageID) => {
            if (e.target.files.length !== 1) {
                return
            }
            setThumbnailEditStateType(EditStateTypes.PENDING)
            uploadImage(e.target.files[0])
                .then((response) => {
                    setImageID(response.id)
                    setThumbnailEditStateType(EditStateTypes.WAIT)
                })
                .catch((error) => {
                    setThumbnailEditStateType(EditStateTypes.FAILED)
                })
        }

        const getItem = () => {
            const submitItem = new BrandItem()
            submitItem.id = item.id // 부모 컴포넌트에서 보낸걸 확인하기 위해 임의로 0을 넣는다. (백엔드에서 사용하지 않음)
            submitItem.detail.title = title
            submitItem.detail.dateStart = "1901-01-01"
            submitItem.detail.dateEnd = "2299-09-09"
            submitItem.detail.thumbnailImageID = thumbnailImageID
            submitItem.detail.sigungu = sigungu
            submitItem.detail.thumbnailLink = thumbnailLink
            submitItem.detail.adCategory = adCategory
            submitItem.detail.adDescription = adDescription
            submitItem.detail.description = FileUtils.getRequestContent(descriptionContent)
            submitItem.detail.adCompanyName = adCompanyName
            submitItem.detail.adCompanyTel = adCompanyTel
            submitItem.detail.adCompanyEmail = adCompanyEmail

            return submitItem
        }

        const handleDelete = async () => {
            if (
                !confirm(
                    `상품 ${item.detail.title}${
                        Utils.isBatchimEnding(item.detail.title) ? "을 " : "를"
                    } 삭제하시겠습니까?`,
                )
            ) {
                return
            }
            deleteItem(item)
        }

        return (
            <ItemInputFromLayout className={`${isShow && "isShow"}`}>
                <div className="flex max-sm:flex-col justify-between items-center w-full">
                    <ItemEditorTabBar>
                        <TabButton
                            index={1}
                            label={"기본정보"}
                            isActive={tabIndex === ItemEditTabTypes.BASE}
                            setActive={() => {
                                setTabIndex(ItemEditTabTypes.BASE)
                            }}
                            validates={[
                                Utils.isStringNullOrEmpty(title) && '"제목"을 입력해 주세요.',
                                thumbnailImageID < 0 && '"썸네일"을 업로드해 주세요.',
                                sigungu.id < 0 && '"지역"을 선택해 주세요.',
                                adCategory < 1 && '"카테고리"을 선택해 주세요.',
                            ]}
                            showArrow={AdTypes.FREE !== item.adType}
                        />
                        {AdTypes.FREE !== item.adType && (
                            <TabButton
                                index={2}
                                label={"상세내용"}
                                isActive={tabIndex === ItemEditTabTypes.DESCRIPTION}
                                setActive={() => {
                                    setTabIndex(ItemEditTabTypes.DESCRIPTION)
                                }}
                                validates={
                                    [
                                        // FileUtils.isBlankContent(FileUtils.getRequestContent(descriptionContent)) &&
                                        //     '"상세내용"을 입력해 주세요.',
                                    ]
                                }
                                warningHoverOffset={"2rem"}
                                showArrow={false}
                            />
                        )}
                        {/* <ItemEditorTabGuide2 tabIndex={tabIndex} /> */}
                    </ItemEditorTabBar>
                    {/* 광고 레벨 */}
                    {AccessRules.staff() && (
                        <div className="flex items-center max-sm:mt-2 sm:ml-auto space-x-1">
                            <AdLevelButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.FREE)
                                }}
                                isActive={AdTypes.FREE === item.adType}
                            >
                                무료
                            </AdLevelButton>
                            <AdLevelButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL1)
                                }}
                                isActive={AdTypes.LEVEL1 === item.adType}
                            >
                                레벨1
                            </AdLevelButton>
                            <AdLevelButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL2)
                                }}
                                isActive={AdTypes.LEVEL2 === item.adType}
                            >
                                레벨2
                            </AdLevelButton>
                            <AdLevelButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL3)
                                }}
                                isActive={AdTypes.LEVEL3 === item.adType}
                            >
                                레벨3
                            </AdLevelButton>
                            <AdLevelMinusButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL1M)
                                }}
                                isActive={AdTypes.LEVEL1M === item.adType}
                            >
                                레벨1-
                            </AdLevelMinusButton>
                            <AdLevelMinusButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL2M)
                                }}
                                isActive={AdTypes.LEVEL2M === item.adType}
                            >
                                레벨2-
                            </AdLevelMinusButton>
                            <AdLevelMinusButton
                                onClick={() => {
                                    updateAdLevel(AdTypes.LEVEL3M)
                                }}
                                isActive={AdTypes.LEVEL3M === item.adType}
                            >
                                레벨3-
                            </AdLevelMinusButton>
                        </div>
                    )}
                </div>
                <ItemEditorTabContentContainer>
                    <ItemEditorTabContentScroll isActive={tabIndex === ItemEditTabTypes.BASE}>
                        <ItemEditNotificationContainer>
                            <ItemEditNotification isShow={viewType === EditStateTypes.EDITED}>
                                {" "}
                                ※ 수정 요청 후 관리자의 승인 전까지 수정 내용이 반영 되지 않습니다.
                            </ItemEditNotification>
                        </ItemEditNotificationContainer>
                        <ItemInputRow>
                            <BrandItemInput
                                type="text"
                                label={"제목"}
                                data={title}
                                setData={setTitle}
                                disabled={disabled}
                                isEdited={isCheckableDetail && title !== itemOrigin.detail.title}
                                information={TitleTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={40}
                            />
                            <BrandItemCombo
                                label={"카테고리"}
                                optionKeys={Object.values(AdCategoryTypes)}
                                names={Object.values(AdCategoryTypeNames)}
                                value={adCategory}
                                onChange={(e) => {
                                    setAdCategory(e.target.value)
                                }}
                                disabled={disabled}
                                isEdited={isCheckableDetail && adCategory != itemOrigin.detail.adCategory}
                                information={MarketerTypeTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                            />
                            <BrandItemSigunguInput
                                label={"지역"}
                                data={sigungu}
                                setData={setSigungu}
                                disabled={disabled}
                                isEdited={isCheckableDetail && sigungu.id !== itemOrigin.detail.sigungu.id}
                                information={MissionTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={200}
                            />
                        </ItemInputRow>
                        <ItemInputRow>
                            <div className="col-span-3">
                                <BrandItemInput
                                    type="text"
                                    label={"상품/서비스 상세"}
                                    data={adDescription}
                                    setData={setAdDescription}
                                    disabled={disabled}
                                    isEdited={isCheckableDetail && adDescription !== itemOrigin.detail.adDescription}
                                    information={MissionTips}
                                    informationAlignType={AlignTypes.RIGHT}
                                    widthFull={true}
                                    maxLength={200}
                                />
                            </div>
                        </ItemInputRow>
                        <ItemInputRow>
                            <BrandItemInput
                                type="text"
                                label={"상호 명"}
                                data={adCompanyName}
                                setData={setAdCompanyName}
                                disabled={disabled}
                                isEdited={isCheckableDetail && adCompanyName !== itemOrigin.detail.adCompanyName}
                                information={MissionTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={200}
                            />
                            <BrandItemInput
                                type="text"
                                label={"담당자 전화번호"}
                                data={adCompanyTel}
                                setData={setAdCompanyTel}
                                disabled={disabled}
                                isEdited={isCheckableDetail && adCompanyTel !== itemOrigin.detail.adCompanyTel}
                                information={MissionTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={200}
                            />
                            <BrandItemInput
                                type="text"
                                label={"담당자 이메일"}
                                data={adCompanyEmail}
                                setData={setAdCompanyEmail}
                                disabled={disabled}
                                isEdited={isCheckableDetail && adCompanyEmail !== itemOrigin.detail.adCompanyEmail}
                                information={MissionTips}
                                informationAlignType={AlignTypes.LEFT}
                                widthFull={true}
                                maxLength={200}
                            />
                        </ItemInputRow>

                        {/* <ItemInputRow>
                        <BrandItemInput 
                            type="number"
                            label={"모집 인원"}
                            data={personMax}
                            setData={setPersonMax}
                            disabled={disabled}
                            isEdited={isCheckableDetail && personMax !== itemOrigin.detail.personMax}
                        />
                        <BrandItemInput 
                            type="date"
                            label={"마케터 모집 시작일"}
                            data={dateStart}
                            setData={setDateStart}
                            disabled={disabled}
                            isEdited={isCheckableDetail && dateStart !== itemOrigin.detail.dateStart}
                        />
                        <BrandItemInput 
                            type="date"
                            label={"마케터 모집 종료일"}
                            data={dateEnd}
                            setData={setDateEnd}
                            disabled={disabled}
                            isEdited={isCheckableDetail && dateEnd !== itemOrigin.detail.dateEnd}
                        />
                    </ItemInputRow> */}

                        <ItemInputRow>
                            <div className="col-span-3">
                                <BrandItemInput
                                    type="text"
                                    label={"썸네일 링크"}
                                    data={thumbnailLink}
                                    setData={setThumbnailLink}
                                    disabled={disabled}
                                    isEdited={isCheckableDetail && thumbnailLink !== itemOrigin.detail.thumbnailLink}
                                    widthFull={true}
                                    helpText="링크 작성 시 썸네일을 클릭하면 작성한 링크로 이동됩니다."
                                />
                            </div>
                        </ItemInputRow>
                        <ItemInputRowEditor className="min-h-[36rem]">
                            <BrandItemFile
                                type="file"
                                label={"썸네일"}
                                fileID={thumbnailImageID}
                                onChange={(e) => {
                                    handleUploadImage(e, setThumbnailImageID)
                                }}
                                disabled={disabled}
                                isEdited={isCheckableDetail && thumbnailImageID !== itemOrigin.detail.thumbnailImageID}
                                editStateType={thumbnailEditStateType}
                            />

                            {/* <span className="absolute text-sm bottom-4 right-4 text-gray-400">가로:600px, 세로:400px 사이즈로 업로드</span>  */}
                            {/* <BrandItemFile
                            type="file"
                            label={"상세 이미지"}
                            fileID={descriptionImageID}
                            onChange={(e)=>{handleUploadImage(e, setDescriptionImageID)}}
                            disabled={disabled}
                            isEdited={isCheckableDetail && descriptionImageID !== itemOrigin.detail.descriptionImageID}
                        />
                        {/* 너비 맞추기 용 */}
                            {/* <ItemInputBox /> */}
                        </ItemInputRowEditor>
                        {/* <div className="flex items-center justify-end px-2">
                        <RevInfo label={"버전"} value={item.contract.rev} />
                        <RevInfo label={"승인 날짜"} value={item.contract.confirmed} />
                    </div> */}

                        {!disabled && AccessRules.staff() && (
                            <BottomButtonCon>
                                {queryType === QueryTypes.UPDATE || itemEditStateType === EditStateTypes.PENDING ? (
                                    <DeleteButton
                                        onClick={() => {
                                            handleDelete()
                                        }}
                                    >
                                        상품 삭제
                                    </DeleteButton>
                                ) : (
                                    <p></p>
                                )}
                            </BottomButtonCon>
                        )}
                    </ItemEditorTabContentScroll>
                    <ItemEditorTabContent isActive={tabIndex === ItemEditTabTypes.DESCRIPTION}>
                        <ItemInputRowEditor>
                            <BrandItemEditor
                                label={"상품 상세 내용"}
                                value={descriptionContent}
                                setValue={setDescriptionContent}
                                disabled={disabled}
                                originValue={originDescription}
                                message={"※ 최적의 이미지 가로 사이즈는 1200px 입니다."}
                            />
                        </ItemInputRowEditor>
                    </ItemEditorTabContent>
                </ItemEditorTabContentContainer>
            </ItemInputFromLayout>
        )
    },
)

export default AdItemInputForm

const TabHoverMessage = ({ message }) => {
    return (
        <ItemEditorTabWarningHoverMessage>
            <div className="flex flex-center h-full py-0.5 mr-1">
                <ItemEditorTabWarningIcon />
            </div>
            {message}
        </ItemEditorTabWarningHoverMessage>
    )
}

const TabButton = ({ index, label, isActive, setActive, validates, showArrow = true, warningHoverOffset = null }) => {
    const [validateMessages, setValidateMessages] = useState([])

    useEffect(() => {
        setValidateMessages(filter(validates, (v) => v !== false))
    }, [validates])

    return (
        <ItemEditorTabButton
            isActive={isActive}
            onClick={() => {
                setActive()
            }}
        >
            <ItemEditorTabIndexBox isActive={isActive}>{index}</ItemEditorTabIndexBox>
            {label}
            {validateMessages.length > 0 && (
                <React.Fragment>
                    <ItemEditorTabWarningBox>
                        <ItemEditorTabWarningIcon />
                    </ItemEditorTabWarningBox>
                    <ItemEditorTabWarningHover
                        ishovering
                        alignType={AlignTypes.RIGHT}
                        className={`${warningHoverOffset !== null && `max-sm:-left-[${warningHoverOffset}]`}`}
                    >
                        {validateMessages.map((message, key) => (
                            <TabHoverMessage key={key} message={message} />
                        ))}
                        <ItemEditorTabWarningHoverMessage2>
                            필수 항목을 작성하셔야 등록이 가능합니다.
                        </ItemEditorTabWarningHoverMessage2>
                    </ItemEditorTabWarningHover>
                </React.Fragment>
            )}
            {showArrow && (
                <div className="absolute -right-3 h-full">
                    <ItemEditorTabButtonArrowSvg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 20"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        isActive={isActive}
                    >
                        <path d="M0 0l5 10-5 10" />
                    </ItemEditorTabButtonArrowSvg>
                </div>
            )}
        </ItemEditorTabButton>
    )
}
