import styled from "styled-components"
import tw from "tailwind-styled-components"
export const Box = styled.div`
    display: ${(props) => (props.flex ? "flex" : "block")};
    align-items: ${(props) => (props.a_center ? "center" : "start")};
    justify-content: ${(props) => (props.j_center ? "center" : "start")};
    width: ${(props) => (props.w_100 ? "100vw" : "auto")};
    height: ${(props) => (props.h_100 ? "100vh" : "auto")};
`

export const BoxMaxMin = tw.div`
    max-sm:w-full sm:max-w-[1200px]
`

export const MyPageBoxMax = styled.div`
    max-width: 1600px;
`

export const BodyInnerBox = tw.div`
    w-full h-full justify-center flex py-8
`

export const ContainerPaddingTop = tw.div`
    w-full h-full justify-center flex max-md:pt-16 md:pt-20 md:pb-4
`

export const BodyBox = tw.div`
    w-full h-fit justify-center flex flex-col items-center
`

export const NavBox = tw(BoxMaxMin)`
    w-100 min-h-[4rem]
    
`
