import React, { useEffect, useState, useMemo } from "react"
import tw from "tailwind-styled-components"
import { ImageBase64 } from "./ImageContainer"
import { Close } from "@styled-icons/ionicons-solid/Close"
import { PerformFileTypes } from "../types/brandItems/performColumns/PerformColumnTypes"
import { FileEarmarkImageFill } from "@styled-icons/bootstrap/FileEarmarkImageFill"
import { getCommonFile, getImageFile } from "../apis/api/files"
import CommonFile from "../types/files/CommonFile"

const INDEX_STATE = {
    next: 1,
    prev: -1,
}

const FileIconImage = tw(FileEarmarkImageFill)`
h-full
`

const CloseIcon = tw(Close)`
  h-10
`

const PerformValueFileViewerWrapper = tw.div`
  absolute absolute-center w-screen h-screen flex items-center justify-center bg-blueGray-300/30
`

const PerformValueFileViewerWrapperCon = tw.div`
  w-full h-full flex flex-col
`

const PerformValueFileViewerHeader = tw.div`
  w-full h-14 flex items-center justify-between px-2
  text-gray-300
`

const CountNumber = tw.div`
    px-3 py-2 text-xl
`

const CloseButton = tw.div`
    flex flex-center cursor-pointer w-8 aspect-square
`

const Body = tw.div`
  w-full h-full flex
  max-lg:pb-32
`

const ImagesContainer = tw.div`
  w-full h-full flex py-14 select-none 
`

const ImageWrapper = tw.div`
  max-lg:hidden lg:flex relative h-full w-60 flex-center flex-shrink-0 cursor-pointer px-4
`

const ImageCon = tw.div`
  w-full h-60 absolute-center flex flex-center p-4
`
const ImageBox = tw.div`
    flex flex-center
    ${(props) => (props.useWidthFull ? "w-full" : "w-auto")}
    ${(props) => (props.useHeightFull ? "h-full" : "h-auto")}
    ${(props) => `aspect-[${props.aspectRatio}]`}
`

const FileBox = tw.div`
    flex flex-center w-48 aspect-square p-16
    rounded-md bg-white text-gray-500
`

const IsImage = ({ index, fileValue, file }) => {
    const [useWidthFull, setUseWidthFull] = useState(false)
    const [useHeightFull, setUseHeightFull] = useState(false)
    const [aspectRatio, setAspectRatio] = useState(1)

    useEffect(() => {
        if (fileValue.fileType === PerformFileTypes.IMAGE) {
            if (file.width > file.height) {
                setUseWidthFull(true)
                setUseHeightFull(false)
                setAspectRatio(file.width / file.height)
            } else {
                setUseWidthFull(false)
                setUseHeightFull(true)
                setAspectRatio(file.width / file.height)
            }
        } else {
            setUseWidthFull(true)
            setUseHeightFull(true)
            setAspectRatio(1)
        }
    }, [fileValue])

    return (
        <ImageBox useWidthFull={useWidthFull} useHeightFull={useHeightFull} aspectRatio={aspectRatio}>
            {fileValue.fileType === PerformFileTypes.IMAGE ? (
                <ImageBase64 imageFile={file} refresh={false} duration={false} />
            ) : (
                <FileBox>
                    <FileIconImage />
                </FileBox>
            )}
        </ImageBox>
    )
}

export const ImageViewer = ({ selectedFileID, fileValues, closeViewer }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [fileValueList, setFileValueList] = useState([])
    const [fileList, setFileList] = useState([])

    const getNextIndex = (current, mode = 0) => {
        var next = current + mode
        if (mode === 0) {
            return current
        } else if (next === -1) {
            return fileValues.length - 1
        } else if (next >= fileValues.length) {
            return 0
        } else {
            return next
        }
    }

    const handleBack = () => {
        setCurrentIndex((prevIndex) => getNextIndex(prevIndex, INDEX_STATE.prev))
    }

    const handleForward = () => {
        setCurrentIndex((prevIndex) => getNextIndex(prevIndex, INDEX_STATE.next))
    }

    useEffect(() => {
        setFileValueList(fileValues)
        getFiles(fileValues)
    }, [fileValues])

    useEffect(() => {
        const index = fileValues.findIndex((e) => e.fileID === selectedFileID)
        setCurrentIndex(index)
    }, [selectedFileID, fileValueList])

    const getFiles = async (fileValues) => {
        let files = []
        await Promise.all(
            fileValues.map(async (fileValue) => {
                let file = new CommonFile()
                if (fileValue.fileType === PerformFileTypes.COMMON) {
                    file = await getCommonFile(fileValue.fileID)
                } else if (fileValue.fileType === PerformFileTypes.IMAGE) {
                    file = await getImageFile(fileValue.fileID)
                }

                files.push(file)
            }),
        )
        files = files.sort((a, b) => a.id - b.id)

        setFileList(files)
    }

    return (
        <React.Fragment>
            {fileValueList.length !== 0 && (
                <PerformValueFileViewerWrapper>
                    <PerformValueFileViewerWrapperCon>
                        <PerformValueFileViewerHeader>
                            <CloseButton />
                            <CountNumber>
                                {currentIndex + 1}/{fileValueList.length}
                            </CountNumber>
                            <CloseButton onClick={closeViewer}>
                                <CloseIcon />
                            </CloseButton>
                        </PerformValueFileViewerHeader>
                        <Body>
                            {fileList.length === fileValueList.length && (
                                <ImagesContainer>
                                    <ImageWrapper onClick={handleBack}>
                                        <ImageCon>
                                            {fileValueList.length > 1 && (
                                                <IsImage
                                                    index={getNextIndex(currentIndex, INDEX_STATE.prev)}
                                                    fileValue={
                                                        fileValueList[getNextIndex(currentIndex, INDEX_STATE.prev)]
                                                    }
                                                    file={fileList[getNextIndex(currentIndex, INDEX_STATE.prev)]}
                                                />
                                            )}
                                        </ImageCon>
                                    </ImageWrapper>
                                    <div className="max-lg:flex lg:hidden w-10 h-full justify-center items-center mx-1">
                                        <button className="w-10 h-10 rounded-full bg-white" onClick={handleBack}>
                                            {`<-`}
                                        </button>
                                    </div>
                                    <div className="h-full w-full relative p-4 flex justify-center items-center overflow-scroll scroll-transparent scroll-overlay">
                                        <div className="w-full h-full absolute-center flex flex-center">
                                            <IsImage
                                                index={getNextIndex(currentIndex)}
                                                fileValue={fileValueList[getNextIndex(currentIndex)]}
                                                file={fileList[getNextIndex(currentIndex)]}
                                            />
                                            {/* <ImageBase64 useImageHeight useImageWidth refresh={false} /> */}
                                        </div>
                                    </div>
                                    <div className="max-lg:flex lg:hidden w-10 h-full justify-center items-center mx-1">
                                        <button className="w-10 h-10 rounded-full bg-white" onClick={handleForward}>
                                            {`->`}
                                        </button>
                                    </div>
                                    <ImageWrapper onClick={handleForward}>
                                        <ImageCon>
                                            {fileValueList.length > 1 && (
                                                <IsImage
                                                    index={getNextIndex(currentIndex, INDEX_STATE.next)}
                                                    fileValue={
                                                        fileValueList[getNextIndex(currentIndex, INDEX_STATE.next)]
                                                    }
                                                    file={fileList[getNextIndex(currentIndex, INDEX_STATE.next)]}
                                                />
                                            )}
                                        </ImageCon>
                                    </ImageWrapper>
                                </ImagesContainer>
                            )}
                        </Body>
                    </PerformValueFileViewerWrapperCon>
                </PerformValueFileViewerWrapper>
            )}
        </React.Fragment>
    )
}
