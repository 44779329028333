import React, { useEffect, useRef, useState } from "react";
import tw from 'tailwind-styled-components'
import { addBoardNotice, getBoardNotice, updateBoardFileIDs, updateBoardNotice } from "../../apis/api/boards";
import Board from "../../types/boards/Board";
import { BoardImportnaceStatusTypes, BoardStatusTypes } from "../../types/boards/BoardTypes";
import { QueryTypes } from "../../types/EditorTypes";
import WebEditor from "../WebEditor"
import { BoardFileUploader } from "./BoardFileUploader";
import { deleteFile, uploadImageBase64 } from "../../apis/api/files";
import { BoardEditContainer, BoardEditControlBox, BoardEditEditorContainer, BoardEditFileButton, BoardEditFileContainer, BoardEditFileModalButton, BoardEditFileModalContainer, BoardEditFileModalLayout, BoardEditLayout, BoardEditSubmitButton, BoardEditTitleContainer, BoardEditTitleInput } from "./BoardEditStyle";
import { PerformValueInputTitle } from "../../pages/myPage/perform/MyPagePerformStyle";
import { ItemInputLabel } from "../../pages/myPage/item/brand/ItemInputFormStyle";
import FileUtils from "../../utils/FileUtils";
import { Dictionary } from '../../types/Dictionary';
import CKEditor from "../ckeditor/CKEditor";

// import QuillDeltaToHtmlConverter from 'quill-delta-to-html';

// mainID는 NoticeID, ItemNoticeID를 나타낸다. Notice및 ItemNotice 내에 Board가 있다.
export default function BoardEdit({ mainID, getMain, addMain, updateMain, close }) {
    const [queryType, setQueryType] = useState(QueryTypes.CREATE)

    const [boardID, setBoardID] = useState(-1)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [files, setFiles] = useState([])
    const [importanceChecked, setImportanceChecked] = useState(false)
    // const [imageDic, setImageIDDic] = useState(new Dictionary())

    const [showFileModal, setShowFileModal] = useState(false) // 파일 업로드 모달을 보여줄지 여부 (모바일(sm)에서만 사용)

    const filesRef = useRef(null)

    // useEffect(() => {
    //     console.log(importanceChecked)
    // },[importanceChecked])

    useEffect(() => {
        return () => {
            // 업로드한 파일을 확정짓지않고 에디터 화면을 종료한다면 임시파일을 바로 제거한다.
            handleDeleteTempFiles()
        }
    }, [])

    useEffect(() => {
        filesRef.current = files
    }, [files])

    useEffect(() => {
        if (mainID >= 0) {
            setQueryType(QueryTypes.UPDATE)
            getMain(mainID).then(response => {
                if (response.id >= 0) {
                    setImportanceChecked(response.board_importance_status)
                    setBoardID(response.board.id)
                    setTitle(response.board.title)
                    setFiles(response.board.files)
                    getProcessContent(response.board.content)
                }
            })
        } else {
            setQueryType(QueryTypes.CREATE)
        }
    }, [mainID])

    // 내용을 사용자에게 보여줄 수 있도록 데이터를 처리한다.
    const getProcessContent = async (content) => {
        const contentWithImage = FileUtils.replaceMedieFileURL(content)
        setContent(contentWithImage)
    }

    const handleChangeTitle = (v) => {
        setTitle(v)
    }
    
    const handleSubmit = async () => {
        const imageUploadedContent = FileUtils.getRequestContent(content)
        let board = new Board()
        board.id = boardID
        board.title = title
        board.boardStatus = BoardStatusTypes.VISIBLE
        board.content = imageUploadedContent
        board.board_importance_status = importanceChecked ? BoardImportnaceStatusTypes.IMPORTANT : BoardImportnaceStatusTypes.DEFAULT
        board.files = files.filter(f => f.isTemp) // board에 등록 전인 파일을 등록한다.
        setFiles(files.map(f => { f.isTemp = false; return f; }))

        if (queryType === QueryTypes.CREATE) {
            addMain(board).then(response => {
                if (response.id >= 0) {
                    close(true)
                }
            })
        } else if (queryType === QueryTypes.UPDATE) {
            updateMain(mainID, board).then(response => {
                if (response.id >= 0) {
                    close(true)
                }
            })
        }
    }

    const handleDeleteTempFiles = () => {
        filesRef.current.map(file => {
            if (file.isTemp) {
                deleteFile(file.id)
            }
        })
    }

    return (
        <BoardEditContainer>
            <BoardEditLayout>
                <PerformValueInputTitle>
                    <span>
                        {queryType === QueryTypes.CREATE && "공지사항 추가"}
                        {queryType === QueryTypes.UPDATE && "공지사항 수정"}
                    </span>
                </PerformValueInputTitle>
                <BoardEditTitleContainer>
                    <ItemInputLabel className="max-sm:w-14 sm:w-24 text-center">
                        제목
                    </ItemInputLabel>

                    <BoardEditTitleInput value={title} onChange={(e) => { handleChangeTitle(e.target.value) }} />
                </BoardEditTitleContainer>
                <BoardEditTitleContainer className="mt-0">
                    <div className="max-sm:w-14 sm:w-24 h-full flex justify-center items-center">
                        <span className="text-red-500 text-sm">중요함</span>
                    </div>
                    <input
                        type="checkbox"
                        checked={importanceChecked}
                        onClick={() => {setImportanceChecked(!importanceChecked)}}
                    />
                </BoardEditTitleContainer>
                

                <BoardEditEditorContainer>
                    {/* <WebEditor value={content} setValue={setContent} /> */}
                    <CKEditor value={content} setValue={setContent} />
                </BoardEditEditorContainer>
                <BoardEditFileContainer>
                    <BoardFileUploader id="notice-file-upload" boardFiles={files} setBoardFiles={setFiles} />
                </BoardEditFileContainer>
                <BoardEditControlBox>
                    <BoardEditFileButton onClick={() => { setShowFileModal(true) }}>
                        파일 업로드
                    </BoardEditFileButton>
                    <BoardEditSubmitButton onClick={() => { handleSubmit() }}>
                        작성
                    </BoardEditSubmitButton>
                </BoardEditControlBox>
            </BoardEditLayout>

            <BoardEditFileModalContainer isShow={showFileModal} onClick={(e) => { setShowFileModal(false) }}>
                <BoardEditFileModalLayout onClick={(e) => { e.stopPropagation() }}>
                    <BoardFileUploader id="notice-file-upload" boardFiles={files} setBoardFiles={setFiles} />
                </BoardEditFileModalLayout>
            </BoardEditFileModalContainer>
        </BoardEditContainer>
    )
}



