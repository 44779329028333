import Paginator from "../Paginator"
import Utils from "../../utils/Utils"
import GiftiCoupon from "./GiftiCoupon"
import CouponApplication from "./CouponApplication"

export default class {
    constructor() {
        this.id = -1
        this.couponID = -1
        this.coupon = new GiftiCoupon()
        this.applicationID = -1
        this.application = new CouponApplication()
        this.trID = ""
        this.orderNo = ""
        this.pinNo = ""
        this.imgUrl = ""
        this.mmsMsg = ""
        this.mmsTitle = ""
        this.phoneNo = ""
        this.gubun = ""
        this.memo = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.trID = response["tr_id"]
        this.orderNo = response["order_no"]
        this.pinNo = response["pin_no"]
        this.imgUrl = response["img_url"]
        this.mmsMsg = response["mms_msg"]
        this.mmsTitle = response["mms_title"]
        this.phoneNo = response["phone_no"]
        this.gubun = response["gubun"]
        this.memo = response["memo"]

        this.created = response["created"]
        this.updated = response["updated"]

        const couponResponse = response["coupon"]
        const applicationResponse = response["application"]

        if (!Utils.isNullOrUndefined(couponResponse)) {
            this.coupon = new GiftiCoupon()
            this.coupon.convertByResponse(couponResponse)
        }
        if (!Utils.isNullOrUndefined(applicationResponse)) {
            this.application = new CouponApplication()
            this.application.convertByResponse(applicationResponse)
        }
    }

    convertToRequest() {
        let request = {
            id: this.id,
            coupon_id: this.couponID,
            application_id: this.applicationID,
            tr_id: this.trID,
            order_no: this.orderNo,
            pin_no: this.pinNo,
            img_url: this.imgUrl,
            mms_msg: this.mmsMsg,
            mms_title: this.mmsTitle,
            phone_no: this.phoneNo,
            gubun: this.gubun,
            memo: this.memo,
        }

        return request
    }
}
