import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import PropTypes from "prop-types"
import { PaginationStyles } from "../types/StyleTypes"

// const StyleCircleButtons = {
//     PaginationBox: (props) => (`
//         p-1 bg-black/30
//     `),
//     Button: (props) => (`
//         w-10 h-10 m-1
//         text-sm
//         bg-white hover:bg-gray-100 rounded-full drop-shadow
//         duration-200
//         ${(props) => props.selected && "bg-sky-300 hover:bg-sky-200"}
//     `)
// }

// const StyleNoneBGCircleButtons = {
//     PaginationBox: (props) => (`
//         p-1 bg-black/30
//     `),
//     Button: (props) => (`
//         w-10 h-10 m-1
//         text-sm
//         hover:bg-gray-100 rounded-full drop-shadow
//         duration-200
//         ${(props) => props.selected && "bg-sky-300 hover:bg-sky-200"}
//     `)
// }

export const PaginationBox = tw.div`
    w-fit flex
    ${(props) =>
        props.styleType === PaginationStyles.NoneBGCircleButtons &&
        `
        flex-center max-lg:h-full lg:h-10 px-2 bg-white rounded-full drop-shadow
    `}
`

export const PaginationButton = tw.button`
    ${(props) =>
        props.styleType === PaginationStyles.CircleButtons &&
        `
        w-10 h-10 m-1
        text-sm
        bg-white hover:bg-slate-100 rounded-full drop-shadow
        duration-200
        ${props.selected && "bg-sky-300 hover:bg-sky-200"}}
        ${props.loading && "bg-slate-300"}}
    `}
    ${(props) =>
        props.styleType === PaginationStyles.NoneBGCircleButtons &&
        `
        w-7 h-7 mx-0.5
        text-sm
        hover:bg-slate-100 rounded-full
        duration-200
        ${props.selected && "bg-nightblue-400 text-white hover:bg-nightblue-200"}}
        ${props.loading && "bg-slate-300 text-white"}}
    `}
    ${(props) =>
        props.styleType === PaginationStyles.TextButtons &&
        `
        w-10 h-10 mx-0.5
        text-base text-slate-500
        rounded-full hover:bg-slate-200
        duration-200
        ${props.selected && "font-bold text-black"}}
        ${props.loading && "font-bold"}}
    `}
`

// Pagination.propTypes = {
//     page: PropTypes.number.isRequired,
//     setPage: PropTypes.func.isRequired,
//     pageCount: PropTypes.number.isRequired,
//     maxPageButtons: PropTypes.number.isRequired,
// }
export function Pagination({
    page,
    setPage,
    itemCount,
    pageSize,
    maxPageButtons,
    styleType = PaginationStyles.CircleButtons,
}) {
    const [tempPageIndex, setTempPageIndex] = useState(page)

    const [pageIndexes, setPageIndexes] = useState([])
    const [pageCount, setPageCount] = useState(0)

    useEffect(() => {
        let indexes = []

        let _pageCount = Math.ceil(itemCount / pageSize)
        setPageCount(_pageCount)

        const first = (Math.ceil(page / maxPageButtons) - 1) * maxPageButtons + 1
        for (let i = first; i < first + maxPageButtons; i++) {
            indexes.push(i)

            if (i === _pageCount) {
                break
            }
        }

        setPageIndexes(indexes)
        setTempPageIndex(page)
    }, [page, itemCount, pageSize])

    const handlePage = (index) => {
        setTempPageIndex(index)
        setPage(index)
    }

    const handlePrev = () => {
        handlePage((Math.floor(page / maxPageButtons) - 1) * maxPageButtons + 1)
    }

    const handleNext = () => {
        handlePage((Math.floor((page - 1) / maxPageButtons) + 1) * maxPageButtons + 1)
    }

    return (
        <React.Fragment>
            {pageCount <= 1 ? (
                <React.Fragment />
            ) : (
                <PaginationBox styleType={styleType}>
                    {page > maxPageButtons && (
                        <PaginationButton
                            styleType={styleType}
                            onClick={() => {
                                handlePrev()
                            }}
                            loading={pageIndexes[0] > tempPageIndex && tempPageIndex !== page}
                        >
                            {"<<"}
                        </PaginationButton>
                    )}
                    {pageIndexes.map((pageIndex) => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <PaginationButton
                                key={pageIndex}
                                onClick={() => {
                                    handlePage(pageIndex)
                                }}
                                selected={pageIndex === page}
                                loading={pageIndex === tempPageIndex && tempPageIndex !== page}
                                styleType={styleType}
                            >
                                {pageIndex}
                            </PaginationButton>
                        )
                    })}
                    {page <= Math.floor(pageCount / maxPageButtons) * maxPageButtons && pageCount > maxPageButtons && (
                        <PaginationButton
                            styleType={styleType}
                            onClick={() => {
                                handleNext()
                            }}
                            loading={pageIndexes[pageIndexes.length - 1] < tempPageIndex && tempPageIndex !== page}
                        >
                            {">>"}
                        </PaginationButton>
                    )}
                </PaginationBox>
            )}
        </React.Fragment>
    )
}
