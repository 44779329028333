import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {NoticeData}  from '../../constants/ConstNotice'
import { Link } from 'react-router-dom';
import tw from 'tailwind-styled-components'
import { NoticeCon } from './NoticeStyle';
import BoardDetail from '../../components/boards/BoardDetail';
import { addBoardNotice, deleteBoardNotice, getBoardNotice, updateBoardNotice } from '../../apis/api/boards';
import BoardNotice from '../../types/notices/BoardNotice';
import { ModalContainer } from '../../components/ModalContainer';
import { AccessRules } from '../../rules/AccessRules';
import BoardEdit from '../../components/boards/BoardEdit';
import { RESPONSIVE_WIDTH } from '../../constants/ConstUI';



export default function NoticeDetail() {
    const { itemID } = useParams()
    let navigate = useNavigate()

    const [isLoading, setLoading] = useState(false)
    const [notice, setNotice] = useState(new BoardNotice())

    const [showNoticeEdit,setShowNoticeEdit] = useState(false)

    useEffect(() => {
        getNotice()
    }, [itemID])

    const getNotice = () => {
        setLoading(true)
        getBoardNotice(itemID).then(response => {
            setNotice(response)
            setTimeout(()=>{
                setLoading(false)
            }, 100)
        }).catch(error => {
            setLoading(false)
        })
    }

    const handleOpenUpdate = () => {
        setShowNoticeEdit(true)
    }

    const handleCloseUpdate = (refresh) => {
        if (refresh) {
            getNotice()
        }
        setShowNoticeEdit(false)
    }

    const handleDelete = () => {
        deleteBoardNotice(itemID).then(response => {
            if (response) {
                navigate("/notice")
            }
        })
    }

    return(
        <NoticeCon>
            <BoardDetail 
                board={notice.board} 
                importance={notice.board_importance_status}
                onUpdate={handleOpenUpdate} 
                onDelete={handleDelete} 
                readOnly={!AccessRules.noticeWrite()} 
                isLoading={isLoading}
            />

            <ModalContainer show={showNoticeEdit} setShow={setShowNoticeEdit} mobileScreenHeight={RESPONSIVE_WIDTH.LG}>
                {/* <NoticeEdit noticeID={notice.id} close={handleCloseUpdate} /> */}
                <BoardEdit 
                    mainID={notice.id} 
                    getMain={getBoardNotice} 
                    addMain={addBoardNotice} 
                    updateMain={updateBoardNotice} 
                    close={handleCloseUpdate} 
                />
            </ModalContainer>
        </NoticeCon>
    )
}