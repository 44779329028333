import React, { useState, useEffect } from "react"
import {
    LoginButton,
    LoginLayout,
    SignupButton,
    LoginImageContainer,
    LoginCol,
    LoginTitle,
    LoginOptionCol,
    LoginOptionItem,
    LoginOptionSpan,
    LoginRegistCol,
    LoginRegistSpan,
    LoginInputCol,
    LoginRegistBox,
    LoginContainer,
} from "./LoginStyle"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import ConstCookie from "../../../constants/ConstCookie"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { getUserByLogin } from "../../../services/users"
import { UserInput } from "../Inputs"
import Login from "./Login"
import FindPassword from "./FindPassword"
import FindEmail from "./FindEmail"
import LogoLogin from "../../../static/LogoLogin.png"
import LogBg from "../../../static/LogBg.png"
import LogoWhite from "../../../static/LogoWhite.png"

import "./login.css"
import LoginList from "./LoginList"
import LoginBrand from "./LoginBrand"

export const LoginComponentTypes = {
    LIST: 0,
    EMAIL_LOGIN: 1,
    FIND_PASSWORD: 2,
    FIND_EMAIL: 3,
    MARKETER_KAKAO: 4,
    BRAND_LOGIN: 5,
}

// 레이아웃, 크기, 위치, 마진, 패딩,
// 텍스트(레이아웃, 사이즈, 무게, 색상(호버)),
// 색상(호버), 보더(호버), 곡률, 그림자
// 트랜지션, 가시성, 투명도,
// 오버플로우, 기타
const LoginMain = ({ defaultPage = LoginComponentTypes.EMAIL_LOGIN, setShowComponent }) => {
    const { actions } = useUserStore()
    const location = useLocation()
    const navigation = useNavigate()
    const currentPath = location.pathname
    const isSignupPage = currentPath.startsWith("/signup")
    const [loginComponentType, setLoginComponentType] = useState(defaultPage)

    const handleLogin = async (email, password) => {
        let result = false
        if (Utils.isStringNullOrEmpty(email)) {
            alert("이메일을 입력 해주세요")
            return result
        } else if (Utils.isStringNullOrEmpty(password)) {
            alert("비밀번호 입력 해주세요")
            return result
        } else {
            const user = await getUserByLogin(email, password)

            if (user !== undefined) {
                result = true
                actions.setEmail(user.email)
                actions.setName(user.name)
                actions.setUserType(user.userType)
                actions.setUser(user)
                if (isSignupPage) {
                    navigation("/")
                }
            }
            return result
        }
    }

    const handleLoginComponentType = (componentType) => {
        setLoginComponentType(componentType)
    }

    return (
        <LoginLayout>
            {/* {!isMobile && (
            )} */}
            <LoginImageContainer>
                <div className="h-full w-full flex flex-center relative overflow-hidden">
                    <img
                        src={LogBg}
                        className="absolute w-[125%] h-[125%] max-w-none max-h-none opacity-90 translate-login"
                    />
                    <div className="absolute flex flex-center w-full h-full backdrop-blur-[2px]">
                        <img src={LogoLogin} className="w-56 drop-shadow-xl" />
                    </div>
                </div>
            </LoginImageContainer>

            {loginComponentType === LoginComponentTypes.LIST && (
                <LoginList
                    login={handleLogin}
                    setShowComponent={setShowComponent}
                    setLoginComponentType={handleLoginComponentType}
                />
            )}
            {loginComponentType === LoginComponentTypes.EMAIL_LOGIN && (
                <Login
                    login={handleLogin}
                    setShowComponent={setShowComponent}
                    setLoginComponentType={handleLoginComponentType}
                />
            )}
            {loginComponentType === LoginComponentTypes.BRAND_LOGIN && (
                <LoginBrand
                    login={handleLogin}
                    setShowComponent={setShowComponent}
                    setLoginComponentType={handleLoginComponentType}
                />
            )}
            {loginComponentType === LoginComponentTypes.FIND_EMAIL && (
                <FindEmail setLoginComponentType={handleLoginComponentType} />
            )}
            {loginComponentType === LoginComponentTypes.FIND_PASSWORD && (
                <FindPassword setLoginComponentType={handleLoginComponentType} />
            )}
        </LoginLayout>
    )
}

export default LoginMain
