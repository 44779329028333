import React, { useRef, useEffect, useState, forwardRef } from "react"
import tw from "tailwind-styled-components"
import { ModalContainer } from "../ModalContainer"
import AppContract from "../../static/AppContract.png"
import { useNavigate, useParams } from "react-router-dom"
import { registItemByMarketer } from "../../apis/services/brandItems"
import { ItemApplyButton } from "../../pages/brandItems/ItemApplyStyle"
import ReactSignatureCanvas from "react-signature-canvas"
import { debounce, cloneDeep } from "lodash"
import { SIGN_SIZE, MOBILE_SIGN_SIZE } from "../../constants/ConstUser"
import Utils from "../../utils/Utils"

const CanvasClearButton = tw.button`
    flex-center py-2 px-4 
    bg-transparent
    ${(props) => (!props.disabled ? "text-red-500" : "text-gray-600")}
`

const UploadButton = tw.button`
    flex-center py-2 px-4 
    bg-transparent
    ${(props) => (!props.disabled ? "text-nightblue-600" : "text-gray-600")}
`

const ApplyButton = tw.button`
    flex-center max-lg:py-1 max-lg:px-3 lg:py-2 lg:px-4 text-white my-8 rounded-md duration-200
    ${(props) => (!props.disabled ? "bg-nightblue-500" : "bg-gray-400")}
`

// eslint-disable-next-line react/display-name
export const SignCanvas = forwardRef(
    (
        {
            signImage = null,
            submit,
            isLoading,
            setImage = null,
            isApply = false,
            hasOldSign = false, // 이미 저장된 서명이 있는지 여부 있으면 모바일에서 저장된 서명 조회버튼이 활성화된다.
            showApplyButton = true,
            setAppliable = null,
        },
        ref,
    ) => {
        const [isPrevImageClick, setIsPrevImageClick] = useState(false)
        const canvasBoxRef = useRef(null)
        const inputRef = useRef(null)
        const SignPrevRef = useRef(null)

        const [isUploaded, setIsUploaded] = useState(false)

        const [canvasSize, setCanvasSize] = useState(0)

        const canvasRef = useRef(null)

        React.useImperativeHandle(ref, () => ({
            handleSubmit,
        }))

        useEffect(() => {
            window.addEventListener("resize", handleResize)

            return () => {
                window.removeEventListener("resize", handleResize)
            }
        }, [])

        useEffect(() => {
            if (canvasBoxRef !== null) {
                setCanvasSize(canvasBoxRef.current.offsetWidth)
            }
        }, [canvasBoxRef.current])

        const handleResize = debounce(() => {
            if (canvasBoxRef !== null) {
                setCanvasSize(canvasBoxRef.current.offsetWidth)
            }
        }, 300)

        useEffect(() => {
            function handleClickOutside(event) {
                if (SignPrevRef.current && !SignPrevRef.current.contains(event.target)) {
                    setIsPrevImageClick(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside)
            return () => {
                document.removeEventListener("mousedown", handleClickOutside)
            }
        }, [SignPrevRef])

        // canvas 컨텐트를 초기화
        const clearCanvas = () => {
            canvasRef.current.clear()
            if (!Utils.isNullOrUndefined(setAppliable)) {
                setAppliable(false)
            }
        }

        // 모든 SignDataBox 를 preview 이미지로 채우는 함수
        const handleImageUploads = (event) => {
            clearCanvas()
            const reader = new FileReader()
            reader.onload = () => {
                const img = new Image()
                img.onload = () => {
                    const canvas = canvasRef.current.getCanvas()
                    const context = canvas.getContext("2d")
                    const size = canvasBoxRef.current.offsetWidth

                    context.drawImage(img, 0, 0, size, size)
                }
                img.src = reader.result
            }
            reader.readAsDataURL(event.target.files[0])
            setIsUploaded(true)
        }

        const handleSubmit = () => {
            // 원본 Canvas 요소 및 Context 생성
            const originalCanvas = canvasRef.current.getCanvas()
            const originalContext = originalCanvas.getContext("2d")

            // 새로운 Canvas 요소 및 Context 생성
            const scaledCanvas = document.createElement("canvas")
            const scaledContext = scaledCanvas.getContext("2d")

            // 새로운 Canvas의 크기를 설정하고 scale 적용
            const scale = SIGN_SIZE / canvasSize
            scaledCanvas.width = originalCanvas.width * scale
            scaledCanvas.height = originalCanvas.height * scale

            // scale이 적용된 Context를 사용하여 원본 Canvas의 내용을 그려줌
            scaledContext.scale(scale, scale)
            scaledContext.drawImage(originalCanvas, 0, 0)

            // 이미지 데이터를 다운로드하거나 저장
            const image = scaledCanvas.toDataURL("image/png")

            if (setImage !== null) {
                setImage(image)
            }
            submit(image)
        }

        return (
            <div className="w-full flex flex-col justify-center lg:px-6">
                <div className="flex flex-center max-sm:w-full sm:w-auto relative">
                    <div
                        className={`max-sm:h-[${MOBILE_SIGN_SIZE}px] sm:h-[${SIGN_SIZE}px] aspect-square ring-1 ring-slate-300 bg-slate-100 rounded`}
                        ref={canvasBoxRef}
                    >
                        <ReactSignatureCanvas
                            ref={canvasRef}
                            clearOnResize={false}
                            backgroundColor="rgba(150, 150, 230, 0)"
                            canvasProps={{ width: canvasSize, height: canvasSize }}
                        />
                    </div>
                    <div className="absolute bottom-4 w-full flex justify-center">
                        <span className="max-sm:text-xs sm:text-sm text-gray-400">
                            이 박스 안쪽에 서명 해주시기 바랍니다.
                        </span>
                    </div>
                </div>
                <div className="flex justify-center w-full mt-2">
                    <div className="flex justify-between items-center max-sm:min-w-full sm:min-w-[32rem]">
                        <input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleImageUploads}
                            style={{ display: "none" }}
                        />
                        <CanvasClearButton onClick={clearCanvas} disabled={isLoading}>
                            초기화
                        </CanvasClearButton>

                        <UploadButton
                            onClick={() => {
                                inputRef.current.click()
                            }}
                            disabled={isLoading}
                        >
                            이미지 업로드
                        </UploadButton>
                    </div>
                </div>

                <div className={`flex flex-col my-6 max-sm:text-sm sm:text-base text-gray-600`}>
                    <span className="flex items-center">※ 회사 인감도장(개인 사업자인 경우) 또는 대표자 서명 필수</span>
                    <span className="flex items-center">※ 최대한 박스를 꽉 채워서 서명 진행.</span>
                    <span className="flex items-center">
                        ※ 이미지 업로드시, 가로 세로 동일비율 및 꽉 채워진 이미지 사용.
                    </span>
                </div>
                <div className={`flex justify-between items-center h-14`}>
                    {hasOldSign && (
                        <button
                            ref={SignPrevRef}
                            onClick={() => {
                                setIsPrevImageClick(true)
                            }}
                            className={`max-sm:block sm:hidden relative ${
                                signImage !== null ? "bg-nightblue-500 " : "bg-gray-400"
                            } text-white  px-4 py-1 rounded-md`}
                        >
                            등록된 서명
                            {signImage !== null && (
                                <div
                                    className={`${
                                        isPrevImageClick ? "block" : "hidden"
                                    } absolute w-40 aspect-square top-[-11rem] left-0 bg-slate-100 rounded-md ring-1 ring-slate-300`}
                                >
                                    <img src={signImage} className="w-full h-full" />
                                </div>
                            )}
                        </button>
                    )}
                    {showApplyButton && (
                        <div className="flex items-center ml-auto">
                            {/* {isLoading && (
                        <svg className="animate-spin h-7 w-7 mr-3 text-nightblue-500" viewBox="0 0 24 24">
                            <path 
                                className="opacity-100" 
                                fill="currentColor" 
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    )} */}
                            <ApplyButton
                                onClick={() => {
                                    handleSubmit()
                                }}
                                disabled={isLoading}
                                className={`px-8 ml-auto`}
                            >
                                {isApply ? "신청" : "저장"}
                            </ApplyButton>
                        </div>
                    )}
                </div>
            </div>
        )
    },
)
