import FileUtils from "../../utils/FileUtils";
import Paginator from "../Paginator";
import Brand from "../users/Brand";
import BrandItemNotice from "./notices/BrandItemNotice";

export default class{
    constructor() {
        this.id = -1
        this.rev = 0
        this.contract = ""
        this.mission = ""
        this.reward = ""
        this.confirmed = ""
    }
    
    

    convertByResponse(response) {
        if (response["id"] !== null) {
            this.id = response["id"]
        }
        if (response["contract"] !== null) {
            this.contract = response["contract"]
        }
        if (response["mission"] !== null) {
            this.mission = response["mission"]
        }
        if (response["reward"] !== null) {
            this.reward = response["reward"]
        }
        if (response["rev"] !== null) {
            this.rev = response["rev"]
        }
        if (response["confirmed"] !== null) {
            this.confirmed = response["confirmed"]
        }
    }

    convertToRequest() {
        let request = {
            contract: "",
            mission: "",
            reward: "",
        }

        request.contract = FileUtils.getRequestContent(this.contract)
        request.mission = FileUtils.getRequestContent(this.mission)
        request.reward = FileUtils.getRequestContent(this.reward)

        return request
    }
}