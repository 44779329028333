import React, { useEffect, useRef, useState } from "react"

// import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../types/DataEnum"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import {
    CouponApplicationStatusTextColor,
    PointHistoryLayout,
    CouponHistoryList,
    CouponHistorySearchBox,
    MyPagePointDashPointBox,
    PointHistoryPointValueBox,
    PointHistoryUserBox,
    PointModalTitle,
    PointSetWriteLayout,
} from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"
import { useInView } from "react-intersection-observer"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { getImageFile } from "../../../apis/api/files"
import { ImageContainer } from "../../../components/ImageContainer"
import { CouponApplicationStatusTypeNames, PointHistoryTypeNames } from "../../../types/points/PointTypes"
import { UserTypes } from "../../../types/users/UserTypes"
import User from "../../../types/users/User"

const PAGE_SIZE = 20

const MyPagePointHistory = ({ showModal, setShowModal, userType, defaultUser }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)

    const [search, setSearch] = useState("")
    const [searchedValue, setSearchedValue] = useState("")
    const [selectedUser, setSelectedUser] = useState(new User()) // 스태프 전용
    const [pointHistories, setPointHistories] = useState([])

    // 스태프 전용 유저 정보
    const [isUserLoading, setUserLoading] = useState(true)
    const [userData, setUserData] = useState({})

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()
    const listRef = useRef(null)

    useEffect(() => {
        getPointHistories(1, PAGE_SIZE, "", selectedUser.id)
    }, [])

    useEffect(() => {
        setSelectedUser(defaultUser)
    }, [defaultUser])

    useEffect(() => {
        if (selectedUser.id <= 0) {
            setUserData({})
            setUserLoading(true)
        } else {
            getUserInfo()

            if (listRef.current !== null) {
                listRef.current.scrollTo({
                    top: 0,
                })
            }
        }
    }, [selectedUser])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && pointHistories.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            getPointHistories(pageIndex + 1, PAGE_SIZE, searchedValue, selectedUser.id)
        }
    }, [scrollInView])

    const getUserInfo = async () => {
        if (selectedUser.id < 0) {
            return
        }

        setUserLoading(true)

        const response = await PointApi.getPointInfo(selectedUser.id)
        setUserData(response)

        setUserLoading(false)
    }

    const getPointHistories = async (_pageIndex, _pageSize, _search, _selectedUserID = -1) => {
        // if (Utils.isStringNullOrEmpty(search)) {
        //     setPointHistories([])
        //     setPageIndex(1)
        //     setMaxPage(1)
        //     return
        // }

        const pagination = await PointApi.getPointHistories(
            _pageIndex,
            _pageSize,
            _search,
            _selectedUserID < 0 ? null : _selectedUserID,
        )

        if (pagination.count > 0) {
            if (_pageIndex > 1) {
                setPointHistories([...pointHistories, ...pagination.items])
            } else {
                setPointHistories(pagination.items)
            }
            setMaxPage(Math.ceil(pagination.count / _pageSize))
            setPageIndex(_pageIndex)
            setSearchedValue(_search)
        } else {
            setPointHistories([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        getPointHistories(1, PAGE_SIZE, search, selectedUser.id)
        if (listRef.current !== null) {
            listRef.current.scrollTo({ top: 0 })
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSelectUser = (_user) => {
        // 재선택하면 해제한다.
        if (_user.id === selectedUser.id) {
            getPointHistories(1, PAGE_SIZE, search, -1)
            setSelectedUser(new User())
        } else {
            getPointHistories(1, PAGE_SIZE, search, _user.id)
            setSelectedUser(_user)
        }
    }

    const handleResetUser = () => {
        getPointHistories(1, PAGE_SIZE, search, -1)
        setSelectedUser(new User())
    }

    return (
        <PointHistoryLayout>
            {/* <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} /> */}

            <div className="flex flex-col w-full h-full max-sm:p-4 sm:p-6">
                <PointModalTitle className="px-2">포인트 내역</PointModalTitle>
                {userType === UserTypes.STAFF &&
                    defaultUser.id < 0 &&
                    (selectedUser.id < 0 ? (
                        <CouponHistorySearchBox>
                            <input
                                type="text"
                                placeholder="회원 검색"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onKeyPress={handleEnter}
                            />

                            <Search
                                onClick={() => {
                                    handleSearch()
                                }}
                            />
                        </CouponHistorySearchBox>
                    ) : (
                        <div className="flex items-center mb-2">
                            <button
                                className="px-4 py-1 rounded hover:bg-slate-200 duration-200 text-nightblue-600"
                                onClick={() => {
                                    handleResetUser()
                                }}
                            >
                                돌아가기
                            </button>
                        </div>
                    ))}

                {selectedUser.id >= 0 && (
                    <PointHistoryUserBox>
                        {/* 유저 정보 */}
                        <div className="flex justify-between w-full">
                            <span className="text-white text-lg">{selectedUser.getNickname()}</span>
                            <div className="flex flex-col text-slate-200 text-xs">
                                <span>{selectedUser.email}</span>
                                <span className="text-right">
                                    {selectedUser.name} | {selectedUser.tel}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-end space-x-4 mt-2">
                            <PointHistoryPointValueBox>
                                <span className="label">보유 중인 포인트</span>
                                <span className={`value ${isUserLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(userData["my_point"], TextFormats.NUMBER)}
                                </span>
                            </PointHistoryPointValueBox>
                            <PointHistoryPointValueBox>
                                <span className="label">총 누적 포인트 / 추천인</span>
                                <span className={`value ${isUserLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(userData["my_accum_point"], TextFormats.NUMBER)} /{" "}
                                    {Utils.textFormat(userData["my_recommands"], TextFormats.NUMBER)}
                                </span>
                            </PointHistoryPointValueBox>

                            <PointHistoryPointValueBox>
                                <span className="label">신청한 쿠폰</span>
                                <span className={`value ${isUserLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(userData["my_coupons_apply"], TextFormats.NUMBER)}
                                </span>
                            </PointHistoryPointValueBox>
                            <PointHistoryPointValueBox>
                                <span className="label">받은 쿠폰</span>
                                <span className={`value ${isUserLoading ? "loading" : ""}`}>
                                    {Utils.textFormat(userData["my_coupons_done"], TextFormats.NUMBER)}
                                </span>
                            </PointHistoryPointValueBox>
                        </div>
                    </PointHistoryUserBox>
                )}

                <CouponHistoryList ref={listRef}>
                    {pointHistories.map((history, index) => (
                        <Item
                            key={index}
                            history={history}
                            userType={userType}
                            handleSelectUser={handleSelectUser}
                            hasSelectedUser={selectedUser.id >= 0}
                        />
                    ))}
                    <div className="w-full h-2" ref={scrollRef}></div>
                </CouponHistoryList>
            </div>
        </PointHistoryLayout>
    )
}

export default MyPagePointHistory

const Item = ({ history, userType, handleSelectUser, hasSelectedUser }) => {
    // const isShowDescription = userType === UserTypes.STAFF || !Utils.isStringNullOrEmpty(history.description)
    const isShowDescription = true
    return (
        <div className="flex flex-col w-full p-2">
            <span className={`font-semibold ${history.pointStatusColor()}`}>
                {history.pointStatus() === 1 && "+"}
                {history.pointStatus() === -1 && "-"}
                {Utils.textFormat(history.point, TextFormats.NUMBER)}P
            </span>
            {/* 메모 (일반 회원이고 내용이 없으면 보여주지 않는다.) */}
            {/* {isShowDescription && (
                <div className="flex w-full min-h-[2rem] max-h-[5rem] mt-1 mb-2 bg-slate-200 rounded-lg"></div>
            )} */}
            <p className="my-1 text-sm text-slate-600">{history.description}</p>
            <div className="flex justify-between items-center mt-auto">
                <span className="font-light text-slate-600 text-xs">{PointHistoryTypeNames[history.historyType]}</span>
                <span className="font-light text-slate-500 text-xs">{history.created}</span>
            </div>
            {userType === UserTypes.STAFF && !hasSelectedUser && (
                <div
                    className="flex items-center w-full h-6 px-1 py-1 mt-1 bg-slate-200 hover:bg-slate-300 rounded text-xs divide-x divide-slate-400 [&>span]:px-1 cursor-pointer duration-200"
                    onClick={() => {
                        handleSelectUser(history.user)
                    }}
                >
                    <span>{history.user.email}</span>
                    <span className="max-sm:hidden">{history.user.name}</span>
                    <span className="max-sm:hidden">{history.user.nickname}</span>
                    <span className="max-sm:hidden">{history.user.tel}</span>
                    <span>보유 {Utils.textFormat(history.user.points, TextFormats.NUMBER)}P</span>
                </div>
            )}
            {/* <div className="flex flex-col w-full">
                <span className="font-semibold text-black">{application.coupon.title}</span>
                <span className="flex items-center font-light text-slate-700 text-sm mt-auto">
                    {Utils.textFormat(application.paymentPrice, TextFormats.NUMBER)}P -
                    <CouponApplicationStatusTextColor className="ml-1" $status={application.applicationStatus}>
                        {CouponApplicationStatusTypeNames[application.applicationStatus]}
                    </CouponApplicationStatusTextColor>
                </span>
                <span className="font-light text-slate-500 text-xs">구입일 {application.created}</span>
            </div>
            <div className="aspect-square flex-shrink-0 h-full ml-2 rounded overflow-hidden">
                <ImageContainer loaded={imageLoaded}>
                    <img
                        className="w-full h-full object-cover bg-center"
                        src={imageSource}
                        alt={null}
                        ref={imageRef}
                        onLoad={imageSetLoad}
                    />
                </ImageContainer>
            </div> */}
        </div>
    )
}
