import { useMouseHover } from '../../hooks/useMouseHover'
import React,{useRef,useState,useEffect} from 'react'
import tw from 'tailwind-styled-components'
// import {InformationCircle} from '@styled-icons/heroicons-outline/InformationCircle'
import {InformationCircle} from '@styled-icons/ionicons-outline/InformationCircle'
import { useOnScreen } from '../../hooks/useOnScreen'
import { AlignTypes } from '../../types/StyleTypes'

const InformationIcon = tw(InformationCircle)`
    h-full scale-110 mr-0.5
`

// const InformationIcon = tw(InformationCircle)`
//     h-full mt-0.5
// `
const HoverContainer = tw.div`
    flex w-[max-content] h-6 items-center text-gray-500 relative  rounded-md
`

const TooltipContainer = tw.div`
    hover:bg-gray-100
    px-2 py-1 rounded-md
    flex items-center h-6
    text-nightblue-400 text-sm
    cursor-pointer
`
export const TooltipHoverBox = tw.div`
    absolute p-2 bg-white top-7 drop-shadow-xl border
    rounded-md
    max-sm:left-0 max-md:left-6
    ${(props) => props.ishovering ? "opacity-100 z-50" : "opacity-0 z-[-10]"}
    ${(props) => props.alignType === AlignTypes.NONE && "hidden"}
    ${(props) => props.alignType === AlignTypes.LEFT && "md:right-6"}
    ${(props) => props.alignType === AlignTypes.RIGHT && "md:left-6"}
`

export function InformationHover({alignType=AlignTypes.LEFT, children=null}) {
    const [isHovering, setHovering] = useState(false)

    return (
        <HoverContainer>
            {/* <TooltipContainer ref={mouseRef}> */}
            <TooltipContainer onMouseOver={()=>{setHovering(true)}} onMouseLeave={()=>{setHovering(false)}}>
                <InformationIcon onMouseOver={()=>{setHovering(true)}} onMouseLeave={()=>{setHovering(false)}} />
                <span>
                    작성 가이드
                </span>
            </TooltipContainer>
            <TooltipHoverBox ishovering={isHovering} alignType={alignType}>
                {children}
            </TooltipHoverBox>
        </HoverContainer>
    )
}