import tw from "tailwind-styled-components"
import styled from "styled-components"
import { ItemLayout } from "../item/MyPageAdItemStyle"
import { ListControlItem, MyPageInputModalLayout } from "../MyPageMainStyle"
import { CouponApplicationStatusTypes } from "../../../types/points/PointTypes"

export const MyPagePointLayout = tw(ItemLayout)`
    flex flex-col
`
export const MyPagePointDashBox = tw.div`
    flex max-md:flex-col justify-between items-center w-full max-sm:p-0 sm:p-4
    rounded-lg sm:bg-white
    [&>div]:h-32
`
export const MyPagePointDashPointBox = tw.div`
    flex flex-col max-md:w-full md:w-44 h-full p-2
    rounded-lg bg-gradient-to-tl from-nightblue-800 to-nightblue-500
    text-white
    hover:ring-2 ring-nightblue-600
    cursor-pointer duration-200

    [&>.title]:mb-auto [&>.title]:text-base [&>.title]:font-semibold [&>.title]:select-none
    [&>.label]:ml-auto [&>.label]:text-xs [&>.label]:text-white/80 [&>.label]:select-none
    [&>.value]:ml-auto [&>.value]:text-sm [&>.value]:text-white [&>.value]:px-1 [&>.value]:rounded
    [&>.value.loading]:text-transparent [&>.value.loading]:bg-white/20 [&>.value.loading]:animate-pulse
`

export const PointModalTitle = tw.span`
    text-xl font-medium px-4 my-2
`
export const PointSetWriteLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[80vw] sm:w-[480px]
    rounded-lg
    p-0 overflow-hidden
    relative
`

export const MyPageCouponListLayout = tw.div`
    flex flex-col w-full
`

export const MyPageCouponListGrid = tw.div`
    grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-3 max-xl:grid-cols-4 xl:grid-cols-5 
    gap-3
    pt-6
    border-t-2 border-nightblue-800
`

export const MyPageCouponBox = tw.div`
    relative
    flex flex-col w-full p-3
    rounded-md bg-white
    hover:drop-shadow-lg duration-200
`
export const MyPageCouponImageBox = tw.div`
    aspect-square rounded-lg w-full
    bg-slate-200
    overflow-hidden select-none
`
export const MyPageCouponContentBox = tw.div`
    flex flex-col w-full mt-2
    [&>.title]:font-medium [&>.title]:text-base [&>.title]:truncate
    [&>.sub]:text-slate-700 [&>.sub]:text-sm
`
export const MyPageCouponRequireContainer = tw.div`
    absolute left-0 top-1
    flex
    [&>svg>polygon]:fill-amber-600
`
export const MyPageCouponRequireBox = tw.div`
    flex items-center pl-2 pr-3 py-0.5
    text-xs
    ${(props) => (props.$is_active ? "bg-emerald-100 text-emerald-800" : "bg-slate-200 text-red-600")}
    rounded-r-full
    font-light
`
export const MyPageCouponRequireTriangle = styled.div`
    width: 24px;
    height: 12px;
    border-bottom: 12px solid transparent;
    border-top: 12px solid rgb(217 119 6);
    border-left: 12px solid rgb(217 119 6);
    border-right: 6px solid transparent;
`

export const CouponWriteLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[80vw] sm:w-[640px]
    rounded-lg
    p-0 overflow-hidden
    relative
`
export const CouponViewLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[90vw] max-sm:h-auto max-sm:max-h-[90vh]
    max-md:w-[70vw] max-md:h-auto
    max-lg:w-[60vw] max-lg:h-auto
    max-lg:max-w-[500px]
    lg:w-auto lg:h-[500px]
    rounded-sm
    p-0 
    max-sm:overflow-y-auto max-sm:scroll-transparent max-sm:scroll-overlay
    sm:overflow-hidden
    relative
`
export const CouponViewThumbnailBox = tw.div`
    max-lg:w-full lg:h-full aspect-square
    rounded-lg bg-slate-500
    overflow-hidden
`
export const CouponViewContentLayout = tw.div`
    flex flex-col max-lg:w-full lg:w-[21rem] lg:h-full space-y-2
`
export const CouponViewContentBox = tw.div`
    flex flex-col w-full h-full p-3
    bg-slate-100 rounded-lg
    [&>.section-title]:text-sm [&>.section-title]:text-nightblue-700 [&>.section-title]:font-semibold
    [&>.section-title]:pb-1 [&>.section-title]:border-b [&>.section-title]:border-slate-400
`
export const CouponViewContentColInfo = tw.div`
    flex flex-col w-full mt-1 text-sm
    [&>.key]:font-light [&>.key]:text-slate-600
    [&>.value]:font-normal [&>.value]:text-slate-800
    
`
export const CouponViewContentInfo = tw.div`
    flex justify-between w-full mt-1
    text-sm
    [&>.key]:font-light [&>.key]:text-slate-600 [&>.key]:flex-shrink-0
    [&>.value]:font-medium [&>.value]:text-slate-800 [&>.value]:max-w-[calc(100%-52px)] [&>.value]:duration-200 [&>.value]:rounded
    [&>.value.loading]:w-12 [&>.value.loading]:bg-slate-200 [&>.value.loading]:text-transparent [&>.value.loading]:animate-pulse
`
export const CouponViewControlBox = tw.div`
    flex flex-shrink-0 w-full h-10

`
export const CouponViewCouponDetailPre = tw.pre`
    absolute w-full h-full p-2
    text-xs 
    hover:bg-slate-200 rounded duration-200
    overflow-y-auto scroll-transparent scroll-overlay
    cursor-pointer
`

export const CouponViewPurchaseButton = tw.button`
    w-full h-full
    ${(props) =>
        props.$status === CouponApplicationStatusTypes.WAIT
            ? "bg-nightblue-500 hover:bg-nightblue-600 "
            : "bg-slate-500 hover:bg-slate-600 "}
    text-white
    rounded-lg duration-200
`

export const CouponHistoryLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[95vw] sm:w-[640px] h-[80vh]
    rounded-lg bg-slate-50
    p-0 overflow-hidden
    relative
`
export const CouponHistorySearchBox = tw.div`
    flex justify-between items-center w-full h-9 px-3 py-1 my-2 
    rounded-md bg-white 
    border border-slate-300
    [&>input]:w-full [&>input]:text-slate-700
    [&>svg]:h-full [&>svg]:py-0.5 [&>svg]:ml-2
    [&>svg]:text-nightblue-600 [&>svg]:cursor-pointer
`

export const CouponHistoryList = tw.div`
    flex flex-col w-full h-full my-2
    divide-y divide-slate-200
    overflow-y-auto scroll-transparent scroll-overlay
`
export const CouponApplicationStatusTextColor = tw.div`
    ${(props) => (props.$status === CouponApplicationStatusTypes.APPLY ? "text-blue-600" : "")}
    ${(props) => (props.$status === CouponApplicationStatusTypes.REFUND_APPLY ? "text-red-600" : "")}
    ${(props) => (props.$status === CouponApplicationStatusTypes.FAILED ? "text-purple-600" : "")}
    ${(props) => (props.$status === CouponApplicationStatusTypes.REJECT ? "text-orange-600" : "")}
`

export const PointHistoryLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[85vw] sm:w-[560px] h-[70vh]
    rounded-lg bg-slate-50
    p-0 overflow-hidden
    relative
`
export const PointHistoryUserBox = tw(MyPagePointDashPointBox)`
    max-md:w-full md:w-full h-24 hover:ring-0
    cursor-default
`
export const PointHistoryPointValueBox = tw.div`
    flex flex-col
    [&>.label]:text-xs [&>.label]:text-white/80
    [&>.value]:text-sm [&>.value]:text-white
    [&>.value]:h-5 [&>.value.loading]:w-12 
    [&>.value.loading]:rounded-md [&>.value.loading]:bg-white/50
    [&>.value.loading]:animate-pulse [&>.value.loading]:text-transparent
`

export const CouponTradeModalLayout = tw(MyPageInputModalLayout)`
    max-sm:min-w-[300px] max-sm:w-[50vw] sm:w-[320px]
    rounded-lg bg-slate-50
    p-0 overflow-hidden
    relative
`
