import Utils from "../../../utils/Utils"
import User from "../../users/User"
import UserMarketer from "../../users/UserMarketer"
import { MarketerStatusTypes, MarketerSuspendStatusTypes } from "../BrandItemTypes"
import BrandItemMarketerGroup from "./BrandItemMarketerGroup"

export default class {
    constructor() {
        this.id = -1
        this.marketerSender = new UserMarketer()
        this.itemMarketerSenderID = -1
        this.marketerReceiver = new UserMarketer()
        this.inviteStatusType = MarketerStatusTypes.WAIT
        this.memo = ""

        this.manualName = ""
        this.manualTel = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]

        const marketerSenderResponse = response["marketer_sender"]
        this.marketerSender = new UserMarketer()
        if (!Utils.isNullOrUndefined(marketerSenderResponse)) {
            this.marketerSender.convertByResponse(marketerSenderResponse)
        }
        const marketerReceiverResponse = response["marketer_receiver"]
        this.marketerReceiver = new UserMarketer()
        if (!Utils.isNullOrUndefined(marketerReceiverResponse)) {
            this.marketerReceiver.convertByResponse(marketerReceiverResponse)
        }

        this.itemMarketerSenderID = response["item_marketer_sender_id"]
        if (this.itemMarketerSenderID === null) {
            this.itemMarketerSenderID = -1
        }
        this.inviteStatusType = response["invite_status_type"]
        if (this.inviteStatusType === null) {
            this.inviteStatusType = 0
        }
        this.memo = response["memo"]
        if (this.memo === null) {
            this.memo = 0
        }
        this.manualName = response["manual_name"]
        if (this.manualName === null) {
            this.manualName = 0
        }
        this.manualTel = response["manual_tel"]
        if (this.manualTel === null) {
            this.manualTel = 0
        }

        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {}
}
