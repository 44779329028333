import React, { useEffect, useRef, useState } from "react"

// import Utils from "../../../../utils/Utils"
import { TextFormats } from "../../../types/DataEnum"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { useUserStore } from "../../../zustand/store"
import SpinLoading from "../../../components/loading/SpinLoading"
import {
    CouponApplicationStatusTextColor,
    CouponHistoryLayout,
    CouponHistoryList,
    CouponHistorySearchBox,
    PointModalTitle,
    PointSetWriteLayout,
} from "./MyPagePointStyle"

import * as PointApi from "../../../apis/api/points/index"
import PointSet from "../../../types/points/PointSet"
import { useInView } from "react-intersection-observer"
import { Search } from "@styled-icons/fluentui-system-filled/Search"
import { useImageLoaded } from "../../../hooks/useImageLoaded"
import { getImageFile } from "../../../apis/api/files"
import { ImageContainer } from "../../../components/ImageContainer"
import { CouponApplicationStatusTypeNames } from "../../../types/points/PointTypes"

import ModalContainerPortal from "../../../components/ModalContainerPortal"
import { MyPageInputModalLayout } from "../MyPageMainStyle"
import MyPageCouponTradeModal from "./MyPageCouponTradeModal"
import CouponApplication from "../../../types/points/CouponApplication"
import { CouponApplicationStatusTypes } from "../../../types/points/PointTypes"

const PAGE_SIZE = 20

const MyPageCouponHistory = ({ showModal, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)

    const [search, setSearch] = useState("")
    const [searchedValue, setSearchedValue] = useState("")
    const [couponApplications, setCouponApplications] = useState([])

    const [pageIndex, setPageIndex] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [scrollRef, scrollInView] = useInView()
    const listRef = useRef(null)

    const [showCouponModal, setShowCouponModal] = useState(false)
    const [selectedApplication, setSelectedApplication] = useState(new CouponApplication())

    useEffect(() => {
        getCouponHistories(1, PAGE_SIZE, "")
    }, [])

    useEffect(() => {
        // 맨 위의 아이템이 보이면 업데이트
        if (scrollInView && couponApplications.length >= PAGE_SIZE - 3 && pageIndex + 1 <= maxPage) {
            getCouponHistories(pageIndex + 1, PAGE_SIZE, searchedValue)
        }
    }, [scrollInView])

    const getCouponHistories = async (_pageIndex, _pageSize, _search) => {
        // if (Utils.isStringNullOrEmpty(search)) {
        //     setCouponApplications([])
        //     setPageIndex(1)
        //     setMaxPage(1)
        //     return
        // }

        const pagination = await PointApi.getCouponApplications(_pageIndex, _pageSize, _search)

        if (pagination.count > 0) {
            if (_pageIndex > 1) {
                setCouponApplications([...couponApplications, ...pagination.items])
            } else {
                setCouponApplications(pagination.items)
            }
            setMaxPage(Math.ceil(pagination.count / _pageSize))
            setPageIndex(_pageIndex)
            setSearchedValue(_search)
        } else {
            setCouponApplications([])
            setPageIndex(1)
            setMaxPage(1)
        }
    }

    const handleSearch = async () => {
        getCouponHistories(1, PAGE_SIZE, search)
        if (listRef.current !== null) {
            listRef.current.scrollTo({ top: 0 })
        }
    }

    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleCouponView = async (application) => {
        setSelectedApplication(application)
        setShowCouponModal(true)
    }

    return (
        <CouponHistoryLayout>
            {/* <SpinLoading isLoading={isLoading} top={"0"} isScreen={false} /> */}

            <div className="flex flex-col w-full h-full p-6">
                <PointModalTitle className="px-2">내 쿠폰</PointModalTitle>
                <CouponHistorySearchBox>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={handleEnter}
                    />

                    <Search
                        onClick={() => {
                            handleSearch()
                        }}
                    />
                </CouponHistorySearchBox>

                <CouponHistoryList ref={listRef}>
                    {couponApplications.map((application, index) => (
                        <Item key={index} application={application} handleCouponView={handleCouponView} />
                    ))}
                    <div className="w-full h-2" ref={scrollRef}></div>
                </CouponHistoryList>
            </div>

            <ModalContainerPortal
                show={showCouponModal}
                setShow={setShowCouponModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
            >
                <MyPageCouponTradeModal
                    application={selectedApplication}
                    showModal={showCouponModal}
                    setShowModal={setShowCouponModal}
                />
            </ModalContainerPortal>
        </CouponHistoryLayout>
    )
}

export default MyPageCouponHistory

const Item = ({ application, handleCouponView }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        setImageSource(application.coupon.giftiCouponThumb.goodsImgS)
    }, [application])

    const handleResend = async () => {
        if (!confirm("쿠폰 재발송 하시겠습니까?\n※최대 5회까지 발송 가능합니다.")) {
            return
        }

        const errorMessage = await PointApi.requestCouponResend(application.uuid)

        if (Utils.isStringNullOrEmpty(errorMessage)) {
            alert("발송 처리 되었습니다.\n재 발송까지 최대 20분 소요됩니다.\n※최대 5회까지 발송 가능합니다.")
        } else {
            alert(errorMessage)
        }
    }

    return (
        <div className="flex w-full max-sm:p-0 sm:p-3 max-sm:mb-2">
            <div className="flex flex-col w-full">
                <span className="font-semibold text-black">{application.coupon.title}</span>

                <span className="text-slate-600 text-sm font-light mb-1">{application.memo}</span>
                <span className="flex items-center font-light text-slate-700 text-sm mt-auto">
                    {Utils.textFormat(application.paymentPrice, TextFormats.NUMBER)}P -
                    <CouponApplicationStatusTextColor className="ml-1" $status={application.applicationStatus}>
                        {CouponApplicationStatusTypeNames[application.applicationStatus]}
                    </CouponApplicationStatusTextColor>
                </span>
                <span className="max-sm:hidden font-light text-slate-500 text-xs">구입일 {application.created}</span>

                {application.applicationStatus === CouponApplicationStatusTypes.DONE && (
                    <div
                        className="
                            flex flex-shrink-0 items-center space-x-1 mt-1
                            [&>button]:w-full [&>button]:px-1.5 [&>button]:py-0.5 [&>button]:rounded
                            max-sm:[&>button]:text-xs sm:[&>button]:text-sm [&>button]:duration-200
                        "
                    >
                        <button
                            className={`bg-nightblue-100 hover:bg-nightblue-200 text-nightblue-600 border-nightblue-400`}
                            onClick={() => {
                                handleCouponView(application)
                            }}
                        >
                            쿠폰 조회
                        </button>
                        <button
                            className={`bg-nightblue-100 hover:bg-nightblue-200 text-nightblue-600 border-nightblue-400`}
                            onClick={() => {
                                handleResend()
                            }}
                        >
                            쿠폰 메세지 재발송
                        </button>
                    </div>
                )}
            </div>
            <div className="max-sm:hidden aspect-square flex-shrink-0 h-28 ml-2 rounded overflow-hidden">
                <ImageContainer loaded={true}>
                    <img
                        className="w-full h-full object-cover bg-center"
                        src={imageSource}
                        alt={null}
                        ref={imageRef}
                        onLoad={imageSetLoad}
                    />
                </ImageContainer>
            </div>
        </div>
    )
}
