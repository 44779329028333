import React, { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'
import { getImageFile } from '../apis/api/files'
import ImageFile from '../types/files/ImageFile'
import Utils from '../utils/Utils'
import { useImageLoaded } from '../hooks/useImageLoaded'


export function ImageContainer({loaded, children}){
    return(
        <div className={`${loaded ? "opacity-100" : "opacity-0"} duration-300 relative w-full h-full`}>
            <div className="absolute-center h-full w-full">
                {/* {loaded && children} */}
                {children}
            </div>
        </div>
    )
}

const Image = tw.img`
    w-full h-full
    object-cover bg-center
    duration-300
`

export function ImageBase64({useImageWidth=false, useImageHeight=false, imageID=undefined, imageFile=undefined, imageURL=undefined, refresh=true, duration}){
    const [_imageFile, setImageFile] = useState(new ImageFile())
    const [imageSource, setImageSource] = useState("")
    const [width, setWidth] = useState("100%")
    const [height, setHeight] = useState("100%")

    const [imageRef, imageSourceLoaded, imageSetSourceLoad] = useImageLoaded() // 이미지 소스가 로딩됐는지 여부.

    useEffect(()=>{
        imageSetSourceLoad(false)
        if (imageID !== undefined) {
            // setImageFile("")
            (refresh && setImageFile(""))
            getImageFile(imageID).then((response)=>{
                setImageFile(response)
            })
        } else if (imageFile !== undefined) {
            setImageFile(imageFile)
        } else if (imageURL !== undefined && !Utils.isStringNullOrEmpty(imageURL)) {
            setImageSource(imageURL)
        } else {
            setImageSource("")
        }
    }, [imageID, imageFile, imageURL])

    useEffect(()=>{
        if (useImageWidth && _imageFile.id !== -1) {
            setWidth(_imageFile.width)
        } else {
            setWidth("100%")
        }

        if (useImageHeight && _imageFile.id !== -1) {
            setHeight(_imageFile.height)
        } else {
            setHeight("100%")
        }
        
        if (_imageFile.id >= 0) {
            setImageSource(_imageFile.getSource())
        }
    }, [_imageFile])

    const imageLoaded = !Utils.isStringNullOrEmpty(imageSource) && imageSourceLoaded

    return(
        <div className={`${imageLoaded ? "opacity-100" : "opacity-0"} ${duration && "duration-300"} relative`} style={{width:width, height:height}}>
            <div className="absolute-center h-full w-full">
                <Image 
                    ref={imageRef} 
                    src={imageSource} 
                    className={`${imageLoaded ? "opacity-100" : "opacity-0"}`} 
                    onLoad={imageSetSourceLoad} 
                    alt={null} 
                />
            </div>
        </div>
    )
}