import React, { useEffect, useRef, useState } from "react"
import { ItemCreatCon, ItemCreateInnerBot, ItemCreateTitle } from "../brand/MyPageItemCreateStyle"
import {
    ItemStatusComboApplyButton,
    ItemStatusComboBox,
    ItemStatusComboSelect,
    ItemStatusComboSelectBox,
    ItemStatusComboTitle,
    ItemSubmitControlBox,
    ItemViewToggleBox,
    ItemViewToggleButton,
    ItemViewToggleStackButton,
    ItemWriteLayout,
    ItemWriteTitle,
    ItemWriteViewTypeButton,
} from "../brand/MyPageItemBrandStyle"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { EditStateTypes, ItemTypes, QueryTypes } from "../../../../types/EditorTypes"
import {
    acceptBrandItemUpdate,
    createBrandItem,
    deleteBrandItem,
    deleteBrandItemTemp,
    getBrandItemTempView,
    getBrandItemView,
    updateBrandItem,
    updateBrandItemState,
} from "../../../../apis/api/boardItems"
import {
    AcceptButton,
    BottomButtonCon,
    DeleteButton,
    EditAcceptButton,
    EditAcceptContainer,
    EditAcceptMenuBox,
    EditAcceptMenuContainer,
    EditAcceptPreviewButton,
    ItemControlBox,
    ItemInputRow,
    ItemInputRowEditor,
    ItemStatusControlBox,
    PreviewButton,
    ResetButton,
    SaveButton,
} from "../brand/ItemInputFormStyle"

import {
    BrandItemCombo,
    BrandItemFile,
    BrandItemInput,
    BrandItemEditor,
    BrandItemTextArea,
    BrandItemImage,
} from "../../../../components/brandItems/Inputs"
import Utils from "../../../../utils/Utils"
import { useUserStore } from "../../../../zustand/store"
import AccessRules from "../../../../rules/AccessRules"
import useDropdownDetectClose from "../../../../hooks/useDropdownDetectClose"
import ConstSession from "../../../../constants/ConstSession"
import ConstLocalStorage from "../../../../constants/ConstLocalStorage"
import FileUtils from "../../../../utils/FileUtils"
import BrandItemUtils from "../../../../utils/brandItem/BrandItemUtils"
import { UserTypes } from "../../../../types/users/UserTypes"
import { useWindowHeight } from "../../../../hooks/useWindowHeight"
import SpinLoading from "../../../../components/loading/SpinLoading"
import { sendAlertTalk } from "../../../../apis/services/sms"
import { AlertTalkTemplateTypes } from "../../../../types/SmsTypes"
import Advertisement from "../../../../types/commons/Advertisement"
import {
    createAdvertisement,
    deleteAdvertisement,
    getAdvertisement,
    updateAdvertisement,
} from "../../../../apis/api/commons"
import { uploadImage } from "../../../../apis/api/files/index"
import { AdvertisementCategoryButton } from "./AdvertisementStyle"
import { AdvertisementCategories } from "../../../../types/commons/CommonTypes"

const MyPageAdvertisementWrite = ({ item, setShowModal }) => {
    // const item = new BrandItem()
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)
    const [queryType, setWriteType] = useState(QueryTypes.NONE)
    const [advertisement, setAdvertisement] = useState(new Advertisement())

    const [category, setCategory] = useState(AdvertisementCategories.MAIN_BANNER)
    const [title, setTitle] = useState("")
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [link, setLink] = useState("")
    const [content, setContent] = useState("")

    const [image1ID, setImage1ID] = useState(-1)
    const [image2ID, setImage2ID] = useState(-1)
    const [image3ID, setImage3ID] = useState(-1)
    const [image4ID, setImage4ID] = useState(-1)

    const [image1EditStateType, setImage1EditStateType] = useState(EditStateTypes.WAIT)
    const [image2EditStateType, setImage2EditStateType] = useState(EditStateTypes.WAIT)

    useEffect(() => {
        setWriteType(
            item.id >= 0 && item.editStateType !== EditStateTypes.PENDING ? QueryTypes.UPDATE : QueryTypes.CREATE,
        )

        getItemData()
    }, [item])

    const getItemData = async () => {
        // 아이템 등록시에는 temp만 보여준다.
        setLoading(true)

        const response = await getAdvertisement(item.id)
        const _advertisement = new Advertisement()
        _advertisement.convertByResponse(response)
        setAdvertisement(_advertisement)

        if (item.id < 0) {
            setLoading(false)
            return
        }

        setCategory(_advertisement.category)
        setTitle(_advertisement.title)
        setDateStart(_advertisement.dateStart)
        setDateEnd(_advertisement.dateEnd)
        setLink(_advertisement.link)
        setContent(_advertisement.content)
        setImage1ID(_advertisement.image1ID)
        setImage2ID(_advertisement.image2ID)
        setImage3ID(_advertisement.image3ID)
        setImage4ID(_advertisement.image4ID)
        setLoading(false)
    }

    const handleSubmit = async () => {
        let bResult = false

        let errorMessages = []
        if (Utils.isStringNullOrEmpty(title)) {
            errorMessages.push("제목을 입력해주세요.")
        }
        if (Utils.isStringNullOrEmpty(dateStart) || Utils.isStringNullOrEmpty(dateEnd)) {
            errorMessages.push("광고 기간을 입력해주세요.")
        }

        if (errorMessages.length > 0) {
            alert(errorMessages.join("\n"))
            return
        }

        let requestData = advertisement.convertToRequest()
        requestData["title"] = title
        requestData["date_start"] = dateStart
        requestData["date_end"] = dateEnd
        requestData["link"] = link
        requestData["content"] = content
        requestData["image1_id"] = image1ID
        requestData["image2_id"] = image2ID

        if (queryType === QueryTypes.UPDATE) {
            bResult = await updateAdvertisement(item.id, requestData)
        } else if (queryType === QueryTypes.CREATE) {
            bResult = await createAdvertisement(requestData)
        }

        if (bResult) {
            alert("저장되었습니다.")
            getItemData()
            setShowModal(false)
        } else {
            alert("요청 실패했습니다.")
        }
    }

    const handleItemDelete = async () => {
        // 스태프만 삭제가 가능하다. (논리삭제)
        let bResult = false

        if (queryType === QueryTypes.UPDATE) {
            bResult = await deleteAdvertisement(advertisement.id)
        }

        if (bResult) {
            alert("삭제 완료되었습니다.")
            setShowModal(false)
        } else {
            alert("삭제 실패했습니다.")
        }
    }

    const handleUpload1Image = (e, setImageID) => {
        if (e.target.files.length !== 1) {
            return
        }
        setImage1EditStateType(EditStateTypes.PENDING)
        uploadImage(e.target.files[0])
            .then((response) => {
                setImageID(response.id)
                setImage1EditStateType(EditStateTypes.WAIT)
            })
            .catch((error) => {
                uploadImage(e.target.files[0])
                setImage1EditStateType(EditStateTypes.FAILED)
            })
    }
    const handleUpload2Image = (e, setImageID) => {
        if (e.target.files.length !== 1) {
            return
        }
        setImage2EditStateType(EditStateTypes.PENDING)
        uploadImage(e.target.files[0])
            .then((response) => {
                setImageID(response.id)
                setImage2EditStateType(EditStateTypes.WAIT)
            })
            .catch((error) => {
                uploadImage(e.target.files[0])
                setImage2EditStateType(EditStateTypes.FAILED)
            })
    }

    return (
        <ItemWriteLayout className="sm:h-auto sm:max-h-[90vh]">
            <SpinLoading isLoading={false} top={"0"} isScreen={false} />

            <div className="flex flex-col w-full h-full p-4 overflow-y-auto scroll-transparent scroll-overlay">
                <ItemWriteTitle>광고 {queryType === QueryTypes.UPDATE ? "수정" : "등록"}</ItemWriteTitle>
                <div className="flex flex-col w-full p-4">
                    <div className="flex items-center w-full space-x-2">
                        <AdvertisementCategoryButton
                            isActive={category === AdvertisementCategories.MAIN_BANNER}
                            onClick={() => {
                                setCategory(AdvertisementCategories.MAIN_BANNER)
                            }}
                        >
                            메인 배너
                        </AdvertisementCategoryButton>
                    </div>
                    <ItemInputRow>
                        <BrandItemInput
                            type="text"
                            label={"제목"}
                            data={title}
                            setData={setTitle}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                        <BrandItemInput
                            type="date"
                            label={"광고 기간 (시작)"}
                            data={dateStart}
                            setData={setDateStart}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                        <BrandItemInput
                            type="date"
                            label={"광고 기간 (종료)"}
                            data={dateEnd}
                            setData={setDateEnd}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="md:grid-cols-1">
                        <BrandItemTextArea
                            type="text"
                            label={"메모"}
                            data={content}
                            setData={setContent}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            maxLength={40}
                        />
                    </ItemInputRow>
                    <ItemInputRow className="grid-cols-1">
                        <BrandItemInput
                            type="text"
                            label={"링크"}
                            data={link}
                            setData={setLink}
                            disabled={false}
                            isEdited={false}
                            widthFull={true}
                            helpText=""
                        />
                    </ItemInputRow>

                    <ItemInputRowEditor className="min-h-[280px]">
                        <BrandItemImage
                            type="file"
                            label={"이미지 PC"}
                            fileID={image1ID}
                            onChange={(e) => {
                                handleUpload1Image(e, setImage1ID)
                            }}
                            disabled={false}
                            editStateType={image1EditStateType}
                            imageWidth={1200}
                            imageHeight={120}
                        />

                        {/* <span className="absolute text-sm bottom-4 right-4 text-gray-400">가로:600px, 세로:400px 사이즈로 업로드</span>  */}
                        {/* <BrandItemFile
                            type="file"
                            label={"상세 이미지"}
                            fileID={descriptionImageID}
                            onChange={(e)=>{handleUploadImage(e, setDescriptionImageID)}}
                            disabled={disabled}
                            isEdited={isCheckableDetail && descriptionImageID !== itemOrigin.detail.descriptionImageID}
                        />
                        {/* 너비 맞추기 용 */}
                        {/* <ItemInputBox /> */}
                    </ItemInputRowEditor>
                    <ItemInputRowEditor className="min-h-[280px]">
                        <BrandItemImage
                            type="file"
                            label={"이미지 모바일"}
                            fileID={image2ID}
                            onChange={(e) => {
                                handleUpload2Image(e, setImage2ID)
                            }}
                            disabled={false}
                            editStateType={image2EditStateType}
                            imageWidth={600}
                            imageHeight={300}
                        />

                        {/* <span className="absolute text-sm bottom-4 right-4 text-gray-400">가로:600px, 세로:400px 사이즈로 업로드</span>  */}
                        {/* <BrandItemFile
                            type="file"
                            label={"상세 이미지"}
                            fileID={descriptionImageID}
                            onChange={(e)=>{handleUploadImage(e, setDescriptionImageID)}}
                            disabled={disabled}
                            isEdited={isCheckableDetail && descriptionImageID !== itemOrigin.detail.descriptionImageID}
                        />
                        {/* 너비 맞추기 용 */}
                        {/* <ItemInputBox /> */}
                    </ItemInputRowEditor>

                    <BottomButtonCon>
                        {queryType === QueryTypes.UPDATE ? (
                            <DeleteButton
                                onClick={() => {
                                    handleItemDelete()
                                }}
                            >
                                상품 삭제
                            </DeleteButton>
                        ) : (
                            <p></p>
                        )}
                    </BottomButtonCon>
                </div>

                <div className="flex justify-end items-center w-full h-12 px-4">
                    <button
                        className="flex flex-center px-6 py-2 text-white bg-nightblue-600 hover:bg-nightblue-700 rounded-full"
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        저장
                    </button>
                </div>
            </div>
        </ItemWriteLayout>
    )
}

export default MyPageAdvertisementWrite
