import create from 'zustand' // create로 zustand를 불러옵니다.


const initialData = {
    mainNavHide: false
}
export const useUiStore = create(set => ({
    ...initialData,
    actions: {
        setMainNavHide: (mainNavHide) => set(() => ({mainNavHide: mainNavHide})),
    }
}))


