import tw from "tailwind-styled-components"
import { MyPageInputModalLayout } from "../MyPageMainStyle"

export const PerformColumnLayout = tw.div`
    py-2 flex flex-col items-center bg-white
`

export const PerformColumnControlBox = tw.div`
flex flex-col items-center w-full p-2 border-l
`

export const PerformColumnSubItemBox = tw.div`
flex justify-between items-center w-full mt-2 px-2 py-1
rounded-lg bg-gray-100
`
export const PerformColumnSubName = tw.span`
font-medium
`
export const PerformColumnSubControlBox = tw.div`
flex flex-col items-center flex-shrink-0 h-full ml-2
`
export const PerformColumnSubDeleteButton = tw.button`
px-1
font-bold text-red-500
`
export const PerformColumnSubSaveButton = tw.button`
px-1
font-bold text-blue-500
`

export const PerformColumnSubConstraintButton = tw.button`
px-1 mt-1
text-sm text-white bg-gray-600 rounded
`

export const StaffPerformUpdateStatusContainer = tw(MyPageInputModalLayout)`
    w-[60rem] h-[85vh] max-h-[85vh]
`

export const StaffPerformUpdateOptionContainer = tw(MyPageInputModalLayout)`
    w-[36rem] h-[85vh] max-h-[85vh]
`
export const StaffPerformUpdateDateContainer = tw(MyPageInputModalLayout)`
    w-[36rem] h-[28.24rem] max-h-[28.24rem]
`

export const StaffPerformUpdateStatusControlBox = tw.div`
    flex justify-between w-full mb-2
`

export const StaffPerformUpdateStatusColorBox = tw.div`
    flex items-center
`
export const StaffPerformUpdateStatusColorButton = tw.div`
    relative
    w-12 h-7 mr-2
    rounded-lg
    cursor-pointer
`
export const StaffPerformUpdateStatusColorMessage = tw.div`
    absolute
    ${(props) => (props.isShow ? "opacity-100 z-10 top-8" : "opacity-0 -z-10 top-0")}
    flex flex-center w-[max-content] px-3 py-2
    bg-slate-100 rounded-md drop-shadow
    duration-500
`

export const StaffPerformUpdateStatusList = tw.div`
    flex flex-col w-full h-full
    overflow-y-auto scroll-transparent scroll-overlay
`
export const StaffPerformUpdateStatusBox = tw.div`
    flex flex-col w-full px-2 py-2 mb-2
    rounded-md bg-gray-100
`
export const StaffPerformUpdateStatusMainBox = tw.div`
    flex items-center w-full
`
export const StaffPerformUpdateStatusTitle = tw.span`
    w-24 text-center
`
export const StaffPerformUpdateStatusInputBox = tw.div`
    flex w-auto ml-2
`

export const StaffPerformUpdateStatusConstraintMessage = tw.span`
    w-full my-2 text-lg
`
export const StaffPerformUpdateStatusConstraintMessageName = tw.span`
    ${(props) => `text-[${props.color}]`} font-bold
`
export const StaffPerformUpdateStatusConstraintList = tw.div`
    flex flex-wrap w-full
`
export const StaffPerformUpdateStatusConstraintBox = tw.div`
    flex flex-center w-32 mr-2 mb-1
    rounded-sm
    border-2 
    ${(props) => `border-[${props.bgColor}]`}
    ${(props) => (props.selected ? `text-[${props.color}] bg-[${props.bgColor}]` : `text-[${props.bgColor}]`)}
    cursor-pointer
    ${(props) => props.disabled && "cursor-not-allowed"}
`
