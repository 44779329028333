import ConstCookie from "../../../constants/ConstCookie"
import User from "../../../types/users/User"
import { setCookie } from "../../../utils/Cookie"
import { deleteItemMarketer, registItemMarketer, updateItemContractMarketer } from "../../api/boardItems"
import { uploadSign } from "../../api/files"
import BackendResponse from "../../../constants/BackendResponse"

export const registItemByMarketer = async (user, itemID, itemMarketerParentID, marketerName, signImage) => {
    let bResult = BackendResponse.NOT_FOUND

    // 1. 아이템 마케터 신청
    const resultItemMarketer = await registItemMarketer(itemID, itemMarketerParentID, marketerName)
    if (resultItemMarketer !== BackendResponse.SUCCESS) {
        bResult = resultItemMarketer
        return bResult
    }

    // 2. 사인 이미지 업로드
    const resultSign = await uploadSign(signImage)
    if (resultSign.id < 0) {
        deleteItemMarketer(itemID, user.id)
        return bResult
    }

    // 3. 계약서 업로드
    const resultContract = await updateItemContractMarketer(itemID, resultSign.id)
    if (!resultContract) {
        deleteItemMarketer(itemID, user.id)
        return bResult
    }
    bResult = BackendResponse.SUCCESS

    return bResult
}
