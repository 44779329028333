import React, { useState, useEffect } from "react"
import WebEditor from "../../../../components/WebEditor"
import tw from "tailwind-styled-components"
import { updateBrandItemForm, getBrandItemForm } from "../../../../apis/api/commons/index"
import CKEditor from "../../../../components/ckeditor/CKEditor"

const StaffItemsContractWriterLayout = tw.div`
    w-156 h-156 flex flex-col
`

const StaffItemsContractWriterTitle = tw.div`
    w-full h-14 flex items-center px-4
`

const StaffItemsContractWriterFooter = tw(StaffItemsContractWriterTitle)`
    justify-between
`

const StaffItemsContractWriterButton = tw.button`
    px-3 py-1.5 bg-nightblue-600 text-white rounded-md 
`

const StaffItemsContractWriter = ({ setShow }) => {
    const [constarctValue, setConstarctValue] = useState(null)

    // 저장 눌렀을때 constarctValue 값 넘겨주기

    const handleonClickSaveValue = async () => {
        let bResult = false
        bResult = await updateBrandItemForm(constarctValue)

        if (bResult) {
            alert("저장되었습니다.")
        } else {
            alert("실패했습니다.")
        }
    }

    const setData = async () => {
        const data = await getBrandItemForm()
        setConstarctValue(data.content)
    }

    useEffect(() => {
        setData()
    }, [])

    return (
        <StaffItemsContractWriterLayout>
            <StaffItemsContractWriterTitle className="text-lg font-medium">계약서 작성</StaffItemsContractWriterTitle>
            <div className="w-full flex-grow overflow-auto">
                <CKEditor value={constarctValue} setValue={setConstarctValue} />
            </div>
            <StaffItemsContractWriterFooter>
                <StaffItemsContractWriterButton
                    onClick={() => {
                        setShow(false)
                    }}
                    className="bg-gray-400"
                >
                    닫기
                </StaffItemsContractWriterButton>

                <StaffItemsContractWriterButton
                    onClick={() => {
                        handleonClickSaveValue()
                    }}
                >
                    저장
                </StaffItemsContractWriterButton>
            </StaffItemsContractWriterFooter>
        </StaffItemsContractWriterLayout>
    )
}

export default StaffItemsContractWriter
