import PerformColumn from "./PerformColumn"
import PerformColumnCombo from "./PerformColumnCombo"
import PerformColumnFile from "./PerformColumnFile"
import PerformColumnStatus from "./PerformColumnStatus"
import PerformColumnText from "./PerformColumnText"
import AccessRules from "../../../rules/AccessRules"
import { useUserStore } from "../../../zustand/store"
import {
    PerformColumnPreviewTypes,
    PerformColumnFormTypes,
    PerformStaticColumnTypeNames,
    PerformStaticColumnTypes,
    PerformColumnTypes,
} from "./PerformColumnTypes"
import { RESPONSIVE_WIDTH } from "../../../constants/ConstUI"

export const getPerformColumnStaticSelect = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.SELECT
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.CHECK_BOX
    column.name = PerformStaticColumnTypeNames[column.id]
    column.width = window.innerWidth < RESPONSIVE_WIDTH.SM ? "60" : "75"

    return column
}

export const getPerformColumnStaticID = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.ID
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.TEXT
    column.name = PerformStaticColumnTypeNames[column.id]

    const columnText = new PerformColumnText()
    columnText.columnID = column.id
    columnText.previewType = PerformColumnPreviewTypes.TRUNCATE
    column.texts = [columnText]
    column.width = "75"

    return column
}

export const getPerformColumnStaticMarketer = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.MARKETER
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.TEXT
    column.name = PerformStaticColumnTypeNames[column.id]
    column.width = "120"

    const columnText = new PerformColumnText()
    columnText.columnID = column.id
    columnText.previewType = PerformColumnPreviewTypes.TRUNCATE
    column.texts = [columnText]

    return column
}

export const getPerformColumnStaticUpdate = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.UPDATE
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.TEXT
    column.name = PerformStaticColumnTypeNames[column.id]
    column.width = window.innerWidth < RESPONSIVE_WIDTH.SM ? "60" : "75"
    const columnText = new PerformColumnText()
    columnText.columnID = column.id
    columnText.previewType = PerformColumnPreviewTypes.TRUNCATE
    column.texts = [columnText]

    return column
}

export const getPerformColumnStaticCreated = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.CREATED
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.DATE
    column.name = PerformStaticColumnTypeNames[column.id]

    const columnText = new PerformColumnText()
    columnText.columnID = column.id
    columnText.previewType = PerformColumnPreviewTypes.TRUNCATE
    column.texts = [columnText]
    column.width = "120"

    return column
}

export const getPerformColumnStaticCalculated = () => {
    const column = new PerformColumn()
    column.id = PerformStaticColumnTypes.CALCULATED
    column.type = PerformColumnTypes.STATIC
    column.formType = PerformColumnFormTypes.DATE
    column.name = PerformStaticColumnTypeNames[column.id]

    const columnText = new PerformColumnText()
    columnText.columnID = column.id
    columnText.previewType = PerformColumnPreviewTypes.TRUNCATE
    column.texts = [columnText]
    column.width = "130"

    return column
}

// 시스템 ID가리기

export const performColumnStatics = [
    getPerformColumnStaticSelect(),
    // getPerformColumnStaticID(),
    getPerformColumnStaticMarketer(),
    getPerformColumnStaticUpdate(),
    getPerformColumnStaticCreated(),
    getPerformColumnStaticCalculated(),
]
