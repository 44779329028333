import React, { useState, useEffect } from "react"
import {
    deleteUserProfileAvatar,
    getUserDetail as getUserDetailApi,
    updateUserProfileAvatar,
} from "../../../apis/api/users"
import { userDetailModalStore, marketerContractViewer } from "../../../zustand/modalstore"
import UserAvatar from "../../../components/user/UserAvatar"
import { MarketerParticipantNames, UserGenderTypes, UserGenderNames, UserTypes } from "../../../types/users/UserTypes"
import UserMarketerCareer from "../../../types/users/UserMarketerCareer"
import { getBusinessTypes, getJobTypes, getRegionTypes } from "../../../apis/api/commons"
import tw from "tailwind-styled-components"
import {
    MarketerDataTextsKey,
    MarketerDataTextsValue,
    CareerDataContainer,
    CareerSideInnerCircle,
    CareerSideInnerCircleLayout,
    CareerSideLine,
    CareerSideOuterCircle,
    MarketerUserCareerInfoContainer,
    MarketerDataTextsContainer,
    MarketerDataTextsLayout,
    MyPageMarketerDetailModalContainer,
    MyPageMarketerUserAvatarContainer,
    MyPageMarketerUserSideContainer,
    MarketerUserSidePerformContainer,
    MarketerUserNameText,
    MarketerUserBasicDataContainer,
    MarketerUserDataContainer,
    MarketerUserBaicTitle,
    MarketerUserCareerContainer,
    MarketerUserCareerLayout,
    MarketerUserCareerTitle,
    MarketerUserCareerDataLayout,
    ContractPaperShowButton,
} from "./MyPageMarketerDetailModalStyle"
import { MyPageMarketerDetailContractViewer } from "./MyPageMarketerDetailContractViewer"
import ContractImageViewer from "../../../components/brandItems/ContractImageViewer"
import { getItemContractMarketerImages } from "../../../apis/api/boardItems"
import { useParams } from "react-router-dom"
import { useUserStore } from "../../../zustand/store"
import AccessRules from "../../../rules/AccessRules"

const MarketerUserCareerInfo = ({ marketer, idx, userShow, idxend }) => {
    const [jobTypes, setJobTypes] = useState({})
    const [businessTypes, setBusinessTypes] = useState({})

    const getTypes = () => {
        getJobTypes().then((response) => {
            setJobTypes(response)
        })
        getBusinessTypes().then((response) => {
            setBusinessTypes(response)
        })
    }

    useEffect(() => {
        if (userShow === true) {
            getTypes()
        }
    }, [])

    return (
        <MarketerUserCareerInfoContainer>
            <div className="flex w-full h-full ">
                {idx !== idxend - 1 && <CareerSideLine />}

                <CareerDataContainer>
                    <span className="font-semibold">{jobTypes[marketer.jobType]}</span>
                    <span className="font-medium text-nightblue-600">{marketer.careerYear}년</span>
                </CareerDataContainer>
            </div>

            <CareerSideOuterCircle>
                <CareerSideInnerCircleLayout>
                    <CareerSideInnerCircle />
                </CareerSideInnerCircleLayout>
            </CareerSideOuterCircle>
        </MarketerUserCareerInfoContainer>
    )
}

export default function MyPageMarketerDetailModal() {
    const { itemID } = useParams()
    const { user } = useUserStore()
    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const useId = userDetailModalStore((state) => state.useId)
    const [userDetail, setUserDetail] = useState(null)
    const [locationTypes, setLocationTypes] = useState({})
    // const [contractImageSources, setContractImageSources] = useState([])

    const marketerContractCardShow = marketerContractViewer((state) => state.marketerContractCardShow)
    const setMarketerContractCardShow = marketerContractViewer((state) => state.setMarketerContractCardShow)

    const contractImageSources = marketerContractViewer((state) => state.contractImageSources)
    const setContractImageSources = marketerContractViewer((state) => state.setContractImageSources)

    const handleContractShow = () => {
        if (userDetail !== null) {
            getItemContractMarketerImages(itemID, userDetail.user.id).then((response) => {
                setContractImageSources(response)
                if (response.length > 0) {
                    setMarketerContractCardShow(true)
                }
            })
        }
    }

    useEffect(() => {
        getTypes()
    }, [])

    const getTypes = () => {
        getRegionTypes().then((response) => {
            let regionTypes = { ...response }
            regionTypes[0] = "-"
            setLocationTypes(regionTypes)
        })
    }

    const getUserDetail = () => {
        getUserDetailApi(useId).then((response) => {
            setUserDetail(response)
        })
    }

    useEffect(() => {
        if (userShow === true) {
            getUserDetail()
        }
    }, [])

    return (
        <MyPageMarketerDetailModalContainer>
            {userDetail !== null && (
                <React.Fragment>
                    <MyPageMarketerUserSideContainer>
                        <MyPageMarketerUserAvatarContainer>
                            <UserAvatar user={userDetail.user} />
                        </MyPageMarketerUserAvatarContainer>
                        <MarketerUserSidePerformContainer>
                            <div className="max-lg:hidden lg:block">
                                <MarketerUserSidePerformContainer>
                                    <MarketerUserNameText>{userDetail.user.name}</MarketerUserNameText>
                                </MarketerUserSidePerformContainer>
                            </div>
                            {AccessRules.brandItemManagement(user) && (
                                <ContractPaperShowButton
                                    onClick={() => {
                                        handleContractShow()
                                    }}
                                >
                                    계약서
                                </ContractPaperShowButton>
                            )}
                        </MarketerUserSidePerformContainer>
                    </MyPageMarketerUserSideContainer>

                    <MarketerUserDataContainer>
                        <div className="h-72 w-full mb-4 flex justify-center">
                            <MarketerUserBasicDataContainer>
                                <MarketerUserBaicTitle>마케터 정보</MarketerUserBaicTitle>
                                <div className="w-full flex-grow flex mt-4">
                                    <MarketerDataTextsLayout>
                                        <div className="max-lg:block lg:hidden">
                                            <MarketerDataTextsContainer>
                                                <MarketerDataTextsKey>이름</MarketerDataTextsKey>
                                                <MarketerDataTextsValue>{userDetail.user.name}</MarketerDataTextsValue>
                                            </MarketerDataTextsContainer>
                                        </div>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>생년월일</MarketerDataTextsKey>
                                            <MarketerDataTextsValue>{userDetail.user.birthday}</MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>성별</MarketerDataTextsKey>
                                            <MarketerDataTextsValue>
                                                {UserGenderNames[userDetail.user.gender]}
                                            </MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>이메일</MarketerDataTextsKey>
                                            <MarketerDataTextsValue>{userDetail.user.email}</MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>전화번호</MarketerDataTextsKey>
                                            <MarketerDataTextsValue> {userDetail.user.tel}</MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>활동 지역</MarketerDataTextsKey>
                                            <MarketerDataTextsValue>
                                                {locationTypes[userDetail.detail.location1]},
                                                {locationTypes[userDetail.detail.location2]},
                                                {locationTypes[userDetail.detail.location3]}
                                            </MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                        <MarketerDataTextsContainer>
                                            <MarketerDataTextsKey>참여 형식</MarketerDataTextsKey>
                                            <MarketerDataTextsValue>
                                                {MarketerParticipantNames[userDetail.detail.participantType]}
                                            </MarketerDataTextsValue>
                                        </MarketerDataTextsContainer>
                                    </MarketerDataTextsLayout>
                                </div>
                            </MarketerUserBasicDataContainer>
                        </div>
                        <MarketerUserCareerContainer>
                            <MarketerUserCareerLayout>
                                <MarketerUserCareerTitle>경력</MarketerUserCareerTitle>
                                <MarketerUserCareerDataLayout>
                                    {userDetail.careers.map((marketer, idx) => (
                                        <MarketerUserCareerInfo
                                            marketer={marketer}
                                            idxend={userDetail.careers.length}
                                            idx={idx}
                                            userShow={userShow}
                                            key={idx}
                                        />
                                    ))}
                                </MarketerUserCareerDataLayout>
                            </MarketerUserCareerLayout>
                        </MarketerUserCareerContainer>
                    </MarketerUserDataContainer>
                </React.Fragment>
            )}
        </MyPageMarketerDetailModalContainer>
    )
}
