import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
    BottomButtonCon,
    DeleteButton,
    ItemInputBox,
    ItemInputRow,
    ItemInputFromLayout,
    SaveButton,
    ItemInputLabel,
    ItemControlBox,
    ItemInputListCol,
    ItemStatusControlBox,
    ItemEditNotification,
    ItemEditNotificationContainer,
    ItemInputRowEditor,
} from "./ItemInputFormStyle"
import BrandItem from "../../../../types/brandItems/BrandItem"
import { QueryTypes, EditStateTypes } from "../../../../types/EditorTypes"
import {
    BrandItemCombo,
    BrandItemFile,
    BrandItemInput,
    BrandItemEditor,
    BrandItemTextArea,
} from "../../../../components/brandItems/Inputs"
import {
    ItemEditTabTypes,
    ItemSalesTypeNames,
    ItemSalesTypes,
    MarketerTypeNames,
    MarketerTypes,
} from "../../../../types/brandItems/BrandItemTypes"
import { uploadImage } from "../../../../apis/api/files"
import { createBrandItem, deleteBrandItem, updateBrandItem } from "../../../../apis/api/boardItems"
import { AccessRules } from "../../../../rules/AccessRules"
import { Dictionary } from "../../../../types/Dictionary"
import FileUtils from "../../../../utils/FileUtils"
import Utils from "../../../../utils/Utils"
import { InformationHover, TooltipHoverBox } from "../../../../components/brandItems/InformationHover"
import { ItemCategoryTips, MarketerTypeTips, MissionTips, RewardTips, TitleTips } from "../../../../constants/ToolTips"
import { AlignTypes } from "../../../../types/StyleTypes"
import {
    ItemEditorMenuButton,
    ItemEditorTabBar,
    ItemEditorTabButton,
    ItemEditorTabButtonArrowSvg,
    ItemEditorTabContent,
    ItemEditorTabContentContainer,
    ItemEditorTabContentScroll,
    ItemEditorTabGuide,
    ItemEditorTabGuide2,
    ItemEditorTabIndexBox,
    ItemEditorTabWarningBox,
    ItemEditorTabWarningHover,
    ItemEditorTabWarningHoverMessage,
    ItemEditorTabWarningHoverMessage2,
    ItemEditorTabWarningIcon,
} from "./MyPageItemBrandStyle"
import { filter } from "lodash"
import ModalContainerPortal from "../../../../components/ModalContainerPortal"
import ItemPersonalAgreementPaperWriter from "./ItemPersonalAgreementPaperWriter"
import ItemMarketingMatchingSetting from "./ItemMarketingMatchingSetting"
import { UserTypes } from "../../../../types/users/UserTypes"

const RevInfo = ({ label, value }) => {
    return (
        <div className="flex items-center ml-2">
            <span className="font-semibold text-gray-800">{label}</span>
            <span className="ml-1">{value}</span>
        </div>
    )
}

// eslint-disable-next-line react/display-name
const ItemInputFrom = React.forwardRef(
    (
        {
            item,
            queryType,
            itemEditStateType,
            isShow,
            disabled = false,
            itemOrigin = null,
            deleteItem,
            viewType,
            userType,
        },
        ref,
    ) => {
        const [title, setTitle] = useState()
        const [marketerType, setMarketerType] = useState()
        const [itemSalesType, setItemSalesType] = useState()
        const [dateStart, setDateStart] = useState()
        const [dateEnd, setDateEnd] = useState()
        const [personMax, setPersonMax] = useState()
        const [thumbnailImageID, setThumbnailImageID] = useState()
        const [rewardMessage, setRewardMessage] = useState()

        const [descriptionContent, setDescriptionContent] = useState()
        // const [descriptionImageDic, setDescriptionImageDic] = useState(new Dictionary())

        const [missionContent, setMissionContent] = useState("")
        const [rewardContent, setRewardContent] = useState("")
        const [contractContent, setContractContent] = useState("")
        // const [missionImageDic, setMissionImageDic] = useState(new Dictionary())
        // const [rewardImageDic, setRewardImageDic] = useState(new Dictionary())
        // const [contractImageDic, setContractImageDic] = useState(new Dictionary())

        const [missionMessage, setMissionMessage] = useState(
            queryType === QueryTypes.UPDATE ? item.detail.rewardMessage : "",
        )

        const [isCheckableDetail, setCheckableDetail] = useState(false) // 항목이 수정됬는지 체크할수 있는지 여부
        const [isCheckableContract, setCheckableContract] = useState(false) // 항목이 수정됬는지 체크할수 있는지 여부

        // const [itemEditorValueReward, setItemEditorValueReward] = useState("");
        // const [itemEditorValueMission, setItemEditorValueMission] = useState("");
        // const [itemEditorValueContent, setItemEditorValueContent] = useState("");

        const [originDescription, setOriginDescription] = useState("")
        const [originMission, setOriginMission] = useState("")
        const [originReward, setOriginReward] = useState("")
        const [originContract, setOriginContract] = useState("")

        const [thumbnailEditStateType, setThumbnailEditStateType] = useState(EditStateTypes.WAIT)

        const [tabIndex, setTabIndex] = useState(ItemEditTabTypes.BASE)

        // 개인정보동의서 모달
        const [isPersonalAgreeModalShow, setPersonalAgreeModalShow] = useState(false)

        // 마케터 매칭 모달
        const [isMarketerMatchingSettingShow, setMarketerMatchingSettingShow] = useState(false)

        React.useImperativeHandle(ref, () => ({
            getItem,
        }))

        useEffect(() => {
            initData()

            handleCheckableEdited()
        }, [queryType, item])

        useEffect(() => {
            handleCheckableEdited()

            try {
                setOriginDescription(FileUtils.replaceMedieFileURL(itemOrigin.detail.description))
                setOriginMission(FileUtils.replaceMedieFileURL(itemOrigin.contract.mission))
                setOriginReward(FileUtils.replaceMedieFileURL(itemOrigin.contract.reward))
                setOriginContract(FileUtils.replaceMedieFileURL(itemOrigin.contract.contract))
            } catch {
                //
            }
        }, [itemOrigin])

        const initData = async () => {
            if (queryType === QueryTypes.UPDATE || itemEditStateType === EditStateTypes.PENDING) {
                setTitle(item.detail.title)
                setMarketerType(item.detail.marketerType)
                setItemSalesType(item.detail.itemSalesType)
                setDateStart(item.detail.dateStart)
                setDateEnd(item.detail.dateEnd)
                setPersonMax(item.detail.personMax)
                setThumbnailImageID(item.detail.thumbnailImageID)
                setRewardMessage(item.detail.rewardMessage)
                setMissionMessage(item.detail.missionMessage)
                setDescriptionContent(FileUtils.replaceMedieFileURL(item.detail.description))
                setMissionContent(FileUtils.replaceMedieFileURL(item.contract.mission))
                setRewardContent(FileUtils.replaceMedieFileURL(item.contract.reward))
                setContractContent(FileUtils.replaceMedieFileURL(item.contract.contract))

                // const oldDescriptionImageDic = await FileUtils.convertImageTemplateToSource(setDescriptionContent, item.detail.description)
                // setDescriptionImageDic(oldDescriptionImageDic)
                // const oldMissionImageDic = await FileUtils.convertImageTemplateToSource(setMissionContent, item.contract.mission)
                // setMissionImageDic(oldMissionImageDic)
                // const oldRewardImageDic = await FileUtils.convertImageTemplateToSource(setRewardContent, item.contract.reward)
                // setRewardImageDic(oldRewardImageDic)
                // const oldContractImageDic = await FileUtils.convertImageTemplateToSource(setContractContent, item.contract.contract)
                // setContractImageDic(oldContractImageDic)
            } else {
                setTitle("")
                setMarketerType(0)
                setItemSalesType(0)
                setDateStart("")
                setDateEnd("")
                setPersonMax(0)
                setThumbnailImageID(-1)
                setRewardMessage("")
                setMissionMessage("")
                setDescriptionContent("")
                setMissionContent("")
                setRewardContent("")
                setContractContent("")
            }
        }

        const handleCheckableEdited = () => {
            // 원본과 수정본의 차이를 비교할 수 있는지 확인하는걸 실행할지 여부
            if (
                !disabled &&
                itemOrigin !== null &&
                Object.keys(item).includes("detail") &&
                Object.keys(itemOrigin).includes("detail")
            ) {
                setCheckableDetail(true)
            }
            if (
                !disabled &&
                itemOrigin !== null &&
                Object.keys(item).includes("contract") &&
                Object.keys(itemOrigin).includes("contract")
            ) {
                setCheckableContract(true)
            }
        }

        const handleUploadImage = (e, setImageID) => {
            if (e.target.files.length !== 1) {
                return
            }
            setThumbnailEditStateType(EditStateTypes.PENDING)
            uploadImage(e.target.files[0])
                .then((response) => {
                    setImageID(response.id)
                    setThumbnailEditStateType(EditStateTypes.WAIT)
                })
                .catch((error) => {
                    setThumbnailEditStateType(EditStateTypes.FAILED)
                })
        }

        const getItem = () => {
            const submitItem = new BrandItem()
            submitItem.id = item.id // 부모 컴포넌트에서 보낸걸 확인하기 위해 임의로 0을 넣는다. (백엔드에서 사용하지 않음)
            submitItem.detail.title = title
            submitItem.detail.marketerType = marketerType
            submitItem.detail.itemSalesType = itemSalesType
            submitItem.detail.dateStart = "1901-01-01"
            submitItem.detail.dateEnd = "2299-09-09"
            submitItem.detail.personMax = personMax
            submitItem.detail.thumbnailImageID = thumbnailImageID
            submitItem.detail.rewardMessage = rewardMessage
            submitItem.detail.missionMessage = missionMessage
            submitItem.detail.description = FileUtils.getRequestContent(descriptionContent)
            submitItem.contract.mission = FileUtils.getRequestContent(missionContent)
            submitItem.contract.reward = FileUtils.getRequestContent(rewardContent)
            submitItem.contract.contract = FileUtils.getRequestContent(contractContent)

            return submitItem
        }

        const handleDelete = async () => {
            if (
                !confirm(
                    `상품 ${item.detail.title}${
                        Utils.isBatchimEnding(item.detail.title) ? "을 " : "를"
                    } 삭제하시겠습니까?`,
                )
            ) {
                return
            }
            deleteItem(item)
        }

        return (
            <ItemInputFromLayout className={`${isShow && "isShow"}`}>
                <div className="flex max-sm:flex-col justify-between items-center w-full">
                    <ItemEditorTabBar>
                        <TabButton
                            index={1}
                            label={"기본정보"}
                            isActive={tabIndex === ItemEditTabTypes.BASE}
                            setActive={() => {
                                setTabIndex(ItemEditTabTypes.BASE)
                            }}
                            validates={[
                                Utils.isStringNullOrEmpty(title) && '"제목"을 입력해 주세요.',
                                marketerType < 0 && '"마케터 유형"을 선택해 주세요.',
                                itemSalesType < 0 && '"상품 유형"를 선택해 주세요.',
                                Utils.isStringNullOrEmpty(rewardMessage) && '"실적 보상"을 입력해 주세요.',
                                Utils.isStringNullOrEmpty(missionMessage) && '"영업 업무"을 입력해 주세요.',
                                thumbnailImageID < 0 && '"썸네일"을 업로드해 주세요.',
                            ]}
                        />
                        <TabButton
                            index={2}
                            label={"상세내용"}
                            isActive={tabIndex === ItemEditTabTypes.DESCRIPTION}
                            setActive={() => {
                                setTabIndex(ItemEditTabTypes.DESCRIPTION)
                            }}
                            validates={[
                                FileUtils.isBlankContent(FileUtils.getRequestContent(descriptionContent)) &&
                                    '"상세내용"을 입력해 주세요.',
                            ]}
                            warningHoverOffset={"2rem"}
                        />
                        <TabButton
                            index={3}
                            label={"업무/보상"}
                            isActive={tabIndex === ItemEditTabTypes.MISSION_REWARD}
                            setActive={() => {
                                setTabIndex(ItemEditTabTypes.MISSION_REWARD)
                            }}
                            validates={[
                                FileUtils.isBlankContent(FileUtils.getRequestContent(missionContent)) &&
                                    '"업무"을 입력해 주세요.',
                                FileUtils.isBlankContent(FileUtils.getRequestContent(rewardContent)) &&
                                    '"보상"을 입력해 주세요.',
                            ]}
                            warningHoverOffset={"8rem"}
                        />
                        <TabButton
                            index={4}
                            label={"특약사항"}
                            isActive={tabIndex === ItemEditTabTypes.CONTRACT}
                            setActive={() => {
                                setTabIndex(ItemEditTabTypes.CONTRACT)
                            }}
                            showArrow={false}
                            warningHoverOffset={"11rem"}
                        />
                        {/* <ItemEditorTabGuide2 tabIndex={tabIndex} /> */}
                    </ItemEditorTabBar>
                    <div className="flex items-center space-x-2">
                        {userType === UserTypes.STAFF && (
                            <ItemEditorMenuButton
                                className="marketer-matching-button-color border-sky-400 text-white duration-200"
                                onClick={() => {
                                    setMarketerMatchingSettingShow(true)
                                }}
                            >
                                마케터 매칭 설정
                            </ItemEditorMenuButton>
                        )}
                        <ItemEditorMenuButton
                            onClick={() => {
                                if (queryType === QueryTypes.CREATE) {
                                    alert("상품 등록 완료 후 설정 가능합니다.")
                                    return
                                }
                                setPersonalAgreeModalShow(true)
                            }}
                        >
                            개인정보동의서
                        </ItemEditorMenuButton>
                    </div>
                </div>
                <ItemEditorTabContentContainer>
                    <ItemEditorTabContentScroll isActive={tabIndex === ItemEditTabTypes.BASE}>
                        <ItemEditNotificationContainer>
                            <ItemEditNotification isShow={viewType === EditStateTypes.EDITED}>
                                {" "}
                                ※ 수정 요청 후 관리자의 승인 전까지 수정 내용이 반영 되지 않습니다.
                            </ItemEditNotification>
                            <ItemEditNotification isShow={viewType === EditStateTypes.EDITED}>
                                {" "}
                                ※ 수정 요청 후 관리자의 승인 전까지 마케터가 해당 아이템을 신규 신청 할수 없으며,
                                메인페이지의 아이템 리스트에서 제외됩니다.
                            </ItemEditNotification>
                            <ItemEditNotification isShow={viewType === EditStateTypes.EDITED}>
                                {" "}
                                ※ 이미 승인된 마케터는 그대로 업무를 진행 할 수 있습니다.
                            </ItemEditNotification>
                        </ItemEditNotificationContainer>
                        <ItemInputRow>
                            <BrandItemInput
                                type="text"
                                label={"제목"}
                                data={title}
                                setData={setTitle}
                                disabled={disabled}
                                isEdited={isCheckableDetail && title !== itemOrigin.detail.title}
                                information={TitleTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={40}
                            />
                            <BrandItemCombo
                                label={"마케터 유형"}
                                optionKeys={Object.values(MarketerTypes)}
                                names={Object.values(MarketerTypeNames)}
                                value={marketerType}
                                onChange={(e) => {
                                    setMarketerType(e.target.value)
                                }}
                                disabled={disabled}
                                isEdited={isCheckableDetail && marketerType != itemOrigin.detail.marketerType}
                                information={MarketerTypeTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                            />
                            <BrandItemCombo
                                label={"상품 유형"}
                                optionKeys={Object.values(ItemSalesTypes)}
                                names={Object.values(ItemSalesTypeNames)}
                                value={itemSalesType}
                                onChange={(e) => {
                                    setItemSalesType(e.target.value)
                                }}
                                disabled={disabled}
                                isEdited={isCheckableDetail && itemSalesType != itemOrigin.detail.itemSalesType}
                                information={ItemCategoryTips}
                                informationAlignType={AlignTypes.LEFT}
                                widthFull={true}
                            />
                        </ItemInputRow>
                        <ItemInputRow>
                            <BrandItemInput
                                type="text"
                                label={"영업 업무"}
                                data={missionMessage}
                                setData={setMissionMessage}
                                disabled={disabled}
                                isEdited={isCheckableDetail && missionMessage !== itemOrigin.detail.missionMessage}
                                information={MissionTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={200}
                            />
                            <BrandItemInput
                                type="text"
                                label={"실적 보상"}
                                data={rewardMessage}
                                setData={setRewardMessage}
                                disabled={disabled}
                                isEdited={isCheckableDetail && rewardMessage !== itemOrigin.detail.rewardMessage}
                                information={RewardTips}
                                informationAlignType={AlignTypes.RIGHT}
                                widthFull={true}
                                maxLength={200}
                            />
                            {/* 너비 맞추기 용 */}
                            <ItemInputBox />
                        </ItemInputRow>

                        {/* <ItemInputRow>
                        <BrandItemInput 
                            type="number"
                            label={"모집 인원"}
                            data={personMax}
                            setData={setPersonMax}
                            disabled={disabled}
                            isEdited={isCheckableDetail && personMax !== itemOrigin.detail.personMax}
                        />
                        <BrandItemInput 
                            type="date"
                            label={"마케터 모집 시작일"}
                            data={dateStart}
                            setData={setDateStart}
                            disabled={disabled}
                            isEdited={isCheckableDetail && dateStart !== itemOrigin.detail.dateStart}
                        />
                        <BrandItemInput 
                            type="date"
                            label={"마케터 모집 종료일"}
                            data={dateEnd}
                            setData={setDateEnd}
                            disabled={disabled}
                            isEdited={isCheckableDetail && dateEnd !== itemOrigin.detail.dateEnd}
                        />
                    </ItemInputRow> */}

                        <ItemInputRowEditor>
                            <BrandItemFile
                                type="file"
                                label={"썸네일"}
                                fileID={thumbnailImageID}
                                onChange={(e) => {
                                    handleUploadImage(e, setThumbnailImageID)
                                }}
                                disabled={disabled}
                                isEdited={isCheckableDetail && thumbnailImageID !== itemOrigin.detail.thumbnailImageID}
                                editStateType={thumbnailEditStateType}
                            />

                            {/* <span className="absolute text-sm bottom-4 right-4 text-gray-400">가로:600px, 세로:400px 사이즈로 업로드</span>  */}
                            {/* <BrandItemFile
                            type="file"
                            label={"상세 이미지"}
                            fileID={descriptionImageID}
                            onChange={(e)=>{handleUploadImage(e, setDescriptionImageID)}}
                            disabled={disabled}
                            isEdited={isCheckableDetail && descriptionImageID !== itemOrigin.detail.descriptionImageID}
                        />
                        {/* 너비 맞추기 용 */}
                            {/* <ItemInputBox /> */}
                        </ItemInputRowEditor>
                        {/* <div className="flex items-center justify-end px-2">
                        <RevInfo label={"버전"} value={item.contract.rev} />
                        <RevInfo label={"승인 날짜"} value={item.contract.confirmed} />
                    </div> */}

                        {!disabled && AccessRules.staff() && (
                            <BottomButtonCon>
                                {queryType === QueryTypes.UPDATE || itemEditStateType === EditStateTypes.PENDING ? (
                                    <DeleteButton
                                        onClick={() => {
                                            handleDelete()
                                        }}
                                    >
                                        상품 삭제
                                    </DeleteButton>
                                ) : (
                                    <p></p>
                                )}
                            </BottomButtonCon>
                        )}
                    </ItemEditorTabContentScroll>
                    <ItemEditorTabContent isActive={tabIndex === ItemEditTabTypes.DESCRIPTION}>
                        <ItemInputRowEditor>
                            <BrandItemEditor
                                label={"상품 상세 내용"}
                                value={descriptionContent}
                                setValue={setDescriptionContent}
                                disabled={disabled}
                                originValue={originDescription}
                                message={"※ 최적의 이미지 가로 사이즈는 1200px 입니다."}
                            />
                        </ItemInputRowEditor>
                    </ItemEditorTabContent>
                    <ItemEditorTabContentScroll isActive={tabIndex === ItemEditTabTypes.MISSION_REWARD}>
                        <ItemInputRowEditor>
                            <BrandItemEditor
                                label={"영업 업무"}
                                value={missionContent}
                                setValue={setMissionContent}
                                disabled={disabled}
                                originValue={originMission}
                                message={"※ 최적의 이미지 가로 사이즈는 1200px 입니다."}
                            />
                        </ItemInputRowEditor>
                        <ItemInputRowEditor>
                            <BrandItemEditor
                                label={"실적 보상"}
                                value={rewardContent}
                                setValue={setRewardContent}
                                disabled={disabled}
                                originValue={originReward}
                                message={"※ 최적의 이미지 가로 사이즈는 1200px 입니다."}
                            />
                        </ItemInputRowEditor>
                    </ItemEditorTabContentScroll>
                    <ItemEditorTabContent isActive={tabIndex === ItemEditTabTypes.CONTRACT}>
                        <ItemInputRowEditor>
                            <BrandItemEditor
                                label={"특약 사항"}
                                value={contractContent}
                                setValue={setContractContent}
                                disabled={disabled}
                                originValue={originContract}
                                message={"※ 최적의 이미지 가로 사이즈는 1200px 입니다."}
                            />
                        </ItemInputRowEditor>
                    </ItemEditorTabContent>
                </ItemEditorTabContentContainer>

                <ModalContainerPortal
                    show={isPersonalAgreeModalShow}
                    setShow={setPersonalAgreeModalShow}
                    closeByBackground={false}
                >
                    {/* <div className="w-156  bg-white"> */}
                    <ItemPersonalAgreementPaperWriter
                        itemID={item.id}
                        isShow={isPersonalAgreeModalShow}
                        setShow={setPersonalAgreeModalShow}
                    />
                    {/* </div> */}
                </ModalContainerPortal>
                <ModalContainerPortal
                    show={isMarketerMatchingSettingShow}
                    setShow={setMarketerMatchingSettingShow}
                    closeByBackground={false}
                >
                    {/* <div className="w-156  bg-white"> */}
                    <ItemMarketingMatchingSetting
                        itemID={item.id}
                        isShow={isMarketerMatchingSettingShow}
                        setShow={setMarketerMatchingSettingShow}
                    />
                    {/* </div> */}
                </ModalContainerPortal>
            </ItemInputFromLayout>
        )
    },
)

export default ItemInputFrom

const TabHoverMessage = ({ message }) => {
    return (
        <ItemEditorTabWarningHoverMessage>
            <div className="flex flex-center h-full py-0.5 mr-1">
                <ItemEditorTabWarningIcon />
            </div>
            {message}
        </ItemEditorTabWarningHoverMessage>
    )
}

const TabButton = ({ index, label, isActive, setActive, validates, showArrow = true, warningHoverOffset = null }) => {
    const [validateMessages, setValidateMessages] = useState([])

    useEffect(() => {
        setValidateMessages(filter(validates, (v) => v !== false))
    }, [validates])

    return (
        <ItemEditorTabButton
            isActive={isActive}
            onClick={() => {
                setActive()
            }}
        >
            <ItemEditorTabIndexBox isActive={isActive}>{index}</ItemEditorTabIndexBox>
            {label}
            {validateMessages.length > 0 && (
                <React.Fragment>
                    <ItemEditorTabWarningBox>
                        <ItemEditorTabWarningIcon />
                    </ItemEditorTabWarningBox>
                    <ItemEditorTabWarningHover
                        ishovering
                        alignType={AlignTypes.RIGHT}
                        className={`${warningHoverOffset !== null && `max-sm:-left-[${warningHoverOffset}]`}`}
                    >
                        {validateMessages.map((message, key) => (
                            <TabHoverMessage key={key} message={message} />
                        ))}
                        <ItemEditorTabWarningHoverMessage2>
                            필수 항목을 작성하셔야 등록이 가능합니다.
                        </ItemEditorTabWarningHoverMessage2>
                    </ItemEditorTabWarningHover>
                </React.Fragment>
            )}
            {showArrow && (
                <div className="absolute -right-3 h-full">
                    <ItemEditorTabButtonArrowSvg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 20"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        isActive={isActive}
                    >
                        <path d="M0 0l5 10-5 10" />
                    </ItemEditorTabButtonArrowSvg>
                </div>
            )}
        </ItemEditorTabButton>
    )
}
