import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"

export function PaginationSize({ pageSize, setPageSize }) {
    return (
        <div className="flex justify-between items-center w-32 p-2 space-x-2 rounded-lg bg-white text-sm drop-shadow">
            <span className="flex-shrink-0">출력 개수</span>
            <select
                defaultValue={"15"}
                className="w-full"
                value={pageSize}
                onChange={(e) => {
                    setPageSize(e.target.value)
                }}
            >
                <option value="15">15</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
        </div>
    )
}
