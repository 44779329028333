import Brand from './Brand';
import User from './User';
import UserMarketerCareer from './UserMarketerCareer';
import { MarketerParticipantTypes, UserTypes } from './UserTypes';
import { EditStateTypes } from '../EditorTypes';

// UserBrand는 Brand로 대체
export default class {
    constructor() {
        this.user = new User()
        this.detail = new Brand()
        this.businessNo = ""
        this.brandStatusType = EditStateTypes.WAIT
    }

    convertByResponse(response) {
        const userResponse = response["user"]
        this.user = new User()
        if (userResponse !== undefined) {
            this.user.convertByResponse(userResponse)
        }

        const detailResponse = response["detail"]
        this.detail = new Brand()
        if (detailResponse !== undefined) {
            this.detail.convertByResponse(response)
        }

        this.businessNo = response["business_no"]
        this.brandStatusType = response["brand_status_type"]
    }
}