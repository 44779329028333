import React, { useEffect, useState, useRef } from "react"
import {
    UserInput,
    UserSMSAuthRequestButton,
    UserSMSAuthSubmitButton,
    UserEamilDuplicatedButton,
} from "../../components/user/Inputs"
import { SignUpInfoCol, SignUpInfoInputCol, SignUpInfoTitle, SignUpTelButton, SignUpTelMessageBox } from "./SignUpStyle"
import { EditStateTypes, EmailDuplicatedTypes } from "../../types/EditorTypes"
import Utils from "../../utils/Utils"
import { sendAuthSMS, sendAuthSubmitSMS } from "../../apis/api/users/index"
import { PasswordValidate } from "../../components/user/PasswordValidate"
import { TelValidate } from "../../components/user/TelValidate"
import { EmailValidate } from "../../components/user/EmailValidate"
import { checkEmail } from "../../apis/api/users/index"
import { useFocus } from "../../hooks/useFocus"
import { debounce } from "lodash"
import { REGEX_EMAIL } from "../../constants/ConstRegex"
import { useUserStore } from "../../zustand/store"

const SignUpUser = ({
    email,
    setEmail,
    name,
    setName,
    nickname,
    setNickname,
    password1,
    setPassword1,
    password2,
    setPassword2,
    passwordValidate,
    setPasswordValidate,
    telValidate,
    setTelValidate,
    emailValidate,
    setEmailValidate,
    tel,
    setTel,
    telAuthState,
    setTelAuthState,
    setEmailState,
    emailState,
    isOauth,
}) => {
    const userStore = useUserStore()

    const emailRef = useRef("") // 이메일 중복 체크 시 현재 입력된 값을 빠르게 업데이트받아 중복체크하기 위함.
    const emailTimerRef = useRef(null)
    const [emailCheckTimer, setEmailCheckTimer] = useState(true)

    const [password1State, setPassword1State] = useState(EditStateTypes.WAIT)
    const [password2State, setPassword2State] = useState(EditStateTypes.WAIT)
    const [telState, setTelState] = useState(EditStateTypes.WAIT)
    // const [emailState, setEmailState] = useState(EditStateTypes.WAIT)
    const [passwordFocusRef, isPasswordFocus] = useFocus()
    const [telFocusRef, isTelFocus] = useFocus()
    const [emailFocusRef, isEmailFocus] = useFocus()
    const passwordCertificateRef = useRef(null)

    const [telSubmitCode, setTelSubmitCode] = useState("")

    const [telSubmitState, setTelSubmitState] = useState(EditStateTypes.DISABLED)
    const [telSubmitTime, setTelSubmitTime] = useState(0)
    const [telSubmitTimeText, setTelSubmitTimeText] = useState("")

    // const [emailDuplcatedState,setEmailDuplcatedState] = useState(EmailDuplicatedTypes.DISABLED)
    const [emailDuplcatedState, setEmailDuplcatedState] = useState(false)

    const [isEntered, setIsEntered] = useState(false)

    useEffect(() => {
        setEmailDuplcatedState(true)
        if (Utils.isStringNullOrEmpty(email)) {
            setEmailState(EditStateTypes.WAIT)
        } else {
            setEmailState(EditStateTypes.FAILED)
        }

        // 이메일 중복체크 검사
        emailRef.current = email

        clearTimeout(emailTimerRef.current)
        setEmailCheckTimer(true)
        emailTimerRef.current = setTimeout(() => {
            handleEmailDuplicatedCheck()
        }, 1000)
    }, [email])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(email)) {
            setEmailState(EditStateTypes.WAIT)
        } else {
            if (emailDuplcatedState) {
                setEmailState(EditStateTypes.FAILED)
            } else {
                setEmailState(EditStateTypes.DONE)
            }
        }
    }, [emailDuplcatedState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(password1)) {
            setPassword1State(EditStateTypes.WAIT)
        } else {
            if (passwordValidate) {
                setPassword1State(EditStateTypes.DONE)
            } else {
                setPassword1State(EditStateTypes.FAILED)
            }
        }
    }, [password1, passwordValidate])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(password2)) {
            setPassword2State(EditStateTypes.WAIT)
        } else {
            if (passwordValidate && password1 === password2) {
                setPassword2State(EditStateTypes.DONE)
            } else {
                setPassword2State(EditStateTypes.FAILED)
            }
        }
    }, [password1, password2])

    useEffect(() => {
        if (telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.WAIT) {
            setTelSubmitState(EditStateTypes.DISABLED)
            setTelSubmitCode("")
        }
        if (telAuthState === EditStateTypes.PENDING) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
    }, [telAuthState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelState(EditStateTypes.WAIT)
        } else {
            if (telValidate) {
                setTelState(EditStateTypes.DONE)
            } else {
                setTelState(EditStateTypes.FAILED)
            }
        }
    }, [tel, telValidate])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelSubmitState(EditStateTypes.DISABLED)
        }
    }, [tel])

    useEffect(() => {
        if (telSubmitState !== EditStateTypes.DISABLED) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
        if (telSubmitCode.length > 20) {
            setTelSubmitCode(telSubmitCode.slice(0, 20))
        }
    }, [telSubmitCode])

    useEffect(() => {
        const time = setInterval(() => {
            const second = telSubmitTime - 1
            setTelSubmitTimeText(`${Math.floor(second / 60)} : ${second % 60}`)
            setTelSubmitTime(second)
        }, 1000)

        if (telSubmitTime === 0 || telAuthState !== EditStateTypes.PENDING) {
            clearInterval(time)
        }

        return () => clearInterval(time)
    }, [telSubmitTime])

    useEffect(() => {
        if (
            (telSubmitState !== EditStateTypes.DISABLED || telSubmitState !== EditStateTypes.DONE) &&
            passwordCertificateRef.current !== null
        ) {
            passwordCertificateRef.current.focus()
            openKeyboard()
        }
    }, [telSubmitState])

    const openKeyboard = () => {
        const input = passwordCertificateRef.current
        input.setAttribute("readonly", "readonly")
        input.removeAttribute("readonly")
    }

    const telAuth = async () => {
        if (telSubmitTime > 60 * 5 - 10) {
            alert("잠시 후에 다시 시도해주세요.")
            return
        }

        if (
            !Utils.isStringNullOrEmpty(tel) &&
            (telAuthState === EditStateTypes.WAIT || telAuthState === EditStateTypes.PENDING)
        ) {
            const requestData = { phone_number: tel }

            // 전화번호로 SMS 전송
            await sendAuthSMS(requestData)

            setTelAuthState(EditStateTypes.PENDING)
            setTelSubmitState(EditStateTypes.WAIT)
            setTelSubmitTime(60 * 5)
        }
        if (telAuthState === EditStateTypes.DONE) {
            setTelAuthState(EditStateTypes.WAIT)
        }
    }

    const telSubmit = async () => {
        if (!Utils.isStringNullOrEmpty(telSubmitCode)) {
            const requestData = {
                phone_number: tel,
                auth_number: telSubmitCode,
            }
            // 전화번호로 SMS 전송
            const response = await sendAuthSubmitSMS(requestData)
            const smsResult = response.result

            if (telSubmitCode.length <= 6 && smsResult === true) {
                setTelAuthState(EditStateTypes.DONE)
                setTelSubmitState(EditStateTypes.DONE)
            } else {
                setTelAuthState(EditStateTypes.PENDING)
                setTelSubmitState(EditStateTypes.FAILED)
            }
        }
    }

    const KeyInputGetData = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            handleEmailDuplicatedCheck()
        }
    }

    const handleEmailDuplicatedCheck = async () => {
        if (REGEX_EMAIL.test(emailRef.current)) {
            await checkEmail(emailRef.current)
                .then((emailCheckedState) => {
                    setEmailDuplcatedState(emailCheckedState)
                })
                .catch((err) => {})

            setEmailCheckTimer(false)
        }
    }

    // function isEmailDuplicated(emailCheckedState) {
    //     if(emailState !== EditStateTypes.FAILED){
    //         if(emailCheckedState === true){
    //             setEmailDuplcatedState(EmailDuplicatedTypes.FAILED)
    //             setEmailState(EditStateTypes.FAILED)
    //             // alert("중복된 이메일 입니다.")
    //         } else {
    //             setEmailDuplcatedState(EmailDuplicatedTypes.DONE)
    //         }
    //     }
    // }

    const handleTelEnter = (e) => {
        if (e.key == "Enter") {
            telAuth()
        }
    }

    const handleTelCodeEnter = (e) => {
        if (e.key == "Enter") {
            telSubmit()
        }
    }

    return (
        <SignUpInfoCol>
            <SignUpInfoInputCol>
                {!isOauth ? (
                    <>
                        <div className="flex items-center relative">
                            <EmailValidate
                                email={email}
                                setValidate={setEmailValidate}
                                isDuplicated={emailDuplcatedState}
                                isShow={isEmailFocus || emailState === EditStateTypes.FAILED}
                                top={"4rem"}
                                duplicateCheckTimer={emailCheckTimer}
                            />
                            <UserInput
                                onKeyPress={KeyInputGetData}
                                placeholder={"이메일 아이디"}
                                type={"email"}
                                editStateType={emailState}
                                data={email}
                                setData={setEmail}
                                ref={emailFocusRef}
                                autoComplete={false}
                                maxLength={200}
                            >
                                {/* <UserEamilDuplicatedButton setEmailDuplcatedState={setEmailDuplcatedState} emailDuplcatedState={emailDuplcatedState} setEmailState={setEmailState} email={email} editStateType={emailState} handleOnClick={handleEmailDuplicatedCheck} /> */}
                            </UserInput>
                        </div>

                        <UserInput
                            placeholder={"이름"}
                            type={"text"}
                            data={name}
                            setData={setName}
                            autoComplete={false}
                            maxLength={100}
                        />
                        {!Utils.isNullOrUndefined(setNickname) && (
                            <UserInput
                                placeholder={"닉네임 (선택)"}
                                type={"text"}
                                data={nickname}
                                setData={setNickname}
                                autoComplete={false}
                                maxLength={100}
                            />
                        )}
                        <div className="flex items-center relative">
                            <PasswordValidate
                                passWord={password1}
                                setValidate={setPasswordValidate}
                                isShow={isPasswordFocus || password1State === EditStateTypes.FAILED}
                                top={"4rem"}
                                autoComplete={false}
                            />
                            <UserInput
                                placeholder={"비밀번호"}
                                type={"password"}
                                data={password1}
                                setData={setPassword1}
                                editStateType={password1State}
                                ref={passwordFocusRef}
                                autoComplete={false}
                                maxLength={40}
                            ></UserInput>
                        </div>
                        <UserInput
                            placeholder={"비밀번호 확인"}
                            type={"password"}
                            data={password2}
                            setData={setPassword2}
                            editStateType={password2State}
                            maxLength={40}
                        />
                    </>
                ) : (
                    <>
                        <UserInput placeholder={"이메일 아이디"} type={"text"} data={userStore.email} disabled={true}>
                            {/* <UserEamilDuplicatedButton setEmailDuplcatedState={setEmailDuplcatedState} emailDuplcatedState={emailDuplcatedState} setEmailState={setEmailState} email={email} editStateType={emailState} handleOnClick={handleEmailDuplicatedCheck} /> */}
                        </UserInput>

                        <UserInput placeholder={"이름"} type={"text"} data={userStore.name} disabled={true} />
                    </>
                )}
                <div className="flex items-center relative">
                    <TelValidate
                        tel={tel}
                        setValidate={setTelValidate}
                        isShow={isTelFocus || telState === EditStateTypes.FAILED}
                        top={"4rem"}
                    />
                    <UserInput
                        isTel={true}
                        placeholder={"전화번호"}
                        type={"tel"}
                        data={tel}
                        setData={setTel}
                        editStateType={telState}
                        disabled={telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE}
                        ref={telFocusRef}
                        onKeyPress={handleTelEnter}
                        maxLength={20}
                    >
                        <UserSMSAuthRequestButton telAuthState={telAuthState} telAuth={telAuth} />
                    </UserInput>
                </div>
                <div
                    className={`flex items-center ${
                        telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE
                            ? "opacity-0"
                            : "opacity-100"
                    } duration-200`}
                >
                    <UserInput
                        placeholder={"전화번호 인증"}
                        type={"text"}
                        data={telSubmitCode}
                        setData={setTelSubmitCode}
                        autoComplete={"one-time-code"}
                        disabled={telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE}
                        ref={passwordCertificateRef}
                        onKeyPress={handleTelCodeEnter}
                    >
                        <UserSMSAuthSubmitButton
                            telAuthState={telAuthState}
                            telSubmitState={telSubmitState}
                            telSubmitTimeText={telSubmitTimeText}
                            telSubmit={telSubmit}
                        />
                    </UserInput>
                </div>
            </SignUpInfoInputCol>
        </SignUpInfoCol>
    )
}

export default SignUpUser
