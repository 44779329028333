import React, { useState, useEffect } from "react"
import {
    LoginButton,
    SignupButton,
    LoginCol,
    LoginTitle,
    LoginOptionCol,
    LoginOptionItem,
    LoginOptionSpan,
    LoginRegistCol,
    LoginRegistSpan,
    LoginInputCol,
    LoginRegistBox,
    LoginContainer,
    LoginFindPassword,
    LoginFindPasswordCol,
    FindPasswordControlBox,
    FindPasswordGotoLoginButton,
    FindPasswordUserCheckButton,
    FindPasswordSetPasswordButton,
} from "./LoginStyle"
import { Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import ConstCookie from "../../../constants/ConstCookie"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { getUserByLogin } from "../../../services/users"
import { UserInput, UserSMSAuthRequestButton, UserSMSAuthSubmitButton } from "../Inputs"
import { LoginComponentTypes } from "./LoginMain"
import { EditStateTypes } from "../../../types/EditorTypes"
import { PasswordValidate } from "../PasswordValidate"
import { useFocus } from "../../../hooks/useFocus"
import {
    checkTelBySetPassword,
    sendAuthSMS,
    sendAuthSubmitSMS,
    setPasswordByFindPassword,
} from "../../../apis/api/users"

const FindPassword = ({ setLoginComponentType }) => {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [tel, setTel] = useState("")

    const [telSubmitCode, setTelSubmitCode] = useState("")
    const [telAuthState, setTelAuthState] = useState(EditStateTypes.WAIT)
    const [telSubmitState, setTelSubmitState] = useState(EditStateTypes.DISABLED)
    const [telSubmitTime, setTelSubmitTime] = useState(0)
    const [telSubmitTimeText, setTelSubmitTimeText] = useState("")

    const [passwordValidate, setPasswordValidate] = useState(false)
    const [password1State, setPassword1State] = useState(EditStateTypes.WAIT)
    const [password2State, setPassword2State] = useState(EditStateTypes.WAIT)

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [isUserCheck, setUserCheck] = useState(false)

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(password1)) {
            setPassword1State(EditStateTypes.WAIT)
        } else {
            if (passwordValidate) {
                setPassword1State(EditStateTypes.DONE)
            } else {
                setPassword1State(EditStateTypes.FAILED)
            }
        }
    }, [password1, passwordValidate])
    useEffect(() => {
        if (Utils.isStringNullOrEmpty(password2)) {
            setPassword2State(EditStateTypes.WAIT)
        } else {
            if (passwordValidate && password1 === password2) {
                setPassword2State(EditStateTypes.DONE)
            } else {
                setPassword2State(EditStateTypes.FAILED)
            }
        }
    }, [password2])

    useEffect(() => {
        if (telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.WAIT) {
            setTelSubmitState(EditStateTypes.DISABLED)
            setTelSubmitCode("")
        }
        if (telAuthState === EditStateTypes.PENDING) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
    }, [telAuthState])

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(tel)) {
            setTelSubmitState(EditStateTypes.DISABLED)
        }

        let newTel = tel
        newTel = tel.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
        if (newTel.length > 12) {
            newTel = newTel.slice(0, 12)
        }
        setTel(newTel)
    }, [tel])

    useEffect(() => {
        if (telSubmitState !== EditStateTypes.DISABLED) {
            setTelSubmitState(EditStateTypes.WAIT)
        }
        if (telSubmitCode.length > 20) {
            setTelSubmitCode(telSubmitCode.slice(0, 20))
        }
    }, [telSubmitCode])

    useEffect(() => {
        const time = setInterval(() => {
            const second = telSubmitTime - 1
            setTelSubmitTimeText(`${Math.floor(second / 60)} : ${second % 60}`)
            setTelSubmitTime(second)
        }, 1000)

        if (telSubmitTime === 0 || telAuthState !== EditStateTypes.PENDING) {
            clearInterval(time)
        }

        return () => clearInterval(time)
    }, [telSubmitTime])

    const telAuth = async () => {
        if (
            !Utils.isStringNullOrEmpty(tel) &&
            (telAuthState === EditStateTypes.WAIT || telAuthState === EditStateTypes.PENDING)
        ) {
            const requestData = { phone_number: tel }

            // 전화번호로 SMS 전송
            await sendAuthSMS(requestData)

            setTelAuthState(EditStateTypes.PENDING)
            setTelSubmitState(EditStateTypes.WAIT)
            setTelSubmitTime(60 * 5)
        }
        if (telAuthState === EditStateTypes.DONE) {
            setTelAuthState(EditStateTypes.WAIT)
        }
    }

    const telSubmit = async () => {
        if (!Utils.isStringNullOrEmpty(telSubmitCode)) {
            const requestData = {
                phone_number: tel,
                auth_number: telSubmitCode,
            }

            // 전화번호로 SMS 전송
            const response = await sendAuthSubmitSMS(requestData)
            const smsResult = response.result

            if (smsResult) {
                setTelAuthState(EditStateTypes.DONE)
                setTelSubmitState(EditStateTypes.DONE)
            } else {
                setTelAuthState(EditStateTypes.PENDING)
                setTelSubmitState(EditStateTypes.FAILED)
            }
        }
    }

    const handleGotoLogin = () => {
        setLoginComponentType(LoginComponentTypes.EMAIL_LOGIN)
    }

    const handleCheckUser = async () => {
        if (Utils.isStringNullOrEmpty(email) || Utils.isStringNullOrEmpty(name) || Utils.isStringNullOrEmpty(tel)) {
            if (Utils.isStringNullOrEmpty(email)) {
                alert("이메일을 입력해주세요")
                return
            } else if (Utils.isStringNullOrEmpty(name)) {
                alert("이름을 입력해주세요")
                return
            } else if (Utils.isStringNullOrEmpty(tel)) {
                alert("전화번호를 입력해주세요")
                return
            } else {
                alert("값을 입력해주세요")
                return
            }
        }

        if (telAuthState !== EditStateTypes.DONE) {
            alert("전화번호를 인증해주세요")
            return
        }

        const check = await checkTelBySetPassword(email, name, tel)

        if (check) {
            setUserCheck(true)
        } else {
            alert("유저를 찾을 수 없습니다.")
        }
    }

    const handleSetPassword = async () => {
        if (
            Utils.isStringNullOrEmpty(email) ||
            Utils.isStringNullOrEmpty(name) ||
            Utils.isStringNullOrEmpty(tel) ||
            Utils.isStringNullOrEmpty(password1) ||
            password1 !== password2 ||
            !passwordValidate
        ) {
            if (Utils.isStringNullOrEmpty(email)) {
                alert("이메일을 입력해주세요")
                return
            } else if (Utils.isStringNullOrEmpty(name)) {
                alert("이름을 입력해주세요")
                return
            } else if (Utils.isStringNullOrEmpty(tel)) {
                alert("전화번호를 입력해주세요")
                return
            } else {
                alert("값을 입력해주세요")
                return
            }
        }

        if (telAuthState !== EditStateTypes.DONE) {
            alert("전화번호를 인증해주세요")
            return
        }

        const result = await setPasswordByFindPassword(email, name, tel, password1)

        if (result) {
            setLoginComponentType(LoginComponentTypes.EMAIL_LOGIN)
            alert("비밀번호가 재설정되었습니다.")
        } else {
            alert("인증 실패했습니다.")
        }
    }

    return (
        <LoginContainer>
            <LoginFindPasswordCol>
                <LoginTitle>비밀번호 재설정</LoginTitle>
                <LoginInputCol>
                    <UserInput
                        placeholder={"이메일 아이디"}
                        type={"email"}
                        data={email}
                        setData={setEmail}
                        disabled={isUserCheck}
                    />
                    {!isUserCheck ? (
                        <React.Fragment>
                            <UserInput placeholder={"이름"} type={"string"} data={name} setData={setName} />
                            <UserInput
                                placeholder={"전화번호"}
                                type={"string"}
                                data={tel}
                                setData={setTel}
                                disabled={
                                    telAuthState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE
                                }
                            >
                                <UserSMSAuthRequestButton telAuthState={telAuthState} telAuth={telAuth} />
                            </UserInput>
                            <UserInput
                                placeholder={"전화번호 인증"}
                                type={"string"}
                                data={telSubmitCode}
                                setData={setTelSubmitCode}
                                disabled={
                                    telSubmitState === EditStateTypes.DISABLED || telAuthState === EditStateTypes.DONE
                                }
                            >
                                <UserSMSAuthSubmitButton
                                    telAuthState={telAuthState}
                                    telSubmitState={telSubmitState}
                                    telSubmitTimeText={telSubmitTimeText}
                                    telSubmit={telSubmit}
                                />
                            </UserInput>
                        </React.Fragment>
                    ) : (
                        <InputPassword
                            password1={password1}
                            setPassword1={setPassword1}
                            password2={password2}
                            setPassword2={setPassword2}
                            password1State={password1State}
                            password2State={password2State}
                            setPasswordValidate={setPasswordValidate}
                        />
                    )}

                    <FindPasswordControlBox>
                        <FindPasswordGotoLoginButton
                            onClick={() => {
                                handleGotoLogin()
                            }}
                        >
                            돌아가기
                        </FindPasswordGotoLoginButton>
                        {!isUserCheck ? (
                            <FindPasswordUserCheckButton
                                onClick={() => {
                                    handleCheckUser()
                                }}
                            >
                                재설정
                            </FindPasswordUserCheckButton>
                        ) : (
                            <FindPasswordSetPasswordButton
                                onClick={() => {
                                    handleSetPassword()
                                }}
                            >
                                비밀번호 설정
                            </FindPasswordSetPasswordButton>
                        )}
                    </FindPasswordControlBox>
                </LoginInputCol>
            </LoginFindPasswordCol>
        </LoginContainer>
    )
}

const InputPassword = ({
    password1,
    setPassword1,
    password2,
    setPassword2,
    password1State,
    password2State,
    setPasswordValidate,
}) => {
    const [passwordFocusRef, isPasswordFocus] = useFocus()

    return (
        <React.Fragment>
            <UserInput
                placeholder={"비밀번호"}
                type={"password"}
                data={password1}
                setData={setPassword1}
                editStateType={password1State}
                ref={passwordFocusRef}
            >
                <PasswordValidate
                    top={"1.6rem"}
                    passWord={password1}
                    setValidate={setPasswordValidate}
                    isShow={isPasswordFocus}
                />
            </UserInput>
            <UserInput
                placeholder={"비밀번호 확인"}
                type={"password"}
                data={password2}
                setData={setPassword2}
                editStateType={password2State}
            />
        </React.Fragment>
    )
}

export default FindPassword
