import tw from "tailwind-styled-components"
import { ContentTopSize, MyPageInputModalLayout } from "../../MyPageMainStyle"
import { PerformListControlSection } from "../../perform/MyPagePerformStyle"

export const StaffListControlBox = tw(ContentTopSize)`
    flex 
    max-lg:w-full max-lg:h-[6rem] max-lg:min-h-[6rem]
    max-lg:flex-col max-lg:items-center justify-between
    lg:flex-col
    
    xl:flex-row xl:items-center
`

export const StaffListControlSection = tw(PerformListControlSection)`
    max-lg:h-10
`

export const StaffUserReviveButton = tw.button`
    px-2 py-1 bg-yellow-600 text-white rounded
`

export const StaffUserPointUpdateLayout = tw(MyPageInputModalLayout)`
    max-sm:w-screen sm:w-[40rem] max-lg:h-full lg:max-h-[60rem]
    max-sm:rounded-none max-md:rounded-xl md:rounded-xl
`

export const StaffUserPointUpdateInput = tw.input`
    w-full p-2
    rounded-lg
    border focus:border-blue-500
    ring-0 focus:ring-0 outline-none focus:outline-none
    duration-200
`
export const StaffUserPointUpdateTextArea = tw.textarea`
    w-full p-2 resize-none
    rounded-lg
    border focus:border-blue-500
    ring-0 focus:ring-0 outline-none focus:outline-none
    duration-200
`
