export default class {
    constructor() {
        this.id = -1
        this.pointReferral = 0
        this.pointReferred = 0
        this.limitPriceUser = 0
        this.limitPriceDay = 0

        this.usedPointDay = 0
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.pointReferral = response["point_referral"]
        this.pointReferred = response["point_referred"]
        this.limitPriceUser = response["limit_price_user"]
        this.limitPriceDay = response["limit_price_day"]

        this.usedPointDay = response["used_point_day"]
    }

    convertToRequest() {
        let request = {
            id: this.id,
            point_referral: this.pointReferral,
            point_referred: this.pointReferred,
            limit_price_user: this.limitPriceUser,
            limit_price_day: this.limitPriceDay,
        }

        return request
    }
}
