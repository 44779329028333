import Paginator from "../../Paginator";
import BrandItemMarketerGroup from "./BrandItemMarketerGroup";

export default class extends Paginator{
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.items = []
        const resultsResponse = response["results"]
        if (resultsResponse !== undefined) {
            response["results"].map(result => {
                let item = new BrandItemMarketerGroup()
                item.convertByResponse(result)
                this.items.push(item)
            })
        }
    }
}