import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { getRegionTypes } from "../../../apis/api/commons"
import { HoverUserCard } from "../../../components/HoverComponants"
import { userDetailModalStore } from "../../../zustand/modalstore"
import { useUserStore } from "../../../zustand/store"
import {
    MarketerCellButton,
    MarketerInviteIcon,
    MarketerStatSelect,
    MarketerTableBody,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
} from "../marketer/MyPageMarketerStyle"
import { AccessRules } from "../../../rules/AccessRules"
import {
    GroupDropdownMenu,
    GroupDropdownMenuBox,
    GroupDropdownMenuContainer,
    GroupInviteControlButton,
} from "./MyPageGroupStyle"
import useDropdownDetectClose from "../../../hooks/useDropdownDetectClose"
import { EditStateTypes } from "../../../types/EditorTypes"
import { MarketerStatusTypes } from "../../../types/brandItems/BrandItemTypes"

const MarketerCellWidth = {
    Index: "min-w-[4rem]",
    Invite: "min-w-[6rem]",
    MasterName: "min-w-[8rem]",
    GroupName: "min-w-[10rem]",
    Email: "min-w-[16rem] w-full",
    Tel: "min-w-[10rem]",
    Age: "min-w-[8rem]",
    Location: "min-w-[12rem] w-full",
    Performs: "min-w-[8rem]",
    Calcs: "min-w-[8rem]",
    Marketers: "min-w-[8rem]",
    Created: "min-w-[8rem]",
}
MarketerCellWidth.MasterName
MarketerCellWidth.Email
MarketerCellWidth.Tel
MarketerCellWidth.Age
MarketerCellWidth.Location
MarketerCellWidth.Created

const rowMinWidth = (user, submitInvite) => {
    let size = 62

    if (submitInvite) {
        size + 6
    }
    return `min-w-[${size}rem]`
}

export const HoverUserCardContainer = ({ userID, onClick, children }) => {
    return (
        <>
            <div className=" h-full items-center max-lg:hidden lg:flex">
                <HoverUserCard
                    userID={userID}
                    onClick={() => {
                        onClick()
                    }}
                >
                    {children}
                </HoverUserCard>
            </div>
            <div className=" h-full items-center max-lg:flex lg:hidden">{children}</div>
        </>
    )
}

const MarketerGroupTableRow = ({
    user,
    itemMarketerInvite,
    locationTypes,
    openMarketerList,
    submitInvite,
    marketerGroupType,
    idx,
}) => {
    const [menuIsOpen, menuRef, menuHandler] = useDropdownDetectClose()

    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    const sender = itemMarketerInvite.marketerSender

    const handeRowClick = () => {
        setUserId(sender.user.id)
        setUserShow(true)
    }

    const handleSubmitInvite = (editStateType) => {
        submitInvite(itemMarketerInvite.id, editStateType)
    }

    return (
        <MarketerTableDataRow className={`${rowMinWidth(user, submitInvite)}`}>
            {/* {AccessRules.brandItemManagement(user) && (
                <MarketerTableData className={`${MarketerCellWidth.Index}`}>
                    {sender.id}
                </MarketerTableData>
            )} */}
            {/* 마케터이고 초대 관리 기능을 가지고 있는 경우 */}
            {!AccessRules.brandItemManagement(user) && submitInvite !== undefined && (
                <MarketerTableData className={`${MarketerCellWidth.Invite}`}>
                    <GroupDropdownMenuContainer>
                        <MarketerCellButton
                            onClick={menuHandler}
                            ref={menuRef}
                            className="h-full bg-nightblue-500 hover:bg-nightblue-600 text-white scale-125"
                        >
                            <MarketerInviteIcon />
                        </MarketerCellButton>
                        <GroupDropdownMenuBox menuIsOpen={menuIsOpen}>
                            {marketerGroupType !== MarketerStatusTypes.BLOCK ? (
                                // 초대 리스트의 경우
                                <React.Fragment>
                                    <GroupDropdownMenu
                                        onClick={() => {
                                            handleSubmitInvite(EditStateTypes.DONE)
                                        }}
                                    >
                                        수락
                                    </GroupDropdownMenu>
                                    <GroupDropdownMenu
                                        onClick={() => {
                                            handleSubmitInvite(EditStateTypes.FAILED)
                                        }}
                                    >
                                        거절
                                    </GroupDropdownMenu>
                                    <GroupDropdownMenu
                                        onClick={() => {
                                            handleSubmitInvite(EditStateTypes.DISABLED)
                                        }}
                                    >
                                        차단
                                    </GroupDropdownMenu>
                                </React.Fragment>
                            ) : (
                                // 차단 리스트의 경우
                                <GroupDropdownMenu
                                    onClick={() => {
                                        handleSubmitInvite(EditStateTypes.WAIT)
                                    }}
                                >
                                    차단 해제
                                </GroupDropdownMenu>
                            )}
                        </GroupDropdownMenuBox>
                    </GroupDropdownMenuContainer>
                </MarketerTableData>
            )}

            <HoverUserCardContainer
                userID={sender.user.id}
                onClick={() => {
                    handeRowClick()
                }}
            >
                <MarketerTableData className={`${MarketerCellWidth.MasterName} sticky z-10 left-0`}>
                    {sender.user.name}
                </MarketerTableData>
            </HoverUserCardContainer>

            <MarketerTableData className={`${MarketerCellWidth.Email}`}>{sender.user.email}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>{sender.user.tel}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>{sender.detail.age}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Location}`}>
                {locationTypes[sender.detail.location1]},{locationTypes[sender.detail.location2]},
                {locationTypes[sender.detail.location3]}
            </MarketerTableData>

            {/* {AccessRules.brandItemManagement(user) && (
                <MarketerTableData className={`${MarketerCellWidth.Performs}`}>
                    {sender.performs}
                </MarketerTableData>
            )}
            {AccessRules.brandItemManagement(user) && (
                <MarketerTableData className={`${MarketerCellWidth.Calcs}`}>
                    {sender.calculates}
                </MarketerTableData>
            )} */}
            <MarketerTableData className={`${MarketerCellWidth.Created}`}>{sender.created}</MarketerTableData>
        </MarketerTableDataRow>
    )
}

const Columns = ({ user, submitInvite, marketerGroupType }) => {
    return (
        <MarketerTableHeadRow className={`${rowMinWidth(user, submitInvite)}`}>
            {/* {AccessRules.brandItemManagement(user) && (
                <MarketerTableHead className={`${MarketerCellWidth.Index}`}>
                    NO
                </MarketerTableHead>
            )} */}
            {!AccessRules.brandItemManagement(user) && submitInvite !== undefined && (
                <MarketerTableHead className={`${MarketerCellWidth.Invite}`}>
                    {marketerGroupType !== MarketerStatusTypes.BLOCK
                        ? // 초대 리스트의 경우
                          "초대 수락"
                        : // 차단 리스트의 경우
                          "차단 해제"}
                </MarketerTableHead>
            )}
            <MarketerTableHead className={`${MarketerCellWidth.MasterName} sticky top-0 left-0 z-20`}>
                초대자 이름
            </MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Email}`}>초대자 이메일</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Tel}`}>초대자 휴대전화</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Age}`}>초대자 나이</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Location}`}>초대자 활동 지역</MarketerTableHead>
            {/* {AccessRules.brandItemManagement(user) && (
                <MarketerTableHead className={`${MarketerCellWidth.Performs}`}>총 실적 수</MarketerTableHead>
            )}
            {AccessRules.brandItemManagement(user) && (
                <MarketerTableHead className={`${MarketerCellWidth.Calcs}`}>총 정산된 실적 수</MarketerTableHead>
            )} */}
            <MarketerTableHead className={`${MarketerCellWidth.Created}`}>생성 일</MarketerTableHead>
        </MarketerTableHeadRow>
    )
}

export default function MyPageGroupInviteList({
    itemMarketerInvites,
    openMarketerList,
    submitInvite = undefined,
    marketerGroupType,
}) {
    const { user } = useUserStore()
    const [locationTypes, setLocationTypes] = useState({})

    useEffect(() => {
        getTypes()
    }, [])

    const getTypes = () => {
        getRegionTypes().then((response) => {
            let regionTypes = { ...response }
            regionTypes[0] = "-"
            setLocationTypes(regionTypes)
        })
    }

    return (
        <MarketerTableCon>
            <Columns user={user} submitInvite={submitInvite} marketerGroupType={marketerGroupType} />

            {itemMarketerInvites.map((itemMarketerInvite, idx) => (
                <MarketerGroupTableRow
                    user={user}
                    itemMarketerInvite={itemMarketerInvite}
                    locationTypes={locationTypes}
                    openMarketerList={openMarketerList}
                    submitInvite={submitInvite}
                    marketerGroupType={marketerGroupType}
                    idx={idx}
                    key={idx}
                />
            ))}
            {/* <MarketerTableBody>
            </MarketerTableBody> */}
        </MarketerTableCon>
    )
}
