import React, { useState, useEffect, useRef } from "react"
import { useParams, Link, useNavigate } from "react-router-dom"
import { getBrandItemView, registItemMarketer } from "../../apis/api/boardItems"
import { ImageBase64, ImageContainer } from "../../components/ImageContainer"
import { ITEM_DATA } from "../../constants/ConstItem"
import BrandItem from "../../types/brandItems/BrandItem"
import { AdCategoryTypeNames, ItemSalesTypeNames, MarketerTypeNames } from "../../types/brandItems/BrandItemTypes"
import {
    ItemApplyBrandContainer,
    ItemApplyBrandContents,
    ItemApplyBrandDescription,
    ItemApplyBrandDescriptionContainer,
    ItemApplyBrandIconContainer,
    ItemApplyBrandTitle,
    ItemApplyBrandTitleContainer,
    ItemApplyDetail,
    ItemDetailApplyContents,
    ItemDetailApplyItem,
    ItemDetailApplyItemContainer,
    ItemDetailApplyItemContents,
    ItemDetailApplyItemDescription,
    ItemDetailApplyButton,
    ItemDetailContainer,
    ItemDetailContentContainer,
    ItemDetailContentDiv,
    ItemDetailContentTitle,
    ItemDetailImageContainer,
    ItemDetailIntroduce,
    ItemDetailNav,
    ItemDetailNavInner,
    ItemDetailPageContainer,
    ItemDetailShareContainer,
    ItemDetailThumbnail,
    ItemDetailThumbnailContainer,
    ItemDetailThumbnailTitle,
    ShareButton,
    ShareIcon,
    ItemDetailApplyMarketerStatus,
    ItemDetailTitleContainer,
    ItemDetailThumbDataContainer,
    ItemDetailNavContainer,
    ItemDetailMobileControlContainer,
    ItemDetailMobileControlBox,
    ItemDetailMobileControlScrollTopButton,
    ItemDetailMobileControlScrollTopIcon,
    ItemDetailMobileApplyMarketerStatus,
    ItemDetailMobileApplyButton,
    ItemDetailApplyTypeContentGrid,
    ItemDetailApplyTypeContentBox,
    ItemDetailApplyTypeContentTitle,
    ItemDetailApplyTypeContentValue,
    ItemDetailApplyItemBox,
} from "./ItemDetailStyle"
import { UserTypes } from "../../types/users/UserTypes"
import { useUserStore } from "../../zustand/store"
import { useLoginModalStore, marketerApplyModal } from "../../zustand/modalstore"
import UserAvatar from "../../components/user/UserAvatar"
import tw from "tailwind-styled-components"
import AccessRules from "../../rules/AccessRules"
import { MarketerStatusTypes } from "../../types/brandItems/BrandItemTypes"
import FileUtils from "../../utils/FileUtils"
import { registItemByMarketer } from "../../apis/services/brandItems"
import Utils from "../../utils/Utils"
import ReactQuillContent from "../../components/boards/ReactQuillContent"
import { useUiStore } from "../../zustand/uiStore"
import { isMobile } from "react-device-detect"
import CKEditorContent from "../../components/boards/CKEditorContent"

export default function ItemDetail() {
    const { user } = useUserStore((state) => state)
    const { mainNavHide } = useUiStore()

    const { itemID } = useParams()
    const setLoginShow = useLoginModalStore((state) => state.setLoginShow)
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(false)
    const [item, setItem] = useState(new BrandItem())

    const [descriptionContent, setDescriptionContent] = useState("")
    const [missionContent, setMissionContent] = useState("")
    const [rewardContent, setRewardContent] = useState("")
    const [contractContent, setContractContent] = useState("")

    const [marketerStatusType, setMarketerStatusType] = useState(null)

    const [activeSection, setActiveSection] = useState(1)
    const DescriptionRef = useRef(null)
    const MissionRef = useRef(null)
    const RewardRef = useRef(null)
    const ContractRef = useRef(null)
    const navContainerRef = useRef(null)

    //zustand로 가져온 데이터
    const setItemID = marketerApplyModal((state) => state.setItemID)
    const setMarketerModalShow = marketerApplyModal((state) => state.setMarketerApplyModal)

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            getBrandItem()
        }, 150)
    }, [itemID])

    const getBrandItem = () => {
        setLoading(true)
        getBrandItemView(itemID)
            .then((response) => {
                const responseItem = new BrandItem()
                responseItem.convertByResponse(response)
                setItem(responseItem)
                setMarketerStatusType(response.marketer_status_type)
                initContractContent(responseItem)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert("찾을 수 없습니다.")
                navigate(-1)
            })
    }

    const initContractContent = (_item) => {
        setDescriptionContent(FileUtils.replaceMedieFileURL(_item.detail.description))
        setMissionContent(FileUtils.replaceMedieFileURL(_item.contract.mission))
        setRewardContent(FileUtils.replaceMedieFileURL(_item.contract.reward))
        setContractContent(FileUtils.replaceMedieFileURL(_item.contract.contract))
    }

    const handleNavLinkClick = (section) => {
        let ref
        let offset = 104
        switch (section) {
            case 1:
                ref = DescriptionRef
                break
            case 2:
                ref = MissionRef
                break
            case 3:
                ref = RewardRef
                break
            case 4:
                ref = ContractRef
                break
            default:
                break
        }

        if (ref) {
            let scrollOption = {
                top: ref.current.offsetTop - offset,
                behavior: "smooth",
            }

            window.scrollTo(scrollOption)
        }
    }

    // 임시 코드
    const handleRegist = () => {
        registItemMarketer(item.id).then((response) => {
            if (response) {
                alert("신청 되었습니다.")
                getBrandItem()
            }
        })
    }

    const NotUser = () => {
        setLoginShow(true)
        alert("로그인 후 이용 가능 합니다")
    }

    const goApply = () => {
        // setMarketerModalShow(true)
        // setItemID(itemID)
        navigate(`/apply/${itemID}`)
    }

    const handleApplyClick = () => {
        {
            !Utils.isNullOrUndefined(user.userType) && user.userType !== UserTypes.NONE ? goApply() : NotUser()
        }
    }
    // const handleReqItem = () => {
    //     {(user.userType !== UserTypes.NONE)?
    //         navigate(`/apply/${itemID}`)
    //     :
    //         NotUser()
    //     }
    // }

    const handleCall = (tel) => {
        // 정규 표현식 정의
        const regex1 = /^(\d{2,3})(\d{3,4})(\d{4})$/ // '01097401615' 형식
        const regex2 = /^(\d{2,3})-(\d{3,4})-(\d{4})$/ // '010-9740-1615' 형식 또는 '02-123-4567' 형식

        // 정규 표현식 테스트
        if (!regex1.test(tel) && !regex2.test(tel)) {
            return
        }

        window.location.href = `tel:${tel}`
    }

    return (
        <ItemDetailPageContainer>
            <ItemDetailContainer>
                <ItemDetailIntroduce>
                    {/* <ItemDetailTitleContainer>
                        <ItemDetailThumbnailTitle>
                            {item.detail.title}
                        </ItemDetailThumbnailTitle>
                        <ItemDetailShareContainer>
                            <ShareButton>
                                <ShareIcon/>
                            </ShareButton>
                        </ItemDetailShareContainer>
                    </ItemDetailTitleContainer> */}
                    <ItemDetailThumbDataContainer>
                        {/* 썸네일 이미지 */}
                        {/* 정보 박스 */}
                        <ItemDetailApplyItem>
                            {/* 첫번째 줄 */}
                            <div className="flex max-md:flex-col-reverse justify-between w-full">
                                <div className="flex flex-col max-md:p-2">
                                    {isLoading ? (
                                        <div className="skeleton flex items-center w-4/5 font-semibold text-xl">
                                            loading....
                                        </div>
                                    ) : (
                                        <ItemDetailThumbnailTitle>{item.detail.title}</ItemDetailThumbnailTitle>
                                    )}
                                    {isLoading ? (
                                        <div className="skeleton flex items-center w-2/5 font-semibold leading-5 mt-2">
                                            loading....
                                        </div>
                                    ) : (
                                        <span className="text-slate-700 leading-5 mt-2">
                                            {item.detail.adDescription}
                                        </span>
                                    )}

                                    {isLoading ? (
                                        <div className="skeleton w-36 h-[1rem] max-md:mt-6 md:mt-auto text-transparent">
                                            loading...
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-3 max-md:gap-x-2 md:gap-x-6 w-full max-md:mt-6 md:mt-auto">
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>
                                                    카테고리
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {AdCategoryTypeNames[item.detail.adCategory]}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <div className="col-span-2">
                                                <ItemDetailApplyItemBox>
                                                    <ItemDetailApplyItemDescription>
                                                        지역
                                                    </ItemDetailApplyItemDescription>
                                                    <ItemDetailApplyItemContents>
                                                        {item.detail.sigungu.title()}
                                                    </ItemDetailApplyItemContents>
                                                </ItemDetailApplyItemBox>
                                            </div>
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <div className="skeleton w-32 h-[1rem] mt-2 text-transparent">loading...</div>
                                    ) : (
                                        <div className="grid grid-cols-3 max-md:gap-x-2 md:gap-x-6 w-full mt-2">
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>상호명</ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyName}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox
                                                onClick={() => {
                                                    handleCall(item.detail.adCompanyTel)
                                                }}
                                            >
                                                <ItemDetailApplyItemDescription>
                                                    담당자 전화
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyTel}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                            <ItemDetailApplyItemBox>
                                                <ItemDetailApplyItemDescription>
                                                    담당자 이메일
                                                </ItemDetailApplyItemDescription>
                                                <ItemDetailApplyItemContents>
                                                    {item.detail.adCompanyEmail}
                                                </ItemDetailApplyItemContents>
                                            </ItemDetailApplyItemBox>
                                        </div>
                                    )}
                                </div>
                                <ItemDetailThumbnail className="w-auto md:h-36">
                                    <ImageBase64 imageID={item.detail.thumbnailImageID} duration={true} />
                                </ItemDetailThumbnail>
                            </div>
                            {/* <ItemDetailApplyContents>
                                <ItemDetailApplyItemDescription>
                                    기간
                                </ItemDetailApplyItemDescription>
                                <ItemDetailApplyItemContents >
                                    {item.detail.dateStart} ~ {item.detail.dateEnd}
                                </ItemDetailApplyItemContents>
                            </ItemDetailApplyContents> */}
                        </ItemDetailApplyItem>
                    </ItemDetailThumbDataContainer>
                </ItemDetailIntroduce>
                <ItemApplyDetail>
                    <ItemDetailContentContainer ref={DescriptionRef}>
                        <ItemDetailContentTitle>상품 상세</ItemDetailContentTitle>
                        {/* <ReactQuillContent content={descriptionContent} isLoading={isLoading} /> */}
                        <CKEditorContent content={descriptionContent} isLoading={isLoading} />
                        {/* <ItemDetailImageContainer>
                            <ImageBase64 imageID={item.detail.descriptionImageID} useImageHeight={true} />
                        </ItemDetailImageContainer> */}
                    </ItemDetailContentContainer>
                </ItemApplyDetail>
            </ItemDetailContainer>
        </ItemDetailPageContainer>
    )
}

const BlindItemContent = ({ loginAction }) => {
    return (
        <div className="flex flex-center w-full h-32">
            <div
                className="flex px-8 py-4 bg-slate-100 hover:bg-slate-150 rounded-lg cursor-pointer"
                onClick={() => {
                    loginAction()
                }}
            >
                <span className="text-gray-700">로그인 후 확인 가능합니다</span>
            </div>
        </div>
    )
}
