import { UserGenderTypes, UserTypes } from "./UserTypes"
import Utils from "../../utils/Utils"
export default class {
    constructor() {
        this.id = -1
        this.email = ""
        this.userType = UserTypes.NONE
        this.tel = ""
        this.birthday = ""
        this.age = ""
        this.gender = UserGenderTypes.MALE
        this.name = ""
        this.nickname = ""
        this.profileImageID = -1

        this.isAlertItem = true

        this.recommanderPoint = 0
        this.points = 0
        this.accumPoints = 0
        this.accumRecommandeds = 0
    }

    getNickname() {
        if (Utils.isStringNullOrEmpty(this.nickname)) {
            return this.name
        }

        return this.nickname
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.email = response["email"]
        this.userType = response["user_type"]
        this.tel = response["tel"]
        this.birthday = response["birthday"]
        this.age = response["age"]
        this.name = response["first_name"]
        this.nickname = response["nickname"]
        this.gender = response["gender"]

        this.isAlertItem = response["is_alert_item"]

        this.recommanderPoint = response["recommander_point"]
        this.points = response["points"]
        this.accumPoints = response["accum_points"]
        this.accumRecommandeds = response["accum_recommandeds"]

        if (response["profile_image_id"] !== null) {
            this.profileImageID = response["profile_image_id"]
        }

        if (response["is_deleted"] !== null) {
            this.isDeleted = response["is_deleted"]
        }
    }
}
