export default class{
    constructor() {
        this.id = -1
        this.index = -1
        this.groupID = -1
        this.name = ""
        this.description = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.index = response["index"]
        this.groupID = response["group_id"]
        this.name = response["name"]
        this.description = response["description"]
        
        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {
    }
}