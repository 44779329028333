import React,{useState,useEffect} from 'react'
import { useLocation  } from 'react-router-dom'
import { TermContainer,TermTitleContainer,TermTitle,TermLayout,TermDetailContainer,TermToggleButtonLayout,TermToggleButtonContainer,TermToggleButton, TermDetail, TermToggleButtonActive} from './TermandpolicyStyle'
import {UsgeInformation,UsageTerm,PrivacyPolicy} from  './termDetail'
import { Usage, Agreement } from '../user/agreementData'
import { getSystemAgreementFrom } from '../../apis/api/commons'
import { AgreementForm } from '../../types/Agreement'

export default function Termandpolicy(){
    const location = useLocation();
    const [selected,setSelected] = useState(0)
    const [termDetail ,setTermDetail] = useState(Usage)

    const [usageData,setUsageData] = useState(null)
    const [personalData,setPersonalData] = useState(null)
    

    const setData = async () => {
        const data = await getSystemAgreementFrom();
        const filteredListPersonal = data.filter(item => item.key === AgreementForm.PERSONAL);
        const filteredListUsage = data.filter(item => item.key === AgreementForm.USAGE);
        setPersonalData(filteredListPersonal[0].content)
        setUsageData(filteredListUsage[0].content)
    };

    useEffect(() => {
        setData()
        if(location.state){
            setSelected(location.state.key)
        }
    },[])

    return(
        <TermLayout>
            <TermContainer>
                <TermTitleContainer>
                    <TermTitle>
                        약관 및 정책
                    </TermTitle>
                </TermTitleContainer>

                <TermToggleButtonLayout>
                    <TermToggleButtonContainer>
                        <TermToggleButton 
                            isselected={selected===0}
                            onClick={() => {setSelected(0)}}
                            className='rounded-r-2xl left-0'
                        >
                            이용약관
                        </TermToggleButton>
                        <TermToggleButton 
                            isselected={selected===1}
                            onClick={() => {setSelected(1)}}
                            className='rounded-l-2xl left-[50%]'
                        >
                            개인정보 처리방침
                        </TermToggleButton>
                        <TermToggleButtonActive
                            className={`left-[${selected === 0 ? "0" : "50"}%] duration-300 rounded-2xl z-0`}
                            isselected={true}
                        />
                    </TermToggleButtonContainer>
                </TermToggleButtonLayout>

                <TermDetailContainer>
                    <TermDetail>
                        <div className="ck-content" dangerouslySetInnerHTML={ selected === 0 ? { __html: usageData} : { __html: personalData} } />
                    </TermDetail>
                </TermDetailContainer>                    
            </TermContainer>
        </TermLayout>
    )
}