import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { updateItemPerformValue } from "../../../apis/api/boardItems"
import { ModalContainer } from "../../../components/ModalContainer"
import AccessRules from "../../../rules/AccessRules"

import {
    PerformColumnPreviewTypes,
    PerformStaticColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { EditStateTypes } from "../../../types/EditorTypes"
import Utils from "../../../utils/Utils"
import { SaveButton } from "../item/brand/ItemInputFormStyle"
import {
    PerformPreviewButton,
    PerformTextBox,
    PerformTextCalculateViewButton,
    PerformTextDetailButton,
    PerformTextDetailIcon,
    PerformUpdateValueBox,
    PerformValueBox,
    PerformValueInputLayout,
    PerformValueInputTitle,
} from "./MyPagePerformStyle"
import { HoverUserCard } from "../../../components/HoverComponants"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"

export const PerformValueTextUpdate = ({ perform, performValue, readOnly }) => {
    const [column, setColumn] = useState(new PerformColumn())
    const [previewType, setPreviewType] = useState(PerformColumnPreviewTypes.NONE)

    const [textValue, setTextValue] = useState(0)

    useEffect(() => {
        setColumn(performValue.column)
        setTextValue(performValue.text)

        if (performValue.column.texts.length > 0) {
            setPreviewType(performValue.column.texts[0].previewType)
        }
    }, [performValue])

    const handleChange = (_textValue) => {
        setTextValue(_textValue)
        performValue.text = _textValue
    }

    const valueReadOnly = readOnly
    // const valueReadOnly = (readOnly || !AccessRules.performValueWrite(column, perform))

    return (
        <PerformUpdateValueBox>
            {previewType === PerformColumnPreviewTypes.TRUNCATE && (
                <input
                    type="text"
                    className="w-full p-2 rounded-lg border focus:outline-none disabled:bg-gray-100"
                    value={textValue}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    disabled={valueReadOnly}
                />
            )}
            {previewType === PerformColumnPreviewTypes.BUTTON && performValue.hasDetail && (
                <textarea
                    className="w-full h-full p-2 rounded-lg border focus:outline-none scroll-transparent disabled:bg-gray-100"
                    value={textValue}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    disabled={valueReadOnly}
                />
            )}
        </PerformUpdateValueBox>
    )
}
