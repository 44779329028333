import tw from 'tailwind-styled-components'
import {ArrowUpward} from '@styled-icons/evaicons-solid/ArrowUpward'

export const HomeCon = tw.div`
  w-full h-full flex flex-col
  p-10
`
export const DashboardGrid =tw.div`
  w-full h-full grid grid-cols-3 gap-10
`
export const DashboardCon = tw.div`
  bg-white rounded-lg flex flex-col overflow-hidden drop-shadow-lg
`
export const DashboardPerformCon = tw.div`
  w-full grid grid-cols-3 gap-10 overflow-hidden
`
export const DashboardPerform = tw.div`
  bg-white rounded-lg flex p-2 drop-shadow
`
export const ArrowUpIcon = tw(ArrowUpward)`
    h-5 text-green-500
`
export const ArrowDownIcon =tw(ArrowUpIcon)`
    origin-center rotate-180 text-red-500
`
export const TableCon = tw.div`
    h-full w-full flex flex-col
`
export const TableHeaderCon = tw.div`
    w-full h-16 bg-blue-400 flex
`
export const TableHeader = tw.div`
    h-full flex items-center justify-center text-white
`
export const TableBodyCon = tw.div`
    w-full h-full flex flex-col overflwo-scroll scroll-transparent scroll-overlay
`
export const TableBodyRow = tw.div`
    w-full h-14 border-b flex hover:bg-gray-100 cursor-pointer
`
export const TableData = tw.div`
    h-full flex items-center justify-center
`

export const StatContainer = tw.div`
  flex h-full flex-col w-full items-center justify-center
`

export const StatWrapper = tw.div`
  relative h-full w-full
`

export const StatTitle = tw.span`
  absolute left-0 top-0
`

export const StatNumber = tw.span`
  text-3xl pr-2 font-semibold
`

export const StatChangeWrapper = tw.div`
  flex w-20 items-end
`

export const StatChangeIcon = tw(ArrowUpIcon)`
  text-green-500
`

export const StatChangeValue = tw.span`
  text-green-500
`

export const StatChangeIconDown = tw(ArrowDownIcon)`
  text-red-500
`

export const StatChangeValueDown = tw.span`
  text-red-500
`