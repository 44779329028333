import { PerformColumnFormTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.name = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["column_id"]
        this.name = response["name"]
    }

    convertToRequest() {
        let request = {
            id: -1,
            column_id: 0,
            name: "",
        }

        request.id = this.id
        request.column_id = this.columnID
        request.name = this.name
        return request
    }
}