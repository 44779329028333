// 여기 작성하는 타입은 백엔드와 호환되어야한다.

export const PointHistoryTypes = {
    ETC: 0,
    MANUAL_DEPOSIT: 1,
    MANUAL_WITHDRAW: 2,
    SIGNUP_REFERRAL: 3,
    SIGNUP_REFERRED: 4,
    COUPON_BUY: 5,
    COUPON_REFUND: 6,
}
export const PointHistoryTypeNames = {
    0: "기타",
    1: "수동 지급",
    2: "수동 회수",
    3: "추천인 지급",
    4: "추천받은 지급",
    5: "쿠폰 구입",
    6: "쿠폰 환불",
}

export const CouponApplicationStatusTypes = {
    WAIT: 0,
    APPLY: 1,
    DONE: 2,
    REJECT: 3,
    REFUND_APPLY: 4,
    REFUNDED: 5,
    FAILED: 6,
}
export const CouponApplicationStatusTypeNames = {
    0: "대기",
    1: "신청 중",
    2: "구매 완료",
    3: "구매 거절",
    4: "환불 요청",
    5: "환불 완료",
    6: "구매 실패",
}
