import React, { useState, useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import {
    FilterCategoryTitle,
    FilterInner,
    FilterTop,
    ItemCardListGrid,
    ItemPageBox,
    ItemPageBoxInner,
    ItemPageBoxInnerLeft,
    ItemPageBoxInnerRight,
    ItemPaginationCon,
    ItemPaginationInner,
    ItemSearchCon,
    ItemSortOptionCon,
    ItemSortSelection,
    PaginationButton,
    SearchButton,
    SearchInput,
    FilterOptionBox,
    CategoryCheckItems,
    SearchCol,
    SearchControlRow,
    SearchTypeSelection,
    ItemSortSwitchCon,
    ItemPageTitle,
    SearchTypeSelectionBox,
    CheckIcon,
    ItemCatgoryCheckContainer,
    ItemCategoryCheckIconContainer,
    ItemCategoryText,
    ItemAdvertiseBox,
    ItemAdvertiseButton,
    ItemAdvertiseImageContainer,
    SearchInputBox,
    FilterToggleButton,
    FilteredIcon,
    FilterIcon,
    ResetButton,
} from "./ItemStyle"
// import {CARD_CATEGORY_DATA_LIST} from '../../constants/ConstCard'
import { ItemOrderNames, ItemOrders, ItemOrderValues, ITEM_PAGE_SIZES } from "../../constants/ConstItem"
import { useLocation } from "react-router-dom"
import { BrandItemFilterCategory } from "../../types/brandItems/BrandItemOption"
import { Pagination } from "../../components/Pagination"
import SortSwitch from "../../components/SortSwitch"
import { getAdItemList } from "../../apis/api/boardItems"
import BrandItemList from "../../types/brandItems/BrandItemList"
import { ItemCardType } from "../../types/DataEnum"
import {
    AdCategoryTypeNames,
    SidoTypes,
    ItemSearchTypeNames,
    ItemSearchTypes,
} from "../../types/brandItems/BrandItemTypes"
import { useSearchParams } from "react-router-dom"
import BrandItemCard from "../../components/brandItems/BrandItemCard"
import { PaginationStyles } from "../../types/StyleTypes"
import { SortTypes } from "../../types/EditorTypes"
import BrandItem from "../../types/brandItems/BrandItem"
import Advertisement from "../../components/Advertisement"

import { isMobile } from "react-device-detect"
import { PerformTableFilterIcon } from "../myPage/perform/MyPagePerformStyle"
import { UserTypes } from "../../types/users/UserTypes"
import { AdItemCard } from "../../components/brandItems/AdItemCard"
import { ItemSearchCombo } from "../../components/brandItems/Inputs"
import { getSigunguDatas } from "../../apis/api/commons/index"
import Sigungu from "../../types/commons/Sigungu"
import Utils from "../../utils/Utils"

// 레이아웃, 크기, 위치, 마진, 패딩,
// 텍스트(레이아웃, 사이즈, 무게, 색상(호버)),
// 색상(호버), 보더(호버), 곡률, 그림자
// 트랜지션, 가시성, 투명도,
// 오버플로우, 기타

const MAX_PAGE_BUTTON_LENGTH_DESKTOP = 10
const MAX_PAGE_BUTTON_LENGTH_MOBILE = 5

const CategoryCheckItem = ({ label, itemType, checkList, setFilterList, isLoading }) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        const _isSelected = checkList.includes(itemType)
        setIsSelected(_isSelected)
    }, [checkList])

    const handleCheck = () => {
        if (!isSelected) {
            setFilterList([...checkList, itemType])
        } else {
            setFilterList(checkList.filter((i) => i !== itemType))
        }
    }

    return (
        <ItemCatgoryCheckContainer
            isselected={isSelected}
            isLoading={isLoading}
            onClick={() => {
                handleCheck()
            }}
        >
            <div className="flex items-center h-4">
                <ItemCategoryCheckIconContainer isselected={isSelected}>
                    <CheckIcon isselected={isSelected} />
                </ItemCategoryCheckIconContainer>
                <ItemCategoryText isselected={isSelected}>{label}</ItemCategoryText>
            </div>
        </ItemCatgoryCheckContainer>
    )
}

export default function Items({
    user,
    categoryFilter,
    setCategoryFilter,
    sigunguFilter,
    setSigunguFilter,
    itemOrder,
    setItemOrder,
    itemSortType,
    setItemSortType,
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    itemCount,
    setItemCount,
    items,
    setItems,
    searchType,
    setSearchType,
    searchTypeName,
    setSearchTypeName,
    searchValue,
    setSearchValue,
    searchKeyword,
    setSearchKeyword,
}) {
    const location = useLocation()
    const navigate = useNavigate()

    const [showfilter, setShowfilter] = useState(true)

    const [searchParams] = useSearchParams()

    const [paginationSize, setPaginationSize] = useState(
        isMobile ? MAX_PAGE_BUTTON_LENGTH_MOBILE : MAX_PAGE_BUTTON_LENGTH_DESKTOP,
    )
    const [isLoading, setLoading] = useState(false)

    const [showFilters, setShowFilters] = useState(false) // 검색 밑에 나올 필터 보일지 여부 (모바일에서만 사용)
    const [showAdvertisement, setShowAdvertisement] = useState(true)

    const [sigunguAll, setSigunguAll] = useState([])
    const [sidoOptionKeys, setSidoOptionKeys] = useState([])
    const [sidoOptions, setSidoOptions] = useState([])
    const [sidoFilterID, setSidoFilterID] = useState(null)
    const [sigunguOptionKeys, setSigunguOptionKeys] = useState([])
    const [sigunguOptions, setSigunguOptions] = useState([])
    const [sigunguFilterID, setSigunguFilterID] = useState(null)

    useEffect(() => {
        if (location.state !== null) {
            // 스켈레톤 용 더미 20개
            setItems([
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
                new BrandItem(),
            ])

            let _categoryFilter = []
            let _sigunguFilter = []
            let _itemOrder = location.state.itemOrder
            if (!Utils.isNullOrUndefined(location.state.adCategory)) {
                _categoryFilter = location.state.adCategory
                setCategoryFilter(_categoryFilter)
            }
            if (!Utils.isNullOrUndefined(location.state.searchSido)) {
                _sigunguFilter = location.state.searchSido
                console.log("filter", _sigunguFilter)
                setSidoFilterID(_sigunguFilter)
                setSigunguFilter(_sigunguFilter)
            }
            // if (location.state.filterCategory === BrandItemFilterCategory.Sido) {
            //     _categoryFilter = [location.state.filterID]
            //     setCategoryFilter(_categoryFilter)
            // } else if (location.state.filterCategory === BrandItemFilterCategory.Sigungu) {
            //     _sigunguFilter = [location.state.filterID]
            //     setSigunguFilter(_sigunguFilter)
            // }

            let _searchKeyword = location.state.searchKeyword
            setSearchKeyword(_searchKeyword)
            setSearchValue(_searchKeyword)

            updateBrandItemList(
                1,
                pageSize,
                ItemOrderValues[_itemOrder],
                itemSortType,
                _categoryFilter,
                _sigunguFilter,
                _searchKeyword,
                searchType,
            )
        } else {
            if (items.length === 0) {
                // 스켈레톤 용 더미 20개
                setItems([
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                    new BrandItem(),
                ])
                updateBrandItemList(
                    pageIndex,
                    pageSize,
                    ItemOrderValues[itemOrder],
                    itemSortType,
                    categoryFilter,
                    sigunguFilter,
                    searchKeyword,
                    searchType,
                )
            }
        }

        getSigunguAll()
    }, [])

    useEffect(() => {
        const outlet = document.getElementById("router-outlet")
        setPaginationSize(
            isMobile || outlet.offsetWidth <= 640 ? MAX_PAGE_BUTTON_LENGTH_MOBILE : MAX_PAGE_BUTTON_LENGTH_DESKTOP,
        )

        setShowFilters(false)
    }, [isMobile])

    useEffect(() => {
        const selectedSigungus = sigunguAll.filter((s) => s.id === sigunguFilter)
        if (selectedSigungus.length === 0) {
            return
        }
        const selectedSigungu = selectedSigungus[0]

        if (Utils.isNullOrUndefined(selectedSigungu.sigungu)) {
            // 시도를 선택한 경우

            // 1. 현재 선택한 필터 아이디 업데이트
            setSidoFilterID(selectedSigungu.id)

            // 2. 시군구 옵션 업데이트
            let _sigunguOptionKeys = []
            let _sigunguOptions = {}
            sigunguAll
                .filter((s) => !Utils.isNullOrUndefined(s.sigungu) && selectedSigungu.sido === s.sido)
                .sort((a, b) => a.id - b.id)
                .map((s) => {
                    _sigunguOptionKeys.push(s.id)
                    _sigunguOptions[s.id] = s.subtitle()
                })
            setSigunguOptionKeys(_sigunguOptionKeys)
            setSigunguOptions(_sigunguOptions)
            setSigunguFilterID(null)
        } else {
            // 시군구를 선택한 경우

            // 1. 현재 선택한 필터 아이디 업데이트
            setSigunguFilterID(selectedSigungu.id)
        }
    }, [sigunguFilter])

    const getSigunguAll = async () => {
        const response = await getSigunguDatas()

        if (!Array.isArray(response)) {
            return
        }

        let _sigungus = []
        response.map((sigunguData) => {
            const _sigungu = new Sigungu()
            _sigungu.convertByResponse(sigunguData)
            _sigungus.push(_sigungu)
        })

        setSigunguAll(_sigungus)
        let _sidoOptionKeys = []
        let _sidoOptions = {}
        _sigungus
            .filter((s) => Utils.isNullOrUndefined(s.sigungu))
            .sort((a, b) => a.index - b.index)
            .map((s) => {
                _sidoOptionKeys.push(s.id)
                _sidoOptions[s.id] = s.subtitle()
            })
        setSidoOptionKeys(_sidoOptionKeys)
        setSidoOptions(_sidoOptions)
    }

    const updateBrandItemList = (
        pageIndex,
        pageSize,
        ordering,
        sortType,
        categoryFilter,
        sigunguFilter,
        searchKeyword,
        searchType,
    ) => {
        if (!isLoading && pageIndex >= 0) {
            setLoading(true)
            // 리스트 업데이트 시 입력중인 키워드를 검색에 사용할 키워드로 돌려놓는다.
            setSearchValue(searchKeyword)

            if (sortType === SortTypes.DESC) {
                ordering = "-" + ordering
            }

            const selectedSigungus = sigunguAll.filter((s) => s.id === sigunguFilter)
            if (selectedSigungus.length > 0) {
                const selectedSigungu = selectedSigungus[0]

                if (selectedSigungu.sido === "전국") {
                    sigunguFilter = null
                }
            }

            getAdItemList(pageIndex, pageSize, ordering, categoryFilter, sigunguFilter, searchKeyword, searchType)
                .then((response) => {
                    const brandItemList = new BrandItemList()
                    brandItemList.convertByResponse(response)

                    setItemCount(brandItemList.count)
                    setItems(brandItemList.items)
                    setLoading(false)
                    setPageIndex(pageIndex)
                })
                .catch((error) => {
                    console.log(error)
                    setItems([])
                    setLoading(false)
                })
        }
    }

    const handleSidoFilter = (value) => {
        if (isLoading) {
            return
        }
        setCategoryFilter(value)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            value,
            sigunguFilter,
            searchKeyword,
            searchType,
        )
    }

    const handleSigunguFilter = (value) => {
        if (isLoading) {
            return
        }

        setSigunguFilter(value)
        // updateBrandItemList(
        //     1,
        //     pageSize,
        //     ItemOrderValues[itemOrder],
        //     itemSortType,
        //     categoryFilter,
        //     value,
        //     searchKeyword,
        //     searchType,
        // )
    }

    const handleOrder = (value) => {
        if (isLoading) {
            return
        }

        setItemOrder(value)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[value],
            itemSortType,
            categoryFilter,
            sigunguFilter,
            searchKeyword,
            searchType,
        )
    }

    const handleSort = (sortType) => {
        setItemSortType(sortType)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            sortType,
            categoryFilter,
            sigunguFilter,
            searchKeyword,
            searchType,
        )
    }

    const handlePageSize = (value) => {
        if (isLoading) {
            return
        }

        setPageSize(ITEM_PAGE_SIZES[value])
        updateBrandItemList(
            1,
            ITEM_PAGE_SIZES[value],
            ItemOrderValues[itemOrder],
            itemSortType,
            categoryFilter,
            sigunguFilter,
            searchKeyword,
            searchType,
        )
    }

    const handlePageIndex = (value) => {
        if (isLoading) {
            return
        }
        updateBrandItemList(
            value,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            categoryFilter,
            sigunguFilter,
            searchKeyword,
            searchType,
        )

        const outlet = document.getElementById("router-outlet")
        outlet.scrollTo({
            top: 0,
        })
    }

    const handleSearch = () => {
        if (isLoading) {
            return
        }

        setSearchKeyword(searchValue)
        updateBrandItemList(
            1,
            pageSize,
            ItemOrderValues[itemOrder],
            itemSortType,
            categoryFilter,
            sigunguFilter,
            searchValue,
            searchType,
        )
    }
    const handleReset = () => {
        if (isLoading) {
            return
        }

        setSearchKeyword(searchValue)
        setSearchValue("")
        setCategoryFilter(null)
        setSidoFilterID(270)
        setSigunguFilter(270)
        updateBrandItemList(1, 20, ItemOrders.CREATED, SortTypes.DESC, null, null, "", ItemSearchTypes.TITLE_ONLY)
    }

    const handleSearchKeyPress = (e) => {
        if (e.key == "Enter") {
            handleSearch()
        }
    }

    const handleSearchType = (value) => {
        if (value === 0) {
            setSearchType(ItemSearchTypes.TITLE_ONLY)
            setSearchTypeName(ItemSearchTypeNames.TITLE_ONLY)
        } else if (value === 1) {
            setSearchType(ItemSearchTypes.BRAND_NAME_ONLY)
            setSearchTypeName(ItemSearchTypeNames.BRAND_NAME_ONLY)
        }
    }

    const [testStyle, setTestStyle] = useState(false)

    return (
        <ItemPageBox>
            <ItemPageBoxInner>
                <ItemPageTitle>
                    <span className="text-xl font-semibold">사업아이템</span>
                </ItemPageTitle>
                <ItemPageBoxInnerLeft>
                    <ItemSearchCon>
                        <SearchCol>
                            <SearchControlRow>
                                <SearchInputBox>
                                    <SearchInput
                                        type="text"
                                        placeholder="상품 혹은 브랜드"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                        }}
                                        onKeyPress={(e) => {
                                            handleSearchKeyPress(e)
                                        }}
                                    />
                                </SearchInputBox>
                                <div className="max-md:hidden md:flex space-x-2">
                                    <ItemSearchCombo
                                        label={"카테고리"}
                                        width={"44"}
                                        value={categoryFilter}
                                        setValue={setCategoryFilter}
                                        optionKeys={Object.keys(AdCategoryTypeNames)}
                                        options={AdCategoryTypeNames}
                                    />
                                    <ItemSearchCombo
                                        label={"지역"}
                                        width={"32"}
                                        value={sidoFilterID}
                                        setValue={handleSigunguFilter}
                                        optionKeys={sidoOptionKeys}
                                        options={sidoOptions}
                                    />
                                </div>
                                {/* <ItemSearchCombo
                                    label={"지역 시군구"}
                                    width={"36"}
                                    value={sigunguFilterID}
                                    setValue={handleSigunguFilter}
                                    optionKeys={sigunguOptionKeys}
                                    options={sigunguOptions}
                                /> */}
                                {/* <ItemSearchCombo label={"충청남도 김량장시"} width={"36"} options={{}} /> */}
                                {/* <div className="w-40 h-full px-2 flex flex-shrink-0 items-center rounded bg-green-500 ml-2">
                                    패션/잡화/뷰티/레저
                                </div>
                                <div className="w-20 h-full px-2 flex flex-shrink-0 items-center rounded bg-green-500 ml-2">
                                    충청남도
                                </div>
                                <div className="w-36 h-full px-2 flex flex-shrink-0 items-center rounded bg-green-500 ml-2">
                                    충청남도 김량장시
                                </div> */}
                                {/* <SearchTypeSelectionBox>
                                    <SearchTypeSelection value={searchTypeName} onChange={(e)=>{handleSearchType(e.target.selectedIndex)}}>
                                        <option key={ItemSearchTypes.TITLE_ONLY}>{ItemSearchTypeNames.TITLE_ONLY}</option>
                                        <option key={ItemSearchTypes.BRAND_NAME_ONLY}>{ItemSearchTypeNames.BRAND_NAME_ONLY}</option>
                                    </SearchTypeSelection>
                                </SearchTypeSelectionBox> */}

                                <SearchButton
                                    onClick={() => {
                                        handleSearch()
                                    }}
                                    isLoading={isLoading}
                                >
                                    검색
                                </SearchButton>
                                <ResetButton
                                    onClick={() => {
                                        handleReset()
                                    }}
                                    isLoading={isLoading}
                                >
                                    초기화
                                </ResetButton>
                                <FilterToggleButton
                                    onClick={() => {
                                        setShowFilters(!showFilters)
                                    }}
                                    isActive={showFilters}
                                >
                                    <FilterIcon />
                                </FilterToggleButton>
                            </SearchControlRow>
                        </SearchCol>

                        <FilterOptionBox isShow={showFilters}>
                            <FilterInner showfilter={showfilter.toString()}>
                                <FilterCategoryTitle>상품 유형</FilterCategoryTitle>
                                <CategoryCheckItems>
                                    <ItemSearchCombo
                                        label={"카테고리"}
                                        width={"44"}
                                        value={categoryFilter}
                                        setValue={setCategoryFilter}
                                        optionKeys={Object.keys(AdCategoryTypeNames)}
                                        options={AdCategoryTypeNames}
                                    />
                                    <ItemSearchCombo
                                        label={"지역"}
                                        width={"32"}
                                        value={sidoFilterID}
                                        setValue={handleSigunguFilter}
                                        optionKeys={sidoOptionKeys}
                                        options={sidoOptions}
                                    />
                                </CategoryCheckItems>
                            </FilterInner>
                        </FilterOptionBox>
                    </ItemSearchCon>
                    {/* <ItemAdvertiseBox>
                        <Advertisement
                            isShow={showAdvertisement}
                            setShow={setShowAdvertisement}
                            imageURL={`${process.env.REACT_APP_API_SERVER_URL}/media/static/advertisement/golf.png`}
                            linkURL={"https://www.volkswagen.co.uk/en.html"}
                        />
                    </ItemAdvertiseBox> */}
                </ItemPageBoxInnerLeft>
                <ItemPageBoxInnerRight>
                    {/* <ItemSortOptionCon>
                        <p></p>
                        <div className="flex items-center h-full">
                            <ItemSortSelection value={itemOrder} onChange={(e)=>{handleOrder(e.target.selectedIndex)}}>
                                <option className="border-none" key={ItemOrders.FAV} value={ItemOrders.FAV}>{ItemOrderNames[0]}</option>
                                <option key={ItemOrders.CREATED} value={ItemOrders.CREATED}>{ItemOrderNames[1]}</option>
                            </ItemSortSelection>

                            <ItemSortSwitchCon>
                                <SortSwitch sortType={itemSortType} setSortType={handleSort} />
                            </ItemSortSwitchCon>
                        </div>
                        <ItemSortSelection value={pageSize} onChange={(e)=>{handlePageSize(e.target.selectedIndex)}}>
                            {ITEM_PAGE_SIZES.map(size => (
                                <option key={size} value={size}>{size}개씩 보기</option>)
                            )}
                        </ItemSortSelection>
                    </ItemSortOptionCon> */}

                    <ItemCardListGrid>
                        {items.map((data, idx) => (
                            <AdItemCard
                                key={idx}
                                item={data}
                                itemType={ItemCardType.ITEM}
                                isLoading={isLoading}
                                testStyle={testStyle}
                            />
                        ))}
                    </ItemCardListGrid>

                    <ItemPaginationCon>
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={paginationSize}
                            styleType={PaginationStyles.TextButtons}
                        />
                    </ItemPaginationCon>
                </ItemPageBoxInnerRight>
            </ItemPageBoxInner>
        </ItemPageBox>
    )
}
