import React,{useEffect} from 'react';
import {PmsNavState} from  '../../../../types/DataEnum'

export default function StaffNotice({setPmsNavState}){

    useEffect(() => {
        setPmsNavState(PmsNavState.STAFFNOTICE)

        return() => (
            setPmsNavState(PmsNavState.NONE)
        )
      },[]);


    return(
        <React.Fragment>
                                  
        </React.Fragment>
    )
}