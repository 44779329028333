import React, { useEffect, useRef, useState } from "react"
import tw from 'tailwind-styled-components'
import { getImageFile } from "../../apis/api/files"
import BrandItem from "../../types/brandItems/BrandItem"
import { ImageBase64 } from "../ImageContainer"
import { useMouseHover } from '../../hooks/useMouseHover'
import { ItemInputBox, ItemInputLabel } from '../../pages/myPage/item/brand/ItemInputFormStyle'
import ReactDatePicker from "react-datepicker"
import useDropdownDetectClose from "../../hooks/useDropdownDetectClose"

const InputText = tw.input`
    px-2 py-1 w-full h-full bg-gray-100 rounded-lg
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50 focus:outline-none
    duration-200
`
const InputTextarea = tw.textarea`
    px-2 py-1 w-full h-full bg-gray-100 rounded-lg
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50 focus:outline-none
    duration-200
`
const InputSelectBox = tw.div`
    flex flex-center w-full bg-white rounded-md p-1 pl-2
    border
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50 focus:outline-none
    duration-200
`
const InputSelect = tw.select`
    w-full bg-transparent
`
const InputFile = tw.input`
    p-2 hidden
`
const InputFileButton = tw.label`
    px-4 py-1 bg-nightblue-400 rounded-full text-white min-w-[4.8rem] text-center
`
const InputFileName = tw.span`
    px-2 py-1 text-gray-700 w-full truncate
`
const InputImageThumbnail = tw.div`
    absolute flex w-44 h-32 left-20 overflow-hidden
    bg-white drop-shadow-xl rounded-lg
    ${(props) => props.isShow ? 
        "visible opacity-100 translate-y-[-5rem]" : 
        "invisible opacity-0 translate-y-[-4rem]"
    }
    transform-gpu
    duration-200
`

export const PerformItemInput = ({type, placeholder, value, setValue, onKeyPress, onBlur, disabled=false,onFocus, color="gray-100"}) => {
    return(
        <InputText 
            type={type} 
            className={`bg-${color}`}
            value={value} 
            onFocus={onFocus}
            onChange={(e)=>{setValue(e.target.value)}}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            disabled={disabled}
            placeholder={placeholder}
        />
    )
}
export const PerformItemTextarea = ({type, value, setValue, onKeyPress, onBlur, disabled=false,onFocus, color="gray-100", minHeight="2rem"}) => {
    return(
        <InputTextarea
            type={type} 
            className={`bg-${color} min-h-[${minHeight}]`}
            value={value} 
            onFocus={onFocus}
            onChange={(e)=>{setValue(e.target.value)}}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            disabled={disabled}
        />
    )
}

export const PerformItemCombo = ({optionKeys, names, value, onChange, editState, color="gray-100"}) => {
    return (
        <InputSelectBox className={`bg-${color}`}>
            <InputSelect key={value} value={value} onChange={onChange} editState={editState} className={`bg-${color}`}>
                <option key={-1} value={-1}>-</option>
                {optionKeys.map((optionKey, i) => <option key={optionKey} value={optionKey}>{names[i]}</option>)}
            </InputSelect>
        </InputSelectBox>
    )
}



export const PerformItemFile = ({label, fileID, onChange}) => {
    const [imageFile, setImageFile] = useState(new BrandItem())

    const [refMouseHover, isMouseHover] = useMouseHover()

    useEffect(()=>{
        setImageFile(new BrandItem())
        if (fileID >= 0) {
            getImageFile(fileID).then(response => {
                setImageFile(response)
            })
        }
    }, [fileID])

    return (
        <ItemInputBox>
            <ItemInputLabel>
                {label}
            </ItemInputLabel>
            <div className="flex items-center mt-1 relative">
                <InputFileButton for={`input-file-${label}`}>
                    업로드
                </InputFileButton>
                <InputFileName ref={refMouseHover}>
                    {imageFile.name}
                </InputFileName>
                <InputImageThumbnail isShow={isMouseHover && imageFile.id >=0}>
                    <ImageBase64 imageFile={imageFile} />
                </InputImageThumbnail>
            </div>
            <InputFile id={`input-file-${label}`} style={{width:0}} type="file" onChange={onChange} />
        </ItemInputBox>
    )
}

export const PerformItemCheckBox = ({checked, onChange}) => {
    return (
        <input type="checkbox" checked={checked} onChange={onChange} /> 
    )
} 

// eslint-disable-next-line react/display-name
const CalendarContainer = React.forwardRef(({children, popperPlacements, isOpen}, ref) => {        
    return( 
        <div
            ref={ref}
            style={{
                zIndex: "100",
                top: (popperPlacements==="top") ? `-248px` : ""
            }}
            className={`absolute bg-white border drop-shadow duration-300 ${(isOpen && popperPlacements) ? "block" : "hidden"}`}
        >
            {children}
        </div>
    )
})

export const DateRangePicker = ({value, setValue}) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [popperPlacements, setPopperPlacement] = useState('bottom')
    const calendarRef = useRef()

    const [isOpen, openRef, openHandler] = useDropdownDetectClose(false, [calendarRef])

    useEffect(() => {
        setPopperPlacement(null)
        const parentDiv = openRef.current.closest('.parent-div')
        const parentRect = parentDiv.getBoundingClientRect()
        const inputRect = openRef.current.getBoundingClientRect()
        
        if (inputRect.bottom + 240 > parentRect.bottom && parentRect.top > inputRect.top) {
            setPopperPlacement('top')
        } else {
            setPopperPlacement('bottom')
        }
    }, [isOpen])
    
    const formatDate = (date) => {
        if (!date) return ''
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
    }

    const handleDateChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
        if(start !== null && end !== null){
            openHandler(false)
            setValue(`${formatDate(start)} ~ ${formatDate(end)}`)
        }
    }
        
    const handleClosePicker = () => {
        openHandler(false)
    }
    
    return (
        <div className="relative h-full w-full" ref={openRef}>
            <div
                onClick={()=>{openHandler(true)}}
                onChange={(e) => {e.preventDefault}}
                className="bg-white flex flex-center rounded-lg h-7 w-full text-black text-center cursor-pointer"
            >
                {value}
            </div>

            <ReactDatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                onClose={handleClosePicker}
                calendarContainer={({ children }) => (
                    <CalendarContainer 
                        popperPlacements={popperPlacements}
                        isOpen={isOpen} 
                        ref={calendarRef}
                    >
                        {children}
                    </CalendarContainer>
                )}
            />
        </div>
    )
}