import React, { useEffect, useState } from "react"
import {
    NoticeCon,
    NoticeFilterContainer,
    NoticeBoxInner,
    NoticeBody,
    NoticeTable,
    NoticeListBody,
    NoticePagination,
    NoticeAddButton,
    NoticeTableHeaderBox,
    NoticeTableHeader,
    NoticeListRow,
    NoticeListData,
    NoticeListDataResponsiveBox,
} from "./NoticeStyle"
import { Pagination } from "../../components/Pagination"
import { PaginationStyles } from "../../types/StyleTypes"
import { Link } from "react-router-dom"
import {
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSearchButton,
    PerformTableSearchIcon,
} from "../myPage/perform/MyPagePerformStyle"
import { MarketerTableSortSelect, MarketerTableStatusSelectBox } from "../myPage/marketer/MyPageMarketerStyle"
import { addBoardNotice, getBoardNotices } from "../../apis/api/boards/index"
import { ModalContainer } from "../../components/ModalContainer"
import { NoticeTitle } from "../myPage/notice/MyPageNoticeStyle"
import AccessRules from "../../rules/AccessRules"
import BoardEdit from "../../components/boards/BoardEdit"
import { BoardImportnaceStatusTypes } from "../../types/boards/BoardTypes"
import { RESPONSIVE_WIDTH } from "../../constants/ConstUI"
import { BoardImportanceLabel } from "../../components/boards/BoardDetailStyle"

const MAX_PAGE_LENGTH = 10
const CellWidth = {
    Index: "max-sm:w-[3rem] sm:w-[4rem] flex-shrink-0 justify-center",
    Title: "max-sm:min-w-[8rem] sm:min-w-[16rem] w-full",
    User: "max-sm:min-w-[4rem] sm:min-w-[8rem] justify-center max-sm:hidden",
    Created: "max-sm:min-w-[5.5rem] sm:min-w-[8rem] justify-center",
}

const NoticeRow = ({ notice, importance, maxidx, idx }) => {
    return (
        <Link to={notice.id.toString()} className="w-full h-auto">
            {/* link to 에 해당 공지 사항의 id값 넣으면 됨 */}
            <NoticeListRow
                className={`${
                    importance === BoardImportnaceStatusTypes.IMPORTANT ? " bg-rose-50 hover:bg-rose-100" : ""
                }`}
            >
                {/* 공지사항 데이터 row 형식 */}
                <NoticeListData className={`${CellWidth.Index}`}>{maxidx - idx}</NoticeListData>
                <NoticeListData
                    className={`${CellWidth.Title} max-sm:flex-col sm:flex-row justify-start max-sm:items-start sm:items-center`}
                >
                    {importance === BoardImportnaceStatusTypes.IMPORTANT && (
                        <BoardImportanceLabel className="max-sm:mb-1">중요공지</BoardImportanceLabel>
                    )}
                    {notice.board.title}
                </NoticeListData>
                <NoticeListData className={`${CellWidth.User}`}>{notice.board.user.name}</NoticeListData>
                <NoticeListData className={`${CellWidth.Created} max-sm:text-xs`}>{notice.created}</NoticeListData>
            </NoticeListRow>
        </Link>
    )
}
// const NoticeRow = ({notice}) => {
//     return (
//         <Link to={notice.id.toString()} className="w-full h-full">
//             {/* link to 에 해당 공지 사항의 id값 넣으면 됨 */}
//             <NoticeListRow>
//                 {/* 공지사항 데이터 row 형식 */}
//                 <NoticeListData className={`${CellWidth.Index} max-sm:justify-center`}>
//                     {notice.index}
//                 </NoticeListData>
//                 <NoticeListDataResponsiveBox>
//                     <NoticeListData className={`${CellWidth.Title} justify-start`}>
//                         {notice.board.title}
//                     </NoticeListData>
//                     <div className="flex max-sm:w-full max-sm:justify-between max-sm:text-gray-500">
//                         <NoticeListData className={`${CellWidth.User} max-sm:justify-start sm:justify-center max-sm:text-xs`}>
//                             {notice.board.user.name}
//                         </NoticeListData>
//                         <NoticeListData className={`${CellWidth.Created} max-sm:text-xs max-sm:justify-end`}>
//                             {notice.created}
//                         </NoticeListData>
//                     </div>
//                 </NoticeListDataResponsiveBox>
//             </NoticeListRow>
//         </Link>
//     )
// }

const NoticeColumns = () => {
    return (
        <NoticeTableHeaderBox>
            <NoticeTableHeader className={CellWidth.Index}>NO</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.Title}>제목</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.User}>작성자</NoticeTableHeader>
            <NoticeTableHeader className={CellWidth.Created}>날짜</NoticeTableHeader>
        </NoticeTableHeaderBox>
    )
}

export default function Notice() {
    const [showNoticeEdit, setShowNoticeEdit] = useState(false)

    const [notices, setNotices] = useState([])

    const [search, setSearch] = useState("")
    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    useEffect(() => {
        getNotices()
    }, [])

    const getNotices = async () => {
        const response = await getBoardNotices(pageIndex, pageSize, search)
        if (response !== undefined) {
            const importantNotices = response.items.filter(
                (notice) => notice.board_importance_status === BoardImportnaceStatusTypes.IMPORTANT,
            )
            const regularNotices = response.items.filter(
                (notice) => notice.board_importance_status !== BoardImportnaceStatusTypes.IMPORTANT,
            )
            const sortedNotices = [...importantNotices, ...regularNotices]
            setNotices(sortedNotices)
            setItemCount(response.count)
        }
    }

    const handleSearchMarketer = () => {
        getNotices()
    }

    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }

    const handleCreateModalClose = (refresh = false) => {
        if (refresh) {
            if (pageIndex > 1) {
                setPageIndex(1)
            } else {
                getNotices()
            }
        }
        setShowNoticeEdit(false)
    }

    return (
        <NoticeCon>
            <NoticeBoxInner>
                <NoticeTitle>
                    <span>공지사항</span>
                </NoticeTitle>

                <NoticeFilterContainer>
                    <PerformTableSearchBox className="w-64">
                        <PerformTableSearch
                            type="text"
                            placeholder="제목"
                            onKeyPress={handleSearchMarketerEnter}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <PerformTableSearchButton
                            onClick={() => {
                                handleSearchMarketer()
                            }}
                        >
                            <PerformTableSearchIcon />
                        </PerformTableSearchButton>
                    </PerformTableSearchBox>
                </NoticeFilterContainer>

                <NoticeBody>
                    <NoticeTable>
                        <NoticeColumns />
                        <NoticeListBody>
                            {/* {notices.map((notice, idx ) => (
                                <NoticeRow notice={notice} key={idx} />
                            ))} */}
                            {notices.map((notice, idx) => (
                                <>
                                    <NoticeRow
                                        notice={notice}
                                        importance={notice.board_importance_status}
                                        key={idx}
                                        maxidx={notices.length}
                                        idx={idx}
                                    />
                                </>
                            ))}
                        </NoticeListBody>
                    </NoticeTable>
                    <NoticePagination>
                        <div />
                        <Pagination
                            page={pageIndex}
                            setPage={setPageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={MAX_PAGE_LENGTH}
                            styleType={PaginationStyles.NoneBGCircleButtons}
                        />
                        {AccessRules.noticeWrite() && (
                            <NoticeAddButton
                                onClick={() => {
                                    setShowNoticeEdit(!showNoticeEdit)
                                }}
                            >
                                등록
                            </NoticeAddButton>
                        )}
                    </NoticePagination>
                </NoticeBody>

                {/* <NoticeTableCon>
                    <NoticeTable/>
                </NoticeTableCon> */}
            </NoticeBoxInner>

            <ModalContainer show={showNoticeEdit} setShow={setShowNoticeEdit} mobileScreenHeight={RESPONSIVE_WIDTH.LG}>
                <BoardEdit
                    mainID={-1}
                    getMain={null}
                    addMain={addBoardNotice}
                    updateMain={null}
                    close={handleCreateModalClose}
                />
            </ModalContainer>
        </NoticeCon>
    )
}
