import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Autoplay, Scrollbar } from "swiper/modules" // 추가
import SwiperCore from "swiper"
// import "swiper/swiper.scss"
// import "swiper/components/navigation/navigation.scss"
// import "swiper/components/pagination/pagination.scss"
import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"

import styled from "styled-components"
import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"

const SwiperStyle = styled.div`
    width: 100%;
    height: 100%;

    @media not all and (min-width: 640px) {
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        background-color: transparent;
        top: calc(50%-17px);
        height: 33px;
        width: 33px;
        border-radius: 9999px;
        color: rgb(71, 85, 105);
        /* --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
        /* --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color); */
        /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
    }
    .swiper-button-prev,
    .swiper-button-next {
        &:after {
            font-size: 1rem;
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
        }
    }
    .swiper-button-next {
        padding-left: 0.25rem;
    }
    .swiper-pagination-bullet-active {
        background: rgb(255, 255, 255, 0.8);
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(129 127 127 / var(--tw-ring-opacity));
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    }
    .swiper-pagination {
        text-align: start;
        padding-left: 10%;
    }
`

function AdvertiseSlide({ ads }) {
    // SwiperCore.use([Navigation, Pagination, Autoplay]) // 추가

    return (
        <SwiperStyle>
            <Swiper
                className="banner w-full h-full shadow"
                // modules={[Navigation, Autoplay, Scrollbar]}
                modules={[Navigation, Pagination, Autoplay, Scrollbar]}
                // spaceBetween={50}
                slidesPerView={1}
                navigation
                loop
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 3000 }} // 추가
            >
                {ads.map((ad, index) => {
                    const pcUrl = FileUtils.getMediaFileURL(ad.image1URL)
                    let mobileUrl = FileUtils.getMediaFileURL(ad.image2URL)
                    if (Utils.isStringNullOrEmpty(mobileUrl)) {
                        mobileUrl = pcUrl
                    }

                    const hasLink = !Utils.isStringNullOrEmpty(ad.link)
                    const handleLink = () => {
                        if (!hasLink) {
                            return
                        }

                        window.open(ad.link, "_blank", "noopener,noreferrer")
                    }

                    return (
                        <SwiperSlide key={index} onClick={handleLink} className={`${hasLink ? "cursor-pointer" : ""}`}>
                            <div
                                className="max-md:hidden md:block w-full h-full"
                                style={{
                                    backgroundImage: `url(${pcUrl})`,
                                    backgroundSize: "100% 100%",
                                }}
                            ></div>
                            <div
                                className="max-md:block md:hidden w-full h-full"
                                style={{
                                    backgroundImage: `url(${mobileUrl})`,
                                    backgroundSize: "100% 100%",
                                }}
                            ></div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </SwiperStyle>
    )
}

export default AdvertiseSlide
