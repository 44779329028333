import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import tw from "tailwind-styled-components"
import { getRegionTypes } from "../../../../apis/api/commons"
import { reviveUser, updateUserDetail } from "../../../../apis/api/users"
import { getUserBrandRequestData } from "../../../../services/users"
import { MarketerStatusTypeColors } from "../../../../types/brandItems/BrandItemTypes"
import { EditStateTypeColors, EditStateTypes } from "../../../../types/EditorTypes"
import Brand from "../../../../types/users/Brand"
import UserBrand from "../../../../types/users/UserBrand"
import { userDetailModalStore, marketerContractViewer } from "../../../../zustand/modalstore"
import {
    MarketerCellButton,
    BuisnessImageButton,
    MarketerStatSelect,
    MarketerTableCon,
    MarketerTableData,
    MarketerTableDataRow,
    MarketerTableHead,
    MarketerTableHeadRow,
} from "../../marketer/MyPageMarketerStyle"
import { getBuisnessImage } from "../../../../apis/api/users/index"
import { getImageFile } from "../../../../apis/api/files"
import { StaffUserReviveButton } from "./StaffUserStyle"

const MarketerCellWidth = {
    Index: "min-w-[6rem]",
    Name: "min-w-[8rem]",
    Email: "min-w-[16rem] w-full",
    Tel: "min-w-[10rem]",
    Age: "min-w-[8rem]",
    ProfileImage: "min-w-[8rem]",
    BrandStatus: "min-w-[8rem]",
    BrandName: "min-w-[16rem] w-full",
    BrandManagerName: "min-w-[8rem]",
    BrandAddress: "min-w-[12rem]",
    BrandTel: "min-w-[10rem]",
    BrandBusinessNo: "min-w-[10rem]",
    BrandBusinessImage: "min-w-[8rem]",
    Created: "min-w-[8rem]",
    Deleted: "min-w-[8rem]",
}

const RowMinWidth = "min-w-[144rem] w-full"

const MarketerTableRow = ({ userBrand }) => {
    // const userBrand = new Brand()
    const [brandStatusType, setBrandStatusType] = useState(userBrand.brandStatusType)
    const [brandStatusEditStateType, setBrandStatusEditStateType] = useState(EditStateTypes.WAIT)

    const userShow = userDetailModalStore((state) => state.userShow)
    const setUserShow = userDetailModalStore((state) => state.setUserShow)
    const setUserId = userDetailModalStore((state) => state.setUserId)

    const marketerContractCardShow = marketerContractViewer((state) => state.marketerContractCardShow)
    const setMarketerContractCardShow = marketerContractViewer((state) => state.setMarketerContractCardShow)

    const contractImageSources = marketerContractViewer((state) => state.contractImageSources)
    const setContractImageSources = marketerContractViewer((state) => state.setContractImageSources)

    useEffect(() => {
        setBrandStatusType(userBrand.brandStatusType)
    }, [userBrand])

    const handleBrandStatus = (value) => {
        if (brandStatusEditStateType !== EditStateTypes.PENDING) {
            setBrandStatusEditStateType(EditStateTypes.PENDING)
            let requestData = {
                ...getUserBrandRequestData(
                    userBrand.detail.name,
                    userBrand.detail.companyManagerName,
                    userBrand.detail.address1,
                    userBrand.detail.address2,
                    userBrand.detail.tel,
                    userBrand.detail.businessImageID,
                ),
                brand_status_type: value,
                user_id: userBrand.user.id,
            }

            updateUserDetail(requestData)
                .then((response) => {
                    const _brand = new Brand()
                    _brand.convertByResponse(response.data)
                    setBrandStatusType(_brand.brandStatusType)
                    alert("수정되었습니다.")
                    setBrandStatusEditStateType(EditStateTypes.SUCCESS)
                })
                .catch((error) => {
                    alert("실패했습니다.")
                    setBrandStatusEditStateType(EditStateTypes.FAILED)
                    console.log(error)
                })
        } else {
            alert("로딩 중 입니다.")
        }
    }

    const handeRowClick = () => {
        setUserId(userBrand.marketer.user.id)
        setUserShow(true)
    }

    // 여기서 이미지 뷰어 로직
    const handleBusinessImage = () => {
        if (userBrand.detail.businessImageID > 0) {
            getImageFile(userBrand.detail.businessImageID)
                .then((response) => {
                    setContractImageSources([response.base64])
                })
                .then(setMarketerContractCardShow(true))
        }
    }

    const handleReviveUser = () => {
        if (!confirm("탈퇴 복구 하시겠습니까?")) {
            return
        }

        reviveUser(userBrand.user.id)
            .then((response) => {
                alert("탈퇴 복구 처리되었습니다.\n새로 고침해주세요.")
            })
            .catch((error) => {
                alert("복구 실패했습니다.")
            })
    }

    return (
        <MarketerTableDataRow className={`${RowMinWidth}`}>
            <MarketerTableData className={`${MarketerCellWidth.Index}`}>{userBrand.user.id}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Name}`}>{userBrand.user.name}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>{userBrand.user.email}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>{userBrand.user.tel}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>{userBrand.user.age}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.ProfileImage}`}>프로필 이미지</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandStatus}`}>
                <MarketerStatSelect
                    value={brandStatusType}
                    className={`bg-[${EditStateTypeColors[brandStatusType]}] text-white`}
                    onChange={(e) => {
                        handleBrandStatus(e.target.value)
                    }}
                    editStateType={brandStatusEditStateType}
                >
                    <option value={EditStateTypes.WAIT}>대기</option>
                    <option value={EditStateTypes.DONE}>승인</option>
                    <option value={EditStateTypes.FAILED}>차단</option>
                </MarketerStatSelect>
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandName}`}>{userBrand.detail.name}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandManagerName}`}>
                {userBrand.detail.companyManagerName}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandAddress} text-sm`}>
                {userBrand.detail.address1} {userBrand.detail.address2}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandTel}`}>{userBrand.detail.tel}</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandBusinessNo}`}>
                {userBrand.businessNo}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.BrandBusinessImage}`}>
                {userBrand.detail.businessImageID ? (
                    <BuisnessImageButton
                        isimage={true}
                        onClick={() => {
                            handleBusinessImage()
                        }}
                    >
                        보기
                    </BuisnessImageButton>
                ) : (
                    <BuisnessImageButton isimage={false}>미등록</BuisnessImageButton>
                )}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Created}`}>가입 날짜</MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Deleted}`}>
                {userBrand.user.isDeleted ? (
                    <StaffUserReviveButton
                        onClick={() => {
                            handleReviveUser()
                        }}
                    >
                        탈퇴 복구
                    </StaffUserReviveButton>
                ) : (
                    <span>활동 중</span>
                )}
            </MarketerTableData>
            {/* <MarketerTableData className={`${MarketerCellWidth.Index}`}>
                {itemMarketer.index}
            </MarketerTableData>
            <HoverUserCard userType={itemMarketer.marketer.user.id} onClick={() => {handeRowClick(itemMarketer)}}    >
                <MarketerTableData className={MarketerCellWidth.Name}>
                    {itemMarketer.marketer.user.name}
                </MarketerTableData>
            </HoverUserCard>
            <MarketerTableData className={`${MarketerCellWidth.GroupName}`}>
                {itemMarketer.group.name}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Email}`}>
                {itemMarketer.marketer.user.email}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Tel}`}>
                {itemMarketer.marketer.user.tel}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Age}`}>
                {itemMarketer.marketer.detail.age}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Location}`}>
                {locationTypes[itemMarketer.marketer.detail.location1]},
                {locationTypes[itemMarketer.marketer.detail.location2]},
                {locationTypes[itemMarketer.marketer.detail.location3]}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Performs}`}>
                {itemMarketer.performs}
            </MarketerTableData>
            <MarketerTableData className={`${MarketerCellWidth.Status}`}>
                <MarketerStatSelect 
                    value={brandStatusType} 
                    className={`bg-[${MarketerStatusTypeColors[brandStatusType]}] text-white`}
                    onChange={(e)=>{handleBrandStatus(e.target.value)}}
                    editStateType={brandStatusEditStateType}
                >
                    <option value={MarketerStatusTypes.WAIT}>{MarketerStatusTypeNames[MarketerStatusTypes.WAIT]}</option>
                    <option value={MarketerStatusTypes.CONFIRM}>{MarketerStatusTypeNames[MarketerStatusTypes.CONFIRM]}</option>
                    <option value={MarketerStatusTypes.BLOCK}>{MarketerStatusTypeNames[MarketerStatusTypes.BLOCK]}</option>
                </MarketerStatSelect>
            </MarketerTableData> */}
        </MarketerTableDataRow>
    )
}

const Columns = () => {
    return (
        <MarketerTableHeadRow className={`sticky top-0 z-10 ${RowMinWidth}`}>
            <MarketerTableHead className={`${MarketerCellWidth.Index}`}>NO</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Name}`}>이름</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Email}`}>이메일</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Tel}`}>전화번호</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Age}`}>나이</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.ProfileImage}`}>프로필 이미지</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandStatus}`}>브랜드 상태</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandName}`}>브랜드 명</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandManagerName}`}>브랜드 대표 이름</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandAddress}`}>브랜드 주소</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandTel}`}>브랜드 전화번호</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandBusinessNo}`}>사업자 등록번호</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.BrandBusinessImage}`}>사업자 등록증</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Created}`}>가입 날짜</MarketerTableHead>
            <MarketerTableHead className={`${MarketerCellWidth.Deleted}`}>탈퇴 여부</MarketerTableHead>
        </MarketerTableHeadRow>
    )
}

export default function StaffUserBrandTable({ userBrands }) {
    return (
        <MarketerTableCon>
            <Columns />

            {userBrands.map((userBrand, idx) => (
                <MarketerTableRow userBrand={userBrand} key={idx} />
            ))}
            {/* <MarketerTableBody>
            </MarketerTableBody> */}
        </MarketerTableCon>
    )
}
