import { EditStateTypes } from "../EditorTypes"
import User from "./User"

export default class {
    constructor() {
        this.id = -1
        this.user = new User()
        this.keyword = ""
        this.dateStart = ""
        this.dateEnd = ""
        this.pointMain = ""
        this.pointSub = ""
        this.description = ""

        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]

        const userResponse = response["user"]
        this.user = new User()
        if (userResponse !== null && userResponse !== undefined) {
            this.user.convertByResponse(userResponse)
        }

        this.keyword = response["keyword"]
        this.dateStart = response["date_start"]
        this.dateEnd = response["date_end"]
        this.pointMain = response["point_main"]
        this.pointSub = response["point_sub"]
        this.description = response["description"]

        this.created = response["created"]
        this.updated = response["updated"]
    }
}
