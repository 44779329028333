import React, { useEffect, useState, useRef } from "react"
import tw from "tailwind-styled-components"
import { PerformItemCheckBox } from "../../../components/brandItems/PerformInputs"

import { EditStateTypes } from "../../../types/EditorTypes"
import { PerformValueBox } from "./MyPagePerformStyle"

export const PerformValueCheckBoxCell = ({ column, value, perform, performCheck }) => {
    const [editState, setEditState] = useState(EditStateTypes.WAIT)
    const [selectedIndex, setIndex] = useState(0)
    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        performCheck(perform, !checked)
        setChecked(!checked)
    }

    return (
        <PerformValueBox>
            <div
                className="flex flex-center h-10 aspect-square"
                onClick={() => {
                    handleChange()
                }}
            >
                <PerformItemCheckBox
                    checked={checked}
                    onChange={(e) => {
                        handleChange()
                    }}
                />
            </div>
        </PerformValueBox>
    )
}
