export default class {
    constructor() {
        this.id = ""
        this.itemMarketerID = -1
        this.paperID = -1
        this.isAgree = false
        this.tel = ""
        this.name = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.itemMarketerID = response["item_marketer_id"]
        this.paperID = response["paper_id"]
        this.isAgree = response["is_agree"]
        this.tel = response["tel"]
        this.name = response["name"]
    }

    convertToRequest() {
        let request = {
            item_marketer_id: this.itemMarketerID,
            paper_id: this.paperID,
            is_agree: this.isAgree,
            tel: this.tel,
            name: this.name,
        }

        return request
    }
}
