import React, { useEffect, useState } from "react"
import { Routes, Route, Router, useLocation } from "react-router-dom"
import Home from "../pages/home/Home"
import Items from "../pages/brandItems/Items"
import ItemDetail from "../pages/brandItems/ItemDetail.js"
import NavFooterOutlet from "./NavFooterOutlet"
// import MyPageNavOutlet from './MyPageNavOutlet'
import SignUpRouter from "../pages/user/SignUpRouter"
import ScrollToTop from "./ScrollToTop.js"
import Notice from "../pages/notice/Notice"
import NoticeDetail from "../pages/notice/NoticeDetail"
import Termandpolicy from "../pages/termandpolicy/Termandpolicy"
import BrandItemRouter from "../pages/brandItems/BrandItemRouter"
import ItemApply from "../pages/brandItems/ItemApply"
import MyPageRouter from "../pages/myPage/router/MyPageRouter"
// import MyPageRouter from '../pages/myPage/router/MyPageRouter';
import { useUserStore } from "../zustand/store"
import { UserTypes } from "../types/users/UserTypes"
import NotFound from "./NotFound"
import Utils from "../utils/Utils"
import ItemDetailPreivew from "../pages/brandItems/ItemDetailPreview"
import AdItemDetailPreview from "../pages/adItems/ItemDetailPreview"
import ItemSearch from "../pages/brandItems/ItemSearch"
import AgreementSignup from "../pages/agreement/AgreementSignup"
import AgreementSignupMarketer from "../pages/agreement/AgreementSignupMarketer"
import KakaoAuth from "../components/auth/KakaoAuth"
import ItemApplyPage from "../pages/brandItems/ItemApplyPage.js"
import AdItemRouter from "../pages/adItems/AdItemRouter.js"

const MainRouter = () => {
    const { userType } = useUserStore((state) => state)
    // const [isTop,setIsTop] = useState(false)

    return (
        <React.Fragment>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<NavFooterOutlet />}>
                    <Route index element={<Home />} />
                    {/* <Route path="test" element={<Test />} /> */}
                    <Route path="itemsearch" element={<ItemSearch />} />
                    <Route path="items/*" element={<BrandItemRouter />} />
                    <Route path="ads/*" element={<AdItemRouter />} />
                    {/* {userType === UserTypes.NONE && */}
                    <Route path="signup/*" element={<SignUpRouter />} />
                    {/* } */}
                    {/* <Route path="items/:itemID" element={<ItemDetail/>} /> */}
                    <Route path="notice" element={<Notice />} />
                    <Route path="notice/:itemID" element={<NoticeDetail />} />
                    <Route path="termandpolicy" element={<Termandpolicy />} />
                    {!Utils.isNullOrUndefined(userType) && userType === UserTypes.MARKETER && (
                        <Route path="apply/:itemID" element={<ItemApplyPage />} />
                    )}
                </Route>
                <Route path="/oauth/kakao/callback" element={<KakaoAuth />}></Route>
                <Route path="/agreement/submit/:item_marketer_code" element={<AgreementSignup />}></Route>
                <Route path="/agreement/submit/:item_marketer_code/:agreement_id" element={<AgreementSignup />}></Route>
                <Route
                    path="/agreement/submitmarketer/:item_marketer_code"
                    element={<AgreementSignupMarketer />}
                ></Route>

                <Route path="mypage/*" element={<MyPageRouter />} />
                <Route path="/item/preview" element={<ItemDetailPreivew />} />
                <Route path="/ads/preview" element={<AdItemDetailPreview />} />
                <Route path="/.well-known/acme-challenge/*" element={<ItemDetailPreivew />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {/* <Routes>
            </Routes> */}
        </React.Fragment>
    )
}

export default MainRouter
