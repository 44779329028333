import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import {
    Container,
    HomeIcon,
    Layout,
    NavButton,
    NavButtonDiv,
    NavButtonTitle,
    NavIconContainer,
} from "./MobileMyPageItemBottomStyle"
import { PmsOfficeNavState, PmsNavState } from "../../../types/DataEnum"
import { UserTypes } from "../../../types/users/UserTypes"

const ITEM_SELECTED_TO_LINK_BRAND = [
    {
        title: "실적관리",
        toLink: "",
        navState: PmsOfficeNavState.PERFORM,
    },
    {
        title: "정산관리",
        toLink: "cal/",
        navState: PmsOfficeNavState.CAL,
    },
    {
        title: "마케터관리",
        toLink: "marketer/",
        navState: PmsOfficeNavState.MARKETER,
    },
    {
        title: "공지사항",
        toLink: "notice/",
        navState: PmsOfficeNavState.NOTICE,
    },
]
const ITEM_SELECTED_TO_LINK_MARKETER = [
    {
        title: "실적관리",
        toLink: "",
        navState: PmsOfficeNavState.PERFORM,
    },
    // {
    //     title: '정산관리',
    //     toLink: 'cal/',
    //     navState:PmsOfficeNavState.CAL
    // },
    {
        title: "그룹 관리",
        toLink: "group/",
        navState: PmsOfficeNavState.GROUP,
    },
    {
        title: "공지사항",
        toLink: "notice/",
        navState: PmsOfficeNavState.NOTICE,
    },
]

const NavButtonContainer = ({ navState, officeNavState, itemID, selectedItem, title, toLink }) => {
    return (
        <NavButton
            onClick={(e) => {
                itemID === undefined ? alert("아이템을 선택해 주세요") : ""
            }}
            to={itemID === undefined ? "" : `${toLink}${selectedItem.id}`}
        >
            <NavButtonTitle navstate={officeNavState === navState && itemID !== undefined}>{title}</NavButtonTitle>
        </NavButton>
    )
}

export function MobileMyPageItemBottom({ userType, officeNavState, selectedItem, itemID }) {
    return (
        <Container>
            <Layout>
                {userType === UserTypes.MARKETER
                    ? ITEM_SELECTED_TO_LINK_MARKETER.map((data, idx) => (
                          <NavButtonContainer
                              navState={data.navState}
                              officeNavState={officeNavState}
                              title={data.title}
                              toLink={data.toLink}
                              itemID={itemID}
                              selectedItem={selectedItem}
                              key={idx}
                          />
                      ))
                    : ITEM_SELECTED_TO_LINK_BRAND.map((data, idx) => (
                          <NavButtonContainer
                              navState={data.navState}
                              officeNavState={officeNavState}
                              title={data.title}
                              toLink={data.toLink}
                              itemID={itemID}
                              selectedItem={selectedItem}
                              key={idx}
                          />
                      ))}
            </Layout>
        </Container>
    )
}
