import React, { useEffect, useState } from "react";
import { BrandItemInput, BrandItemTextArea } from "../../../components/brandItems/Inputs";
import { PerformValueInputTitle } from "../perform/MyPagePerformStyle";
import { CalcEditLayout } from "./MyPageCalcStyle";
import Utils from "../../../utils/Utils";


export default function MyPageCalcGroupAdd({ handleAddCalcGroup,isMobile=false }) {
    const [name, setName] = useState("")

    const handelAdd = () => {
        if (Utils.isStringNullOrEmpty(name)) {
            alert('정산그룹 이름을 입력 해주시기 바랍니다.')
        } else {
            handleAddCalcGroup(name)
        }
    }
    
    const handleEnter = (e) => {
        if (e.key == "Enter") {
            handleAddCalcGroup(name)
        }
    }

    return (
        <CalcEditLayout isMobile={isMobile}>
            <PerformValueInputTitle>
                <span>
                    정산 그룹 추가
                </span>
            </PerformValueInputTitle>
            <div className="lg:grid max-lg:mt-4 lg:grid-cols-1 lg:gap-4 lg:py-4">
                <BrandItemInput
                    type="text"
                    label={"이름"}
                    data={name}
                    setData={setName}
                    onKeyPress={handleEnter}
                    widthFull
                />
            </div>
            <div className="w-full flex-1">

            </div>

            <button className="max-lg:mb-2mlg:mt-4 px-4 max-lg:py-4 lg:py-2 bg-nightblue-500 text-white rounded-md"
                onClick={handelAdd}
            >
                저장
            </button>
        </CalcEditLayout>
    )
}