import React, { useEffect, useRef } from "react"
import tw from "tailwind-styled-components"
import { ItemCardType } from "../../types/DataEnum"
import { Link } from "react-router-dom"
import {
    AdCategoryTypeNames,
    ItemSalesTypeNames,
    MarketerTypeNames,
    MarketerTypes,
} from "../../types/brandItems/BrandItemTypes"
import { useState } from "react"
import { getImageFile } from "../../apis/api/files/index"
import { ImageContainer } from "../ImageContainer"

import { Like } from "@styled-icons/foundation/Like"
import { Buildings } from "@styled-icons/boxicons-regular/Buildings"
import { Reward } from "@styled-icons/fluentui-system-regular/Reward"
import ImageFile from "../../types/files/ImageFile"
import FileUtils from "../../utils/FileUtils"
import Utils from "../../utils/Utils"
import { MoneyDollarBox } from "@styled-icons/remix-fill/MoneyDollarBox"
import { DollarCircle } from "@styled-icons/boxicons-regular/DollarCircle"
import { useImageLoaded } from "../../hooks/useImageLoaded"
import { Receipt } from "@styled-icons/boxicons-regular/Receipt"
import { useMouseHover } from "../../hooks/useMouseHover"

const ItemCardCon = tw.div`
    group cursor-pointer 
    relative
    flex w-full h-32 max-sm:px-2 max-sm:py-2 sm:px-2 sm:py-3
    bg-transparent hover:bg-slate-50
    rounded-lg border border-slate-200
    duration-300
`
const MainItemCardCon = tw(ItemCardCon)`
    flex-col w-full h-auto aspect-[3/2] max-sm:px-0 max-sm:py-0 sm:px-0 sm:py-0 overflow-hidden
`

const BackgroundThumbnailBox = tw.div`
    absolute z-0 w-full h-full
`
const ItemCardImgCon = tw.div`
    relative max-sm:hidden
    min-w-[165px] h-full aspect-[3/2] bg-slate-300
    rounded-lg group-hover:drop-shadow-1.5xl
    duration-300 overflow-hidden
`

const ItemCardContentCol = tw.div`
    flex flex-col w-full max-h-full md:px-1 z-10
`
const MainItemCardContentCol = tw(ItemCardContentCol)`
    absolute z-10
    ${(props) => (props.isShow ? "opacity-100" : "opacity-0")};
    w-full h-full max-sm:p-1 sm:p-3
    bg-white/60 backdrop-blur
    duration-200
`
const ItemCardTypeRow = tw.div`
    flex max-md:justify-normal md:justify-between items-center text-sm md:py-2
`
const ItemCardMarketerType = tw.span`
    text-white flex justify-center rounded-sm text-[0.625rem] px-2 font-bold
    ${(props) =>
        props.marketerType === MarketerTypes.PRO ? "bg-sky-100 text-sky-600" : "bg-orange-100 text-orange-600"}
`
const ItemCardSalesType = tw.span`
    font-semibold w-12 flex justify-center text-xs text-slate-600
`

const ItemCardTitleCon = tw.div`
    w-full max-md:h-[3.2rem] md:h-[3.2rem] flex max-md:mt-1
`
const ItemCardTitle = tw.p`
    line-clamp-2 w-full !leading-5
    max-md:text-base md:text-lg
    font-semibold
`
const ItemCardBrandName = tw.p`
    w-full
    text-slate-600 max-sm:text-xs sm:text-sm
`
const ItemCardAttribute = tw.p`
    px-2
    max-md:text-xs md:text-[14px]
    font-light text-slate-600
`
//${props => props.itemType === ItemCardType.ITEM ? 'text-base' : "text-base"};
const ItemCardSubInfoContainer = tw.div`
    flex justify- items-center
`
const ItemCardSubInfoBox = tw.div`
    flex items-center w-full
    [&>span]:text-medium [&>span]:text-gray-600
`

const ItemCardCompensation = tw.div`
    flex items-center w-full justify-between pl-1
    truncate py-1
    text-gray-800
    ${(props) => (props.itemType === ItemCardType.ITEM ? "text-sm font-semibold" : "text-base font-semibold")};
`

const Thumbnail = tw.img`
    w-full h-full
    object-cover bg-center
`

const ItemDetailIconContainer = tw.div`
    flex flex-center flex-shrink-0 h-full max-md:py-1.5 md:py-1 text-gray-600 mr-1
`
const FavIcon = tw(Like)`
    h-5 text-gray-600 mr-1 pb-px
`
const CompanyIcon = tw(Buildings)`
    h-full 
`
const RewardIcon = tw(Reward)`
    h-full
`

const MoneyIcon = tw(Receipt)`
    h-full
`

const RewardContainer = tw.div`
    w-full flex h-8 max-md:justify-start md:justify-end
`
const RewardTextContainer = tw.div`
    flex items-center h-full
    [&>span]:line-clamp-1 [&>span]:font-light
    text-sm max-md:text-right
    text-slate-800
`

export const AdItemCard = ({ itemType, item, isLoading, classStyle = "", testStyle = false }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) && item.detail.thumbnailImageID >= 0) {
            getImageFile(item.detail.thumbnailImageID).then((response) => {
                setImageSource(response.url)
            })
        } else if (!Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setImageSource(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleLink = (e) => {
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailLink)) {
            return
        } else {
            window.open(item.detail.thumbnailLink, "_blank", "noopener,noreferrer")
            e.preventDefault()
        }

        // 로딩 중에는 넘어갈 수 없다.
        if (isLoading) {
            e.preventDefault()
        }
    }

    return (
        <Link to={`/ads/${item.id}`} onClick={handleLink} className={classStyle}>
            <ItemCardCon itemType={itemType} testStyle={testStyle}>
                <ItemCardContentCol className="max-md:px-1 md:px-1">
                    {/* 모바일에선 첫번째 블럭과 두번째 블럭의 위치가 바뀐다. */}
                    {/* 두번째 블럭(제목, 보상메세지) */}
                    <div className="flex flex-col h-full">
                        <ItemCardTitleCon>
                            {isLoading ? (
                                <div className="skeleton w-4/5 h-[1.5rem] mb-2">loading...</div>
                            ) : (
                                <ItemCardTitle itemType={itemType}>{item.detail.title}</ItemCardTitle>
                            )}
                        </ItemCardTitleCon>
                        <ItemCardSubInfoContainer className="flex !mt-auto text-sm">
                            {isLoading ? (
                                <div className="skeleton w-36 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <CompanyIcon className="h-4 mr-1" />
                                    <ItemCardBrandName itemType={itemType}>
                                        {item.detail.adCompanyName}
                                    </ItemCardBrandName>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                        <ItemCardSubInfoContainer className="flex items-center divide-x divide-slate-200">
                            {isLoading ? (
                                <div className="skeleton w-56 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <ItemCardAttribute itemType={itemType} className="pl-0">
                                        {AdCategoryTypeNames[item.detail.adCategory]}
                                    </ItemCardAttribute>
                                    <ItemCardAttribute itemType={itemType}>
                                        {item.detail.sigungu.title()}
                                    </ItemCardAttribute>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>

                        <ItemCardSubInfoContainer className="">
                            <ItemCardSubInfoBox>
                                {isLoading ? (
                                    <div className="skeleton w-40 h-5 max-md:mb-1">loading...</div>
                                ) : (
                                    <RewardTextContainer>
                                        <span>{item.detail.adDescription}</span>
                                    </RewardTextContainer>
                                )}
                            </ItemCardSubInfoBox>
                        </ItemCardSubInfoContainer>
                    </div>
                </ItemCardContentCol>
                <ItemCardImgCon>
                    <ImageContainer loaded={!isLoading && imageLoaded}>
                        <Thumbnail src={imageSource} alt={null} ref={imageRef} onLoad={imageSetLoad} />
                    </ImageContainer>
                </ItemCardImgCon>
            </ItemCardCon>
        </Link>
    )
}

export const AdLevel3Card = ({ item, isLoading, classStyle = "", testStyle = false }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) && item.detail.thumbnailImageID >= 0) {
            getImageFile(item.detail.thumbnailImageID).then((response) => {
                setImageSource(response.url)
            })
        } else if (!Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setImageSource(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleLink = (e) => {
        // 로딩 중에는 넘어갈 수 없다.
        if (isLoading) {
            e.preventDefault()
        }
    }

    return (
        <Link to={`/ads/${item.id}`} onClick={handleLink} className={classStyle}>
            <ItemCardCon className="flex-col h-auto" backgroundImage={imageSource}>
                <ItemCardImgCon className="flex flex-center w-full h-24 bg-transparent border-0">
                    <div className="h-full aspect-[3/2]">
                        <ImageContainer loaded={!isLoading && imageLoaded}>
                            <Thumbnail src={imageSource} alt={null} ref={imageRef} onLoad={imageSetLoad} />
                        </ImageContainer>
                    </div>
                </ItemCardImgCon>
                <ItemCardContentCol>
                    {/* 모바일에선 첫번째 블럭과 두번째 블럭의 위치가 바뀐다. */}
                    {/* 두번째 블럭(제목, 보상메세지) */}
                    <div className="flex flex-col h-full space-y-2">
                        <ItemCardTitleCon>
                            {isLoading ? (
                                <div className="skeleton w-4/5 h-[1.5rem] mb-2">loading...</div>
                            ) : (
                                <ItemCardTitle>{item.detail.title}</ItemCardTitle>
                            )}
                        </ItemCardTitleCon>
                        <ItemCardSubInfoContainer className="!mt-auto">
                            <ItemCardSubInfoBox>
                                {isLoading ? (
                                    <div className="skeleton w-40 h-5 max-md:mb-1">loading...</div>
                                ) : (
                                    <RewardTextContainer>
                                        <span>{item.detail.adDescription}</span>
                                    </RewardTextContainer>
                                )}
                            </ItemCardSubInfoBox>
                        </ItemCardSubInfoContainer>
                        <ItemCardSubInfoContainer className="flex">
                            {isLoading ? (
                                <div className="skeleton w-36 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <CompanyIcon className="h-4 mr-1" />
                                    <ItemCardBrandName>{item.adCompanyName}</ItemCardBrandName>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                        <ItemCardSubInfoContainer className="flex items-center divide-x divide-slate-200">
                            {isLoading ? (
                                <div className="skeleton w-56 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <ItemCardAttribute className="pl-0">
                                        {AdCategoryTypeNames[item.detail.adCategory]}
                                    </ItemCardAttribute>
                                    <ItemCardAttribute>{item.detail.sigungu.title()}</ItemCardAttribute>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                    </div>
                </ItemCardContentCol>
            </ItemCardCon>
        </Link>
    )
}

export const AdLevel2Card = ({ item, isLoading, classStyle = "", testStyle = false }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()
    const [hoverRef, isHover] = useMouseHover()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) && item.detail.thumbnailImageID >= 0) {
            getImageFile(item.detail.thumbnailImageID).then((response) => {
                setImageSource(response.url)
            })
        } else if (!Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setImageSource(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleLink = (e) => {
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailLink)) {
            return
        } else {
            window.open(item.detail.thumbnailLink, "_blank", "noopener,noreferrer")
            e.preventDefault()
        }

        // 로딩 중에는 넘어갈 수 없다.
        if (isLoading) {
            e.preventDefault()
        }
    }

    return (
        <Link to={`/ads/${item.id}`} onClick={handleLink} className={classStyle}>
            <MainItemCardCon ref={hoverRef}>
                <BackgroundThumbnailBox>
                    <ImageContainer loaded={!isLoading && imageLoaded}>
                        <Thumbnail src={imageSource} alt={null} ref={imageRef} onLoad={imageSetLoad} />
                    </ImageContainer>
                </BackgroundThumbnailBox>
                <MainItemCardContentCol isShow={isHover}>
                    {/* 모바일에선 첫번째 블럭과 두번째 블럭의 위치가 바뀐다. */}
                    {/* 두번째 블럭(제목, 보상메세지) */}
                    <div className="flex flex-col h-full space-y-1 px-1">
                        <ItemCardSubInfoContainer className="flex">
                            {isLoading ? (
                                <div className="skeleton w-36 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <CompanyIcon className="h-4 mr-1" />
                                    <ItemCardBrandName>{item.detail.adCompanyName}</ItemCardBrandName>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                        <ItemCardTitleCon>
                            {isLoading ? (
                                <div className="skeleton w-4/5 h-[1.5rem] mb-auto">loading...</div>
                            ) : (
                                <ItemCardTitle className="h-[2.4rem]">{item.detail.title}</ItemCardTitle>
                            )}
                        </ItemCardTitleCon>
                        <ItemCardSubInfoContainer className="!mt-auto">
                            <ItemCardSubInfoBox>
                                {isLoading ? (
                                    <div className="skeleton w-40 h-5 max-md:mb-1">loading...</div>
                                ) : (
                                    <RewardTextContainer>
                                        <span>{item.detail.adDescription}</span>
                                    </RewardTextContainer>
                                )}
                            </ItemCardSubInfoBox>
                        </ItemCardSubInfoContainer>
                        <ItemCardSubInfoContainer className="hidden items-center divide-x divide-slate-200">
                            {isLoading ? (
                                <div className="skeleton w-56 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <ItemCardAttribute className="pl-0">
                                        {AdCategoryTypeNames[item.detail.adCategory]}
                                    </ItemCardAttribute>
                                    <ItemCardAttribute>{item.detail.sigungu.title()}</ItemCardAttribute>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                    </div>
                </MainItemCardContentCol>
            </MainItemCardCon>
        </Link>
    )
}

export const AdLevel1Card = ({ item, isLoading, classStyle = "", testStyle = false }) => {
    const [imageSource, setImageSource] = useState("")
    const [imageRef, imageLoaded, imageSetLoad] = useImageLoaded()
    const [hoverRef, isHover] = useMouseHover()

    useEffect(() => {
        setImageSource("")
        imageSetLoad(false)
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) && item.detail.thumbnailImageID >= 0) {
            getImageFile(item.detail.thumbnailImageID).then((response) => {
                setImageSource(response.url)
            })
        } else if (!Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL)) {
            setImageSource(FileUtils.getMediaFileURL(item.detail.thumbnailImageURL))
        }
    }, [item])

    const handleLink = (e) => {
        if (Utils.isStringNullOrEmpty(item.detail.thumbnailLink)) {
            return
        } else {
            window.open(item.detail.thumbnailLink, "_blank", "noopener,noreferrer")
            e.preventDefault()
        }

        // 로딩 중에는 넘어갈 수 없다.
        if (isLoading) {
            e.preventDefault()
        }
    }

    return (
        <Link to={`/ads/${item.id}`} onClick={handleLink} className={classStyle}>
            <ItemCardCon ref={hoverRef} className="bg-nightblue-10">
                <ItemCardContentCol>
                    {/* 모바일에선 첫번째 블럭과 두번째 블럭의 위치가 바뀐다. */}
                    {/* 두번째 블럭(제목, 보상메세지) */}
                    <div className="flex flex-col h-full space-y-1">
                        <ItemCardSubInfoContainer className="flex">
                            {isLoading ? (
                                <div className="skeleton w-36 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <CompanyIcon className="h-4 mr-1" />
                                    <ItemCardBrandName>{item.detail.adCompanyName}</ItemCardBrandName>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                        <ItemCardTitleCon>
                            {isLoading ? (
                                <div className="skeleton w-4/5 h-[2.4rem] mb-auto">loading...</div>
                            ) : (
                                <ItemCardTitle className="h-[2.4rem] max-md:text-base md:text-base font-medium">
                                    {item.detail.title}
                                </ItemCardTitle>
                            )}
                        </ItemCardTitleCon>
                        <ItemCardSubInfoContainer className="!mt-auto hidden">
                            <ItemCardSubInfoBox>
                                {isLoading ? (
                                    <div className="skeleton w-40 h-5 max-md:mb-1">loading...</div>
                                ) : (
                                    <RewardTextContainer>
                                        <span>{item.detail.adDescription}</span>
                                    </RewardTextContainer>
                                )}
                            </ItemCardSubInfoBox>
                        </ItemCardSubInfoContainer>
                        <ItemCardSubInfoContainer className="flex items-center !mt-auto divide-x divide-slate-200">
                            {isLoading ? (
                                <div className="skeleton w-56 h-[1rem] text-transparent">loading...</div>
                            ) : (
                                <React.Fragment>
                                    <ItemCardAttribute className="pl-0">
                                        {AdCategoryTypeNames[item.detail.adCategory]}
                                    </ItemCardAttribute>
                                    <ItemCardAttribute>{item.detail.sigungu.sido}</ItemCardAttribute>
                                </React.Fragment>
                            )}
                        </ItemCardSubInfoContainer>
                    </div>
                </ItemCardContentCol>
            </ItemCardCon>
        </Link>
    )
}
