import React, { useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { Outlet, useLocation } from "react-router-dom"
import { Link, useParams } from "react-router-dom"
import { PmsOfficeNavState, PmsNavState } from "../../../types/DataEnum"
import {
    getBrandItemListAuthSimple,
    getBrandItemTempView,
    getBrandItemView,
    getItemMarketerByUser,
    getItemMarketerInviteds,
} from "../../../apis/api/boardItems"
import BrandItemList from "../../../types/brandItems/BrandItemList"
import { ImageBase64 } from "../../../components/ImageContainer"
import BrandItem from "../../../types/brandItems/BrandItem"
import AccessRules from "../../../rules/AccessRules"
import { useInView } from "react-intersection-observer"
import Utils from "../../../utils/Utils"
import FileUtils from "../../../utils/FileUtils"
import { Box } from "@styled-icons/bootstrap/Box"
import {
    BoxIcon,
    BoxIconContainer,
    BrandItemThumbBox,
    PerformItemCardContainer,
    PerformItemsListSelectContainer,
    PerformItemsMoreIcon,
    PerformNavButton,
    PerformNavContainer,
    PerformNavLinkContainer,
    PerformNowSelectedContainer,
    PerformSelectContainer,
} from "./MyPageNavOutletStyle"
import { MobileMyPageItemBottom } from "./MobileMyPageItemBottom"
import { MobileModal } from "../../../components/MobileModal"
import { MyPageLayout } from "../MyPageMainStyle"
import { UserTypes } from "../../../types/users/UserTypes"
import { MarketerStatusTypes, MarketerSuspendStatusTypes } from "../../../types/brandItems/BrandItemTypes"
import { useUserStore } from "../../../zustand/store"
import { InvitedCountContainer } from "../marketer/MyPageMarketerStyle"

export function MyPagePerformNavContents({
    userType,
    officeNavState,
    setOfficeNavState,
    setNavigationFolded,
    selectedItem,
    setSelectedItem,
    setPmsNavState,
}) {
    const { user, marketerWaitCount, groupInviteCount, actions } = useUserStore()

    const { itemID } = useParams()
    const [scrollRef, scrollInView] = useInView()
    const [items, setItems] = useState([])
    const [itemLoadPage, setitemLoadPage] = useState(1)
    const [itemLoadPageSize, setItemLoadSize] = useState(10)
    const [endPage, setEndPage] = useState(false)
    const [mobileModalOpen, setMobileModalOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const pathname = location.pathname
        const regex = /\/\d+$/
        if (regex.exec(pathname) === null) {
            setMobileModalOpen(true)
        }
    }, [])

    useEffect(() => {
        if (scrollInView === true && endPage !== true) {
            setitemLoadPage(itemLoadPage + 1)
        }
    }, [scrollInView])

    useEffect(() => {
        // updateBrandItemList()
        // if(officeNavState===PmsOfficeNavState.NONE){
        //     setOfficeNavState(PmsOfficeNavState.PERFORM)
        // }
        setPmsNavState(PmsNavState.PERFORM)
        // setNavigationFolded(false)
        return () => {
            setPmsNavState(PmsNavState.NONE)
            setOfficeNavState(PmsOfficeNavState.NONE)
        }
    }, [])

    useEffect(() => {
        if (itemID != selectedItem.id) {
            getSelectedItemData(itemID)
        }
        actions.updateGroupInviteCount(itemID, user.id)
        actions.updateMarketerWaitCount(itemID)
    }, [itemID])

    useEffect(() => {
        updateBrandItemList()
    }, [itemLoadPage, itemLoadPageSize])

    const getSelectedItemData = async (_itemID) => {
        let item = new BrandItem()
        if (_itemID >= 0) {
            await getBrandItemView(_itemID).then((response) => {
                item.convertByResponse(response)
            })
            // 정보가 없는 경우 이제 추가한 아이템이라고 보고 임시 데이터를 가져온다.
            if (Utils.isStringNullOrEmpty(item.detail.title)) {
                await getBrandItemTempView(_itemID).then((response) => {
                    item.convertByResponse(response)
                })
            }
        }
        setSelectedItem(item)
    }

    const updateBrandItemList = () => {
        // 리스트 업데이트 시 입력중인 키워드를 검색에 사용할 키워드로 돌려놓는다.
        getBrandItemListAuthSimple(itemLoadPage, itemLoadPageSize)
            .then((response) => {
                const brandItemList = new BrandItemList()
                brandItemList.convertByResponse(response)
                if (items.length !== 0) {
                    var newItems = items
                    brandItemList.items.map((data) => {
                        newItems.push(data)
                    })
                    setItems([...newItems])
                } else {
                    setItems(brandItemList.items)
                }
            })
            .catch((error) => {
                setEndPage(true)
                setItems(items)
            })
    }

    const itemSelectedToLink = (itemId) => {
        if (officeNavState === PmsOfficeNavState.PERFORM) {
            return `${itemId}`
        } else if (officeNavState === PmsOfficeNavState.PERFORM_COLUMN) {
            return `column/${itemId}`
        } else if (officeNavState === PmsOfficeNavState.CAL) {
            return `cal/${itemId}`
        } else if (officeNavState === PmsOfficeNavState.MARKETER) {
            return `marketer/${itemId}`
        } else if (officeNavState === PmsOfficeNavState.NOTICE) {
            return `notice/${itemId}`
        } else if (officeNavState === PmsOfficeNavState.NONE) {
            return `${itemId}`
        }
    }

    const handleOpenMobileItemSelect = () => {
        setMobileModalOpen(true)
    }

    return (
        <React.Fragment>
            <PerformNavContainer>
                {/* 아이템 선택 */}
                <PerformSelectContainer className="max-lg:hidden lg:flex">
                    {/* 현재 선택된 아이템 */}
                    <PerformNowSelectedContainer>
                        <BrandItemThumbBox>
                            {selectedItem.id >= 0 ? (
                                <ImageBase64 imageID={selectedItem.detail.thumbnailImageID} />
                            ) : (
                                <BoxIconContainer>
                                    <BoxIcon />
                                </BoxIconContainer>
                            )}
                        </BrandItemThumbBox>
                        <span className="text-slate-700">
                            {selectedItem.id >= 0 ? selectedItem.detail.title : "상품을 선택해 주세요"}
                        </span>
                    </PerformNowSelectedContainer>
                    <PerformItemsListSelectContainer>
                        {items.map((item, idx) => (
                            <Link key={idx} to={"/mypage/perform/" + itemSelectedToLink(item.id)}>
                                <PerformItemCardContainer ref={idx === items.length - 1 ? scrollRef : null}>
                                    <BrandItemThumbBox className="h-full aspect-square">
                                        <ImageBase64
                                            imageURL={
                                                !Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) &&
                                                FileUtils.getMediaFileURL(item.detail.thumbnailImageURL)
                                            }
                                        />
                                    </BrandItemThumbBox>
                                    <span className="">{item.detail.title}</span>
                                </PerformItemCardContainer>
                            </Link>
                        ))}
                    </PerformItemsListSelectContainer>
                    <div className="flex items-center h-full">
                        <PerformItemsMoreIcon />
                    </div>
                </PerformSelectContainer>

                <PerformSelectContainer className="max-lg:flex lg:hidden" onClick={handleOpenMobileItemSelect}>
                    {/* 현재 선택된 아이템 */}
                    <PerformNowSelectedContainer>
                        <BrandItemThumbBox>
                            {selectedItem.id >= 0 ? (
                                <ImageBase64 imageID={selectedItem.detail.thumbnailImageID} />
                            ) : (
                                <BoxIconContainer>
                                    <BoxIcon />
                                </BoxIconContainer>
                            )}
                        </BrandItemThumbBox>
                        <span className="w-full truncate">
                            {selectedItem.id >= 0 ? selectedItem.detail.title : "상품을 선택해 주세요"}
                        </span>
                    </PerformNowSelectedContainer>
                    <div className="flex items-center h-full">
                        <PerformItemsMoreIcon />
                    </div>
                </PerformSelectContainer>

                {/* 네비게이션 탭 */}
                <PerformNavLinkContainer>
                    <Link
                        to={itemID === undefined ? "" : `${selectedItem.id}`}
                        onClick={(e) => {
                            itemID === undefined ? alert("상품을 선택해 주세요") : ""
                        }}
                        className="h-full"
                    >
                        <PerformNavButton
                            navstate={officeNavState === PmsOfficeNavState.PERFORM && itemID !== undefined}
                        >
                            실적 관리
                        </PerformNavButton>
                    </Link>
                    {AccessRules.staff() && (
                        <Link
                            to={itemID === undefined ? "" : `column/${selectedItem.id}`}
                            onClick={(e) => {
                                itemID === undefined ? alert("상품을 선택해 주세요") : ""
                            }}
                            className="h-full"
                        >
                            <PerformNavButton
                                navstate={officeNavState === PmsOfficeNavState.PERFORM_COLUMN && itemID !== undefined}
                            >
                                실적 컬럼 관리
                            </PerformNavButton>
                        </Link>
                    )}
                    {AccessRules.brandItemManagement() && (
                        <Link
                            to={itemID === undefined ? "" : `cal/${selectedItem.id}`}
                            onClick={(e) => {
                                itemID === undefined ? alert("상품을 선택해 주세요") : ""
                            }}
                            className="h-full"
                        >
                            <PerformNavButton
                                navstate={officeNavState === PmsOfficeNavState.CAL && itemID !== undefined}
                            >
                                정산 관리
                            </PerformNavButton>
                        </Link>
                    )}
                    {AccessRules.brandItemManagement() ? (
                        <Link
                            to={itemID === undefined ? "" : `marketer/${selectedItem.id}`}
                            onClick={(e) => {
                                itemID === undefined ? alert("상품을 선택해 주세요") : ""
                            }}
                            className="relative h-full"
                        >
                            <PerformNavButton
                                navstate={officeNavState === PmsOfficeNavState.MARKETER && itemID !== undefined}
                            >
                                마케터 관리
                            </PerformNavButton>
                            {marketerWaitCount > 0 && (
                                <InvitedCountContainer>{marketerWaitCount}</InvitedCountContainer>
                            )}
                        </Link>
                    ) : (
                        <Link
                            to={itemID === undefined ? "" : `group/${selectedItem.id}`}
                            onClick={(e) => {
                                itemID === undefined ? alert("상품을 선택해 주세요") : ""
                            }}
                            className="relative h-full"
                        >
                            <PerformNavButton
                                navstate={officeNavState === PmsOfficeNavState.GROUP && itemID !== undefined}
                            >
                                그룹 관리
                            </PerformNavButton>
                            {groupInviteCount > 0 && <InvitedCountContainer>{groupInviteCount}</InvitedCountContainer>}
                        </Link>
                    )}
                    <Link
                        to={itemID === undefined ? "" : `notice/${selectedItem.id}`}
                        onClick={(e) => {
                            itemID === undefined ? alert("상품을 선택해 주세요") : ""
                        }}
                        className="h-full"
                    >
                        <PerformNavButton
                            navstate={officeNavState === PmsOfficeNavState.NOTICE && itemID !== undefined}
                        >
                            공지사항
                        </PerformNavButton>
                    </Link>
                </PerformNavLinkContainer>
            </PerformNavContainer>
            <div className="max-lg:block lg:hidden">
                <MobileModal
                    isOpen={mobileModalOpen}
                    setIsOpen={setMobileModalOpen}
                    canBigger={false}
                    defaultHeight={100}
                >
                    {items.length > 0 ? (
                        <div className="w-full overflow-scroll p-3">
                            {items.map((item, idx) => (
                                <Link
                                    key={idx}
                                    onClick={() => {
                                        setMobileModalOpen(false)
                                    }}
                                    to={"/mypage/perform/" + itemSelectedToLink(item.id)}
                                >
                                    <PerformItemCardContainer ref={idx === items.length - 1 ? scrollRef : null}>
                                        <BrandItemThumbBox className="h-full aspect-square">
                                            <ImageBase64
                                                imageURL={
                                                    !Utils.isStringNullOrEmpty(item.detail.thumbnailImageURL) &&
                                                    FileUtils.getMediaFileURL(item.detail.thumbnailImageURL)
                                                }
                                            />
                                        </BrandItemThumbBox>
                                        <span className="text-sm">{item.detail.title}</span>
                                    </PerformItemCardContainer>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full h-full flex flex-center">
                            {userType === UserTypes.MARKETER
                                ? "영업 중인 상품이 없습니다!"
                                : "아직 추가된 영업 상품이 없습니다!"}
                        </div>
                    )}
                </MobileModal>
            </div>
            <MobileMyPageItemBottom
                userType={userType}
                officeNavState={officeNavState}
                selectedItem={selectedItem}
                itemID={itemID}
            />
        </React.Fragment>
    )
}

export default function MyPagePerformOutlet({
    user,
    userType,
    officeNavState,
    setOfficeNavState,
    setNavigationFolded,
    selectedItem,
    setSelectedItem,
    setPmsNavState,
}) {
    const [isWaitMarketer, setWaitMarketer] = useState(false)

    useEffect(() => {
        getMyItemMarketer()
    }, [selectedItem])

    // 자신의 아이템 마케터 정보를 가져온다.
    const getMyItemMarketer = async () => {
        if (userType !== UserTypes.MARKETER) {
            setWaitMarketer(false)
            return
        }

        const pagination = await getItemMarketerByUser(selectedItem.id, user.id)

        if (pagination.items === 0) {
            setWaitMarketer(true)

            return
        }

        const itemMarketer = pagination.items[0]

        if (
            itemMarketer.marketerStatusType === MarketerStatusTypes.WAIT ||
            itemMarketer.marketerStatusType === MarketerStatusTypes.BLOCK ||
            itemMarketer.marketerSuspendStatusType === MarketerSuspendStatusTypes.CONFIRM
        ) {
            setWaitMarketer(true)
        } else {
            setWaitMarketer(false)
        }
    }

    return (
        <MyPageLayout>
            <MyPagePerformNavContents
                userType={userType}
                officeNavState={officeNavState}
                setOfficeNavState={setOfficeNavState}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                setPmsNavState={setPmsNavState}
                setNavigationFolded={setNavigationFolded}
            />
            {isWaitMarketer ? (
                <div className="flex flex-center w-full lg:h-full max-lg:pb-14">
                    <div className="flex flex-col p-4 rounded-lg bg-slate-200">
                        <span>브랜드의 승인 후 이용 가능합니다.</span>
                    </div>
                </div>
            ) : (
                <div className="w-full lg:h-full max-lg:pb-14">
                    <Outlet />
                </div>
            )}
        </MyPageLayout>
    )
}
