import React, { useState, useEffect, useCallback } from "react"
import {
    LoginButton,
    SignupButton,
    LoginCol,
    LoginTitle,
    LoginOptionCol,
    LoginOptionItem,
    LoginOptionSpan,
    LoginRegistCol,
    LoginRegistSpan,
    LoginInputCol,
    LoginRegistBox,
    LoginContainer,
    LoginFindPassword,
    LoginImage,
    KakaoLogo,
    KakaoLoginButton,
    KakaoLogoContainer,
    LoginListKakaoButton,
    LoginRecentBox,
    LoginTypeBox,
    LoginTypeButton,
} from "./LoginStyle"
import { Link, useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import ConstCookie from "../../../constants/ConstCookie"
import Utils from "../../../utils/Utils"
import { useUserStore } from "../../../zustand/store"
import { getUserByLogin } from "../../../services/users"
import { UserInput } from "../Inputs"
import { LoginComponentTypes } from "./LoginMain"
import kakaoLoginImage from "../../../static/kakao_login.png"
import { UserTypes } from "../../../types/users/UserTypes"

// 레이아웃, 크기, 위치, 마진, 패딩,
// 텍스트(레이아웃, 사이즈, 무게, 색상(호버)),
// 색상(호버), 보더(호버), 곡률, 그림자
// 트랜지션, 가시성, 투명도,
// 오버플로우, 기타
const Login = ({ login, setShowComponent, setLoginComponentType }) => {
    const navigation = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [idRemember, setIDRemember] = useState(false)
    const [loginRecent, setLoginRecent] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies([ConstCookie.MARKETER_REMEMBERED_EMAIL])

    useEffect(() => {
        const rememberedEmail = cookies[ConstCookie.MARKETER_REMEMBERED_EMAIL]
        if (Utils.isNullOrUndefined(rememberedEmail)) {
            setIDRemember(true)
        } else if (rememberedEmail !== "") {
            setEmail(rememberedEmail)
            setIDRemember(true)
        }

        setLoginRecent(cookies[ConstCookie.MARKETER_LOGIN_RECENT])
    }, [])

    const handleIDRemember = () => {
        setIDRemember(!idRemember)
    }

    const saveIDRemember = () => {
        if (!idRemember) {
            setCookie(ConstCookie.MARKETER_REMEMBERED_EMAIL, "")
        } else {
            setCookie(ConstCookie.MARKETER_REMEMBERED_EMAIL, email)
        }
    }

    const handleLogin = async () => {
        const result = await login(email, password)

        if (result) {
            setCookie(ConstCookie.MARKETER_LOGIN_RECENT, "EMAIL")
            saveIDRemember()
            setShowComponent(false)
        }
    }

    const handleKeyPress = useCallback(
        (e) => {
            if (e.key == "Enter" && !Utils.isStringNullOrEmpty(email) && !Utils.isStringNullOrEmpty(password)) {
                handleLogin()
            }
        },
        [email, password],
    )

    const loginWithKakao = () => {
        setCookie(ConstCookie.MARKETER_LOGIN_RECENT, "KAKAO")
        const baseUrl = Utils.getBaseUrl()
        const kakaoRedirectUrl = `${baseUrl}/oauth/kakao/callback`
        window.Kakao.Auth.authorize({
            redirectUri: kakaoRedirectUrl,
            prompt: "select_account",
        })
    }

    return (
        <LoginContainer>
            <LoginCol>
                <LoginTitle>로그인</LoginTitle>

                <LoginTypeBox>
                    <LoginTypeButton $is_active={true}>일반 회원</LoginTypeButton>
                    <LoginTypeButton
                        onClick={() => {
                            setLoginComponentType(LoginComponentTypes.BRAND_LOGIN)
                        }}
                    >
                        기업 회원
                    </LoginTypeButton>
                </LoginTypeBox>

                <LoginInputCol>
                    <UserInput
                        placeholder={"이메일 아이디"}
                        type={"email"}
                        data={email}
                        setData={setEmail}
                        onKeyPress={handleKeyPress}
                        autoComplete={true}
                    />
                    <UserInput
                        placeholder={"비밀번호"}
                        type={"password"}
                        data={password}
                        setData={setPassword}
                        onKeyPress={handleKeyPress}
                        autoComplete={true}
                    />
                    <LoginOptionCol>
                        <LoginOptionItem
                            onClick={() => {
                                handleIDRemember()
                            }}
                        >
                            {/* 여기에도 onChange를 둬야 에러가 발생하지 않음. */}
                            <input
                                type="checkbox"
                                className="h-full aspect-square border rounded"
                                checked={idRemember}
                                onChange={() => {
                                    handleIDRemember()
                                }}
                            />
                            <LoginOptionSpan>아이디 저장</LoginOptionSpan>
                        </LoginOptionItem>
                        <div className="flex flex-col h-full justify-start">
                            <LoginFindPassword
                                onClick={() => {
                                    setLoginComponentType(LoginComponentTypes.FIND_EMAIL)
                                }}
                            >
                                이메일 찾기
                            </LoginFindPassword>
                            <LoginFindPassword
                                onClick={() => {
                                    setLoginComponentType(LoginComponentTypes.FIND_PASSWORD)
                                }}
                            >
                                비밀번호 찾기
                            </LoginFindPassword>
                        </div>
                    </LoginOptionCol>
                    <LoginButton
                        onClick={() => {
                            handleLogin()
                        }}
                    >
                        로그인
                        {loginRecent === "EMAIL" && <LoginRecentBox />}
                    </LoginButton>
                    {/* <KakaoLoginButton onClick={()=>{loginWithKakao()}}>
                        <KakaoLogoContainer>
                            <KakaoLogo />
                        </KakaoLogoContainer>
                        로그인
                    </KakaoLoginButton> */}
                    <KakaoLoginButton
                        className="mt-0"
                        onClick={() => {
                            loginWithKakao()
                        }}
                    >
                        <div className="h-full pb-3 pt-1 mb-1 mr-2">
                            <KakaoLogo />
                        </div>
                        카카오 간편 로그인
                        {loginRecent === "KAKAO" && <LoginRecentBox />}
                    </KakaoLoginButton>
                    {/* <button
                        className="p-4 pl-0 mr-auto text-slate-500 hover:text-slate-600"
                        onClick={() => {
                            setLoginComponentType(LoginComponentTypes.LIST)
                        }}
                    >
                        돌아가기
                    </button> */}
                </LoginInputCol>
            </LoginCol>
            <LoginRegistBox>
                <LoginRegistCol>
                    <Link to={"/signup"} state={{ userType: UserTypes.MARKETER }} className="w-full">
                        <SignupButton
                            onClick={() => {
                                setShowComponent(false)
                            }}
                        >
                            <LoginRegistSpan>처음이신가요?</LoginRegistSpan>
                            회원가입
                        </SignupButton>
                    </Link>
                </LoginRegistCol>
            </LoginRegistBox>
        </LoginContainer>
    )
}

export default Login
