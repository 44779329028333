import tw from 'tailwind-styled-components'

const Maxdiv = tw.div`
    max-w-[1200px]
`

export const TermLayout = tw.div`
    w-full flex-1 flex justify-center
`

export const TermContainer = tw(Maxdiv)`
    w-full max-md:px-2 md:px-12 mt-16
`
export const TermTitleContainer = tw.div`
    h-24 w-full flex justify-center items-center font-semibold
`   

export const TermTitle = tw.span`
    max-md:text-xl md:text-2xl
`

export const TermToggleButtonLayout = tw.div`
    w-full flex justify-center p-2
`

export const TermToggleButtonContainer = tw.div`
    flex w-96 h-10 relative
    bg-white rounded-2xl
    drop-shadow
    overflow-hidden
`

export const TermToggleButton = tw.div`
    absolute z-10
    flex items-center justify-center w-1/2 h-full
    cursor-pointer max-md:text-base md:text-md font-medium
    duration-300
    ${(props)=>props.isselected ? 'text-nightblue-20' : 'text-black' }
    select-none
`
export const TermToggleButtonActive = tw.div`
    absolute z-10
    flex items-center justify-center w-1/2 h-full
    cursor-pointer max-md:text-md md:text-lg font-medium
    ${(props)=>props.isselected ? 'bg-nightblue-400 border-nightblue-400 text-white' : 'bg-transparent text-black hover:bg-gray-50' }   
`

export const TermDetailContainer =tw.div`
    w-full h-auto bg-white my-10 rounded-lg overflow-hidden border
`

export const TermDetail = tw.div`
    w-full h-full p-4 overflow-x-scroll scroll-transparent text-sm
`

// ${(props)=>props.is_pnt_selected ? 'bg-slate-100' : 'bg-white' }