import React, { Dispatch, SetStateAction, useEffect } from "react"
import { createPortal } from "react-dom"
import Modal from "react-modal"
import tw from "tailwind-styled-components"
import { useWindowWidth } from "../hooks/useWindowWidth"
import { useWindowHeight } from "../hooks/useWindowHeight"

const Background = tw.div`
    flex flex-center w-full h-full
`

const preventGoBack = () => {
    history.pushState(null, "", location.href)
}

// export interface IModalContainer {
//     show: boolean
//     setShow: Dispatch<SetStateAction<boolean>>
//     isBlur: boolean
//     closeByBackground: boolean
//     hideCloseButton: boolean
//     disableESC: boolean
//     children: React.ReactNode
// }
const ModalContainerPortal = ({
    show,
    setShow,
    isBlur = false,
    closeByBackground = true,
    hideCloseButton = false,
    disableESC = false,
    mobileScreenHeight = 0,
    children,
}) => {
    const windowInnerWidth = useWindowWidth() // 모바일에서 최대사이즈를 이용하기 위해 windowInnerWidth를 이용한다.
    const windowInnerHeight = useWindowHeight() // 모바일에서 최대사이즈를 이용하기 위해 windowInnerHeight를 이용한다.
    const isFullScreen = mobileScreenHeight > 0 && windowInnerWidth < mobileScreenHeight // ScreenSize 사용 여부

    const handleClick = (e) => {
        if (closeByBackground) {
            setShow(false)
        }
    }

    const handleStopPropagation = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        if (show) {
            preventGoBack()
            window.addEventListener("popstate", preventGoBack)
        } else {
            window.removeEventListener("popstate", preventGoBack)
        }
    }, [show])

    return (
        <Modal
            isOpen={show}
            ariaHideApp={false}
            onRequestClose={() => {
                setShow(false)
            }}
            autoFocus={false}
            style={{
                overlay: {
                    backgroundColor: "rgb(0,0,0,0.5)",
                    zIndex: 500,
                    outline: 0,
                    backdropFilter: `blur(${isBlur ? 2 : 0}px)`,
                },
                outline: 0,
            }}
            className={`w-screen h-screen outline-zero focus:outline-zero ab`}
            shouldCloseOnEsc={!disableESC}
        >
            <div
                onClick={handleClick}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    onClick={handleStopPropagation}
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: isFullScreen ? `${windowInnerHeight}px` : "auto",
                    }}
                >
                    {((!closeByBackground && !hideCloseButton) || isFullScreen) && (
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 50,
                                top: "0.5rem",
                                right: "0.5rem",
                                padding: "0.5rem",
                                fontSize: "1.5rem",
                                display: "flex",
                                alignItems: "start",
                                cursor: "pointer",
                                color: "rgb(82, 91, 81)",
                                borderRadius: "0.5rem",
                            }}
                            onClick={() => {
                                setShow(false)
                            }}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </Modal>
    )
}

export default ModalContainerPortal
