import React,{useEffect,useState,useRef} from "react"
import tw from 'tailwind-styled-components'
import { PerformOptionValueBox } from "./MyPagePerformStyle"

import { OptionControlBox, OptionControlResetButton, OptionItemList } from "./PerformValueOptionStyle"
import { OptionItem } from "./PerformValueOptionCell"
import { Dictionary } from "../../../types/Dictionary"
import { QueryTypes } from "../../../types/EditorTypes"

export const PerformValueOptionUpdate = ({perform, performValue, readOnly}) => {
    const column = performValue.column

    const [optionItemDic, setOptionItemDic] = useState(new Dictionary()) // 체크된 항목 관리
    const [radioOptionDic, setRadioOptionDic] = useState(new Dictionary()) // 라디오 버튼인 항목은 하나만 체크되도록 관리

    useEffect(()=>{
        let _radioOptionDic = new Dictionary()

        column.options.map(option => {
            if (!option.isMultiSelect) {
                _radioOptionDic.push(option.id, option.id)
            }
        })

        setRadioOptionDic(_radioOptionDic)
    }, [column])

    useEffect(()=>{
        let _optionItemDic = new Dictionary()

        performValue.optionItems.map(optionItem => {
            _optionItemDic.push(optionItem.optionID, optionItem)
        })

        setOptionItemDic(_optionItemDic)
    }, [performValue])

    const updateOptionDic = (optionItem, queryType) => {
        let _optionItemDic = optionItemDic.copy()

        if (queryType === QueryTypes.CREATE_OR_UPDATE) {
            // 라디오 버튼인 경우 이미 체크된 다른 라디오버튼을 해제한다.
            if (radioOptionDic.contains(optionItem.optionID)) {
                _optionItemDic.getKeys().map(optionID => {
                    if (radioOptionDic.contains(optionID)) {
                        _optionItemDic.delete(optionID)
                    }
                })
            }

            _optionItemDic.push(optionItem.optionID, optionItem)
        } else if (queryType === QueryTypes.DELETE) {
            _optionItemDic.delete(optionItem.optionID)
        }

        setOptionItemDic(_optionItemDic)
        performValue.optionItems = _optionItemDic.getValues()
    }

    const handleReset = () => {
        setOptionItemDic(new Dictionary())
    }

    return (
        <PerformOptionValueBox>
            <OptionItemList>
                {column.options.map((option, idx) => 
                    <OptionItem option={option} optionItemDic={optionItemDic} updateOptionDic={updateOptionDic} readOnly={readOnly} key={idx} />
                )}
            </OptionItemList>
            <OptionControlBox>
                {!readOnly && (
                    <OptionControlResetButton onClick={()=>{handleReset()}}>
                        선택 초기화
                    </OptionControlResetButton>
                )}
            </OptionControlBox>
        </PerformOptionValueBox>
    )
}