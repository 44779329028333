import tw from "tailwind-styled-components";
import styled from "styled-components";

export const MyPageDetailMobileLayout = tw.div`
    flex flex-col w-full h-full p-3
    overflow-hidden
`

const MyPageDetailMobileSection = tw.div`
    w-full mb-3
`
export const MyPageDetailMobileTitleBox = tw(MyPageDetailMobileSection)`
    flex items-center h-16
`
export const MyPageDetailMobileThumbnailBox = tw.div`
    flex flex-center flex-shrink-0 h-full aspect-[3/2] bg-white
    rounded-md overflow-hidden
`
export const MyPageDetailMobileTitle = tw.p`
    flex w-full h-[2.6rem] ml-4
    line-clamp-2 leading-5
`
export const MyPageDetailMobileDataBox = tw(MyPageDetailMobileSection)`
    flex p-2
    rounded-md bg-slate-50 border
`
export const MyPageDetailMobileDataBlock = tw.div`
    grid grid-cols-1 gap-y-2 w-auto h-full
`
export const MyPageDetailMobileProcessBox = tw(MyPageDetailMobileSection)`
    grid grid-cols-2 gap-x-6 gap-y-2 p-2
    rounded-md bg-slate-150
`
export const MyPageDetailMobileNoticeBox = tw(MyPageDetailMobileSection)`
    flex flex-col w-full h-full
    border rounded-md
    overflow-hidden
`

const FirstMarginZero = styled.div`
    div {
        &:first-child {
            margin-left: 0px;
        }
    }
`
export const MyPageDetailMobileControlBox = tw(FirstMarginZero)`
    flex justify-between items-center flex-shrink-0 w-full h-10 first:mr-0
`
const MyPageDetailMobileControlButton = tw.div`
    flex flex-row flex-center w-full h-full ml-2
    rounded-md bg-gray-200
    text-white
    cursor-pointer
    [&>span]:text-sm
    select-none
`
export const MyPageDetailMobileControlButtonIconContainer = tw.div`
    flex flex-cente h-5 aspect-square mr-2
    [&>svg]:h-full [&>svg]:mb-0
    select-none
`

export const SettingBtn = tw(MyPageDetailMobileControlButton)`
    bg-nightblue-600
    ${(props) => props.isShow ? "flex" : "hidden"}
`

export const RegistCancleBtn = tw(MyPageDetailMobileControlButton)`
    flex bg-gray-200 text-red-700
`
export const SetContractBtn = tw(MyPageDetailMobileControlButton)`
    bg-gray-200 text-nightblue-700
`

export const LinkBtn = tw(MyPageDetailMobileControlButton)`
    bg-green-700
    ${(props) => props.isShow ? "flex" : "hidden"}
`