import Paginator from "../Paginator"
import Utils from "../../utils/Utils"
import Coupon from "./Coupon"
import UserBrand from "../users/UserBrand"
import UserMarketer from "../users/UserMarketer"
import { CouponApplicationStatusTypes, PointHistoryTypes } from "./PointTypes"

export default class {
    constructor() {
        this.id = -1
        this.uuid = ""
        this.couponID = -1
        this.coupon = new Coupon()
        this.brandID = -1
        this.brand = new UserBrand()
        this.marketerID = -1
        this.marketer = new UserMarketer()
        this.paymentPrice = 0
        this.applicationStatus = CouponApplicationStatusTypes.WAIT
        this.memo = ""

        this.orderID = -1

        this.created = ""
        this.updated = ""
        this.doneDateTime = ""
        this.refundedDateTime = ""
        this.couponRequestCount = 0
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.uuid = response["uuid"]
        this.couponID = response["coupon_id"]
        this.brandID = response["brand_id"]
        this.marketerID = response["marketer_id"]
        this.paymentPrice = response["payment_price"]
        this.applicationStatus = response["application_status"]
        this.memo = response["memo"]

        this.orderID = response["order_id"]

        this.created = response["created"]
        this.updated = response["updated"]
        this.doneDateTime = response["done_datetime"]
        this.refundedDateTime = response["refunded_datetime"]
        this.couponRequestCount = response["coupon_request_count"]

        const couponResponse = response["coupon"]
        const brandResponse = response["brand"]
        const marketerResponse = response["marketer"]

        if (!Utils.isNullOrUndefined(couponResponse)) {
            this.coupon = new Coupon()
            this.coupon.convertByResponse(couponResponse)
        }
        if (!Utils.isNullOrUndefined(brandResponse)) {
            this.brand = new UserBrand()
            this.brand.convertByResponse(brandResponse)
        }
        if (!Utils.isNullOrUndefined(marketerResponse)) {
            this.marketer = new UserMarketer()
            this.marketer.convertByResponse(marketerResponse)
        }
    }

    convertToRequest() {
        let request = {
            id: this.id,
            uuid: this.uuid,
            coupon_id: this.couponID,
            payment_price: this.paymentPrice,
            application_status: this.applicationStatus,
            memo: this.memo,
        }

        return request
    }
}
