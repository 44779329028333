import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getUserRecommanders } from "../../../../apis/api/users"
import {
    FilterInputCombo,
    FilterInputDate,
    FilterInputReset,
    FilterInputText,
} from "../../../../components/brandItems/FilterInputs"
import { Pagination } from "../../../../components/Pagination"
import { PmsNavState } from "../../../../types/DataEnum"
import { EditStateTypes, SortTypes } from "../../../../types/EditorTypes"
import { PaginationStyles } from "../../../../types/StyleTypes"
import { UserBrandOrderOptionNames, UserBrandOrderOptionTypes } from "../../../../types/users/UserOption"
import { MarketerSubControlBox, MarketerToggleButton } from "../../marketer/MyPageMarketerStyle"
import { ContentContainer, MyPageLayout } from "../../MyPageMainStyle"
import {
    PerformAddButton,
    PerformListControlBox,
    PerformListControlSection,
    PerformTableContainer,
    PerformTableSort,
    PerformTableSortTop,
} from "../../perform/MyPagePerformStyle"
import StaffUserBrandTable from "./StaffUserBrandTable"
import { StaffListControlBox, StaffListControlSection } from "./StaffUserStyle"
import StaffUserPointTable from "./StaffUserPointTable"
import Recommander from "../../../../types/users/Recommander"
import ModalContainerPortal from "../../../../components/ModalContainerPortal"
import StaffUserPointUpdate from "./StaffUserPointUpdate"

const MAX_PAGE_LENGTH = 10

export default function StaffUserPoint({ setPmsNavState }) {
    const { itemID } = useParams()

    const [recommanders, setRecommanders] = useState([])

    const [search, setSearch] = useState("")
    // const [brandStatus, setBrandStatus] = useState(EditStateTypes.NONE)
    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")

    const [order, setOrder] = useState("id")
    const [sortType, setSortType] = useState(SortTypes.DESC)

    const [pageSize, setPageSize] = useState(100)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    // 수정 모달
    const [isShowModal, setShowModal] = useState(false)
    const [selectedRecommander, setSelectedRecommander] = useState(new Recommander())

    useEffect(() => {
        setPmsNavState(PmsNavState.STAFFUSER)
        getRecommanders(pageIndex, pageSize, search, dateStart, dateEnd, order, sortType)

        return () => setPmsNavState(PmsNavState.NONE)
    }, [])

    const getRecommanders = async (
        _pageIndex, // 페이지
        _pageSize, // 페이지내 아이템 개수
        _search, // 검색 키워드
        _dateStart, // 마케터 상태
        _dateEnd, // 마케터 상태
        _order, // 정렬 옵션
        _sort, // 정렬 방향
    ) => {
        if (_sort === SortTypes.DESC) {
            _order = "-" + _order
        }
        // 정렬 옵션이 id가 아닌 경우 2차로 id를 정렬한다
        // if (!_order.includes(UserBrandOrderOptionTypes.REGIST)) {
        //     _order += `,-${UserBrandOrderOptionTypes.REGIST}`
        // }

        setPageIndex(_pageIndex)
        const response = await getUserRecommanders(_pageIndex, _pageSize, _search, _dateStart, _dateEnd, _order)

        if (response !== undefined) {
            setRecommanders(response.items)
            setItemCount(response.count)
        }
    }

    const handleSearchMarketer = () => {
        getRecommanders(1, pageSize, search, dateStart, dateEnd, order, sortType)
    }

    const handleSearchMarketerEnter = () => {
        handleSearchMarketer()
    }

    const handlePageIndex = (pageIndex) => {
        getRecommanders(pageIndex, pageSize, search, dateStart, dateEnd, order, sortType)
    }
    const handleDateStart = (value) => {
        setDateStart(value)
        getRecommanders(1, pageSize, search, value, dateEnd, order, sortType)
    }
    const handleDateEnd = (value) => {
        setDateEnd(value)
        getRecommanders(1, pageSize, search, dateStart, value, order, sortType)
    }
    const handleOrderType = (value) => {
        setOrder(value)
        let _sortType = sortType
        if (value === UserBrandOrderOptionTypes.REGIST) {
            _sortType = SortTypes.DESC
        } else if (value === UserBrandOrderOptionTypes.NAME) {
            _sortType = SortTypes.ASC
        } else if (value === UserBrandOrderOptionTypes.BRAND_NAME) {
            _sortType = SortTypes.ASC
        }
        setSortType(_sortType)

        getRecommanders(1, pageSize, search, dateStart, dateEnd, value, _sortType)
    }
    const handleSortType = (value) => {
        setSortType(value)
        getRecommanders(1, pageSize, search, dateStart, dateEnd, order, value)
    }

    const handleFilterReset = () => {
        setSearch("")
        setDateStart("")
        setDateEnd("")
        setOrder(UserBrandOrderOptionTypes.REGIST)
        setSortType(SortTypes.DESC)

        getRecommanders(1, pageSize, "", EditStateTypes.NONE, UserBrandOrderOptionTypes.REGIST, SortTypes.DESC)
    }

    const handleModal = (_recommander) => {
        setShowModal(true)
        setSelectedRecommander(_recommander)
    }

    const getCurrentRecommanders = () => {
        getRecommanders(pageIndex, pageSize, search, dateStart, dateEnd, order, sortType)
    }

    return (
        <MyPageLayout>
            <ContentContainer full_height>
                <StaffListControlBox>
                    <PerformListControlSection>
                        <FilterInputText
                            value={search}
                            setValue={setSearch}
                            submit={() => {
                                handleSearchMarketerEnter()
                            }}
                            width={"52"}
                            placeholder={"브랜드"}
                        />
                        <FilterInputDate
                            width={"84"}
                            label={"적용기간"}
                            dateStart={dateStart}
                            setDateStart={setDateStart}
                            dateEnd={dateEnd}
                            setDateEnd={setDateEnd}
                        />

                        {/* <FilterInputCombo
                            label={"정렬"}
                            value={order}
                            setValue={handleOrderType}
                            sortType={sortType}
                            setSortType={handleSortType}
                            width={"48"}
                        >
                            <option value={UserBrandOrderOptionTypes.REGIST}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.REGIST]}
                            </option>
                            <option value={UserBrandOrderOptionTypes.NAME}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.NAME]}
                            </option>
                            <option value={UserBrandOrderOptionTypes.BRAND_NAME}>
                                {UserBrandOrderOptionNames[UserBrandOrderOptionTypes.BRAND_NAME]}
                            </option>
                        </FilterInputCombo> */}

                        <FilterInputReset reset={handleFilterReset} />
                    </PerformListControlSection>

                    <StaffListControlSection last>
                        <Pagination
                            page={pageIndex}
                            setPage={handlePageIndex}
                            pageSize={pageSize}
                            itemCount={itemCount}
                            maxPageButtons={MAX_PAGE_LENGTH}
                            styleType={PaginationStyles.NoneBGCircleButtons}
                        />
                        <div className="w-2"></div>
                        <Link to={`/mypage/staff/user/point`}>
                            <MarketerToggleButton marketerstate={0}>포인트 관리</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/staff/user/brand`}>
                            <MarketerToggleButton marketerstate={1}>브랜드 담당 목록</MarketerToggleButton>
                        </Link>
                        <Link to={`/mypage/staff/user/marketer`}>
                            <MarketerToggleButton marketerstate={1}>마케터 목록</MarketerToggleButton>
                        </Link>
                    </StaffListControlSection>
                </StaffListControlBox>
                <MarketerSubControlBox></MarketerSubControlBox>

                <PerformTableContainer>
                    <div className="w-full h-full relative">
                        <PerformTableSort>
                            <PerformTableSortTop className="justify-end">
                                <div className="flex items-center">
                                    <PerformAddButton
                                        onClick={() => {
                                            handleModal(new Recommander())
                                        }}
                                    >
                                        실적 등록
                                    </PerformAddButton>
                                </div>
                            </PerformTableSortTop>
                        </PerformTableSort>
                        <StaffUserPointTable recommanders={recommanders} openModal={handleModal} />
                    </div>
                </PerformTableContainer>
            </ContentContainer>

            <ModalContainerPortal show={isShowModal} setShow={setShowModal} isBlur={true} closeByBackground={false}>
                <StaffUserPointUpdate
                    recommander={selectedRecommander}
                    getCurrentRecommanders={getCurrentRecommanders}
                    close={() => {
                        setShowModal(false)
                    }}
                />
            </ModalContainerPortal>
        </MyPageLayout>
    )
}
