/* eslint-disable no-undef */

import { PerformStaticColumnTypes } from "../performColumns/PerformColumnTypes"
import PerformValueText from "./PerformValueText"
import PerformValueCheckBox from "./PerformValueCheckBox"
import PerfromValueDate from "./PerformValueDate"

export const getPerformColumnStaticSelect = (perform) => {
    const value = new PerformValueCheckBox()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.ID
    value.checked = false

    return value
}

export const getPerformValueStaticID = (perform) => {
    const value = new PerformValueText()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.ID
    value.text = "수정"
    value.hasDetail = false

    return value
}
export const getPerformValueStaticMarketer = (perform) => {
    const value = new PerformValueText()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.MARKETER
    value.text = perform.marketer.user.name
    value.hasDetail = false

    return value
}
export const getPerformValueStaticUpdate = (perform) => {
    const value = new PerformValueText()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.UPDATE
    value.text = "수정"
    value.hasDetail = false

    return value
}
export const getPerformValueStaticCreated = (perform) => {
    // const value = new PerformValueText()
    const value = new PerfromValueDate()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.CREATED
    value.text = perform.created //perform.id
    value.hasDetail = false

    return value
}
export const getPerformValueStaticCalculated = (perform) => {
    // const value = new PerformValueText()
    const value = new PerfromValueDate()
    value.performID = perform.id
    value.columnID = PerformStaticColumnTypes.CALCULATED
    value.text = perform.calculated
    value.hasDetail = false

    return value
}

export const getPerformValueStatics = (perform) => {
    const values = [
        getPerformValueStaticID(perform),
        getPerformValueStaticMarketer(perform),
        getPerformValueStaticUpdate(perform),
        getPerformValueStaticCreated(perform),
        getPerformValueStaticCalculated(perform),
    ]

    return values
}
