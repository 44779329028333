import React, { useRef, useEffect, useState, createRef } from "react"
import { useParams, useNavigate, useNavigation } from "react-router-dom"
import { registItemByMarketer } from "../../apis/services/brandItems"
import {
    getItemContractHtml,
    getItemContractImages,
    getBrandItemView,
    getItemMarketerInviteds,
} from "../../apis/api/boardItems"
import { DrawingBoard } from "../../components/brandItems/SignDrawingBoard"
import { useUserStore } from "../../zustand/store"
import tw from "tailwind-styled-components"
import BackendResponse from "../../constants/BackendResponse"
import ContractImageViewer, { ContractImage } from "../../components/brandItems/ContractImageViewer"
import {
    ItemApplyContainer,
    ItemApplyLayout,
    ItemApplyContractPaperContainer,
    ContractTitle,
    ContractImageContainer,
    DrawingBoardContainer,
    CheckIcon,
    CheckIconContainer,
    ItemApplyAgreeContainer,
    ItemApplyAgreeText,
    ItemApplyButton,
    ContractDescription,
    ItemApplyContractButtonContainer,
    CheckBoxIcon,
    UnCheckBoxIcon,
    PhoneIcon,
} from "./ItemApplyStyle"
import { Contract } from "../../components/ContractCreate"
import BrandItem from "../../types/brandItems/BrandItem"
import { MarketerStatusTypes } from "../../types/brandItems/BrandItemTypes"
import { ModalContainer } from "../../components/ModalContainer"
import Utils from "../../utils/Utils"
import { isMobile } from "react-device-detect"
import usePreventLeave from "../../hooks/usePreventLeave"
import { SignCanvas } from "../../components/brandItems/SignCanvas"
import { RESPONSIVE_WIDTH } from "../../constants/ConstUI"
import SpinLoading, { SpinLoadingBackgroundType } from "../../components/loading/SpinLoading"
import ModalContainerPortal from "../../components/ModalContainerPortal"
import { sendAlertTalk } from "../../apis/services/sms"
import { AlertTalkTemplateTypes } from "../../types/SmsTypes"

// eslint-disable-next-line react/display-name
const ItemApply = React.forwardRef(({ setAppliable }, ref) => {
    const [signUp, setSignUp] = useState(null)
    const { itemID } = useParams()
    const { user, userDetail } = useUserStore()
    const [item, setItem] = useState(new BrandItem())
    const [contractImage, setContractImage] = useState([])
    const [contractHtml, setContractHtml] = useState("")
    const [isRegisting, setRegisting] = useState(false)
    const [marketerStatusType, setMarketerStatusType] = useState(null)
    const [showContractImageModal, setShowContractImageModal] = useState(false)
    const [isMobileContractConfirmed, setMobileContractConfirmed] = useState(false)

    const [marketerName, setMarketerName] = useState("")
    const [marketerInvites, setMarketerInvites] = useState([])
    const [selectedInviteMarketerID, setSelectedInviteMarketerID] = useState(-1)

    // const [isAgreed,setIsAgreed] = useState(false) // 서명 시 동의하는 걸로 처리

    const canvasRef = useRef(null)

    const navigate = useNavigate()

    React.useImperativeHandle(ref, () => ({
        handleApply,
    }))

    useEffect(() => {
        window.onpageshow = (e) => {
            if (e.persisted || (window.performance && window.performance.navigation.type === 2)) {
                // setOverflowAuto(false)
            }
        }

        const { enablePrevent, disablePrevent } = usePreventLeave()
        enablePrevent()

        handleShowItemContract()
        getBrandItem()
        getMarketerInvites()
        return () => {
            disablePrevent()
        }
    }, [user])

    useEffect(() => {
        if (userDetail === null) {
            return
        }

        if (
            marketerStatusType === MarketerStatusTypes.WAIT ||
            marketerStatusType === MarketerStatusTypes.BLOCK ||
            marketerStatusType === MarketerStatusTypes.CONFIRM
        ) {
            alert("권한이 없습니다.")
            navigate(-1)
        } else if (user.gender === 2 || user.birthday === null || userDetail.detail.location1 === 0) {
            // alert("추가정보를 모두 입력 해주시기 바랍니다.")
            // navigate(-1)
            //
        }
    }, [marketerStatusType])

    const getBrandItem = () => {
        getBrandItemView(itemID)
            .then((response) => {
                const responseItem = new BrandItem()
                responseItem.convertByResponse(response)
                setItem(responseItem)
                setMarketerStatusType(response.marketer_status_type)
            })
            .catch((error) => {
                alert("찾을 수 없습니다.")
                navigate(-1)
            })
    }

    const getMarketerInvites = () => {
        getItemMarketerInviteds(itemID, user.id, MarketerStatusTypes.WAIT, 1, 100, "").then((response) => {
            setMarketerInvites(response.items)
        })
    }

    const handleShowItemContract = async () => {
        getItemContractHtml(itemID).then((response) => {
            setContractHtml(response)
        })
    }

    const handleContractModal = () => {
        setShowContractImageModal(true)
        setMobileContractConfirmed(true)
    }

    // const handleAgree = () => {
    //     if (!isAgreed && isMobile && !isMobileContractConfirmed) {
    //         alert("계약서를 확인해주세요.")
    //         return
    //     }
    //     setIsAgreed(!isAgreed)
    // }

    const handleRegist = (signImage) => {
        // if (Utils.isStringNullOrEmpty(marketerName)) {
        //     alert("마케팅 활동 명을 입력해주세요.")
        //     return
        // }
        if (isRegisting) {
            alert("진행 중")
            return
        }
        setRegisting(true)

        registItemByMarketer(user, itemID, selectedInviteMarketerID, marketerName, signImage)
            .then((result) => {
                if (result === BackendResponse.SUCCESS) {
                    // 알림톡 발송
                    let alertTalkDatas = [
                        {
                            tel: String(item.brand.user.tel),
                            variables: {
                                "#{아이템명}": item.detail.title,
                                "#{마케터명}": marketerName,
                            },
                        },
                    ]
                    sendAlertTalk(AlertTalkTemplateTypes.ITEM_APPLY_REQUEST, alertTalkDatas)

                    alert("신청되었습니다.")
                    navigate(`/items/${itemID}`)
                } else if (result === BackendResponse.NOT_FOUND_MARKETER_DETAIL) {
                    alert("마케터 추가 정보를 입력 해주시기 바랍니다.")
                    navigate(`/mypage/myinfo`)
                } else if (result === BackendResponse.NOT_FOUND) {
                    alert("상품 정보를 찾지 못했습니다.")
                } else {
                    alert("신청에 실패하였습니다.")
                }
                setRegisting(false)
            })
            .catch((error) => {
                alert("신청에 실패하였습니다.")

                setRegisting(false)
            })
    }

    const handleApply = () => {
        if (Utils.isNullOrUndefined(canvasRef.current)) {
            return
        }

        canvasRef.current.handleSubmit()
    }

    return (
        <>
            <ContractTitle>마케터 그룹 명</ContractTitle>
            <ContractDescription className="max-md:mb-1 md:mb-0">
                단체이거나 그룹인 경우 그룹명을 입력해주세요.
            </ContractDescription>
            <div className="flex w-full max-lg:px-4 lg:px-8 py-2">
                <input
                    type="text"
                    placeholder={"A영업그룹"}
                    value={marketerName}
                    onChange={(e) => {
                        setMarketerName(e.target.value)
                    }}
                    className="w-full rounded-md bg-slate-100 px-3 py-2"
                />
            </div>

            {marketerInvites.length > 0 && (
                <>
                    <ContractTitle>마케터 초대내역</ContractTitle>
                    <div className="flex flex-col w-full max-lg:px-4 lg:px-8">
                        <span className="p-2 pt-0">초대한 마케터가 맞으면 선택해 주세요.</span>
                        <div className="grid max-lg:grid-cols-1 lg:grid-cols-2 gap-2 w-full max-h-[16rem] p-2 border border-slate-300 rounded-lg overflow-y-auto scroll-transparent">
                            {marketerInvites.map((marketerInvite, index) => (
                                <div
                                    key={index}
                                    className={`flex items-center w-full h-fit px-4 py-2 rounded-md ${
                                        selectedInviteMarketerID === marketerInvite.itemMarketerSenderID
                                            ? "bg-blue-200 hover:bg-blue-100"
                                            : "bg-slate-100 hover:bg-slate-200"
                                    } duration-200 cursor-pointer`}
                                    onClick={() => {
                                        setSelectedInviteMarketerID(
                                            selectedInviteMarketerID === marketerInvite.itemMarketerSenderID
                                                ? -1
                                                : marketerInvite.itemMarketerSenderID,
                                        )
                                    }}
                                >
                                    <div className="flex flex-center h-7 text-slate-500 mr-4">
                                        {selectedInviteMarketerID === marketerInvite.itemMarketerSenderID ? (
                                            <CheckBoxIcon />
                                        ) : (
                                            <UnCheckBoxIcon />
                                        )}
                                    </div>
                                    <div className="flex max-sm:flex-col sm:flex-row w-full">
                                        <div className="flex items-center">
                                            <span>{marketerInvite.marketerSender.user.name}</span>
                                            <div className="flex items-center text-slate-600 ml-1">
                                                <div className="h-5">
                                                    <PhoneIcon />
                                                </div>{" "}
                                                {marketerInvite.marketerSender.user.tel}
                                            </div>
                                        </div>
                                        <div className="flex items-center text-sm text-slate-500 max-sm:mt-2 sm:ml-auto">
                                            <span>초대날짜 {marketerInvite.created}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            <div className="flex max-lg:flex-col lg:flex-row lg:justify-between lg:items-center w-full max-md:my-6 md:my-10 max-lg:space-y-2">
                <ContractTitle className="mt-0 mb-0">계약서</ContractTitle>
                <ItemApplyContractPaperContainer>
                    <div className="max-lg:hidden lg:hidden w-full h-192 rounded-md border border-slate-300">
                        <Contract htmlContract={contractHtml} />
                    </div>
                    {/* <ItemApplyAgreeContainer onClick={() => {handleAgree()}} isAgreed={isAgreed}>
                            <CheckIconContainer isAgreed={isAgreed}>
                            <CheckIcon />
                            </CheckIconContainer>
                            <ItemApplyAgreeText isAgreed={isAgreed}>
                            상기 내용에 대해 동의 하시겠습니까?
                            </ItemApplyAgreeText>
                        </ItemApplyAgreeContainer> */}
                </ItemApplyContractPaperContainer>
                <ItemApplyContractButtonContainer>
                    <ItemApplyButton
                        className="w-full my-0"
                        onClick={() => {
                            handleContractModal()
                        }}
                    >
                        계약서 보기
                    </ItemApplyButton>
                </ItemApplyContractButtonContainer>
            </div>

            <ContractTitle>서명</ContractTitle>
            <ContractDescription>계약 내용에 동의 시 서명 후 신청 버튼을 눌러주세요.</ContractDescription>
            <DrawingBoardContainer>
                {/* <DrawingBoard regist={handleRegist} isRegisting={isRegisting} signImage={signUp} setSignImage={setSignUp} isApply={true} /> */}
                <div
                    onMouseDown={() => {
                        setAppliable(true)
                    }}
                    onTouchStart={() => {
                        setAppliable(true)
                    }}
                >
                    <SignCanvas
                        ref={canvasRef}
                        submit={handleRegist}
                        isLoading={isRegisting}
                        showApplyButton={false}
                        setAppliable={setAppliable}
                    />
                </div>
            </DrawingBoardContainer>

            <SpinLoading
                isLoading={isRegisting}
                top={"0"}
                backgroundType={SpinLoadingBackgroundType.BLUR}
                message={"지원 신청 중입니다."}
            />
            <ModalContainerPortal
                show={showContractImageModal && !Utils.isStringNullOrEmpty(contractHtml)}
                setShow={setShowContractImageModal}
                isBlur={true}
                fadeDown={true}
                closeByBackground={true}
                mobileScreenHeight={RESPONSIVE_WIDTH.LG}
            >
                <ContractImageViewer htmlSources={[contractHtml]} />
            </ModalContainerPortal>
        </>
    )
})
export default ItemApply
