import { EditStateTypes } from "../../EditorTypes"
import PerformValueCombo from "./PerformValueCombo"
import PerformValueFile from "./PerformValueFile"
import PerformValueStatus from "./PerformValueStatus"
import PerformValueText from "./PerformValueText"
import PerformValueDate from "./PerformValueDate"
import { PerformValueTypes } from "./PerformValueTypes"
import UserMarketer from "../../users/UserMarketer"
import BrandItemMarketer from "../marketers/BrandItemMarketer"
import PerformValueOption from "./PerformValueOption"
import { Dictionary } from "../../Dictionary"
import PerformValueOptionItem from "./PerformValueOptionItem"
import User from "../../users/User"
import Utils from "../../../utils/Utils"
import AccessRules from "../../../rules/AccessRules"

export default class {
    constructor() {
        this.id = -1
        this.writer = new User()
        this.itemID = -1
        this.marketer = new UserMarketer()
        this.itemMarketer = new BrandItemMarketer()
        this.isConfirmed = false
        this.isCalculated = false
        this.editStateType = EditStateTypes.WAIT
        this.created = ""
        this.calculated = ""

        this.combos = []
        this.files = []
        this.texts = []
        this.statuses = []
        this.options = []
        this.dates = []

        this.values = []
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.itemID = response["item_id"]
        this.marketer = response["marketer"]["user"]["first_name"]
        this.isConfirmed = response["is_confirmed"]
        this.isCalculated = response["is_calculated"]
        this.editStateType = response["edit_state_type"]
        this.created = response["created"]
        this.calculated = response["calculated"]

        const writerResponse = response["writer"]
        this.writer = new User()
        if (!Utils.isNullOrUndefined(writerResponse)) {
            this.writer.convertByResponse(writerResponse)
        }
        const marketerResponse = response["marketer"]
        this.marketer = new UserMarketer()
        if (!Utils.isNullOrUndefined(marketerResponse)) {
            this.marketer.convertByResponse(marketerResponse)
        }
        const itemMarketerResponse = response["item_marketer"]
        this.itemMarketer = new BrandItemMarketer()
        if (!Utils.isNullOrUndefined(itemMarketerResponse)) {
            this.itemMarketer.convertByResponse(itemMarketerResponse)
        }

        const comboResponse = response["perform_value_combo"]
        if (!Utils.isNullOrUndefined(comboResponse)) {
            comboResponse.map((res) => {
                const combo = new PerformValueCombo()
                combo.convertByResponse(res)
                this.combos.push(combo)

                this.values.push(combo)
            })
        }
        const fileResponse = response["perform_value_file"]
        if (!Utils.isNullOrUndefined(fileResponse)) {
            fileResponse.map((res) => {
                const file = new PerformValueFile()
                file.convertByResponse(res)
                this.files.push(file)

                this.values.push(file)
            })
        }
        const textResponse = response["perform_value_text"]
        if (!Utils.isNullOrUndefined(textResponse)) {
            textResponse.map((res) => {
                const text = new PerformValueText()
                text.convertByResponse(res)
                this.texts.push(text)

                this.values.push(text)
            })
        }
        const statusResponse = response["perform_value_status"]
        if (!Utils.isNullOrUndefined(statusResponse)) {
            statusResponse.map((res) => {
                const status = new PerformValueStatus()
                status.convertByResponse(res)
                this.statuses.push(status)

                this.values.push(status)
            })
        }
        const optionResponse = response["perform_value_option"]
        if (!Utils.isNullOrUndefined(optionResponse)) {
            // OPTION은 Value가 여러개여서 로직이 다르다.
            let optionDic = new Dictionary()

            optionResponse.map((res) => {
                const option = new PerformValueOptionItem()
                option.convertByResponse(res)

                let mainOption
                if (optionDic.contains(option.columnID)) {
                    mainOption = optionDic.getValue(option.columnID)
                } else {
                    mainOption = new PerformValueOption()
                    mainOption.columnID = option.columnID
                    mainOption.performID = this.id
                }
                mainOption.optionItems.push(option)
                optionDic.push(option.columnID, mainOption)
            })

            optionDic.getValues().map((mainOption) => {
                this.options.push(mainOption)
                this.values.push(mainOption)
            })
        }
        const dateResponse = response["perform_value_date"]
        if (!Utils.isNullOrUndefined(dateResponse)) {
            dateResponse.map((res) => {
                const date = new PerformValueDate()
                date.convertByResponse(res)
                this.dates.push(date)

                this.values.push(date)
            })
        }
    }

    convertToRequest() {}

    isReadOnly(user) {
        if (!user) {
            return true
        }

        let readOnly = false

        if (AccessRules.performCreate(user)) {
            //
        } else {
            if (this.writer.id === user.id || this.id < 0) {
                //
            } else {
                readOnly = true
            }
        }

        return readOnly
    }
}
