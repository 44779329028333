import React,{useEffect,useState,useRef} from "react";
import tw from 'tailwind-styled-components'
import {Send} from "@styled-icons/bootstrap/Send"

export const CommentMarginBox = tw.div`
    min-w-[2rem]
`

export const CommentBox = tw.div`
    flex flex-col mb-2 w-auto max-w-[16rem]
    ${(props) => props.isMainComment ? "items-end" : "items-start"}
`

export const CommentStatusBox = tw.div`
    flex items-center px-1 py-0.5 mb-1
    text-xs rounded-full drop-shadow
`
export const CommentStatusSpan = tw.span`
    px-2 text-white
`
export const CommentDetailBox = tw.div`
    flex items-center px-2
    bg-white rounded-full
`

export const CommentMessageBox = tw.div`
    flex flex-col px-2.5 py-1
    rounded-lg shadow-lg
    max-sm:text-sm sm:text-base
    ${(props) => props.isMainComment ? 
        "bg-gradient-to-t from-nightblue-600 to-nightblue-500 [&>span]:text-white [&>span]:text-right" : 
        "bg-white [&>span]:text-black"
    }
    [&>span]:whitespace-pre-line
`

export const AlertCommentBox = tw.div`
    flex items-center px-4 py-1 my-4
    bg-gray-150 border border-gray-200
    text-xs text-gray-600
    rounded-full 
`


export const StatusCommentLayout = tw.div`
    flex max-sm:flex-col sm:flex-row justify-between w-full h-full
`

export const StatusCommentTypeBox = tw.div`
flex max-sm:flex-row sm:flex-col max-sm:w-full sm:w-36 sm:h-full rounded-md pr-2
`

export const StatusCommentsContainer = tw.div`
w-full h-full relative
`
export const StatusCommentsLayout = tw.div`
flex flex-col w-full h-full absolute bg-gray-75 p-2 rounded-lg
first-margin-top-auto
`
export const StatusCommentsCol = tw.div`
flex flex-col-reverse w-full max-h-full p-2
first-margin-top-auto
overflow-y-auto scroll-transparent scroll-overlay
`
export const StatusCommentInputLayout = tw.div`
flex items-end w-full mt-auto
`
export const StatusCommentInputBar = tw.div`
flex items-end w-full p-2 rounded-md
`
export const StatusCommentInputTextAreaContainer = tw.div`
w-full flex rounded-md bg-white p-2
`

export const CommentInputTextArea = tw.textarea`
    w-full focus:outline-none scroll-transparent resize-none
`
export const CommentSubmitButton = tw.button`
flex flex-center w-20 h-10 ml-2
bg-nightblue-600 hover:bg-nightblue-500 rounded-lg text-white
disabled:bg-gray-400 disabled:hover:bg-gray-400
`

export const CommentSubmitIcon = tw(Send)`h-5`


export const ConfirmApplyBox = tw.div`
flex flex-col p-2
bg-white drop-shadow-lg rounded-lg border
`
export const ConfirmApplyMessage = tw.div`
flex justify-center py-2
[&>span]:font-medium
`