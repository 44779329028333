import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import { MyPageInputModalLayout } from '../../pages/myPage/MyPageMainStyle'


export const BoardEditContainer = tw(MyPageInputModalLayout)`
    bg-white flex flex-col 
    max-sm:w-screen max-lg:w-screen lg:w-[64rem]
    max-lg:h-full lg:min-h-[90vh] lg:h-[90vh] p-0
    max-md:rounded-none md:rounded-none lg:rounded-xl
`
export const BoardEditLayout = tw.div`
    flex flex-col w-full h-full p-4
`
export const BoardEditTitleContainer = tw.div`
    flex items-center w-full my-2
`
export const BoardEditTitleInput = tw.input`
    px-2 py-1 bg-gray-100 rounded-lg w-full
    focus:ring-2 focus:ring-blue-300 focus:bg-blue-50
    max-sm:text-sm sm:text-base
    duration-200
`

export const BoardEditEditorContainer = tw.div`
    w-full h-full relative overflow-hidden
` 
export const BoardEditControlBox = tw.div`
    w-full flex max-sm:justify-between sm:justify-end py-1 
`
export const BoardEditFileButton = tw.button`
    max-sm:block sm:hidden
    px-4 py-1
    text-medium text-gray-700
    rounded-lg hover:bg-gray-100
`
export const BoardEditSubmitButton = tw.button`
    px-4 py-1
    text-medium text-nightblue-700
    rounded-lg hover:bg-nightblue-100
`

export const BoardEditFileContainer = tw.div`
    max-sm:hidden sm:flex flex-col w-full h-48 mt-2 relative 
`

export const BoardEditFileModalContainer = tw.div`
    absolute ${(props) => props.isShow ? "z-30 opacity-100" : "-z-10 opacity-0"} duration-300
    flex flex-center px-6 py-16 w-full h-full
    bg-white/30 backdrop-blur-sm rounded-xl
`
export const BoardEditFileModalLayout = tw.div`
    relative
    flex flex-col w-full h-full
    bg-white rounded-xl border
`