import React, { useEffect, useState, useRef, useCallback } from "react"
import tw from "tailwind-styled-components"
import { debounce } from "lodash"

import { PmsOfficeNavState } from "../../../types/DataEnum"
// import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd"
import { TableHeaderList, TestDataList } from "../../../constants/ConstTableData"
import { Pagination } from "../../../components/Pagination"
import {
    PerformCon,
    PerformConInner,
    PerformListControlBox,
    PerformTableSearchButton,
    PerformValueLayout,
    PerformTableHeader,
    PerformTableHeaderBox,
    PerformTableRowLayout,
    PerformTableSearch,
    PerformTableSearchBox,
    PerformTableSort,
    PerformTableSortBottom,
    PerformTableSortInput,
    PerformTableSortSelect,
    PerformTableSortTop,
    PerformSortOptionButton,
    PerformValueInputLayout,
    PerformTableContainer,
    PerformAddButton,
    PerformTableFilterBox,
    PerformTableSearchIcon,
    PerformTableFilterButton,
    PerformTableFilterIcon,
    PerformBatchUpdateButton,
    PerformListControlSection,
    ExcelIcon,
    PerformExcelButton,
} from "./MyPagePerformStyle"
import { Dictionary } from "../../../types/Dictionary"
import {
    createItemPerform,
    getItemPerformColumnOrders,
    getItemPerformColumns,
    getItemPerforms,
    getPerformCalc,
    getPerfromExcelData,
} from "../../../apis/api/boardItems"
import MyPagePerformTable from "./MyPagePerformTable"
import { getPerformValueStatics } from "../../../types/brandItems/performValues/PerformValueStatics"
import { performColumnStatics } from "../../../types/brandItems/performColumns/PerformColumnStatics"
import {
    PerformColumnFormTypes,
    PerformColumnTypes,
    PerformStaticColumnTypes,
} from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { ModalContainer } from "../../../components/ModalContainer"
import {
    getComboName,
    getPerformEmptyValue,
    getStatusName,
    getStatusDate,
} from "../../../utils/brandItem/BrandItemPerformUtils"
import {
    FilterTagBox,
    FilterTagCancleButton,
    FilterTagCancleIcon,
    FilterTagColumnName,
    FilterTagContainer,
    FilterTagList,
    FilterTagValue,
} from "./MyPagePerformFilterStyle"
import Utils from "../../../utils/Utils"
import MyPagePerformBatchUpdate from "./MyPagePerformBatchUpdate"
import { PaginationStyles } from "../../../types/StyleTypes"
import { ContentContainer } from "../MyPageMainStyle"
import { CalcSelectContainer } from "../calculate/MyPageCalcStyle"
import MyPageCalc from "../calculate/MyPageCalc"
import MyPagePerformCalcAdd from "./MyPagePerformCalcAdd"
import MyPagePerformFilter from "../../myPage/perform/MyPagePerformFilter"
import MyPagePerformCalcList from "./MyPagePerformCalcList"
import Perform from "../../../types/brandItems/performValues/Perform"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import PerformCalculate from "../../../types/brandItems/calculates/PerformCalculate"
import PerformColumn from "../../../types/brandItems/performColumns/PerformColumn"
import PerformValueText from "../../../types/brandItems/performValues/PerformValueText"
import AccessRules from "../../../rules/AccessRules"
import { useUserStore } from "../../../zustand/store"
import { FilterInputText } from "../../../components/brandItems/FilterInputs"
import {
    BrandItemMarketerSearchOptionNames,
    BrandItemMarketerSearchOptionTypes,
} from "../../../types/brandItems/BrandItemOption"
import MyPagePerformUpdate from "./MyPagePerformUpdate"
import { CreateExcelFile } from "./MyPageCreateExcel"
import MyPagePerformFilterShortcut from "./MyPagePerformFilterShortcut"
import { UserTypes } from "../../../types/users/UserTypes"
import { RESPONSIVE_WIDTH } from "../../../constants/ConstUI"
import SpinLoading from "../../../components/loading/SpinLoading"
import { EditStateTypes } from "../../../types/EditorTypes"
import PerformUtils from "../../../utils/PerformUtils"
import PerformList from "../../../types/brandItems/performValues/PerformList"
import { PaginationSize } from "../../../components/PaginationSize"

const CELL_MIN_WIDTH = 100

const RATIO_MOBILE_WIDTH = 0.8
const getCellWidth = (width) => {
    return window.innerWidth < RESPONSIVE_WIDTH.LG ? width * RATIO_MOBILE_WIDTH : width
}

// 각 행에 고정 컬럼 정보를 넣는다.
const updateRowsStaticValue = (performs) => {
    if (performs === undefined) {
        return performs
    }

    performs.map((perform) => {
        const staticValues = getPerformValueStatics(perform)
        staticValues.map((staticValue) => {
            perform.values.push(staticValue)
        })
    })

    if (Utils.isNullOrUndefined(performs)) {
        performs = []
    }

    return performs
}

// 동적 컬럼과 고정 컬럼을 합친다.
const updateColumnsStatic = (columns, user) => {
    let newColumns = [...columns, ...performColumnStatics]

    newColumns.map((newcolumn) => {
        // if (newcolumn.id === PerformStaticColumnTypes.UPDATE && user.userType === UserTypes.MARKETER) {
        //     newcolumn.name = "수정"
        // }
        return newcolumn
    })
    return newColumns
}

// 각 컬럼의 너비를 테이블 너비에 맞춰 계산한다.
const updateColumnSize = (columns, tableWidth) => {
    // 고정 너비들의 합.
    const widthStaticSum = columns.reduce(function add(sum, currValue) {
        if (!currValue.width.includes("*")) {
            return sum + Number(currValue.width)
        } else {
            return sum
        }
    }, 0)
    // 비율 너비들의 비율 합. (비율 너비는 *을 포함)
    const widthRatioSum = columns.reduce(function add(sum, currValue) {
        if (currValue.width.includes("*")) {
            const widthRatio = Number(currValue.width.replace("*", ""))
            return sum + widthRatio
        } else {
            return sum
        }
    }, 0)
    // 테이블 너비에서 고정 너비들의 합을 뺀다. 고정 너비의 영향을 받지 않고 비율 너비들의 너비값 구할 때 사용.
    const tableWidthOnlyRatio = tableWidth - widthStaticSum

    // 해당 값보다 낮아지면 최소값을 사용한다.
    const useMinWidth = tableWidthOnlyRatio * (1 / widthRatioSum) < CELL_MIN_WIDTH
    let newColumns = []

    columns.map((column) => {
        let newColumn = { ...column }

        if (useMinWidth) {
            if (newColumn.width.includes("*")) {
                const widthRatio = Number(column.width.replace("*", ""))
                newColumn.widthCalculated = CELL_MIN_WIDTH * widthRatio
            } else {
                newColumn.widthCalculated = getCellWidth(newColumn.width)
            }
        } else {
            if (newColumn.width.includes("*")) {
                const widthRatio = Number(column.width.replace("*", ""))
                newColumn.widthCalculated = tableWidthOnlyRatio * (widthRatio / widthRatioSum)
            } else {
                newColumn.widthCalculated = getCellWidth(newColumn.width)
            }
        }

        newColumns.push(newColumn)
    })

    return newColumns
}

export default function MyPagePerform({ selectedItem, setOfficeNavState }) {
    const { itemID } = useParams()

    const tableRef = useRef(0)
    const { user } = useUserStore()

    const [isLoading, setLoading] = useState(true)
    const [isColumnRendering, setColumnRendering] = useState(true)
    const [isPerformRendering, setPerformRendering] = useState(true)

    // 실적과 동적 컬럼 정보. (아직 테이블에 사용하기 위해 가공되기 전 상태)
    const [rawColumns, setRawColumns] = useState([])
    const [rawPerforms, setRawPerforms] = useState([])

    const [columnOrders, setColumnOrders] = useState([]) // DB에 저장한 Column 정렬 정보 [columnID, columnID, columnID, ...]
    const [columnDic, setColumnDic] = useState(new Dictionary()) // 컬럼의 아이디를 키로 딕셔너리화
    const [columns, setColumns] = useState([]) // 컬럼 정렬 및 너비 계산이 완료된 배열
    const [columnFilterShortcuts, setColumnFilterShortcuts] = useState([]) // 필터 숏컷 설정된 컬럼
    const [rows, setRows] = useState([]) // 테이블의 열로 각 실적의 정보를 고정 컬럼과 동적 컬럼에 나누어 정리해놓은 상태
    const [sortVisible, setSortVisible] = useState(false) // 필터 바 표시
    const [tableWidth, setTableWidth] = useState(tableRef.current.offsetWidth) // 테이블 너비 (브라우저 크기에 따른 컬럼 너비 계산을 위해 사용)

    const [pageSize, setPageSize] = useState(15)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [showUpdate, setShowUpdate] = useState(false) // 실적 수정 모달 여부
    const [selectedPerform, setSelectedPerform] = useState(new Perform())

    const [showEdit, setShowEdit] = useState(false) // 실적 값 편집 모달 여부
    const [editComponent, setEditComponent] = useState(null) // 실적 값 편집 컴포넌트
    const [willCloseRefresh, setWillCloseRefresh] = useState(false) // 실적 값 편집 모달 종료 시 실적 새로고침 여부

    const [showViewer, setShowViewer] = useState(false) // 파일 뷰어 모달 여부
    const [viewerComponent, setViewerComponent] = useState(null) // 파일 뷰어 컴포넌트

    const [showFilter, setShowFilter] = useState(false) // 필터 모달 여부
    const [filterDic, setFilterDic] = useState(new Dictionary()) // 키: 컬럼 ID, 밸류: 실적 value

    const [showBatchUpdate, setShowBatchUpdate] = useState(false) // 일괄 수정 모달 여부
    const [performCheckDic, setPerformCheckDic] = useState(new Dictionary())

    const [showCalcAdd, setShowCalcAdd] = useState(false) // 실적 등록 모달 여부
    const [showCalcList, setShowCalcList] = useState(false) // 실적 리스트 모달 여부
    const [filteredCalc, setFilteredCalc] = useState(new PerformCalculate())

    const [selectedPerformID, setSelectedPerformID] = useState(-1) // 선택한 실적

    const [searchMarketerValue, setSearchMarketerValue] = useState("") // 마케터 검색 값
    const [searchMarketerOptionType, setSearchMarketerOptionType] = useState(
        BrandItemMarketerSearchOptionTypes.MARKETER,
    ) // 마케터 검색 타입
    const [isExcelLoading, setExcelLoading] = useState(false)

    const location = useLocation()

    useEffect(() => {
        setOfficeNavState(PmsOfficeNavState.PERFORM)
        window.addEventListener("resize", handleResize)

        setLoading(false)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (Number(itemID) < 0) {
            return
        }

        setRawColumns([])
        setRawPerforms([])
        setFilterDic(new Dictionary())
        setPerformCheckDic(new Dictionary())
        setSelectedPerformID(-1)
        setSearchMarketerValue("")

        if (
            location.state !== null &&
            location.state.filteredCalc !== undefined &&
            location.state.itemID === Number(itemID)
        ) {
            setFilteredCalc(location.state.filteredCalc)
        } else {
            setFilteredCalc(new PerformCalculate())
        }

        setColumnRendering(true)
        setPerformRendering(true)

        getItemPerformColumns(itemID).then((response) => {
            setRawColumns(response)
        })

        getItemPerformColumnOrders(itemID).then((response) => {
            response[-1] = PerformStaticColumnTypes.SELECT
            const keys = Object.keys(response).map((k) => Number(k))
            const sortedKeys = keys.sort((a, b) => a - b)
            let orders = []
            sortedKeys.map((sortedKey) => {
                orders.push(response[sortedKey])
            })

            setColumnOrders(orders)
        })

        getRawPerforms(1, new Dictionary())
    }, [itemID])

    useEffect(() => {
        setTableWidth(tableRef.current.offsetWidth)
    }, [tableRef.current.offsetWidth])

    useEffect(() => {
        const staticUpdatedColumns = updateColumnsStatic(rawColumns, user)
        const sizeUpdatedColumns = updateColumnSize(staticUpdatedColumns, tableWidth)
        const newColumnDic = new Dictionary()

        sizeUpdatedColumns.map((column) => {
            newColumnDic.push(column.id.toString(), column)
        })

        setColumnDic(newColumnDic)
    }, [tableWidth, rawColumns])

    useEffect(() => {
        let newColumns = []
        if (columnDic.getLength() > 0 && columnOrders.length > 0) {
            let columnWidthSum = 0 // 컬럼의 left 값을 구하기 위함.

            columnOrders.map((columnID) => {
                const column = columnDic.getValue(columnID)
                if (column === undefined) {
                    return
                }
                column.columnLeft = columnWidthSum
                columnWidthSum += Number(column.widthCalculated)

                newColumns.push(column)
            })
        }
        setColumns(newColumns)
        setColumnRendering(false)
    }, [columnDic, columnOrders])

    useEffect(() => {
        let shortcuts = []
        columns.map((column) => {
            if (column.isFilterShortcut) {
                shortcuts.push(column)
            } else if (column.formType === PerformColumnFormTypes.DATE) {
                shortcuts.push(column)
            }
        })
        setColumnFilterShortcuts(shortcuts)
    }, [columns])

    useEffect(() => {
        setRows(updateRowsStaticValue(rawPerforms))
        setPerformRendering(false)
    }, [rawPerforms, columnDic])

    useEffect(() => {
        if (!showEdit && willCloseRefresh) {
            setEditComponent(null)
            getRawPerforms(pageIndex, filterDic)
            setWillCloseRefresh(false)
        }
    }, [showEdit])

    useEffect(() => {
        if (!showFilter) {
            getRawPerforms(1, filterDic)
        }
    }, [filterDic, showFilter])

    useEffect(() => {
        getRawPerforms(pageIndex, filterDic)
    }, [filteredCalc])

    const handleResize = debounce(() => {
        setTableWidth(tableRef.current.offsetWidth)
    }, 300)

    const getRawPerforms = useCallback(
        async (_pageIndex, _filterDic, _pageSize = pageSize) => {
            if (itemID >= 0 && !isLoading) {
                if (filteredCalc.id >= 0) {
                    await getPerformCalc(-1, filteredCalc.id).then((response) => {
                        if (!Utils.isNullOrUndefined(response.performs)) {
                            setRawPerforms(response.performs)
                            setItemCount(response.count)
                        }
                    })
                } else {
                    setLoading(true)
                    await getItemPerforms(itemID, _pageIndex, _pageSize, _filterDic.data)
                        .then((response) => {
                            if (!Utils.isNullOrUndefined(response.performs)) {
                                setRawPerforms(response.performs)
                                setItemCount(response.count)
                            }
                            setLoading(false)
                        })
                        .catch((error) => {
                            setLoading(false)
                        })
                }
                setPageIndex(_pageIndex)
                setPageSize(_pageSize)
            }
        },
        [itemID, isLoading],
    )

    const reloadPerforms = () => {
        getRawPerforms(pageIndex, filterDic)
    }

    const handlePageIndex = (page) => {
        if (!isLoading) {
            getRawPerforms(page, filterDic)

            if (window.innerWidth < RESPONSIVE_WIDTH.LG) {
                window.scrollTo(0, 0)
            }
        }
    }

    const handlePageSize = (size) => {
        if (!isLoading) {
            getRawPerforms(pageIndex, filterDic, size)

            if (window.innerWidth < RESPONSIVE_WIDTH.LG) {
                window.scrollTo(0, 0)
            }
        }
    }

    const showEditComponent = (component) => {
        setEditComponent(component)
        setShowEdit(true)
    }
    const closeEditComponent = (refresh = false, willClose = false) => {
        if (!willClose) {
            if (refresh) {
                reloadPerforms()
            }
            setEditComponent(null)
            setShowEdit(false)
        } else if (willClose && refresh) {
            setWillCloseRefresh(true)
        }
    }

    const showViewerComponent = (show, component = null) => {
        setViewerComponent(component)
        setShowViewer(show)
    }

    const handleOpenPerformUpdate = async (_perform = new Perform()) => {
        if (_perform.id < 0) {
            if (
                selectedItem.editStateType === EditStateTypes.DISABLED ||
                selectedItem.editStateType === EditStateTypes.FAILED
            ) {
                alert("등록할 수 없는 상품입니다.")
                return
            }
        }

        setSelectedPerform(_perform)
        setShowUpdate(true)
        // const perform = await createItemPerform(itemID)

        // if (perform.id >= 0) {
        //     getRawPerforms(pageIndex, filterDic)
        //     alert("실적이 생성되었습니다.")
        // } else {
        //     alert("실적 생성 실패하였습니다.")
        // }
    }
    const handleClosePerformUpdate = (refresh = false) => {
        if (refresh) {
            // 실적을 수정하는 경우에는 페이지를 유지한다.
            if (selectedPerform.id >= 0) {
                reloadPerforms()
            } else {
                getRawPerforms(1, filterDic)
            }
        }
        setShowUpdate(false)
    }

    const handleAddFilter = (columnID, filterValue) => {
        handleRemoveFilteredCalc()

        let newFilterDic = new Dictionary(filterDic)
        newFilterDic.push(columnID.toString(), filterValue)

        setFilterDic(newFilterDic)
    }

    const handleRemoveFilter = (columnID) => {
        if (filterDic.contains(columnID)) {
            let newFilterDic = new Dictionary(filterDic)
            newFilterDic.delete(columnID.toString())

            setFilterDic(newFilterDic)
        }
    }

    const handleSearchMarketer = () => {
        if (columnDic.contains(PerformStaticColumnTypes.MARKETER.toString())) {
            const marketerColumn = { ...columnDic.getValue(PerformStaticColumnTypes.MARKETER.toString()) }

            if (!Utils.isStringNullOrEmpty(searchMarketerValue)) {
                let emptyValue = getPerformEmptyValue(marketerColumn)
                emptyValue.column = marketerColumn
                emptyValue.text = searchMarketerValue

                handleAddFilter(PerformStaticColumnTypes.MARKETER, emptyValue)
            } else {
                handleRemoveFilter(PerformStaticColumnTypes.MARKETER)
            }
        }
    }

    const handlePerformCheck = (perform, check) => {
        const newPerformCheckDic = new Dictionary(performCheckDic)
        const performID = perform.id

        if (check && !newPerformCheckDic.contains(performID)) {
            newPerformCheckDic.push(performID, perform)
            setPerformCheckDic(newPerformCheckDic)
        } else if (!check && newPerformCheckDic.contains(performID)) {
            newPerformCheckDic.delete(performID)
            setPerformCheckDic(newPerformCheckDic)
        }
    }

    const handleSearchMarketerEnter = (e) => {
        if (e.key == "Enter") {
            handleSearchMarketer()
        }
    }

    const handleOpenBatchUpdateModal = () => {
        if (performCheckDic.getLength() > 0) {
            setShowBatchUpdate(true)
        } else {
            alert("실적을 선택해주세요.")
        }
    }

    const handleCreateExcel = () => {
        // CreateExcelFile()
    }

    const handleOpenAddCalcModal = () => {
        if (performCheckDic.getLength() > 0) {
            setShowCalcAdd(true)
        } else {
            alert("실적을 선택해주세요.")
        }
    }

    const handleCloseAddCalcModal = () => {
        if (performCheckDic.getLength() > 0) {
            reloadPerforms()
            setShowCalcAdd(false)
        }
    }

    const handleOpenCalcListModal = (perform) => {
        setSelectedPerformID(perform)
        setShowCalcList(true)
    }

    const handleExcelDownload = async () => {
        let pagination = new PerformList()
        let _rows = []

        if (performCheckDic.getLength() > 0) {
            _rows = performCheckDic.getValues()
        } else {
            setExcelLoading(true)
            pagination = await getItemPerforms(itemID, 1, itemCount, filterDic.data)
            if (!Utils.isNullOrUndefined(pagination.performs)) {
                _rows = pagination.performs
            }
        }

        if (_rows.length === 0) {
            alert("데이터가 없습니다.")
            return
        }

        _rows = updateRowsStaticValue(_rows)
        PerformUtils.excelPerform(selectedItem, columns, _rows)
        setExcelLoading(false)
    }

    const handleRemoveFilteredCalc = () => {
        setFilteredCalc(new PerformCalculate())
    }

    return (
        <ContentContainer full_height>
            <PerformListControlBox>
                <PerformListControlSection>
                    <FilterInputText
                        value={searchMarketerValue}
                        setValue={setSearchMarketerValue}
                        submit={() => {
                            handleSearchMarketer()
                        }}
                        width={"52"}
                        placeholder={BrandItemMarketerSearchOptionNames[searchMarketerOptionType]}
                        textOption={searchMarketerOptionType}
                        setTextOption={setSearchMarketerOptionType}
                        textOptionTypes={BrandItemMarketerSearchOptionTypes}
                        textOptionNames={BrandItemMarketerSearchOptionNames}
                        textOptionWidth={"16"}
                    />
                    <MyPagePerformFilterShortcut
                        columns={columnFilterShortcuts}
                        filterDic={filterDic}
                        addFilter={handleAddFilter}
                        removeFilter={handleRemoveFilter}
                    />
                    <PerformTableFilterButton
                        onClick={() => {
                            setShowFilter(!showFilter)
                        }}
                    >
                        <PerformTableFilterIcon />
                    </PerformTableFilterButton>
                </PerformListControlSection>
                {/* <PerformListControlSection className="max-lg:hidden lg:flex max-xl:justify-end">
                    <Pagination
                        page={pageIndex}
                        setPage={handlePageIndex}
                        pageSize={pageSize}
                        itemCount={itemCount}
                        maxPageButtons={5}
                        styleType={PaginationStyles.NoneBGCircleButtons}
                    />
                </PerformListControlSection> */}
            </PerformListControlBox>

            <PerformTableContainer>
                <PerformTableSort sortVisible={sortVisible} ref={tableRef}>
                    <PerformTableSortTop>
                        <PerformTableFilterBox>
                            <FilterTags
                                filterDic={filterDic}
                                columnDic={columnDic}
                                removeFilter={handleRemoveFilter}
                                filteredCalc={filteredCalc}
                                removeFilteredCalc={handleRemoveFilteredCalc}
                            />
                        </PerformTableFilterBox>
                        <div className="flex flex-shrink-0 items-center">
                            {AccessRules.performManagement(selectedItem, user) && (
                                <PerformBatchUpdateButton
                                    onClick={() => {
                                        handleOpenAddCalcModal()
                                    }}
                                >
                                    일괄 정산
                                </PerformBatchUpdateButton>
                            )}
                            <PerformExcelButton
                                onClick={() => {
                                    handleExcelDownload()
                                }}
                                disabled={selectedItem.editStateType !== EditStateTypes.WAIT}
                            >
                                <ExcelIcon />
                            </PerformExcelButton>
                            {AccessRules.performCreate(user) && (
                                <React.Fragment>
                                    {/* <PerformExcelButton onClick={() => {handleCreateExcel()}}><ExcelIcon/></PerformExcelButton> */}
                                    {/* 어느 상태에서만 비활성화 할지 확인 필요 */}
                                    <PerformBatchUpdateButton
                                        onClick={() => {
                                            handleOpenBatchUpdateModal()
                                        }}
                                        disabled={selectedItem.editStateType !== EditStateTypes.WAIT}
                                    >
                                        일괄 수정
                                    </PerformBatchUpdateButton>
                                </React.Fragment>
                            )}
                            <PerformAddButton
                                onClick={() => {
                                    handleOpenPerformUpdate()
                                }}
                                disabled={selectedItem.editStateType !== EditStateTypes.WAIT}
                            >
                                실적 등록
                            </PerformAddButton>
                        </div>
                    </PerformTableSortTop>
                    <PerformTableSortBottom sortVisible={sortVisible}>
                        <PerformTableSortInput type={"text"} className="border" placeholder="인풋 테스트" />

                        <PerformTableSortInput type={"date"} className="border" />

                        {/* <PerformTableSortSelect>
                            <option>드롭다운</option>
                            <option>드롭다운</option>
                        </PerformTableSortSelect> */}
                    </PerformTableSortBottom>
                </PerformTableSort>

                {/* 모바일에서는 다 채우는게 아닌 밑으로 쭉 스크롤한다. */}
                <div className="relative w-full lg:h-full">
                    {rawColumns.length > 0 && (
                        <MyPagePerformTable
                            user={user}
                            columns={columns}
                            rows={rows}
                            filterDic={filterDic}
                            setEditComponent={[showEditComponent, closeEditComponent]}
                            showUpdateComponent={handleOpenPerformUpdate}
                            showViewerComponent={showViewerComponent}
                            performCheck={handlePerformCheck}
                            openCalcListModal={handleOpenCalcListModal}
                        />
                    )}
                </div>
            </PerformTableContainer>

            <PerformListControlSection className="relative flex flex-shrink-0 h-10 mt-2">
                <PaginationSize pageSize={pageSize} setPageSize={handlePageSize} />
                <div className="absolute absolute-center h-full">
                    <Pagination
                        page={pageIndex}
                        setPage={handlePageIndex}
                        pageSize={pageSize}
                        itemCount={itemCount}
                        maxPageButtons={5}
                        styleType={PaginationStyles.NoneBGCircleButtons}
                    />
                </div>
            </PerformListControlSection>

            <ModalContainer
                show={showUpdate}
                setShow={setShowUpdate}
                fadeDown
                isBlur
                unMount={false}
                closeByBackground
                mobileScreenHeight={RESPONSIVE_WIDTH.LG}
            >
                <MyPagePerformUpdate
                    user={user}
                    selectedItem={selectedItem}
                    columns={columns}
                    perform={selectedPerform}
                    isShow={showUpdate}
                    close={handleClosePerformUpdate}
                    showViewerComponent={showViewerComponent}
                />
            </ModalContainer>
            <ModalContainer show={showEdit} setShow={setShowEdit} fadeDown isBlur closeByBackground>
                {editComponent}
            </ModalContainer>
            <ModalContainer show={showViewer} setShow={setShowViewer} fadeDown closeButton={false}>
                {viewerComponent}
            </ModalContainer>
            {/* 여기 */}
            <ModalContainer show={showFilter} setShow={setShowFilter} fadeDown closeByBackground unMount={false}>
                <MyPagePerformFilter
                    columns={columns}
                    filterDic={filterDic}
                    addFilter={handleAddFilter}
                    removeFilter={handleRemoveFilter}
                />
            </ModalContainer>
            <ModalContainer show={showBatchUpdate} setShow={setShowBatchUpdate} fadeDown isBlur>
                <MyPagePerformBatchUpdate
                    user={user}
                    columns={columns}
                    performCheckDic={performCheckDic}
                    reloadPerforms={reloadPerforms}
                    offModal={() => {
                        setShowBatchUpdate(false)
                    }}
                />
            </ModalContainer>
            <ModalContainer show={showCalcAdd} setShow={setShowCalcAdd} fadeDown isBlur>
                <MyPagePerformCalcAdd
                    selectedItem={selectedItem}
                    performCheckDic={performCheckDic}
                    closeAddCalcModal={() => {
                        handleCloseAddCalcModal()
                    }}
                />
            </ModalContainer>
            <ModalContainer show={showCalcList} setShow={setShowCalcList} fadeDown isBlur closeByBackground>
                <MyPagePerformCalcList selectedItem={selectedItem} selectedPerformID={selectedPerformID} />
            </ModalContainer>

            <SpinLoading
                isLoading={isLoading || isColumnRendering || isPerformRendering}
                maxWindowWidth={RESPONSIVE_WIDTH.LG}
            />
            <SpinLoading isLoading={isExcelLoading} message={"엑셀 데이터를 불러오는 중입니다."} />
        </ContentContainer>
    )
}

const FilterTag = ({ column, filterValue, removeFilter }) => {
    const [value, setValue] = useState("")
    useEffect(() => {
        if (column.formType === PerformColumnFormTypes.COMBO) {
            const comboName = getComboName(column, filterValue.comboID)
            setValue(comboName)
        } else if (column.formType === PerformColumnFormTypes.TEXT) {
            setValue(filterValue.text)
        } else if (column.formType === PerformColumnFormTypes.STATUS) {
            const statusName = getStatusName(column, filterValue.statusID)
            setValue(statusName)
        } else if (column.formType === PerformColumnFormTypes.DATE) {
            setValue(filterValue.text)
        } else if (column.formType === PerformColumnFormTypes.OPTION) {
            let optionNameList = []
            filterValue.optionItems.map((optionItem) => {
                const columnOption = column.options.find((s) => s.id === optionItem.optionID)
                let columnOptionName = ""
                if (columnOption && !Utils.isStringNullOrEmpty(columnOption.name)) {
                    columnOptionName = columnOption.name
                }
                if (!Utils.isStringNullOrEmpty(optionItem.etc)) {
                    columnOptionName += `:${optionItem.etc}`
                }

                optionNameList.push(columnOptionName)
            })

            setValue(optionNameList.join(","))
        }
    }, [filterValue])

    const handleCancle = () => {
        removeFilter(column.id)
    }

    return (
        <FilterTagBox columnType={column.type}>
            <FilterTagColumnName>{column.name}</FilterTagColumnName>
            <FilterTagValue>{value}</FilterTagValue>
            <FilterTagCancleButton
                onClick={() => {
                    handleCancle()
                }}
            >
                <FilterTagCancleIcon />
            </FilterTagCancleButton>
        </FilterTagBox>
    )
}

const FilterCalcTag = ({ filteredCalc, removeFilteredCalc }) => {
    const getColumn = () => {
        const column = new PerformColumn()
        column.id = -1
        column.type = PerformColumnTypes.CALCULATE
        column.formType = PerformColumnFormTypes.DATE
        column.name = "실적"

        return column
    }

    const getFilterValue = () => {
        const filterValue = new PerformValueText()
        filterValue.text = filteredCalc.name

        return filterValue
    }

    const removeFilter = (i) => {
        removeFilteredCalc()
    }

    return <FilterTag column={getColumn()} filterValue={getFilterValue()} removeFilter={removeFilter} />
}

const FilterTags = ({ filterDic, columnDic, removeFilter, filteredCalc, removeFilteredCalc }) => {
    return (
        <FilterTagList>
            {filterDic.getKeys().map((filterKey, idx) => {
                if (columnDic.contains(filterKey.toString())) {
                    const column = columnDic.getValue(filterKey.toString())
                    const filterValue = filterDic.getValue(filterKey.toString())
                    return <FilterTag column={column} filterValue={filterValue} removeFilter={removeFilter} key={idx} />
                }
            })}
            {filteredCalc.id >= 0 && (
                <FilterCalcTag filteredCalc={filteredCalc} removeFilteredCalc={removeFilteredCalc} />
            )}
        </FilterTagList>
    )
}
