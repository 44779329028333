import { Routes,Outlet } from 'react-router-dom';
import React, { useCallback } from 'react'
import {Nav} from '../components/navigation/Nav'
import {Footer} from "../components/navigation/Footer"
import {NavBox}from '../layouts/Box'
import {useState,useRef,useEffect} from 'react';
import NavMobileBottom from '../components/navigation/NavMobileBottom';

const Router = ({isTop}) => {
    // const [navSize,setNavSize] = useState(1)
    
    // useEffect(() => {   
    //     // console.log(isTop) 
    //     // console.log(navSize)
    //     if (isTop) {
    //         setNavSize(1)
    //     }
    //     else{
    //         setNavSize(0)
    //     }
    // }, [isTop])
  
    return (
        <React.Fragment>
            <Nav />
            {/* <div className={'h-10'}/> */}
            <div 
                id="router-outlet"
                className={`flex flex-col justify-between w-full duration-200 bg-nightblue-10`}
            >
                <Outlet />
                <Footer/>
            </div>
            <NavMobileBottom />
        </React.Fragment>
    );
    };
    
    export default Router;