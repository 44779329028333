import React, { useState, useEffect } from "react"
import tw from "tailwind-styled-components"
import { MyPageInputModalLayout } from "../../pages/myPage/MyPageMainStyle"
import {
    MarketerMatchingMenuBox,
    MarketerMatchingSettingConfirmButton,
    MarketerMatchingSettingLayout,
    MarketerMatchingSettingSubTitle,
    MarketerMatchingSettingTitleBox,
} from "../../pages/myPage/item/brand/MyPageItemBrandStyle"
import { BrandItemInput } from "../../components/brandItems/Inputs"
import Utils from "../../utils/Utils"

const StaffItemsContractWriterLayout = tw(MyPageInputModalLayout)`
    max-sm:w-[95vw] sm:w-[640px]
    max-sm:h-[90vh] sm:h-[60vh] bg-slate-50 p-6
`

const StaffItemsContractWriterTitle = tw.div`
    w-full h-14 flex items-center
`

const MenuBox = tw(MarketerMatchingMenuBox)`
    [&>h3]:!mb-0 h-auto justify-center
`

const SigunguModal = ({ title, sigunguAll, selectSigungu, selectedSigungu, isShow, setShow }) => {
    const [sidos, setSidos] = useState([])
    const [selectedSido, setSelectedSido] = useState("")
    const [sigungus, setSigungus] = useState([])

    useEffect(() => {
        setSidos(sigunguAll.filter((s) => Utils.isNullOrUndefined(s.sigungu)))
    }, [sigunguAll])

    // useEffect(() => {
    //     setSigungus(sigunguAll.filter((s) => s.sido === selectedSido.sido && !Utils.isNullOrUndefined(s.sigungu)))
    // }, [selectedSido])

    const handleSido = (sido) => {
        setSelectedSido(sido)

        if (Utils.isNullOrUndefined(sido.sigungu)) {
            selectSigungu(sido)

            const _sigungus = sigunguAll.filter((s) => s.sido === sido.sido && !Utils.isStringNullOrEmpty(s.sigungu))
            setSigungus(_sigungus)
            if (_sigungus.length === 0) {
                setShow(false)
            }
        }
    }

    const handleSigungu = (sigungu) => {
        selectSigungu(sigungu)
        setShow(false)
    }

    return (
        <StaffItemsContractWriterLayout>
            <StaffItemsContractWriterTitle className="text-xl font-medium mb-4">{title}</StaffItemsContractWriterTitle>
            {/* 설정 부분 */}
            <div className="flex w-full h-full max-h-full lg:space-x-2 pt-4 pb-8 border-t">
                {/* [데스크탑] 단계 선택 */}
                <div className="flex flex-col w-72 h-full space-y-2 overflow-y-auto pr-6 border-r border-slate-200">
                    {sidos.map((sido, index) => (
                        <MenuBox
                            key={index}
                            isSelected={selectedSido === sido}
                            onClick={() => {
                                handleSido(sido)
                            }}
                        >
                            <h3>{sido.sido}</h3>
                        </MenuBox>
                    ))}
                </div>
                {/* 설정 화면 */}
                <div className="relative flex flex-col w-full h-full px-2">
                    <div className="absolute grid max-sm:grid-cols-1 sm:grid-cols-2 w-full max-h-full px-2 gap-4 overflow-y-scroll scroll-transparent">
                        {sigungus.map((sigungu, index) => (
                            <MenuBox
                                key={index}
                                isSelected={selectedSigungu === sigungu}
                                onClick={() => {
                                    handleSigungu(sigungu)
                                }}
                            >
                                <h3 className="!font-normal">{sigungu.sigungu}</h3>
                            </MenuBox>
                        ))}
                    </div>
                </div>
            </div>
        </StaffItemsContractWriterLayout>
    )
}

export default SigunguModal
