import LogoBig from '../../static/logo-3.png'
import LogoSmall from '../../static/logo_small.png'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserProfileDropDown } from './Dropdown'
import { useUserStore } from '../../zustand/store'
import { PmsNavState, PmsOfficeNavState } from '../../types/DataEnum'
import { ImageBase64 } from "../../components/ImageContainer";
import { BrandIcon, CalIcon, GroupIcon, NotiFicationIcon, HomeIcon, ItemIcon, ItemManageBoxIcon, ItemManageIcon, Logo_img, MarketerIcon, MyPageCon, MyPageInner, NavCon, NavFoldBtn, NavFoldIcon, NavInnerCon, NavLinkStyle, NoticeIcon, PerformIcon, VerticalLinkText, VerticalNavBoxcon, VerticalNavcon, VerticalNavLink, VerticalNavCategoryTitle, MyPageVerticalNavIconContainer, VerticalNavCategoryBox, VerticalNavCategoryContent, VerticalNavLinkHead, VerticalSubNavLink, VerticalSubNavLinkHead, VerticalSubNavCategoryContent, VerticalNavBr, NavMenuIcon, NavCloseIcon } from './MyPageNavStyle';
import { MyPageNavContents } from './MyPageNavContents';
import { isMobile } from 'react-device-detect';
import {SideBarMenu} from './SideBarMenu'

export function MyPageNavHorizontal(props) {
    const [loginShow, setLoginShow] = useState(false)
    const { userType } = useUserStore(state => state)

    useEffect(() => {
    }, [loginShow]);

    return (
        <NavCon ref={props.MyPageNavRef}>
            <div className='h-full w-screen flex align-middle justify-center'>
                <div className="w-full h-full flex">
                    <NavInnerCon navlogocon="true">
                        <NavFoldBtn onClick={() => { props.setFolded(!props.folded) }} className="duration-300 mr-4">
                            {props.folded ? (
                                <NavMenuIcon />
                            ) : (
                                <NavFoldIcon />
                            )}
                        </NavFoldBtn>
                        <Link to={{ pathname: `/` }}>
                            <Logo_img className='max-lg:hidden lg:block py-1' src={LogoBig} alt={null} />
                            <Logo_img className='w-6 h-6 max-lg:block lg:hidden' src={LogoSmall}/>
                        </Link>
                    </NavInnerCon>
                    <NavInnerCon>
                        <NavLinkStyle to={"/items"}><ItemIcon /></NavLinkStyle>
                        <NavLinkStyle to={"/notice"}><NoticeIcon /></NavLinkStyle>
                        <UserProfileDropDown />
                        <div className='text-gray-500 max-lg:block lg:hidden duration-300 ml-4' onClick={() => {props.setIsSideMenuOpen(!props.isSideMenuOpen)}}>
                            {props.isSideMenuOpen ? (
                                <NavCloseIcon />
                            ) : (
                                <NavMenuIcon />
                            )}
                        </div>
                    </NavInnerCon>
                </div>
            </div>
        </NavCon>
    )
}

export function MyPageNavVertival({folded, navPostId, setFolded, officeNavState, pmsNavState, selectedItem, children}) {
    return (
        <MyPageCon>
            <div className='max-lg:hidden lg:block fixed top-0'>
                <MyPageNavContents folded={folded} pmsNavState={pmsNavState} selectedItem={selectedItem} officeNavState={officeNavState}/>
            </div>
            <VerticalNavBoxcon isfold={folded} />
            <MyPageInner isfold={folded}>
                {children}
            </MyPageInner>
        </MyPageCon>
    )
}