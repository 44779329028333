import tw from "tailwind-styled-components"
import { BodyInnerBox, BoxMaxMin, ContainerPaddingTop } from "../../layouts/Box"
import styled from "styled-components"
import { MyPageContentRowHeight } from "../myPage/MyPageMainStyleParameter"

export const NoticeCon = tw(ContainerPaddingTop)`
    flex-col items-center justify-normal h-auto
`
export const NoticeTitle = tw.div`
    flex flex-center w-full h-40
    [&>span]:text-3xl
`

export const NoticeBoxInner = tw(BoxMaxMin)`
    flex flex-col items-center w-full
`

export const NoticeTableCon = tw.div`
    w-full bg-white rounded-xl overflow-hidden drop-shadow-lg
`

export const NoticeFilterContainer = tw.div`
    flex justify-between items-center w-full max-sm:px-2 py-2 mb-1
`

export const NoticeSearchOption = tw.select`
    w-24 flex justify-center
`

// export const NoticeOption = tw.div`
//     h-full w-4/12 flex justify-center items-center rounded-full cursor-pointer text-lg
//     ${(props) => props.is_option_selected ? "bg-slate-200" : "bg-white"}
// `

export const NoticeBody = tw.div`
    h-full w-full flex flex-col items-center mb-12
`
export const NoticeTable = tw.div`
    w-full border-y border-gray-600 
`

export const NoticeTableHeaderBox = tw.div`
    flex w-full border-b-2 border-gray-300
`

export const NoticeTableHeader = tw.div`
    max-sm:flex sm:flex flex-center max-sm:text-sm sm:text-lg max-sm:h-9 sm:h-10
    max-sm:bg-white
`

export const NoticeListBody = tw.div` 
    w-full flex flex-col
`

export const NoticeListRow = tw.div`
    flex
    w-full items-center py-2
    max-lg:max-h-auto lg:max-h-auto
    hover:bg-slate-100 border-b cursor-pointer
`

export const NoticeListData = tw.div`
    max-sm:flex sm:flex max-sm:items-center sm:items-center h-auto px-2 max-sm:text-sm sm:text-base
`
export const NoticeListDataResponsiveBox = tw.div`
    flex max-sm:flex-col sm:flex-row w-full max-sm:pr-2
`

export const NoticePagination = tw.div`
    w-full py-2 flex items-center justify-between
`

export const NoticeAddButton = tw.button`
    px-5 bg-nightblue-400 py-1 text-white rounded-lg max-sm:mr-2
`
