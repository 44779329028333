import React, { useEffect, useState } from "react"
// import {BarChart,DonutCahrt} from './ChartGraph'
import { ModalContainer } from "../../../components/ModalContainer"
import { BarChart, PieChart } from "./ApexChartGraph"
import { PmsNavState } from "../../../types/DataEnum"
import {
    ArrowUpIcon,
    DashboardGrid,
    DashboardCon,
    TableCon,
    HomeCon,
    ArrowDownIcon,
    TableHeaderCon,
    TableHeader,
    TableBodyCon,
    TableBodyRow,
    TableData,
    DashboardPerformCon,
    DashboardPerform,
    StatContainer,
    StatWrapper,
    StatTitle,
    StatNumber,
    StatChangeWrapper,
    StatChangeIcon,
    StatChangeValue,
    StatChangeIconDown,
    StatChangeValueDown,
} from "./MyPageHomeStyle"
import tw from "tailwind-styled-components"

function DashBoardStat({ statTitle, statNum, statChanged, statchanednum }) {
    return (
        <StatContainer>
            <StatWrapper>
                <StatTitle>{statTitle}</StatTitle>
                <div className="absolute-center">
                    <StatNumber>{statNum}</StatNumber>
                    {statChanged ? (
                        <StatChangeWrapper>
                            <StatChangeIcon />
                            <StatChangeValue>{statchanednum}</StatChangeValue>
                        </StatChangeWrapper>
                    ) : (
                        <StatChangeWrapper>
                            <StatChangeIconDown />
                            <StatChangeValueDown>{statchanednum}</StatChangeValueDown>
                        </StatChangeWrapper>
                    )}
                </div>
            </StatWrapper>
        </StatContainer>
    )
}

function MarketerGroup(props) {
    return (
        <React.Fragment>
            <div
                className={`h-full w-full bg-gradient-to-r from-${props.bgcolor}-300 to-${props.bgcolor}-400 rounded-md flex`}
            >
                <div className="w-full h-10"></div>
            </div>
        </React.Fragment>
    )
}

export default function MyPageHome({ setPmsNavState }) {
    const [show, setShow] = useState(true)

    useEffect(() => {
        setPmsNavState(PmsNavState.HOME)
    }, [])

    const barchartdata = {
        category: ["1월", "2월", "3월", "4월", "5월"],
        name: "실적",
        data: [30, 40, 45, 50, 49],
    }

    const piechartdata = {
        series: [44, 55, 41],
        labels: ["SCRM", "세일즈랑", "마커누"],
    }

    return (
        <HomeCon>
            <DashboardGrid>
                <DashboardCon className="row-span-3 col-span-2  p-5 ">
                    {/* 
                    
                        chartdata={
                            category: ['1월','2월','3월','4월','5월'] 막대 그래프에 보여지는 월
                            name:"실적"                             막대에 그려질 정보 이름
                            data:[30, 40, 45, 50, 49]              
                        }

                    */}
                    <BarChart barchartdata={barchartdata} />
                </DashboardCon>
                <DashboardCon className="row-span-3 items-center justify-center p-5">
                    <PieChart piechartdata={piechartdata} />
                </DashboardCon>

                <DashboardCon className=" row-span-2 col-span-2 p-4 ">
                    <div className="w-full h-full grid grid-cols-2 gap-10 ">
                        {/* <MarketerGroup bgcolor={"blue"} />
                        <MarketerGroup bgcolor={"orange"} /> */}
                    </div>
                </DashboardCon>

                <DashboardCon className="row-span-3 ">
                    <TableCon>
                        <TableHeaderCon>
                            <TableHeader className="w-3/12">상품명</TableHeader>
                            <TableHeader className="w-4/12">제목</TableHeader>
                            <TableHeader className="w-3/12">작성일자</TableHeader>
                            <TableHeader className="w-2/12">작성자</TableHeader>
                        </TableHeaderCon>
                        <TableBodyCon>
                            <TableBodyRow>
                                <TableData className="w-3/12">scrm</TableData>
                                <TableData className="w-4/12">테스트 제목</TableData>
                                <TableData className="w-3/12">2023-03-09</TableData>
                                <TableData className="w-2/12">네스타</TableData>
                            </TableBodyRow>
                        </TableBodyCon>
                    </TableCon>
                </DashboardCon>

                <DashboardPerformCon className="col-span-2 drop-shadow-none">
                    <DashboardPerform>
                        <DashBoardStat
                            statTitle={"소속마케터"}
                            statNum={"120명"}
                            statChanged={true}
                            statchanednum={"20명"}
                        />
                    </DashboardPerform>
                    <DashboardPerform>
                        <DashBoardStat
                            statTitle={"실적수"}
                            statNum={"100개"}
                            statChanged={false}
                            statchanednum={"20개"}
                        />
                    </DashboardPerform>
                    <DashboardPerform>
                        <DashBoardStat
                            statTitle={"정산완료"}
                            statNum={"80개"}
                            statChanged={false}
                            statchanednum={"20개"}
                        />
                    </DashboardPerform>
                </DashboardPerformCon>
            </DashboardGrid>
        </HomeCon>
    )
}
