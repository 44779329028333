import User from "../../users/User";
import PerformColumnStatus from "./PerformColumnStatus";
import { PerformColumnFormTypes } from "./PerformColumnTypes";

export default class{
    constructor() {
        this.id = -1
        this.columnID = -1
        this.performID = -1
        this.status = new PerformColumnStatus()
        this.user = new User()
        this.comment = ""
        this.isUpdateAlert = false

        this.created = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.columnID = response["columnID"]
        this.performID = response["performID"]
        this.comment = response["comment"]
        this.isUpdateAlert = false
        if (response["update_alert"] !== undefined) {
            this.isUpdateAlert = response["update_alert"]
        }

        this.user = new User()
        if (response["user"] !== undefined) {
            this.user.convertByResponse(response["user"])
        }
        this.status = new PerformColumnStatus()
        if (response["status"] !== undefined) {
            this.status.convertByResponse(response["status"])
        }

        this.created = response["created"]
    }

    convertToRequest() {
    }
}