import React, { useState, useEffect,useRef } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'
import { Close } from "@styled-icons/evil/Close"
import { useLocation } from 'react-router-dom'
import { MOBILE_MODAL_CONATINER, MODAL_CONATINER } from '../constants/ConstClassName'
import { useWindowHeight } from '../hooks/useWindowHeight'
import { useWindowWidth } from '../hooks/useWindowWidth';

const ContainerMargn = styled.div`
    margin-left: calc(-50vw + 50%);
    top: ${(props) => props.bodyScrollY}px;
    z-index: 100;
`
const Container = tw(ContainerMargn)`
    fixed w-screen left-0 right-0 bottom-0
    ${(props) => props.isFullScreen ? `justify-start h-[${props.windowInnerHeight}px]` : "justify-center h-screen"}
    flex flex-col items-center 
    ${(props) => props.show ? "visible" : "invisible"} duration-200
    will-change-auto
`

const Background = tw.div`
    w-screen h-screen transition-opacity duration-300 absolute z-0
    ${(props) => props.show ? "bg-black/[.5] opacity-100" : "bg-black[.0] opacity-0"}
    ${(props) => (props.show && props.isBlur) && "backdrop-blur-sm"}
    will-change-opacity
`


const ModalBlock = tw.div`
    rounded-md z-10
    ${(props) => props.show ? "fade-in" : ""}
    ${(props) => (props.show && props.fadeUp) && "fade-in-up"}
    ${(props) => (props.show && props.fadeDown) && "fade-in-down"}
    will-change-transform
    will-change-opacity
`


const ModalShowBtn = tw.button`
    cursor-pointer w-fit flex justify-center

`

const Contents = tw.div`
    flex flex-col itemts-center w-full relative
    ${(props) => props.isFullScreen && `h-[${props.windowInnerHeight}px]`}
`

const CloseBtn = tw.button`
    absolute right-3 top-3 w-8 h-8 rounded-md hover:bg-gray-300/50 flex flex-center z-20
`

const CloseIcon = tw(Close)`
    w-6 h-6
`


export const CloseButton = ({close}) => {
    return (
        <CloseBtn onClick={() => { close(false); }}>
            <CloseIcon />
        </CloseBtn>
    )
}

export const ModalContainer = ({ 
    show,
    setShow,
    children,
    isBlur = false,
    fadeUp = false,
    fadeDown = false,
    closeButton = true,
    closeByBackground = false,
    unMount = true,
    shutDownNavigateChange = false,
    mobileScreenHeight = 0 // 해당 값 window 너비까지는 모바일 화면에서 전체화면을 사용한다. (safari 대응)
}) => {
    const [bodyScrollY, setBodyScrollY] = useState(0)
    const location = useLocation()
    const [previousPathname, setPreviousPathname] = useState(null)

    const windowInnerWidth = useWindowWidth() // 모바일에서 최대사이즈를 이용하기 위해 windowInnerWidth를 이용한다.
    const windowInnerHeight = useWindowHeight() // 모바일에서 최대사이즈를 이용하기 위해 windowInnerHeight를 이용한다.
    const isFullScreen = mobileScreenHeight > 0 && windowInnerWidth < mobileScreenHeight // ScreenSize 사용 여부

    useEffect(() => {
        const documentTop = parseInt(document.body.style.top.replace("px", "")) * -1
        if (show === true) {
            // setBodyScrollY(documentTop > 0 ? documentTop : 0)
            setBodyScrollY(document.body.scrollTop)
            // document.body.style.cssText = `
            //     overflow: hidden 
            // `
            const scrollPosition = window.pageYOffset
            document.body.style.overflow = 'hidden'
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollPosition}px`
            document.body.style.left = '0'
            document.body.style.right= '0'

            return () => {
                const mobileModalContainers = document.getElementsByClassName(MOBILE_MODAL_CONATINER)
                
                // 모달보다 하위인 모바일 모달이 켜져있는 경우 스크롤 방지 속성을 없애지않는다.
                if (mobileModalContainers.length === 0) {
                    document.body.style.removeProperty('overflow')
                    document.body.style.removeProperty('position')
                    document.body.style.removeProperty('top')
                    document.body.style.removeProperty('left')
                    document.body.style.removeProperty('right')
                    window.scrollTo(0, scrollPosition)
                }

                // document.body.style.cssText = `
                //     overflow: unset 
                // `
            }
        }
    }, [show])

    useEffect(() => {
        if(shutDownNavigateChange === true){
            if (previousPathname !== location.pathname) {
                if(previousPathname !== location.pathname){
                    setShow(false)
                }
                setPreviousPathname(location.pathname)
            }
        }
    }, [location.pathname, previousPathname])
    
    return (
        <Container show={show} bodyScrollY={bodyScrollY} isFullScreen={isFullScreen} windowInnerHeight={windowInnerHeight}>
            <Background show={show} isBlur={isBlur} onClick={() => { closeByBackground && setShow(false) }} />
            <ModalBlock
                className={`${show && `${MODAL_CONATINER}`}`}
                show={show} 
                fadeUp={fadeUp} 
                fadeDown={fadeDown}
            >
                {/* unMount 속성 비활성화 시 컴포넌트가 항상 켜져있다 */}
                {unMount ? show && (
                    <React.Fragment>
                        <Contents isFullScreen={isFullScreen} windowInnerHeight={windowInnerHeight}>
                            {children}
                            {closeButton && (
                                <CloseButton close={()=>{setShow(false)}} />
                            )}
                        </Contents>
                    </React.Fragment>
                ) : (
                    <div className={`${show ? "opacity-100" : "opacity-0 -z-10"} `}>
                        <div id="calendar-container" className='absolute bg-white top-[-20px]'></div>
                        <Contents isFullScreen={isFullScreen} windowInnerHeight={windowInnerHeight}>
                            {children}
                            {closeButton && (
                                <CloseButton close={()=>{setShow(false)}} />
                            )}
                        </Contents>
                    </div>
                )}
            </ModalBlock>
        </Container>
    )
}