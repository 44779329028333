import tw from 'tailwind-styled-components'
import {Telephone} from '@styled-icons/bootstrap/Telephone'
import {Location} from '@styled-icons/ionicons-outline/Location'
import {Email} from '@styled-icons/material-outlined/Email' 
import { NOT_SCROLLABLE } from '../../../constants/ConstClassName'

export const TelephoneIcon = tw(Telephone)`
    h-4 mr-1 text-nightblue-600
`
export const LocationIcon = tw(Location)`
    h-4 mr-1 text-nightblue-600
`
export const EmailIcon = tw(Email)`
    h-4 mr-1 text-nightblue-600
`

export const MyPageMarketerDetailModalContainer = tw.div`
    max-lg:w-screen max-lg:h-full lg:w-156 lg:h-156 bg-blue-50 rounded-lg flex max-lg:flex-col lg:flex-row
`

export const MyPageMarketerUserSideContainer = tw.div`
    max-lg:w-full lg:w-40 flex flex-col items-center 
`

export const MyPageMarketerUserAvatarContainer = tw.div`
    w-32 mt-4 overflow-hidden rounded-full 
`
export const MarketerUserSidePerformContainer = tw.div`
    w-full flex-grow mt-2  flex flex-col px-3 items-center
` 

export const MarketerUserSideNameContainer = tw.div`
    w-full h-14 flex justify-center items-center
`
export const MarketerUserNameText = tw.div`
    text-xl font-semibold
`
export const MarketerUserBaicTitle = tw.span`
    font-semibold text-xl text-nightblue-800
`

export const MarketerUserCareerContainer = tw.div`
    flex-grow w-full flex flex-col
`
export const MarketerUserCareerLayout = tw.div`
    w-full h-full flex flex-col p-4 bg-white rounded-lg
`

export const MarketerUserCareerDataLayout = tw.div`
    w-full flex-grow py-2 overflow-scroll scroll-transparent scroll-overlay
`

export const MarketerUserCareerTitle = tw.span`
    font-semibold text-xl text-nightblue-800
`

export const MarketerUserDataContainer = tw.div`
    flex-grow h-full flex flex-col  max-lg:p-3 lg:py-4 lg:pr-4 overflow-scroll scroll-transparent ${() => NOT_SCROLLABLE}
`
export const MarketerUserBasicDataContainer = tw.div`
    w-full h-full flex flex-col p-4 bg-white rounded-lg
`

export const MarketerDataTextsKey = tw.span`
    text-sm font-semibold text-slate-700 
`
export const MarketerDataTextsValue = tw.span`
    text-sm  text-slate-700 
`
export const MarketerUserCareerInfoContainer = tw.div`
    flex-grow h-20 relative ml-2
`

export const MarketerDataTextsLayout = tw.div`
    flex flex-col justify-between w-full flex-grow
`
export const MarketerDataTextsContainer = tw.div`
    flex justify-between w-full 
`

//career
export const CareerSideLine = tw.div`
    flex flex-col w-1 h-full bg-gray-400
`
export const CareerDataContainer = tw.div`
    flex flex-col justify-start flex-grow h-full ml-5  
`
export const CareerSideOuterCircle = tw.div`
    absolute top-0.5 transform -translate-x-1/2 h-5 w-5 left-[2.5px] rounded-full bg-nightblue-600
`
export const CareerSideInnerCircleLayout = tw.div`
    flex items-center justify-center w-full h-full 
`
export const CareerSideInnerCircle = tw.div`
    w-3 h-3 rounded-full bg-white
`
export const ContractPaperShowButton = tw.button`
    py-1 px-4 border border-blue-400 rounded-md hover:bg-blue-100 mb-4
    text-blue-600
`