import react, {useState, useEffect, useCallback} from "react"

export const useGettingWidth = () => {
    const [width, setWidth] = useState(false)

    const ref = useCallback((node) => {
        if (node !== null) {
            setWidth(node.getBoundingClientRect().width)
        }
    }, [])

    useEffect(()=>{
    }, [])

    return [width, ref]
}