import React, { useState, useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { ItemOrders } from "../../constants/ConstItem"
import { ItemSearchTypes } from "../../types/brandItems/BrandItemTypes"
import { SortTypes } from "../../types/EditorTypes"
import ItemDetail from "./ItemDetail"
import Items from "./Items"
import ItemDetailPreivew from "./ItemDetailPreview"
import BrandItem from "../../types/brandItems/BrandItem"
import { useUserStore } from "../../zustand/store"
import ItemMarketerMatchingPage from "./ItemMarketerMatchingPage"

const AdItemRouter = () => {
    const { user } = useUserStore()

    const [categoryFilter, setCategoryFilter] = useState(null)
    const [sigunguFilter, setSigunguFilter] = useState(null)

    const [itemOrder, setItemOrder] = useState(ItemOrders.CREATED)
    const [itemSortType, setItemSortType] = useState(SortTypes.DESC)
    const [pageSize, setPageSize] = useState(20)
    const [pageIndex, setPageIndex] = useState(1)
    const [itemCount, setItemCount] = useState(0)

    const [items, setItems] = useState([])

    const [searchType, setSearchType] = useState(ItemSearchTypes.TITLE_ONLY)
    const [searchTypeName, setSearchTypeName] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [searchKeyword, setSearchKeyword] = useState("")

    useEffect(() => {
        return () => {}
    }, [])

    return (
        <Routes>
            <Route
                path=""
                element={
                    <Items
                        user={user}
                        categoryFilter={categoryFilter}
                        setCategoryFilter={setCategoryFilter}
                        sigunguFilter={sigunguFilter}
                        setSigunguFilter={setSigunguFilter}
                        itemOrder={itemOrder}
                        setItemOrder={setItemOrder}
                        itemSortType={itemSortType}
                        setItemSortType={setItemSortType}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        itemCount={itemCount}
                        setItemCount={setItemCount}
                        items={items}
                        setItems={setItems}
                        searchType={searchType}
                        setSearchType={setSearchType}
                        searchTypeName={searchTypeName}
                        setSearchTypeName={setSearchTypeName}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchKeyword={searchKeyword}
                        setSearchKeyword={setSearchKeyword}
                    />
                }
            />
            <Route path="/:itemID" element={<ItemDetail />} />
        </Routes>
        // <BodyInnerBox>
        //     <SignUpLayout>
        //     </SignUpLayout>
        // </BodyInnerBox>
    )
}

export default AdItemRouter
