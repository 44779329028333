import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
    Container,
    HomeIcon,
    Layout,
    MyPageIcon,
    NavButton,
    NavButtonDiv,
    NavButtonTitle,
    NavIconContainer,
    NoticeIcon,
    SearchIcon,
} from "./NavMobileBottomStyle"
import { useLoginModalStore } from "../../zustand/modalstore"
import { useUserStore } from "../../zustand/store"
import Utils from "../../utils/Utils"
import { UserTypes } from "../../types/users/UserTypes"
import { ItemAdBoxIcon, ItemManageIcon } from "./MyPageNavStyle"

// 해당 주소에서는 모바일 하단 내비가 보이지 않는다.
const DISALLOW_URLS = ["/apply/", "/items/", "/signup"]

const NavMobileBottom = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { userType } = useUserStore((state) => state)
    const setLoginShow = useLoginModalStore((state) => state.setLoginShow)

    const [isHide, setHide] = useState(false)

    useEffect(() => {
        let _isHide = false
        for (let i = 0; i < DISALLOW_URLS.length; i++) {
            if (pathname.includes(DISALLOW_URLS[i])) {
                _isHide = true
                break
            }
        }
        setHide(_isHide)
    }, [pathname])

    const handleMyPage = () => {
        if (!Utils.isNullOrUndefined(userType) && userType !== UserTypes.NONE) {
            navigate("/mypage/item")
        } else {
            setLoginShow(true)
        }
    }

    return (
        <Container isHide={isHide}>
            <Layout>
                {/* <NavButton to={{ pathname: `/` }}>
                    <NavIconContainer>
                        <HomeIcon />
                    </NavIconContainer>
                    <NavButtonTitle>홈</NavButtonTitle>
                </NavButton> */}
                <NavButton to={"/items"}>
                    <NavIconContainer>
                        <ItemManageIcon />
                    </NavIconContainer>
                    <NavButtonTitle>영업아이템</NavButtonTitle>
                </NavButton>
                <NavButton to={"/ads"}>
                    <NavIconContainer>
                        <ItemAdBoxIcon />
                    </NavIconContainer>
                    <NavButtonTitle>홍보아이템</NavButtonTitle>
                </NavButton>
                <NavButton to={"/notice"}>
                    <NavIconContainer>
                        <NoticeIcon />
                    </NavIconContainer>
                    <NavButtonTitle>공지사항</NavButtonTitle>
                </NavButton>
                <NavButtonDiv
                    onClick={() => {
                        handleMyPage()
                    }}
                >
                    <NavIconContainer>
                        <MyPageIcon />
                    </NavIconContainer>
                    <NavButtonTitle>마이페이지</NavButtonTitle>
                </NavButtonDiv>
            </Layout>
        </Container>
    )
}

export default NavMobileBottom
