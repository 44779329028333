import Paginator from "../Paginator";
import BoardNotice from "./BoardNotice";

export default class extends Paginator{
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.items = []
        const resultsResponse = response["results"]
        if (resultsResponse !== null && resultsResponse !== undefined) {
            resultsResponse.map(result => {
                let item = new BoardNotice()
                item.convertByResponse(result)
                this.items.push(item)
            })
        }
    }
}