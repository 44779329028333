import tw from 'tailwind-styled-components'
import { EditStateTypes } from "../../types/EditorTypes";

export const MyPageInputLayout = tw.div`
flex flex-col
`
export const MyPageInputLabel = tw.div`
text-gray-800
`
export const MyPageInputLocationLabel = tw(MyPageInputLabel)`
  text-sm
`

export const MyPageInputBox = tw.div`
flex items-center px-2 h-10 w-full my-2 relative
rounded-md border 
${(props) => (props.readOnly || props.disabled) ? "bg-gray-50" : "bg-white"} 
${(props) => props.isFocus && "border-nightblue-400"} 
duration-200

${(props) => props.editStateType === EditStateTypes.DONE && "border-green-600"} 
${(props) => props.editStateType === EditStateTypes.FAILED && "border-red-600"} 

`
export const MyPageInput = tw.input`
w-full
bg-transparent
`
export const MyPageInputChildContainer = tw.div`
flex justify-end absolute right-1
`