import { getImageFile, uploadImageBase64 } from "../apis/api/files"
import { IMAGE_SOURCE_DUMMY, IMAGE_SOURCE_TEMPLATE } from "../constants/ConstFile"
import { Dictionary } from "../types/Dictionary"
import Utils from "./Utils"
import Cheerio from "cheerio"
import { isEqual } from "lodash"
import sanitizeHtml from "sanitize-html"
import dompurify from "dompurify"

const SANITIZE_CONFIG = {
    allowedTags: ["img"],
    allowedAttributes: {
        "*": ["style"],
        img: ["src", "alt", "style"],
    },
    disallowedTags: ["html", "head", "body"],
}
export default class {
    static getOldImageIndexByImageSource(base64) {
        const regex = /data:image;base64;index=([^"]+),/g
        let match
        let oldImageIndex = 0

        while ((match = regex.exec(base64)) !== null) {
            oldImageIndex = match[1]
            return Number(oldImageIndex)
        }

        return oldImageIndex
    }
    // [게시글 WRITE] CKEditor 소스에서 업로드할 이미지를 업로드 후 업로드된 게시글 소스를 반환한다
    static async getContentSourceByImageUploaded(content, oldImageIDDic) {
        const temp = document.createElement("temp")
        temp.innerHTML = content

        const imgs = temp.querySelectorAll("img")
        await Promise.all(
            Array.from(imgs).map(async (img) => {
                if (img.src.includes("data:image/")) {
                    // 새로 업로드 하는 경우
                    const imageFile = await uploadImageBase64(img.src, "temp.png").catch((error) => {
                        console.log(error)
                    })
                    // 새로 업로드한 이미지의 아이디를 넣는다.
                    if (imageFile.id >= 0) {
                        img.className = IMAGE_SOURCE_TEMPLATE.replace("ID", String(imageFile.id))
                    } else {
                        alert("이미지 업로드 실패했습니다.")
                    }
                    // } else if (img.src.includes("data:image;base64;index=")) {
                } else {
                    // 이미지 소스에 저장해놓은 기존 이미지 index를 가져온다.
                    const imageIndex = this.getOldImageIndexByImageSource(img.src)
                    // 이미 업로드된 이미지의 아이디를 넣는다.
                    img.className = IMAGE_SOURCE_TEMPLATE.replace("ID", String(oldImageIDDic.getValue(imageIndex)))
                }
                img.src = ""
            }),
        )

        let result = temp.innerHTML
        if (Utils.isNullOrUndefined(result)) {
            result = ""
        }

        return result
    }
    // [게시글 LOAD] 이미지 ID(템플릿)을 이미지 소스로 변환한다.
    static async convertImageTemplateToSource(setContent, content) {
        const temp = document.createElement("temp")
        temp.innerHTML = content

        const imgs = temp.querySelectorAll("img")
        let oldImageIDDic = new Dictionary() // 추후에 이미지 업로드 시 기존 이미지 ID를 사용하기 위해 저장

        setContent(temp.innerHTML)
        Promise.all(
            Array.from(imgs).map(async (img, index) => {
                const imageTemplate = img.className
                const imageID = Number(imageTemplate.replace("{%IMAGE_", "").replace("%}", ""))
                const image = await getImageFile(imageID)

                if (image.id >= 0) {
                    let indexSource = image.getSource()
                    indexSource = indexSource.replace("data:image;base64,", `data:image;base64;index=${index},`)
                    img.src = indexSource
                    oldImageIDDic.push(index, image.id)
                } else {
                    img.src = ""
                }
                img.className = ""
            }),
        )
        setContent(temp.innerHTML)

        return oldImageIDDic
    }

    static getMediaURL() {
        return process.env.REACT_APP_API_SERVER_URL + "/media/"
    }

    // 서버에 파일 URL은 서버주소를 포함하지 않기 때문에 붙여준다. (리액트에서만 붙여서 쓴다. 백엔드로 다시 보낼때는 제거해야한다.)
    static getMediaFileURL(fileURL) {
        if (Utils.isStringNullOrEmpty(fileURL)) {
            return ""
        }

        return this.getMediaURL() + fileURL
    }
    // 해당 주소가 MediaFileURL인지 확인한다.
    static isMediaFileURL(fileURL) {
        return fileURL.includes(this.getMediaURL())
    }
    // 텍스트 에디터 내용에 있는 파일 소스 URL은 서버주소를 포함하지 않기 때문에 붙여준다.
    static replaceMedieFileURL(content) {
        const cheerio = require("cheerio")
        const bs = cheerio.load(content)

        bs("img").each((i, el) => {
            const oldSrc = bs(el).attr("src")
            const newSrc = this.getMediaURL() + oldSrc
            bs(el).attr("src", newSrc)
        })

        return bs.html()
    }
    // 텍스트 에디터 내용을 안전하게 사용하지 않는 태그들을 제거한다.
    static getSantinizedContent(content) {
        // const sanitized = sanitizeHtml(content, SANITIZE_CONFIG)
        // const sanitized = dompurify.sanitize(content, {FORCE_BODY: true})
        // return sanitized
        return content // CKEditor5는 내용 업데이트 시 자체 sanitze기능이 있다.
    }
    // 텍스트 에디터 내용을 다시 업로드할 때 서버 주소를 다시 제거한다.
    static removeMediaFileURL(content) {
        if (Utils.isStringNullOrEmpty(content)) {
            return content
        }

        return content.replaceAll(this.getMediaURL(), "")
    }
    // 텍스트 에디터 내용을 다시 업로드할 때 서버에 저장용으로 처리한다.
    static getRequestContent(content) {
        let converted = content
        converted = this.removeMediaFileURL(converted)
        converted = this.getSantinizedContent(converted)

        return converted
    }
    static BLANK_CONTENT = "<p><br></p>"
    // 텍스트 에디터의 내용을 비교한다.
    static equalsContent(html1, html2) {
        if (Utils.isStringNullOrEmpty(html1) || Utils.isStringNullOrEmpty(html2)) {
            return false
        }
        const cheerio = require("cheerio")

        // 1. cheerio 모듈을 사용하여 비교할 두 HTML 문자열을 각각 파싱합니다.
        try {
            let sanitized1 = this.getSantinizedContent(html1)
            let sanitized2 = this.getSantinizedContent(html2)

            // 내용을 다시 모두 지운 경우 "<p><br></p>"가 되기 때문에 이런 경우 빈 텍스트로 바꾼다.
            if (sanitized1 === this.BLANK_CONTENT) {
                sanitized1 = ""
            }
            if (sanitized2 === this.BLANK_CONTENT) {
                sanitized2 = ""
            }

            const $1 = cheerio.load(sanitized1)
            const $2 = cheerio.load(sanitized2)

            // 2. 두 DOM 트리에서 순서대로 모든 요소를 가져옵니다.
            const elements1 = $1("*").toArray()
            const elements2 = $2("*").toArray()

            // 4. 두 요소가 다른 경우, 두 요소가 다른 것으로 판별하고 비교를 중단합니다.
            for (let i = 0; i < elements1.length; i++) {
                if (!isEqual(elements1[i], elements2[i])) {
                    return false
                }
            }

            // 5. 모든 요소를 비교한 후에도 동일한 경우, 두 HTML은 동일한 것으로 간주합니다.
            return true
        } catch {
            return true
        }
    }
    static isBlankContent(content) {
        return Utils.isStringNullOrEmpty(content) || content === this.BLANK_CONTENT
    }
    static isFileOverVolume(fileSize, fileSizeMB = 10) {
        // 파일 사이즈가 더 크면 true
        // 파일 사이즈가 더 작으면 false
        const maxFileSize = fileSizeMB * 1024 * 1024
        if (fileSize > maxFileSize) {
            return true
        } else {
            return false
        }
    }
    static getFileSize(file) {
        return file.size
    }
}
