import React, { useEffect, useState, useRef, useCallback } from "react"
import tw from "tailwind-styled-components"
import { getItemPerformValue } from "../../../apis/api/boardItems"
import { ImageViewer } from "../../../components/ImageViewer"
import PerformValueFile from "../../../types/brandItems/performValues/PerformValueFile"
import { PerformFileValueBox, PerformValueBox } from "./MyPagePerformStyle"

import { FileContainer, FileUpload } from "./PerformValueFileCell"
import {
    FileContentBox,
    FileDeleteIconContainer,
    FileDetailBox,
    FileExpandIcon,
    FileGrid,
    FileGridContainer,
    FileIconBlank,
    FileIconDelete,
    FileIconDownload,
    FileIconImage,
    FileIconImageDownload,
    FileImageBox,
    FileImageContainer,
    FileImageDetailBox,
    FileImageDetailIconContainer,
    FileImageDetailIcons,
    FileInfoBox,
    FileInfoControlBox,
    FileLayout,
    FileNameSpan,
    FileUploadIconContainer,
    FileZeroMessageSpan,
} from "./PerformValueFileStyle"
import AccessRules from "../../../rules/AccessRules"
import { deleteFile, downloadFileZip, getCommonFile } from "../../../apis/api/files"
import CommonFile from "../../../types/files/CommonFile"
import { downloadFile } from "../../../apis/api/files"
import { PerformFileTypes } from "../../../types/brandItems/performColumns/PerformColumnTypes"
import { ImageBase64 } from "../../../components/ImageContainer"

export const PerformValueFileUpdate = ({
    downLoadZip,
    setDownLoadZip,
    perform,
    performValue,
    showViewerComponent,
    readOnly,
}) => {
    const [fileValues, setFileValues] = useState([])
    const [fileIDs, setFileIDs] = useState([])
    const updatedRef = useRef(false)

    useEffect(() => {
        getFileValue()
    }, [performValue])

    useEffect(() => {
        if (downLoadZip) {
            handleZipDownload()
        }
    }, [downLoadZip])

    const setUpdated = (b) => {
        updatedRef.current = b
    }

    const getFileValue = () => {
        if (performValue.performID >= 0) {
            // 실적이 등록된 경우 실적에 연결된 파일들을 가져온다.
            getItemPerformValue(performValue.column.id, performValue.performID).then((response) => {
                let newFileValues = []

                response.map((response) => {
                    const newFileValue = new PerformValueFile()
                    newFileValue.convertByResponse(response)
                    newFileValues.push(newFileValue)
                })
                setFileValues(newFileValues)
            })
        } else {
            // 실적이 등록되지 않은 경우 file id를 직접 넘겨주어 파일들을 가져온다.
        }
    }

    const handleViewer = (selectedFileID) => {
        const closeViewer = () => {
            showViewerComponent(false)
        }

        showViewerComponent(
            true,
            <ImageViewer selectedFileID={selectedFileID} fileValues={fileValues} closeViewer={closeViewer} />,
        )
    }

    const getFile = async (fileValue) => {
        if (fileValue.fileID >= 0) {
            let response = await getCommonFile(fileValue.fileID)
            return response
        }
    }

    const handleZipDownload = async () => {
        if (fileValues.length === 0) {
            return
        }
        var files = []
        for (let fileValue of fileValues) {
            var file = await getFile(fileValue)
            files.push({ ...file })
        }
        downloadFileZip(files, `${performValue.column.name}_filedown`)
        setDownLoadZip(false)
    }

    const valueReadOnly = readOnly
    // const valueReadOnly = (readOnly || !AccessRules.performValueWrite(column, perform))

    return (
        <PerformFileValueBox>
            {fileValues.length > 0 ? (
                <FileGridContainer>
                    <FileGrid>
                        {fileValues.map((fileValue, idx) => (
                            <FileContainer
                                fileValue={fileValue}
                                column={performValue.column}
                                perform={perform}
                                key={idx}
                                index={idx}
                                getFileValue={getFileValue}
                                handleViewer={handleViewer}
                                readOnly={valueReadOnly}
                            />
                        ))}
                    </FileGrid>
                </FileGridContainer>
            ) : (
                <FileZeroMessageSpan>파일을 업로드해주세요.</FileZeroMessageSpan>
            )}
            {!valueReadOnly && (
                <FileUpload
                    itemID={performValue.column.itemID}
                    columnID={performValue.column.id}
                    performID={performValue.performID}
                    getFileValue={getFileValue}
                    setUpdated={setUpdated}
                />
            )}
        </PerformFileValueBox>
    )
}

export const PerformValueFileUpdateNonPerform = () => {
    return (
        <PerformValueBox>
            <div className="flex flex-center py-2">
                <div className="px-12 py-4 rounded-lg border border-blue-400 bg-blue-100 [&>span]:text-blue-700">
                    <span>실적 등록 후 업로드해주세요.</span>
                </div>
            </div>
        </PerformValueBox>
    )
}
