import React, { useState } from "react"
import tw from "tailwind-styled-components"
import {
    AgreementText,
    CheckIcon,
    CheckIconContainer,
    CircleCheckIcon,
    SignUpAgreementAllContainer,
    SignUpAgreementAllTextLayout,
    SignUpAgreementContainer,
    SignUpAgreementEachContainer,
    SignUpAgreementEachTextBoxLayout,
    SignUpAgreementEachTextLayout,
    SignUpAgreementModalContainer,
    SignUpAgreementModalDetail,
    SignUpAgreementModalDetailContainer,
    SignUpAgreementModalTitleContainer,
    SignUpAgreementViewButtonLayout,
} from "./SignUpAgreementStyle"
import { ModalContainer } from "../../components/ModalContainer"
import { useEffect } from "react"
import { Usage, Agreement } from "./agreementData"
import { getSystemAgreementFrom } from "../../apis/api/commons"
import { AgreementForm } from "../../types/Agreement"
import ModalContainerPortal from "../../components/ModalContainerPortal"

export function SignUpAgreementModal({ show, setShow, modalData }) {
    return (
        <ModalContainerPortal show={show} setShow={setShow}>
            <SignUpAgreementModalContainer>
                <SignUpAgreementModalTitleContainer>{modalData.title}</SignUpAgreementModalTitleContainer>
                <SignUpAgreementModalDetailContainer>
                    <div className="ck-content" dangerouslySetInnerHTML={{ __html: modalData.detail }} />
                </SignUpAgreementModalDetailContainer>
            </SignUpAgreementModalContainer>
        </ModalContainerPortal>
    )
}

export function SignUpAgreement({ ischeked, setIsChekced, setIsMarketingChecked }) {
    const [allSelected, setAllSelected] = useState(false)
    const [useageSelected, setUsageSelected] = useState(false)
    const [personalInfoSelected, setPersonalInfoSelected] = useState(false)
    const [thirdPartySelected, setThirdPartySelected] = useState(false)
    const [marketingSelected, setMarketingSelected] = useState(false)
    const [agreementShow, setAgreementShow] = useState(false)
    const [modalData, setModalData] = useState("")
    const [personalData, setPersonalData] = useState(null)
    const [usageData, setUsageData] = useState(null)

    const handleOnClick = (data) => {
        setModalData(data)
        setAgreementShow(true)
    }

    const setData = async () => {
        const data = await getSystemAgreementFrom()
        const filteredListPersonal = data.filter((item) => item.key === AgreementForm.PERSONAL)
        const filteredListUsage = data.filter((item) => item.key === AgreementForm.USAGE)
        setPersonalData(filteredListPersonal[0].content)
        setUsageData(filteredListUsage[0].content)
    }

    useEffect(() => {
        setData()
    }, [])

    useEffect(() => {
        if (useageSelected && personalInfoSelected) {
            setIsChekced(true)
        } else {
            setIsChekced(false)
        }

        if (marketingSelected) {
            setIsMarketingChecked(true)
        }

        if (useageSelected && personalInfoSelected) {
            setAllSelected(true)
        } else {
            setAllSelected(false)
        }
    }, [useageSelected, personalInfoSelected])

    const handelSelectAll = () => {
        setUsageSelected(!allSelected)
        setPersonalInfoSelected(!allSelected)
    }

    const AGREEMENT_LIST = [
        {
            title: "(필수) 이용약관",
            onclickfunction: setUsageSelected,
            oncickstate: useageSelected,
            detail: usageData,
        },
        {
            title: "(필수) 개인정보 수집 이용, 제3자 제공 및 마케팅 정보 수신",
            onclickfunction: setPersonalInfoSelected,
            oncickstate: personalInfoSelected,
            detail: personalData,
        },
    ]

    return (
        <React.Fragment>
            <SignUpAgreementContainer>
                <SignUpAgreementAllContainer>
                    <SignUpAgreementAllTextLayout
                        isSelected={allSelected}
                        onClick={() => {
                            handelSelectAll()
                        }}
                    >
                        {/* <CircleCheckIcon className='mr-4' /> */}
                        <CheckIconContainer isSelected={allSelected}>
                            <CheckIcon />
                        </CheckIconContainer>
                        <AgreementText>{"전체 동의"}</AgreementText>
                    </SignUpAgreementAllTextLayout>
                </SignUpAgreementAllContainer>
                <SignUpAgreementEachContainer>
                    {AGREEMENT_LIST.map((data, idx) => {
                        return (
                            <SignUpAgreementEachTextBoxLayout key={idx} isSelected={data.oncickstate}>
                                <SignUpAgreementEachTextLayout
                                    onClick={() => {
                                        data.onclickfunction(!data.oncickstate)
                                    }}
                                >
                                    <CheckIconContainer isSelected={data.oncickstate}>
                                        <CheckIcon />
                                    </CheckIconContainer>
                                    <AgreementText>{data.title}</AgreementText>
                                </SignUpAgreementEachTextLayout>
                                {data.detail && (
                                    <SignUpAgreementViewButtonLayout>
                                        <button
                                            onClick={() => {
                                                handleOnClick(data)
                                            }}
                                        >
                                            보기
                                        </button>
                                    </SignUpAgreementViewButtonLayout>
                                )}
                            </SignUpAgreementEachTextBoxLayout>
                        )
                    })}
                </SignUpAgreementEachContainer>
            </SignUpAgreementContainer>
            <SignUpAgreementModal show={agreementShow} setShow={setAgreementShow} modalData={modalData} />
        </React.Fragment>
    )
}
