import { debounce } from "lodash"
import react, {useState, useEffect, useCallback} from "react"

export const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(()=>{
        window.addEventListener("resize", handleResize)
        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const handleResize = debounce(()=>{
        setWidth(window.innerWidth)
    }, 300)

    return width
}