export const UserBrandOrderOptionTypes = {
    REGIST: "user__id",
    NAME: "user__first_name",
    BRAND_NAME: "detail__name",
}
export const UserBrandOrderOptionNames = {
    "user__id": "가입 순",
    "user__first_name": "이름 순",
    "detail__name": "브랜드 이름 순",
}
