import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import {BodyInnerBox,BoxMaxMin} from '../../layouts/Box'

// 상단 내비 아래의 레이아웃
export const MyPageLayout = tw.div`
    flex flex-col w-full lg:h-[calc(100vh-2.5rem)]
`

// 페이지가 늘어나면 스크롤하지 않고 꽉채워서 쓸 경우 full_height 파라미터 전달. (예: 실적 페이지)
export const ContentContainer = tw.div`
    flex flex-col
    w-full max-lg:px-2 max-xl:px-4 xl:px-6 py-2 items-center
    ${(props) => props.full_height ? "lg:h-full" : "h-auto bg-slate-100"}
`

export const ContentTopSize = tw.div`
    w-full 
    lg:h-[6rem] lg:min-h-[6rem] max-xl:max-h-[6rem]
    xl:h-[4rem] xl:min-h-[4rem] xl:max-h-[4rem]
`

export const MyPageInputModalLayout = tw.div`
    flex flex-col
    p-4
    bg-white max-md:rounded-xl md:rounded-md shadow-lg
`

export const ListControlItem = tw.div`
flex items-center px-2 py-1 max-lg:h-8 lg:h-9
bg-white
rounded-lg drop-shadow
`

export const ListSubControlBox = tw.div`
    flex items-center pb-2
`