import React, { useEffect, useRef,useState } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'
import './editorstyle.css'
import './ckcontentstyle.css'
import { debounce } from 'lodash'

import { CKEditor as Editor } from '@ckeditor/ckeditor5-react'
import CustomEditor from "ckeditor5-custom-build/build/ckeditor"


import { uploadFile, uploadImage } from '../../apis/api/files'
import ImageUploadAdapter from './ImageUploadAdapter'
// React Quill 관련 모듈

const EditorCon = tw.div`
    flex relative
    w-full h-full
`

const preventGoBack = () => {
    history.pushState(null, "", location.href)
}

const CKEditor = ({value, setValue, setFocus=null}) => {
    const [toolbarHeight, setToolbarHeight] = useState(0) // 웹에디터 높이에서 툴바높이 만큼 빼야 높이가 맞는다.
    const editorRef = useRef(null)

    useEffect(() => {
        history.pushState(null, "", location.href)
        window.addEventListener("popstate", preventGoBack)

        return () => {
            window.removeEventListener("popstate", preventGoBack)
        }
    },[])

    const initEditor = (editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = function(loader) {
            return new ImageUploadAdapter(loader);
        }
        // 기본 폰트 선택
        editor.execute( 'fontFamily', {value: 'Pretendard'})
        // 기본 폰트 사이즈 선택
        editor.execute('fontSize', {value: '12pt'})

        // 특수문자 추가
        editor.plugins.get( 'SpecialCharacters' ).addItems('Custom', [
            { title: 'z', character: 'ZZZ' },
            { title: 'x', character: 'XXX' },
            { title: 'y', character: 'YYY' }
        ]);
    }

    const handleEditorBlur = (event, editor) => {
        setValue(editor.getData())
    }

    // 데이터를 delta값으로 받기 
    return (
        <EditorCon className="text-[14pt]">
            {/* <Toolbar /> */}
            <Editor
                ref={editorRef}
                editor={CustomEditor}
                data={value}
                onBlur={handleEditorBlur}
                onReady={initEditor}
                config={CONFIG}
            />
        </EditorCon>
    )
}

export default CKEditor

const generateFontFamily = (fontName, fontFamily) => {
	return {
		title: fontName,
		model: fontFamily,
		view: {
			name: 'span',
			styles: {
				'font-family': `${fontFamily}`
			}
		}
	};
}
const generatePtSetting = (size, sizeName=null) => {
    const number = size.replace(/\D/g, "");
	return {
		model: size,
		title: sizeName ? sizeName : size,
		view: {
			name: 'span',
			styles: {
				'font-size': `${number}pt`
			}
		}
	};
}

const CONFIG = {
    fontFamily: {
        options: [
            generateFontFamily("기본", "Pretendard"),
            generateFontFamily("궁서체", "ChosunGs"),
            generateFontFamily("굴림체", "ChosunGu"),
            generateFontFamily("고딕체", "ChosunSg"),
            generateFontFamily("신명조", "ChosunSm"),
        ],
        supportAllValues: true
    },
    fontSize: {
        options: [
            generatePtSetting( '8pt' ),
            generatePtSetting( '10pt' ),
            generatePtSetting( '12pt', "12pt 기본" ),
            generatePtSetting( '14pt' ),
            generatePtSetting( '16pt' ),
            generatePtSetting( '18pt' ),
            generatePtSetting( '20pt' ),
            generatePtSetting( '22pt' ),
        ],
        shouldNotGroupWhenFull: true,
        defaultSize: "12pt"
    },
    mediaEmbed: {
        previewsInData: true,
        providers: [
            {
                name: 'youtube',
                url: [
                    /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                    /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                    /^youtube\.com\/embed\/([\w-]+)/,
                    /^youtu\.be\/([\w-]+)/
                ],
                html: match => {
                    const id = match[ 1 ];

                    return (
                        '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                        `<iframe src="https://www.youtube.com/embed/${ id }" ` +
                        'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                        'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                        '</iframe>' +
                        '</div>'
                    );
                }
            },
        ]
    },
}

