import { PerformColumnFormTypes } from "../../types/brandItems/performColumns/PerformColumnTypes"
import PerformValueCheckBox from "../../types/brandItems/performValues/PerformValueCheckBox"
import PerformValueCombo from "../../types/brandItems/performValues/PerformValueCombo"
import PerformValueFile from "../../types/brandItems/performValues/PerformValueFile"
import PerformValueStatus from "../../types/brandItems/performValues/PerformValueStatus"
import PerformValueText from "../../types/brandItems/performValues/PerformValueText"
import PerfromValueDate from "../../types/brandItems/performValues/PerformValueDate"
import PerfromValueOption from "../../types/brandItems/performValues/PerformValueOption"
import Utils from "../Utils"


export const getPerformEmptyValue = (column) => {
    let emptyValue = null
    if (column.formType === PerformColumnFormTypes.COMBO) {
        emptyValue = new PerformValueCombo()
    } else if (column.formType === PerformColumnFormTypes.FILE) {
        emptyValue = new PerformValueFile()
        emptyValue.fileID = 0
    } else if (column.formType === PerformColumnFormTypes.TEXT) {
        emptyValue = new PerformValueText()
    } else if (column.formType === PerformColumnFormTypes.STATUS) {
        emptyValue = new PerformValueStatus()
    } else if (column.formType === PerformColumnFormTypes.CHECK_BOX) {
        emptyValue = new PerformValueCheckBox()
    } else if (column.formType === PerformColumnFormTypes.DATE) {
        emptyValue = new PerfromValueDate()
    } else if (column.formType === PerformColumnFormTypes.OPTION) {
        emptyValue = new PerfromValueOption()
    }

    return emptyValue
}

export const getComboName = (column, comboID) => {
    let name = ""

    const combo = column.combos.find(s => s.id === Number(comboID))
    if (combo !== undefined && !Utils.isStringNullOrEmpty(combo.name)) {
        if (comboID >= 0) {
            name = combo.name
        }
    }

    return name
}

export const getStatusName = (column, statusID) => {
    let name = ""

    const status = column.statuses.find(s => s.id === Number(statusID))
    if (status !== undefined && !Utils.isStringNullOrEmpty(status.name)) {
        if (statusID >= 0) {
            name = status.name
        }
    }

    return name
}
