import React, { useReducer, useEffect, useCallback } from "react"
import {
    SignUpCommitButton,
    SignUpInfoCol,
    SignUpInfoInputCol,
    SignUpInfoTitle,
    SignUpUserLayout,
    SignUpUserTitle,
    SignUpUserLayoutMarketer,
    StepsLayout,
    StepsContainer,
    StepLine,
    StepNumer,
    StepTitle,
    SignUpUserMarketerContainer,
    SignUpUserMarketerLayout,
    SignUpStepContainer,
    SignUpBasicInformationContainer,
    SignUpButtonContainer,
    SignUpButton,
    SignUpBuisnessButton,
    SignUpBuisnessPreview,
    BuisnessImageHover,
    BuisnessImage,
    SignUpBackButton,
    SignUpButtonBox,
} from "./SignUpStyle"
import { useNavigate } from "react-router-dom"
import { UserTypes } from "../../types/users/UserTypes"

const initialState = {
    step: 0,
}

function reducer(state, action, maxStep) {
    switch (action.type) {
        case "increment":
            return { step: state.step < maxStep ? state.step + 1 : state.step }
        case "decrement":
            return { step: state.step > 0 ? state.step - 1 : state.step }
        case "reset":
            return { step: 1 }
        case "end":
            return { step: maxStep }
        default:
            throw new Error()
    }
}

function SignUpStepButton({
    userType,
    signUp,
    setSignUpLoading,
    validateUserRegist,
    setStepIndex,
    ischecked,
    userChecked,
    maxStep = 3,
}) {
    const [state, dispatch] = useReducer((state, action) => reducer(state, action, maxStep), initialState)
    const navigate = useNavigate()

    // 부모 컴포넌트의 stepindex 동기화
    useEffect(() => {
        setStepIndex(state.step)
    }, [state])

    const handleStep1 = () => {
        if (ischecked) {
            dispatch({ type: "increment" })
        } else {
            alert("필수 정보를 동의 해주시기 바랍니다.")
        }
    }

    const handleStep2 = async () => {
        if (userType === UserTypes.BRAND) {
            // 브랜드인 경우 입력이 올바른지 확인 후 다음 단계로 넘어간다.
            const result = await validateUserRegist()
            if (result) {
                dispatch({ type: "increment" })
            }
        } else if (userType === UserTypes.MARKETER) {
            // 마케터인 경우 회원가입 완료 후 추천 정보 입력으로 넘어간다.
            // handleStep3()
            setSignUpLoading(true)
            signUp()
                .then((result) => {
                    setSignUpLoading(false)
                    if (result) {
                        dispatch({ type: "increment" })
                    }
                })
                .catch((error) => {
                    setSignUpLoading(false)
                })
        }
    }

    const handleStep3 = async () => {
        if (userType === UserTypes.BRAND) {
            // 브랜드인 경우 회원가입 완료
            setSignUpLoading(true)
            signUp()
                .then((result) => {
                    setSignUpLoading(false)
                    if (result) {
                        dispatch({ type: "end" })
                    }
                })
                .catch((error) => {
                    setSignUpLoading(false)
                })
        } else if (userType === UserTypes.MARKETER) {
            // 마케터인 경우 추천 정보 저장
            dispatch({ type: "end" })
        }
    }

    const handleNext = () => {
        dispatch({ type: "increment" })
    }

    return (
        <SignUpButtonContainer>
            <SignUpButtonBox>
                {state.step === 0 && (
                    <>
                        <SignUpBackButton
                            onClick={() => {
                                dispatch({ type: "reset" })
                                navigate("/signup")
                            }}
                        >
                            처음으로
                        </SignUpBackButton>
                        <button
                            className="px-4 py-2 bg-black text-white"
                            onClick={() => {
                                handleNext()
                            }}
                        >
                            강제 다음
                        </button>
                        <SignUpButton
                            className={`${ischecked ? "bg-nightblue-500" : "bg-gray-400"}`}
                            onClick={handleStep1}
                        >
                            다음
                        </SignUpButton>
                    </>
                )}
                {state.step === 1 && (
                    <>
                        <SignUpBackButton onClick={() => dispatch({ type: "decrement" })}>이전</SignUpBackButton>
                        <button
                            className="px-4 py-2 bg-black text-white"
                            onClick={() => {
                                handleNext()
                            }}
                        >
                            강제 다음
                        </button>
                        <SignUpButton disabled={!userChecked} isdisabled={!userChecked} onClick={handleStep2}>
                            {userType === UserTypes.BRAND && "다음"}
                            {userType === UserTypes.MARKETER && "완료"}
                        </SignUpButton>
                    </>
                )}
                {state.step === 2 && maxStep === 3 && (
                    <>
                        {userType === UserTypes.BRAND && (
                            <>
                                <SignUpBackButton onClick={() => dispatch({ type: "decrement" })}>
                                    이전
                                </SignUpBackButton>
                                <SignUpButton disabled={!userChecked} onClick={handleStep3}>
                                    완료
                                </SignUpButton>
                            </>
                        )}
                        {/* {userType === UserTypes.MARKETER && (
                            <>
                                <SignUpBackButton
                                    onClick={() => {
                                        dispatch({ type: "reset" })
                                        {
                                            navigate("/")
                                        }
                                    }}
                                >
                                    홈으로
                                </SignUpBackButton>
                                <SignUpButton disabled={!userChecked} onClick={handleStep3}>
                                    추천
                                </SignUpButton>
                            </>
                        )} */}
                    </>
                )}
                {state.step === maxStep && (
                    <>
                        <SignUpButton
                            onClick={() => {
                                dispatch({ type: "reset" })
                                {
                                    navigate("/")
                                }
                            }}
                        >
                            홈으로
                        </SignUpButton>
                    </>
                )}
            </SignUpButtonBox>
        </SignUpButtonContainer>
    )
}

export default SignUpStepButton
