import React from 'react';
import tw from 'tailwind-styled-components'
import Utils from '../utils/Utils';
import { Close } from 'styled-icons/fa-solid';

const AdvertiseContainer = tw.div`
    flex flex-col mt-8 relative
    w-full
    bg-transparent
`
const AdvertiseImageContainer = tw.div`
    w-auto h-auto
    ${(props) => props.isShow ? "opacity-100" : "opacity-0"}
`
const AdvertiseButton = tw.button`
    absolute top-3 right-3
    flex flex-center
    w-4 h-4 py-0.25
    bg-gray-100 border border-gray-400
    text-gray-600
`


const Advertisement = ({isShow, setShow, imageURL, linkURL=""}) => {
    const handleLink = () => {
        if (!Utils.isStringNullOrEmpty(linkURL)) {
            window.open(linkURL)
        }
    }
    
    return (
        <AdvertiseContainer>
            <AdvertiseImageContainer isShow={isShow} onClick={()=>{handleLink()}}>
                <img
                    src={imageURL}
                />
            </AdvertiseImageContainer>
            <AdvertiseButton onClick={()=>{setShow(!isShow)}}>
                <Close className='h-full' />
            </AdvertiseButton>
        </AdvertiseContainer>
    );
}

export default Advertisement