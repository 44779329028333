import User from "../../users/User";
import UserMarketer from "../../users/UserMarketer";
import { MarketerStatusTypes } from "../BrandItemTypes";
import BrandItemMarketer from "./BrandItemMarketer";

export default class{
    constructor() {
        this.id = -1
        this.index = 0
        this.name = ""
        this.masterMarketer = new UserMarketer()
        this.marketers = []
        
        this.created = ""
        this.updated = ""

        this.performs = 0
        this.performConfirms = 0
        this.calculates = 0
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.index = response["index"]
        this.name = response["name"]
        this.marketerStatusType = response["marketer_status_type"]
        
        const masterMarketerResponse = response["master_marketer"]
        this.masterMarketer = new UserMarketer()
        if (masterMarketerResponse !== undefined) {
            this.masterMarketer.convertByResponse(masterMarketerResponse)
        }

        const marketersResponse = response["marketers"]
        this.marketers = []
        if (marketersResponse !== undefined) {
            marketersResponse.map(marketerResponse => {
                const marketer = new BrandItemMarketer()
                marketer.convertByResponse(marketerResponse)
                this.marketers.push(marketer)
            })
        }
        this.marketers.map(marketer => {
            this.performs += marketer.performs
            this.performConfirms += marketer.performConfirms
            this.calculates += marketer.calculates
        })

        this.created = response["created"]
    }

    convertToRequest() {
    }
}