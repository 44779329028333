import React, { useRef, useEffect, useState } from "react"
import tw from "tailwind-styled-components"
import { ModalContainer } from "../../components/ModalContainer"
import AppContract from "../../static/AppContract.png"
import { useNavigate, useParams } from "react-router-dom"
import { registItemByMarketer } from "../../apis/services/brandItems"
import { ItemApplyButton } from "../../pages/brandItems/ItemApplyStyle"

const CanvasClearButton = tw.button`
    flex-center py-2 px-4 
    bg-transparent
    ${(props) => (!props.disabled ? "text-red-500" : "text-gray-600")}
`

const UploadButton = tw.button`
    flex-center py-2 px-4 
    bg-transparent
    ${(props) => (!props.disabled ? "text-nightblue-600" : "text-gray-600")}
`

// 현재 사용하지않음 SignCanvas로 대체
export const DrawingBoard = ({
    regist,
    isRegisting,
    signImage = { signImage },
    setSignImage = { setSignImage },
    isApply = false,
}) => {
    // cnavas 와 context state
    const canvasRef = useRef(null)
    const [context, setContext] = useState(null)
    // 그림을 그리는 중인지 판단하는 state
    const [drawing, setDrawing] = useState(false)
    const [isUploaded, setIsUploaded] = useState(false)
    const inputRef = useRef(null)
    // canvas 설정한다

    useEffect(() => {
        setContext(canvasRef.current.getContext("2d"))
    }, [])

    // 마우스 커서 위치를 get 하는 함수
    const getCursorPosition = (canvas, event, isTouch) => {
        const rect = canvas.getBoundingClientRect()
        const scaleX = canvas.width / rect.width
        const scaleY = canvas.height / rect.height

        let clientX = isTouch ? event.touches[0].clientX : event.clientX
        let clientY = isTouch ? event.touches[0].clientY : event.clientY

        const x = (clientX - rect.left) * scaleX
        const y = (clientY - rect.top) * scaleY
        return { x, y }
    }

    // 마우스 다운 그림을 그리기 시작함
    const handleMouseDown = (e, isTouch) => {
        if (isUploaded === false && !isRegisting) {
            e.preventDefault()
            const { x, y } = getCursorPosition(canvasRef.current, e, isTouch)

            // 이게 있어야 초기화 후에 그림을 처음부터 그릴 수 있음
            context.beginPath()
            context.moveTo(x, y)
            setDrawing(true)
        }
    }

    // 마우스떼기 이벤트 그림 그리는 것을 멈춤
    const handleMouseUp = (e, isTouch) => {
        e.preventDefault()

        setDrawing(false)
    }

    // 마우스 이동 이벤트 (cnavas에 그림을 그림)
    const handleMouseMove = (e, isTouch) => {
        e.preventDefault()
        if (isUploaded === false && !isRegisting) {
            if (!drawing) return
            const { x, y } = getCursorPosition(canvasRef.current, e, isTouch)
            context.lineTo(x, y)
            context.stroke()
        }
    }

    // canvas 컨텐트를 초기화
    const clearCanvas = () => {
        setIsUploaded(false)
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    }

    // canvas 컨텐트를 이미지로 저장(png)
    const saveCanvasAsImage = () => {
        const image = canvasRef.current.toDataURL("image/png")
        setSignImage(image)
        regist(image)
    }

    // 모든 SignDataBox 를 preview 이미지로 채우는 함수
    const handleImageUploads = (event) => {
        clearCanvas()
        const reader = new FileReader()
        reader.onload = () => {
            const img = new Image()
            img.onload = () => {
                context.drawImage(img, 0, 0, 500, 500)
            }
            img.src = reader.result
        }
        reader.readAsDataURL(event.target.files[0])
        setIsUploaded(true)
    }

    const handleButtonClick = () => {
        inputRef.current.click()
    }

    return (
        <div className="w-full flex flex-col justify-center px-6">
            <div className="flex flex-center max-sm:h-64 sm:h-128 relative">
                <div className="w-[500px] h-[500px] max-sm:scale-50">
                    <canvas
                        ref={canvasRef}
                        className={"border-black border border-dashed"}
                        width="500"
                        height="500"
                        onMouseDown={(e) => {
                            handleMouseDown(e, false)
                        }}
                        onMouseUp={(e) => {
                            handleMouseUp(e, false)
                        }}
                        onMouseMove={(e) => {
                            handleMouseMove(e, false)
                        }}
                        onTouchStart={(e) => {
                            handleMouseDown(e, true)
                        }}
                        onTouchEnd={(e) => {
                            handleMouseUp(e, true)
                        }}
                        onTouchMove={(e) => {
                            handleMouseMove(e, true)
                        }}
                    />
                </div>
                <div className="absolute bottom-1 w-full flex justify-center">
                    <span className="max-sm:text-xs sm:text-sm text-gray-400">
                        이 박스 안쪽에 서명 해주시기 바랍니다. 500 x 500
                    </span>
                </div>
            </div>
            <div className="flex justify-center w-full">
                <div className="flex justify-between items-center max-sm:min-w-[16rem] sm:min-w-[32rem]">
                    <input
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUploads}
                        style={{ display: "none" }}
                    />
                    <CanvasClearButton onClick={clearCanvas} disabled={isRegisting}>
                        초기화
                    </CanvasClearButton>
                    <UploadButton onClick={handleButtonClick} disabled={isRegisting}>
                        이미지 업로드
                    </UploadButton>
                </div>
            </div>
            <div className="flex flex-col my-6">
                <span className="flex items-center">
                    ※ 회사 인감도장(개인 사업자인 경우 대표자 인감도 가능) 또는 대표자 서명 필수
                </span>
                <span className="flex items-center">※ 서명 시 최대한 꽉채워서 서명 해주시기 바랍니다.</span>
                <span className="flex items-center">
                    ※ 이미지를 직접 업로드 할 때, 가로 세로 비율을 동일하게 유지하고 최대한 꽉 채워진 이미지를 업로드
                    해주시기 바랍니다.
                </span>
            </div>
            <div className="flex justify-between items-center h-14">
                <p></p>
                <ItemApplyButton
                    onClick={() => {
                        saveCanvasAsImage()
                    }}
                    disabled={isRegisting}
                    className="px-8"
                >
                    {isApply ? "신청" : "저장"}
                </ItemApplyButton>
            </div>
        </div>
    )
}
