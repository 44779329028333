// import ReactDOM from 'react-dom';
import React, { useEffect, useState } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import MyPageNavOutlet from "./MyPageNavOutlet"
import MyPageItemOutlet from "./MyPageItemOutlet"
import MyPageHome from "../home/MyPageHome"
import MyPageItem from "../item/MyPageItem"
import { PmsOfficeNavState, PmsNavState } from "../../../types/DataEnum"
import MyPagePerformOutlet from "./MyPagePerformOutlet"
import MyPagePerfrom from "../perform/MyPagePerform"
import BrandItem from "../../../types/brandItems/BrandItem"
import MyPageCalc from "../calculate/MyPageCalc"
import MyPageMarketer from "../marketer/MyPageMarketer"
import MyPageGroup from "../marketerGroup/MyPageGroup"
import MyPageNotice from "../notice/MyPageNotice"
import MyPageNoticeDetail from "../notice/MyPageNoticeDetail"
import { ContentContainer } from "../MyPageMainStyle"
import MyInfoMain from "../myinfo/MyInfoMain"
// import {PmsNavFolded,PmsNavState,PmsNavUrlState,PerformNavState} from '../../types/DataEnum'
import StaffUserBrand from "../staff/staffuser/StaffUserBrand"
import StaffItems from "../staff/staffitems/StaffItems"
import StaffNotice from "../staff/staffnotice/StaffNotice"
import AccessRules from "../../../rules/AccessRules"
import StaffPerformColumn from "../perform/StaffPerformColumn"
import StaffUserMarketer from "../staff/staffuser/StaffUserMarketer"
import { useUserStore } from "../../../zustand/store"
import { UserTypes } from "../../../types/users/UserTypes"
import ConstLocalStorage from "../../../constants/ConstLocalStorage"
import Utils from "../../../utils/Utils"
import StaffUserPoint from "../staff/staffuser/StaffUserPoint"
import MyPageItemAd from "../item/MyPageItemAdContainer"
import MyPageItemAdContainer from "../item/MyPageItemAdContainer"
import MyPageItemAdvertisementContainer from "../item/MyPageItemAdvertisement"
import MyPagePointMain from "../points/MyPagePointMain"

const MyPageRouter = () => {
    const { user, userType } = useUserStore((state) => state)
    let navigate = useNavigate()

    const [navigationFolded, setNavigationFolded] = useState(false)

    const [officeNavState, setOfficeNavState] = useState(PmsOfficeNavState.NONE)
    //
    const [pmsNavState, setPmsNavState] = useState(PmsNavState.NONE)

    const [selectedItem, setSelectedItem] = useState(new BrandItem())

    useEffect(() => {
        // 내비게이션 접힘이 저장되어있는지 여부
        // MY_PAGE_NAV_FOLDED에는 어떠한 값이라도 있으면 접혀있다고 본다.
        const storageNavFolded = localStorage.getItem(ConstLocalStorage.MY_PAGE_NAV_FOLDED)
        setNavigationFolded(!Utils.isStringNullOrEmpty(storageNavFolded))
    }, [])

    useEffect(() => {
        if (navigationFolded) {
            localStorage.setItem(ConstLocalStorage.MY_PAGE_NAV_FOLDED, "0")
        } else {
            localStorage.removeItem(ConstLocalStorage.MY_PAGE_NAV_FOLDED)
        }
    }, [navigationFolded])

    useEffect(() => {
        if (!Utils.isNullOrUndefined(user)) {
            if (user.userType === UserTypes.NONE) {
                navigate("/")
            }
        }
    }, [userType])

    useEffect(() => {
        // 실적 페이지에 들어가면 내비를 접는다.
        if (officeNavState === PmsOfficeNavState.PERFORM) {
            setNavigationFolded(true)
        }
    }, [officeNavState])

    return (
        <>
            <Routes>
                {/* 상단 및 사이드 네비, 화면 */}
                <Route
                    path=""
                    element={
                        <MyPageNavOutlet
                            officeNavState={officeNavState}
                            selectedItem={selectedItem}
                            pmsNavState={pmsNavState}
                            folded={navigationFolded}
                            setFolded={setNavigationFolded}
                        />
                    }
                >
                    {/* 마이페이지 홈 화면 (대시보드) */}
                    <Route path="" element={<MyPageHome setPmsNavState={setPmsNavState} />} />
                    {/* 마이페이지 아이템 화면 */}
                    <Route path="item">
                        <Route index element={<MyPageItem setPmsNavState={setPmsNavState} />} />
                        {/* <Route path='create' element={<MyPageItemCreate/>}/>
                        <Route path='edit' element={<MyPageItemEdit/>}/> */}
                    </Route>
                    <Route path="item_ad">
                        <Route index element={<MyPageItemAdContainer setPmsNavState={setPmsNavState} />} />
                        {/* <Route path='create' element={<MyPageItemCreate/>}/>
                        <Route path='edit' element={<MyPageItemEdit/>}/> */}
                    </Route>
                    <Route path="advertisement">
                        <Route index element={<MyPageItemAdvertisementContainer setPmsNavState={setPmsNavState} />} />
                        {/* <Route path='create' element={<MyPageItemCreate/>}/>
                        <Route path='edit' element={<MyPageItemEdit/>}/> */}
                    </Route>
                    {/* 실적 관리 네비와 화면 */}
                    <Route
                        path="perform"
                        element={
                            <MyPagePerformOutlet
                                user={user}
                                userType={userType}
                                officeNavState={officeNavState}
                                setOfficeNavState={setOfficeNavState}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                setPmsNavState={setPmsNavState}
                                setNavigationFolded={setNavigationFolded}
                            />
                        }
                    >
                        <Route
                            path=":itemID"
                            element={
                                <MyPagePerfrom
                                    officeNavState={officeNavState}
                                    setOfficeNavState={setOfficeNavState}
                                    selectedItem={selectedItem}
                                />
                            }
                        />
                        {AccessRules.staff() && (
                            <Route
                                path="column/:itemID"
                                element={
                                    <StaffPerformColumn
                                        officeNavState={officeNavState}
                                        setOfficeNavState={setOfficeNavState}
                                        selectedItem={selectedItem}
                                    />
                                }
                            />
                        )}
                        <Route
                            path="cal/:itemID"
                            element={
                                <MyPageCalc
                                    officeNavState={officeNavState}
                                    setOfficeNavState={setOfficeNavState}
                                    selectedItem={selectedItem}
                                />
                            }
                        />
                        <Route
                            path="marketer/:itemID"
                            element={
                                <MyPageMarketer setOfficeNavState={setOfficeNavState} selectedItem={selectedItem} />
                            }
                        />
                        <Route path="group/:itemID" element={<MyPageGroup setOfficeNavState={setOfficeNavState} />} />
                        <Route
                            path="notice/:itemID"
                            element={<MyPageNotice setOfficeNavState={setOfficeNavState} selectedItem={selectedItem} />}
                        />
                        <Route
                            path="notice/:itemID/:noticeID"
                            element={
                                <MyPageNoticeDetail setOfficeNavState={setOfficeNavState} selectedItem={selectedItem} />
                            }
                        />
                    </Route>

                    <Route path="point" element={<MyPagePointMain setPmsNavState={setPmsNavState} />} />
                    <Route path="myinfo" element={<MyInfoMain setPmsNavState={setPmsNavState} />} />
                    {AccessRules.staff() && (
                        <Route path="staff">
                            <Route path="user/point" element={<StaffUserPoint setPmsNavState={setPmsNavState} />} />
                            <Route path="user/brand" element={<StaffUserBrand setPmsNavState={setPmsNavState} />} />
                            <Route
                                path="user/marketer"
                                element={<StaffUserMarketer setPmsNavState={setPmsNavState} />}
                            />
                            <Route path="item" element={<StaffItems setPmsNavState={setPmsNavState} />} />
                            <Route path="notice" element={<StaffNotice setPmsNavState={setPmsNavState} />} />
                        </Route>
                    )}
                </Route>
            </Routes>
        </>
    )
}

export default MyPageRouter
