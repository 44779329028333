import React, {useState, useEffect} from "react"
import {ImageBase64} from "../ImageContainer"
import { useGettingHeight } from '../../hooks/useGettingHeight';

const DefaultImage = ({user}) => {
    const [height, ref] = useGettingHeight()
    return (
        <div className="flex flex-center w-full h-full bg-slate-600 font-semibold text-white" ref={ref}>
            <span style={{fontSize: height*0.6}}>
                {user.name.slice(0, 1)}
            </span>
        </div>
    )
}

const UserAvatar = ({user}) => {
    const [email, setEmail] = useState("")

    return (
        <div className="flex flex-center w-full h-full aspect-square select-none">
            {user.profileImageID >= 0 ? (
                <ImageBase64 imageID={user.profileImageID} />
            ) : (
                <DefaultImage user={user} />
            )}
        </div>
    )
}

export default UserAvatar