import React from 'react'
import tw from 'tailwind-styled-components'

export function TableNonColumnSimple({data}){
    return(
        <div className="flex flex-col p-1 bg-white rounded-md overflow-hidden">
            {data.map((row, rowIndex) => (
                <TableRow key={rowIndex} row={row} rowIndex={rowIndex} />
            ))}
        </div>
    )
}

const TableRow = ({row, rowIndex}) => {
    return (
        <div className={`flex p-1 ${rowIndex > 0 && "border-t"} text-sm`}>
            {row.map((value, rowColIndex) => (
                <div key={rowColIndex} className={`flex flex-center flex-wrap px-2 max-sm:min-w-[5rem] sm:min-w-[6rem] ${rowColIndex > 0 && "border-l"} ${rowColIndex === 0 ? "text-black font-medium" : "text-gray-800"}`}>
                    {value}
                </div>    
            ))}
        </div>
    )
}