import Paginator from "../Paginator"
import Advertisement from "./Advertisement"

export default class extends Paginator {
    constructor(count) {
        super(count)
    }

    convertByResponse(response) {
        this.count = response["count"]

        this.items = []
        response["results"].map((result) => {
            let item = new Advertisement()
            item.convertByResponse(result)
            this.items.push(item)
        })
    }
}
