import User from './User';
import UserMarketerCareer from './UserMarketerCareer';
import { StaffTypes } from './UserTypes';
export default class {
    constructor() {
        this.user = new User()
        this.staffType = StaffTypes.STAFF
    }

    convertByResponse(response) {
        const userResponse = response["user"]
        this.user = new User()
        if (userResponse !== undefined) {
            this.user.convertByResponse(userResponse)
        }

        this.staffType = response["staff_type"]
    }
}