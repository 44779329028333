import { createGlobalStyle } from "styled-components";
import './fonts/font.css'

export const FONT_FAMILY = "Pretendard"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  :root {
    --main-color: #5b81ff;
    --heading-color: #0a1d5a;
    --paragraph-color: #6777aa;
    --heading-font: "Heebo", sans-serif;
    --body-font-size: 16px;
    --line-height30: 1.7;
  }
  
  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height:100%;
    overflow:auto;
    overflow-x: hidden;
  }
  
  * {
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
  }
  
  div {
    -webkit-tap-highlight-color: transparent; /* iOS */
    -webkit-touch-callout: none; /* iOS */
    -webkit-user-select: none; /* iOS, Android */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  body {
    margin: 0;
    color: var(--paragraph-color);
    line-height: var(--line-height30);
    
    font-size: var(--body-font-size);
    height:100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
  
  h1 {
    font-size: 70px;
    line-height: 1.2333333333;
  }
  
  h2 {
    font-size: 45px;
    line-height: 1.3380952381;
  }
  
  h3 {
    font-size: 32px;
    line-height: 1.3833333333;
  }
  
  h4 {
    font-size: 24px;
    line-height: 1.3380952381;
  }
  
  h5 {
    font-size: 20px;
    line-height: 1.3380952381;
  }
  
  h6 {
    font-size: 16px;
    line-height: 1.2380952381;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-weight: 700;
  }
  
  p {
    color: var(--paragraph-color);
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-bottom: 10px;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: 0.4s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* iOS */
    -webkit-tap-highlight-color: transparent; /* iOS */
    -webkit-touch-callout: none; /* iOS */
    -webkit-user-select: none; /* iOS, Android */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent; /* iOS */
    -webkit-touch-callout: none; /* iOS */
    -webkit-user-select: none; /* iOS, Android */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  
  a:hover {
    color: var(--main-color-one);
  }
  
  pre {
    word-break: break-word;
  }
  
  a i {
    padding: 0 2px;
  }
  
  img {
    max-width: 100%;
  }
  
  ol {
    counter-reset: counter;
    padding-left: 0;
  }
  
  ol li {
    list-style: none;
    margin-bottom: 1rem;
  }
  
  ol li:before {
    counter-increment: counter;
    content: counter(counter);
    font-weight: 500;
    margin-right: 10px;
  }
  
  button:hover,
  button:active,
  button:focus {
    outline: 0;
  }
`;



export default GlobalStyle;