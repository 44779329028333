import Board from "../../boards/Board";
import { BoardImportnaceStatusTypes } from "../../boards/BoardTypes";
import Paginator from "../../Paginator";

export default class{
    constructor() {
        this.id = -1        
        this.index = -1
        this.board = new Board()
        this.board_importance_status = BoardImportnaceStatusTypes.DEFAULT
        this.created = ""
        this.updated = ""
    }

    convertByResponse(response) {
        this.id = response["id"]
        this.index = response["index"]

        const boardResponse = response["board"]
        this.board = new Board()
        if (boardResponse !== null && boardResponse !== undefined) {
            this.board.convertByResponse(boardResponse)
        }
        this.board_importance_status = (response["board_importance_status"] === null ?BoardImportnaceStatusTypes.DEFAULT:response["board_importance_status"] )
        this.created = response["created"]
        this.updated = response["updated"]
    }

    convertToRequest() {
        let request = {
            board: this.board.convertToRequest()
        }

        return request
    }
}